import PropTypes from "prop-types";
import React, { useState } from "react";
import { Row, Col, Alert, Card, CardBody, Container, FormFeedback, Input, Label, Form } from "reactstrap";
import { withRouter, Link } from "react-router-dom";
// Formik Validation
import * as Yup from "yup";
import { useFormik } from "formik";
// import images
import profile from "../../assets/images/profile-img.png";
import logo from "assets/images/logo.png";
import { AuthAPI } from "apis/AuthAPI";

const UpdatePasswordPage = (props) => {
    //meta title
    document.title = "Medx-Forget Password";
    const search = window.location.search;
    const params = new URLSearchParams(search);
    const userID = params.get('id');

    const [forgetSuccessMsg, setForgetSuccessMsg] = useState("");
    const [forgetError, setForgetError] = useState("");
    
    const validation = useFormik({
        // enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: true,
        initialValues: {
            password: '',
            confpassword: ''
        },
        validationSchema: Yup.object({
            password: Yup.string().required('Password is required'),
            confpassword: Yup.string().required('Confirm Password is required')
            .oneOf([Yup.ref('password'), null], 'Passwords must match')
        }),
        onSubmit: (values) => {
            values.userID = parseInt(atob(userID));
            AuthAPI.resetPassword(values)
                .then(res => {
                    if (res.data.success) {
                        setForgetError("")
                        setForgetSuccessMsg(res.data.message);
                        setInterval(() => {
                            window.location.href = "/login";
                        },2000);
                    } else {
                        setForgetSuccessMsg("")
                        setForgetError(res.data.message)
                    }
                })
                .catch(function (error) {
                console.log(error)
                })
        }
    });

    return (
        <React.Fragment>
            <div className="home-btn d-none d-sm-block">
                <Link to="/" className="text-dark">
                    <i className="fas fa-home h2" />
                </Link>
            </div>
            <div className="account-pages my-5 pt-sm-5">
                <Container>
                    <Row className="justify-content-center">
                        <Col md={8} lg={6} xl={5}>
                            <Card className="overflow-hidden">
                                <div className="bg-primary bg-softbg-soft-primary">
                                    <Row>
                                        <Col xs={7}>

                                        </Col>
                                        <Col className="col-5 align-self-end">
                                            <img src={profile} alt="" className="img-fluid" />
                                        </Col>
                                    </Row>
                                </div>
                                <CardBody className="pt-0">
                                    <div>
                                        <Link to="/">
                                        <div className="avatar-md profile-user-wid mb-4">
                                            <span className="avatar-title rounded-circle bg-white">
                                            <img
                                                src={logo}
                                                alt=""
                                                className="rounded-circle"
                                                width="60%"
                                            />
                                            </span>
                                        </div>
                                        </Link>
                                    </div>
                                    <div className="p-2">
                                        {forgetError && forgetError ? (
                                            <Alert color="danger" style={{ marginTop: "13px" }}>
                                                {forgetError}
                                            </Alert>
                                        ) : null}
                                        {forgetSuccessMsg ? (
                                            <Alert color="success" style={{ marginTop: "13px" }}>
                                                {forgetSuccessMsg}
                                            </Alert>
                                        ) : null}

                                        <Form
                                            className="form-horizontal"
                                            onSubmit={(e) => {
                                                e.preventDefault();
                                                validation.handleSubmit();
                                                return false;
                                            }}
                                        >
                                            <div className="mb-3">
                                                <Label className="form-label">Password</Label>
                                                <Input
                                                    name="password"
                                                    className="form-control"
                                                    placeholder="Enter Your Desired Password"
                                                    type="password"
                                                    onChange={validation.handleChange}
                                                    onBlur={validation.handleBlur}
                                                    value={validation.values.password || ""}
                                                    invalid={
                                                        validation.touched.password && validation.errors.password ? true : false
                                                    }
                                                />
                                                {validation.touched.password && validation.errors.password ? (
                                                    <FormFeedback type="invalid">{validation.errors.password}</FormFeedback>
                                                ) : null}
                                            </div>
                                            <div className="mb-3">
                                                <Label className="form-label">Confirm Password</Label>
                                                <Input
                                                    name="confpassword"
                                                    className="form-control"
                                                    placeholder="Re-Enter Your Password"
                                                    type="password"
                                                    onChange={validation.handleChange}
                                                    onBlur={validation.handleBlur}
                                                    value={validation.values.confpassword || ""}
                                                    invalid={
                                                        validation.touched.confpassword && validation.errors.confpassword ? true : false
                                                    }
                                                />
                                                {validation.touched.confpassword && validation.errors.confpassword ? (
                                                    <FormFeedback type="invalid">{validation.errors.confpassword}</FormFeedback>
                                                ) : null}
                                            </div>
                                            <Row className="mb-3">
                                                <Col className="text-end">
                                                    <button
                                                        className="btn btn-primary w-md "
                                                        type="submit"
                                                    >
                                                        Update Password
                                                    </button>
                                                </Col>
                                            </Row>
                                        </Form>
                                    </div>
                                </CardBody>
                            </Card>
                            <div className="mt-5 text-center">
                                <p>
                                    Go back to{" "}
                                    <Link to="login" className="font-weight-medium text-primary">
                                        Login
                                    </Link>{" "}
                                </p>

                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
};

UpdatePasswordPage.propTypes = {
    history: PropTypes.object,
};

export default withRouter(UpdatePasswordPage);
