import { EvalutionFormApi } from "apis/EvalutionFormApi"
import { InstitutionApi } from "apis/InstitutionApi"
import { InternshipApi } from "apis/InternshipApi"
import { UserApi } from "apis/UserApi"
import Breadcrumb from "components/Common/Breadcrumb"
import { useFormik } from "formik"
import { cloneDeep, debounce } from "lodash"
import React, { useEffect, useState } from "react"
import { withTranslation } from "react-i18next"
import { useHistory } from "react-router-dom"
import Select from "react-select"
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Form,
  FormFeedback,
  Input,
  Label,
  Row,
} from "reactstrap"
import Swal from "sweetalert2"
import toastr from "toastr"
import "toastr/build/toastr.min.css"
import { getUserID } from "../../Token"
import * as Yup from "yup"

const ManageSession = props => {
  const history = useHistory()
  const { instituteId } = history.location.state
  const sessionId = props.match.params.sessionId
  const [session, setSession] = useState()
  const [taskTypes, setTaskypes] = useState([])
  const [selectedTaskTypes, setSelectedTaskTypes] = useState([])
  const [internsData, setInternsData] = useState([])
  const [interns, setinterns] = useState([])
  const [selectedInterns, setSelectedInterns] = useState([])
  const [selectedInstituteHost, setSelectedInstituteHost] = useState()
  const [showEvaluators, setShowEvaluators] = useState(false)
  const [institutesData, setinstitutesData] = useState([])
  const [defualtInstitute, setDefualtInstitute] = useState(null)
  const [evaluationForms, setEvaluationForms] = useState([])
  const [institutesPaginateData, setinstitutesPaginateData] = React.useState({
    page: 1,
    limit: 20,
    totalRecords: 1000,
    search: "",
  })

  useEffect(() => {
    getSessionTaskAndEvaaluators()
  }, [])

  useEffect(() => {
    if (instituteId !== undefined) getAllMainTaskByInstitute()
    getAllDoctors("INTERNAL")
    getAllInstitutes()
    getInstituteAllEvaluationForms()
  }, [])

  const getInstituteAllEvaluationForms = () => {
    const payload = {
      user_id: getUserID(),
    }
    EvalutionFormApi.getInstituteEvaluationForms(payload)
      .then(res => {
        if (res.data.success) {
          let formsArr = []
          res.data.data.rows.length > 0 &&
            res.data.data.rows.map((form, i) => {
              formsArr[i] = {
                value: form.id,
                label: form.name_en,
              }
            })
          setEvaluationForms([...formsArr])
        } else {
          setEvaluationForms([])
        }
      })
      .catch(err => {
        setEvaluationForms([])
      })
  }

  const getSessionTaskAndEvaaluators = () => {
    InternshipApi.getSessionTaskAndEvalauatorsApi(sessionId)
      .then(res => {
        if (res.data.success) {
          let sessiontasks = res.data.data.sessionTasks
          if (res.data.data.session.instituteHost)
            setSelectedInstituteHost(res.data.data.session.instituteHost)

          setSession(res.data.data.session)
          sessiontasks.map((task, i) => {
            let evaluatorArr = []
            task.taskEvaluators.map((evaluatorData, j) => {
              evaluatorArr.push(evaluatorData.value)
            })
            task.evaluators = evaluatorArr
            if (task.taskEvaluators.length > 0) {
              if (task.taskEvaluators[0].evaluationForm)
                task.evaluation_form_id =
                  task.taskEvaluators[0].evaluationForm.value
            }
          })
          setSelectedTaskTypes(sessiontasks)
          setSelectedInterns(res.data.data.interns)
          setShowEvaluators(!res.data.data.session.is_host ? true : false)
          let internArr = []
          res.data.data.interns.length > 0 &&
            res.data.data.interns.map((intern, i) => {
              internArr.push(intern.value)
            })
          setinterns([...internArr])
        } else {
          // setSelectedTaskTypes([])
        }
      })
      .catch(err => {
        console.log(err)

        setTaskypes([])
      })
  }

  const getAllMainTaskByInstitute = () => {
    const payload = {
      institute_id: instituteId,
      search: "",
    }
    InternshipApi.getAllMainTaskByInstitutes(payload)
      .then(res => {
        if (res.data.success) {
          let taskArr = []
          res.data.data.rows.length > 0 &&
            res.data.data.rows.map((task, i) => {
              taskArr[i] = {
                id: task.id,
                value: task.id,
                label: task.title_en,
              }
            })
          setTaskypes([...taskArr])
        } else {
          setTaskypes([])
        }
      })
      .catch(err => {
        setTaskypes([])
      })
  }

  const getAllDoctors = (type, searchKeyword = "") => {
    const payload = {
      speaker_type: type,
      search: searchKeyword,
      page: 1,
      limit: 10,
    }
    UserApi.getSpeakerUser(payload)
      .then(res => {
        if (res.data.success) {
          let doctorArr = []
          res.data.data.rows.length > 0 &&
            res.data.data.rows.map((doctor, i) => {
              doctorArr[i] = {
                value: doctor.id,
                label:
                  doctor.first_name +
                  " " +
                  (doctor.last_name ? doctor.last_name : ""),
              }
            })
          setInternsData([...doctorArr])
        } else {
          setInternsData([])
        }
      })
      .catch(err => {
        setInternsData([])
      })
  }

  const getAllInstitutes = (searchKeyword = "") => {
    if (searchKeyword !== "") {
      institutesPaginateData.search = searchKeyword
    } else {
      institutesPaginateData.search = ""
    }
    InstitutionApi.getAllInstitutesApi(institutesPaginateData)
      .then(res => {
        if (res.data.success) {
          setinstitutesData([...res.data.data.rows])
        } else {
          setinstitutesData([])
        }
      })
      .catch(err => {
        setinstitutesData([])
      })
  }

  const validationType = useFormik({
    enableReinitialize: true,
    validateOnBlur: false,
    initialValues: {
      session_id: sessionId,
      main_task_id: selectedTaskTypes ? selectedTaskTypes : "",
      interns: interns.length ? interns : "",
      // is_host: session ? session?.is_host : true,
      // institute_host_id: session ? session?.institute_host_id : "",
    },
    validationSchema: Yup.object().shape({
      main_task_id: Yup.array().required(props.t("Required-Message")),
      interns: Yup.array().required(props.t("Required-Message")),
      // institute_host_id: Yup.string().when("is_host", {
      //   is: false,
      //   then: Yup.string().required(props.t("Required-Message")),
      // }),
    }),
    onSubmit: values => {
      values.tasks = selectedTaskTypes
      console.log(values)
      InternshipApi.addSessionTaskAndEvalauatorsApi(values)
        .then(res => {
          if (res.data.success) {
            Swal.fire({
              text: res.data.message,
              icon: "success",
              imageAlt: "success image",
            }).then(result => {
              if (result?.isConfirmed) {
                history.push("/sessions")
              }
            })
          } else {
            toastr.error(res?.data?.message, "")
          }
        })
        .catch(function (error) {
          if (error?.response?.data?.message) {
            toastr.error(error?.response?.data?.message, "")
          } else {
            toastr.error(
              error?.response?.data?.error?.errors?.map(
                (item, v) => `${item.msg}<br>`
              ),
              ""
            )
          }
        })
    },
  })

  const onChangeTaskDetailhandler = (index, key, value, valueInArr = []) => {
    const selectedTaskTypesCopy = cloneDeep(selectedTaskTypes)
    if (key === "start_date") {
      selectedTaskTypesCopy[index].start_date = value
    } else if (key === "end_date") {
      selectedTaskTypesCopy[index].end_date = value
    } else if (key === "evaluators") {
      selectedTaskTypesCopy[index].evaluators = value
      selectedTaskTypesCopy[index].taskEvaluators = valueInArr
    } else if (key === "evaluation_form_id") {
      selectedTaskTypesCopy[index].evaluation_form_id = value
      selectedTaskTypesCopy[index].taskEvaluators[0].evaluationForm = valueInArr
    } else if (key === "is_host") {
      selectedTaskTypesCopy[index].is_host = value
    } else if (key === "institute_host_id") {
      selectedTaskTypesCopy[index].institute_host_id = value
      selectedTaskTypesCopy[index].instituteHost = valueInArr
    }
    setSelectedTaskTypes(selectedTaskTypesCopy)
  }

  const onSearchInternHandler = debounce(function (searchInput) {
    if (searchInput.length >= 2)
      getAllDoctors("INTERNAL", searchInput.toString().trim())
  }, 500)

  const onSearchInstitutesHandler = debounce(function (searchInput) {
    if (searchInput.length >= 2) getAllInstitutes(searchInput)
  }, 500)

  document.title = props.t("internshipSessions") + " | " + props.t("MedX")

  return (
    <React.Fragment>
      <div className="page-content">
        <Breadcrumb
          title={props.t("Internship")}
          breadcrumbItem={props.t("manageSession")}
        />
        <Container fluid={true}>
          <Row>
            <Col lg={12}>
              <Card>
                <CardBody>
                  <Col sm={12}>
                    <Form
                      onSubmit={e => {
                        e.preventDefault()
                        validationType.handleSubmit()
                        return false
                      }}
                    >
                      <Row>
                        <Col sm={12}>
                          <div className="mb-3">
                            <Label className="form-label">
                              {props.t("interns")}{" "}
                              <span className="text-danger">*</span>
                            </Label>
                            <Select
                              className={
                                validationType.touched.interns &&
                                validationType.errors.interns
                                  ? " bor"
                                  : " text-dark  "
                              }
                              styles={{
                                control: (provided, state) => ({
                                  ...provided,
                                  menuPortal: base => ({
                                    ...base,
                                    zIndex: 9999,
                                  }),
                                  borderColor:
                                    validationType.touched.institute_host_id &&
                                    validationType.errors.institute_host_id
                                      ? "#f46a6a"
                                      : "#ccc",
                                }),
                              }}
                              isMulti={true}
                              value={selectedInterns}
                              defaultValue={validationType.values.interns}
                              onBlur={validationType.handleBlur}
                              name="interns"
                              options={[...internsData]}
                              onChange={e => {
                                const f = e.map((item, index) => {
                                  return item.value
                                })
                                setinterns(f)
                                setSelectedInterns(e)
                              }}
                              onMenuOpen={getAllDoctors}
                              onInputChange={onSearchInternHandler}
                              placeholder={<div>{props.t("typeToSearch")}</div>}
                            />
                            {validationType.touched.interns &&
                            validationType.errors.interns ? (
                              <div
                                style={{
                                  color: "#f46a6a",
                                  fontSize: "11px",
                                  marginTop: "3px",
                                }}
                              >
                                {validationType.errors.interns}
                              </div>
                            ) : null}
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col sm={6}>
                          <div className="mb-3">
                            <Label className="form-label">
                              {props.t("task_type")}{" "}
                              <span className="text-danger">*</span>
                            </Label>
                            <Select
                              value={selectedTaskTypes}
                              onChange={e => {
                                const f = e.map((item, index) => {
                                  return item.value
                                })
                                validationType.setFieldValue("main_task_id", f)
                                setSelectedTaskTypes(e)
                              }}
                              options={taskTypes}
                              isMulti={true}
                              menuPortalTarget={document.body}
                              styles={{
                                control: (provided, state) => ({
                                  ...provided,
                                  menuPortal: base => ({
                                    ...base,
                                    zIndex: 9999,
                                  }),
                                  borderColor:
                                    validationType.touched.main_task_id &&
                                    validationType.errors.main_task_id
                                      ? "#f46a6a"
                                      : "#ccc",
                                }),
                              }}
                            />
                            {validationType.touched.main_task_id &&
                            validationType.errors.main_task_id ? (
                              <div
                                style={{
                                  color: "#f46a6a",
                                  fontSize: "11px",
                                  marginTop: "3px",
                                }}
                              >
                                {validationType.errors.main_task_id}
                              </div>
                            ) : null}
                          </div>
                        </Col>
                      </Row>
                      {selectedTaskTypes.length > 0 && (
                        <>
                          <h4>Fill following details about task</h4>
                        </>
                      )}
                      {selectedTaskTypes.length > 0 &&
                        selectedTaskTypes.map((selectedTask, index) => {
                          return (
                            <>
                              <Row key={selectedTask.label}>
                                <hr />
                                <Col sm={2}>
                                  <h5 className="">
                                    {index + 1}. {selectedTask.label}
                                  </h5>
                                </Col>
                                <Col sm={4}>
                                  <div className="mb-3">
                                    <Label className="form-label">
                                      {props.t("Start_Date")}{" "}
                                      <span className="text-danger">*</span>
                                    </Label>
                                    <Input
                                      name={`task_start_date_${selectedTask.value}`}
                                      type="date"
                                      value={selectedTask?.start_date || ""}
                                      onChange={e =>
                                        onChangeTaskDetailhandler(
                                          index,
                                          "start_date",
                                          e.target.value
                                        )
                                      }
                                    />
                                    {validationType.touched[
                                      `task_start_date_${selectedTask.value}`
                                    ] &&
                                    validationType.errors[
                                      `task_start_date_${selectedTask.value}`
                                    ] ? (
                                      <FormFeedback type="invalid">
                                        {
                                          validationType.errors[
                                            `task_start_date_${selectedTask.value}`
                                          ]
                                        }
                                      </FormFeedback>
                                    ) : null}
                                  </div>
                                </Col>
                                <Col sm={4}>
                                  <div className="mb-3">
                                    <Label className="form-label">
                                      {props.t("End_Date")}{" "}
                                      <span className="text-danger">*</span>
                                    </Label>
                                    <Input
                                      name={`task_end_date_${selectedTask.value}`}
                                      type="date"
                                      onChange={e =>
                                        onChangeTaskDetailhandler(
                                          index,
                                          "end_date",
                                          e.target.value
                                        )
                                      }
                                      value={selectedTask.end_date || ""}
                                    />
                                    {validationType.touched[
                                      `task_end_date_${selectedTask.value}`
                                    ] &&
                                    validationType.errors[
                                      `task_end_date_${selectedTask.value}`
                                    ] ? (
                                      <FormFeedback type="invalid">
                                        {
                                          validationType.errors[
                                            `task_end_date_${selectedTask.value}`
                                          ]
                                        }
                                      </FormFeedback>
                                    ) : null}
                                  </div>
                                </Col>
                                <Col sm={2}>
                                  <span className="caret">
                                    <div className="form-check">
                                      <input
                                        className="form-check-input"
                                        type="checkbox"
                                        name="is_host"
                                        checked={selectedTask?.is_host || false}
                                        onClick={e => {
                                          onChangeTaskDetailhandler(
                                            index,
                                            "is_host",
                                            !e.target.checked
                                          )
                                        }}
                                      />
                                      <Label className="form-label">
                                        {props.t("areYouInstitiuteHost")}
                                      </Label>
                                    </div>
                                  </span>
                                </Col>
                              </Row>
                              <Row>
                                {selectedTask.is_host ? (
                                  <>
                                    <Col sm={2}></Col>
                                    <Col sm={4}>
                                      <div className="mb-3">
                                        <Label className="form-label">
                                          {props.t("evaluators")}{" "}
                                          <span className="text-danger">*</span>
                                        </Label>
                                        <Select
                                          className={
                                            validationType.touched.interns &&
                                            validationType.errors.interns
                                              ? " bor"
                                              : " text-dark  "
                                          }
                                          styles={{
                                            control: styles => ({
                                              ...styles,
                                            }),
                                          }}
                                          isMulti={true}
                                          value={
                                            selectedTask?.taskEvaluators || ""
                                          }
                                          name="interns"
                                          options={[...internsData]}
                                          onChange={e => {
                                            const f = e.map((item, index) => {
                                              return item.value
                                            })
                                            onChangeTaskDetailhandler(
                                              index,
                                              "evaluators",
                                              f,
                                              e
                                            )
                                          }}
                                          onMenuOpen={getAllDoctors}
                                          onInputChange={onSearchInternHandler}
                                          placeholder={
                                            <div>{props.t("typeToSearch")}</div>
                                          }
                                        />
                                        {/* {validationType.touched.interns &&
                                      validationType.errors.interns ? (
                                        <div
                                          style={{
                                            color: "#f46a6a",
                                            fontSize: "11px",
                                            marginTop: "3px",
                                          }}
                                        >
                                          {validationType.errors.interns}
                                        </div>
                                      ) : null} */}
                                      </div>
                                    </Col>
                                    <Col sm={4}>
                                      <div className="mb-3">
                                        <Label className="form-label">
                                          {props.t("evaluationForm")}{" "}
                                          <span className="text-danger">*</span>
                                        </Label>
                                        <Select
                                          className={
                                            validationType.touched
                                              .evaluation_form_id &&
                                            validationType.errors
                                              .evaluation_form_id
                                              ? " bor"
                                              : " text-dark  "
                                          }
                                          styles={{
                                            control: styles => ({
                                              ...styles,
                                            }),
                                          }}
                                          value={
                                            selectedTask?.taskEvaluators?.length > 0 ? selectedTask?.taskEvaluators[0]
                                              ?.evaluationForm : ""
                                          }
                                          name="evaluation_form_id"
                                          options={[...evaluationForms]}
                                          onChange={e => {
                                            onChangeTaskDetailhandler(
                                              index,
                                              "evaluation_form_id",
                                              e.value,
                                              e
                                            )
                                          }}
                                          placeholder={
                                            <div>
                                              {props.t("selectEvaluation")}
                                            </div>
                                          }
                                        />
                                        {validationType.touched
                                          .evaluation_form_id &&
                                        validationType.errors
                                          .evaluation_form_id ? (
                                          <div
                                            style={{
                                              color: "#f46a6a",
                                              fontSize: "11px",
                                              marginTop: "3px",
                                            }}
                                          >
                                            {
                                              validationType.errors
                                                .evaluation_form_id
                                            }
                                          </div>
                                        ) : null}
                                      </div>
                                    </Col>
                                  </>
                                ) : (
                                  <>
                                    <Col sm={2}></Col>
                                    <Col sm={4}>
                                      <div className="mb-3">
                                        <Label className="form-label">
                                          {props.t("Institute")}{" "}
                                          <span className="text-danger">*</span>
                                        </Label>
                                        <Select
                                          className={
                                            validationType.touched
                                              .institute_host_id &&
                                            validationType.errors
                                              .institute_host_id
                                              ? "bor "
                                              : "text-dark"
                                          }
                                          styles={{
                                            control: (provided, state) => ({
                                              ...provided,
                                              menuPortal: base => ({
                                                ...base,
                                                zIndex: 9999,
                                              }),
                                              borderColor:
                                                validationType.touched
                                                  .institute_host_id &&
                                                validationType.errors
                                                  .institute_host_id
                                                  ? "#f46a6a"
                                                  : "#ccc",
                                            }),
                                          }}
                                          onChange={e => {
                                            // validationType.setFieldValue(
                                            //   "institute_host_id",
                                            //   e.value
                                            // )
                                            // setDefualtInstitute(e)
                                            onChangeTaskDetailhandler(
                                              index,
                                              "institute_host_id",
                                              e.value,
                                              e
                                            )
                                          }}
                                          value={selectedTask.instituteHost}
                                          onInputChange={
                                            onSearchInstitutesHandler
                                          }
                                          onMenuOpen={getAllInstitutes}
                                          name="institute_host_id"
                                          options={[...institutesData]}
                                          placeholder={
                                            <div>{props.t("typeToSearch")}</div>
                                          }
                                        />
                                        {/* {validationType.touched.institute_host_id &&
                              validationType.errors.institute_host_id ? (
                                <div
                                  style={{
                                    color: "#f46a6a",
                                    fontSize: "11px",
                                    marginTop: "3px",
                                  }}
                                >
                                  {validationType.errors.institute_host_id}
                                </div>
                              ) : null} */}
                                      </div>
                                    </Col>
                                  </>
                                )}
                              </Row>
                            </>
                          )
                        })}
                      {/* {selectedTaskTypes.length > 0 && <hr />} */}

                      {/* <Row>
                        <Col sm={6}>
                          <span className="caret">
                            <div className="form-check">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                name="is_host"
                                checked={validationType.values.is_host}
                                onClick={e => {
                                  validationType.setFieldValue(
                                    "is_host",
                                    !e.target.checked ? true : false
                                  )
                                  {
                                    e.target.checked
                                      ? setShowEvaluators(true)
                                      : setShowEvaluators(false)
                                  }
                                }}
                              />
                              <Label className="form-label">
                                {props.t("areYouInstitiuteHost")}
                              </Label>
                            </div>
                          </span>
                        </Col>
                      </Row> */}
                      {/* {!showEvaluators ? (
                        <>
                          {selectedTaskTypes.length > 0 && (
                            <>
                              <h4>Add evaluators for each task</h4>
                              <hr />
                            </>
                          )}
                          {selectedTaskTypes.length > 0 &&
                            selectedTaskTypes.map((selectedTask, index) => {
                              return (
                                <Row key={selectedTask.label}>
                                  <Col sm={2}>
                                    <h5 className="">
                                      {index + 1}. {selectedTask.label}
                                    </h5>
                                  </Col>
                                  <Col sm={5}>
                                    <div className="mb-3">
                                      <Label className="form-label">
                                        {props.t("evaluators")}{" "}
                                        <span className="text-danger">*</span>
                                      </Label>
                                      <Select
                                        className={
                                          validationType.touched.interns &&
                                          validationType.errors.interns
                                            ? " bor"
                                            : " text-dark  "
                                        }
                                        styles={{
                                          control: styles => ({
                                            ...styles,
                                          }),
                                        }}
                                        isMulti={true}
                                        value={
                                          selectedTask?.taskEvaluators || ""
                                        }
                                        onBlur={validationType.handleBlur}
                                        name="interns"
                                        options={[...internsData]}
                                        onChange={e => {
                                          const f = e.map((item, index) => {
                                            return item.value
                                          })
                                          onChangeTaskDetailhandler(
                                            index,
                                            "evaluators",
                                            f,
                                            e
                                          )
                                        }}
                                        onInputChange={onSearchInternHandler}
                                        placeholder={
                                          <div>{props.t("typeToSearch")}</div>
                                        }
                                      />
                                    </div>
                                  </Col>
                                  <Col sm={5}>
                                    <div className="mb-3">
                                      <Label className="form-label">
                                        {props.t("evaluationForm")}{" "}
                                        <span className="text-danger">*</span>
                                      </Label>
                                      <Select
                                        className={
                                          validationType.touched
                                            .evaluation_form_id &&
                                          validationType.errors
                                            .evaluation_form_id
                                            ? " bor"
                                            : " text-dark  "
                                        }
                                        styles={{
                                          control: styles => ({
                                            ...styles,
                                          }),
                                        }}
                                        value={
                                          selectedTask?.taskEvaluators[0]
                                            ?.evaluationForm || ""
                                        }
                                        // defaultValue={
                                        //   selectedTask?.taskEvaluators[0]
                                        //     ?.evaluationForm || ""
                                        // }
                                        name="evaluation_form_id"
                                        options={[...evaluationForms]}
                                        onChange={e => {
                                          onChangeTaskDetailhandler(
                                            index,
                                            "evaluation_form_id",
                                            e.value,
                                            e
                                          )
                                        }}
                                        placeholder={
                                          <div>
                                            {props.t("selectEvaluation")}
                                          </div>
                                        }
                                      />
                                      {validationType.touched
                                        .evaluation_form_id &&
                                      validationType.errors
                                        .evaluation_form_id ? (
                                        <div
                                          style={{
                                            color: "#f46a6a",
                                            fontSize: "11px",
                                            marginTop: "3px",
                                          }}
                                        >
                                          {
                                            validationType.errors
                                              .evaluation_form_id
                                          }
                                        </div>
                                      ) : null}
                                    </div>
                                  </Col>
                                </Row>
                              )
                            })}
                        </>
                      ) : (
                        <Row>
                          <Col sm={6}>
                            <div className="mb-3">
                              <Label className="form-label">
                                {props.t("Institute")}{" "}
                                <span className="text-danger">*</span>
                              </Label>
                              <Select
                                className={
                                  validationType.touched.institute_host_id &&
                                  validationType.errors.institute_host_id
                                    ? "bor "
                                    : "text-dark"
                                }
                                styles={{
                                  control: (provided, state) => ({
                                    ...provided,
                                    menuPortal: base => ({
                                      ...base,
                                      zIndex: 9999,
                                    }),
                                    borderColor:
                                      validationType.touched
                                        .institute_host_id &&
                                      validationType.errors.institute_host_id
                                        ? "#f46a6a"
                                        : "#ccc",
                                  }),
                                }}
                                onChange={e => {
                                  validationType.setFieldValue(
                                    "institute_host_id",
                                    e.value
                                  )
                                  setDefualtInstitute(e)
                                }}
                                value={selectedInstituteHost}
                                onInputChange={onSearchInstitutesHandler}
                                defaultValue={
                                  validationType.values.institute_host_id
                                }
                                onBlur={validationType.handleBlur}
                                name="institute_host_id"
                                options={[...institutesData]}
                                placeholder={
                                  <div>{props.t("typeToSearch")}</div>
                                }
                              />
                              {validationType.touched.institute_host_id &&
                              validationType.errors.institute_host_id ? (
                                <div
                                  style={{
                                    color: "#f46a6a",
                                    fontSize: "11px",
                                    marginTop: "3px",
                                  }}
                                >
                                  {validationType.errors.institute_host_id}
                                </div>
                              ) : null}
                            </div>
                          </Col>
                        </Row>
                      )} */}
                      <div className="d-flex flex-wrap gap-2">
                        <Button type="submit" color="primary">
                          {props.t("Submit")}
                        </Button>
                      </div>
                    </Form>
                  </Col>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withTranslation()(ManageSession)
