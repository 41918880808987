// src/components/filter.
import React, { useMemo, useEffect, useState } from "react";
import PropTypes from 'prop-types';

//import components
import Breadcrumbs from '../../../components/Common/Breadcrumb';
import TableContainer from '../../../components/Common/TableContainer';
import DataTable from 'react-data-table-component';
import { UserApi } from "../../../apis/UserApi";
import { useHistory } from "react-router-dom"
import { withTranslation } from "react-i18next";
import i18n from "../../../i18n";
import config from "../../../config/config";
import * as Yup from "yup";

import { useFormik } from "formik";

import { Link } from "react-router-dom";
import {
  Button,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  ButtonDropdown,
  Row,
  Col,
  Card,
  CardBody,
  FormGroup,
  Label,
  Input,
  Form,
  Container
} from "reactstrap";

import Swal from "sweetalert2";


function DoctorUser(props) {

  const history = useHistory();
  const [roles, setRoles] = useState([]);
  const [loading, setLoading] = useState(false);
  const [activeData, setActiveData] = useState({
    page: 1,
    totalPage: 1,
    search: "",
    limit: config.LIMIT,
  });

  const [licenseNumber, setLicenseNumber] = useState();
  const [autoSearch, setAutoSearchData] = useState([]);
  const [page, setCurrentPage] = useState(1);
  const [limit, setCurrentLimit] = useState(config.LIMIT);
  
  useEffect(() => {
    getAllDoctors(activeData);
  }, [page,limit]);

  const columns = [
    {
      name: props.t("Sr_No"),
      selector: row => row.sr_no,
      sort: "asc",
      width: 150,
      sortable: true,
      defaultSortField: true,
      defaultSortAsc: false
    },
    {
      name: props.t("Name"),
      selector: row => row.name,
      sort: "asc",
      width: 270,
      sortable: true,
      defaultSortField: true,
      defaultSortAsc: false
    },
    {
      name: props.t("Email"),
      selector: row => row.email,
      sort: "asc",
      width: 270,
      sortable: true,
      defaultSortField: true,
      defaultSortAsc: false
    },
    {
      name: props.t("License Number"),
      selector: row => row.license_number,
      sort: "asc",
      width: 270,
      sortable: true,
      defaultSortField: true,
      defaultSortAsc: false
    },
    {
      name: props.t("Phone"),
      selector: row => row.mobile,
      sort: "asc",
      width: 270,
      sortable: true,
      defaultSortField: true,
      defaultSortAsc: false
    },
    {
      name: props.t("Action"),
      selector: row => row.action,
      sort: "asc",
      width: 200,
    }
  ];



  const getAllDoctors = (data) => {
    setLoading(true);
    UserApi.getAllDoctor(data)
      .then((res) => {
        setActiveData({
          page: activeData.page,
          totalPage: res.data.data.count,
          search: activeData.search,
          limit: activeData.limit,
        });
        let data = [];
        var sr_no = res.data.sr_no_start;
        res.data.data.rows.forEach((v, i) => {
          sr_no = sr_no + 1;
          data[i] = {
            sr_no: sr_no,
            name: v.first_name,
            email: v.email,
            license_number: v.license_number,
            mobile: v.mobile,
            action: (
              <>
                <Link
                  style={{ marginRight: "15px" }}
                  to={`/edit-doctor/${v.id}`}
                >
                  <i className=" fas fa-edit" id="edittooltip" />
                </Link>
                <i className="mdi mdi-delete font-size-18" style={{ color: "red" }} id="deletetooltip" onClick={() => onDelete(v.id)} />
              </>
            ),
          };
        });
        setRoles(data);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const onDelete = (id) => {
    Swal.fire({
      title: props.t("Are_you_sure"),
      text: props.t("Able_to_revert_this"),
      icon: props.t("Warning"),
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#f46a6a",
      confirmButtonText: props.t("Yes_delete_it"),
      cancelButtonText: props.t("Cancel"),
    }).then((result) => {
      if (result.isConfirmed) {
        var user_id = {user_id:id};
        UserApi.deleteDoctor(user_id)
          .then((res) => {
            Swal.fire(props.t("Deleted"), res.data.message, props.t("Success"));
            handleChange(page);
            //getAllDoctors(activeData);
          })
          .catch((err) => {
            console.log(err);
          });
      }
    });
  };

  const handleChange = (v) => {
    setCurrentPage(v);
    setActiveData({ page: v, totalPage: activeData.totalPage, search: activeData.search, limit: limit });
    const data = { page: v, totalPage: activeData.totalPage, search: activeData.search, limit: limit }
    getAllDoctors(data)
  }


  const handleRowChange = (v) => {
    setCurrentLimit(v);
    setActiveData({ page: activeData.page, totalPage: activeData.totalPage, search: activeData.search, limit: v });
    const data = { page: activeData.page, totalPage: activeData.totalPage, search: activeData.search, limit: v }
    getAllDoctors(data)
  }


  //meta title
  document.title = props.t("Doctor") + ' | ' + props.t("MedX");

  // Form validation 
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      license_number: "",
    },
    validationSchema: Yup.object({
      //license_number: Yup.string().required(props.t("Required-Message")),
    }),
    onSubmit: (values) => {
      activeData.page = 1;
      activeData.totalPage = 1;
      activeData.search = values;
      activeData.limit = 10;
      setActiveData({ totalPage: 1, page: 1, search: values, limit: 10 });
      getAllDoctors(activeData);

    }
  });

  const OnSearchKeyUp = (e) => {
    setLicenseNumber(e.target.value);
    if(e.key == "Enter") {
      setActiveData({ page: activeData.page, totalPage: activeData.totalPage, search: e.target.value, limit: activeData.limit });
      const data = { page: activeData.page, totalPage: activeData.totalPage, search: e.target.value, limit: activeData.limit }
      getAllDoctors(data);
      setLicenseNumber(licenseNumber);
      setAutoSearchData([]);
      return false;
    }
  }
    
  const OnSearch = (e) => {
    let data = { keyword: e.target.value };
    setLicenseNumber(e.target.value);
    if(e.target.value != '') {
      UserApi.getDoctorNameByLicenseNumber(data)
        .then((res) => {
          if (res.data.success) {
            setAutoSearchData(res.data.data);
          } else {
            setAutoSearchData([]);
          }
        })
        .catch((err) => {
          console.log(err);
      });
    }  else {
      setAutoSearchData([]);
    }
    // setActiveData({ ...activeData, search: e.target.value })
  }

  const searchByLicenseNumber = (keyword) => {
    setActiveData({ page: activeData.page, totalPage: activeData.totalPage, search: keyword, limit: activeData.limit });
    const data = { page: activeData.page, totalPage: activeData.totalPage, search: keyword, limit: activeData.limit }
    getAllDoctors(data);
    setLicenseNumber(keyword);
    setAutoSearchData([]);
  }

  const searchRecord = () => {
    activeData.licenseNumber = (licenseNumber !== undefined) ? licenseNumber : "";
    setActiveData({ page: activeData.page, totalPage: activeData.totalPage, search: activeData.licenseNumber, limit: activeData.limit });
    const data = { page: activeData.page, totalPage: activeData.totalPage, search: activeData.licenseNumber, limit: activeData.limit }
    console.log(data);
    getAllDoctors(data);
    setLicenseNumber(licenseNumber);
  }


  return (
    <div className="page-content">
      <div className="container-fluid">
        <Breadcrumbs title={props.t("User")} breadcrumbItem={props.t("Doctors")} />
     
        <Container fluid={true}>
        <Row>
        <div className="d-flex ">

        <div className="wrapper w-40">
        <div className="search-input">
        <a href="" target="_blank" hidden="" />
        <Input type="text" defaultValue={licenseNumber} value={licenseNumber} onChange={(e) => OnSearch(e) } onKeyUp = {OnSearchKeyUp}  placeholder={props.t("License Number")}/>
        {autoSearch.length > 0 && licenseNumber != '' && (
        <div className="autocom-box">
        {autoSearch?.map((searchdata, index) => {
        return (<li key={index} onClick={() => searchByLicenseNumber(searchdata.license_number)}>{searchdata.license_number}</li>)
        })}
        </div>
        )}
        <div className="icon">
        <i className="fas fa-search" onClick={() => searchRecord()}/>
        </div>
        </div>
        </div>
        </div>

        <Col>
        <Link to="/add-doctor" className="btn btn-primary w-xs btn-right">{props.t("Add")}</Link>
        </Col>
        </Row></Container>
      </div>

      

      {/* <Form className="needs-validation"
        onSubmit={(e) => {
          e.preventDefault();
          validation.handleSubmit();
          return false;
        }}
      >
        <Row>

          <Col md="3">
            <FormGroup className="mb-3">
              <Label htmlFor="validationCustom01">
                {props.t("License Number")}
              </Label>
              <Input
                          name="license_number"
                          placeholder={props.t("License Number")}
                          type="text"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.license_number || ""}
                          invalid={
                            validation.touched.license_number &&
                            validation.errors.license_number
                              ? true
                              : false
                          }
                        />
              {validation.touched.license_number &&
                validation.errors.license_number ? (
                <div
                  style={{
                    color: "#f46a6a",
                    fontSize: "11px",
                    marginTop: "3px",
                  }}
                >
                  {validation.errors.license_number}
                </div>
              ) : null}
            </FormGroup>
          </Col>

          <Col md="3" className="mt-4" style={{ paddingTop: 5 }}>
            <Button color="primary"
            >
              {props.t("Search")}
            </Button>
          </Col>

        </Row>
      </Form> */}

      <div className="container-fluid">
        <DataTable
          className="table-bordered"
          progressPending={loading}
          columns={columns}
          data={roles}
          pagination
          paginationServer
          paginationTotalRows={activeData.totalPage}
          paginationPerPage={activeData.limit}
          defaultSortFieldID={1}
          onChangeRowsPerPage={value => handleRowChange(value)}
          onChangePage={value => handleChange(value)}
          sortable
          noHeader
          defaultSortField="id"
          defaultSortAsc={false}
          highlightOnHover
        />
      </div>
    </div>
  );
}
DoctorUser.propTypes = {
  t: PropTypes.any,
  preGlobalFilteredRows: PropTypes.any,

};
export default withTranslation()(DoctorUser);