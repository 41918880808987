import React, { useEffect, useState } from "react";
import {
    Row,
    Col,
    Card,
    CardBody,
    FormGroup,
    Button,
    CardTitle,
    CardSubtitle,
    Label,
    Input,
    Container,
    FormFeedback,
    Form,
} from "reactstrap";
// Formik validation
import * as Yup from "yup";
import { useFormik } from "formik";


import toastr from "toastr";
import "toastr/build/toastr.min.css";
import PropTypes from 'prop-types';
// import { RoleApi } from "../../apis/RoleApi";
// import { RoleModuleApi } from "../../apis/RoleModuleApi";

import { useHistory } from "react-router-dom"
//Import Breadcrumb
// import Breadcrumbs from "../../components/Common/Breadcrumb";
import Swal from "sweetalert2";
import { withTranslation } from "react-i18next";
// import i18n from "../../i18n";
// import constants from "../../config/constants";
import config from "config/config";

import { OfferTypeApi } from "apis/OfferTypeApi";
import { OfferApi } from "apis/OfferApi";
import moment from "moment";
import { CSpeakerApi } from "apis/CompaniesApi";
import Breadcrumb from "components/Common/Breadcrumb";
import { SpeakerApi } from "apis/SpeakerApi";
import { Base64 } from "Token";


const EditSpeaker = props => {
    const speaker_id = props.match.params.speaker_id;
    const [files1, setFiles1] = useState([]);
    const [speaker, setSpeaker] = useState('')

    const history = useHistory();
    const [offerTypeList, setOfferTypeList] = useState([]);
    const [loading, setLoading] = useState(false);

    const [activeData, setActiveData] = useState({
        page: 1,
        totalPage: 1,
        search: "",
        limit: config.LIMIT
    });




    const getSpeakerById = () => {

        const newId = Base64.encode(speaker_id)
        // console.log(newId)
        SpeakerApi.getSpeakerById(newId)
        .then(res => {
            //   alert(33)
            // console.log('rolessss',res.data);
            setSpeaker(res.data.speaker)
          }).catch(err => {
            console.log(err)
          })
      }
      

    useEffect(() => {
        getSpeakerById();
        // getAllOfferTypes(activeData);
    }, []);

    // Form validation 
    const validationType = useFormik({
        // enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: true,

        initialValues: {
            first_name: speaker.first_name  ??'',
            last_name: speaker.last_name ??'',
            email: speaker.email ??'',
            mobile: speaker.mobile ??'',

        },
        validationSchema: Yup.object().shape({
            first_name: Yup.string().required(
                props.t("Required-Message")
            ),
            last_name: Yup.string().required(
                props.t("Required-Message")
            ),
            email: Yup.string().email().required(
                props.t("Required-Message")
            ),
            mobile: Yup.number().required(
                props.t("Required-Message")
            ),            
        }),
        onSubmit: (values) => {
       const speaker = {

        speaker_id : speaker_id,
        ...values
       }
            SpeakerApi.updateSpeaker(speaker)
                    .then(res => {
                        if (res.data.success) {
                            Swal.fire({
                                text: res.data.message,
                                icon: 'success',
                                imageAlt: 'success image',
                            }).then((result) => {
                                if (result?.isConfirmed) {
                                    history.push('/speaker');
                                    formClear()
                                    setFiles1()
                                }
                            });
                        } else {
                            toastr.error(res?.data?.message, '');
                        }
                    }).catch(function (error) {

                        if (error?.response?.data?.message) {
                            toastr.error(error?.response?.data?.message, '');
                        }
                        else {
                            toastr.error(error?.response?.data?.error?.errors?.map((item, v) => `${item.msg}<br>`), '');
                        }
                    });
            

        }
    });
    

    const formClear = () => {
        validationType.resetForm();
       
    };



    document.title = props.t("Speaker") + ' | ' + props.t("MedX");

    return (
        <React.Fragment>
            <div className="page-content">
                <Breadcrumb title={props.t("Speaker")} breadcrumbItem={props.t("Edit Speaker")} />
                <Container fluid={true}>
                    <Row>

                        <Col lg={12}>
                            <Card>
                                <CardBody >
                                    <Col sm={12}>
                                        <Form
                                            onSubmit={(e) => {
                                                e.preventDefault();
                                                validationType.handleSubmit();
                                                return false;
                                            }}>
                                            <Col sm={12}>
                                                <div className="mb-3">
                                                    <Label className="form-label">{props.t("First Name")}</Label>
                                                    <Input
                                                        name="first_name"
                                                        placeholder={props.t("First Name")}
                                                        type="text"
                                                        onChange={validationType.handleChange}
                                                        onBlur={validationType.handleBlur}
                                                        value={validationType.values.first_name || ""}
                                                        invalid={
                                                            validationType.touched.first_name && validationType.errors.first_name ? true : false
                                                        }
                                                    />
                                                    {validationType.touched.first_name && validationType.errors.first_name ? (
                                                        <FormFeedback type="invalid">{validationType.errors.first_name}</FormFeedback>
                                                    ) : null}
                                                </div>
                                            </Col>
                                            <Col sm={12}>
                                                <div className="mb-3">
                                                    <Label className="form-label">{props.t("Last Name")}</Label>
                                                    <Input
                                                        name="last_name"
                                                        placeholder={props.t("Last Name")}
                                                        type="text"
                                                        onChange={validationType.handleChange}
                                                        onBlur={validationType.handleBlur}
                                                        value={validationType.values.last_name || ""}
                                                        invalid={
                                                            validationType.touched.last_name && validationType.errors.last_name ? true : false
                                                        }
                                                    />
                                                    {validationType.touched.last_name && validationType.errors.last_name ? (
                                                        <FormFeedback type="invalid">{validationType.errors.last_name}</FormFeedback>
                                                    ) : null}
                                                </div>
                                            </Col>
                                           
                                            <Col sm={12}>
                                                <div className="mb-3">
                                                    <Label className="form-label">{props.t("Email")}</Label>
                                                    <Input
                                                        name="email"
                                                        placeholder={props.t("Enter Email")}
                                                        type="email"
                                                        onChange={validationType.handleChange}
                                                        onBlur={validationType.handleBlur}
                                                        value={validationType.values.email || ""}
                                                        invalid={
                                                            validationType.touched.email && validationType.errors.email ? true : false
                                                        }
                                                    />
                                                    {validationType.touched.email && validationType.errors.email ? (
                                                        <FormFeedback type="invalid">{validationType.errors.email}</FormFeedback>
                                                    ) : null}
                                                </div>
                                            </Col>
                                            <Col sm={12}>
                                                <div className="mb-3">
                                                    <Label className="form-label">{props.t("Phone No")}</Label>
                                                    <Input
                                                        name="mobile"
                                                        placeholder={props.t("Enter Phone No")}
                                                        type="number"
                                                        onChange={validationType.handleChange}
                                                        onBlur={validationType.handleBlur}
                                                        value={validationType.values.mobile || ""}
                                                        invalid={
                                                            validationType.touched.mobile && validationType.errors.mobile ? true : false
                                                        }
                                                    />
                                                    {validationType.touched.mobile && validationType.errors.mobile ? (
                                                        <FormFeedback type="invalid">{validationType.errors.mobile}</FormFeedback>
                                                    ) : null}
                                                </div>
                                            </Col>
                              
                                            




                                            <div className="d-flex flex-wrap gap-2">
                                                <Button type="submit" color="primary" >
                                                    {props.t("Submit")}
                                                </Button>{" "}
                                                {/* <Button color="secondary" onClick={e => formClear()}>
                                                    {props.t("Clear")}
                                                </Button> */}
                                            </div>
                                        </Form>
                                    </Col>

                                </CardBody>
                            </Card>
                        </Col>

                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
};


EditSpeaker.propTypes = {
    t: PropTypes.any,
};

export default withTranslation()(EditSpeaker);


