import { InternshipApi } from "apis/InternshipApi"
import Breadcrumb from "components/Common/Breadcrumb"
import { useFormik } from "formik"
import React, { useEffect, useState } from "react"
import { withTranslation } from "react-i18next"
import { useHistory } from "react-router-dom"
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Form,
  FormFeedback,
  Input,
  Label,
  Row,
} from "reactstrap"
import Swal from "sweetalert2"
import toastr from "toastr"
import "toastr/build/toastr.min.css"
import * as Yup from "yup"

const AddSession = props => {
  const history = useHistory()
  const [session, setSession] = useState()

  useEffect(() => {
    if (history.location.state !== undefined) {
      const { copyId } = history.location.state
      if (copyId !== undefined) getSessionDetails(copyId)
    }
  }, [])

  const getSessionDetails = copyId => {
    InternshipApi.getSessionDetailApi(copyId)
      .then(res => {
        if (res.data.success) setSession(res.data.data)
      })
      .catch(err => {
        console.log(err)
      })
  }
  const validationType = useFormik({
    enableReinitialize: true,
    validateOnBlur: false,
    initialValues: {
      title_en: session ? session.title_en : "",
      title_th: session ? session.title_th : "",
      description_en: session ? session.description_en : "",
      description_th: session ? session.description_th : "",
      start_date: session ? session.start_date : "",
      end_date: session ? session.end_date : "",
    },
    validationSchema: Yup.object().shape({
      title_en: Yup.string().required(props.t("Required-Message")),
      title_th: Yup.string().required(props.t("Required-Message")),
      start_date: Yup.date().required(props.t("Required-Message")),
      end_date: Yup.date().required(props.t("Required-Message")),
    }),
    onSubmit: values => {
      const data = new FormData()
      data.append("title_en", values.title_en)
      data.append("title_th", values.title_th)
      data.append("description_en", values.description_en)
      data.append("description_th", values.description_th)
      data.append("start_date", values.start_date)
      data.append("end_date", values.end_date)

      InternshipApi.createSessionApi(data)
        .then(res => {
          if (res.data.success) {
            Swal.fire({
              text: res.data.message,
              icon: "success",
              imageAlt: "success image",
            }).then(result => {
              if (result?.isConfirmed) {
                formClear()
                history.push("/sessions")
              }
            })
          } else {
            toastr.error(res?.data?.message, "")
          }
        })
        .catch(function (error) {
          if (error?.response?.data?.message) {
            toastr.error(error?.response?.data?.message, "")
          } else {
            toastr.error(
              error?.response?.data?.error?.errors?.map(
                (item, v) => `${item.msg}<br>`
              ),
              ""
            )
          }
        })
    },
  })

  const formClear = () => {
    validationType.resetForm()
  }
  document.title = props.t("internshipSessions") + " | " + props.t("MedX")

  return (
    <React.Fragment>
      <div className="page-content">
        <Breadcrumb
          title={props.t("Internship")}
          breadcrumbItem={props.t("createSessions")}
        />
        <Container fluid={true}>
          <Row>
            <Col lg={12}>
              <Card>
                <CardBody>
                  <Col sm={12}>
                    <Form
                      onSubmit={e => {
                        e.preventDefault()
                        validationType.handleSubmit()
                        return false
                      }}
                    >
                      <Row>
                        <Col sm={6}>
                          <div className="mb-3">
                            <Label className="form-label">
                              {props.t("Title_Thai")}{" "}
                              <span className="text-danger">*</span>
                            </Label>
                            <Input
                              name="title_th"
                              placeholder={props.t("Title_Thai")}
                              type="text"
                              onChange={validationType.handleChange}
                              onBlur={validationType.handleBlur}
                              value={validationType.values.title_th || ""}
                              invalid={
                                validationType.touched.title_th &&
                                validationType.errors.title_th
                                  ? true
                                  : false
                              }
                            />
                            {validationType.touched.title_th &&
                            validationType.errors.title_th ? (
                              <FormFeedback type="invalid">
                                {validationType.errors.title_th}
                              </FormFeedback>
                            ) : null}
                          </div>
                        </Col>
                        <Col sm={6}>
                          <div className="mb-3">
                            <Label className="form-label">
                              {props.t("Title_Eng")}{" "}
                              <span className="text-danger">*</span>
                            </Label>

                            <Input
                              name="title_en"
                              placeholder={props.t("Title_Eng")}
                              type="text"
                              onChange={validationType.handleChange}
                              onBlur={validationType.handleBlur}
                              value={validationType.values.title_en || ""}
                              invalid={
                                validationType.touched.title_en &&
                                validationType.errors.title_en
                                  ? true
                                  : false
                              }
                            />
                            {validationType.touched.title_en &&
                            validationType.errors.title_en ? (
                              <FormFeedback type="invalid">
                                {validationType.errors.title_en}
                              </FormFeedback>
                            ) : null}
                          </div>
                        </Col>
                        <Col sm={6}>
                          <div className="mb-3">
                            <Label className="form-label">
                              {props.t("Description_Th")}
                            </Label>
                            <Input
                              name="description_th"
                              placeholder={props.t("Description_Th")}
                              type="textarea"
                              autoComplete="off"
                              onChange={validationType.handleChange}
                              onBlur={validationType.handleBlur}
                              value={validationType.values.description_th || ""}
                              invalid={
                                validationType.touched.description_th &&
                                validationType.errors.description_th
                                  ? true
                                  : false
                              }
                            />
                            {validationType.touched.description_th &&
                            validationType.errors.description_th ? (
                              <FormFeedback type="invalid">
                                {validationType.errors.description_th}
                              </FormFeedback>
                            ) : null}
                          </div>
                        </Col>
                        <Col sm={6}>
                          <div className="mb-3">
                            <Label className="form-label">
                              {props.t("Description_En")}
                            </Label>
                            <Input
                              name="description_en"
                              placeholder={props.t("Description_En")}
                              type="textarea"
                              autoComplete="off"
                              onChange={validationType.handleChange}
                              onBlur={validationType.handleBlur}
                              value={validationType.values.description_en || ""}
                              invalid={
                                validationType.touched.description_en &&
                                validationType.errors.description_en
                                  ? true
                                  : false
                              }
                            />
                            {validationType.touched.description_en &&
                            validationType.errors.description_en ? (
                              <FormFeedback type="invalid">
                                {validationType.errors.description_en}
                              </FormFeedback>
                            ) : null}
                          </div>
                        </Col>
                        <Col sm={6}>
                          <div className="mb-3">
                            <Label className="form-label">
                              {props.t("Start_Date")}{" "}
                              <span className="text-danger">*</span>
                            </Label>
                            <Input
                              name="start_date"
                              type="date"
                              onChange={validationType.handleChange}
                              onBlur={validationType.handleBlur}
                              value={validationType.values.start_date || ""}
                              invalid={
                                validationType.touched.start_date &&
                                validationType.errors.start_date
                                  ? true
                                  : false
                              }
                            />
                            {validationType.touched.start_date &&
                            validationType.errors.start_date ? (
                              <FormFeedback type="invalid">
                                {validationType.errors.start_date}
                              </FormFeedback>
                            ) : null}
                          </div>
                        </Col>
                        <Col sm={6}>
                          <div className="mb-3">
                            <Label className="form-label">
                              {props.t("End_Date")}{" "}
                              <span className="text-danger">*</span>
                            </Label>
                            <Input
                              name="end_date"
                              type="date"
                              onChange={validationType.handleChange}
                              onBlur={validationType.handleBlur}
                              value={validationType.values.end_date || ""}
                              invalid={
                                validationType.touched.end_date &&
                                validationType.errors.end_date
                                  ? true
                                  : false
                              }
                            />
                            {validationType.touched.end_date &&
                            validationType.errors.end_date ? (
                              <FormFeedback type="invalid">
                                {validationType.errors.end_date}
                              </FormFeedback>
                            ) : null}
                          </div>
                        </Col>
                      </Row>
                      <div className="d-flex flex-wrap gap-2">
                        <Button type="submit" color="primary">
                          {props.t("Submit")}
                        </Button>
                      </div>
                    </Form>
                  </Col>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withTranslation()(AddSession)
