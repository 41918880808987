// src/components/filter.
import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"

//import components
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import DataTable from "react-data-table-component"
import { BannerApi } from "../../../apis/BannerApi"
import { useHistory } from "react-router-dom"
import { withTranslation } from "react-i18next"
import * as Yup from "yup"
import { useFormik } from "formik"
import toastr from "toastr"
import config from "config/config"
import constants from "../../../config/constants"

import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Form,
  Label,
  Input,
  FormFeedback,
  Button,
} from "reactstrap"

import Swal from "sweetalert2"

function Banner(props) {
  const history = useHistory()
  const [files1, setFiles1] = useState([])
  const [bannerList, setBannerList] = useState([])
  const [fetchBanner, setFetchBanner] = useState([])
  const [postBannerId, setBannerId] = useState("")
  const [loading, setLoading] = useState(false)
  const [isFormSubmited, setIsFormSubmited] = useState(false)
  const [activeData, setActiveData] = useState({
    page: 1,
    totalPage: 1,
    search: "",
    limit: config.LIMIT,
  })
  const [page, setCurrentPage] = useState(1)

  useEffect(() => {
    getAllBanner(activeData)
  }, [page])

  const formClear = () => {
    validationType.resetForm()
    setFiles1([])
    setFetchBanner()
  }

  const columns = [
    {
      name: props.t("Sr_No"),
      selector: row => row.banner_id,
      sort: "asc",
      width: 50,
      sortable: true,
      defaultSortField: true,
      defaultSortAsc: false,
    },
    {
      name: props.t("Title_Eng"),
      selector: row => row.title_en,
      sort: "asc",
      width: 200,
      sortable: true,
      defaultSortField: true,
      defaultSortAsc: false,
    },
    {
      name: props.t("Title_Thai"),
      selector: row => row.title_th,
      sort: "asc",
      width: 200,
      sortable: true,
      defaultSortField: true,
      defaultSortAsc: false,
    },
    {
      name: props.t("Type"),
      selector: row => row.type,
      sort: "asc",
      width: 100,
      sortable: true,
      defaultSortField: true,
      defaultSortAsc: false,
    },
    {
      name: props.t("Page_Name"),
      selector: row => row.page_name,
      sort: "asc",
      width: 150,
      sortable: true,
      defaultSortField: true,
      defaultSortAsc: false,
    },
    {
      name: props.t("Position"),
      selector: row => row.position,
      sort: "asc",
      width: 100,
      sortable: true,
      defaultSortField: true,
      defaultSortAsc: false,
    },
    {
      name: props.t("Image"),
      selector: row => row.photo,
      sort: "asc",
      width: 270,
      sortable: true,
      defaultSortField: true,
      defaultSortAsc: false,
    },
    {
      name: props.t("Rank"),
      selector: row => row.rank,
      sort: "asc",
      width: 50,
      sortable: true,
      defaultSortField: true,
      defaultSortAsc: false,
    },
    {
      name: props.t("Status"),
      selector: row => row.status,
      sort: "asc",
      width: 150,
      sortable: true,
      defaultSortField: true,
      defaultSortAsc: false,
    },
    {
      name: props.t("Action"),
      selector: row => row.action,
      sort: "asc",
      width: 200,
    },
  ]

  const getAllBanner = data => {
    setLoading(true)
    BannerApi.getAllBanners(data)
      .then(res => {
        setActiveData({
          page: activeData.page,
          totalPage: res?.data?.data?.count,
          search: activeData.search,
          limit: activeData.limit,
        })
        let data = []
        var sr_no = res.data.sr_no_start

        res?.data?.data?.rows?.forEach((v, i) => {
          sr_no = sr_no + 1
          data[i] = {
            banner_id: sr_no,
            title_en: v.title_en,
            title_th: v.title_th,
            type: v.type,
            page_name: v.page_name,
            position: v.position,
            photo: (
              <>
                <img className=" header-profile-user" src={`${v.image_url}`} />
              </>
            ),
            rank: v.rank,
            status:
              v.status == 1 ? (
                <>
                  <span style={{ color: "green" }}>{props.t("Active")}</span>
                </>
              ) : (
                <>
                  <span style={{ color: "red" }}>{props.t("Deactive")}</span>
                </>
              ),
            action: (
              <>
                <i
                  className="fas fa-edit"
                  id="deletetooltip"
                  style={{ color: "blue" }}
                  onClick={() => onEdit(v.id)}
                />{" "}
                <i
                  className="mdi mdi-delete font-size-18"
                  style={{ color: "red" }}
                  id="deletetooltip"
                  onClick={() => onDelete(v.id)}
                />
              </>
            ),
          }
        })
        setBannerList(data)
        setLoading(false)
      })
      .catch(err => {
        console.log(err)
      })
  }

  const onDelete = id => {
    Swal.fire({
      title: props.t("Are_you_sure"),
      text: props.t("Able_to_revert_this"),
      icon: props.t("Warning"),
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#f46a6a",
      confirmButtonText: props.t("Yes_delete_it"),
      cancelButtonText: props.t("Cancel"),
    }).then(result => {
      if (result.isConfirmed) {
        let BannerId = { banner_id: id }
        BannerApi.deleteBanner(BannerId)
          .then(res => {
            Swal.fire(props.t("Deleted"), res.data.message, props.t("Success"))
            setBannerId()
            formClear()
            handleChange(page)
            //getAllBanner(activeData)
          })
          .catch(err => {
            console.log(err)
          })
      }
    })
  }

  const onEdit = id => {
    let BannerId = { banner_id: id }
    setBannerId(id)
    BannerApi.getBannerById(BannerId)
      .then(res => {
        setFetchBanner(res.data.data)
      })
      .catch(err => {
        console.log(err)
      })
  }
  // Form validation
  const validationType = useFormik({
    enableReinitialize: true,
    initialValues: {
      banner_id: fetchBanner ? fetchBanner.id : "",
      title_en: fetchBanner ? fetchBanner.title_en : "",
      title_th: fetchBanner ? fetchBanner.title_th : "",
      banner_link: fetchBanner ? fetchBanner.banner_link : "",
      image_url: fetchBanner ? fetchBanner.image_url : "",
      type: fetchBanner?.type ? fetchBanner.type : "Banner",
      page_name: fetchBanner?.page_name ? fetchBanner.page_name : "Home",
      position: fetchBanner?.position ? fetchBanner.position : "Top",
      rank: fetchBanner ? fetchBanner.rank : 1,
      status:
        fetchBanner?.status === 1 ? "1" : fetchBanner?.status === 0 ? "0" : "1",
    },
    validationSchema: Yup.object().shape({
      title_en: Yup.string().required(props.t("Required-Message")),
      title_th: Yup.string().required(props.t("Required-Message")),
      image_url: Yup.string().required(props.t("Required-Message")),
      banner_link: Yup.string().url().required(props.t("Required-Message")),
      status: Yup.string().required(props.t("Required-Message")),
      type: Yup.string().required(props.t("Required-Message")),
      page_name: Yup.string().required(props.t("Required-Message")),
      position: Yup.string().required(props.t("Required-Message")),
      rank: Yup.number()
        .positive()
        .min(1)
        .required(props.t("Required-Message")),
    }),
    onSubmit: values => {
      const data = new FormData()
      data.append("image_url", files1)
      data.append("title_en", values.title_en)
      data.append("title_th", values.title_th)
      data.append("banner_id", postBannerId)
      data.append("banner_link", values.banner_link)
      data.append("type", values.type)
      data.append("page_name", values.page_name)
      data.append("position", values.position)
      data.append("rank", values.rank)
      data.append("status", values.status)
      if (postBannerId) {
        values.banner_id = postBannerId
        BannerApi.updateBanner(data)
          .then(res => {
            if (res.data.success) {
              Swal.fire({
                text: res.data.message,
                icon: "success",
                imageAlt: "success image",
              }).then(result => {
                if (result.isConfirmed) {
                  history.push("/banners")
                  setBannerId()
                  formClear()
                  setFiles1()
                  getAllBanner(activeData)
                }
              })
            } else {
              toastr.error(res.message, "")
            }
          })
          .catch(function (error) {
            if (error?.response?.data?.message) {
              toastr.error(error?.response?.data?.message, "")
            } else {
              toastr.error(
                error?.response?.data?.error?.errors?.map(
                  (item, v) => `${item.msg}<br>`
                ),
                ""
              )
            }
          })
      } else {
        BannerApi.createBanner(data)
          .then(res => {
            if (res.data.success) {
              Swal.fire({
                text: res.data.message,
                icon: "success",
                imageAlt: "success image",
              }).then(result => {
                if (result?.isConfirmed) {
                  history.push("/banners")
                  formClear()
                  setFiles1()
                  getAllBanner(activeData)
                }
              })
            } else {
              toastr.error(res?.data?.message, "")
            }
          })
          .catch(function (error) {
            if (error?.response?.data?.message) {
              toastr.error(error?.response?.data?.message, "")
            } else {
              toastr.error(
                error?.response?.data?.error?.errors?.map(
                  (item, v) => `${item.msg}<br>`
                ),
                ""
              )
            }
          })
      }
    },
  })

  const handleChange = v => {
    setCurrentPage(v)
    setActiveData({
      page: v,
      totalPage: activeData.totalPage,
      search: activeData.search,
      limit: activeData.limit,
    })
    const data = {
      page: v,
      totalPage: activeData.totalPage,
      search: activeData.search,
      limit: activeData.limit,
    }
    getAllBanner(data)
  }

  const handleRowChange = v => {
    setActiveData({
      page: activeData.page,
      totalPage: activeData.totalPage,
      search: activeData.search,
      limit: v,
    })
    const data = {
      page: activeData.page,
      totalPage: activeData.totalPage,
      search: activeData.search,
      limit: v,
    }
    getAllBanner(data)
  }

  useEffect(() => {
    if (isFormSubmited) {
      const { errors } = validationType
      const keys = Object.keys(errors)
      if (keys.length > 0) {
        const errorElement = document.getElementById(keys[0])
        errorElement.focus()
        setIsFormSubmited(false)
      }
    }
  }, [isFormSubmited, validationType])

  //meta title
  document.title = props.t("Banner") + " | " + props.t("MedX")

  return (
    <div className="page-content">
      <div className="container-fluid">
        <Breadcrumbs
          title={props.t("Master")}
          breadcrumbItem={props.t("Banner")}
        />
      </div>
      <Container fluid={false}>
        <Row>
          <Col lg={8}>
            <Card>
              <CardBody>
                <Col sm={12}>
                  <DataTable
                    className="table-bordered"
                    progressPending={loading}
                    columns={columns}
                    data={bannerList}
                    pagination
                    paginationServer
                    paginationTotalRows={activeData.totalPage}
                    paginationPerPage={activeData.limit}
                    defaultSortFieldID={1}
                    onChangeRowsPerPage={value => handleRowChange(value)}
                    onChangePage={value => handleChange(value)}
                    sortable
                    noHeader
                    defaultSortField="id"
                    defaultSortAsc={false}
                    highlightOnHover
                  />
                </Col>
              </CardBody>
            </Card>
          </Col>
          <Col lg={4}>
            <Card>
              <CardBody>
                <Col sm={12}>
                  <Form
                    onSubmit={e => {
                      e.preventDefault()
                      validationType.handleSubmit()
                      setIsFormSubmited(true)
                      return false
                    }}
                  >
                    <Col sm={12}>
                      <div className="mb-3">
                        <Label className="form-label">
                          {props.t("Title_Eng")}
                        </Label>
                        <Input
                          name="title_en"
                          id="title_en"
                          placeholder={props.t("Title_Eng")}
                          type="text"
                          onChange={validationType.handleChange}
                          onBlur={validationType.handleBlur}
                          value={validationType.values.title_en || ""}
                          invalid={
                            validationType.touched.title_en &&
                            validationType.errors.title_en
                              ? true
                              : false
                          }
                        />
                        {validationType.touched.title_en &&
                        validationType.errors.title_en ? (
                          <FormFeedback type="invalid">
                            {validationType.errors.title_en}
                          </FormFeedback>
                        ) : null}
                      </div>
                    </Col>
                    <Col sm={12}>
                      <div className="mb-3">
                        <Label className="form-label">
                          {props.t("Title_Thai")}
                        </Label>
                        <Input
                          name="title_th"
                          id="title_th"
                          placeholder={props.t("Title_Thai")}
                          type="text"
                          onChange={validationType.handleChange}
                          onBlur={validationType.handleBlur}
                          value={validationType.values.title_th || ""}
                          invalid={
                            validationType.touched.title_th &&
                            validationType.errors.title_th
                              ? true
                              : false
                          }
                        />
                        {validationType.touched.title_th &&
                        validationType.errors.title_th ? (
                          <FormFeedback type="invalid">
                            {validationType.errors.title_th}
                          </FormFeedback>
                        ) : null}
                      </div>
                    </Col>
                    <Col sm={12}>
                      <div className="mb-3">
                        <Label htmlFor="validationCustom01">
                          {props.t("Upload_Banner_Image")}
                        </Label>
                        <Input
                          name="image_url"
                          id="image_url"
                          type="file"
                          className="form-control"
                          onChange={e => {
                            setFiles1(e.target.files[0])
                            validationType.setFieldValue(
                              "image_url",
                              e.target.files[0]
                            )
                          }}
                          invalid={
                            validationType.touched.image_url &&
                            validationType.errors.image_url
                              ? true
                              : false
                          }
                        />
                        {validationType.touched.image_url &&
                        validationType.errors.image_url ? (
                          <div
                            style={{
                              color: "#f46a6a",
                              fontSize: "11px",
                              marginTop: "3px",
                            }}
                          >
                            {validationType.errors.image_url}
                          </div>
                        ) : null}
                      </div>
                    </Col>
                    <Col sm={12}>
                      <div className="mb-3">
                        <Label className="form-label">
                          {props.t("Banner_Link")}
                        </Label>
                        <Input
                          name="banner_link"
                          id="banner_link"
                          placeholder={props.t("Banner_Link")}
                          type="text"
                          onChange={validationType.handleChange}
                          onBlur={validationType.handleBlur}
                          value={validationType.values.banner_link || ""}
                          invalid={
                            validationType.touched.banner_link &&
                            validationType.errors.banner_link
                              ? true
                              : false
                          }
                        />
                        {validationType.touched.banner_link &&
                        validationType.errors.banner_link ? (
                          <FormFeedback type="invalid">
                            {validationType.errors.banner_link}
                          </FormFeedback>
                        ) : null}
                      </div>
                    </Col>
                    <Col sm={12}>
                      <div className="mb-3">
                        <Label className="form-label">
                          {props.t("Status")}
                        </Label>
                        <select
                          name="status"
                          type="select"
                          className="form-control"
                          onChange={validationType.handleChange}
                          onBlur={validationType.handleBlur}
                          value={validationType.values.status || ""}
                        >
                          {Object.keys(constants.STATUS).map(key => {
                            return (
                              <option value={key} key={key}>
                                {constants.STATUS[key]}
                              </option>
                            )
                          })}
                        </select>
                        {validationType.touched.status &&
                        validationType.errors.status ? (
                          <div
                            style={{
                              color: "#f46a6a",
                              fontSize: "11px",
                              marginTop: "3px",
                            }}
                          >
                            {validationType.errors.status}
                          </div>
                        ) : null}
                      </div>
                    </Col>
                    <Col sm={12}>
                      <div className="mb-3">
                        <Label className="form-label">{props.t("Type")}</Label>
                        <select
                          name="type"
                          id="type"
                          type="select"
                          className="form-control"
                          onChange={validationType.handleChange}
                          onBlur={validationType.handleBlur}
                          value={validationType.values.type || ""}
                        >
                          {Object.keys(constants.BANNER_TYPE).map(key => {
                            return (
                              <option value={key} key={key}>
                                {constants.BANNER_TYPE[key]}
                              </option>
                            )
                          })}
                        </select>
                        {validationType.touched.type &&
                        validationType.errors.type ? (
                          <div
                            style={{
                              color: "#f46a6a",
                              fontSize: "11px",
                              marginTop: "3px",
                            }}
                          >
                            {validationType.errors.type}
                          </div>
                        ) : null}
                      </div>
                    </Col>
                    <Col sm={12}>
                      <div className="mb-3">
                        <Label className="form-label">
                          {props.t("Page_Name")}
                        </Label>
                        <select
                          name="page_name"
                          id="page_name"
                          type="select"
                          className="form-control"
                          onChange={validationType.handleChange}
                          onBlur={validationType.handleBlur}
                          value={validationType.values.page_name || ""}
                        >
                          {Object.keys(constants.PAGE_NAME).map(key => {
                            return (
                              <option value={key} key={key}>
                                {constants.PAGE_NAME[key]}
                              </option>
                            )
                          })}
                        </select>
                        {validationType.touched.page_name &&
                        validationType.errors.page_name ? (
                          <div
                            style={{
                              color: "#f46a6a",
                              fontSize: "11px",
                              marginTop: "3px",
                            }}
                          >
                            {validationType.errors.page_name}
                          </div>
                        ) : null}
                      </div>
                    </Col>
                    <Col sm={12}>
                      <div className="mb-3">
                        <Label className="form-label">
                          {props.t("Position")}
                        </Label>
                        <select
                          name="position"
                          id="position"
                          type="select"
                          className="form-control"
                          onChange={validationType.handleChange}
                          onBlur={validationType.handleBlur}
                          value={validationType.values.position || ""}
                        >
                          {Object.keys(constants.BANNER_POSITIONS).map(key => {
                            return (
                              <option value={key} key={key}>
                                {constants.BANNER_POSITIONS[key]}
                              </option>
                            )
                          })}
                        </select>
                        {validationType.touched.position &&
                        validationType.errors.position ? (
                          <div
                            style={{
                              color: "#f46a6a",
                              fontSize: "11px",
                              marginTop: "3px",
                            }}
                          >
                            {validationType.errors.position}
                          </div>
                        ) : null}
                      </div>
                    </Col>
                    <Col sm={12}>
                      <div className="mb-3">
                        <Label className="form-label">{props.t("Rank")}</Label>
                        <Input
                          name="rank"
                          id="rank"
                          placeholder={props.t("Rank")}
                          type="text"
                          onChange={validationType.handleChange}
                          onBlur={validationType.handleBlur}
                          value={validationType.values.rank || ""}
                          invalid={
                            validationType.touched.rank &&
                            validationType.errors.rank
                              ? true
                              : false
                          }
                        />
                        {validationType.touched.rank &&
                        validationType.errors.rank ? (
                          <FormFeedback type="invalid">
                            {validationType.errors.rank}
                          </FormFeedback>
                        ) : null}
                      </div>
                    </Col>
                    <div className="d-flex flex-wrap gap-2">
                      <Button type="submit" color="primary">
                        {props.t("Submit")}
                      </Button>{" "}
                      <Button color="secondary" onClick={e => formClear()}>
                        {props.t("Clear")}
                      </Button>
                    </div>
                  </Form>
                </Col>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  )
}
Banner.propTypes = {
  t: PropTypes.any,
  preGlobalFilteredRows: PropTypes.any,
}
export default withTranslation()(Banner)
