module.exports = {
  //API_URL: "https://api.medxdev.online",
  // API_URL: "http://medxdev.online:8003",
  API_URL: "https://api.medxcorporation.com",
  // API_URL: "http://localhost:8002",
  // API_URL: "https://api.medxcorporation.com",
  //API_URL: "http://localhost:8002",
  LIMIT:10,
  google: {
    API_KEY: "AIzaSyAnT1QYVcj2rsVI4A54LJoU0QNh1w33DPw",
    CLIENT_ID: "",
    SECRET: "",
  },
  facebook: {
    APP_ID: "",
  },
}