// src/components/filter.
import React, { useEffect, useState } from "react";
import PropTypes from 'prop-types';

//import components
import Breadcrumbs from '../../../components/Common/Breadcrumb';
import DataTable from 'react-data-table-component';
import { InterestApi } from "../../../apis/InterestApi";
import { LeaveReasonApi } from "apis/LeaveReasonApi";
import { useHistory } from "react-router-dom"
import { withTranslation } from "react-i18next";
import * as Yup from "yup";
import { useFormik } from "formik";
import toastr from "toastr";
import config from "config/config";
import constants from "../../../config/constants";
import * as url from "../../../helpers/common_helper"
import moment from "moment";

import {
    Container,
    Row,
    Col,
    Card,
    CardBody,
    Form,
    Label,
    Input,
    FormFeedback,
    Button
} from "reactstrap";

import Swal from "sweetalert2";
import { LeaveApi } from "apis/LeaveApi";

function Leave(props) {
    const history = useHistory();
    const [leavesList, setLeavesList] = useState([]);
    const [loading, setLoading] = useState(false);
    const [limit, setCurrentLimit] = useState(config.LIMIT);

    const [activeData, setActiveData] = useState({
        page: 1,
        totalPage: 1,
        search: "",
        limit: config.LIMIT
    });

    useEffect(() => {
        getAllLeaves(activeData);
    }, [limit]);

    const columns = [
        {
            name: props.t("Sr_No"),
            selector: row => row.leave_id,
            sort: "asc",
            width: 150,
            sortable: true,
            defaultSortField: true,
            defaultSortAsc: false
        },
        {
            name: props.t("User"),
            selector: row => row.user_name,
            sort: "asc",
            width: 270,
            sortable: true,
            defaultSortField: true,
            defaultSortAsc: false
        },
        {
            name: props.t("Reason"),
            selector: row => row.reason_type,
            sort: "asc",
            width: 270,
            sortable: true,
            defaultSortField: true,
            defaultSortAsc: false
        },
        // {
        //     name: props.t("Type"),
        //     selector: row => row.type,
        //     sort: "asc",
        //     width: 270,
        //     sortable: true,
        //     defaultSortField: true,
        //     defaultSortAsc: false
        // },
        {
            name: props.t("From_Date"),
            selector: row => row.from_date,
            sort: "asc",
            width: 270,
            sortable: true,
            defaultSortField: true,
            defaultSortAsc: false
        },
        {
            name: props.t("To_Date"),
            selector: row => row.to_date,
            sort: "asc",
            width: 270,
            sortable: true,
            defaultSortField: true,
            defaultSortAsc: false
        },
        {
            name: props.t("Status"),
            selector: row => row.status,
            sort: "asc",
            width: 270,
            sortable: true,
            defaultSortField: true,
            defaultSortAsc: false
        },
        {
            name: props.t("Action"),
            selector: row => row.action,
            sort: "asc",
            width: 200,
        }
    ];

    const getAllLeaves = (data) => {
        setLoading(true);
        LeaveApi.getAllLeaves(data)
            .then((res) => {
                setActiveData({
                    page: activeData.page,
                    totalPage: res?.data?.data?.count,
                    search: activeData.search,
                    limit: activeData.limit,
                });
                let data = [];
                var sr_no = 0;

                res?.data?.data?.rows?.forEach((v, i) => {
                    sr_no = sr_no + 1;
                    data[i] = {
                        leave_id: sr_no,
                        user_name: v.UserInformation.username,
                        reason_type: v.subject,
                        type: v.type,
                        from_date: url.dateFormat(v.from_date),
                        to_date: url.dateFormat(v.to_date),
                        description: v.description,
                        comment: v.comment,
                        status: v.status,
                        action: (
                            <>
                                { v.status !== constants.LEAVE_STATUS.Canceled ? (
                                    <a href={`/edit-leave/${v.id}`}><i className="fas fa-edit" id="deletetooltip" style={{ color: "blue" }} /></a>
                                ) : "" }
                            </>
                        ),
                    };
                });
                setLeavesList(data);
                setLoading(false);
            })
            .catch((err) => {
                console.log(err);
            });
    };
    
    const handleChange = (v) => {
        setActiveData({ page: v, totalPage: activeData.totalPage, search: activeData.search, limit: limit });
        const data = { page: v, totalPage: activeData.totalPage, search: activeData.search, limit: limit }
        getAllLeaves(data)
    }
    const handleRowChange = (v) => {
        setCurrentLimit(v);
        setActiveData({ page: activeData.page, totalPage: activeData.totalPage, search: activeData.search, limit: v });
        const data = { page: activeData.page, totalPage: activeData.totalPage, search: activeData.search, limit: v }
        getAllLeaves(data)
    }

    //meta title
    document.title = props.t("Leave") + ' | ' + props.t("MedX");

    return (
        <div className="page-content">
            <div className="container-fluid">
                <Breadcrumbs title={props.t("Master")} breadcrumbItem={props.t("Leave")} />
            </div>
            <Container fluid={false}>
                <Row>
                    <Col lg={12}>
                        <Card>
                            <CardBody >
                                <Col sm={12}>
                                    <DataTable
                                        className="table-bordered"
                                        progressPending={loading}
                                        columns={columns}
                                        data={leavesList}
                                        pagination
                                        paginationServer
                                        paginationTotalRows={activeData.totalPage}
                                        paginationPerPage={activeData.limit}
                                        defaultSortFieldID={1}
                                        onChangeRowsPerPage={value => handleRowChange(value)}
                                        onChangePage={value => handleChange(value)}
                                        sortable
                                        noHeader
                                        defaultSortField="id"
                                        defaultSortAsc={false}
                                        highlightOnHover
                                    />
                                </Col>

                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </div>
    );
}
Leave.propTypes = {
    t: PropTypes.any,
    preGlobalFilteredRows: PropTypes.any,

};
export default withTranslation()(Leave);