let constants = {
  STATUS: {
    1: "Active",
    0: "Deactive",
  },
  ACTIVITY_STATUS: {
    1: "Active",
    0: "Activate Later",
    2: "Schedule publish date and time",
  },
  IS_MICROSITE: {
    1: "TRUE",
    0: "FALSE",
  },
  TYPE: {
    Online: "Online",
    Onsite: "Onsite",
    Hybrid: "Hybrid",
  },
  FEE_TYPE: {
    PAID: "PAID",
    FREE: "FREE",
  },
  ATTENDEE_LIMIT: {
    LIMITED: "LIMITED",
    UNLIMITED_MEMEBER: "UNLIMITED_MEMEBER",
  },
  CATEGORY_PARENT_TYPE: {
    community: "Community",
    offers: "Offers",
    company: "Company",
  },
  CATEGORY_TYPE: {
    INFOCUS: "INFOCUS",
    NEWS: "NEWS",
    "D-CONNECT": "D-CONNECT",
    COMPANY: "COMPANY",
  },
  EDUCATION_TYPE: {
    CME: "Accredited Course",
    CPD: "Webinars",
    Multimedia: "Multimedia",
  },
  SPEAKER_TYPE: {
    EXTERNAL: "EXTERNAL",
    INTERNAL: "INTERNAL",
  },
  ACTIVITY_GROUP: {
    1: "1",
    2: "2",
  },
  CUSTOM_ACTIVITY_GROUP: {
    3: "3",
    4: "4",
    5: "5",
  },
  BANNER_TYPE: {
    Banner: "Banner",
    Slider: "Slider",
  },
  PAGE_NAME: {
    Home: "Home",
    Community: "Community",
    "Community-In-Focus": "Community In Focus",
    "Community-News": "Community News",
    "Community-Blog": "Community Dr. Connect",
    Education: "Education",
    EducationActivityDetails: "Education Activity Detail",
    Offer: "Offer",
  },
  BANNER_POSITIONS: {
    Top: "Top",
    Bottom: "Bottom",
    TopRight: "TopRight",
    TopLeft: "TopLeft",
    BottomRight: "BottomRight",
    BottomLeft: "BottomLeft",
  },

  INSTITUTE_INDEX_TYPES: {
    Primary: "Primary",
    Subinstitute: "Subinstitute",
  },
  SCORE_TYPE: {
    CONTENT_PROVIDING: 1,
    GIVING_ADVISE: 2,
    DISPLAY_CONTENT_PROVIDING: 3,
    CONTENT_TYPE: 4,
    FILE_ATTACHMENT: 5,
    ATTENDING_ACTIVITY: 6,
    TASK_COMPLETION: 7,
    FOUR_LEVEL_EVALUATION: 8,
    ONE_YEAR_ROTATION: 9,
    ROTATION_CERTIFICATE: 10,
    EXAM_WITH_QUIZ: 11,
  },
  ROLE: {
    SUPERADMIN: 1,
    CME: 2,
    INSTITUTE: 3,
    SUBINSTITUTE: 4,
    DOCTOR: 5,
    INTERN: 6,
    SPEAKER: 7,
    PHARMACTICAL: 8,
    COMPANY: 9,
    MDCONNECT: 10,
  },
  ASSIGN_EVALUATION_TYPE: ["HOD", "MSF", "Director"],
  IMAGE_VALIDATION: {
    ACTIVITY_IMAGE: 2097152,
    PRODUCT_IMAGE: 2097152,
    ACTIVITY_PDF: 10485760
  },
  REQUEST_DOCTOR_STATUS: {
    APPROVED: "APPROVED",
    PENDING: "PENDING",
    REJECTED: "REJECTED",
  },
  REQUEST_DOCTOR_SEARCH_STATUS: {
    PENDING: "PENDING",
    REJECTED: "REJECTED",
  },

  LEAVE_STATUS: {
    Approved: "Approved",
    Rejected: "Rejected",
    Canceled: "Canceled",
  },
  CONTENT_TYPE: {
    SHORT: "SHORT",
    LONG: "LONG"
  },
}

export default constants
