import config from "../config/config"
import axios from "axios"
import { getToken } from "../Token"
import * as url from "../helpers/url_helper"

const token = getToken()
export const ReportApi = {
  getLoginHostory: async data => {
    var axiosConfig = {
      method: "post",
      url: `${config.API_URL}${url.LOGIN_HOSTORY}`,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      data: data,
    }

    return await axios(axiosConfig)
  },
  getLoginHistoryWithoutLimit: async data => {
    var axiosConfig = {
      method: "post",
      url: `${config.API_URL}${url.LOGIN_HISTORY_WITHOUT_LIMIT}`,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      data: data,
    }

    return await axios(axiosConfig)
  },
  getRegisteredInstitutes: async data => {
    const axiosConfig = {
      method: "post",
      url: `${config.API_URL}${url.REGISTERED_INSTITUTES}`,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      data: data,
    }
    return await axios(axiosConfig)
  },
  getRegisteredSubInstitutes: async data => {
    const axiosConfig = {
      method: "post",
      url: `${config.API_URL}${url.REGISTERED_SUB_INSTITUTES}`,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      data: data,
    }
    return await axios(axiosConfig)
  },
  getActivitiesReports: async data => {
    const axiosConfig = {
      method: "post",
      url: `${config.API_URL}${url.ACTIVITIES_REPORTS}`,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      data: data,
    }
    return await axios(axiosConfig)
  },
  getApprovedDoctors: async data => {
    const axiosConfig = {
      method: "post",
      url: `${config.API_URL}${url.APPROVED_DOCTORS}`,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      data: data,
    }
    return await axios(axiosConfig)
  },
}
