import config from "config/config";
import axios from "axios"
import { getToken } from "Token";
import * as url from "helpers/url_helper";


const token = getToken();
export const CommunityApi = {
    
    getAllNews: async (data) => {
        var axiosConfig = {
            method: 'post',
            url: `${config.API_URL}${url.POST_FETCH_ALL_NEWS}`,
            headers: {
                'Authorization': `Bearer ${token}`,
                
                'Content-Type': 'application/json'
            },
            data: data
        };

        return await axios(axiosConfig);
    },
    getCommunityByName: async (data) => {
        var axiosConfig = {
            method: 'post',
            url: `${config.API_URL}${url.POST_FETCH_COMMUNITY_BY_NAME}`,
            headers: {
                'Authorization': `Bearer ${token}`,
                
                'Content-Type': 'application/json'
            },
            data: data
        };

        return await axios(axiosConfig);
    },
    getCommunityNewsByName: async (data) => {
        var axiosConfig = {
            method: 'post',
            url: `${config.API_URL}${url.POST_FETCH_COMMUNITY_NEWS_BY_NAME}`,
            headers: {
                'Authorization': `Bearer ${token}`,
                
                'Content-Type': 'application/json'
            },
            data: data
        };

        return await axios(axiosConfig);
    },
    getAllCommunity: async (data) => {
        var axiosConfig = {
            method: 'post',
            url: `${config.API_URL}${url.POST_FETCH_ALL_COMMUNITY}`,
            headers: {
                'Authorization': `Bearer ${token}`,
                
                'Content-Type': 'application/json'
            },
            data: data
        };

        return await axios(axiosConfig);
    },
    createCommunity: async (data) => {
        var axiosConfig = {
            method: 'post',
            url: `${config.API_URL}${url.POST_CREATE_COMMUNITY}`,
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'multipart/form-data'
            },
            data: data
        };

        return await axios(axiosConfig);
    },
    getCommunityById: async (data) => {
        var axiosConfig = {
            method: 'get',
            url: `${config.API_URL}${url.POST_FETCH_COMMUNITY}/${data.community_id}`,
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            data: data
        };
       
        return await axios(axiosConfig);
    },
    updateCommunity: async (data) => {
        var axiosConfig = {
            method: 'post',
            url: `${config.API_URL}${url.POST_UPDATE_COMMUNITY}`,
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'multipart/form-data'
            },
            data: data
        };

        return await axios(axiosConfig);
    },
    deleteCommunity: async (data) => {
        // console.log(data);
        var axiosConfig = {
            method: 'post',
            url: `${config.API_URL}${url.POST_DELETE_COMMUNITY}/${data.community_id}`,
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            data: data
        };

        return await axios(axiosConfig);
    },
    deleteImage: async (data) => {
        var axiosConfig = {
            method: 'post',
            url: `${config.API_URL}${url.POST_IMAGE_DELETE_COMMUNITY}`,
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            data: data
        };

        return await axios(axiosConfig);
    },
}