import React from "react"
import { Redirect } from "react-router-dom"

// Authentication related pages
import Login from "../pages/Authentication/Login"
import Logout from "../pages/Authentication/Logout"
import Register from "../pages/Authentication/Register"
import ForgetPwd from "../pages/Authentication/ForgetPassword"
import UpdatePassword from "pages/Authentication/UpdatePassword"

//  // Inner Authentication
import Login1 from "../pages/AuthenticationInner/Login"
import Login2 from "../pages/AuthenticationInner/Login2"
import Register1 from "../pages/AuthenticationInner/Register"
import Register2 from "../pages/AuthenticationInner/Register2"
import Recoverpw from "../pages/AuthenticationInner/Recoverpw"
import Recoverpw2 from "../pages/AuthenticationInner/Recoverpw2"
import ForgetPwd1 from "../pages/AuthenticationInner/ForgetPassword"
import ForgetPwd2 from "../pages/AuthenticationInner/ForgetPassword2"
import LockScreen from "../pages/AuthenticationInner/auth-lock-screen"
import LockScreen2 from "../pages/AuthenticationInner/auth-lock-screen-2"
import ConfirmMail from "../pages/AuthenticationInner/page-confirm-mail"
import ConfirmMail2 from "../pages/AuthenticationInner/page-confirm-mail-2"
import EmailVerification from "../pages/AuthenticationInner/auth-email-verification"
import EmailVerification2 from "../pages/AuthenticationInner/auth-email-verification-2"
import TwostepVerification from "../pages/AuthenticationInner/auth-two-step-verification"
import TwostepVerification2 from "../pages/AuthenticationInner/auth-two-step-verification-2"
import ViewInstitutions from "pages/Institution/ViewInstitutions"
import ViewSubInstitution from "pages/SubInstitution/ViewSubInstitution"

// Dashboard
import Dashboard from "../pages/Dashboard/index"

import ProfileRoles from "../pages/Profile/Role/Roles"
import ProfileAddRoles from "../pages/Profile/Role/AddRoles"
import ProfileEditRoles from "../pages/Profile/Role/EditRoles"
import Permission from "../pages/Profile/Role/Permission"

import Institution from "../pages/Institution/Institutions"
import RequestInstitutions from "../pages/Institution/RequestInstitution/RequestInstitutions"
import ViewRequestInstitutions from "../pages/Institution/RequestInstitution/ViewRequestInstitutions"

import SubInstitutions from "../pages/SubInstitution/SubInstitutions"
import RequestSubInstitutions from "../pages/SubInstitution/RequestSubInstitution/RequestSubInstitutions"
import ViewRequestSubInstitutions from "../pages/SubInstitution/RequestSubInstitution/ViewRequestSubInstitutions"

import ProgramActivities from "../pages/Program/Activity/Activities"
import AddActivity from "../pages/Program/Activity/AddActivity"
import EditActivity from "../pages/Program/Activity/EditActivity"

import ProgramEvents from "../pages/Program/Events"
import ProgramLibraries from "../pages/Program/Libraries"

import SettingSpecializations from "../pages/Setting/Specializations"
import SettingBanners from "../pages/Master/Banner/Banners"
import SettingEducationTypes from "../pages/Setting/EducationTypes"
import SettingNews from "../pages/Setting/News"
import SettingPageContent from "../pages/Setting/PageContent"
import SystemSetting from "../pages/Setting/SystemSetting/SystemSettings"

import UserUsers from "../pages/User/Users"
import UserPrograms from "../pages/User/UserPrograms"
import UserScores from "../pages/User/UserScores"
import UserBookings from "../pages/User/UserBookings"
import UserBookmarks from "../pages/User/UserBookmarks"
import UserLibraries from "../pages/User/UserLibraries"
import UserActivities from "../pages/User/UserActivities"

import MarketingOffers from "../pages/Marketing/Offers"
import MarketingServices from "../pages/Marketing/Services"
import MarketingProdcuts from "../pages/Marketing/Products"

import UpdateProfile from "../pages/Profile/UserProfile/UpdateProfile"
import ChangePassword from "../pages/Profile/UserProfile/ChangePassword"

import Interests from "../pages/Master/Interest/Interests"
import OfferTypes from "../pages/Master/OfferType/OfferType"
import Specialists from "../pages/Master/Specialist/Specialist"

import OrganizationTypes from "pages/Master/OrganizationType/OrganizationType"
import ServiceTypes from "pages/Master/ServiceType/ServiceType"
import ActivityTypes from "pages/Master/ActivityType/ActivityType"
import CustomActivityTypes from "pages/Master/CustomActivityType/CustomActivityTypes"
import AddCustomActivityTypes from "pages/Master/CustomActivityType/AddCustomActivityTypes"
import Provinces from "pages/Master/Province/Province"
import Districts from "pages/Master/District/District"
import SubDistricts from "pages/Master/SubDistrict/SubDistrict"
import Categories from "pages/Master/Category/Category"
import EditPageContent from "pages/Setting/EditPageContent"

import CommunityPosts from "../pages/Community/CommunityPosts"

import MarktingProducts from "../pages/Marketing/Products"
import MarktingOffers from "../pages/Marketing/Offers"
import AddOffer from "pages/Marketing/AddOffer"
import EditOffer from "pages/Marketing/EditOffer"
import MasterCompanies from "pages/Master/Companies/Companies"
import AddCompany from "pages/Master/Companies/AddCompany"
import EditCompany from "pages/Master/Companies/EditCompany"
import ManageCompanyTemplate from "pages/Master/Companies/ManageCompanyTemplate"
import MasterSpeaker from "pages/Master/Speaker/Speaker"
import AddSpeaker from "pages/Master/Speaker/AddSpeaker"
import EditSpeaker from "pages/Master/Speaker/EditSpeaker"
import CommunityForm from "pages/Community/CommunityForm"
import EditCommunity from "pages/Community/EditCommunity"
import EditCustomActivityTypes from "pages/Master/CustomActivityType/EditCustomActivityTypes"

import QuizTypes from "pages/Master/QuizTypes/QuizTypes"
import ScoreTypes from "pages/Master/ScoreTypes/ScoreTypes"
// import CustomActivityTypes from "pages/Master/CustomActivityTypes/CustomActivityTypes";
import ActivityDisplayTypes from "pages/Master/ActivityDisplayTypes/ActivityDisplayTypes"
import ActivityContentTypes from "pages/Master/ActivityContentTypes/ActivityContentTypes"
import TaskTypes from "pages/Master/TaskTypes/TaskTypes"
import AttachmentTypes from "pages/Master/AttachmentTypes/AttachmentTypes"
import InstituteIndex from "pages/Master/InstituteIndex/InstituteIndex"
import NotificationTypes from "pages/Master/NotificationTypes/NotificationTypes"
import ContentLengthScore from "pages/Master/ContentLengthScore/ContentLengthScore"
import EvalutionForm from "pages/Master/EvalutionForm/EvalutionForm"
import ViewEvalutionForm from "pages/Master/EvalutionForm/ViewEvalutionForm"
import ActivityRequests from "pages/Program/ActivityRequests/ActivityRequests"
import LeaveReason from "pages/Master/LeaveReason/LeaveReason"
import Leave from "pages/Master/Leave/Leave"
import EditLeave from "pages/Master/Leave/EditLeave"

import Products from "pages/Products/Products"
import AddProduct from "pages/Products/AddProduct"
import EditProduct from "pages/Products/EditProduct"
import DoctorUser from "../pages/User/Doctor/DoctorUser";
import AddDoctor from "../pages/User/Doctor/AddDoctor";
import EditDoctor from "../pages/User/Doctor/EditDoctor";


import RequestDoctor from "../pages/User/RequestDoctor/RequestDoctor";
import ViewRequestDoctor from "../pages/User/RequestDoctor/ViewRequestDoctor";

import LoginHistory from "pages/Reports/LoginHistory"
import MainTaskTypes from "pages/Internship/MainTaskTypes"
import Sessions from "pages/Internship/Sessions"
import AddSession from "pages/Internship/AddSession"
import EditSession from "pages/Internship/EditSession"
import ManageSession from "pages/Internship/ManageSession"
import UserEvaluations from "pages/Internship/UserEvaluations"
import EvaluationList from "pages/Internship/EvaluationList"
import AddEvaluators from "pages/Internship/AddEvaluators"

import CommunityNews from "pages/Community/CommunityNews"
import EditCommunityNews from "pages/Community/EditCommunityNews"
import RegistredInstitutes from "pages/Reports/RegistredInstitutes"
import RegistredSubInstitutes from "pages/Reports/RegistredSubInstitutes"
import Activities from "pages/Reports/Activities"
import ApprovedDoctors from "pages/Reports/ApprovedDoctors"



const authProtectedRoutes = [
  { path: "/dashboard", component: Dashboard },

  //Marketing
  // { path: "/products", component: MarktingProducts },
  { path: "/offers", component: MarktingOffers },
  { path: "/add-offer", component: AddOffer },
  { path: "/edit-offer/:offer_id", component: EditOffer },

  //Products
  { path: "/products", component: Products },
  { path: "/add-product", component: AddProduct },
  { path: "/edit-product/:product_id", component: EditProduct },

  //community news
  { path: "/news", component: CommunityNews },
  { path: "/edit-news/:community_id", component: EditCommunityNews },


  //community posts
  { path: "/posts", component: CommunityPosts },
  { path: "/add-posts", component: CommunityForm },
  { path: "/edit-posts/:community_id", component: EditCommunity },

  //companies
  { path: "/companies", component: MasterCompanies },
  { path: "/add-company", component: AddCompany },
  { path: "/edit-company/:company_id", component: EditCompany },
  {
    path: "/manage-company-temlate/:company_id",
    component: ManageCompanyTemplate,
  },

  // Speaker
  { path: "/speaker", component: MasterSpeaker },
  { path: "/add-speaker", component: AddSpeaker },
  { path: "/edit-speaker/:speaker_id", component: EditSpeaker },

  // Profile
  { path: "/roles", component: ProfileRoles },
  { path: "/add-role", component: ProfileAddRoles },
  { path: "/edit-role/:role_id", component: ProfileEditRoles },
  { path: "/permission/:role_id", component: Permission },

  //Institutions
  { path: "/institutions", component: Institution },

  { path: "/request-institutions", component: RequestInstitutions },
  { path: "/request-sub-institutions", component: RequestSubInstitutions },

  {
    path: "/view-request-institute/:request_institute_id",
    component: ViewRequestInstitutions,
  },

  { path: "/sub-institutions", component: SubInstitutions },

  { path: "/request-sub-institutions", component: RequestSubInstitutions },
  {
    path: "/view-request-sub-institute/:request_institute_id",
    component: ViewRequestSubInstitutions,
  },
  {
    path: "/view-get-institute/:request_institute_id",
    component: ViewInstitutions,
  },

  {
    path: "/view-sub-institute/:request_institute_id",
    component: ViewSubInstitution,
  },

  //Programs
  { path: "/activities", component: ProgramActivities },
  { path: "/add-activity", component: AddActivity },
  { path: "/edit-activity/:activity_id", component: EditActivity },

  { path: "/events", component: ProgramEvents },
  { path: "/libraries", component: ProgramLibraries },

  { path: "/activity-requests", component: ActivityRequests },

  //Settings
  { path: "/specializations", component: SettingSpecializations },
  { path: "/banners", component: SettingBanners },
  { path: "/education-types", component: SettingEducationTypes },
  { path: "/news", component: SettingNews },
  { path: "/page-content", component: SettingPageContent },
  // { path: "/add-cms-page", component: EditPageContent },
  { path: "/edit-cms-page/:cms_page_id", component: EditPageContent },
  { path: "/system-settings", component: SystemSetting },

  //Marketing
  { path: "/offers", component: MarketingOffers },
  { path: "/services", component: MarketingServices },
  { path: "/prodcuts", component: MarketingProdcuts },



  //doctors
  { path: "/doctors", component: DoctorUser },
  { path: "/add-doctor", component: AddDoctor },
  { path: "/edit-doctor/:user_id", component: EditDoctor },


  //request doctor
  { path: "/request-doctors", component: RequestDoctor },
  { path: "/view-request-doctor/:request_doctor_id", component: ViewRequestDoctor },


  //Users
  { path: "/users", component: UserUsers },
  { path: "/user-programs", component: UserPrograms },
  { path: "/user-score", component: UserScores },
  { path: "/user-bookings", component: UserBookings },
  { path: "/user-bookmark", component: UserBookmarks },
  { path: "/user-activities", component: UserActivities },
  { path: "/user-libraries", component: UserLibraries },

  // Non Module Route
  { path: "/update-profile", component: UpdateProfile },
  { path: "/change-password", component: ChangePassword },

  // this route should be at the end of all other routes
  // eslint-disable-next-line react/display-name
  { path: "/", exact: true, component: () => <Redirect to="/dashboard" /> },

  // Interests
  { path: "/interests", component: Interests },

  // OfferTypes
  { path: "/offer-types", component: OfferTypes },

  // Specialists
  { path: "/specialists", component: Specialists },

  // OrganizationTypes
  { path: "/organisation-types", component: OrganizationTypes },

  // ServiceTypes
  { path: "/service-type", component: ServiceTypes },

  // ActivityTypes
  { path: "/activity-types", component: ActivityTypes },

  // CustomActivityTypes
  { path: "/custom-activity-types", component: CustomActivityTypes },
  { path: "/add-custom-activity-types", component: AddCustomActivityTypes },
  {
    path: "/edit-custom-activity-types/:activity_type_id",
    component: EditCustomActivityTypes,
  },

  // Categories
  { path: "/categories", component: Categories },

  // Provinces
  { path: "/provinces", component: Provinces },

  // District
  { path: "/districts", component: Districts },

  // SubDistricts
  { path: "/sub-districts", component: SubDistricts },

  // Masters - Activity 3, 4, 5 - Gulshan

  // Quiz Types

  { path: "/quiz-types", component: QuizTypes },

  // Custom Activity Types

  { path: "/custom-activity-types", component: CustomActivityTypes },

  // Score Types

  { path: "/score-types", component: ScoreTypes },

  // Activity Display Types

  { path: "/activity-display-types", component: ActivityDisplayTypes },

  // Activity Content Types

  { path: "/activity-content-types", component: ActivityContentTypes },

  // Task Types

  { path: "/task-types", component: TaskTypes },

  // Attachment Types

  { path: "/attachment-types", component: AttachmentTypes },

  // Institute Index

  { path: "/institute-index", component: InstituteIndex },

  // Notification Types

  { path: "/notification-types", component: NotificationTypes },

  // Content Length Scores

  { path: "/content-length-scores", component: ContentLengthScore },

  // Evalution Form

  { path: "/evalution-forms", component: EvalutionForm },

  { path: "/view-evalution-form/:id", component: ViewEvalutionForm },

  { path: "/main-task-types", component: MainTaskTypes },
  { path: "/sessions", component: Sessions },
  { path: "/add-session", component: AddSession },
  { path: "/edit-session/:id", component: EditSession },
  { path: "/manage-session/:sessionId", component: ManageSession },
  { path: "/user-evaluations", component: UserEvaluations },
  { path: "/evaluation-list", component: EvaluationList },
  { path: "/add-evaluators/:sessionId/:taskTypeId", component: AddEvaluators },

  //report
  { path: "/login-history", component: LoginHistory },
  { path: "/institute-report", component: RegistredInstitutes },
  { path: "/sub-institute-report", component: RegistredSubInstitutes },
  { path: "/activities-report", component: Activities },
  { path: "/approved-doctor-reports", component: ApprovedDoctors },

  { path: "/leave-reason", component: LeaveReason },

  { path: "/leaves", component: Leave },

  { path: "/edit-leave/:leave_id", component: EditLeave },
]

const publicRoutes = [
  { path: "/logout", component: Logout },
  { path: "/login", component: Login },
  { path: "/forgot-password", component: ForgetPwd },
  { path: "/update-password", component: UpdatePassword },
  { path: "/register", component: Register },

  // { path: "/pages-maintenance", component: PagesMaintenance },
  // { path: "/pages-comingsoon", component: PagesComingsoon },
  // { path: "/pages-404", component: Pages404 },
  // { path: "/pages-500", component: Pages500 },
  // { path: "/crypto-ico-landing", component: CryptoIcoLanding },

  // Authentication Inner
  { path: "/pages-login", component: Login1 },
  { path: "/pages-login-2", component: Login2 },
  { path: "/pages-register", component: Register1 },
  { path: "/pages-register-2", component: Register2 },
  { path: "/page-recoverpw", component: Recoverpw },
  { path: "/page-recoverpw-2", component: Recoverpw2 },
  { path: "/pages-forgot-pwd", component: ForgetPwd1 },
  { path: "/auth-recoverpw-2", component: ForgetPwd2 },
  { path: "/auth-lock-screen", component: LockScreen },
  { path: "/auth-lock-screen-2", component: LockScreen2 },
  { path: "/page-confirm-mail", component: ConfirmMail },
  { path: "/page-confirm-mail-2", component: ConfirmMail2 },
  { path: "/auth-email-verification", component: EmailVerification },
  { path: "/auth-email-verification-2", component: EmailVerification2 },
  { path: "/auth-two-step-verification", component: TwostepVerification },
  { path: "/auth-two-step-verification-2", component: TwostepVerification2 },
]

export { authProtectedRoutes, publicRoutes }
