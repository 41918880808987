import React from "react"
import moment from "moment"
import { convertToHTML } from "draft-convert"

export const dateFormat = date => {
  return moment(date).format("DD MMM YYYY")
}

export const dateFormatTH = date => {
  var options = { hour: "numeric", minute: "numeric", second: "numeric" }
  return moment(date).add(543, "years").format("DD MMM YYYY")
}

export const currentDateTH = date => {
  return moment().add(543, "years").format("DD/MMM/YYYY")
}

export const dateTimeFormat = datetime => {
  return moment(datetime).format("DD MMMM YYYY")
}

export const TimeFormat = datetime => {
  return moment(datetime, "hh:mm").format("LT") //moment(datetime).format("h:mm A UTC");
}

export const timePeriodArr = [
  {
    type: "TODAY",
    name: "Today",
  },
  {
    type: "YESTERDAY",
    name: "Yesterday",
  },
  {
    type: "LAST_7_DAYS",
    name: "Last 7 Days",
  },
  {
    type: "CURRENT_MONTH",
    name: "This Month",
  },
  {
    type: "LAST_MONTH",
    name: "Last Month",
  },
  {
    type: "CHOOSE_PERIOD",
    name: "Choose Period",
  },
]

export const convertEditorContentTextToHtml = async content => {
  let currentContentTextAsHTML = convertToHTML({
    blockToHTML: block => {
      // if (block.type === "atomic") {
      //   return {
      //     start: "<figure>",
      //     end: "</figure>",
      //     empty: "",
      //   }
      // }

      // if (block.type === 'PARAGRAPH') {
      //   return <p/>;
      // }
      return
    },
    entityToHTML: (entity, originalText) => {
      if (entity.type === "LINK") {
        return (
          <a
            href={entity.data.url}
            target={`${entity.data.targetOption}`}
            rel="noreferrer"
          >
            {originalText}
          </a>
        )
      }

      if (entity.type === "IMAGE") {
        return {
          start: `<img src='${entity.data["src"]}' height='${
            entity.data["height"] === "auto" ? "100%" : entity.data["height"]
          }' width='${
            entity.data["width"] === "auto" ? "100%" : entity.data["width"]
          }'>`,
          end: "</img>",
          empty: "",
        }
      }
      return originalText
    },
  })(content.getCurrentContent())
  let strText = currentContentTextAsHTML
  strText = strText.replace(/<[^/>][^>]*><\/[^>]+>/, "")
  return strText
}
