import config from "../config/config"
import axios from "axios"
import { getToken } from "../Token"
import * as url from "../helpers/url_helper"

const token = getToken()
export const EvalutionFormApi = {
  getAllEvalutionForms: async data => {
    var axiosConfig = {
      method: "post",
      url: `${config.API_URL}${url.POST_FETCH_ALL_EVALUTIONFORMS}`,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      data: data,
    }

    return await axios(axiosConfig)
  },
  createEvalutionForm: async data => {
    var axiosConfig = {
      method: "post",
      url: `${config.API_URL}${url.POST_CREATE_EVALUTIONFORM}`,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      data: data,
    }

    return await axios(axiosConfig)
  },
  getEvalutionFormById: async data => {
    var axiosConfig = {
      method: "post",
      url: `${config.API_URL}${url.POST_FETCH_EVALUTIONFORM}`,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      data: data,
    }

    return await axios(axiosConfig)
  },
  updateEvalutionForm: async data => {
    var axiosConfig = {
      method: "post",
      url: `${config.API_URL}${url.POST_UPDATE_EVALUTIONFORM}`,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      data: data,
    }

    return await axios(axiosConfig)
  },
  deleteEvalutionForm: async data => {
    var axiosConfig = {
      method: "post",
      url: `${config.API_URL}${url.POST_DELETE_EVALUTIONFORM}`,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      data: data,
    }

    return await axios(axiosConfig)
  },
  getAllEvalutionFormDetails: async data => {
    var axiosConfig = {
      method: "post",
      url: `${config.API_URL}${url.POST_FETCH_ALL_EVALUTIONFORM_DETAILS}`,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      data: data,
    }

    return await axios(axiosConfig)
  },
  createEvalutionFormDetail: async data => {
    var axiosConfig = {
      method: "post",
      url: `${config.API_URL}${url.POST_CREATE_EVALUTIONFORM_DETAIL}`,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      data: data,
    }

    return await axios(axiosConfig)
  },
  getEvalutionFormFieldsApi: async data => {
    var axiosConfig = {
      method: "post",
      url: `${config.API_URL}${url.POST_FETCH_EVALUTIONFORMFIELDS}`,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      data: data,
    }

    return await axios(axiosConfig)
  },
  updateEvalutionFormDetail: async data => {
    var axiosConfig = {
      method: "post",
      url: `${config.API_URL}${url.POST_UPDATE_EVALUTIONFORM_DETAIL}`,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      data: data,
    }

    return await axios(axiosConfig)
  },
  deleteEvalutionFormDetail: async data => {
    var axiosConfig = {
      method: "post",
      url: `${config.API_URL}${url.POST_DELETE_EVALUTIONFORM_DETAIL}`,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      data: data,
    }

    return await axios(axiosConfig)
  },
  getInstituteEvaluationForms: async data => {
    const axiosConfig = {
      method: "post",
      url: `${config.API_URL}${url.POST_FETCH_INSTITUTEEVALUATIONS}`,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    }

    return await axios(axiosConfig)
  },
}
