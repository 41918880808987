import config from "../config/config"
import axios from "axios"
import { getToken } from "../Token"
import * as url from "../helpers/url_helper"

const token = getToken()
export const InternshipApi = {
  getMainTaskTypesApi: async data => {
    const axiosConfig = {
      method: "post",
      url: `${config.API_URL}${url.POST_FETCH_ALL_MAINTASKTYPES}`,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      data: data,
    }

    return await axios(axiosConfig)
  },
  createMainTaskTypeApi: async data => {
    const axiosConfig = {
      method: "post",
      url: `${config.API_URL}${url.POST_CREATE_MAINTASKTYPE}`,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      data: data,
    }

    return await axios(axiosConfig)
  },
  getMainTaskTypeApi: async id => {
    const axiosConfig = {
      method: "get",
      url: `${config.API_URL}${url.POST_FETCH_MAINTASKTYPE}/${id}`,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    }
    return await axios(axiosConfig)
  },
  updateMainTaskTypeApi: async data => {
    const axiosConfig = {
      method: "post",
      url: `${config.API_URL}${url.POST_UPDATE_MAINTASKTYPE}`,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      data: data,
    }
    return await axios(axiosConfig)
  },
  deleteMainTaskTypeApi: async id => {
    const axiosConfig = {
      method: "post",
      url: `${config.API_URL}${url.POST_DELETE_MAINTASKTYPE}/${id}`,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    }
    return await axios(axiosConfig)
  },
  getSessionsApi: async data => {
    const axiosConfig = {
      method: "post",
      url: `${config.API_URL}${url.POST_FETCH_ALL_INTERNSHIPSESSION}`,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      data: data,
    }
    return await axios(axiosConfig)
  },
  createSessionApi: async data => {
    const axiosConfig = {
      method: "post",
      url: `${config.API_URL}${url.POST_CREATE_SESSION}`,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      data: data,
    }
    return await axios(axiosConfig)
  },
  getSessionDetailApi: async id => {
    const axiosConfig = {
      method: "get",
      url: `${config.API_URL}${url.GET_FETCH_SESSIONDETAIL}/${id}`,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    }
    return await axios(axiosConfig)
  },
  updateSessionApi: async data => {
    const axiosConfig = {
      method: "post",
      url: `${config.API_URL}${url.POST_UPDATE_SESSION}`,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      data: data,
    }
    return await axios(axiosConfig)
  },
  getUserEvaluationsApi: async data => {
    const axiosConfig = {
      method: "post",
      url: `${config.API_URL}${url.POST_FETCH_ALL_INTERNSHIPUSEREVALAUTION}`,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      data: data,
    }
    return await axios(axiosConfig)
  },
  createUserEvaluationApi: async data => {
    const axiosConfig = {
      method: "post",
      url: `${config.API_URL}${url.POST_CREATE_INT_USEREVALUATION}`,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      data: data,
    }
    return await axios(axiosConfig)
  },
  getUserEvaluationDetailByIDApi: async id => {
    const axiosConfig = {
      method: "get",
      url: `${config.API_URL}${url.GET_FETCH_INT_USEREVALUATIONETAIL}/${id}`,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    }
    return await axios(axiosConfig)
  },
  updateUserEvaluationApi: async data => {
    const axiosConfig = {
      method: "post",
      url: `${config.API_URL}${url.POST_UPDATE_USEREVALUATION}`,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      data: data,
    }
    return await axios(axiosConfig)
  },
  deleteUserEvaluationApi: async id => {
    const axiosConfig = {
      method: "post",
      url: `${config.API_URL}${url.POST_DELETE_USEREVALUATION}/${id}`,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    }
    return await axios(axiosConfig)
  },
  getAllMainTaskByInstitutes: async data => {
    const axiosConfig = {
      method: "post",
      url: `${config.API_URL}${url.POST_FETCH_MAINTASKTYPES_BY_INSTITUTE}`,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      data: data,
    }
    return await axios(axiosConfig)
  },
  addSessionTaskAndEvalauatorsApi: async data => {
    const axiosConfig = {
      method: "post",
      url: `${config.API_URL}${url.POST_CREATE_INT_ADDSESSIONTASKS}`,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      data: data,
    }
    return await axios(axiosConfig)
  },
  getSessionTaskAndEvalauatorsApi: async (id, data = {}) => {
    const axiosConfig = {
      method: "post",
      url: `${config.API_URL}${url.POST_CREATE_INT_GETSESSIONTASKS}/${id}`,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      data: data,
    }
    return await axios(axiosConfig)
  },
  getEvaluationListApi: async data => {
    const axiosConfig = {
      method: "post",
      url: `${config.API_URL}${url.POST_FETCH_ALL_INTERNSHIPEVALUATIONLIST}`,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      data: data,
    }
    return await axios(axiosConfig)
  },
  addEvalauatorsToSessionApi: async data => {
    const axiosConfig = {
      method: "post",
      url: `${config.API_URL}${url.POST_CREATE_INT_ADDEVALUATORS}`,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      data: data,
    }
    return await axios(axiosConfig)
  },
}
