import React from 'react';
import { Link } from 'react-router-dom';

const DashboardCard = (props) => {

    return (
        <div className="mini-stats-wid card">
            <div className="card-body">
                <div className="d-flex">
                    <div className="flex-grow-1">
                        <Link to={props.path}><p className="text-muted fw-medium">{props.title}</p></Link>
                        <h4 className="mb-0">{props.totalCount}</h4>
                    </div>
                    <div className="mini-stat-icon avatar-sm rounded-circle bg-primary align-self-center">
                        <span className="avatar-title">
                            <i className={props.iconName}></i>
                        </span>
                    </div>
                </div>
            </div>
        </div>
    )


}

export default DashboardCard;