// src/components/filter.
import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"

//import components
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import DataTable from "react-data-table-component"
import { BannerApi } from "../../../apis/BannerApi"
import { useHistory, Link } from "react-router-dom"
import TableContainer from "../../../components/Common/TableContainer"
import { withTranslation } from "react-i18next"
import * as Yup from "yup"
import { useFormik } from "formik"
import toastr from "toastr"
import config from "config/config"
import constants from "../../../config/constants"
import i18n from "../../../i18n"
import * as url from "../../../helpers/common_helper"

import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Form,
  Label,
  Input,
  FormFeedback,
  Button,
} from "reactstrap"
import Swal from "sweetalert2"
import { ActivityApi } from "apis/ActivityApi"
import moment from "moment"
import { getRoleID, getToken } from "../../../Token"

function Activities(props) {
  const history = useHistory()
  const [activityList, setActivityList] = useState([])
  const [loading, setLoading] = useState(false)

  const [activeData, setActiveData] = useState({
    page: 1,
    totalPage: 1,
    search: "",
    limit: config.LIMIT,
  })

  const [page, setCurrentPage] = useState(1);
  const [limit, setCurrentLimit] = useState(config.LIMIT);

  const [activityName, setActivityName] = useState();
  const [autoSearch, setAutoSearchData] = useState([]);

  const onCreateCustomActivityRequest = () => {
    const payload = {
      token: getToken(),
    }
    ActivityApi.loadCreateCustomActivityURLApi(payload)
      .then(res => {
        if (res.data.success) {
          window.open(res.data.loadUrl, "_blank")
        }
      })
      .catch(err => {
        console.log(err)
      })
  }

  const onEditCustomActivityRequest = (
    activity_id,
    activity_date,
    activity_time,
    formStatus
  ) => {
    var CurrentDate = moment().format("YYYY-MM-DD")
    var activity_date = moment(activity_date).format("YYYY-MM-DD")
    var CurrentTime = moment().format("HH:mm:ss")
    if (CurrentDate >= activity_date && formStatus == 'edit') {
      if (activity_date == CurrentDate && CurrentTime >= activity_time) {
        alert(props.t("Can not edit this activity"))
        return false
      }
      if (CurrentDate > activity_date) {
        alert(props.t("Can not edit this activity"))
        return false
      }
    }
    const payload = {
      token: getToken(),
      activity_id: activity_id,
      formStatus
    }
    ActivityApi.loadEditCustomActivityURLApi(payload)
      .then(res => {
        if (res.data.success) {
          window.open(res.data.loadUrl, "_blank")
        }
      })
      .catch(err => {
        console.log(err)
      })
  }

  useEffect(() => {
    getAllActivites(activeData)
  }, [page,limit])

  const formClear = () => {
    validationType.resetForm()
  }

  const columns = [
    {
      name: props.t("Sr_No"),
      selector: row => row.sr_no,
      sort: "asc",
      width: 150,
      sortable: true,
      defaultSortField: true,
      defaultSortAsc: false,
    },
    {
      name: props.t("Title_Eng"),
      selector: row => row.title_en,
      sort: "asc",
      width: 270,
      sortable: true,
      defaultSortField: true,
      defaultSortAsc: false,
    },
    {
      name: props.t("Title_Thai"),
      selector: row => row.title_th,
      sort: "asc",
      width: 270,
      sortable: true,
      defaultSortField: true,
      defaultSortAsc: false,
    },
    {
      name: props.t("Activity_Type"),
      selector: row => row.activity_type,
      sort: "asc",
      width: 270,
      sortable: true,
      defaultSortField: true,
      defaultSortAsc: false,
    },
    {
      name: props.t("Activity_Date"),
      selector: row => row.activity_date,
      sort: "asc",
      width: 270,
      sortable: true,
      defaultSortField: true,
      defaultSortAsc: false,
    },
    {
      name: props.t("Start_Time"),
      selector: row => row.start_time,
      sort: "asc",
      width: 270,
      sortable: true,
      defaultSortField: true,
      defaultSortAsc: false,
    },
    {
      name: props.t("End_Time"),
      selector: row => row.end_time,
      sort: "asc",
      width: 270,
      sortable: true,
      defaultSortField: true,
      defaultSortAsc: false,
    },
    {
      name: props.t("credit"),
      selector: row => row.credit,
      sort: "asc",
      width: 270,
      sortable: true,
      defaultSortField: true,
      defaultSortAsc: false,
    },
    {
      name: props.t("Type"),
      selector: row => row.type,
      sort: "asc",
      width: 270,
      sortable: true,
      defaultSortField: true,
      defaultSortAsc: false,
    },
    {
      name: props.t("Action"),
      selector: row => row.action,
      sort: "asc",
      width: 200,
    },
  ]

  const getAllActivites = data => {
    setLoading(true)
    ActivityApi.getAllActivites(data)
      .then(res => {
        setActiveData({
          page: activeData.page,
          totalPage: res?.data?.data?.count,
          search: activeData.search,
          limit: activeData.limit,
        })
        let data = []
        var sr_no = res.data.sr_no_start

        res?.data?.data?.rows?.forEach((v, i) => {

        let userScore = 0,
        speakerScore = 0;
        const startTime = moment(v.start_time, "HH:mm:ss");
        const endTime = moment(v.end_time, "HH:mm:ss");
        const duration = moment.duration(endTime.diff(startTime));
        const hours = parseInt(duration.asHours());
        const minutes = parseInt(duration.asMinutes()) % 60;
        const minuestcal = (minutes * 100) / 60;
        const totalTime = hours + "." + minuestcal.toFixed(0);
        if(totalTime > 1) {
          userScore = totalTime * v.ActivityType?.learner_per_hour_score;
        } else {
          userScore = v.ActivityType?.learner_per_hour_score;
        }

        //const activityDateTime = moment(`${v.activity_date} ${v.start_time}`)
        const activityDateCurrent = moment(`${v.activity_date}`).format('YYYY-MM-DD');
        var a = moment(`${activityDateCurrent}T${v.start_time}`);//now
        var b = moment();
        
        const duration1 = a.diff(b, 'minutes')
        const minuestcal1 = (duration1  / 60 ).toFixed(2);
        //const totalTime1 = hours1 + "." + minuestcal1.toFixed(0);
        var editActivity  = true;
        if((v.ActivityType?.code == '4.1' || v.ActivityType?.code == '5.1')
        && minuestcal1 <= 24 ) {
          editActivity  = false;
        }
          sr_no = sr_no + 1
          url.TimeFormat(v.start_time)
          data[i] = {
            sr_no: sr_no,
            title_en: v.title_en ? v.title_en : "-",
            title_th: v.title_th ? v.title_th : "-",
            activity_type: v.ActivityType?.activity_group ?
              v.ActivityType?.activity_group + " - " + v.ActivityType?.code : ' - ',
            activity_date:
              url.dateFormat(v.activity_date) === "Invalid date"
                ? "-"
                : url.dateFormat(v.activity_date),
            start_time:
              url.TimeFormat(v.start_time) === "Invalid date"
                ? "-"
                : url.TimeFormat(v.start_time),
            end_time:
              url.TimeFormat(v.end_time) === "Invalid date"
                ? "-"
                : url.TimeFormat(v.end_time),
            credit: userScore ? userScore.toFixed(2) : "",
            type: v.type ? v.type : "-",
            action: (
              <>
                {v.is_custom_activity == 1 ? (
                  (getRoleID() !== constants.ROLE.SUPERADMIN && editActivity) ? (
                    <a
                      style={{ marginRight: "15px" }}
                      href="#"
                      onClick={() =>
                        onEditCustomActivityRequest(
                          v.id,
                          v.activity_date,
                          v.start_time,
                          'view'
                        )
                      }
                    >
                      <i className="fas fa-eye" id="edittooltip" />
                    </a>
                  ) : (
                    <></>
                  )
                ) : (
                  <>
                    <Link
                      style={{ marginRight: "15px" }}
                      to={`/edit-activity/${v.id}?formStatus=view`}
                    >
                      <i className="fas fa-eye" id="edittooltip" />
                    </Link>
                  </>
                )}

                

                {v.is_custom_activity == 1 ? (
                  getRoleID() !== constants.ROLE.SUPERADMIN ? (
                    <a
                      style={{ marginRight: "15px" }}
                      href="#"
                      onClick={() =>
                        onEditCustomActivityRequest(
                          v.id,
                          v.activity_date,
                          v.start_time,
                          'edit'
                        )
                      }
                    >
                      <i className="fas fa-edit" id="edittooltip" />
                    </a>
                  ) : (
                    <></>
                  )
                ) : (
                  <>
                    <Link
                      style={{ marginRight: "15px" }}
                      to={`/edit-activity/${v.id}?formStatus=edit`}
                    >
                      <i className="fas fa-edit" id="edittooltip" />
                    </Link>
                  </>
                )}

                <i
                className="mdi mdi-delete font-size-18"
                style={{ color: "red" }}
                id="deletetooltip"
                onClick={() => onDelete(v.id)}
                />
                
              </>
            ),
          }
        })
        setActivityList(data)
        setLoading(false)
      })
      .catch(err => {
        console.log(err)
      })
  }

  const onDelete = id => {
    Swal.fire({
      title: props.t("Are_you_sure"),
      text: props.t("Able_to_revert_this"),
      icon: props.t("Warning"),
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#f46a6a",
      confirmButtonText: props.t("Yes_delete_it"),
      cancelButtonText: props.t("Cancel"),
    }).then(result => {
      if (result.isConfirmed) {
        const ActivityId = { activity_id: id }
        ActivityApi.deleteActivity(ActivityId)
          .then(res => {
            Swal.fire({
              icon: "success",
              text: res.data.message,
              imageAlt: "success image",
            }).then(() => {
              handleChange(page);
              //window.location.reload();
            })
          })
          .catch(err => {
            console.log(err)
          })
      }
    })
  }

  const handleChange = (v) => {
    setCurrentPage(v);
    //console.log(v);
    setActiveData({
      page: v,
      totalPage: activeData.totalPage,
      search: activeData.search,
      limit: limit,
    });
    const data = {
      page: v,
      totalPage: activeData.totalPage,
      search: activeData.search,
      limit: limit,
    }
    getAllActivites(data);
  }

  const handleRowChange = v => {
    setCurrentLimit(v);
    setActiveData({
      page: activeData.page,
      totalPage: activeData.totalPage,
      search: activeData.search,
      limit: v,
    })
    const data = {
      page: activeData.page,
      totalPage: activeData.totalPage,
      search: activeData.search,
      limit: v,
    }
    getAllActivites(data)
  }

  const onChangeSearchActivityHandler = (event) => {
    setActiveData({...activeData, search: event.target.value})
  }

  const onSearchActivityHanlder = () => {
    console.log(activeData);
    getAllActivites(activeData)
  }

  const OnSearchKeyUp = (e) => {
    setActivityName(e.target.value);
    if(e.key == "Enter") {
      setActiveData({ page: activeData.page, totalPage: activeData.totalPage, search: e.target.value, limit: activeData.limit });
      const data = { page: activeData.page, totalPage: activeData.totalPage, search: e.target.value, limit: activeData.limit }
      getAllActivites(data);
      setActivityName(activityName);
      setAutoSearchData([]);
      return false;
    }
  }
    
  const OnSearch = (e) => {
    let data = { keyword: e.target.value };
    setActivityName(e.target.value);
    if(e.target.value != '') {
      ActivityApi.getActivityByName(data)
        .then((res) => {
          if (res.data.success) {
            setAutoSearchData(res.data.data);
          } else {
            setAutoSearchData([]);
          }
        })
        .catch((err) => {
          console.log(err);
      });
    }  else {
      setAutoSearchData([]);
    }
    // setActiveData({ ...activeData, search: e.target.value })
  }

  const searchByactivityName = (keyword) => {
    setActiveData({ page: activeData.page, totalPage: activeData.totalPage, search: keyword, limit: activeData.limit });
    const data = { page: activeData.page, totalPage: activeData.totalPage, search: keyword, limit: activeData.limit }
    getAllActivites(data);
    setActivityName(keyword);
    setAutoSearchData([]);
  }

  const searchRecord = () => {
    activeData.activityName = (activityName !== undefined) ? activityName : "";

    setActiveData({ page: activeData.page, totalPage: activeData.totalPage, search: activeData.activityName, limit: activeData.limit });
    const data = { page: activeData.page, totalPage: activeData.totalPage, search: activeData.activityName, limit: activeData.limit }
    getAllActivites(data);
    setActivityName(activityName);
  }


  //meta title
  document.title = props.t("Activities") + " | " + props.t("MedX")

  return (
    <div className="page-content">
      <div className="container-fluid">
        <Breadcrumbs
          title={props.t("Activity")}
          breadcrumbItem={props.t("Activities")}
        />
        <Container fluid={true}>
          <Row>
            <Col>
              {getRoleID() !== constants.ROLE.SUPERADMIN && (
                <button
                  onClick={onCreateCustomActivityRequest}
                  className="btn btn-primary w-xs btn-right"
                >
                  {props.t("createActivity34and5")}
                </button>
              )}
              <Link
                to="/add-activity"
                className="btn btn-primary w-xs btn-right"
                style={{ marginRight: "10px" }}
              >
                {props.t("createActivity1and2")}
              </Link>
            </Col>
          </Row>
        </Container>
        <div style={{ marginBottom: "10px" }} className="d-flex">
          {/* <Input
            className="w-25"
            name="search"
            placeholder={props.t("Search")}
            onChange={onChangeSearchActivityHandler}
          />
          <button onClick={onSearchActivityHanlder} className="btn btn-primary">{props.t("Search")}</button> */}
         <div className="wrapper w-40">
          <div className="search-input">
            <a href="" target="_blank" hidden="" />
            <input type="text" defaultValue={activityName} value={activityName} onChange={(e) => OnSearch(e) } onKeyUp = {OnSearchKeyUp}  placeholder="Type to search.."/>
            {autoSearch.length > 0 && activityName != '' && (
              <div className="autocom-box">
                {autoSearch?.map((searchdata, index) => {
                  return (<li key={index} onClick={() => searchByactivityName(searchdata.title_en)}  >{searchdata.title_en}</li>)
                })}
              </div>
            )}
            <div className="icon">
              <i className="fas fa-search" onClick={() => searchRecord()}/>
            </div>
          </div>
        </div>

        </div>
        <Container fluid={true}>
          <Row>
            <Col lg={12}>
              <Card>
                <CardBody>
                  <Col sm={12}>
                    <DataTable
                      className="table-bordered"
                      progressPending={loading}
                      columns={columns}
                      data={activityList}
                      pagination
                      paginationServer
                      paginationTotalRows={activeData.totalPage}
                      paginationPerPage={activeData.limit}
                      defaultSortFieldID={1}
                      onChangeRowsPerPage={value => handleRowChange(value)}
                      onChangePage={value => handleChange(value) }
                      sortable
                      noHeader
                      defaultSortField="id"
                      defaultSortAsc={false}
                      highlightOnHover
                    />
                  </Col>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  )
}

Activities.propTypes = {
  t: PropTypes.any,
  preGlobalFilteredRows: PropTypes.any,
}

export default withTranslation()(Activities)
