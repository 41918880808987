// src/components/filter.
import React, { useMemo, useEffect, useState } from "react"
import PropTypes from "prop-types"
import ReactExport from "react-export-excel-xlsx-fix"

const ExcelFile = ReactExport.ExcelFile
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn

//import components
import DataTable from "react-data-table-component"
import { Link } from "react-router-dom"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { ReportApi } from "apis/ReportApi"
import TableContainer from "../../components/Common/TableContainer"
import { withTranslation } from "react-i18next"
import config from "../../config/config"
import Swal from "sweetalert2"
import {
  Row,
  Col,
  Card,
  CardBody,
  FormGroup,
  Label,
  Input,
  Form,
  Button,
} from "reactstrap"
import * as Yup from "yup"
import { useFormik } from "formik"
// import { CSVLink } from 'react-csv';
import moment from "moment"
import { dateFormat, dateFormatTH, timePeriodArr } from "helpers/common_helper"

function LoginHistory(props) {
  const [loading, setLoading] = useState(false)
  const [loginHostory, setLoginHostory] = useState([])
  const [loginHistoryExcelData, setLoginHistoryExcelData] = useState([])
  const [isDateFilterShow, setDateFilterShow] = useState(false)
  const [csvData, setCsvData] = useState([])
  const [timePeriod, setTimePeriod] = useState("TODAY")
  const fileName = "loginhistory" // filename for excel file

  const [activeData, setActiveData] = useState({
    page: 1,
    totalPage: 1,
    search: "",
    limit: config.LIMIT,
  })

  useEffect(() => {
    getLoginHostory(activeData)
  }, [])

  const getLoginHostory = active_data => {
    // setLoading(true);
    ReportApi.getLoginHostory(active_data)
      .then(res => {
        //setCsvData(res.data.data.rows);
        // console.log(res.data.data.rows)
        setActiveData({
          page: activeData.page,
          totalPage: res.data.data.count,
          search: activeData.search,
          limit: activeData.limit,
        })
        let data = []
        var sr_no = res.data.sr_no_start
        res.data.data.rows.forEach((v, i) => {
          sr_no = sr_no + 1
          data[i] = {
            id: sr_no,
            login_date:
              dateFormatTH(v.login_date) === "Invalid date"
                ? "-"
                : dateFormat(v.login_date),
            user_role_type: v.user_role_type,
            user_name: v.user_name,
            name_en: v.name_en,
            ip_address: v.ip_address,
          }
        })
        setLoginHostory(data)
        getLoginHistoryWithoutLimit(active_data)
        setLoading(false)
      })
      .catch(err => {
        console.log(err)
      })
  }

  const getLoginHistoryWithoutLimit = () => {
    //setLoginHistoryExcelData([]);
    ReportApi.getLoginHistoryWithoutLimit(activeData)
      .then(res => {
        let data = []
        var sr_no = res.data.sr_no_start
        res.data.data.rows.forEach((v, i) => {
          sr_no = sr_no + 1
          data[i] = {
            id: sr_no,
            login_date:
              dateFormatTH(v.login_date) === "Invalid date"
                ? "-"
                : dateFormat(v.login_date),
            user_role_type: v.user_role_type,
            user_name: v.user_name,
            name_en: v.name_en,
            ip_address: v.ip_address,
          }
        })
        setLoginHistoryExcelData(data)
      })
      .catch(error => {
        console.log(error)
      })
  }


  const columns = [
    {
      name: props.t("Sr_No"),
      selector: row => row.id,
      sort: "asc",
      width: 150,
      sortable: true,
      defaultSortField: true,
      defaultSortAsc: false,
    },

    {
      name: props.t("Accessed_Date"),
      selector: row => row.login_date,
      sort: "asc",
      width: 270,
      sortable: true,
      defaultSortField: true,
      defaultSortAsc: false,
    },
    {
      name: props.t("Type_of_User"),
      selector: row => row.user_role_type,
      sort: "asc",
      width: 270,
      sortable: true,
      defaultSortField: true,
      defaultSortAsc: false,
    },
    {
      name: props.t("Username"),
      selector: row => row.user_name,
      sort: "asc",
      width: 270,
      sortable: true,
      defaultSortField: true,
      defaultSortAsc: false,
    },
    {
      name: props.t("Full_Name"),
      selector: row => row.name_en,
      sort: "asc",
      width: 270,
      sortable: true,
      defaultSortField: true,
      defaultSortAsc: false,
    },
    {
      name: props.t("IP_Address"),
      selector: row => row.ip_address,
      sort: "asc",
      width: 270,
      sortable: true,
      defaultSortField: true,
      defaultSortAsc: false,
    },
  ]

  const handleChange = v => {
    setActiveData({
      page: v,
      totalPage: activeData.totalPage,
      search: activeData.search,
      limit: activeData.limit,
    })
    const data = {
      page: v,
      totalPage: activeData.totalPage,
      search: activeData.search,
      limit: activeData.limit,
    }
    getLoginHostory(data)
    getLoginHistoryWithoutLimit(data)
  }

  const handleRowChange = v => {
    setActiveData({
      page: activeData.page,
      totalPage: activeData.totalPage,
      search: activeData.search,
      limit: v,
    })
    const data = {
      page: activeData.page,
      totalPage: activeData.totalPage,
      search: activeData.search,
      limit: v,
    }
    getLoginHostory(data)
  }

  const handleChangeTimePeriod = v => {
    validation.setFieldValue("time_period", v)
    setTimePeriod(v)
    if (v == "CHOOSE_PERIOD") {
      setDateFilterShow(true)
    } else {
      validation.setFieldValue("start_date", "")
      validation.setFieldValue("end_date", "")
      setDateFilterShow(false)
    }
  }

  const handleChangeSearch = () => {
    setActiveData({
      page: 1,
      totalPage: 1,
      search: activeData.search,
      limit: activeData.limit,
    })
    const data = {
      page: 1,
      totalPage: 1,
      search: activeData.search,
      limit: activeData.limit,
    }
    getLoginHostory(data)
  }

  document.title = "Page Content | MedX"

  // Form validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      time_period: timePeriod,
      isDateShow: isDateFilterShow ? "true" : "false",
      start_date: "",
      end_date: "",
    },
    validationSchema: Yup.object({
      // start_date: Yup.date(),
      time_period: Yup.string().required(props.t("Required-Message")),
      // start_date: Yup.string().when('isDateFilterShow', {
      //   is: "false",
      //   then: Yup.string(),
      //   otherwise: Yup.string().required(props.t("Required-Message")),
      // }),
      // end_date: Yup.string().when('isDateFilterShow', {
      //   is: "false",
      //   then: Yup.string(),
      //   otherwise: Yup.string().required(props.t("Required-Message")),
      // }),
    }),
    onSubmit: values => {
      if (isDateFilterShow) {
        if (values.start_date == "") {
          validation.setErrors({ start_date: `${props.t("Required-Message")}` })
          return false
        }

        if (values.end_date == "") {
          validation.setErrors({ end_date: `${props.t("Required-Message")}` })
          return false
        }

        var start = moment(values.start_date, "YYYY-MM-DD")
        var end = moment(values.end_date, "YYYY-MM-DD")
        var differenceDay = moment.duration(end.diff(start)).asDays() + 1
        if (differenceDay <= 0) {
          validation.setErrors({ end_date: `${props.t("Invalid-Date-Range")}` })
          return false
        }

        if (differenceDay > 30) {
          validation.setErrors({ end_date: `${props.t("Max-30-Day-Search")}` })
          return false
        }
      }

      setLoginHistoryExcelData([])
      activeData.page = 1
      activeData.totalPage = 1
      activeData.search = values
      activeData.limit = 10
      setActiveData({ totalPage: 1, page: 1, search: values, limit: 10 })
      getLoginHostory(activeData)
      getLoginHistoryWithoutLimit(activeData)
    },
  })

  return (
    <div className="page-content">
      <div className="container-fluid">
        <Breadcrumbs
          title={props.t("Report")}
          breadcrumbItem={props.t("Login")}
        />
      </div>

      <Form
        className="needs-validation"
        onSubmit={e => {
          e.preventDefault()
          validation.handleSubmit()
          return false
        }}
      >
        <Row>
          <Col md="3">
            <FormGroup className="mb-3">
              <Label htmlFor="validationCustom01">
                {props.t("Time Period")}
              </Label>
              <Input
                name="time_period"
                type="select"
                className="form-control"
                // onChange={validation.handleChange}
                onBlur={validation.handleBlur}
                defaultValue={validation.values.time_period}
                onChange={e => handleChangeTimePeriod(e.target.value)}
              >
                <option value="">{props.t("Select The Time Period")}</option>
                {timePeriodArr.map((item, i) => (
                  <option key={i} value={item.type}>
                    {item.name}
                  </option>
                ))}
              </Input>
              {validation.touched.time_period &&
              validation.errors.time_period ? (
                <div
                  style={{
                    color: "#f46a6a",
                    fontSize: "11px",
                    marginTop: "3px",
                  }}
                >
                  {validation.errors.time_period}
                </div>
              ) : null}
            </FormGroup>
          </Col>

          {isDateFilterShow ? (
            <>
              <Col md="3">
                <FormGroup className="mb-3">
                  <Label htmlFor="validationCustom01">
                    {props.t("Start Date")}
                  </Label>
                  <Input
                    name="start_date"
                    placeholder={props.t("Start Date")}
                    type="date"
                    className="form-control"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.start_date || ""}
                    // onChangePage={(value) => handleChangeStartdate(value)}
                  />

                  {validation.touched.start_date &&
                  validation.errors.start_date ? (
                    <div
                      style={{
                        color: "#f46a6a",
                        fontSize: "11px",
                        marginTop: "3px",
                      }}
                    >
                      {validation.errors.start_date}
                    </div>
                  ) : null}
                </FormGroup>
              </Col>
              <Col md="3">
                <FormGroup className="mb-3">
                  <Label htmlFor="validationCustom01">
                    {props.t("End Date")}
                  </Label>
                  <Input
                    name="end_date"
                    placeholder={props.t("End Date")}
                    type="date"
                    className="form-control"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.end_date || ""}
                    invalid={
                      validation.touched.end_date && validation.errors.end_date
                        ? true
                        : false
                    }
                  />

                  {validation.touched.end_date && validation.errors.end_date ? (
                    <div
                      style={{
                        color: "#f46a6a",
                        fontSize: "11px",
                        marginTop: "3px",
                      }}
                    >
                      {validation.errors.end_date}
                    </div>
                  ) : null}
                </FormGroup>
              </Col>
            </>
          ) : (
            <></>
          )}

          <Col md="3" className="mt-4" style={{ paddingTop: 5 }}>
            <Button
              color="primary"
              // onClick={handleClickCustomSearch()}
            >
              {props.t("Search")}
            </Button>

            <ExcelFile
              filename="login-history"
              element={
                <Button
                  style={{ float: "right" }}
                  onClick={() => getLoginHistoryWithoutLimit()}
                >
                  {" "}
                  {props.t("exportToExcel")}
                </Button>
              }
            >
              <ExcelSheet data={loginHistoryExcelData} name="LoginHistory">
                <ExcelColumn label={props.t("Sr_No")} value="id" />
                <ExcelColumn
                  label={props.t("Accessed_Date")}
                  value="login_date"
                />
                <ExcelColumn
                  label={props.t("TypeOfUser")}
                  value="user_role_type"
                />
                <ExcelColumn label={props.t("Username")} value="user_name" />
                <ExcelColumn label={props.t("Full_Name")} value="name_en" />
                <ExcelColumn label={props.t("IP_Address")} value="ip_address" />
              </ExcelSheet>
            </ExcelFile>
          </Col>

          {/* <Col md="3" className="mt-4" style={{ paddingTop: 5 }}>
            <CSVLink data={csvData} filename={`login-report`} className="btn btn-secondary">Export To CSV</CSVLink>
          </Col> */}
        </Row>
      </Form>

      <div className="container-fluid">
        <DataTable
          className="table-bordered"
          progressPending={loading}
          columns={columns}
          data={loginHostory}
          pagination
          paginationServer
          paginationTotalRows={activeData.totalPage}
          paginationPerPage={activeData.limit}
          defaultSortFieldID={1}
          onChangeRowsPerPage={value => handleRowChange(value)}
          onChangePage={value => handleChange(value)}
          sortable
          noHeader
          defaultSortField="id"
          defaultSortAsc={false}
          highlightOnHover
        />
      </div>
    </div>
  )
}
LoginHistory.propTypes = {
  preGlobalFilteredRows: PropTypes.any,
}

export default withTranslation()(LoginHistory)
