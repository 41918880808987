// src/components/filter.
import React, { useMemo, useState, useEffect } from "react";
import PropTypes from 'prop-types';

import {
    Accordion,
    AccordionHeader,
    AccordionItem,
    Card,
    CardBody,
    CardText,
    CardTitle,
    Col,
    Collapse,
    Container,
    Nav,
    NavItem,
    NavLink,
    Row,
    TabContent,
    TabPane,
    Label,
    Input,
    FormGroup,
    Button,
    FormFeedback,
    Form,
  } from "reactstrap";
  
//import components
import Breadcrumbs from '../../../components/Common/Breadcrumb';
import TableContainer from '../../../components/Common/TableContainer';
import classnames from "classnames";
import { SystemSettingApi } from "../../../apis/SystemSettingApi";
import toastr from "toastr";
import "toastr/build/toastr.min.css";
import Swal from "sweetalert2";


function SystemSetting() {
    
     //meta title
    document.title = "System Setting | MedX";
    const [activeTab, setactiveTab] = useState("1");
    const [settings, setactiveSettings] = useState([])
    useEffect(() => {
        getAllSystemSetting()
    }, []);


    const getAllSystemSetting = () => {
      SystemSettingApi.getAllSystemSetting()
         .then(res => {
          console.log(res.data.settings);
          setactiveSettings(res.data.settings);
         }).catch(err => {
           console.log(err)
         })
     }
  

    const toggle = tab => {
        if (activeTab !== tab) {
          setactiveTab(tab);
        }
      };

      const handleSubmit = (event) => {
        const formData = new FormData(event.currentTarget);
        console.log(formData);
        event.preventDefault();
        const formData1 = {};
        for (let [key, value] of formData.entries()) {
          console.log(key, value);
          formData1[key] = value;
        }
        const values = {'data':formData1} 
        SystemSettingApi.updateSystemSetting(values)
        .then(res => {
            // setLoader(false)
            if (res.data.success) {
                    Swal.fire({
                    text: res.data.message,
                    icon: 'success',
                    imageAlt: 'success image',
                    // confirmButtonColor: '#00CA84'
                    }).then((result) => {
                    if (result.isConfirmed) {
                        //history.push('/roles');
                    }
                    });
            } else {
                toastr.error(res.message, '');
            }
        }).catch(function (error) {
        toastr.error(error.response.data.message, '');
        });
        //console.log('-->', formData2);
      };

    return (
        <div className="page-content">
            <div className="container-fluid">
                <Breadcrumbs title="Setting" breadcrumbItem="System Setting" />
                <center><h1>Coming Soon</h1></center>
                <Col xl={12}>
              {/* <Card>
                <CardBody>
                  <Nav tabs>
                    <NavItem>
                      <NavLink
                        style={{ cursor: "pointer" }}
                        className={classnames({
                          active: activeTab === "1",
                        })}
                        onClick={() => {
                          toggle("1");
                        }}
                      >
                        GENERAL
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        style={{ cursor: "pointer" }}
                        className={classnames({
                          active: activeTab === "2",
                        })}
                        onClick={() => {
                          toggle("2");
                        }}
                      >
                        SMTP
                      </NavLink>
                    </NavItem>
                   
                  </Nav>
                  <form onSubmit={handleSubmit}> 
                  <TabContent activeTab={activeTab} className="p-3 text-muted">
                    <TabPane tabId="1">
                      <Row>
                      {
                      settings.map((main, i) => {
                          if (main.tab == 'GENERAL') { 
                            return ( <>
                              {main.childrens.map((child, j) => {
                                    return (
                                          <Col sm={6} key={child.id}>
                                          <div className="mb-3">
                                          <Label className="form-label">{child.comment}</Label>
                                          <Input
                                          name={child.config_key}
                                          placeholder={child.comment}
                                          type="text"
                                          defaultValue={child.config_value}
                                          />
                                          </div>
                                          </Col>
                                        )
                                  })}
                              </>
                            )
                          }
                      })
                      }

                   
                    
                      </Row>
                    </TabPane>
                    <TabPane tabId="2">
                      <Row>
                      {
                      settings.map((main, i) => {
                          if (main.tab == 'SMTP') { 
                            return ( <>
                              {main.childrens.map((child, j) => {
                                    return (
                                          <Col sm={6} key={child.id}>
                                          <div className="mb-3">
                                          <Label className="form-label">{child.comment}</Label>
                                          <Input
                                          name={child.config_key}
                                          placeholder={child.comment}
                                          type="text"
                                          defaultValue={child.config_value}
                                          />
                                          </div>
                                          </Col>
                                        )
                                  })}
                              </>
                            )
                          }
                      })
                      }
                      </Row>
                    </TabPane>
                  </TabContent>
               
                    <div className="d-flex flex-wrap gap-2">
                      <Button type="submit" color="primary" >
                        Submit
                      </Button>{" "}
                      <Button type="reset" color="secondary" >
                        Cancel
                      </Button>
                    </div>

                  </form>     
                </CardBody>
              </Card> */}
            </Col>
            </div>
        </div>
    );
}
SystemSetting.propTypes = {
    preGlobalFilteredRows: PropTypes.any,

};


export default SystemSetting;