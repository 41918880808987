import React, { useEffect, useState } from "react"
import Select from "react-select"
import {
  Button,
  Card,
  CardBody,
  CardImg,
  CardText,
  Col,
  Container,
  Form,
  FormFeedback,
  Input,
  Label,
  Row,
} from "reactstrap"
import { useFormik } from "formik"
import PropTypes from "prop-types"
import toastr from "toastr"
import "toastr/build/toastr.min.css"
import * as Yup from "yup"
import { withTranslation } from "react-i18next"
import { useHistory } from "react-router-dom"
import Swal from "sweetalert2"
import config from "config/config"
import constants from "../../config/constants"
import { CompaniesApi } from "apis/CompaniesApi"
import { ProductApi } from "apis/ProductApi"
import Breadcrumb from "components/Common/Breadcrumb"
import { ContentState, EditorState, convertFromHTML } from "draft-js"
import { convertEditorContentTextToHtml } from "helpers/common_helper"
import { Editor } from "react-draft-wysiwyg"

const EditProduct = props => {
  const product_id = props.match.params.product_id

  const [desContentTh, setdesContentTh] = useState(() =>
    EditorState.createEmpty()
  )
  const [desContentEn, setdesContentEn] = useState(() =>
    EditorState.createEmpty()
  )
  const [compositionContent, setcompositionContent] = useState(() =>
    EditorState.createEmpty()
  )
  const [pharmaceuticalContent, setPharmaceuticalContent] = useState(() =>
    EditorState.createEmpty()
  )
  const [indicationContent, setIndicationContent] = useState(() =>
    EditorState.createEmpty()
  )
  const [doseAndAdministrationContent, setDoseAndAdministrationContent] =
    useState(() => EditorState.createEmpty())
  const [contraindicationContent, setContraindicationContent] = useState(() =>
    EditorState.createEmpty()
  )
  const [precautionsContent, setPrecautionsContent] = useState(() =>
    EditorState.createEmpty()
  )
  const [interactionContent, setInteractionContent] = useState(() =>
    EditorState.createEmpty()
  )

  const [files1, setFiles1] = useState([])
  const [product, setProduct] = useState({})
  const [companyList, setComapnyList] = useState([])
  const [defualtCompany, setdefualtCompany] = useState({})
  const [productImages, setProductImages] = useState([])
  const history = useHistory()
  const [loading, setLoading] = useState(false)
  const [activeData, setActiveData] = useState({
    page: 1,
    totalPage: 1,
    search: "",
    limit: config.LIMIT,
  })
  const [isFormSubmited, setIsFormSubmited] = useState(false)

  const getProductById = () => {
    ProductApi.getProductById({ product_id })
      .then(res => {
        setProduct(res.data.data)
        let descriptionEn = res.data.data.description_en
        if (descriptionEn) {
          descriptionEn = descriptionEn.replace(/<[\S]+?><\/[\S]+>/gim, "")
          setdesContentEn(
            EditorState.createWithContent(
              ContentState.createFromBlockArray(convertFromHTML(descriptionEn))
            )
          )
        }
        let descriptionTh = res.data.data.description_th
        if (descriptionTh) {
          descriptionTh = descriptionTh.replace(/<[\S]+?><\/[\S]+>/gim, "")
          setdesContentTh(
            EditorState.createWithContent(
              ContentState.createFromBlockArray(convertFromHTML(descriptionTh))
            )
          )
        }
        let composition = res.data.data.composition
        if (composition) {
          composition = composition.replace(/<[\S]+?><\/[\S]+>/gim, "")
          setcompositionContent(
            EditorState.createWithContent(
              ContentState.createFromBlockArray(convertFromHTML(composition))
            )
          )
        }
        let pharmaceuticalDoseForm = res.data.data.pharmaceutical_dose_form
        if (pharmaceuticalDoseForm) {
          pharmaceuticalDoseForm = pharmaceuticalDoseForm.replace(
            /<[\S]+?><\/[\S]+>/gim,
            ""
          )
          setPharmaceuticalContent(
            EditorState.createWithContent(
              ContentState.createFromBlockArray(
                convertFromHTML(pharmaceuticalDoseForm)
              )
            )
          )
        }
        let indication = res.data.data.indication
        if (indication) {
          indication = indication.replace(/<[\S]+?><\/[\S]+>/gim, "")
          setIndicationContent(
            EditorState.createWithContent(
              ContentState.createFromBlockArray(convertFromHTML(indication))
            )
          )
        }
        let doseAndAdministration = res.data.data.dose_and_administration
        if (doseAndAdministration) {
          doseAndAdministration = doseAndAdministration.replace(
            /<[\S]+?><\/[\S]+>/gim,
            ""
          )
          setDoseAndAdministrationContent(
            EditorState.createWithContent(
              ContentState.createFromBlockArray(
                convertFromHTML(doseAndAdministration)
              )
            )
          )
        }
        let contraindications = res.data.data.contraindications
        if (contraindications) {
          contraindications = contraindications.replace(
            /<[\S]+?><\/[\S]+>/gim,
            ""
          )
          setContraindicationContent(
            EditorState.createWithContent(
              ContentState.createFromBlockArray(
                convertFromHTML(contraindications)
              )
            )
          )
        }
        let precautions = res.data.data.precautions
        if (precautions) {
          precautions = precautions.replace(/<[\S]+?><\/[\S]+>/gim, "")
          setPrecautionsContent(
            EditorState.createWithContent(
              ContentState.createFromBlockArray(convertFromHTML(precautions))
            )
          )
        }
        let interaction = res.data.data.interaction
        if (interaction) {
          interaction = interaction.replace(/<[\S]+?><\/[\S]+>/gim, "")
          setInteractionContent(
            EditorState.createWithContent(
              ContentState.createFromBlockArray(convertFromHTML(interaction))
            )
          )
        }
        setProductImages(res.data?.data?.ProductImages)
        setdefualtCompany({
          value: res.data.data?.Company.id,
          label: res.data.data.Company.name_en,
        })
      })
      .catch(err => {
        console.log(err)
      })
  }

  const getAllCompanies = () => {
    setLoading(true)
    CompaniesApi.fetchAllCompanies()
      .then(res => {
        setComapnyList(res.data.data)
        setLoading(false)
      })
      .catch(err => {
        setComapnyList([])
      })
  }

  useEffect(() => {
    getProductById()
    getAllCompanies()
  }, [])

  const removeMedia = (product_id, product_image_id) => {
    Swal.fire({
      title: props.t("Are_you_sure"),
      text: props.t("Able_to_revert_this"),
      icon: props.t("Warning"),
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#f46a6a",
      confirmButtonText: props.t("Yes_delete_it"),
      cancelButtonText: props.t("Cancel"),
    }).then(result => {
      if (result.isConfirmed) {
        let payload = {
          product_id: product_id,
          product_image_id: product_image_id,
        }
        ProductApi.removeProductImage(payload)
          .then(res => {
            setProductImages(res?.data?.data.rows)
          })
          .catch(err => {
            console.log(err)
          })
      }
    })
  }

  // Form validation
  const validationType = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: {
      company_id: product.company_id ?? "",
      name_en: product.name_en ?? "",
      name_th: product.name_th ?? "",
      description_en: product.description_en ?? "",
      description_th: product.description_th ?? "",
      image_url: product.image_url ?? "",
      license_name: product.license_name ?? "",
      distributor_name: product.distributor_name ?? "",
      authorisation_number: product.authorisation_number ?? "",
      composition: product.composition ?? "",
      pharmaceutical_dose_form: product.pharmaceutical_dose_form ?? "",
      indication: product.indication ?? "",
      dose_and_administration: product.dose_and_administration ?? "",
      contraindications: product.contraindications ?? "",
      precautions: product.precautions ?? "",
      interaction: product.interaction ?? "",
      product_images: null,
    },
    validationSchema: Yup.object().shape({
      name_en: Yup.string().required(props.t("Required-Message")),
      name_th: Yup.string().required(props.t("Required-Message")),
      company_id: Yup.string().required(props.t("Required-Message")),
      description_en: Yup.string().required(props.t("Required-Message")),
      description_th: Yup.string().required(props.t("Required-Message")),
      image_url: Yup.mixed().required(props.t("File-Required-Message")),
      product_images: Yup.mixed()
        .test(
          "fileSize",
          props.t("File_Size_is_too_large") + " Max Size is 2 MB",
          value => {
            if (value && value?.length > 0) {
              for (let i = 0; i < value.length; i++) {
                if (value[i].size > constants.IMAGE_VALIDATION.PRODUCT_IMAGE) {
                  return false
                }
              }
            }
            return true
          }
        )
        .test("fileType", props.t("Unsupported_File_Format"), value => {
          if (value && value.length > 0) {
            for (let i = 0; i < value.length; i++) {
              if (
                value[i].type != "image/png" &&
                value[i].type != "image/jpg" &&
                value[i].type != "image/jpeg"
              ) {
                return false
              }
            }
          }
          return true
        }),
    }),
    onSubmit: values => {
      const data = new FormData()
      for (let i = 0; i < values.product_images?.length; i++) {
        data.append("product_images", values.product_images[i])
      }
      data.append("company_id", values.company_id)
      data.append("name_en", values.name_en)
      data.append("name_th", values.name_th)
      data.append("description_en", values.description_en)
      data.append("description_th", values.description_th)
      data.append("license_name", values.license_name)
      data.append("distributor_name", values.distributor_name)
      data.append("authorisation_number", values.authorisation_number)
      data.append("composition", values.composition)
      data.append("pharmaceutical_dose_form", values.pharmaceutical_dose_form)
      data.append("indication", values.indication)
      data.append("dose_and_administration", values.dose_and_administration)
      data.append("contraindications", values.contraindications)
      data.append("precautions", values.precautions)
      data.append("interaction", values.interaction)
      data.append("image_url", files1)
      data.append("product_id", product_id)
      ProductApi.updateProduct(data)
        .then(res => {
          if (res.data.success) {
            Swal.fire({
              text: res.data.message,
              icon: "success",
              imageAlt: "success image",
            }).then(result => {
              if (result?.isConfirmed) {
                history.push("/products")
                formClear()
                setFiles1()
              }
            })
          } else {
            toastr.error(res?.data?.message, "")
          }
        })
        .catch(function (error) {
          if (error?.response?.data?.message) {
            toastr.error(error?.response?.data?.message, "")
          } else {
            toastr.error(
              error?.response?.data?.error?.errors?.map(
                (item, v) => `${item.msg}<br>`
              ),
              ""
            )
          }
        })
    },
  })

  useEffect(() => {
    if (isFormSubmited) {
      const { errors } = validationType
      const keys = Object.keys(errors)
      if (keys.length > 0) {
        console.log(keys[0])
        const errorElement = document.getElementById(keys[0])
        errorElement.focus()
        setIsFormSubmited(false)
      }
    }
  }, [isFormSubmited, validationType])

  const formClear = () => {
    validationType.resetForm()
    setFiles1([])
  }

  const removeImage = lastModified => {
    const updatedList = [
      ...validationType.values.product_images.filter(
        e => e.lastModified !== lastModified
      ),
    ]
    validationType.setFieldValue(
      "product_images",
      updatedList.length > 0 ? updatedList : null
    )
  }

  const onChangeDescThContentHandler = async state => {
    setdesContentTh(state)
    const contentHtml = await convertEditorContentTextToHtml(state)
    validationType.setFieldValue("description_th", contentHtml)
  }

  const onChangeDescEnContentHandler = async state => {
    setdesContentEn(state)
    const contentHtml = await convertEditorContentTextToHtml(state)
    validationType.setFieldValue("description_en", contentHtml)
  }

  const onChangeCompositionContentHandler = async state => {
    setcompositionContent(state)
    const contentHtml = await convertEditorContentTextToHtml(state)
    validationType.setFieldValue("composition", contentHtml)
  }

  const onChangePharmaceuticalContentHandler = async state => {
    setPharmaceuticalContent(state)
    const contentHtml = await convertEditorContentTextToHtml(state)
    validationType.setFieldValue("pharmaceutical_dose_form", contentHtml)
  }

  const onChangeIndicationContentHandler = async state => {
    setIndicationContent(state)
    const contentHtml = await convertEditorContentTextToHtml(state)
    validationType.setFieldValue("indication", contentHtml)
  }

  const onChangeDoseAndAdministrationContentHandler = async state => {
    setDoseAndAdministrationContent(state)
    const contentHtml = await convertEditorContentTextToHtml(state)
    validationType.setFieldValue("dose_and_administration", contentHtml)
  }

  const onChangeContraindicationsContentHandler = async state => {
    setContraindicationContent(state)
    const contentHtml = await convertEditorContentTextToHtml(state)
    validationType.setFieldValue("contraindications", contentHtml)
  }

  const onChangePrecautionsContentHandler = async state => {
    setPrecautionsContent(state)
    const contentHtml = await convertEditorContentTextToHtml(state)
    validationType.setFieldValue("precautions", contentHtml)
  }

  const onChangeInteractionContentHandler = async state => {
    setInteractionContent(state)
    const contentHtml = await convertEditorContentTextToHtml(state)
    validationType.setFieldValue("interaction", contentHtml)
  }

  document.title = props.t("Products") + " | " + props.t("MedX")

  return (
    <React.Fragment>
      <div className="page-content">
        <Breadcrumb
          title={props.t("Product")}
          breadcrumbItem={props.t("Edit") + " " + props.t("Product")}
        />
        <Container fluid={true}>
          <Row>
            <Col lg={12}>
              <Card>
                <CardBody>
                  <Col sm={12}>
                    <Form
                      onSubmit={e => {
                        e.preventDefault()
                        validationType.handleSubmit()
                        setIsFormSubmited(true)
                        return false
                      }}
                    >
                      <Col sm={12}>
                        <div className="mb-3">
                          <Label className="form-label">
                            {props.t("Name_Thai")}{" "}
                            <span className="text-danger">*</span>
                          </Label>
                          <Input
                            name="name_th"
                            id="name_th"
                            placeholder={props.t("Name_Thai")}
                            type="text"
                            onChange={validationType.handleChange}
                            onBlur={validationType.handleBlur}
                            value={validationType.values.name_th || ""}
                            invalid={
                              validationType.touched.name_th &&
                              validationType.errors.name_th
                                ? true
                                : false
                            }
                          />
                          {validationType.touched.name_th &&
                          validationType.errors.name_th ? (
                            <FormFeedback type="invalid">
                              {validationType.errors.name_th}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </Col>
                      <Col sm={12}>
                        <div className="mb-3">
                          <Label className="form-label">
                            {props.t("Name_Eng")}{" "}
                            <span className="text-danger">*</span>
                          </Label>
                          <Input
                            name="name_en"
                            id="name_en"
                            placeholder={props.t("Name_Eng")}
                            type="text"
                            onChange={validationType.handleChange}
                            onBlur={validationType.handleBlur}
                            value={validationType.values.name_en || ""}
                            invalid={
                              validationType.touched.name_en &&
                              validationType.errors.name_en
                                ? true
                                : false
                            }
                          />
                          {validationType.touched.name_en &&
                          validationType.errors.name_en ? (
                            <FormFeedback type="invalid">
                              {validationType.errors.name_en}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </Col>
                      <Col sm={12}>
                        <div className="mb-3 product-company">
                          <Label className="form-label">
                            {props.t("Company")}{" "}
                            <span className="text-danger">*</span>
                          </Label>
                          <Select
                            onChange={e => {
                              validationType.setFieldValue(
                                "company_id",
                                e.value
                              )
                              setdefualtCompany(e)
                            }}
                            onBlur={validationType.handleBlur}
                            styles={{
                              control: styles => ({
                                ...styles,
                              }),
                            }}
                            name="company_id"
                            inputId="company_id"
                            value={defualtCompany}
                            className={
                              validationType.touched.company_id &&
                              validationType.errors.company_id
                                ? " bor"
                                : " text-dark"
                            }
                            options={companyList.map(value => ({
                              value: `${value.id}`,
                              label: `${value.name_en}`,
                            }))}
                          />
                          {validationType.touched.company_id &&
                          validationType.errors.company_id ? (
                            <div
                              style={{
                                color: "#f46a6a",
                                fontSize: "11px",
                                marginTop: "3px",
                              }}
                            >
                              {validationType.errors.company_id}
                            </div>
                          ) : null}
                        </div>
                      </Col>
                      <Col sm={12}>
                        <div className="mb-3">
                          <Label
                            className="form-label"
                            tabIndex={0}
                            id="description_th"
                          >
                            {props.t("Description_Th")}{" "}
                            <span className="text-danger">*</span>
                          </Label>
                          <Editor
                            name="description_th"
                            editorState={desContentTh}
                            onEditorStateChange={onChangeDescThContentHandler}
                            toolbarClassName="toolbarClassName"
                            wrapperClassName="wrapperClassName"
                            editorClassName="editorClassName"
                            styles={{
                              control: (provided, state) => ({
                                ...provided,
                                borderColor: validationType.errors
                                  .description_th
                                  ? "#f46a6a"
                                  : "#ccc",
                              }),
                            }}
                          />
                          {validationType.errors.description_th ? (
                            <div className="red-text">
                              {validationType.errors.description_th}
                            </div>
                          ) : null}
                        </div>
                      </Col>
                      <Col sm={12}>
                        <div className="mb-3">
                          <Label
                            className="form-label"
                            tabIndex={0}
                            id="description_en"
                          >
                            {props.t("Description_En")}{" "}
                            <span className="text-danger">*</span>
                          </Label>
                          <Editor
                            name="description_en"
                            editorState={desContentEn}
                            onEditorStateChange={onChangeDescEnContentHandler}
                            toolbarClassName="toolbarClassName"
                            wrapperClassName="wrapperClassName"
                            editorClassName="editorClassName"
                            styles={{
                              control: (provided, state) => ({
                                ...provided,
                                borderColor: validationType.errors
                                  .description_en
                                  ? "#f46a6a"
                                  : "#ccc",
                              }),
                            }}
                          />
                          {validationType.errors.description_en ? (
                            <div className="red-text">
                              {validationType.errors.description_en}
                            </div>
                          ) : null}
                        </div>
                      </Col>
                      <Col sm={12}>
                        <div className="mb-3">
                          <Label htmlFor="logo">
                            {props.t("Image")}{" "}
                            <span className="text-danger">*</span>
                          </Label>
                          <Input
                            name="image_url"
                            id="image_url"
                            type="file"
                            className="form-control"
                            onChange={e => {
                              setFiles1(e.target.files[0])
                              validationType.setFieldValue(
                                "image_url",
                                e.target.files[0]
                              )
                            }}
                            invalid={
                              validationType.touched.image_url &&
                              validationType.errors.image_url
                                ? true
                                : false
                            }
                          />
                          {validationType.touched.image_url &&
                          validationType.errors.image_url ? (
                            <div
                              style={{
                                color: "#f46a6a",
                                fontSize: "11px",
                                marginTop: "3px",
                              }}
                            >
                              {validationType.errors.image_url}
                            </div>
                          ) : null}
                        </div>
                      </Col>

                      <Col sm={12}>
                        <div className="mb-3">
                          <Label className="form-label">
                            {props.t("License_Name")}
                          </Label>
                          <Input
                            name="license_name"
                            id="license_name"
                            placeholder={props.t("License_Name")}
                            type="text"
                            onChange={validationType.handleChange}
                            onBlur={validationType.handleBlur}
                            value={validationType.values.license_name || ""}
                            invalid={
                              validationType.touched.license_name &&
                              validationType.errors.license_name
                                ? true
                                : false
                            }
                          />
                          {validationType.touched.license_name &&
                          validationType.errors.license_name ? (
                            <FormFeedback type="invalid">
                              {validationType.errors.license_name}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </Col>

                      <Col sm={12}>
                        <div className="mb-3">
                          <Label className="form-label">
                            {props.t("Distributor_Name")}
                          </Label>
                          <Input
                            name="distributor_name"
                            id="distributor_name"
                            placeholder={props.t("Distributor_Name")}
                            type="text"
                            onChange={validationType.handleChange}
                            onBlur={validationType.handleBlur}
                            value={validationType.values.distributor_name || ""}
                            invalid={
                              validationType.touched.distributor_name &&
                              validationType.errors.distributor_name
                                ? true
                                : false
                            }
                          />
                          {validationType.touched.distributor_name &&
                          validationType.errors.distributor_name ? (
                            <FormFeedback type="invalid">
                              {validationType.errors.distributor_name}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </Col>

                      <Col sm={12}>
                        <div className="mb-3">
                          <Label className="form-label">
                            {props.t("Marketing_Authorisation_Number")}
                          </Label>
                          <Input
                            name="authorisation_number"
                            id="authorisation_number"
                            placeholder={props.t(
                              "Marketing_Authorisation_Number"
                            )}
                            type="text"
                            onChange={validationType.handleChange}
                            onBlur={validationType.handleBlur}
                            value={
                              validationType.values.authorisation_number || ""
                            }
                            invalid={
                              validationType.touched.authorisation_number &&
                              validationType.errors.authorisation_number
                                ? true
                                : false
                            }
                          />
                          {validationType.touched.authorisation_number &&
                          validationType.errors.authorisation_number ? (
                            <FormFeedback type="invalid">
                              {validationType.errors.authorisation_number}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </Col>

                      <Col sm={12}>
                        <div className="mb-3">
                          <Label className="form-label">
                            {props.t("Qualitative_Quantitative_Composition")}
                          </Label>
                          <Editor
                            name="composition"
                            id="composition"
                            editorState={compositionContent}
                            onEditorStateChange={
                              onChangeCompositionContentHandler
                            }
                            toolbarClassName="toolbarClassName"
                            wrapperClassName="wrapperClassName"
                            editorClassName="editorClassName"
                            styles={{
                              control: (provided, state) => ({
                                ...provided,
                                borderColor:
                                  validationType.touched.composition &&
                                  validationType.touched.composition &&
                                  validationType.touched.composition &&
                                  validationType.errors.composition
                                    ? "#f46a6a"
                                    : "#ccc",
                              }),
                            }}
                          />
                          {validationType.touched.composition &&
                          validationType.errors.composition ? (
                            <FormFeedback type="invalid">
                              {validationType.errors.composition}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </Col>

                      <Col sm={12}>
                        <div className="mb-3">
                          <Label className="form-label">
                            {props.t("Pharmaceutical_Dose_Form")}
                          </Label>
                          <Editor
                            name="pharmaceutical_dose_form"
                            id="pharmaceutical_dose_form"
                            editorState={pharmaceuticalContent}
                            onEditorStateChange={
                              onChangePharmaceuticalContentHandler
                            }
                            toolbarClassName="toolbarClassName"
                            wrapperClassName="wrapperClassName"
                            editorClassName="editorClassName"
                            styles={{
                              control: (provided, state) => ({
                                ...provided,
                                borderColor:
                                  validationType.touched
                                    .pharmaceutical_dose_form &&
                                  validationType.touched
                                    .pharmaceutical_dose_form &&
                                  validationType.touched
                                    .pharmaceutical_dose_form &&
                                  validationType.errors.pharmaceutical_dose_form
                                    ? "#f46a6a"
                                    : "#ccc",
                              }),
                            }}
                          />
                          {validationType.touched.pharmaceutical_dose_form &&
                          validationType.errors.pharmaceutical_dose_form ? (
                            <FormFeedback type="invalid">
                              {validationType.errors.pharmaceutical_dose_form}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </Col>

                      <Col sm={12}>
                        <div className="mb-3">
                          <Label className="form-label">
                            {props.t("Indication")}
                          </Label>
                          <Editor
                            name="indication"
                            id="indication"
                            editorState={indicationContent}
                            onEditorStateChange={
                              onChangeIndicationContentHandler
                            }
                            toolbarClassName="toolbarClassName"
                            wrapperClassName="wrapperClassName"
                            editorClassName="editorClassName"
                            styles={{
                              control: (provided, state) => ({
                                ...provided,
                                borderColor:
                                  validationType.touched.indication &&
                                  validationType.touched.indication &&
                                  validationType.touched.indication &&
                                  validationType.errors.indication
                                    ? "#f46a6a"
                                    : "#ccc",
                              }),
                            }}
                          />
                          {validationType.touched.indication &&
                          validationType.errors.indication ? (
                            <FormFeedback type="invalid">
                              {validationType.errors.indication}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </Col>

                      <Col sm={12}>
                        <div className="mb-3">
                          <Label className="form-label">
                            {props.t("Dose_And_Administration")}
                          </Label>
                          <Editor
                            name="dose_and_administration"
                            id="dose_and_administration"
                            editorState={doseAndAdministrationContent}
                            onEditorStateChange={
                              onChangeDoseAndAdministrationContentHandler
                            }
                            toolbarClassName="toolbarClassName"
                            wrapperClassName="wrapperClassName"
                            editorClassName="editorClassName"
                            styles={{
                              control: (provided, state) => ({
                                ...provided,
                                borderColor:
                                  validationType.touched
                                    .dose_and_administration &&
                                  validationType.touched
                                    .dose_and_administration &&
                                  validationType.touched
                                    .dose_and_administration &&
                                  validationType.errors.dose_and_administration
                                    ? "#f46a6a"
                                    : "#ccc",
                              }),
                            }}
                          />
                          {validationType.touched.dose_and_administration &&
                          validationType.errors.dose_and_administration ? (
                            <FormFeedback type="invalid">
                              {validationType.errors.dose_and_administration}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </Col>

                      <Col sm={12}>
                        <div className="mb-3">
                          <Label className="form-label">
                            {props.t("Contraindications")}
                          </Label>
                          <Editor
                            name="contraindications"
                            id="contraindications"
                            editorState={contraindicationContent}
                            onEditorStateChange={
                              onChangeContraindicationsContentHandler
                            }
                            toolbarClassName="toolbarClassName"
                            wrapperClassName="wrapperClassName"
                            editorClassName="editorClassName"
                            styles={{
                              control: (provided, state) => ({
                                ...provided,
                                borderColor:
                                  validationType.touched.contraindications &&
                                  validationType.touched.contraindications &&
                                  validationType.touched.contraindications &&
                                  validationType.errors.contraindications
                                    ? "#f46a6a"
                                    : "#ccc",
                              }),
                            }}
                          />
                          {validationType.touched.contraindications &&
                          validationType.errors.contraindications ? (
                            <FormFeedback type="invalid">
                              {validationType.errors.contraindications}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </Col>

                      <Col sm={12}>
                        <div className="mb-3">
                          <Label className="form-label">
                            {props.t("Special_Warnings_Precautions")}
                          </Label>
                          <Editor
                            name="precautions"
                            id="precautions"
                            editorState={precautionsContent}
                            onEditorStateChange={
                              onChangePrecautionsContentHandler
                            }
                            toolbarClassName="toolbarClassName"
                            wrapperClassName="wrapperClassName"
                            editorClassName="editorClassName"
                            styles={{
                              control: (provided, state) => ({
                                ...provided,
                                borderColor:
                                  validationType.touched.precautions &&
                                  validationType.touched.precautions &&
                                  validationType.touched.precautions &&
                                  validationType.errors.precautions
                                    ? "#f46a6a"
                                    : "#ccc",
                              }),
                            }}
                          />
                          {validationType.touched.precautions &&
                          validationType.errors.precautions ? (
                            <FormFeedback type="invalid">
                              {validationType.errors.precautions}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </Col>

                      <Col sm={12}>
                        <div className="mb-3">
                          <Label className="form-label">
                            {props.t("Interaction")}
                          </Label>
                          <Editor
                            name="interaction"
                            id="interaction"
                            editorState={interactionContent}
                            onEditorStateChange={
                              onChangeInteractionContentHandler
                            }
                            toolbarClassName="toolbarClassName"
                            wrapperClassName="wrapperClassName"
                            editorClassName="editorClassName"
                            styles={{
                              control: (provided, state) => ({
                                ...provided,
                                borderColor:
                                  validationType.touched.interaction &&
                                  validationType.touched.interaction &&
                                  validationType.touched.interaction &&
                                  validationType.errors.interaction
                                    ? "#f46a6a"
                                    : "#ccc",
                              }),
                            }}
                          />
                          {validationType.touched.interaction &&
                          validationType.errors.interaction ? (
                            <FormFeedback type="invalid">
                              {validationType.errors.interaction}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </Col>

                      <Col sm={12}>
                        <div className="mb-3">
                          <Label className="form-label">
                            {props.t("Images")}
                            <span className="text-danger">
                              {" "}
                              ({props.t("Note")} :{" "}
                              {props.t("Image_File_Extensions")})
                            </span>
                          </Label>
                          <Input
                            name="product_images"
                            id="product_images"
                            type="file"
                            multiple
                            className="form-control"
                            onBlur={e => {
                              if (
                                e.target.files.length == 0 &&
                                validationType.values.product_images &&
                                validationType.values.product_images.length == 0
                              ) {
                                validationType.setFieldValue(
                                  "product_images",
                                  null
                                )
                              }
                            }}
                            onChange={e => {
                              const filesArray2 = []
                              for (let i = 0; i < e.target.files.length; i++) {
                                filesArray2.push(e.target.files[i])
                              }
                              validationType.setFieldValue(
                                "product_images",
                                validationType.values.product_images
                                  ? [
                                      ...validationType.values.product_images,
                                      ...filesArray2,
                                    ]
                                  : filesArray2
                              )
                            }}
                            invalid={
                              validationType.touched.product_images &&
                              validationType.errors.product_images
                                ? true
                                : false
                            }
                          />
                          <span className="text-danger">
                            {props.t("Size")} ((310 X 310))
                          </span>
                          {validationType.touched.product_images &&
                          validationType.errors.product_images ? (
                            <div
                              style={{
                                color: "#f46a6a",
                                fontSize: "11px",
                                marginTop: "3px",
                              }}
                            >
                              {validationType.errors.product_images}
                            </div>
                          ) : null}
                        </div>
                        <div className="d-flex  px-3 py-2 pb-4">
                          {productImages?.length > 0
                            ? productImages?.map((item, i) => {
                                return (
                                  <Col sm={1} key={i}>
                                    <CardImg
                                      src={item.file_url}
                                      alt="SkoteSkote"
                                      className="rounded avatar-sm"
                                    />
                                    <CardText className="mt-2 mb-lg-0">
                                      <a
                                        className="text-danger"
                                        type="button"
                                        onClick={() =>
                                          removeMedia(item.product_id, item.id)
                                        }
                                      >
                                        {props.t("Remove")}
                                      </a>
                                    </CardText>
                                  </Col>
                                )
                              })
                            : null}

                          {validationType.values.product_images?.length > 0
                            ? validationType.values.product_images?.map(
                                (item, index) => {
                                  return (
                                    <Col sm={1} key={index}>
                                      <CardImg
                                        src={URL.createObjectURL(item)}
                                        alt="SkoteSkote"
                                        className="rounded avatar-sm"
                                      />
                                      <CardText
                                        class
                                        Name="mt-2 mb-lg-0 card-text"
                                      >
                                        <a
                                          className="text-danger"
                                          type="button"
                                          onClick={() => {
                                            removeImage(item.lastModified)
                                          }}
                                        >
                                          {props.t("Remove")}
                                        </a>
                                      </CardText>
                                    </Col>
                                  )
                                }
                              )
                            : null}
                        </div>
                      </Col>

                      <div className="d-flex flex-wrap gap-2">
                        <Button type="submit" color="primary">
                          {props.t("Submit")}
                        </Button>{" "}
                        <Button color="secondary" onClick={e => formClear()}>
                          {props.t("Clear")}
                        </Button>
                      </div>
                    </Form>
                  </Col>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

EditProduct.propTypes = {
  t: PropTypes.any,
}

export default withTranslation()(EditProduct)
