import PropTypes from "prop-types";
import React, { useEffect, useRef, useState } from "react";

// //Import Scrollbar
import SimpleBar from "simplebar-react";

// MetisMenu
import MetisMenu from "metismenujs";
import { withRouter } from "react-router-dom";
import { Link } from "react-router-dom";

//i18n
import { withTranslation } from "react-i18next";
import { AdminApi } from "../../apis/AdminApi";
import { useDispatch, useSelector } from "react-redux";
import { saveSideNavData } from "store/actions";
import { getRoleID } from "../../Token"
import { ActivityApi } from "../../apis/ActivityApi";
import constants from "../../config/constants"

const SidebarContent = props => {
  const {sideNavData: rolemodules} = useSelector(state => ({ sideNavData: state.Layout.sideNavData }))
// console.log(rolemodules,"SND")
  const dispatch = useDispatch();
  const [termsAndCondtionData, setTermsAndCondtionData] = useState("");
  const [activityRequestCount, setActivityRequestCount] = useState(0);
  const ref = useRef();
  // Use ComponentDidMount and ComponentDidUpdate method symultaniously
  useEffect(() => {
    const pathName = props.location.pathname;
    const initMenu = () => {
      new MetisMenu("#side-menu");
      let matchingMenuItem = null;
      const ul = document.getElementById("side-menu");
      const items = ul.getElementsByTagName("a");
      for (let i = 0; i < items.length; ++i) {
        if (pathName === items[i].pathname) {
          matchingMenuItem = items[i];
          break;
        }
      }

      if (matchingMenuItem) {
        activateParentDropdown(matchingMenuItem);
        getActivityRequestCount();
      }
    };

    const getActivityRequestCount = () => {
      ActivityApi.getActivityRequestCount()
        .then(res => {
          if (res.data.success) {
            setActivityRequestCount(res.data.data);
          } else {
            setActivityRequestCount(0);
          }
        }).catch(err => {
          console.log(err)
        })
    }

    const getAllModule = () => {
      AdminApi.getAllModule()
        .then(res => {
          if (res.data.success) {
            dispatch(saveSideNavData(res.data.modules));
            setTimeout(() => {
              initMenu();
            }, 100)
          }
        }).catch(err => {
          console.log(err)
        })
    }
    if(rolemodules.length <= 0) {
      getAllModule();
      getActivityRequestCount();
    }
    else
      initMenu();

  }, [props.location.pathname]);

  useEffect(() => {
    ref.current.recalculate();
    //getTermsAndCondtion();
  });

  const getTermsAndCondtion = async () => {
    AdminApi.cmsPageByTypeApi("TermsAndCondition")
      .then((res) => {
        if (res.data.success) {
          setTermsAndCondtionData(res.data.data);
          // $("#terms").modal({ backdrop: "static", keyboard: false });
          //  $("#terms").modal("show");
        } else {
          //toastr.error(res?.data?.message, "");
        }
      })
      .catch((err) => {
        //toastr.error(err?.message, "");
      });
  };

  const onAcceptTermsAndCondition = async () => {
    // const payload = {
    //   termsandcondition: true,
    // };
    // HomeApi.addUpdateUserInformationAPI(payload)
    //   .then((res) => {
    //     if (res.data.success) {
    //       window.$("#terms").modal("hide");
    //       if (!isProfileCompleted) {
    //         window.$("#more-information").modal("show");
    //       }
    //       return true;
    //     } else {
    //     }
    //   })
    //   .catch((err) => {
    //     console.log(err);
    //   });
  };

  function scrollElement(item) {
    if (item) {
      const currentPosition = item.offsetTop;
      if (currentPosition > window.innerHeight) {
        ref.current.getScrollElement().scrollTop = currentPosition - 300;
      }
    }
  }

  function activateParentDropdown(item) {
    item.classList.add("active");
    const parent = item.parentElement;
    const parent2El = parent.childNodes[1];
    if (parent2El && parent2El.id !== "side-menu") {
      parent2El.classList.add("mm-show");
    }

    if (parent) {
      parent.classList.add("mm-active");
      const parent2 = parent.parentElement;

      if (parent2) {
        parent2.classList.add("mm-show"); // ul tag

        const parent3 = parent2.parentElement; // li tag

        if (parent3) {
          parent3.classList.add("mm-active"); // li
          parent3.childNodes[0].classList.add("mm-active"); //a
          const parent4 = parent3.parentElement; // ul
          if (parent4) {
            parent4.classList.add("mm-show"); // ul
            const parent5 = parent4.parentElement;
            if (parent5) {
              parent5.classList.add("mm-show"); // li
              parent5.childNodes[0].classList.add("mm-active"); // a tag
            }
          }
        }
      }
      scrollElement(item);
      return false;
    }
    scrollElement(item);
    return false;
  }

  return (
    <React.Fragment>
      <SimpleBar className="h-100" ref={ref}>
        <>
      
        <div id="sidebar-menu">
          <ul className="metismenu list-unstyled" id="side-menu">
            {/* <li className="menu-title">{props.t("Dashboard")}</li> */}
            {rolemodules.map((menu, i) => {
              if (!menu.is_dropdown) {
                return (<li key={`main_${i}`}>
                  {(menu.name === "Activity Requests" && getRoleID() !== constants.ROLE.SUPERADMIN) ? (
                    <>
                      <Link to={`/${menu.action}`}>
                        <i className={menu.icon}></i>
                        <span>{menu.name}</span>
                        { activityRequestCount > 0 &&
                          <span style={{color:"#FF0000", marginLeft:"45px", fontSize:"15px"}}>●</span>
                        }
                      </Link>
                    </>
                  ) : 
                    <Link to={`/${menu.action}`}>
                      <i className={menu.icon}></i>
                      <span>{menu.name}</span>
                    </Link>
                  }
                </li>)
              } else {
                return (<li key={`main_${i}`}>
                  <Link to="/#" className="has-arrow">
                    <i className={menu.icon}></i>
                    <span>{menu.name}</span>
                  </Link>
                  <ul className="sub-menu" key={`ul_${i}`}>
                    {menu.childrens.map((childmenu, j) => {
                      return (<li key={`child_${j}`}>
                        <Link to={`/${childmenu.action}`}>{childmenu.name}</Link>
                      </li>
                      )
                    })}
                  </ul>
                </li>
                )
              }
            })}
          </ul>
        </div>

        
      {/* terms and condition modal */}
      {/* <div className="modal fade in" id="terms">
        <div className="modal-dialog scrollDialog modal-lg"> */}
          {/* <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title">{termsAndCondtionData?.name_en}</h4>
            </div>

            <div className="modal-body scrollAndFixedHeight">
              <div
                dangerouslySetInnerHTML={{
                  __html: termsAndCondtionData?.content_en,
                }}
              />
            </div>

            <div className="modal-footer">
              <div className="d-flex align-items-center justify-content-center">
                <button
                  onClick={onAcceptTermsAndCondition}
                  className="float-right btn btn-blue"
                >
                  {props.t("Accept")}
                </button>
              </div>
            </div>
          </div> */}
        {/* </div>
      </div> */}
       

        </>

      </SimpleBar>
    </React.Fragment>
  );
};

SidebarContent.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
};

export default withRouter(withTranslation()(SidebarContent));
