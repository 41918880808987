// src/components/filter.
import React, { useMemo, useEffect,useState } from "react";
import PropTypes from 'prop-types';

//import components
import Breadcrumbs from '../../../components/Common/Breadcrumb';
import TableContainer from '../../../components/Common/TableContainer';
import DataTable from 'react-data-table-component';
import { RequestInstitutionApi } from "../../../apis/RequestInstitutionApi";
import { InstitutionApi } from "../../../apis/InstitutionApi";

import { useHistory } from "react-router-dom"
import * as Yup from "yup";
import { useFormik } from "formik";


import { Link } from "react-router-dom";
import {
    Card,
    Col,
    Container,
    Row,
    CardBody,
    CardTitle,
    Label,
    Button,
    Form,
    Input,
    InputGroup,
    TabContent,
  Table,
  TabPane,
  FormFeedback,

  } from "reactstrap";
import Swal from "sweetalert2";
import toastr from "toastr";
import "toastr/build/toastr.min.css";
import { withTranslation } from "react-i18next";

import i18n from "../../../i18n";
import { UserApi } from "apis/UserApi";



function ViewRequestDoctor(props) {

    const history = useHistory();
    const request_doctor_id = props.match.params.request_doctor_id;
    const [requestDoctorData, setRequestDoctorData] = useState({});
    const [specialties, setSpecialties] = useState()
    const [status, setStatus] = useState("");
    const [comment, setComment] = useState("");
    const [errorMsg, setErrorMsg] = useState("");

    useEffect(() => {
      getViewRequestDoctorById();
    }, []);

    const getViewRequestDoctorById = () => {
        UserApi.getViewRequestDoctorById(request_doctor_id)
          .then((res) => {
            setRequestDoctorData(res.data.data);
            setStatus(res.data.data?.status);
            setComment(res.data.data?.comment);
            setSpecialties(res.data.data?.RequestDoctorSpecialist?.Specialties?.name_en);
          })
          .catch((err) => {
            console.log(err);
          });
    };

    const formik = useFormik({
      // enableReinitialize : use this flag when initial values needs to be changed
      enableReinitialize: true,
  
      initialValues: {
        comment:  comment || '',
        status: status || ''
      },
      validationSchema: Yup.object({
        status: Yup.string().required(props.t("Select_Status")),
        comment: Yup.string().required(props.t("Required-Message")), 
      }),
      onSubmit: (values) => {
        //values.request_sub_institute_id =  request_institute_id;
        const payload = {
          request_doctor_id: request_doctor_id,
          status: status,
          comment: values.comment
         };
         UserApi.createRequestAllDoctor(payload)
          .then(res => {
              console.log(res.data)
            if (res.data.success) {
                      Swal.fire({
                        text: res.data.message,
                        icon: 'success',
                        imageAlt: 'success image',
                        // confirmButtonColor: '#00CA84'
                      }).then((result) => {
                        if (result.isConfirmed) {
                          history.push('/request-doctors');
                        }
                      });
              } else {
                  toastr.error(res.data.message, '');
              }
          }).catch(function (error) {
          toastr.error(error.response.data.message, '');
          });
      }
    });

    document.title = props.t("Request_Doctor_Details")+' | '+props.t("MedX")
    

    return (
        <div className="page-content">
            <div className="container-fluid">
            <Breadcrumbs title={props.t("Request_Doctor")} breadcrumbItem={props.t("Request_Doctor_Details")} />
            </div>
            <div className="container-fluid">

            <Row>
            <Col lg={6}>
              <Card>
                <CardBody>
                <div className="table-responsive">
                        <Table className="table mb-0 table-bordered">
                          <tbody>
                            
                                <tr>
                                  <th scope="row" className={"text-capitalize"}>{props.t("Name")}</th>
                                  <td>{requestDoctorData.first_name ? requestDoctorData.first_name +' '+requestDoctorData.last_name : "-"}</td>
                                </tr>

                                <tr>
                                  <th scope="row" className={"text-capitalize"}>{props.t("License_No")}</th>
                                  <td>{requestDoctorData.license_number ? requestDoctorData.license_number : "-"}</td>
                                </tr>

                                <tr>
                                  <th scope="row" className={"text-capitalize"}>{props.t("unique_id")}</th>
                                  <td>{requestDoctorData.unique_id ? requestDoctorData.unique_id : "-"}</td>
                                </tr>

                                <tr>
                                  <th scope="row" className={"text-capitalize"}>{props.t("specialist")}</th>
                                  <td>{specialties ? specialties : "-"}</td>
                                </tr>

                                
                          </tbody>
                        </Table>
                      </div>
                </CardBody>
              </Card>
            </Col>

            <Col lg={6}>
              <Card>
                <CardBody>
                   <div className="table-responsive">
                        <Table className="table mb-0 table-bordered">
                          <tbody>

                                <tr>
                                  <th scope="row" className={"text-capitalize"}>{props.t("Email")}</th>
                                  <td>{requestDoctorData.email ? requestDoctorData.email : "-"}</td>
                                </tr>


                                <tr>
                                  <th scope="row" className={"text-capitalize"}>{props.t("Contact_Number")}</th>
                                  <td>{requestDoctorData.mobile ? requestDoctorData.mobile : "-"}</td>
                                </tr>

                                <tr>
                                  <th scope="row" className={"text-capitalize"}>{props.t("Interests")}</th>
                                  <td>{requestDoctorData.interests ? requestDoctorData.interests : "-"}</td>
                                </tr>

                          </tbody>
                        </Table>
                      </div>
                </CardBody>
              </Card>
            </Col>
          </Row>

          <Row>
            <Col lg={12}>
              <Card>
                <CardBody>
                  <CardTitle className="mb-4">{props.t("Update_Req_Doctor_Status")}</CardTitle>

                  <form
                  className="form-horizontal"
                  onSubmit={e => {
                    e.preventDefault()
                    formik.handleSubmit()
                    return false
                  }}
                 >
                  {/* <h1>

                   {institutionData.RequestInstituteDocument.request_institute_id ? institutionData.RequestInstituteDocument.request_institute_id : 'null'}
                  </h1> */}
                  
                    <Row>
                      <Col md={6}>
                        <div className="mb-3">
                          <Label htmlFor="formrow-email-Input">{props.t("Status")}</Label>
                          <select 
                            onChange={(e) => {setStatus(e.target.value)}}
                            value={status || ""}
                            // onBlur={validation.handleBlur}
                            // defaultValue={institutionData.status}
                            className="form-select">
                            <option value="0">{props.t("Select_Status")}</option>
                            <option value="APPROVED">{props.t("APPROVED")}</option>
                            <option value="PENDING">{props.t("PENDING")}</option>
                            <option value="REJECTED">{props.t("REJECTED")}</option>
                        </select>
                        {errorMsg !== "" && 
                        <div style={{ color: '#f46a6a', fontSize: '11px', marginTop: '3px' }}>
                          {errorMsg}
                        </div>
                        }
                        </div>
                      </Col>
                    </Row>

                    <Row>
                      <Col md={6}>
                        <div className="mb-3">
                          <Label htmlFor="formrow-email-Input">{props.t("Comment")}</Label>
                          <Input
                          name="comment"
                          type="textarea"
                          className="form-control"
                          id="horizontal-firstname-Input"
                          placeholder={props.t("Comment")}
                          maxLength="225"
                          rows="3"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.comment}
                          // value={comment || ""}
                          invalid={
                            formik.touched.comment &&
                              formik.errors.comment
                              ? true
                              : false
                          }
                        
                         />

                        {formik.touched.comment &&
                        formik.errors.comment ? (
                        <div
                        style={{
                        color: "#f46a6a",
                        fontSize: "11px",
                        marginTop: "3px",
                        }}
                        >
                        {formik.errors.comment}
                        </div>
                        ) : null}
                        </div>
                      </Col>
                    </Row>

                    <div>
                    {/* onClick={onSubmitSubRequestInstituteHandler}  */}
                      <button type="submit" className="btn btn-primary w-md">
                        {props.t("Submit")}
                      </button>
                    </div>
                  </form>
                </CardBody>
              </Card>
            </Col>

            
          </Row>


                   
            </div>
        </div>
    );
}
ViewRequestDoctor.propTypes = {
  t: PropTypes.any,
  preGlobalFilteredRows: PropTypes.any,
};

export default withTranslation()(ViewRequestDoctor);