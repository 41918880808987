// LOGIN
export const POST_ADMIN_LOGIN = "/api/admin/login";

// PROFILE
export const POST_CREATE_ROLE = "/api/admin/create-role";
export const POST_UPDATE_ROLE = "/api/admin/update-role";
export const POST_FETCH_ROLE = "/api/admin/fetch-role";
export const POST_FETCH_ALL_ROLES = "/api/admin/fetch-roles";

// System Setting
export const POST_FETCH_SYSTEM_SETTINGS = "/api/admin/fetch-system-setting";
export const POST_UPDATE_SYSTEM_SETTINGS = "/api/admin/update-system-setting";
export const POST_FETCH_SYSTEM_SETTINGS_COMMON = "/api/admin/fetch-system-settings";

export const POST_FETCH_REQUEST_INSTITUTIONS_BY_NAME = "/api/admin/fetch-request-institutes-by-name";
export const POST_FETCH_REQUEST_INSTITUTIONS = "/api/admin/fetch-request-institutes";
export const POST_FETCH_REQUEST_SUB_INSTITUTIONS = "/api/admin/fetch-request-sub-institutes";
export const POST_FETCH_REQUEST_INSTITUTION_BY_ID = "/api/admin/fetch-request-institute-details";
export const POST_FETCH_SUB_REQUEST_INSTITUTION_BY_ID = "/api/admin/fetch-request-sub-institute-details";
export const POST_FETCH_REQUEST_SUB_INSTITUTION_BY_NAME = "/api/admin/fetch-request-sub-institutes-by-name";


export const GET_FETCH_REQUEST_DETAILS_VIEW = "/api/admin/fetch-institute-details";
export const GET_FETCH_REQUEST_SUB_INSTITUTE_DETAILS = "/api/admin/fetch-sub-institute-detail";

//profile
export const POST_UPDATE_USER_LANGUAGE = "/api/admin/update-user-language";
export const POST_UPDATE_USER_PROFILE = "/api/admin/update-profile";
export const POST_UPDATE_USER_PASSWORD = "/api/admin/change-password";
export const POST_GET_USER_PROFILE = "/api/admin/get-profile-detail";

//INSTITUTIONS
export const POST_CREATE_INSTITUTIONS = "/api/admin/create-institute";
export const POST_CREATE_SUB_INSTITUTIONS = "/api/admin/create-sub-institute";

//Role
export const POST_FETCH_MODULES = "/api/admin/fetch-all-modules";
export const POST_FETCH_MODULES_BY_ROLE = "/api/admin/fetchAllModulesByRole";

//Permission
export const SAVE_UPDATE_PERMISSION = "/api/admin/addUpdatePermissions";

//REGISTER
export const POST_FAKE_REGISTER = "/post-fake-register";

//LOGIN
export const POST_FAKE_LOGIN = "/post-fake-login";
export const POST_FAKE_JWT_LOGIN = "/post-jwt-login";
export const POST_FAKE_PASSWORD_FORGET = "/fake-forget-pwd";
export const POST_FAKE_JWT_PASSWORD_FORGET = "/jwt-forget-pwd";
export const SOCIAL_LOGIN = "/social-login";

//PROFILE
export const POST_EDIT_JWT_PROFILE = "/post-jwt-profile";
export const POST_EDIT_PROFILE = "/post-fake-profile";

//PRODUCTS
export const GET_PRODUCTS = "/products";
export const GET_PRODUCTS_DETAIL = "/product";

//Mails
export const GET_INBOX_MAILS = "/inboxmails";
export const ADD_NEW_INBOX_MAIL = "/add/inboxmail";
export const DELETE_INBOX_MAIL = "/delete/inboxmail";

//starred mail
export const GET_STARRED_MAILS = "/starredmails";

//important mails
export const GET_IMPORTANT_MAILS = "/importantmails";

//Draft mail
export const GET_DRAFT_MAILS = "/draftmails";

//Send mail
export const GET_SENT_MAILS = "/sentmails";

//Trash mail
export const GET_TRASH_MAILS = "/trashmails";

//CALENDER
export const GET_EVENTS = "/events";
export const ADD_NEW_EVENT = "/add/event";
export const UPDATE_EVENT = "/update/event";
export const DELETE_EVENT = "/delete/event";
export const GET_CATEGORIES = "/categories";

//CHATS
export const GET_CHATS = "/chats";
export const GET_GROUPS = "/groups";
export const GET_CONTACTS = "/contacts";
export const GET_MESSAGES = "/messages";
export const ADD_MESSAGE = "/add/messages";

//ORDERS
export const GET_ORDERS = "/orders";
export const ADD_NEW_ORDER = "/add/order";
export const UPDATE_ORDER = "/update/order";
export const DELETE_ORDER = "/delete/order";

//CART DATA
export const GET_CART_DATA = "/cart";

//CUSTOMERS
export const GET_CUSTOMERS = "/customers";
export const ADD_NEW_CUSTOMER = "/add/customer";
export const UPDATE_CUSTOMER = "/update/customer";
export const DELETE_CUSTOMER = "/delete/customer";

//SHOPS
export const GET_SHOPS = "/shops";

//CRYPTO
export const GET_WALLET = "/wallet";
export const GET_CRYPTO_ORDERS = "/crypto/orders";

//INVOICES
export const GET_INVOICES = "/invoices";
export const GET_INVOICE_DETAIL = "/invoice";

//PROJECTS
export const GET_PROJECTS = "/projects";
export const GET_PROJECT_DETAIL = "/project";
export const ADD_NEW_PROJECT = "/add/project";
export const UPDATE_PROJECT = "/update/project";
export const DELETE_PROJECT = "/delete/project";

//TASKS
export const GET_TASKS = "/tasks";

//CONTACTS
export const GET_USERS = "/users";
export const GET_USER_PROFILE = "/user";
export const ADD_NEW_USER = "/add/user";
export const UPDATE_USER = "/update/user";
export const DELETE_USER = "/delete/user";

//INTERESTS
export const POST_FETCH_ALL_INTERESTS = "/api/admin/fetch-interests";
export const POST_CREATE_INTEREST = "/api/admin/create-interest";
export const POST_FETCH_INTEREST = "/api/admin/fetch-interest";
export const POST_UPDATE_INTEREST = "/api/admin/update-interest";
export const POST_DELETE_INTEREST = "/api/admin/delete-interest";

//CMSS
export const POST_FETCH_ALL_CMSS = "/api/admin/fetch-cms-pages";
export const POST_CREATE_CMS = "/api/admin/create-cms-page";
export const POST_FETCH_CMS = "/api/admin/fetch-cms-page";
export const POST_UPDATE_CMS = "/api/admin/update-cms-page";
export const POST_DELETE_CMS = "/api/admin/delete-cms-page";

//ORGANIZATIONTYPES
export const POST_FETCH_ALL_ORGANIZATIONTYPES = "/api/admin/fetch-organization-types";
export const POST_CREATE_ORGANIZATIONTYPE = "/api/admin/create-organization-type";
export const POST_FETCH_ORGANIZATIONTYPE = "/api/admin/fetch-organization-type";
export const POST_UPDATE_ORGANIZATIONTYPE = "/api/admin/update-organization-type";
export const POST_DELETE_ORGANIZATIONTYPE = "/api/admin/delete-organization-type";

//SERVICETYPES
export const POST_FETCH_ALL_SERVICETYPES = "/api/admin/fetch-service-types";
export const POST_CREATE_SERVICETYPE = "/api/admin/create-service-type";
export const POST_FETCH_SERVICETYPE = "/api/admin/fetch-service-type";
export const POST_UPDATE_SERVICETYPE = "/api/admin/update-service-type";
export const POST_DELETE_SERVICETYPE = "/api/admin/delete-service-type";

// QUIZTYPES

export const POST_FETCH_ALL_QUIZTYPES = "/api/admin/fetch-quiz-types";
export const POST_CREATE_QUIZTYPES = "/api/admin/create-quiz-types";
export const POST_FETCH_QUIZTYPE = "/api/admin/fetch-quiz-type";
export const POST_UPDATE_QUIZTYPE = "/api/admin/update-quiz-type";
export const POST_DELETE_QUIZTYPE = "/api/admin/delete-quiz-type";

// SCORETYPES

export const POST_FETCH_ALL_SCORETYPES = "/api/admin/fetch-score-types";
export const POST_CREATE_SCORETYPES = "/api/admin/create-score-types";
export const POST_FETCH_SCORETYPE = "/api/admin/fetch-score-type";
export const POST_UPDATE_SCORETYPE = "/api/admin/update-score-type";
export const POST_DELETE_SCORETYPE = "/api/admin/delete-score-type";

// ACTIVITYDISPLAYTYPES

export const POST_FETCH_ALL_ACTIVITYDISPLAYTYPES = "/api/admin/fetch-activity-display-types";
export const POST_CREATE_ACTIVITYDISPLAYTYPES = "/api/admin/create-activity-display-types";
export const POST_FETCH_ACTIVITYDISPLAYTYPE = "/api/admin/fetch-activity-display-type";
export const POST_UPDATE_ACTIVITYDISPLAYTYPE = "/api/admin/update-activity-display-type";
export const POST_DELETE_ACTIVITYDISPLAYTYPE = "/api/admin/delete-activity-display-type";

// ACTIVITYCONTENTTYPES

export const POST_FETCH_ALL_ACTIVITYCONTENTTYPES = "/api/admin/fetch-activity-content-types";
export const POST_CREATE_ACTIVITYCONTENTTYPES = "/api/admin/create-activity-content-types";
export const POST_FETCH_ACTIVITYCONTENTTYPE = "/api/admin/fetch-activity-content-type";
export const POST_UPDATE_ACTIVITYCONTENTTYPE = "/api/admin/update-activity-content-type";
export const POST_DELETE_ACTIVITYCONTENTTYPE = "/api/admin/delete-activity-content-type";

// TASKTYPES

export const POST_FETCH_ALL_TASK_TYPES = "/api/admin/fetch-task-types";
export const POST_CREATE_TASK_TYPES = "/api/admin/create-task-types";
export const POST_FETCH_TASK_TYPE = "/api/admin/fetch-task-type";
export const POST_UPDATE_TASK_TYPE = "/api/admin/update-task-type";
export const POST_DELETE_TASK_TYPE = "/api/admin/delete-task-type";

// ATTACHMENTTYPES

export const POST_FETCH_ALL_ATTACHMENT_TYPES = "/api/admin/fetch-attachment-types";
export const POST_CREATE_ATTACHMENT_TYPES = "/api/admin/create-attachment-types";
export const POST_FETCH_ATTACHMENT_TYPE = "/api/admin/fetch-attachment-type";
export const POST_UPDATE_ATTACHMENT_TYPE = "/api/admin/update-attachment-type";
export const POST_DELETE_ATTACHMENT_TYPE = "/api/admin/delete-attachment-type";

// INSTITUTEINDEXES

export const POST_FETCH_ALL_INSTITUTES_INDEXES = "/api/admin/fetch-institute-indexes";
export const POST_CREATE_INSTITUTES_INDEXES = "/api/admin/create-institute-indexes";
export const POST_FETCH_INSTITUTES_INDEX = "/api/admin/fetch-institute-index";
export const POST_UPDATE_INSTITUTES_INDEX = "/api/admin/update-institute-index";
export const POST_DELETE_INSTITUTES_INDEX = "/api/admin/delete-institute-index";

// NOTIFICATIONTYPES

export const POST_FETCH_ALL_NOTIFICATION_TYPES = "/api/admin/fetch-notification-types";
export const POST_CREATE_NOTIFICATION_TYPES = "/api/admin/create-notification-types";
export const POST_FETCH_NOTIFICATION_TYPE = "/api/admin/fetch-notification-type";
export const POST_UPDATE_NOTIFICATION_TYPE = "/api/admin/update-notification-type";
export const POST_DELETE_NOTIFICATION_TYPE = "/api/admin/delete-notification-type";

// CONTENTLENGTHSCORES

export const POST_FETCH_ALL_CONTENT_LENGTH_SCORES = "/api/admin/fetch-content-length-scores";
export const POST_CREATE_CONTENT_LENGTH_SCORES = "/api/admin/create-content-length-scores";
export const POST_FETCH_CONTENT_LENGTH_SCORE = "/api/admin/fetch-content-length-score";
export const POST_UPDATE_CONTENT_LENGTH_SCORE = "/api/admin/update-content-length-score";
export const POST_DELETE_CONTENT_LENGTH_SCORE = "/api/admin/delete-content-length-score";

//PROVINCES
export const POST_FETCH_ALL_PROVINCES = "/api/admin/fetch-provinces";
export const POST_CREATE_PROVINCE = "/api/admin/create-province";
export const POST_FETCH_PROVINCE = "/api/admin/fetch-province";
export const POST_UPDATE_PROVINCE = "/api/admin/update-province";
export const POST_DELETE_PROVINCE = "/api/admin/delete-province";

//DISTRICTS
export const POST_FETCH_ALL_DISTRICTS = "/api/admin/fetch-districts";
export const POST_CREATE_DISTRICT = "/api/admin/create-district";
export const POST_FETCH_DISTRICT = "/api/admin/fetch-district";
export const POST_UPDATE_DISTRICT = "/api/admin/update-district";
export const POST_DELETE_DISTRICT = "/api/admin/delete-district";
export const POST_FETCH_ALL_COMMON_PROVINCES = "/api/common/fetch-province"; // COMMON PROVINCES

//SUBDISTRICTS
export const POST_FETCH_ALL_SUBDISTRICTS = "/api/admin/fetch-sub-districts";
export const POST_CREATE_SUBDISTRICT = "/api/admin/create-sub-district";
export const POST_FETCH_SUBDISTRICT = "/api/admin/fetch-sub-district";
export const POST_UPDATE_SUBDISTRICT = "/api/admin/update-sub-district";
export const POST_DELETE_SUBDISTRICT = "/api/admin/delete-sub-district";
export const POST_FETCH_ALL_COMMON_DISTRICTS = "/api/common/fetch-district"; // COMMON DISTRICTS

//CATEGORIES
export const POST_FETCH_ALL_CATEGORIES = "/api/admin/fetch-categories";
export const POST_CREATE_CATEGORY = "/api/admin/create-category";
export const POST_FETCH_CATEGORY = "/api/admin/fetch-category";
export const POST_UPDATE_CATEGORY = "/api/admin/update-category";
export const POST_DELETE_CATEGORY = "/api/admin/delete-category";

//BANNERS
export const POST_FETCH_ALL_BANNERS = "/api/admin/fetch-banners";
export const POST_CREATE_BANNER = "/api/admin/create-banner";
export const POST_FETCH_BANNER = "/api/admin/fetch-banner";
export const POST_UPDATE_BANNER = "/api/admin/update-banner";
export const POST_DELETE_BANNER = "/api/admin/delete-banner";

//OFFERTYPES
export const POST_FETCH_ALL_OFFERTYPES = "/api/admin/fetch-offer-types";
export const POST_CREATE_OFFERTYPE = "/api/admin/create-offer-type";
export const POST_FETCH_OFFERTYPE = "/api/admin/fetch-offer-type";
export const POST_UPDATE_OFFERTYPE = "/api/admin/update-offer-type";
export const POST_DELETE_OFFERTYPE = "/api/admin/delete-offer-type";

//OFFERS
export const POST_FETCH_ALL_OFFERS = "/api/admin/fetch-offers";
export const POST_CREATE_OFFER = "/api/admin/create-offer";
export const POST_FETCH_OFFER = "/api/admin/fetch-offer";
export const POST_UPDATE_OFFER = "/api/admin/update-offer";
export const POST_DELETE_OFFER = "/api/admin/delete-offer";

//PRODUCTS
export const POST_FETCH_ALL_PRODUCTS = "/api/admin/fetch-products";
export const POST_CREATE_PRODUCT = "/api/admin/create-product";
export const POST_FETCH_PRODUCT = "/api/admin/fetch-product";
export const POST_UPDATE_PRODUCT = "/api/admin/update-product";
export const POST_DELETE_PRODUCT = "/api/admin/delete-product";
export const POST_REMOVE_PRODUCT_IMAGE = "/api/admin/delete-product-image";

//COMMUNITY
export const POST_FETCH_ALL_COMMUNITY = "/api/admin/fetch-community-posts";
export const POST_FETCH_COMMUNITY_BY_NAME = "/api/admin/fetch-community-post-by-name";
export const POST_FETCH_COMMUNITY_NEWS_BY_NAME = "/api/admin/fetch-community-post-news-by-name";

export const POST_CREATE_COMMUNITY = "/api/admin/create-community-post";
export const POST_FETCH_COMMUNITY = "/api/admin/fetch-community-post-details";
export const POST_UPDATE_COMMUNITY = "/api/admin/update-community-post";
export const POST_DELETE_COMMUNITY = "/api/admin/delete-community-post";
export const POST_IMAGE_DELETE_COMMUNITY = "/api/admin/remove-community-post-file";
export const POST_FETCH_ALL_NEWS = "/api/admin/fetch-community-news";


//COMPANIES
export const POST_FETCH_ALL_COMPANIES = "/api/admin/fetch-companies";
export const POST_CREATE_COMPANY = "/api/admin/create-company";
export const POST_DELETE_COMPANY = "/api/admin/delete-company";
export const POST_UPDATE_COMPANY = "/api/admin/update-company";
export const POST_FETCH_COMPANY = "/api/admin/fetch-company";
export const POST_SEND_EMAIL = "/api/admin/send-email";

export const POST_GET_ALL_COMPANIES = "/api/common/fetch-all-companies";


//SPEAKERS
export const POST_FETCH_ALL_SPEAKER = "/api/admin/fetch-speakers";
export const POST_CREATE_SPEAKER = "/api/admin/create-speaker";
export const POST_DELETE_SPEAKER = "/api/admin/delete-speaker";
export const POST_UPDATE_SPEAKER = "/api/admin/update-speaker";
export const POST_FETCH_SPEAKER = "/api/admin/fetch-speaker";

//ACTIVITIES
export const POST_FETCH_ALL_ACTIVITIE_BY_NAME_KEYWORD = "/api/admin/fetch-activitie-by-name-keyword";
export const POST_FETCH_ALL_ACTIVITIES = "/api/admin/fetch-activities";
export const POST_CREATE_ACTIVITY = "/api/admin/create-activity";
export const POST_FETCH_ACTIVITY = "/api/admin/fetch-activity";
export const POST_UPDATE_ACTIVITY = "/api/admin/update-activity";
export const POST_FETCH_ALL_INSTITUTIONS = "/api/admin/fetch-institutes";
export const POST_FETCH_ALL_INSTITUTION_NAME = "/api/admin/fetch-institute-name-by-keyword";
export const POST_FETCH_ALL_INSTITUTION_ALL = "/api/admin/fetch-institute-all";
export const POST_FETCH_ACTIVITY_REPORTS = "/api/admin/fetch-activity-reports";

//Activity Requests
export const POST_FETCH_ALL_ACTIVITyREQUESTS = "/api/admin/fetch-activity-requests";
export const POST_GET_ACTIVITY_REQUEST_COUNT = "/api/admin/get-activity-request-count";

export const POST_FETCH_ALL_SUB_INSTITUTIONS = "/api/admin/fetch-sub-institutes";
export const POST_FETCH_ALL_SUB_INSTITUTION_NAME_BY_KEYWORD = "/api/admin/fetch-sub-institute-name-by-keyword";
export const POST_FETCH_ALL_SPEAKERUSER = "/api/common/fetch-speaker-user";

// export const POST_FETCH_ALL_ACTIVITYTYPE = "/api/common/fetch-activity-types";
export const POST_FETCH_ALL_ACTIVITYTYPE = "/api/common/fetch-activity-types";
// export const POST_FETCH_ALL_SPEAKERUSER = "/api/admin/speaker-user";
export const POST_FETCH_ALL_COMMON_ACTIVITYTYPE = "/api/common/fetch-activity-types";
export const POST_FETCH_ALL_ACTIVITYSUBTYPE = "/api/common/fetch-activity-sub-types";
export const POST_FETCH_ALL_COMMON_INTEREST = "/api/common/fetch-interests"
export const POST_REMOVE_ACTIVITY_MEDIA = "/api/admin/delete-activity-media";
export const POST_DELETE_ACTIVITY = "/api/admin/delete-activity";
export const POST_VIEW_CUSTOM_ACTIVITY = "/api/admin/view-custom-activity";
export const POST_CREATECUSTOMEACTIVITY = "/api/admin/creatCustomActivity";
export const POST_EDITCUSTOMEACTIVITY = "/api/admin/editCustomActivity";

//ACTIVITY Type start
export const POST_FETCH_ALL_ADMIN_ACTIVITYTYPE = "/api/admin/fetch-activity-types";
export const POST_CREATE_ACTIVITYTYPE = "/api/admin/create-activity-type";
export const POST_FETCH_ACTIVITYTYPE = "/api/admin/fetch-activity-type";
export const POST_FETCH_ACTIVITYTYPE_BY_ID = "/api/admin/fetch-activity-types-by-id";
export const POST_UPDATE_ACTIVITYTYPE = "/api/admin/update-activity-type";
export const POST_DELETE_ACTIVITYTYPE = "/api/admin/delete-activity-type";
//ACTIVITY Type end

//ACTIVITY Type start
export const POST_FETCH_ALL_ADMIN_CUSTOMACTIVITYTYPE = "/api/admin/fetch-custom-activity-types";
export const POST_CREATE_CUSTOMACTIVITYTYPE = "/api/admin/create-custom-activity-type";
// export const POST_FETCH_ACTIVITYTYPE = "/api/admin/fetch-activity-type";
export const POST_FETCH_CUSTOMACTIVITYTYPE_BY_ID = "/api/admin/fetch-custom-activity-types-by-id";
export const POST_UPDATE_CUSTOMACTIVITYTYPE = "/api/admin/update-custom-activity-type";
export const POST_DELETE_CUSTOMACTIVITYTYPE = "/api/admin/delete-activity-type";
//ACTIVITY Type end

//dashboard charts data
export const GET_WEEKLY_DATA = "/weekly-data";
export const GET_YEARLY_DATA = "/yearly-data";
export const GET_MONTHLY_DATA = "/monthly-data";

export const TOP_SELLING_DATA = "/top-selling-data";
export const GET_EARNING_DATA = "/earning-charts-data";
export const GET_PRODUCT_COMMENTS = "/comments-product";
export const ON_LIKNE_COMMENT = "/comments-product-action";
export const ON_ADD_REPLY = "/comments-product-add-reply";
export const ON_ADD_COMMENT = "/comments-product-add-comment";


//SPECIALISTS
export const POST_FETCH_ALL_SPECIALISTS = "/api/admin/fetch-specialists";
export const POST_CREATE_SPECIALIST = "/api/admin/create-specialist";
export const POST_FETCH_SPECIALIST = "/api/admin/fetch-specialist";
export const POST_UPDATE_SPECIALIST = "/api/admin/update-specialist";
export const POST_DELETE_SPECIALIST = "/api/admin/delete-specialist";

//Score Type
export const POST_FETCH_ALL_SCORETYPE = "/api/admin/fetch-score-types";

//Task Type
export const POST_FETCH_ALL_TASKTYPE = "/api/admin/fetch-task-types";

//Evalution Form
export const POST_FETCH_ALL_EVALUTIONFORMS = "/api/admin/fetch-evalution-forms";
export const POST_CREATE_EVALUTIONFORM = "/api/admin/create-evalution-form";
export const POST_FETCH_EVALUTIONFORM = "/api/admin/fetch-evalution-form";
export const POST_UPDATE_EVALUTIONFORM = "/api/admin/update-evalution-form";
export const POST_DELETE_EVALUTIONFORM = "/api/admin/delete-evalution-form";

//Evalution Form Details
export const POST_FETCH_ALL_EVALUTIONFORM_DETAILS = "/api/admin/fetch-evalution-form-details";
export const POST_CREATE_EVALUTIONFORM_DETAIL = "/api/admin/create-evalution-form-detail";
export const POST_FETCH_EVALUTIONFORMFIELDS = "/api/admin/fetch-evalution-form-fields";
export const POST_UPDATE_EVALUTIONFORM_DETAIL = "/api/admin/update-evalution-form-detail";
export const POST_DELETE_EVALUTIONFORM_DETAIL = "/api/admin/delete-evalution-form-detail";
export const POST_FETCH_INSTITUTEEVALUATIONS = "/api/common/fetch-evaluation-forms";

// Evalution Form Field Options

export const POST_FETCH_ALL_EVALUTIONFORMFIELD_OPTIONS = "/api/admin/fetch-evalution-form-field-options";
export const POST_CREATE_EVALUTIONFORMFIELD_OPTION = "/api/admin/create-evalution-form-field-option";
export const POST_FETCH_EVALUTIONFORMFIELD_OPTION = "/api/admin/fetch-evalution-form-field-option";
export const POST_UPDATE_EVALUTIONFORMFIELD_OPTION = "/api/admin/update-evalution-form-field-option";
export const POST_DELETE_EVALUTIONFORMFIELD_OPTION = "/api/admin/delete-evalution-form-field-option";


//Doctor
export const POST_CREATE_DOCTOR = "/api/admin/create-doctor";
export const POST_FETCH_DOCTOR_BY_ID = "/api/admin/fetch-doctor-by-id";
export const POST_UPDATE_DOCTOR = "/api/admin/update-doctor";
export const POST_FETCH_ALL_DOCTOR = "/api/admin/fetch-all-doctors";
export const POST_DELETE_DOCTOR = "/api/admin/delete-doctor";
export const POST_FETCH_ALL_DOCTOR_NAME_BY_LICENSE_NO = "/api/admin/fetch-doctor-user-by-license-no";

//End Doctor


// Request Doctor
export const POST_REQUEST_ALL_DOCTOR = "/api/admin/fetch-all-request-doctors";
export const POST_FETCH_REQUST_DOCTOR_BY_ID = "/api/admin/fetch-request-doctor-by-id";
export const POST_CREATE_REQUEST_DOCTOR = "/api/admin/create-request-doctor";
export const POST_DELETE_REQUEST_DOCTOR = "/api/admin/delete-request-doctor";


// Report
export const LOGIN_HOSTORY = "/api/admin/fetch-login-histories";
export const REGISTERED_INSTITUTES = "/api/admin/fetch-registered-institutes";
export const REGISTERED_SUB_INSTITUTES = "/api/admin/fetch-registered-subinstitutes";
export const ACTIVITIES_REPORTS = "/api/admin/fetch-activitites-reports";
export const APPROVED_DOCTORS = "/api/admin/fetch-approved-doctors";

//main task type Form
export const POST_FETCH_ALL_MAINTASKTYPES = "/api/admin/fetch-main-task-types";
export const POST_CREATE_MAINTASKTYPE = "/api/admin/create-main-task-type";
export const POST_FETCH_MAINTASKTYPE = "/api/admin/fetch-main-task-type";
export const POST_UPDATE_MAINTASKTYPE = "/api/admin/update-main-task-type";
export const POST_DELETE_MAINTASKTYPE = "/api/admin/delete-main-task-type";
export const POST_FETCH_MAINTASKTYPES_BY_INSTITUTE = "/api/common/fetch-internship-main-task";

//sessions
export const POST_FETCH_ALL_INTERNSHIPSESSION = "/api/admin/fetch-sesions";
export const POST_CREATE_SESSION = "/api/admin/create-session";
export const GET_FETCH_SESSIONDETAIL = "/api/admin/fetch-session";
export const POST_UPDATE_SESSION = "/api/admin/update-session";
export const POST_CREATE_INT_ADDSESSIONTASKS = "/api/admin/addSessionTaskAndEvalauators";
export const POST_CREATE_INT_GETSESSIONTASKS = "/api/admin/getSessionTaskAndEvalauators";

//manage sessions
export const POST_FETCH_ALL_INTERNSHIPUSEREVALAUTION = "/api/admin/fetch-user-evaluations";
export const POST_CREATE_INT_USEREVALUATION = "/api/admin/create-user-evaluation";
export const GET_FETCH_INT_USEREVALUATIONETAIL = "/api/admin/fetch-user-evaluation";
export const POST_UPDATE_USEREVALUATION = "/api/admin/update-user-evaluation";
export const POST_DELETE_USEREVALUATION = "/api/admin/delete-user-evaluation";

//evaluation list
export const POST_FETCH_ALL_INTERNSHIPEVALUATIONLIST = "/api/admin/fetch-evaluation-list";
export const POST_CREATE_INT_ADDEVALUATORS = "/api/admin/add-evaluators";
export const LOGIN_HISTORY_WITHOUT_LIMIT = "/api/admin/fetch-login-histories-without-limit";

//Leave
export const POST_FETCH_ALL_LEAVES = "/api/admin/leaves-list";
export const POST_CREATE_LEAVE = "/api/admin/add-leave";
export const POST_FETCH_LEAVE_BY_ID = "/api/admin/get-leave-by-id";
export const POST_UPDATE_LEAVE = "/api/admin/update-leave";
export const POST_DELETE_LEAVE = "/api/admin/delete-leave";

//Leave Reason
export const POST_FETCH_ALL_LEAVE_REASON = "/api/admin/leave-reason-list";
export const POST_CREATE_LEAVE_REASON = "/api/admin/add-leave-reason";
export const POST_FETCH_LEAVEREASON_BY_ID = "/api/admin/get-leave-reason-by-id";
export const POST_UPDATE_LEAVE_REASON = "/api/admin/update-leave-reason";
export const POST_DELETE_LEAVE_REASON = "/api/admin/delete-leave-reason";

export const GET_CMSPAGE = "/api/common/cmspage";
export const ACCEPT_TERMS = "/api/admin/accept_termsandcondition";