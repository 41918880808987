import React, { useEffect, useState } from "react";
import {
    Row,
    Col,
    Card,
    CardBody,
    FormGroup,
    Button,
    CardTitle,
    CardSubtitle,
    Label,
    Input,
    Container,
    FormFeedback,
    Form,
} from "reactstrap";
// Formik validation
import * as Yup from "yup";
import { useFormik } from "formik";
import toastr from "toastr";
import "toastr/build/toastr.min.css";
import PropTypes from 'prop-types';
// import { RoleApi } from "../../apis/RoleApi";
// import { RoleModuleApi } from "../../apis/RoleModuleApi";
import { useHistory } from "react-router-dom"
import Swal from "sweetalert2";
import { withTranslation } from "react-i18next";
import * as url from "../../../helpers/common_helper"
import moment from "moment";
// import i18n from "../../i18n";
import config from "config/config";
import { CompaniesApi } from "apis/CompaniesApi";
import Breadcrumb from "components/Common/Breadcrumb";
import constants from "../../../config/constants";
import { CategoryApi } from "apis/CategoryApi"
import { LeaveApi } from "apis/LeaveApi";
import Select from "react-select"

const EditLeave = props => {
    const leave_id = props.match.params.leave_id;
    const [leave, setLeave] = useState('');
    const [defaultStatus, setDefaultStatus] = useState();
    const [username, setUserName] = useState('');
    const history = useHistory();
    const [loading, setLoading] = useState(false);

    const [activeData, setActiveData] = useState({
        page: 1,
        totalPage: 1,
        search: "",
        limit: config.LIMIT
    });

    const getLeavesById = () => {
        LeaveApi.getLeaveById({ leave_id })
        .then(res => {
            setLeave(res.data.data);
            setUserName(res.data.data.UserInformation.username)
        }).catch(err => {
            console.log(err)
        })
    }

    useEffect(() => {
        getLeavesById();
    }, []);

    // Form validation 
    const validationType = useFormik({
        // enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: true,

        initialValues: {
            leave_id: leave.id ?? '',
            user_name: username ?? '',
            reason_type: leave.subject ?? '',
            type: leave.type ?? '',
            from_date: leave.from_date ?? '',
            to_date: leave.to_date ?? '',
            status: "",
            description: leave.description ?? '',
            comment: leave.comment ?? '',
        },
        validationSchema: Yup.object().shape({
            status: Yup.string().required(
                props.t("Required-Message")
            ),
            // comment: Yup.string().required(
            //     props.t("Required-Message")
            // ),
        }),
        onSubmit: (values) => {
            const data = new FormData();
            data.append('leave_id', values.leave_id);
            data.append('comment', values.comment);
            data.append('status', values.status);
            LeaveApi.updateLeave(data)
                .then(res => {
                    if (res.data.success) {
                        Swal.fire({
                            text: res.data.message,
                            icon: 'success',
                            imageAlt: 'success image',
                        }).then((result) => {
                            if (result?.isConfirmed) {
                                history.push('/leaves');
                                formClear()
                                setFiles1()
                            }
                        });
                    } else {
                        toastr.error(res?.data?.message, '');
                    }
                }).catch(function (error) {

                    if (error?.response?.data?.message) {
                        toastr.error(error?.response?.data?.message, '');
                    }
                    else {
                        toastr.error(error?.response?.data?.error?.errors?.map((item, v) => `${item.msg}<br>`), '');
                    }
                });
        }
    });

    const formClear = () => {
        validationType.resetForm();
        setFiles1([])
    };

    document.title = props.t("Leave") + ' | ' + props.t("MedX");
    return (
        <React.Fragment>
            <div className="page-content">
                <Breadcrumb title={props.t("Leave")} breadcrumbItem={props.t("Edit") + " " + props.t("Master")} />
                <Container fluid={true}>
                    <Row>

                        <Col lg={12}>
                            <Card>
                                <CardBody >
                                    <Col sm={12}>
                                        <Form
                                            onSubmit={(e) => {
                                                e.preventDefault();
                                                validationType.handleSubmit();
                                                return false;
                                            }}>
                                            <Col sm={12}>
                                                <div className="mb-3">
                                                    <Label className="form-label">{props.t("User")}
                                                    </Label>
                                                    <Input
                                                        name="user_name"
                                                        placeholder={props.t("User")}
                                                        type="text"
                                                        onChange={validationType.handleChange}
                                                        onBlur={validationType.handleBlur}
                                                        value={validationType.values.user_name || ""}
                                                        disabled={true}

                                                    />

                                                </div>
                                            </Col>
                                            <Col sm={12}>
                                                <div className="mb-3">
                                                    <Label className="form-label">{props.t("Reason")}

                                                    </Label>
                                                    <Input
                                                        name="reason_type"
                                                        placeholder={props.t("Reason")}
                                                        type="text"
                                                        onChange={validationType.handleChange}
                                                        onBlur={validationType.handleBlur}
                                                        value={validationType.values.reason_type || ""}
                                                        disabled={true}

                                                    />

                                                </div>
                                            </Col>
                                            <Col sm={12}>
                                                <div className="mb-3">
                                                    <Label className="form-label">{props.t("From_Date")}

                                                    </Label>
                                                    <Input
                                                        name="from_date"
                                                        placeholder={props.t("From_Date")}
                                                        type="text"
                                                        onChange={validationType.handleChange}
                                                        onBlur={validationType.handleBlur}
                                                        value={url.dateFormat(validationType.values.from_date) || ""}
                                                        disabled={true}

                                                    />

                                                </div>
                                            </Col>
                                            <Col sm={12}>
                                                <div className="mb-3">
                                                    <Label className="form-label">{props.t("To_Date")}

                                                    </Label>
                                                    <Input
                                                        name="to_date"
                                                        placeholder={props.t("To_Date")}
                                                        type="text"
                                                        onChange={validationType.handleChange}
                                                        onBlur={validationType.handleBlur}
                                                        value={url.dateFormat(validationType.values.to_date) || ""}
                                                        disabled={true}

                                                    />

                                                </div>
                                            </Col>
                                            <Col sm={12}>
                                                <div className="mb-3">
                                                    <Label className="form-label">{props.t("Description")}

                                                    </Label>
                                                    <Input
                                                        name="description"
                                                        placeholder={props.t("Description")}
                                                        type="textarea"
                                                        onChange={validationType.handleChange}
                                                        onBlur={validationType.handleBlur}
                                                        value={validationType.values.description || ""}
                                                        disabled={true}

                                                    />

                                                </div>
                                            </Col>

                                            <Col sm={12}>
                                                <div className="mb-3">
                                                    <Label className="form-label">{props.t("Status")}
                                                        {" "} <span className="text-danger">*</span></Label>
                                                    <Select
                                                        className={
                                                            validationType.touched.type &&
                                                            validationType.errors.type
                                                                ? "bor "
                                                                : "text-dark"
                                                        }
                                                        styles={{
                                                            control: styles => ({
                                                                ...styles,
                                                            }),
                                                        }}
                                                        onChange={e => {
                                                            validationType.setFieldValue("status", e.value)
                                                            setDefaultStatus(e)
                                                        }}
                                                        value={defaultStatus || ""}
                                                        onBlur={validationType.handleBlur}
                                                        name="status"
                                                        options={Object.keys(constants.LEAVE_STATUS).map(
                                                            value => ({
                                                                value: `${value}`,
                                                                label: `${value}`,
                                                            })
                                                        )}
                                                        placeholder={
                                                            <div>{props.t("Select_Status")}</div>
                                                        }
                                                    />
                                                    {validationType.touched.status && validationType.errors.status ? (
                                                        <div style={{ color: '#f46a6a', fontSize: '11px', marginTop: '3px' }}>
                                                            {validationType.errors.status}
                                                        </div>
                                                    ) : null}
                                                </div>
                                            </Col>

                                            <Col sm={12}>
                                                <div className="mb-3">
                                                    <Label className="form-label">{props.t("Comment")}
                                                        {/* {" "} <span className="text-danger">*</span> */}
                                                    </Label>
                                                    <Input
                                                        name="comment"
                                                        placeholder={props.t("Comment")}
                                                        type="textarea"
                                                        onChange={validationType.handleChange}
                                                        onBlur={validationType.handleBlur}
                                                        value={validationType.values.comment || ""}
                                                    />

                                                    {validationType.touched.comment && validationType.errors.comment ? (
                                                        <FormFeedback type="invalid">{validationType.errors.comment}</FormFeedback>
                                                    ) : null}

                                                </div>
                                            </Col>

                                            <div className="d-flex flex-wrap gap-2">
                                                <Button type="submit" color="primary" >
                                                    {props.t("Submit")}
                                                </Button>{" "}
                                                <Button color="secondary" onClick={e => formClear()}>
                                                    {props.t("Clear")}
                                                </Button>
                                            </div>
                                        </Form>
                                    </Col>

                                </CardBody>
                            </Card>
                        </Col>

                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
};


EditLeave.propTypes = {
    t: PropTypes.any,
}; 

export default withTranslation()(EditLeave);