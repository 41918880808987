import React, { useEffect, useRef, useState } from "react"
import {
  Button,
  Card,
  CardBody,
  CardImg,
  CardText,
  CardTitle,
  Col,
  Container,
  Form,
  FormFeedback,
  Input,
  Label,
  Modal,
  ModalBody,
  Row,
} from "reactstrap"
// Formik validation
import { useFormik } from "formik"
import * as Yup from "yup"

import PropTypes from "prop-types"
import toastr from "toastr"
import "toastr/build/toastr.min.css"

import { useHistory } from "react-router-dom"
//Import Breadcrumb
import config from "config/config"
import { withTranslation } from "react-i18next"
import Swal from "sweetalert2"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import constants from "../../../config/constants"

import { ActivityApi } from "apis/ActivityApi"
import { ActivityTypeApi } from "apis/ActivityTypeApi"
import { InstitutionApi } from "apis/InstitutionApi"
import { InterestApi } from "apis/InterestApi"
import { ProvinceApi } from "apis/ProvinceApi"
import { UserApi } from "apis/UserApi"
import { useLocation } from "react-router-dom"

// Form Editor
import { ContentState, EditorState, convertFromHTML } from "draft-js"
import { Editor } from "react-draft-wysiwyg"
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css"

import "flatpickr/dist/themes/material_blue.css"

import Select from "react-select"

import { debounce } from "lodash"
import moment from "moment"

import { getRoleID } from "Token"
import DataTable from "react-data-table-component"
import * as url from "../../../helpers/common_helper"

import {
  GoogleMap,
  LoadScript,
  MarkerF,
  StandaloneSearchBox
} from "@react-google-maps/api"

const AddOffer = props => {
  const [files1, setFiles1] = useState([])
  const [files2, setFiles2] = useState([])
  const [videoFile, setVideoFile] = useState([])
  const [videoThumbnailFile, setVideoThumbnailFile] = useState()
  const history = useHistory()
  const [institutionList, setInstitutionList] = useState([])
  const [activitySubTypeList, setActivitySubTypeList] = useState([])
  const [proviceList, setProviceList] = useState([])
  const [interestList, setInterestList] = useState([])
  const [userList, setUserList] = useState([])
  const [loading, setLoading] = useState(false)
  const [contentEn, setContentEn] = useState(() => EditorState.createEmpty())
  const [contentTh, setContentTh] = useState(() => EditorState.createEmpty())
  const [convertedContentEn, setConvertedContentEn] = useState(null)
  const [convertedContentTh, setConvertedContentTh] = useState(null)
  const [isSubmit, setIsSubmit] = useState(false)
  const activity_id = props.match.params.activity_id
  const search = useLocation().search
  const formStatus = new URLSearchParams(search).get("formStatus")
  const [activity, setActivity] = useState([])
  const [activityMediaImage, setActivityMediaImage] = useState([])
  const [activityMediaFile, setActivityMediaFile] = useState([])
  const [selectedOption, setSelectedOption] = useState([])
  const [speakerNewType, setSpeakerType] = useState("")
  const [doctorsData, setDoctorsData] = useState([])
  const [doctorPaginateData, setDoctorPaginateData] = useState({
    page: 1,
    limit: 20,
    totalRecords: 1000,
    search: "",
  })
  const [isMoreDoctors, setIsMoreDoctors] = useState(true)
  const [isDoctorLoading, setIsDoctorLoading] = useState(true)

  const [institutesData, setinstitutesData] = useState([])
  const [institutesPaginateData, setinstitutesPaginateData] = React.useState({
    page: 1,
    limit: 20,
    totalRecords: 1000,
    search: "",
  })
  const [isMoreInstitutes, setIsMoreInstitutes] = useState(true)
  const [isInstituteLoading, setIsInstituteLoading] = useState(true)

  const [activitySelectedInterest, setActivitySelectedInterest] = useState([])
  const [selectedInstitute, setSelectedInstitute] = useState()
  const [activeData, setActiveData] = useState({
    activity_id: activity_id,
    page: 1,
    totalPage: 1,
    search: "",
    limit: config.LIMIT,
  })

  const [selectedOptionSpeaker, setSelectedOptionSpeaker] = useState([])
  const [customValidation, setCustomValidation] = useState()
  const [isFormSubmited, setIsFormSubmited] = useState(false)
  const [activityReports, setActivityReports] = useState([])
  const [showModal, setShowModal] = useState(false)

  const columns = [
    {
      name: props.t("Sr_No"),
      selector: row => row.sr_no,
      sort: "asc",
      width: 150,
      sortable: true,
      defaultSortField: true,
      defaultSortAsc: false,
    },
    {
      name: props.t("License_No"),
      selector: row => row.licenseId,
      sort: "asc",
      width: 270,
      sortable: true,
      defaultSortField: true,
      defaultSortAsc: false,
    },
    {
      name: props.t("Name"),
      selector: row => row.userName,
      sort: "asc",
      width: 270,
      sortable: true,
      defaultSortField: true,
      defaultSortAsc: false,
    },
    {
      name: props.t("joinActviityTimestamp"),
      selector: row => row.joiningDate,
      sort: "asc",
      width: 270,
      sortable: true,
      defaultSortField: true,
      defaultSortAsc: false,
    },
    {
      name: props.t("checkInTimestamp"),
      selector: row => row.checkInDate,
      sort: "asc",
      width: 270,
      sortable: true,
      defaultSortField: true,
      defaultSortAsc: false,
    },
  ]

  useEffect(() => {
    getActivityDetails()
    getActivityReports(activeData)
  }, [])

  const getActivityReports = data => {
    setLoading(true)
    ActivityApi.getActivityReports(data)
      .then(res => {
        setActiveData({
          page: activeData.page,
          totalPage: res?.data?.data?.count,
          search: activeData.search,
          limit: activeData.limit,
        })
        let data = []
        let sr_no = res.data.sr_no_start
        res?.data?.data?.rows?.forEach((v, i) => {
          sr_no = sr_no + 1
          data[i] = {
            sr_no: sr_no,
            joiningDate:
              v.type === "Online"
                ? `${v.joining_date} (${v.check_in_time})`
                : "-",
            checkInDate:
              v.type === "Onsite"
                ? `${v.joining_date} (${v.check_in_time})`
                : "-",
            ...v,
          }
        })
        setActivityReports(data)
        setLoading(false)
      })
      .catch(err => {
        console.log(err)
      })
  }

  const getAllInstitutesPaginate = (searchKeyword = "") => {
    if (searchKeyword !== "") {
      institutesPaginateData.search = searchKeyword
    } else {
      institutesPaginateData.search = ""
    }
    InstitutionApi.getAllInstitutesApi(institutesPaginateData)
      .then(res => {
        if (res.data.success) {
          //   const pageNo = paginationData.page
          //   setinstitutesPaginateData({
          //     ...paginationData,
          //     totalRecords: res.data.data.count,
          //     page: res.data.data.metadata.page,
          //     limit: res.data.data.metadata.limit,
          //   })
          setinstitutesData([...res.data.data.rows])
          //   if (pageNo === 1) {
          //     setinstitutesData([...res.data.data.rows])
          //   } else {
          //     if (res.data.data.rows.length === 0) {
          //       setIsMoreInstitutes(false)
          //       setIsInstituteLoading(false)
          //     }
          //     setinstitutesData([...institutesData, ...res.data.data.rows])
          //   }
        } else {
          setinstitutesData([])
        }
      })
      .catch(err => {
        setinstitutesData([])
      })
  }

  const getAllSpeakers = (type, searchKeyword = "") => {
    const payload = {
      speaker_type: type,
      search: searchKeyword,
      page: 1,
      limit: 10,
    }

    UserApi.getSpeakerUser(payload)
      .then(res => {
        if (res.data.success) {
          //   const pageNo = doctorPaginateData.page
          //   setDoctorPaginateData({
          //     ...doctorPaginateData,
          //     totalRecords: res.data.data.count,
          //     page: res.data.data.metadata.page + 1,
          //     limit: res.data.data.metadata.limit,
          //   })
          let doctorArr = []
          res.data.data.rows.length > 0 &&
            res.data.data.rows.map((doctor, i) => {
              doctorArr[i] = {
                value: doctor.id,
                label:
                  doctor.first_name +
                  " " +
                  (doctor.last_name ? doctor.last_name : ""),
              }
            })
          setDoctorsData([...doctorArr])

          //   if (pageNo === 1) {
          //     setDoctorsData([...doctorArr])
          //   } else {
          //     if (doctorArr.length === 0) {
          //       setIsMoreDoctors(false)
          //       setIsDoctorLoading(false)
          //     }
          //   }
        } else {
          setDoctorsData([])
        }
      })
      .catch(err => {
        setDoctorsData([])
      })
  }

  const removeMedia = (activity_id, activity_media_id, type) => {
    Swal.fire({
      title: props.t("Are_you_sure"),
      text: props.t("Able_to_revert_this"),
      icon: props.t("Warning"),
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#f46a6a",
      confirmButtonText: props.t("Yes_delete_it"),
      cancelButtonText: props.t("Cancel"),
    }).then(result => {
      if (result.isConfirmed) {
        let activityMediaId = {
          activity_id: activity_id,
          activity_media_id: activity_media_id,
          type: type,
        }
        ActivityApi.removeActivityMedia(activityMediaId)
          .then(res => {
            if (type == "IMAGE") {
              setActivityMediaImage(res?.data?.data.rows)
            } else {
              setActivityMediaFile(res?.data?.data.rows)
            }
          })
          .catch(err => {
            console.log(err)
          })
      }
    })
  }

  // const handleActivityDate = activity_date => {
  //   validationType.setFieldValue("activity_date", activity_date)
  // }

  // Form validation
  const validationType = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: {
      institute_id: activity ? activity.institute_id : "",
      isInstitute: constants.ROLE.INSTITUTE == getRoleID() ? "false" : "",
      //speaker_user_id: activity ? activity.speaker_user_id : "",
      speaker_user_id: activity?.ActivitySpeaker?.map((item, index) => {
        return item.SpeakerUser.id
      }),
      speaker_type: activity?.speaker_type ? activity.speaker_type : "",
      activity_group: activity?.activity_group ? activity.activity_group : "",
      activity_type_id: activity?.activity_type_id
        ? activity.activity_type_id
        : "",
      title_en: activity ? activity.title_en : "",
      title_th: activity ? activity.title_th : "",
      description_en: activity?.description_en ? activity.description_en : "",
      description_th: activity ? activity.description_th : "",
      activity_date: activity?.activity_date ? activity.activity_date : "",
      address: activity?.address ? activity.address : "",
      latitude: activity?.latitude ? activity.latitude : "",
      longitude: activity?.longitude ? activity.longitude : "",
      start_time: activity?.start_time ? activity.start_time : "",
      end_time: activity?.end_time ? activity.end_time : "",
      province_id: activity?.province_id ? activity.province_id : "",
      education_type: activity?.education_type ? activity.education_type : "",
      attendee_limit: activity?.attendee_limit ? activity.attendee_limit : "",
      type: activity?.type ? activity.type : "",
      fee_type: activity?.fee_type ? activity.fee_type : "",
      fees: activity?.fees ? activity.fees : "",
      interest_id: activity?.ActivityInterest?.map((item, index) => {
        return item.Interest.id
      }),
      status: activity.status,
      publish_date: activity?.publish_date ? activity.publish_date : "",
      publish_time: activity?.publish_time ? activity.publish_time : "",
      //activity_images: activity ? activity.image_url : "",
      website: activity?.ContactDetails?.website
        ? activity.ContactDetails?.website
        : "",
      email: activity?.ContactDetails?.email
        ? activity.ContactDetails?.email
        : "",
      contact_number: activity?.ContactDetails?.contact_number
        ? activity.ContactDetails?.contact_number
        : "",
      facebook_link: activity?.ContactDetails?.facebook_link
        ? activity.ContactDetails?.facebook_link
        : "",
      online_meeting_url: activity?.online_meeting_url
        ? activity.online_meeting_url
        : "",
      join_number_of_users: activity?.join_number_of_users
        ? activity.join_number_of_users
        : "",
      activity_images: null,
      videoFile: null,
      is_credit: activity.is_credit ? true : false,
    },
    validationSchema: Yup.object().shape(
      {
        ...customValidation,
      },
      [
        ["title_en", "title_th"],
        ["description_en", "description_th"],
      ]
    ),
    onSubmit: values => {
     
      setIsSubmit(true)
      const data = new FormData()
      for (let i = 0; i < values.activity_images?.length; i++) {
        data.append("activity_images", values.activity_images[i])
      }
      data.append("file_url", files2)
      data.append("institute_id", values.institute_id)
      data.append("speaker_user_id", values.speaker_user_id)
      data.append("speaker_type", values.speaker_type)
      data.append("activity_group", values.activity_group)
      data.append("activity_type_id", values.activity_type_id)
      data.append("activity_date", values.activity_date)
      data.append("title_en", values.title_en)
      data.append("title_th", values.title_th)
      data.append("description_en", values.description_en)
      data.append("description_th", values.description_th)
      data.append("fees", values.fees)
      data.append("fee_type", values.fee_type)
      data.append("start_time", values.start_time)
      data.append("end_time", values.end_time)
      data.append("address", values.address)
      data.append("latitude", values.latitude)
      data.append("longitude", values.longitude)
      data.append("province_id", values.province_id)
      data.append("type", values.type)
      data.append("attendee_limit", values.attendee_limit)
      data.append("education_type", values.education_type)
      data.append("online_meeting_url", values.online_meeting_url)
      data.append("join_number_of_users", values.join_number_of_users)
      data.append("interest_id", values.interest_id)
      data.append("status", values.status)
      data.append("publish_date", values.publish_date)
      data.append("publish_time", values.publish_time)
      data.append("website", values.website)
      data.append("email", values.email)
      data.append("contact_number", values.contact_number)
      data.append("facebook_link", values.facebook_link)
      data.append("activity_id", activity_id)
      data.append("videoFile", videoFile)
      data.append("videoThumbnailFile", videoThumbnailFile)
      data.append("is_credit", values.is_credit)

      ActivityApi.updateActivity(data)
        .then(res => {
          setIsSubmit(false)
          if (res.data.success) {
            Swal.fire({
              text: res.data.message,
              icon: "success",
              imageAlt: "success image",
            }).then(result => {
              if (result?.isConfirmed) {
                history.push("/activities")
                formClear()
                setFiles1()
              }
            })
          } else {
            toastr.error(res?.data?.message, "")
          }
        })
        .catch(function (error) {
          setIsSubmit(false)
          if (error?.response?.data?.message) {
            toastr.error(error?.response?.data?.message, "")
          } else {
            toastr.error(
              error?.response?.data?.error?.errors?.map(
                (item, v) => `${item.msg}<br>`
              ),
              ""
            )
          }
        })
    },
  })

  useEffect(() => {
    if (isFormSubmited) {
      const { errors } = validationType
      const keys = Object.keys(errors)
      if (keys.length > 0) {
        const errorElement = document.getElementById(keys[0])
        errorElement.focus();
        setIsFormSubmited(false);
      }
    }
  }, [isFormSubmited, validationType])

  useEffect(() => {
    if (
      validationType.values.education_type ===
      constants.EDUCATION_TYPE.Multimedia
    ) {
      setCustomValidation({
        institute_id: Yup.string().when("isInstitute", {
          is: "false",
          then: Yup.string(),
          otherwise: Yup.string().required(props.t("Required-Message")),
        }),
        title_th: Yup.string().when("title_en", {
          is: title_en => !title_en || title_en.length === 0,
          then: Yup.string().required(props.t("Required-Message")),
          otherwise: Yup.string(),
        }),
        title_en: Yup.string().when("title_th", {
          is: title_th => !title_th || title_th.length === 0,
          then: Yup.string().required(props.t("Required-Message")),
          otherwise: Yup.string(),
        }),
        videoFile: undefined,
        videoThumbnailImage: undefined,
        status: Yup.string().required(props.t("Required-Message")),
        publish_date: Yup.string().when("status", {
          is: "2",
          then:  Yup.string().required(props.t("Required-Message")),
          otherwise: Yup.string(),
        }),
        publish_time: Yup.string().when("status", {
          is: "2",
          then: Yup.string().required(props.t("Required-Message")),
          otherwise:  Yup.string(),
        }),
        description_th: Yup.string().when("description_en", (val, schema) => {
          if (val?.length == undefined) {
            //if address exist then apply min max else not
            return Yup.string().required(props.t("Required-Message"))
          } else {
            return Yup.string().notRequired()
          }
        }),
      })
    } else {
      setCustomValidation({
        institute_id: Yup.string().when("isInstitute", {
          is: "false",
          then: Yup.string(),
          otherwise: Yup.string().required(props.t("Required-Message")),
        }),
        education_type: Yup.string().required(props.t("Required-Message")),
        activity_date: Yup.date().required(props.t("Required-Message")),
        activity_group: Yup.string().required(props.t("Required-Message")),
        activity_type_id: Yup.string().required(props.t("Required-Message")),
        title_th: Yup.string().when("title_en", {
          is: title_en => !title_en || title_en.length === 0,
          then: Yup.string().required(props.t("Required-Message")),
          otherwise: Yup.string(),
        }),
        title_en: Yup.string().when("title_th", {
          is: title_th => !title_th || title_th.length === 0,
          then: Yup.string().required(props.t("Required-Message")),
          otherwise: Yup.string(),
        }),
        start_time: Yup.string().required(props.t("Required-Message")),
        end_time: Yup.string().required(props.t("Required-Message")),
        province_id: Yup.string().required(props.t("Required-Message")),
        attendee_limit: Yup.string().required(props.t("Required-Message")),
        join_number_of_users: Yup.string().when("attendee_limit", {
          is: "LIMITED",
          then: Yup.string()
            .required(props.t("Required-Message"))
            .matches(/^[0-9\b]+$/, props.t("Only-Number-Message")),
        }),
        type: Yup.string().required(props.t("Required-Message")),
        address: Yup.string().when("type", {
          is: type =>
            type === constants.TYPE.Onsite || type === constants.TYPE.Hybrid,
          then: Yup.string().required(props.t("Required-Message")),
          otherwise: Yup.string(),
        }),
        latitude: Yup.string().when("type", {
          is: type =>
            type === constants.TYPE.Onsite || type === constants.TYPE.Hybrid,
          then: Yup.string().required(props.t("Required-Message")),
          otherwise: Yup.string(),
        }),
        longitude: Yup.string().when("type", {
          is: type =>
            type === constants.TYPE.Onsite || type === constants.TYPE.Hybrid,
          then: Yup.string().required(props.t("Required-Message")),
          otherwise: Yup.string(),
        }),
        online_meeting_url: Yup.string().when("type", {
          is: type =>
            type === constants.TYPE.Online || type === constants.TYPE.Hybrid,
          then: Yup.string().required(props.t("Required-Message")),
          otherwise: Yup.string(),
        }),
        fee_type: Yup.string().required(props.t("Required-Message")),
        fees: Yup.number().when("fee_type", {
          is: "PAID",
          then: Yup.number().required(props.t("Required-Message")),
        }),
        speaker_type: Yup.string().required(props.t("Required-Message")),
        speaker_user_id: Yup.array()
          .min(1, props.t("Select_Interest"))
          .required(props.t("Required-Message")),
        website: Yup.string().required(props.t("Required-Message")),
        email: Yup.string().email().required(props.t("Required-Message")),
        // description_en: Yup.string().when("description_th", (val, schema) => {
        //     if(val?.length == undefined) {  //if address exist then apply min max else not
        //        return Yup.string().required(props.t("Required-Message"));
        //     } else {
        //        return Yup.string().notRequired();
        //     }
        // }),
        contact_number: Yup.string()
          .min(8, props.t("Contact-Number-Message"))
          .max(10, props.t("Contact-Number-Message"))
          .required(props.t("Required-Message")),
        facebook_link: Yup.string().required(props.t("Required-Message")),
        interest_id: Yup.array()
          .min(1, props.t("Select_Interest"))
          .required(props.t("Required-Message")),
        status: Yup.string().required(props.t("Required-Message")),
        publish_date: Yup.string().when("status", {
          is: "2",
          then:  Yup.string().required(props.t("Required-Message")),
          otherwise: Yup.string(),
        }),
        publish_time: Yup.string().when("status", {
          is: "2",
          then: Yup.string().required(props.t("Required-Message")),
          otherwise:  Yup.string(),
        }),
        description_th: Yup.string().when("description_en", (val, schema) => {
          if (val?.length == undefined) {
            //if address exist then apply min max else not
            return Yup.string().required(props.t("Required-Message"))
          } else {
            return Yup.string().notRequired()
          }
        }),
        // activity_images: Yup.mixed().required(
        //     props.t("Image is required")
        // ),

        // contact_number:  Yup.number()
        //     .typeError(props.t("you must specify a number") )
        //     .required(props.t("Required-Message")
        // ),
        activity_images: Yup.mixed()
          .test(
            "fileSize",
            props.t("File_Size_is_too_large") + " Max Size is 2 MB",
            value => {
              if (value && value?.length > 0) {
                for (let i = 0; i < value.length; i++) {
                  if (
                    value[i].size > constants.IMAGE_VALIDATION.ACTIVITY_IMAGE
                  ) {
                    return false
                  }
                }
              }
              return true
            }
          )
          .test("fileType", props.t("Unsupported_File_Format"), value => {
            if (value && value.length > 0) {
              for (let i = 0; i < value.length; i++) {
                if (
                  value[i].type != "image/png" &&
                  value[i].type != "image/jpg" &&
                  value[i].type != "image/jpeg"
                ) {
                  return false
                }
              }
            }
            return true
          }),
        file_url: Yup.mixed()
          .test(
            "fileSize",
            props.t("File_Size_is_too_large") + " Max Size is 10 MB",
            value => {
              if (value && value?.length > 0) {
                for (let i = 0; i < value.length; i++) {
                  if (value[i].size > constants.IMAGE_VALIDATION.ACTIVITY_PDF) {
                    return false
                  }
                }
              }
              return true
            }
          )
          .test("fileType", props.t("Unsupported_File_Format"), value => {
            if (value && value.length > 0) {
              for (let i = 0; i < value.length; i++) {
                if (value[i].type != "application/pdf") {
                  return false
                }
              }
            }
            return true
          }),
        videoFile: undefined,
        videoThumbnailImage: undefined,
      })
    }
  }, [validationType.values.education_type])

  const getActivityDetails = () => {
    setLoading(true)

    let ActivityId = { activity_id: activity_id }

    ActivityApi.getActivityById(ActivityId)
      .then(res => {
        const xy = res.data.data?.ActivityInterest
        const yz = xy.map(item => {
          return { value: item.Interest.id, label: item.Interest.name_en }
        })
        setSelectedOption(yz)

        const xy1 = res.data.data?.ActivitySpeaker
        const yz1 = xy1.map(item => {
          return {
            value: item.SpeakerUser.id,
            label:
              item.SpeakerUser.first_name +
              " " +
              (item.SpeakerUser.last_name ? item.SpeakerUser.last_name : ""),
          }
        })
        setSelectedOptionSpeaker(yz1)

        if (res.data.data.Institute) {
          setSelectedInstitute({
            value: res.data.data.Institute.id,
            label: res.data.data.Institute.institution_name,
          })
        }
        setActivity(res?.data?.data)
        setActivityMediaImage(res?.data?.data.ActivityMediaImage)
        setActivityMediaFile(res?.data?.data.ActivityMediaFile)
        setLoading(false)
        handleChangeActivityType(res?.data?.data?.activity_group)
        handleChangeSpeakerType(res?.data?.data?.speaker_type)
        changeActivityStatus(res?.data?.data?.status);

        let strEn = res.data.data.description_en
        if (res.data.data.description_en) {
          setContentEn(
            EditorState.createWithContent(
              ContentState.createFromBlockArray(convertFromHTML(strEn))
            )
          )
        }

        if(res.data.data.description_th){
          let strTh = res.data.data.description_th
          setContentTh(
            EditorState.createWithContent(
              ContentState.createFromBlockArray(convertFromHTML(strTh))
            )
          )
        }
        
      })
      .catch(err => {
        console.log(err)
      })

    // InstitutionApi.getAllInstituteWithoutPaginate()
    //     .then((res) => {
    //         setInstitutionList(res?.data?.data);
    //         setLoading(false);
    //     })
    //     .catch((err) => {
    //         console.log(err);
    //     });
    ProvinceApi.getAllProvince()
      .then(res => {
        setProviceList(res?.data?.data)
        setLoading(false)
      })
      .catch(err => {
        console.log(err)
      })
    InterestApi.getAllInterest()
      .then(res => {
        const y = res?.data?.data?.map((item, index) => {
          return {
            label: item.name_en,
            value: item.id,
          }
        })
        setInterestList(y)
        setLoading(false)
      })
      .catch(err => {
        console.log(err)
      })
  }

  const handleChangeSpeakerType = speaker_type => {
    if (speakerNewType != "" && speaker_type != speakerNewType) {
      setSelectedOptionSpeaker([])
    }
    validationType.setFieldValue("speaker_type", speaker_type)
    let speakerType = { speaker_type: speaker_type }
    setSpeakerType(speaker_type)
    getAllSpeakers(speaker_type)
    // UserApi.getSpeakerUser(speakerType)
    //     .then((res) => {
    //         setUserList(res?.data?.data);
    //         setLoading(false);
    //     })
    //     .catch((err) => {
    //         console.log(err);
    //     });
  }

  const handleChangeActivityType = activity_group => {
    validationType.setFieldValue("activity_group", activity_group)
    let activityTypeId = { activity_group: activity_group }
    if (activity_group) {
      ActivityTypeApi.getActivityTypeById(activityTypeId)
        .then(res => {
          setActivitySubTypeList(res?.data?.data)
        })
        .catch(err => {
          console.log(err)
        })
    }
  }

  useEffect(() => {
    if (contentTh) {
      convertContentThTextToHTML()
    } 
  }, [contentTh])

  useEffect(() => {
    if (contentEn) {
      convertContentEnTextToHTML()
    }
  }, [contentEn])

  const convertContentThTextToHTML = async () => {
    const contentHtml = await url.convertEditorContentTextToHtml(contentTh)
    validationType.setFieldValue("description_th", contentHtml)
  }

  const convertContentEnTextToHTML = async () => {
    const contentHtml = await url.convertEditorContentTextToHtml(contentEn)
    validationType.setFieldValue("description_en", contentHtml)
  }

  const formClear = () => {
    validationType.resetForm()
    setFiles1([])
  }

  const removeImage = lastModified => {
    const updatedList = [
      ...validationType.values.activity_images.filter(
        e => e.lastModified !== lastModified
      ),
    ]
    validationType.setFieldValue(
      "activity_images",
      updatedList.length > 0 ? updatedList : null
    )
  }

  const onSearchInstitutesHandler = debounce(function (searchInput) {
    if (searchInput.length >= 2) getAllInstitutesPaginate(searchInput)
  }, 500)

  const onSearchDoctorHandler = debounce(function (searchInput) {
    if (searchInput.length >= 2)
      getAllSpeakers(speakerNewType, searchInput.toString().trim())
  }, 500)

  const onOpenInstituteMenuHandler = () => {
    getAllInstitutesPaginate()
  }



  const [isSchedulePublish, setIsSchedulePublish] = useState(false);

  const [latitude, setLatitude] = useState(15.87);
  const [longitude, setLongitude] = useState(100.5086199930891);
  const [address, setAddress] = useState(
    "Ministry of Public Health The Secretariat of the Medical Council"
  )
  const libraries = ["places", "geometry"]

  const google_key = config.google.API_KEY

  const inputRef = useRef()
  const inputRef1 = useRef()

  const handlePlaceChanged = () => {
    const [place] = inputRef.current.getPlaces()
    if (place) {
      //console.log(place);
      if (place.geometry.location.lat() && place.geometry.location.lng()) {
        setLatitude(place.geometry.location.lat())
        setLongitude(place.geometry.location.lng())
        setAddress(place.formatted_address)
      }
      validationType.setFieldValue("address", place.formatted_address)
      validationType.setFieldValue("latitude", place.geometry.location.lat())
      validationType.setFieldValue("longitude", place.geometry.location.lng())
    }
  }

  const handlePlaceChangedOne = () => {
    const [place] = inputRef1.current.getPlaces()
    if (place) {
      //console.log(place);
      if (place.geometry.location.lat() && place.geometry.location.lng()) {
        setLatitude(place.geometry.location.lat())
        setLongitude(place.geometry.location.lng())
        setAddress(place.formatted_address)
      }
      validationType.setFieldValue("address", place.formatted_address)
      validationType.setFieldValue("latitude", place.geometry.location.lat())
      validationType.setFieldValue("longitude", place.geometry.location.lng())
    }
  }

  const onLocationSearch = () => {
    setShowModal(true)
  }

  function handleLoad() {}

  function hanldePlacesChanged() {}

  const onMarkerDragEnd = coord => {
    const { latLng } = coord
    setLatitude(latLng.lat())
    setLongitude(latLng.lng())
    validationType.setFieldValue("latitude", latLng.lat())
    validationType.setFieldValue("longitude", latLng.lng())
    validationType.setFieldValue("addrsss", address)
  }

  const changeActivityStatus = (value) => {
    validationType.setFieldValue("status",value);
    if(value == 2) {
      setIsSchedulePublish(true);
    } else {
      setIsSchedulePublish(false);
    }
  };

  document.title = props.t("Activity") + " | " + props.t("MedX")

  return (
    <React.Fragment>
      <div className="page-content">
        <Breadcrumbs
          title={props.t("Activity")}
          breadcrumbItem={props.t("Edit") + " " + props.t("Activity")}
        />
        <Container fluid={true}>
          <Row>
            <Col lg={12}>
              <Card>
                <CardBody>
                  <Col sm={12}>
                    <Form
                      onSubmit={e => {
                        e.preventDefault()
                        validationType.handleSubmit()
                        setIsFormSubmited(true)
                        return false
                      }}
                    >
                      <Row>
                        {constants.ROLE.INSTITUTE != getRoleID() ? (
                          <Col sm={6}>
                            <div className="mb-3">
                              <Label className="form-label">
                                {props.t("Institute")}{" "}
                                <span className="text-danger">*</span>
                              </Label>
                              {/* <select
                              name="institute_id"
                              type="select"
                              className="form-control"
                              onChange={validationType.handleChange}
                              onBlur={validationType.handleBlur}
                              value={validationType.values.institute_id || ""}
                              >
                              <option value="">
                                {props.t("Select_Institute")}
                              </option>
                              {institutionList.map((item, i) => {
                                return (
                                  <option value={item.id} key={i}>
                                    {item.institution_name}
                                  </option>
                                )
                              })}
                            </select> */}
                              <input
                                type="text"
                                className="hide-focus"
                                id="institute_id"
                              />
                              <Select
                                className={
                                  validationType.touched.institute_id &&
                                  validationType.errors.institute_id
                                    ? "bor "
                                    : "text-dark"
                                }
                                styles={{
                                  control: styles => ({
                                    ...styles,
                                  }),
                                }}
                                onChange={e => {
                                  validationType.setFieldValue(
                                    "institute_id",
                                    e.value
                                  )
                                }}
                                //   onMenuScrollToBottom={
                                //     onScrollInstituesDataHandler
                                //   }
                                value={selectedInstitute}
                                onInputChange={onSearchInstitutesHandler}
                                onMenuOpen={onOpenInstituteMenuHandler}
                                defaultValue={
                                  validationType.values.institute_id
                                }
                                onBlur={validationType.handleBlur}
                                name="institute_id"
                                options={[...institutesData]}
                                placeholder={
                                  <div>{props.t("typeToSearch")}</div>
                                }
                              />
                              {validationType.touched.institute_id &&
                              validationType.errors.institute_id ? (
                                <div
                                  style={{
                                    color: "#f46a6a",
                                    fontSize: "11px",
                                    marginTop: "3px",
                                  }}
                                >
                                  {validationType.errors.institute_id}
                                </div>
                              ) : null}
                            </div>
                          </Col>
                        ) : (
                          <></>
                        )}

                        <Col sm={6}>
                          <div className="mb-3">
                            <Label className="form-label">
                              {props.t("Education_Type")}{" "}
                              <span className="text-danger">*</span>
                            </Label>
                            <select
                              name="education_type"
                              id="education_type"
                              type="select"
                              className="form-control"
                              onChange={validationType.handleChange}
                              onBlur={validationType.handleBlur}
                              value={validationType.values.education_type || ""}
                            >
                              <option value="">
                                {props.t("Select_Education_Type")}
                              </option>
                              {Object.keys(constants.EDUCATION_TYPE).map(
                                key => {
                                  return (
                                    <option value={key} key={key}>
                                      {constants.EDUCATION_TYPE[key]}
                                    </option>
                                  )
                                }
                              )}
                            </select>
                            {validationType.touched.education_type &&
                            validationType.errors.education_type ? (
                              <div
                                style={{
                                  color: "#f46a6a",
                                  fontSize: "11px",
                                  marginTop: "3px",
                                }}
                              >
                                {validationType.errors.education_type}
                              </div>
                            ) : null}
                          </div>
                        </Col>

                        {validationType.values.education_type !==
                          constants.EDUCATION_TYPE.Multimedia && (
                          <Col sm={6}>
                            <div className="mb-3">
                              <Label className="form-label">
                                {props.t("Activity_Date")}{" "}
                                <span className="text-danger">*</span>
                              </Label>
                              {/* <Flatpickr
                                                        name="activity_date"
                                                        className="form-control d-block"
                                                        placeholder="dd-mm-yyyy"
                                                        options={{
                                                        altInput: true,
                                                        altFormat: "d-m-Y",
                                                        dateFormat: "Y-m-d"
                                                        }}
                                                        onChange={(e) => handleActivityDate(e.target.value)}
                                                        //onChange={validationType.handleChange}
                                                        onBlur={validationType.handleBlur}
                                                        value={validationType.values.activity_date || ""}
                                                        invalid={
                                                            validationType.touched.activity_date && validationType.errors.activity_date ? true : false
                                                        }
                                                    /> */}
                              <Input
                                name="activity_date"
                                id="activity_date"
                                type="date"
                                min={moment().format("Y-M-D")}
                                onChange={validationType.handleChange}
                                onBlur={validationType.handleBlur}
                                value={
                                  validationType.values.activity_date || ""
                                }
                                invalid={
                                  validationType.touched.activity_date &&
                                  validationType.errors.activity_date
                                    ? true
                                    : false
                                }
                              />
                              {validationType.touched.activity_date &&
                              validationType.errors.activity_date ? (
                                <FormFeedback type="invalid">
                                  {validationType.errors.activity_date}
                                </FormFeedback>
                              ) : null}
                            </div>
                          </Col>
                        )}
                      </Row>

                      {validationType.values.education_type !==
                        constants.EDUCATION_TYPE.Multimedia && (
                        <Row>
                          <Col sm={6}>
                            <div className="mb-3">
                              <Label className="form-label">
                                {props.t("Activity_Group")}{" "}
                                <span className="text-danger">*</span>
                              </Label>
                              <select
                                name="activity_group"
                                id="activity_group"
                                type="select"
                                className="form-control"
                                onChange={e =>
                                  handleChangeActivityType(e.target.value)
                                }
                                onBlur={validationType.handleBlur}
                                value={
                                  validationType.values.activity_group || ""
                                }
                              >
                                <option value="">
                                  {props.t("Select_Activity_Group")}
                                </option>
                                {Object.keys(constants.ACTIVITY_GROUP).map(
                                  key => {
                                    return (
                                      <option value={key} key={key}>
                                        {constants.ACTIVITY_GROUP[key]}
                                      </option>
                                    )
                                  }
                                )}
                              </select>
                              {validationType.touched.activity_group &&
                              validationType.errors.activity_group ? (
                                <div
                                  style={{
                                    color: "#f46a6a",
                                    fontSize: "11px",
                                    marginTop: "3px",
                                  }}
                                >
                                  {validationType.errors.activity_group}
                                </div>
                              ) : null}
                            </div>
                          </Col>
                          <Col sm={6}>
                            <div className="mb-3">
                              <Label className="form-label">
                                {props.t("Activity_Type")}{" "}
                                <span className="text-danger">*</span>
                              </Label>
                              <select
                                name="activity_type_id"
                                id="activity_type_id"
                                type="select"
                                className="form-control"
                                onChange={validationType.handleChange}
                                onBlur={validationType.handleBlur}
                                value={
                                  validationType.values.activity_type_id || ""
                                }
                              >
                                <option value="">
                                  {props.t("Select_Activity_Type")}
                                </option>
                                {activitySubTypeList?.map((item, i) => {
                                  return (
                                    <option value={item.id} key={i}>
                                      {item.code} - {item.title_en}
                                    </option>
                                  )
                                })}
                              </select>
                              {validationType.touched.activity_type_id &&
                              validationType.errors.activity_type_id ? (
                                <div
                                  style={{
                                    color: "#f46a6a",
                                    fontSize: "11px",
                                    marginTop: "3px",
                                  }}
                                >
                                  {validationType.errors.activity_type_id}
                                </div>
                              ) : null}
                            </div>
                          </Col>
                        </Row>
                      )}

                      <Row>
                        <Col sm={6}>
                          <div className="mb-3">
                            <Label className="form-label">
                              {props.t("Title_Thai")}{" "}
                              <span className="text-danger">*</span>
                            </Label>
                            <Input
                              name="title_th"
                              id="title_th"
                              placeholder={props.t("Title_Thai")}
                              type="text"
                              onChange={validationType.handleChange}
                              onBlur={validationType.handleBlur}
                              value={validationType.values.title_th || ""}
                              invalid={
                                validationType.touched.title_th &&
                                validationType.errors.title_th
                                  ? true
                                  : false
                              }
                            />
                            {validationType.touched.title_th &&
                            validationType.errors.title_th ? (
                              <FormFeedback type="invalid">
                                {validationType.errors.title_th}
                              </FormFeedback>
                            ) : null}
                          </div>
                        </Col>

                        <Col sm={6}>
                          <div className="mb-3">
                            <Label className="form-label">
                              {props.t("Title_Eng")}{" "}
                              <span className="text-danger">*</span>
                            </Label>
                            <Input
                              name="title_en"
                              id="title_en"
                              placeholder={props.t("Title_Eng")}
                              type="text"
                              onChange={validationType.handleChange}
                              onBlur={validationType.handleBlur}
                              value={validationType.values.title_en || ""}
                              invalid={
                                validationType.touched.title_en &&
                                validationType.errors.title_en
                                  ? true
                                  : false
                              }
                            />
                            {validationType.touched.title_en &&
                            validationType.errors.title_en ? (
                              <FormFeedback type="invalid">
                                {validationType.errors.title_en}
                              </FormFeedback>
                            ) : null}
                          </div>
                        </Col>
                      </Row>
                      {/* start cond */}
                      {validationType.values.education_type !==
                        constants.EDUCATION_TYPE.Multimedia && (
                        <>
                          <Row>
                            <Col sm={6}>
                              <div className="form-check mb-3">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  name="is_credit"
                                  id="is_credit"
                                  checked={
                                    validationType.values.is_credit
                                      ? true
                                      : false
                                  }
                                  onClick={e => {
                                    validationType.setFieldValue(
                                      "is_credit",
                                      !e.target.checked
                                    )
                                  }}
                                />
                                <Label className="form-label">
                                  {props.t("isHavingCredit")}
                                </Label>
                              </div>
                              {/* </span> */}
                            </Col>
                          </Row>
                          <Row>
                            <Col sm={6}>
                              <div className="mb-3">
                                <Label className="form-label">
                                  {props.t("Start_Time")}{" "}
                                  <span className="text-danger">*</span>
                                </Label>
                                {/* <Flatpickr
                                                        className="form-control d-block"
                                                        placeholder="Select time"
                                                        name="start_time"
                                                        options={{
                                                            enableTime: true,
                                                            noCalendar: true,
                                                            dateFormat: "H:i"
                                                          }}
                                                        onChange={validationType.handleChange}
                                                        onBlur={validationType.handleBlur}
                                                        value={validationType.values.start_time || ""}
                                                        invalid={
                                                            validationType.touched.start_time && validationType.errors.start_time ? true : false
                                                        }
                                                        /> */}
                                <Input
                                  name="start_time"
                                  id="start_time"
                                  type="time"
                                  onChange={validationType.handleChange}
                                  onBlur={validationType.handleBlur}
                                  value={validationType.values.start_time || ""}
                                  invalid={
                                    validationType.touched.start_time &&
                                    validationType.errors.start_time
                                      ? true
                                      : false
                                  }
                                />
                                {validationType.touched.start_time &&
                                validationType.errors.start_time ? (
                                  <FormFeedback type="invalid">
                                    {validationType.errors.start_time}
                                  </FormFeedback>
                                ) : null}
                              </div>
                            </Col>
                            <Col sm={6}>
                              <div className="mb-3">
                                <Label className="form-label">
                                  {props.t("End_Time")}{" "}
                                  <span className="text-danger">*</span>
                                </Label>
                                {/* <Flatpickr
                                                        name="end_time"
                                                        className="form-control d-block"
                                                        placeholder="Select time"
                                                        options={{
                                                            enableTime: true,
                                                            noCalendar: true,
                                                            dateFormat: "H:i"
                                                          }}
                                                        onChange={validationType.handleChange}
                                                        onBlur={validationType.handleBlur}
                                                        value={validationType.values.end_time || ""}
                                                        invalid={
                                                            validationType.touched.end_time && validationType.errors.end_time ? true : false
                                                        }
                                                        /> */}
                                <Input
                                  name="end_time"
                                  id="end_time"
                                  type="time"
                                  onChange={validationType.handleChange}
                                  onBlur={validationType.handleBlur}
                                  value={validationType.values.end_time || ""}
                                  invalid={
                                    validationType.touched.end_time &&
                                    validationType.errors.end_time
                                      ? true
                                      : false
                                  }
                                />
                                {validationType.touched.end_time &&
                                validationType.errors.end_time ? (
                                  <FormFeedback type="invalid">
                                    {validationType.errors.end_time}
                                  </FormFeedback>
                                ) : null}
                              </div>
                            </Col>
                          </Row>
                          <Row>
                            <Col sm={6}>
                              <div className="mb-3">
                                <Label className="form-label">
                                  {props.t("Province")}{" "}
                                  <span className="text-danger">*</span>
                                </Label>
                                <select
                                  name="province_id"
                                  id="province_id"
                                  type="select"
                                  className="form-control"
                                  onChange={validationType.handleChange}
                                  onBlur={validationType.handleBlur}
                                  value={
                                    validationType.values.province_id || ""
                                  }
                                >
                                  <option value="">
                                    {props.t("Select-Province-Message")}
                                  </option>
                                  {proviceList?.map((item, i) => {
                                    return (
                                      <option value={item.id} key={i}>
                                        {item.name_en}
                                      </option>
                                    )
                                  })}
                                </select>
                                {validationType.touched.province_id &&
                                validationType.errors.province_id ? (
                                  <div
                                    style={{
                                      color: "#f46a6a",
                                      fontSize: "11px",
                                      marginTop: "3px",
                                    }}
                                  >
                                    {validationType.errors.province_id}
                                  </div>
                                ) : null}
                              </div>
                            </Col>
                          </Row>
                          <Row>
                            <Col sm={6}>
                              <div className="mb-3">
                                <Label className="form-label">
                                  {props.t("Attendee_Limit")}{" "}
                                  <span className="text-danger">*</span>
                                </Label>
                                <select
                                  name="attendee_limit"
                                  id="attendee_limit"
                                  type="select"
                                  className="form-control"
                                  onChange={validationType.handleChange}
                                  onBlur={validationType.handleBlur}
                                  value={
                                    validationType.values.attendee_limit || ""
                                  }
                                >
                                  <option value="">
                                    {props.t("Select_Attendee_Limit")}
                                  </option>
                                  {Object.keys(constants.ATTENDEE_LIMIT).map(
                                    key => {
                                      return (
                                        <option value={key} key={key}>
                                          {constants.ATTENDEE_LIMIT[key]}
                                        </option>
                                      )
                                    }
                                  )}
                                </select>
                                {validationType.touched.attendee_limit &&
                                validationType.errors.attendee_limit ? (
                                  <div
                                    style={{
                                      color: "#f46a6a",
                                      fontSize: "11px",
                                      marginTop: "3px",
                                    }}
                                  >
                                    {validationType.errors.attendee_limit}
                                  </div>
                                ) : null}
                              </div>
                            </Col>
                            <Col sm={6}>
                              <div className="mb-3">
                                <Label className="form-label">
                                  {props.t("Join_Number_Users")}{" "}
                                </Label>
                                <Input
                                  name="join_number_of_users"
                                  id="join_number_of_users"
                                  placeholder={props.t("Join_Number_Users")}
                                  type="text"
                                  readOnly={
                                    validationType.values.attendee_limit ===
                                    "LIMITED"
                                      ? false
                                      : true
                                  }
                                  onChange={validationType.handleChange}
                                  onBlur={validationType.handleBlur}
                                  value={
                                    validationType.values.attendee_limit ==
                                    "LIMITED"
                                      ? validationType.values
                                          .join_number_of_users
                                      : ""
                                  }
                                  invalid={
                                    validationType.touched
                                      .join_number_of_users &&
                                    validationType.errors.join_number_of_users
                                      ? true
                                      : false
                                  }
                                />
                                {validationType.touched.join_number_of_users &&
                                validationType.errors.join_number_of_users ? (
                                  <FormFeedback type="invalid">
                                    {validationType.errors.join_number_of_users}
                                  </FormFeedback>
                                ) : null}
                              </div>
                            </Col>
                          </Row>

                          <Row>
                            <Col sm={6}>
                              <div className="mb-3">
                                <Label className="form-label">
                                  {props.t("Type")}{" "}
                                  <span className="text-danger">*</span>
                                </Label>
                                <select
                                  name="type"
                                  id="type"
                                  type="select"
                                  className="form-control"
                                  onChange={validationType.handleChange}
                                  onBlur={validationType.handleBlur}
                                  value={validationType.values.type || ""}
                                >
                                  <option value="">
                                    {props.t("Please_Select_Type")}
                                  </option>
                                  {Object.keys(constants.TYPE).map(key => {
                                    return (
                                      <option value={key} key={key}>
                                        {constants.TYPE[key]}
                                      </option>
                                    )
                                  })}
                                </select>
                                {validationType.touched.type &&
                                validationType.errors.type ? (
                                  <div
                                    style={{
                                      color: "#f46a6a",
                                      fontSize: "11px",
                                      marginTop: "3px",
                                    }}
                                  >
                                    {validationType.errors.type}
                                  </div>
                                ) : null}
                              </div>
                            </Col>

                            {(validationType.values.type ===
                              constants.TYPE.Online ||
                              validationType.values.type ===
                                constants.TYPE.Hybrid) && (
                              <Col sm={6}>
                                <div className="mb-3">
                                  <Label className="form-label">
                                    {props.t("Online_Meeting_Url")}{" "}
                                    <span className="text-danger">*</span>
                                  </Label>
                                  <Input
                                    name="online_meeting_url"
                                    id="online_meeting_url"
                                    placeholder={props.t("Online_Meeting_Url")}
                                    type="text"
                                    readOnly={
                                      validationType.values.type ===
                                        constants.TYPE.Online ||
                                      validationType.values.type ===
                                        constants.TYPE.Hybrid
                                        ? false
                                        : true
                                    }
                                    onChange={validationType.handleChange}
                                    onBlur={validationType.handleBlur}
                                    value={
                                      validationType.values.type ===
                                        constants.TYPE.Online ||
                                      validationType.values.type ===
                                        constants.TYPE.Hybrid
                                        ? validationType.values
                                            .online_meeting_url
                                        : ""
                                    }
                                    invalid={
                                      validationType.touched
                                        .online_meeting_url &&
                                      validationType.errors.online_meeting_url
                                        ? true
                                        : false
                                    }
                                  />
                                  {validationType.touched.online_meeting_url &&
                                  validationType.errors.online_meeting_url ? (
                                    <FormFeedback type="invalid">
                                      {validationType.errors.online_meeting_url}
                                    </FormFeedback>
                                  ) : null}
                                </div>
                              </Col>
                            )}

                            {(validationType.values.type ===
                              constants.TYPE.Onsite ||
                              validationType.values.type ===
                                constants.TYPE.Hybrid) && (
                              <>
                                <Col sm={6}>
                                  <LoadScript
                                    googleMapsApiKey={google_key}
                                    libraries={libraries}
                                    region="th"
                                    country="th"
                                  >
                                    <div className="mb-3">
                                      <Label className="form-label">
                                        {props.t("Address")}{" "}
                                        <span className="text-danger">*</span>
                                      </Label>

                                      <StandaloneSearchBox
                                        onLoad={ref =>
                                          (inputRef1.current = ref)
                                        }
                                        onPlacesChanged={handlePlaceChangedOne}
                                      >
                                        <Input
                                          name="address"
                                          id="address"
                                          placeholder={props.t("Address")}
                                          type="text"
                                          onChange={validationType.handleChange}
                                          onBlur={validationType.handleBlur}
                                          readOnly={
                                            validationType.values.type ===
                                              constants.TYPE.Onsite ||
                                            validationType.values.type ===
                                              constants.TYPE.Hybrid
                                              ? false
                                              : true
                                          }
                                          value={
                                            validationType.values.type ===
                                              constants.TYPE.Onsite ||
                                            validationType.values.type ===
                                              constants.TYPE.Hybrid
                                              ? validationType.values.address
                                              : ""
                                          }
                                          invalid={
                                            validationType.touched.address &&
                                            validationType.errors.address
                                              ? true
                                              : false
                                          }
                                        />
                                      </StandaloneSearchBox>

                                      {validationType.touched.address &&
                                      validationType.errors.address ? (
                                        <FormFeedback type="invalid">
                                          {validationType.errors.address}
                                        </FormFeedback>
                                      ) : null}

                                      <Button
                                        onClick={onLocationSearch}
                                        color="info"
                                        className="btn-sm"
                                        style={{
                                          float: "left",
                                          marginTop: "4px",
                                        }}
                                      >
                                        {props.t("FindLocation")}
                                      </Button>

                                      <Modal
                                        className="map-model locationSearch"
                                        isOpen={showModal}
                                        centered={true}
                                      >
                                        <ModalBody>
                                          <Row>
                                            <Col lg={12}>
                                              <div className="text-center">
                                                <h4 className="modal-title">
                                                  {props.t(
                                                    "Whatyourexactlocation"
                                                  )}
                                                </h4>
                                              </div>
                                            </Col>
                                            <Col lg={12}>
                                              {/* <LoadScript
                                            googleMapsApiKey={google_key}
                                            libraries={libraries}
                                            region="th"
                                            country="th"
                                            > */}
                                              <StandaloneSearchBox
                                                onLoad={ref =>
                                                  (inputRef.current = ref)
                                                }
                                                onPlacesChanged={
                                                  handlePlaceChanged
                                                }
                                              >
                                                <input
                                                  type="text"
                                                  className="form-control"
                                                  placeholder={props.t(
                                                    "Address"
                                                  )}
                                                  style={{
                                                    marginBottom: "15px",
                                                    border: "1px solid #ccc",
                                                  }}
                                                />
                                              </StandaloneSearchBox>

                                              <div>
                                                <Button
                                                  color="info"
                                                  className="btn-sm"
                                                  onClick={() => {
                                                    setShowModal(false)
                                                  }}
                                                  style={{ float: "left" }}
                                                >
                                                  {props.t("Save")}
                                                </Button>
                                              </div>

                                              <br></br>
                                              <br></br>
                                              <div className="modal-body scrollAndFixedHeight">
                                                {latitude && longitude && (
                                                  <GoogleMap
                                                    zoom={14}
                                                    center={{
                                                      lat: latitude,
                                                      lng: longitude,
                                                    }}
                                                    id="map"
                                                    style={{
                                                      overflow: "unset",
                                                    }}
                                                    yesIWantToUseGoogleMapApiInternals
                                                  >
                                                    <MarkerF
                                                      position={{
                                                        lat: latitude,
                                                        lng: longitude,
                                                      }}
                                                      name={address}
                                                      draggable={true}
                                                      onDragEnd={event =>
                                                        onMarkerDragEnd(event)
                                                      }
                                                    />
                                                  </GoogleMap>
                                                )}
                                              </div>
                                              {/* </LoadScript> */}
                                            </Col>
                                          </Row>
                                        </ModalBody>
                                      </Modal>
                                    </div>
                                  </LoadScript>
                                </Col>
                                <Col sm={6}>
                                  <div className="mb-3">
                                    <Label className="form-label">
                                      {props.t("Latitude")}{" "}
                                      <span className="text-danger">*</span>
                                    </Label>
                                    <Input
                                      name="latitude"
                                      id="latitude"
                                      placeholder={props.t("Latitude")}
                                      type="text"
                                      onChange={validationType.handleChange}
                                      onBlur={validationType.handleBlur}
                                      readOnly={
                                        validationType.values.type ===
                                          constants.TYPE.Onsite ||
                                        validationType.values.type ===
                                          constants.TYPE.Hybrid
                                          ? false
                                          : true
                                      }
                                      value={
                                        validationType.values.type ===
                                          constants.TYPE.Onsite ||
                                        validationType.values.type ===
                                          constants.TYPE.Hybrid
                                          ? validationType.values.latitude
                                          : ""
                                      }
                                      invalid={
                                        validationType.touched.latitude &&
                                        validationType.errors.latitude
                                          ? true
                                          : false
                                      }
                                    />
                                    {validationType.touched.latitude &&
                                    validationType.errors.latitude ? (
                                      <FormFeedback type="invalid">
                                        {validationType.errors.latitude}
                                      </FormFeedback>
                                    ) : null}
                                  </div>
                                </Col>
                                <Col sm={6}>
                                  <div className="mb-3">
                                    <Label className="form-label">
                                      {props.t("Longitude")}{" "}
                                      <span className="text-danger">*</span>
                                    </Label>
                                    <Input
                                      name="longitude"
                                      id="longitude"
                                      placeholder={props.t("Longitude")}
                                      type="text"
                                      onChange={validationType.handleChange}
                                      onBlur={validationType.handleBlur}
                                      readOnly={
                                        validationType.values.type ===
                                          constants.TYPE.Onsite ||
                                        validationType.values.type ===
                                          constants.TYPE.Hybrid
                                          ? false
                                          : true
                                      }
                                      value={
                                        validationType.values.type ===
                                          constants.TYPE.Onsite ||
                                        validationType.values.type ===
                                          constants.TYPE.Hybrid
                                          ? validationType.values.longitude
                                          : ""
                                      }
                                      invalid={
                                        validationType.touched.longitude &&
                                        validationType.errors.longitude
                                          ? true
                                          : false
                                      }
                                    />
                                    {validationType.touched.longitude &&
                                    validationType.errors.longitude ? (
                                      <FormFeedback type="invalid">
                                        {validationType.errors.longitude}
                                      </FormFeedback>
                                    ) : null}
                                  </div>
                                </Col>
                              </>
                            )}
                          </Row>
                          <Row>
                            <Col sm={6}>
                              <div className="mb-3">
                                <Label className="form-label">
                                  {props.t("Fee_Type")}{" "}
                                  <span className="text-danger">*</span>
                                </Label>
                                <select
                                  name="fee_type"
                                  id="fee_type"
                                  type="select"
                                  className="form-control"
                                  onChange={validationType.handleChange}
                                  onBlur={validationType.handleBlur}
                                  value={validationType.values.fee_type || ""}
                                >
                                  <option value="">
                                    {props.t("Select_Fee_Type")}
                                  </option>
                                  {Object.keys(constants.FEE_TYPE).map(key => {
                                    return (
                                      <option value={key} key={key}>
                                        {constants.FEE_TYPE[key]}
                                      </option>
                                    )
                                  })}
                                </select>
                                {validationType.touched.fee_type &&
                                validationType.errors.fee_type ? (
                                  <div
                                    style={{
                                      color: "#f46a6a",
                                      fontSize: "11px",
                                      marginTop: "3px",
                                    }}
                                  >
                                    {validationType.errors.fee_type}
                                  </div>
                                ) : null}
                              </div>
                            </Col>
                            <Col sm={6}>
                              <div className="mb-3">
                                <Label className="form-label">
                                  {props.t("Fees")}
                                </Label>
                                <Input
                                  name="fees"
                                  id="fees"
                                  placeholder={props.t("Fees")}
                                  type="text"
                                  readOnly={
                                    validationType.values.fee_type === "PAID"
                                      ? false
                                      : true
                                  }
                                  onChange={validationType.handleChange}
                                  onBlur={validationType.handleBlur}
                                  value={
                                    validationType.values.fee_type == "FREE"
                                      ? ""
                                      : validationType.values.fees
                                  }
                                  invalid={
                                    validationType.touched.fees &&
                                    validationType.errors.fees
                                      ? true
                                      : false
                                  }
                                />
                                {validationType.touched.fees &&
                                validationType.errors.fees ? (
                                  <FormFeedback type="invalid">
                                    {validationType.errors.fees}
                                  </FormFeedback>
                                ) : null}
                              </div>
                            </Col>
                          </Row>

                          <Row>
                            <Col sm={6}>
                              <div className="mb-3">
                                <Label className="form-label">
                                  {props.t("Speaker_Type")}{" "}
                                  <span className="text-danger">*</span>
                                </Label>
                                <select
                                  name="speaker_type"
                                  id="speaker_type"
                                  type="select"
                                  className="form-control"
                                  onChange={e =>
                                    handleChangeSpeakerType(e.target.value)
                                  }
                                  onBlur={validationType.handleBlur}
                                  value={
                                    validationType.values.speaker_type || ""
                                  }
                                >
                                  <option value="">
                                    {props.t("Select_Speaker_Type")}
                                  </option>
                                  {Object.keys(constants.SPEAKER_TYPE).map(
                                    key => {
                                      return (
                                        <option value={key} key={key}>
                                          {constants.SPEAKER_TYPE[key]}
                                        </option>
                                      )
                                    }
                                  )}
                                </select>
                                {validationType.touched.speaker_type &&
                                validationType.errors.speaker_type ? (
                                  <div
                                    style={{
                                      color: "#f46a6a",
                                      fontSize: "11px",
                                      marginTop: "3px",
                                    }}
                                  >
                                    {validationType.errors.speaker_type}
                                  </div>
                                ) : null}
                              </div>
                            </Col>

                            <Col sm={6}>
                              <div className="mb-3">
                                <Label className="form-label">
                                  {props.t("Speaker_User")}{" "}
                                  <span className="text-danger">*</span>
                                </Label>
                                {/* <select
                              name="speaker_user_id"
                              type="select"
                              className="form-control"
                              onChange={validationType.handleChange}
                              onBlur={validationType.handleBlur}
                              value={
                                validationType.values.speaker_user_id || ""
                              }
                            >
                              <option value="">
                                {props.t("Select_Speaker")}
                              </option>
                              {userList.map((item, i) => {
                                return (
                                  <option value={item.id} key={i}>
                                    {item.first_name} {item.last_name}
                                  </option>
                                )
                              })}
                            </select> */}
                                <input
                                  type="text"
                                  className="hide-focus"
                                  id="speaker_user_id"
                                />
                                {activity && doctorsData.length > 0 ? (
                                  <Select
                                    placeholder={
                                      <div>{props.t("typeToSearch")}</div>
                                    }
                                    menuPortalTarget={document.body}
                                    styles={{
                                      menuPortal: base => ({
                                        ...base,
                                        zIndex: 9999,
                                      }),
                                    }}
                                    value={selectedOptionSpeaker}
                                    onBlur={validationType.handleBlur}
                                    name="speaker_user_id"
                                    onChange={e => {
                                      const f = e.map((item, index) => {
                                        return item.value
                                      })
                                      validationType.setFieldValue(
                                        "speaker_user_id",
                                        f
                                      )
                                      setSelectedOptionSpeaker(e)
                                    }}
                                    options={[...doctorsData]}
                                    isMulti={true}
                                    className={
                                      validationType.touched.speaker_user_id &&
                                      validationType.errors.speaker_user_id
                                        ? " border border-danger"
                                        : ""
                                    }
                                    onInputChange={onSearchDoctorHandler}
                                  />
                                ) : null}
                                {validationType.touched.speaker_user_id &&
                                validationType.errors.speaker_user_id ? (
                                  <div
                                    style={{
                                      color: "#f46a6a",
                                      fontSize: "11px",
                                      marginTop: "3px",
                                    }}
                                  >
                                    {validationType.errors.speaker_user_id}
                                  </div>
                                ) : null}
                              </div>
                            </Col>
                          </Row>
                        </>
                      )}
                      <Row>
                        {validationType.values.education_type !==
                        constants.EDUCATION_TYPE.Multimedia ? (
                          <Col sm={6}>
                            <div className="mb-3">
                              <Label className="form-label">
                                {props.t("Images")}
                                {/* <span className="text-danger">*</span> */}
                                <span className="text-danger">
                                  {" "}
                                  ({props.t("Note")} :{" "}
                                  {props.t("Image_File_Extensions")})
                                </span>
                              </Label>
                              <Input
                                name="activity_images"
                                id="activity_images"
                                type="file"
                                multiple
                                className="form-control"
                                onBlur={e => {
                                  if (
                                    e.target.files.length == 0 &&
                                    validationType.values.activity_images &&
                                    validationType.values.activity_images
                                      .length == 0
                                  ) {
                                    validationType.setFieldValue(
                                      "activity_images",
                                      null
                                    )
                                  }
                                }}
                                onChange={e => {
                                  const filesArray2 = []
                                  for (
                                    let i = 0;
                                    i < e.target.files.length;
                                    i++
                                  ) {
                                    filesArray2.push(e.target.files[i])
                                  }
                                  validationType.setFieldValue(
                                    "activity_images",
                                    validationType.values.activity_images
                                      ? [
                                          ...validationType.values
                                            .activity_images,
                                          ...filesArray2,
                                        ]
                                      : filesArray2
                                  )
                                }}
                                invalid={
                                  validationType.touched.activity_images &&
                                  validationType.errors.activity_images
                                    ? true
                                    : false
                                }
                              />
                              <span className="text-danger">
                                {props.t("Size")} ((310 X 310))
                              </span>
                              {validationType.touched.activity_images &&
                              validationType.errors.activity_images ? (
                                <div
                                  style={{
                                    color: "#f46a6a",
                                    fontSize: "11px",
                                    marginTop: "3px",
                                  }}
                                >
                                  {validationType.errors.activity_images}
                                </div>
                              ) : null}
                            </div>
                            <div className="d-flex  px-3 py-2 pb-4">
                              {activityMediaImage?.length > 0
                                ? activityMediaImage?.map((item, i) => {
                                    return (
                                      <Col sm={2} key={i}>
                                        <CardImg
                                          src={item.file_url}
                                          alt="SkoteSkote"
                                          className="rounded avatar-sm"
                                        />
                                        <CardText className="mt-2 mb-lg-0">
                                          {formStatus != "view" && (
                                            <a
                                              className="text-danger"
                                              type="button"
                                              onClick={() =>
                                                removeMedia(
                                                  item.activity_id,
                                                  item.id,
                                                  "IMAGE"
                                                )
                                              }
                                            >
                                              {props.t("Remove")}
                                            </a>
                                          )}
                                        </CardText>
                                      </Col>
                                    )
                                  })
                                : null}

                              {validationType.values.activity_images?.length > 0
                                ? validationType.values.activity_images?.map(
                                    (item, index) => {
                                      return (
                                        <Col sm={2} key={index}>
                                          <CardImg
                                            src={URL.createObjectURL(item)}
                                            alt="SkoteSkote"
                                            className="rounded avatar-sm"
                                          />
                                          <CardText class Name="mt-2 mb-lg-0">
                                            <a
                                              className="text-danger"
                                              type="button"
                                              onClick={() => {
                                                removeImage(item.lastModified)
                                              }}
                                            >
                                              Remove
                                            </a>
                                          </CardText>
                                        </Col>
                                      )
                                    }
                                  )
                                : null}
                            </div>
                          </Col>
                        ) : (
                          <>
                            <Col sm={6}>
                              <div className="mb-3">
                                <Label className="form-label">
                                  {props.t("video")}
                                  <span className="text-danger"> *</span>
                                </Label>
                                <Input
                                  name="videoFile"
                                  id="videoFile"
                                  type="file"
                                  className="form-control"
                                  onChange={e => {
                                    setVideoFile(e.target.files[0])
                                    validationType.setFieldValue(
                                      "videoFile",
                                      e.target.files[0]
                                    )
                                  }}
                                  accept="video/mp4,video/x-m4v,video/*"
                                  invalid={
                                    validationType.touched.videoFile &&
                                    validationType.errors.videoFile
                                      ? true
                                      : false
                                  }
                                />
                                {validationType.touched.videoFile &&
                                validationType.errors.videoFile ? (
                                  <div
                                    style={{
                                      color: "#f46a6a",
                                      fontSize: "11px",
                                      marginTop: "3px",
                                    }}
                                  >
                                    {validationType.errors.videoFile}
                                  </div>
                                ) : null}
                              </div>
                              <div className="d-flex ">
                                {activityMediaImage?.length > 0
                                  ? activityMediaImage?.map((item, i) => {
                                      return (
                                        <Col sm={2} key={i}>
                                          {item.type === "IMAGE" ? (
                                            <CardImg
                                              src={item.file_url}
                                              alt="SkoteSkote"
                                              className="rounded avatar-sm"
                                            />
                                          ) : (
                                            <a
                                              href={item.file_url}
                                              target="_blank"
                                              rel="noreferrer"
                                            >
                                              <video
                                                src={item.file_url}
                                                width={300}
                                                height={300}
                                                autoPlay
                                              ></video>
                                            </a>
                                          )}
                                          <CardText className="mb-lg-0">
                                            <a
                                              className="text-danger"
                                              type="button"
                                              onClick={() =>
                                                removeMedia(
                                                  item.activity_id,
                                                  item.id,
                                                  "VIDEO"
                                                )
                                              }
                                            >
                                              {props.t("Remove")}
                                            </a>
                                          </CardText>
                                        </Col>
                                      )
                                    })
                                  : null}

                                {validationType.values.activity_images?.length >
                                0
                                  ? validationType.values.activity_images?.map(
                                      (item, index) => {
                                        return (
                                          <Col sm={2} key={index}>
                                            <CardImg
                                              src={URL.createObjectURL(item)}
                                              alt="SkoteSkote"
                                              className="rounded avatar-sm"
                                            />
                                            <CardText class Name="mt-2 mb-lg-0">
                                              <a
                                                className="text-danger"
                                                type="button"
                                                onClick={() => {
                                                  removeImage(item.lastModified)
                                                }}
                                              >
                                                Remove
                                              </a>
                                            </CardText>
                                          </Col>
                                        )
                                      }
                                    )
                                  : null}
                              </div>
                            </Col>
                            <Col sm={6}>
                              <div className="mb-3">
                                <Label htmlFor="validationCustom01">
                                  {props.t("thumbnailImage")}
                                  {/* <span className="text-danger">*</span> */}
                                </Label>
                                <span className="text-danger">
                                  {" "}
                                  ({props.t("Note")} :{" "}
                                  {props.t("Image_File_Extensions")})
                                </span>
                                <Input
                                  name="videoThumbnailImage"
                                  id="videoThumbnailImage"
                                  type="file"
                                  className="form-control"
                                  onChange={e => {
                                    setVideoThumbnailFile(e.target.files[0])
                                    validationType.setFieldValue(
                                      "videoThumbnailImage",
                                      e.target.files[0]
                                    )
                                  }}
                                  accept="image/png, image/jpeg"
                                  invalid={
                                    validationType.errors.videoThumbnailImage
                                      ? true
                                      : false
                                  }
                                />
                                <span className="text-danger">
                                  {props.t("Size")} (310 X 310)
                                </span>
                                {validationType.errors.videoThumbnailImage ? (
                                  <div
                                    style={{
                                      color: "#f46a6a",
                                      fontSize: "11px",
                                      marginTop: "3px",
                                    }}
                                  >
                                    {validationType.errors.videoThumbnailImage}
                                  </div>
                                ) : null}
                              </div>
                              {validationType.values.videoThumbnailImage ? (
                                <div className="d-flex  px-3 py-2 pb-4">
                                  <Col sm={6} key="videothumnailimage">
                                    <CardImg
                                      src={URL.createObjectURL(
                                        validationType.values
                                          .videoThumbnailImage
                                      )}
                                      height={250}
                                      width={250}
                                      alt="Activity"
                                    />
                                  </Col>
                                </div>
                              ) : (
                                <div className="d-flex  px-3 py-2 pb-4">
                                  <Col sm={6} key="videothumnailimage">
                                    <CardImg
                                      src={
                                        activity?.activityVideoThumbnail
                                          ?.file_url
                                      }
                                      height={250}
                                      width={250}
                                      alt="Activity"
                                    />
                                  </Col>
                                </div>
                              )}
                            </Col>
                          </>
                        )}

                        {validationType.values.education_type !==
                          constants.EDUCATION_TYPE.Multimedia && (
                          <>
                            <Col sm={6}>
                              <div className="mb-3">
                                <Label className="form-label">
                                  {props.t("Details_Activity_PDF")}{" "}
                                  <span className="text-danger">*</span>
                                </Label>
                                <Input
                                  name="file_url"
                                  id="file_url"
                                  type="file"
                                  className="form-control"
                                  onChange={e => {
                                    setFiles2(e.target.files[0])
                                    validationType.setFieldValue(
                                      "file_url",
                                      e.target.files[0]
                                    )
                                  }}
                                  invalid={
                                    validationType.touched.file_url &&
                                    validationType.errors.file_url
                                      ? true
                                      : false
                                  }
                                />
                                {validationType.touched.file_url &&
                                validationType.errors.file_url ? (
                                  <div
                                    style={{
                                      color: "#f46a6a",
                                      fontSize: "11px",
                                      marginTop: "3px",
                                    }}
                                  >
                                    {validationType.errors.file_url}
                                  </div>
                                ) : null}

                                <CardTitle className="h4 mb-4"></CardTitle>
                                <Row>
                                  {activityMediaFile?.map((item, i) => {
                                    return (
                                      <Col lg={2} key={i}>
                                        <a
                                          href={item.file_url}
                                          className="text-center"
                                        >
                                          <i
                                            className="fas fa-file-pdf"
                                            style={{ fontSize: "32px" }}
                                          />
                                        </a>

                                        <CardText className="mt-2 mb-lg-0">
                                          <a
                                            href="javascript:void()"
                                            onClick={() =>
                                              removeMedia(
                                                item.activity_id,
                                                item.id,
                                                "FILE"
                                              )
                                            }
                                          >
                                            Remove
                                          </a>
                                        </CardText>
                                      </Col>
                                    )
                                  })}
                                </Row>
                              </div>
                            </Col>

                            <Col sm={6}>
                              <div className="mb-3">
                                <Label className="form-label">
                                  {props.t("Website")}{" "}
                                  <span className="text-danger">*</span>
                                </Label>
                                <Input
                                  name="website"
                                  id="website"
                                  placeholder={props.t("Website")}
                                  type="text"
                                  onChange={validationType.handleChange}
                                  onBlur={validationType.handleBlur}
                                  value={validationType.values.website || ""}
                                  invalid={
                                    validationType.touched.website &&
                                    validationType.errors.website
                                      ? true
                                      : false
                                  }
                                />
                                {validationType.touched.website &&
                                validationType.errors.website ? (
                                  <FormFeedback type="invalid">
                                    {validationType.errors.website}
                                  </FormFeedback>
                                ) : null}
                              </div>
                            </Col>

                            <Col sm={6}>
                              <div className="mb-3">
                                <Label className="form-label">
                                  {props.t("Email")}{" "}
                                  <span className="text-danger">*</span>
                                </Label>
                                <Input
                                  name="email"
                                  id="email"
                                  placeholder={props.t("Email")}
                                  type="text"
                                  onChange={validationType.handleChange}
                                  onBlur={validationType.handleBlur}
                                  value={validationType.values.email || ""}
                                  invalid={
                                    validationType.touched.email &&
                                    validationType.errors.email
                                      ? true
                                      : false
                                  }
                                />
                                {validationType.touched.email &&
                                validationType.errors.email ? (
                                  <FormFeedback type="invalid">
                                    {validationType.errors.email}
                                  </FormFeedback>
                                ) : null}
                              </div>
                            </Col>

                            <Col sm={6}>
                              <div className="mb-3">
                                <Label className="form-label">
                                  {props.t("Contact_Number")}{" "}
                                  <span className="text-danger">*</span>
                                </Label>
                                <Input
                                  name="contact_number"
                                  id="contact_number"
                                  placeholder={props.t("Contact_Number")}
                                  type="text"
                                  onChange={validationType.handleChange}
                                  onBlur={validationType.handleBlur}
                                  value={
                                    validationType.values.contact_number || ""
                                  }
                                  invalid={
                                    validationType.touched.contact_number &&
                                    validationType.errors.contact_number
                                      ? true
                                      : false
                                  }
                                />
                                {validationType.touched.contact_number &&
                                validationType.errors.contact_number ? (
                                  <FormFeedback type="invalid">
                                    {validationType.errors.contact_number}
                                  </FormFeedback>
                                ) : null}
                              </div>
                            </Col>

                            <Col sm={6}>
                              <div className="mb-3">
                                <Label className="form-label">
                                  {props.t("Facebook_Link")}{" "}
                                  <span className="text-danger">*</span>
                                </Label>
                                <Input
                                  name="facebook_link"
                                  id="facebook_link"
                                  placeholder={props.t("Facebook_Link")}
                                  type="text"
                                  onChange={validationType.handleChange}
                                  onBlur={validationType.handleBlur}
                                  value={
                                    validationType.values.facebook_link || ""
                                  }
                                  invalid={
                                    validationType.touched.facebook_link &&
                                    validationType.errors.facebook_link
                                      ? true
                                      : false
                                  }
                                />
                                {validationType.touched.facebook_link &&
                                validationType.errors.facebook_link ? (
                                  <FormFeedback type="invalid">
                                    {validationType.errors.facebook_link}
                                  </FormFeedback>
                                ) : null}
                              </div>
                            </Col>

                            <Col sm={6}>
                              <div className="mb-3">
                                <Label className="form-label">
                                  {props.t("Interests")}{" "}
                                  <span className="text-danger">*</span>
                                </Label>
                                <input
                                  type="text"
                                  className="hide-focus"
                                  id="interest_id"
                                />
                                {activity && interestList.length > 0 ? (
                                  <Select
                                    placeholder={props.t("Select_Interest")}
                                    menuPortalTarget={document.body}
                                    styles={{
                                      menuPortal: base => ({
                                        ...base,
                                        zIndex: 9999,
                                      }),
                                    }}
                                    defaultValue={selectedOption}
                                    onChange={e => {
                                      const f = e.map((item, index) => {
                                        return item.value
                                      })
                                      validationType.setFieldValue(
                                        "interest_id",
                                        f
                                      )
                                      setSelectedOption(e)
                                    }}
                                    options={interestList}
                                    isMulti={true}
                                    className={
                                      validationType.touched.interest_id &&
                                      validationType.errors.interest_id
                                        ? " border border-danger"
                                        : ""
                                    }
                                  />
                                ) : null}
                                {validationType.touched.interest_id &&
                                validationType.errors.interest_id ? (
                                  <div
                                    style={{
                                      color: "#f46a6a",
                                      fontSize: "11px",
                                      marginTop: "3px",
                                    }}
                                  >
                                    {validationType.errors.interest_id}
                                  </div>
                                ) : null}
                              </div>
                            </Col>
                          </>
                        )}

                        <Col sm={6}>
                          <div className="mb-3">
                            <Label className="form-label">
                              {props.t("Status")}{" "}
                              <span className="text-danger">*</span>
                            </Label>
                            <select
                              name="status"
                              type="select"
                              className="form-control"
                              //onChange={validationType.handleChange}
                              onChange={e => 
                                changeActivityStatus(e.target.value)
                              }
                              onBlur={validationType.handleBlur}
                              value={validationType.values.status || 0}
                            >
                              {Object.keys(constants.ACTIVITY_STATUS).map(key => {
                                return (
                                  <option value={key} key={key}>
                                    {constants.ACTIVITY_STATUS[key]}
                                  </option>
                                )
                              })}
                            </select>
                            {validationType.touched.status &&
                            validationType.errors.status ? (
                              <div
                                style={{
                                  color: "#f46a6a",
                                  fontSize: "11px",
                                  marginTop: "3px",
                                }}
                              >
                                {validationType.errors.status}
                              </div>
                            ) : null}
                          </div>
                        </Col>
                      </Row>

                      {isSchedulePublish &&
                          <Row>
                          <Col sm={6}>
                                <div className="mb-3">
                                  <Label className="form-label">
                                    {props.t("Activity_Publish_Date")}{" "}
                                    <span className="text-danger">*</span>
                                  </Label>
                                  <Input
                                    name="publish_date"
                                    id="publish_date"
                                    type="date"
                                    locale="th"
                                    onChange={validationType.handleChange}
                                    onBlur={validationType.handleBlur}
                                    min={moment().format("Y-M-D")}
                                    value={
                                      validationType.values.publish_date || ""
                                    }
                                    invalid={
                                      validationType.touched.publish_date &&
                                      validationType.errors.publish_date
                                        ? true
                                        : false
                                    }
                                  />
                                  {validationType.touched.publish_date &&
                                  validationType.errors.publish_date ? (
                                    <FormFeedback type="invalid">
                                      {validationType.errors.publish_date}
                                    </FormFeedback>
                                  ) : null}
                                </div>
                              </Col>
                              

                              <Col sm={6}>
                                  <div className="mb-3">
                                    <Label className="form-label">
                                      {props.t("Publish_Time")}{" "}
                                      <span className="text-danger">*</span>
                                    </Label>
                                  
                                    <Input
                                      name="publish_time"
                                      id="publish_time"
                                      type="time"
                                      onChange={validationType.handleChange}
                                      onBlur={validationType.handleBlur}
                                      value={validationType.values.publish_time || ""}
                                      invalid={
                                        validationType.touched.publish_time &&
                                        validationType.errors.publish_time
                                          ? true
                                          : false
                                      }
                                    />
                                    {validationType.touched.publish_time &&
                                    validationType.errors.publish_time ? (
                                      <FormFeedback type="invalid">
                                        {validationType.errors.publish_time}
                                      </FormFeedback>
                                    ) : null}
                                  </div>
                                </Col>

                          </Row>
                      }




                      <Row>
                        <Col sm={12}>
                          <div className="mb-3">
                            <Label className="form-label">
                              {props.t("Description_Th")}{" "}
                              <span className="text-danger">*</span>
                            </Label>
                            <input
                              type="text"
                              className="hide-focus"
                              id="description_th"
                            />
                            <Editor
                              name="description_th"
                              editorState={contentTh}
                              onEditorStateChange={state => setContentTh(state)}
                              toolbarClassName="toolbarClassName"
                              wrapperClassName="wrapperClassName"
                              editorClassName="editorClassName"
                            />

                            {validationType.touched.description_th &&
                            validationType.errors.description_th ? (
                              <div
                                style={{
                                  color: "#f46a6a",
                                  fontSize: "11px",
                                  marginTop: "3px",
                                }}
                              >
                                {validationType.errors.description_th}
                              </div>
                            ) : null}
                          </div>
                        </Col>

                        <Col sm={12}>
                          <div className="mb-3">
                            <Label className="form-label">
                              {props.t("Description_En")}
                              {/* <span className="text-danger">*</span> */}
                            </Label>
                            <Editor
                              name="description_en"
                              editorState={contentEn}
                              onEditorStateChange={state => setContentEn(state)}
                              toolbarClassName="toolbarClassName"
                              wrapperClassName="wrapperClassName"
                              editorClassName="editorClassName"
                            />

                            {validationType.touched.description_en &&
                            validationType.errors.description_en ? (
                              <div
                                style={{
                                  color: "#f46a6a",
                                  fontSize: "11px",
                                  marginTop: "3px",
                                }}
                              >
                                {validationType.errors.description_en}
                              </div>
                            ) : null}
                          </div>
                        </Col>
                      </Row>

                      <div className="d-flex flex-wrap gap-2">
                        {formStatus != "view" &&
                          (isSubmit ? (
                            <Button type="submit" disabled color="primary">
                              {props.t("Submit")}{" "}
                            </Button>
                          ) : (
                            <Button type="submit" color="primary">
                              {props.t("Submit")}{" "}
                            </Button>
                          ))}

                        <Button color="secondary" onClick={e => formClear()}>
                          {props.t("Clear")}
                        </Button>
                      </div>
                    </Form>
                  </Col>
                </CardBody>
              </Card>
            </Col>
          </Row>
          {activityReports.length > 0 && (
            <Row>
              <Col lg={12}>
                <Card>
                  <CardBody>
                    <Col sm={12}>
                      <Row>
                        <Col xs="12">
                          <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                            <h4 className="mb-0 font-size-18">
                              {props.t("activityReport")}
                            </h4>
                          </div>
                        </Col>
                        <DataTable
                          className="table-bordered"
                          progressPending={loading}
                          columns={columns}
                          data={activityReports}
                          pagination
                          paginationServer
                          paginationTotalRows={activeData.totalPage}
                          paginationPerPage={activeData.limit}
                          defaultSortFieldID={1}
                          // onChangeRowsPerPage={value => handleRowChange(value)}
                          // onChangePage={value => handleChange(value)}
                          sortable
                          noHeader
                          defaultSortField="id"
                          defaultSortAsc={false}
                          highlightOnHover
                        />
                      </Row>
                    </Col>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          )}
        </Container>
      </div>
    </React.Fragment>
  )
}

AddOffer.propTypes = {
  t: PropTypes.any,
}

export default withTranslation()(AddOffer)
