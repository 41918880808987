import React, { useEffect, useState } from "react";
import {
    Row,
    Col,
    Card,
    CardBody,
    FormGroup,
    Button,
    CardTitle,
    CardSubtitle,
    Label,
    Input,
    Container,
    FormFeedback,
    Form,
} from "reactstrap";
// Formik validation
import * as Yup from "yup";
import { useFormik } from "formik";


import toastr from "toastr";
import "toastr/build/toastr.min.css";
import PropTypes from 'prop-types';
// import { RoleApi } from "../../apis/RoleApi";
// import { RoleModuleApi } from "../../apis/RoleModuleApi";

import { useHistory } from "react-router-dom"
//Import Breadcrumb
// import Breadcrumbs from "../../components/Common/Breadcrumb";
import Swal from "sweetalert2";
import { withTranslation } from "react-i18next";
// import i18n from "../../i18n";
// import constants from "../../config/constants";
import config from "config/config";

import { OfferTypeApi } from "apis/OfferTypeApi";
import { OfferApi } from "apis/OfferApi";
import moment from "moment";
import { CompaniesApi } from "apis/CompaniesApi";
import Breadcrumb from "components/Common/Breadcrumb";
import constants from "../../../config/constants";



const ManageCompanyTemplate = props => {
    const company_id = props.match.params.company_id;
    const [files1, setFiles1] = useState([]);
    const [company, setCompany] = useState('')

    const history = useHistory();
    const [offerTypeList, setOfferTypeList] = useState([]);
    const [loading, setLoading] = useState(false);

    const [activeData, setActiveData] = useState({
        page: 1,
        totalPage: 1,
        search: "",
        limit: config.LIMIT
    });




    const getCompanyById = () => {
        // console.log(company_id)
        CompaniesApi.getCompanyById({company_id})
          .then(res => {
            // console.log('rolessss',res.data);
            setCompany(res.data.data)
          }).catch(err => {
            console.log(err)
          })
      }
      

    useEffect(() => {
        getCompanyById();
        // getAllOfferTypes(activeData);
    }, []);

    // Form validation 
    const validationType = useFormik({
        // enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: true,

        initialValues: {
            name_en: company.name_en  ??'',
            name_th: company.name_th ??'',
            email: company.email ??'',
            phone_no: company.phone_no ??'',
            is_microsite : company.is_microsite ?? '',

        },
        validationSchema: Yup.object().shape({
            name_en: Yup.string().required(
                props.t("Required-Message")
            ),
            name_th: Yup.string().required(
                props.t("Required-Message")
            ),
            email: Yup.string().email().required(
                props.t("Required-Message")
            ),
            phone_no: Yup.number().required(
                props.t("Required-Message")
            ), 
            is_microsite: Yup.number().required(
                props.t("Required-Message")
            ),            
        }),
        onSubmit: (values) => {
       const company = {

        company_id : company_id,
        ...values
       }
            CompaniesApi.updateCompany(company)
                    .then(res => {
                        if (res.data.success) {
                            Swal.fire({
                                text: res.data.message,
                                icon: 'success',
                                imageAlt: 'success image',
                            }).then((result) => {
                                if (result?.isConfirmed) {
                                    history.push('/companies');
                                    formClear()
                                    setFiles1()
                                }
                            });
                        } else {
                            toastr.error(res?.data?.message, '');
                        }
                    }).catch(function (error) {

                        if (error?.response?.data?.message) {
                            toastr.error(error?.response?.data?.message, '');
                        }
                        else {
                            toastr.error(error?.response?.data?.error?.errors?.map((item, v) => `${item.msg}<br>`), '');
                        }
                    });
            

        }
    });
    

    const formClear = () => {
        validationType.resetForm();
        setFiles1([])
    };



    document.title = props.t("Companies") + ' | ' + props.t("MedX");

    return (
        <React.Fragment>
            <div className="page-content">
                <Breadcrumb title={props.t("Company")} breadcrumbItem={props.t("Manage_Template")} />
                <Container fluid={true}>
                
                </Container>
            </div>
        </React.Fragment>
    );
};


ManageCompanyTemplate.propTypes = {
    t: PropTypes.any,
};

export default withTranslation()(ManageCompanyTemplate);