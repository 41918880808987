// src/components/filter.
import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"

//import components
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import DataTable from "react-data-table-component"
import { InstitutionApi } from "apis/InstitutionApi"
import { EvalutionFormApi } from "apis/EvalutionFormApi"
import { Link, useHistory } from "react-router-dom"
import { withTranslation } from "react-i18next"
import * as Yup from "yup"
import { useFormik } from "formik"
import toastr from "toastr"
import config from "config/config"
import constants from "../../../config/constants"
import { getRoleID } from "Token"

import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Form,
  Label,
  Input,
  FormFeedback,
  Button,
} from "reactstrap"

import Select from "react-select"

import Swal from "sweetalert2"
import { debounce } from "lodash"

const EvalutionForm = props => {
  const history = useHistory()
  const [EvalutionFormsList, setEvalutionFormsList] = useState([])
  const [fetch_evalution_form, setFetchEvalutionForm] = useState([])
  const [post_evalution_form_id, setPostEvalutionFormId] = useState("")
  const [institutionList, setInstitutionList] = useState([])
  const [defualtInstitute, setDefualtInstitute] = useState(null)
  const [institutesData, setinstitutesData] = useState([])
  const [institutesPaginateData, setinstitutesPaginateData] = React.useState({
    page: 1,
    limit: 20,
    totalRecords: 1000,
    search: "",
  });
  const [page, setCurrentPage] = useState(1);

  const [isMoreInstitutes, setIsMoreInstitutes] = useState(true)
  const [isInstituteLoading, setIsInstituteLoading] = useState(true)

  const [loading, setLoading] = useState(false)
  let customValidation = {}

  if (getRoleID() === constants.ROLE.SUPERADMIN) {
    customValidation.institute_id = Yup.number().required(
      props.t("Required-Message")
    )
  }

  const [activeData, setActiveData] = useState({
    page: 1,
    totalPage: 1,
    search: "",
    limit: config.LIMIT,
  })

  useEffect(() => {
    getAllEvalutionForms(activeData)
  }, [page])

  const formClear = () => {
    validationType.resetForm()
    setFetchEvalutionForm()
  }

  const columns = [
    {
      name: props.t("Sr_No"),
      selector: row => row.evalution_form_id,
      sort: "asc",
      width: 150,
      sortable: true,
      defaultSortField: true,
      defaultSortAsc: false,
    },
    {
      name: props.t("Name_Eng"),
      selector: row => row.name_en,
      sort: "asc",
      width: 270,
      sortable: true,
      defaultSortField: true,
      defaultSortAsc: false,
    },
    {
      name: props.t("Name_Thai"),
      selector: row => row.name_th,
      sort: "asc",
      width: 270,
      sortable: true,
      defaultSortField: true,
      defaultSortAsc: false,
    },
    {
      name: props.t("Status"),
      selector: row => row.status,
      sort: "asc",
      width: 270,
      sortable: true,
      defaultSortField: true,
      defaultSortAsc: false,
    },
    {
      name: props.t("Action"),
      selector: row => row.action,
      sort: "asc",
      width: 200,
    },
  ]

  const getAllInstitutesPaginate = (searchKeyword = "") => {
    if (searchKeyword !== "") {
      institutesPaginateData.search = searchKeyword
    }else{
      institutesPaginateData.search = ''
    }
    InstitutionApi.getAllInstitutesApi(institutesPaginateData)
      .then(res => {
        if (res.data.success) {
          //   const pageNo = paginationData.page
          //   setinstitutesPaginateData({
          //     ...paginationData,
          //     totalRecords: res.data.data.count,
          //     page: res.data.data.metadata.page,
          //     limit: res.data.data.metadata.limit,
          //   })
          setinstitutesData([...res.data.data.rows])
          //   if (pageNo === 1) {
          //     setinstitutesData([...res.data.data.rows])
          //   } else {
          //     if (res.data.data.rows.length === 0) {
          //       setIsMoreInstitutes(false)
          //       setIsInstituteLoading(false)
          //     }
          //     setinstitutesData([...institutesData, ...res.data.data.rows])
          //   }
        } else {
          setinstitutesData([])
        }
      })
      .catch(err => {
        setinstitutesData([])
      })
  }

  const onOpenInstituteMenuHandler = () => {
    getAllInstitutesPaginate()
  }

  const onSearchInstitutesHandler = debounce(function (searchInput) {
    if (searchInput.length >= 2) getAllInstitutesPaginate(searchInput)
  }, 500)

  const getAllEvalutionForms = data => {
    setLoading(true)
    EvalutionFormApi.getAllEvalutionForms(data)
      .then(res => {
        setActiveData({
          page: activeData.page,
          totalPage: res?.data?.data?.count,
          search: activeData.search,
          limit: activeData.limit,
        })
        let data = []
        var sr_no = res.data.sr_no_start

        res?.data?.data?.rows?.forEach((v, i) => {
          sr_no = sr_no + 1
          data[i] = {
            evalution_form_id: sr_no,
            name_en: v.name_en,
            name_th: v.name_th,
            status:
              v.status == 1 ? (
                <>
                  <span style={{ color: "green" }}>{props.t("Active")}</span>
                </>
              ) : (
                <>
                  <span style={{ color: "red" }}>{props.t("Deactive")}</span>
                </>
              ),
            action: (
              <>
                <Link to={`/view-evalution-form/${v.id}`}>
                  <i
                    className="fas fa-eye"
                    style={{ color: "grey", marginRight: "10px" }}
                  />
                </Link>
                <a href="#" onClick={() => onEdit(v.id)}>
                  <i
                    className="fas fa-edit"
                    id="deletetooltip"
                    style={{ color: "blue", marginRight: "3px" }}
                  />{" "}
                </a>
                <a href="#" onClick={() => onDelete(v.id)}>
                  <i
                    className="mdi mdi-delete font-size-16"
                    style={{ color: "red" }}
                    id="deletetooltip"
                  ></i>
                </a>
              </>
            ),
          }
        })
        setEvalutionFormsList(data)
        setLoading(false)
      })
      .catch(err => {
        console.log(err)
      })
  }

  const onDelete = id => {
    Swal.fire({
      title: props.t("Are_you_sure"),
      text: props.t("Able_to_revert_this"),
      icon: props.t("Warning"),
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#f46a6a",
      confirmButtonText: props.t("Yes_delete_it"),
      cancelButtonText: props.t("Cancel"),
    }).then(result => {
      if (result.isConfirmed) {
        let eValutionformId = { evalution_form_id: id }
        EvalutionFormApi.deleteEvalutionForm(eValutionformId)
          .then(res => {
            Swal.fire(props.t("Deleted"), res.data.message, props.t("Success"))
            setPostEvalutionFormId()
            formClear();
            handleChange(page);
            //getAllEvalutionForms(activeData)
          })
          .catch(err => {
            console.log(err)
          })
      }
    })
  }

  const onEdit = id => {
    const payload = { evalution_form_id: id }
    setPostEvalutionFormId(id)

    EvalutionFormApi.getEvalutionFormById(payload)
      .then(res => {
        setDefualtInstitute({
          value: res.data.data?.EvalutionInstitute.id,
          label: res.data.data.EvalutionInstitute.institution_name,
        })
        setFetchEvalutionForm(res.data.data)
      })
      .catch(err => {
        console.log(err)
      })
  }
  // Form validation
  const validationType = useFormik({
    enableReinitialize: true,
    initialValues: {
      evalution_form_id: fetch_evalution_form ? fetch_evalution_form.id : "",
      name_en: fetch_evalution_form ? fetch_evalution_form.name_en : "",
      name_th: fetch_evalution_form ? fetch_evalution_form.name_th : "",
      description_en: fetch_evalution_form
        ? fetch_evalution_form.description_en
        : "",
      description_th: fetch_evalution_form
        ? fetch_evalution_form.description_th
        : "",
      institute_id: fetch_evalution_form
        ? fetch_evalution_form.institute_id
        : "",
      status:
        fetch_evalution_form?.status === 1
          ? "1"
          : fetch_evalution_form?.status === 0
          ? "0"
          : "1",
    },
    validationSchema: Yup.object().shape({
      name_en: Yup.string().required(props.t("Required-Message")),
      name_th: Yup.string().required(props.t("Required-Message")),
      description_en: Yup.string().required(props.t("Required-Message")),
      description_th: Yup.string().required(props.t("Required-Message")),
      status: Yup.string().required(props.t("Required-Message")),
      ...customValidation,
    }),
    onSubmit: values => {
      if (post_evalution_form_id) {
        values.evalution_form_id = post_evalution_form_id
        EvalutionFormApi.updateEvalutionForm(values)
          .then(res => {
            if (res.data.success) {
              Swal.fire({
                text: res.data.message,
                icon: "success",
                imageAlt: "success image",
              }).then(result => {
                if (result.isConfirmed) {
                  history.push("/evalution-forms")
                  setPostEvalutionFormId()
                  formClear()
                  getAllEvalutionForms(activeData)
                }
              })
            } else {
              toastr.error(res.message, "")
            }
          })
          .catch(function (error) {
            if (error?.response?.data?.message) {
              toastr.error(error?.response?.data?.message, "")
            } else {
              toastr.error(
                error?.response?.data?.error?.errors?.map(
                  (item, v) => `${item.msg}<br>`
                ),
                ""
              )
            }
          })
      } else {
        EvalutionFormApi.createEvalutionForm(values)
          .then(res => {
            if (res.data.success) {
              Swal.fire({
                text: res.data.message,
                icon: "success",
                imageAlt: "success image",
              }).then(result => {
                if (result?.isConfirmed) {
                  history.push("/evalution-forms")
                  formClear()
                  getAllEvalutionForms(activeData)
                }
              })
            } else {
              toastr.error(res?.data?.message, "")
            }
          })
          .catch(function (error) {
            if (error?.response?.data?.message) {
              toastr.error(error?.response?.data?.message, "")
            } else {
              toastr.error(
                error?.response?.data?.error?.errors?.map(
                  (item, v) => `${item.msg}<br>`
                ),
                ""
              )
            }
          })
      }
    },
  })

  const handleChange = v => {
    setCurrentPage(v);
    setActiveData({
      page: v,
      totalPage: activeData.totalPage,
      search: activeData.search,
      limit: activeData.limit,
    })
    const data = {
      page: v,
      totalPage: activeData.totalPage,
      search: activeData.search,
      limit: activeData.limit,
    }
    getAllEvalutionForms(data)
  }

  const handleRowChange = v => {
    setActiveData({
      page: activeData.page,
      totalPage: activeData.totalPage,
      search: activeData.search,
      limit: v,
    })
    const data = {
      page: activeData.page,
      totalPage: activeData.totalPage,
      search: activeData.search,
      limit: v,
    }
    getAllEvalutionForms(data)
  }

  //meta title
  document.title = props.t("Evalution Form") + " | " + props.t("MedX")

  return (
    <div className="page-content">
      <div className="container-fluid">
        <Breadcrumbs
          title={props.t("Master")}
          breadcrumbItem={props.t("Evalution Form")}
        />
      </div>
      <Container fluid={false}>
        <Row>
          <Col lg={8}>
            <Card>
              <CardBody>
                <Col sm={12}>
                  <DataTable
                    className="table-bordered"
                    progressPending={loading}
                    columns={columns}
                    data={EvalutionFormsList}
                    pagination
                    paginationServer
                    paginationTotalRows={activeData.totalPage}
                    paginationPerPage={activeData.limit}
                    defaultSortFieldID={1}
                    onChangeRowsPerPage={value => handleRowChange(value)}
                    onChangePage={value => handleChange(value)}
                    sortable
                    noHeader
                    defaultSortField="id"
                    defaultSortAsc={false}
                    highlightOnHover
                  />
                </Col>
              </CardBody>
            </Card>
          </Col>
          <Col lg={4}>
            <Card>
              <CardBody>
                <Col sm={12}>
                  <Form
                    onSubmit={e => {
                      e.preventDefault()
                      validationType.handleSubmit()
                      return false
                    }}
                  >
                    {getRoleID() === constants.ROLE.SUPERADMIN && (
                      <>
                        <Col sm={12}>
                          <div className="mb-3">
                            <Label className="form-label">
                              {props.t("Institute")}{" "}
                              <span className="text-danger">*</span>
                            </Label>
                            <Select
                              className={
                                validationType.touched.institute_id &&
                                validationType.errors.institute_id
                                  ? "bor "
                                  : "text-dark"
                              }
                              styles={{
                                control: styles => ({
                                  ...styles,
                                }),
                              }}
                              onChange={e => {
                                validationType.setFieldValue(
                                  "institute_id",
                                  e.value
                                )
                                setDefualtInstitute(e)
                              }}
                              value={defualtInstitute}
                              //   onMenuScrollToBottom={
                              //     onScrollInstituesDataHandler
                              //   }
                              onInputChange={onSearchInstitutesHandler}
                              onMenuOpen={onOpenInstituteMenuHandler}
                              defaultValue={validationType.values.institute_id}
                              onBlur={validationType.handleBlur}
                              name="institute_id"
                              options={[...institutesData]}
                              placeholder={<div>{props.t("typeToSearch")}</div>}
                            />
                            {validationType.touched.institute_id &&
                            validationType.errors.institute_id ? (
                              <div
                                style={{
                                  color: "#f46a6a",
                                  fontSize: "11px",
                                  marginTop: "3px",
                                }}
                              >
                                {validationType.errors.institute_id}
                              </div>
                            ) : null}
                          </div>
                        </Col>
                      </>
                    )}
                    <Col sm={12}>
                      <div className="mb-3">
                        <Label className="form-label">
                          {props.t("Name_Eng")}
                        </Label>
                        <Input
                          name="name_en"
                          placeholder={props.t("Name_Eng")}
                          type="text"
                          onChange={validationType.handleChange}
                          onBlur={validationType.handleBlur}
                          value={validationType.values.name_en || ""}
                          invalid={
                            validationType.touched.name_en &&
                            validationType.errors.name_en
                              ? true
                              : false
                          }
                        />
                        {validationType.touched.name_en &&
                        validationType.errors.name_en ? (
                          <FormFeedback type="invalid">
                            {validationType.errors.name_en}
                          </FormFeedback>
                        ) : null}
                      </div>
                    </Col>
                    <Col sm={12}>
                      <div className="mb-3">
                        <Label className="form-label">
                          {props.t("Name_Thai")}
                        </Label>
                        <Input
                          name="name_th"
                          placeholder={props.t("Name_Thai")}
                          type="text"
                          onChange={validationType.handleChange}
                          onBlur={validationType.handleBlur}
                          value={validationType.values.name_th || ""}
                          invalid={
                            validationType.touched.name_th &&
                            validationType.errors.name_th
                              ? true
                              : false
                          }
                        />
                        {validationType.touched.name_th &&
                        validationType.errors.name_th ? (
                          <FormFeedback type="invalid">
                            {validationType.errors.name_th}
                          </FormFeedback>
                        ) : null}
                      </div>
                    </Col>
                    <Col sm={12}>
                      <div className="mb-3">
                        <Label className="form-label">
                          {props.t("Description_En")}{" "}
                          <span className="text-danger">*</span>
                        </Label>
                        <Input
                          name="description_en"
                          placeholder={props.t("Description_En")}
                          type="textarea"
                          onChange={validationType.handleChange}
                          onBlur={validationType.handleBlur}
                          value={validationType.values.description_en || ""}
                          invalid={
                            validationType.touched.description_en &&
                            validationType.errors.description_en
                              ? true
                              : false
                          }
                          autoComplete="off"
                        />
                        {validationType.touched.description_en &&
                        validationType.errors.description_en ? (
                          <FormFeedback type="invalid">
                            {validationType.errors.description_en}
                          </FormFeedback>
                        ) : null}
                      </div>
                    </Col>
                    <Col sm={12}>
                      <div className="mb-3">
                        <Label className="form-label">
                          {props.t("Description_Th")}{" "}
                          <span className="text-danger">*</span>
                        </Label>
                        <Input
                          name="description_th"
                          placeholder={props.t("Description_Th")}
                          type="textarea"
                          onChange={validationType.handleChange}
                          onBlur={validationType.handleBlur}
                          value={validationType.values.description_th || ""}
                          invalid={
                            validationType.touched.description_th &&
                            validationType.errors.description_th
                              ? true
                              : false
                          }
                          autoComplete="off"
                        />
                        {validationType.touched.description_th &&
                        validationType.errors.description_th ? (
                          <FormFeedback type="invalid">
                            {validationType.errors.description_th}
                          </FormFeedback>
                        ) : null}
                      </div>
                    </Col>

                    <Col sm={12}>
                      <div className="mb-3">
                        <Label className="form-label">
                          {props.t("Status")}
                        </Label>

                        <select
                          name="status"
                          type="select"
                          className="form-control"
                          onChange={validationType.handleChange}
                          onBlur={validationType.handleBlur}
                          value={validationType.values.status || ""}
                        >
                          {Object.keys(constants.STATUS).map(key => {
                            return (
                              <option value={key} key={key}>
                                {constants.STATUS[key]}
                              </option>
                            )
                          })}
                        </select>
                        {validationType.touched.status &&
                        validationType.errors.status ? (
                          <div
                            style={{
                              color: "#f46a6a",
                              fontSize: "11px",
                              marginTop: "3px",
                            }}
                          >
                            {validationType.errors.status}
                          </div>
                        ) : null}
                      </div>
                    </Col>

                    <div className="d-flex flex-wrap gap-2">
                      <Button type="submit" color="primary">
                        {props.t("Submit")}
                      </Button>{" "}
                      <Button color="secondary" onClick={e => formClear()}>
                        {props.t("Clear")}
                      </Button>
                    </div>
                  </Form>
                </Col>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  )
}
// EvalutionForm.propTypes = {
//   t: PropTypes.any,
//   preGlobalFilteredRows: PropTypes.any,
// }
export default withTranslation()(EvalutionForm)
