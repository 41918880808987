import React, { useMemo, useEffect, useState } from "react"

import {
  Form,
  Card,
  CardBody,
  Col,
  Row,
  Label,
  Input,
  FormFeedback,
  Button,
  Container,
} from "reactstrap"

// Form Editor
import { Editor } from "react-draft-wysiwyg"
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css"
import { convertToHTML } from "draft-convert"
import { EditorState, ContentState, convertFromHTML } from "draft-js"

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import { withTranslation } from "react-i18next"
import { UserApi } from "apis/UserApi"
import { InterestApi } from "apis/InterestApi"
import * as Yup from "yup"
import { useFormik } from "formik"
import toastr from "toastr"
import Swal from "sweetalert2"
import { useHistory } from "react-router-dom"
import Select from "react-select"
import config from "config/config"

const EditDoctor = props => {
  const history = useHistory();

  const [loading, setLoading] = useState(false);
  const [specialtiestList, setSpecialtiesList] = useState([])
  const [interests, setInterests] = useState([]);
  const [selectedOption, setSelectedOption] = useState()
  const [userInterests, setUserInterests] = useState([]);
  const user_id = props.match.params.user_id;
  const [doctor, setDoctor] = useState('');
  const [buttonDisable, setButtonDisable] = useState(false);

  //const [userInt, setUserInt] = useState([]);

  const [activeData, setActiveData] = useState({
    page: 1,
    limit:100
  })

  useEffect(() => {
    getDoctorById()
  }, []);

  const getDoctorById = () => {
    UserApi.getDoctorById(user_id)
      .then(res => {
        
        const xy = res.data.data.UserSpecialties;
        // const yz = xy.map((item) => {
        //   return { value: item.Specialties.id, label: item.Specialties.name_en }
        // })

        if(xy) {
          const yz = { value: xy.Specialties.id, label: xy.Specialties.name_en };
          setSelectedOption({
            value:  xy.Specialties.id,
            label: xy.Specialties.name_en,
          })
         }

         let userIntArr = [];
         if(res.data.data.UserInterest.length > 0) {
            res.data.data.UserInterest !== undefined &&
            res.data.data.UserInterest.forEach((element, i) => {
                userIntArr.push(element.interest_id);
              });
            var uniq = [...new Set(userIntArr)];
            setUserInterests(uniq);
        } 

        setDoctor(res.data.data);

      }).catch(err => {
        console.log(err)
      })
  }

  const getAllCommunityTypes = data => {
    setLoading(true)
    InterestApi.getAllInterests(data)
      .then(res => {
        setActiveData({
          page: activeData.page,
          totalPage: res?.data?.data?.count,
          search: activeData.search,
          limit: activeData.limit,
        })
        setInterests(res.data.data?.rows);
        const x = res?.data?.data?.rows
        const y = x?.map((item, index) => {
          return {
            label: item.name_en,
            value: item.id,
          };

        })
        // console.log(y)
        setSpecialtiesList(y)
        setLoading(false)
      })
      .catch(err => {
        console.log(err)
      })
  }

  useEffect(() => {
    getAllCommunityTypes(activeData)
  }, [])


  const onInterestHandler = async (e, interestID) => {
    const userInterestsCopy = _.cloneDeep(userInterests);
    if (e.target.checked) {
      const interestIndex = userInterestsCopy.indexOf(interestID);
      userInterestsCopy.splice(interestIndex, 1);
    } else {
      userInterestsCopy.push(interestID);
    }
    var uniq = [...new Set(userInterestsCopy)];
    setUserInterests(uniq);
  };

  //meta title
  document.title = "Edit Doctor"
  const validationType = useFormik({
    enableReinitialize: true,
    initialValues: {
      first_name: doctor.first_name ? doctor.first_name : "",
      last_name: doctor.last_name ? doctor.last_name : "",
      license_number: doctor.license_number ? doctor.license_number : "",
      unique_id: doctor.unique_id ? doctor.unique_id : "",
      email: doctor.email ? doctor.email : "",
      mobile: doctor.mobile ? doctor.mobile : "",
      specialist_list: doctor?.UserSpecialties?.interest_id,
      //interest_list: userInterests ? userInterests : [], 
      request_doctor_id: doctor.request_doctor_id ? doctor.request_doctor_id : 0,
    },
    validationSchema: Yup.object().shape({
      first_name: Yup.string().required(props.t("Required-Message")),
      last_name: Yup.string().required(props.t("Required-Message")),
      license_number: Yup.string().required(props.t("Required-Message")).matches(/^[0-9\b]+$/, props.t("Only-Number-Message")),
      // unique_id: Yup.string()
      //           .required(props.t("Required-Message"))
      //           .matches(/^[0-9]+$/, props.t("Only-Number-Message"))
      //           .min(13, 'Must be exactly 13 digits')
      //           .max(13, 'Must be exactly 13 digits'),
      email: Yup.string()
      .email(props.t("Valid-Email-Message"))
      .required(props.t("Required-Message")),
      mobile: Yup.string()
          .matches(/^[0-9\b]+$/, props.t("Only-Number-Message"))
          .min(8, props.t("Contact-Number-Message"))
          .max(10, props.t("Contact-Number-Message"))
          .required(props.t("Required-Message")),
      specialist_list:  Yup.string().required(props.t("Required-Message")),
      //interest_list:  Yup.array().min(3, props.t("select_interest_any_three")).max(3, props.t("select_interest_any_three")).required(props.t("Required-Message")),

      // interest_list: Yup.string().when("interestCheck", (val, schema) => {
      //     if (val?.length !== 3) {
      //       return Yup.array().min(3, props.t("select_interest_any_three")).max(3, props.t("select_interest_any_three")).required(props.t("Required-Message"))
      //     } else {
      //       return Yup.array().notRequired()
      //     }
      //   }),
    },),
    onSubmit: values => {
      // if(userInterests.length != 3) {
      //   validationType.setErrors({ interest_list: `${props.t("select_interest_any_three")}` });
      //   return false;
      // }
      setButtonDisable(true);

      const data = new FormData()
      data.append("first_name", values.first_name);
      data.append("last_name", values.last_name);
      data.append("license_number", values.license_number);
      data.append("email", values.email);
      data.append("unique_id", values.unique_id);
      data.append("mobile", values.mobile);
      data.append("user_id", user_id);
      data.append("request_doctor_id", values.request_doctor_id);
      for (let i = 0; i < userInterests.length; i++) {
        data.append("interests", userInterests[i]);
      }
      
      // for (let i = 0; i < values.specialist_list.length; i++) {
      //   data.append("specialities", values.specialist_list[i]);
      // }
      data.append("specialities", values.specialist_list);

      UserApi.updateDoctor(data)
        .then(res => {
          setButtonDisable(false);
          if (res.data.success) {
            Swal.fire({
              text: res.data.message,
              icon: "success",
              imageAlt: "success image",
            }).then(result => {
              if (result.isConfirmed) {
                history.push("/doctors")
              }
            })
          } else {
            toastr.error(res.message, "")
          }
        })
        .catch(function (err) {
          setButtonDisable(false);
          if (err?.response?.data?.message) {
              //toastr.error(error?.response?.data?.message, "")
            } else {
              err.response.data.error.errors?.map((erritem, errindex) => {
                if (erritem.param == "license_number") {
                  validationType.setErrors({ license_number: `${erritem.msg}` });
                }
                if (erritem.param == "unique_id") {
                  validationType.setErrors({ unique_id: `${erritem.msg}` });
                }
                if (erritem.param == "email") {
                  validationType.setErrors({ email: `${erritem.msg}` });
                }
                if (erritem.param == "mobile") {
                  validationType.setErrors({ mobile: `${erritem.msg}` });
                }
              });
            }
          })
      },
  })

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs
            title={props.t("User")}
            breadcrumbItem={props.t("Edit") + " " + props.t("Doctor")}
          />
          <Row>
            <Col className="col-12">
              <Button
                style={{ float: "right", marginBottom: "20px" }}
                color="primary"
                className="waves-effect waves-light  pull-right"
                onClick={() => history.goBack()}
              >
                {props.t("Back")}
              </Button>
            </Col>
            <Col>
              <Card>
                <CardBody>
                  <Form
                    method="post"
                    className="wysiwyg-custom"
                    onSubmit={e => {
                      e.preventDefault()
                      validationType.handleSubmit()
                      return false
                    }}
                  >
                     <Row>
                    <Col sm={6}>
                      <div className="mb-3">
                        <Label className="form-label">
                          {props.t("First Name")}{" "}
                          <span className="text-danger">*</span>
                        </Label>
                        <Input
                          name="first_name"
                          placeholder={props.t("First Name")}
                          type="text"
                          onChange={validationType.handleChange}
                          onBlur={validationType.handleBlur}
                          value={validationType.values.first_name || ""}
                          invalid={
                            validationType.touched.first_name &&
                            validationType.errors.first_name
                              ? true
                              : false
                          }
                        />
                        {validationType.touched.first_name &&
                        validationType.errors.first_name ? (
                          <FormFeedback type="invalid">
                            {validationType.errors.first_name}
                          </FormFeedback>
                        ) : null}
                      </div>
                    </Col>

                    <Col sm={6}>
                      <div className="mb-3">
                        <Label className="form-label">
                          {props.t("Last Name")}{" "}
                          <span className="text-danger">*</span>
                        </Label>
                        <Input
                          name="last_name"
                          placeholder={props.t("Last Name")}
                          type="text"
                          onChange={validationType.handleChange}
                          onBlur={validationType.handleBlur}
                          value={validationType.values.last_name || ""}
                          invalid={
                            validationType.touched.last_name &&
                            validationType.errors.last_name
                              ? true
                              : false
                          }
                        />
                        {validationType.touched.last_name &&
                        validationType.errors.last_name ? (
                          <FormFeedback type="invalid">
                            {validationType.errors.last_name}
                          </FormFeedback>
                        ) : null}
                      </div>
                    </Col>
                    </Row>
                    
                    <Row>
                    <Col sm={6}>
                      <div className="mb-3">
                        <Label className="form-label">
                          {props.t("License Number")}{" "}
                          <span className="text-danger">*</span>
                        </Label>
                        <Input
                          name="license_number"
                          placeholder={props.t("License Number")}
                          type="text"
                          onChange={validationType.handleChange}
                          onBlur={validationType.handleBlur}
                          value={validationType.values.license_number || ""}
                          invalid={
                            validationType.touched.license_number &&
                            validationType.errors.license_number
                              ? true
                              : false
                          }
                        />
                        {validationType.touched.license_number &&
                        validationType.errors.license_number ? (
                          <FormFeedback type="invalid">
                            {validationType.errors.license_number}
                          </FormFeedback>
                        ) : null}
                      </div>
                    </Col>

                    <Col sm={6}>
                      <div className="mb-3">
                        <Label className="form-label">
                          {props.t("unique_id")}
                          {/* <span className="text-danger">*</span> */}
                        </Label>
                        <Input
                          name="unique_id"
                          placeholder={props.t("unique_id")}
                          type="text"
                          onChange={validationType.handleChange}
                          onBlur={validationType.handleBlur}
                          value={validationType.values.unique_id || ""}
                          invalid={
                            validationType.touched.unique_id &&
                            validationType.errors.unique_id
                              ? true
                              : false
                          }
                        />
                        {validationType.touched.unique_id &&
                        validationType.errors.unique_id ? (
                          <FormFeedback type="invalid">
                            {validationType.errors.unique_id}
                          </FormFeedback>
                        ) : null}
                      </div>
                    </Col>
                    </Row>

                    <Row>
                    <Col sm={6}>
                      <div className="mb-3">
                        <Label className="form-label">
                          {props.t("Email")}{" "}
                          {/* <span className="text-danger">*</span> */}
                        </Label>
                        <Input
                          name="email"
                          placeholder={props.t("Email")}
                          type="text"
                          onChange={validationType.handleChange}
                          onBlur={validationType.handleBlur}
                          value={validationType.values.email || ""}
                          invalid={
                            validationType.touched.email &&
                            validationType.errors.email
                              ? true
                              : false
                          }
                        />
                        {validationType.touched.email &&
                        validationType.errors.email ? (
                          <FormFeedback type="invalid">
                            {validationType.errors.email}
                          </FormFeedback>
                        ) : null}
                      </div>
                    </Col>


                    <Col sm={6}>
                      <div className="mb-3">
                        <Label className="form-label">
                          {props.t("Phone")}{" "}
                          <span className="text-danger">*</span>
                        </Label>
                        <Input
                          name="mobile"
                          placeholder={props.t("Phone")}
                          type="text"
                          onChange={validationType.handleChange}
                          onBlur={validationType.handleBlur}
                          value={validationType.values.mobile || ""}
                          invalid={
                            validationType.touched.mobile &&
                            validationType.errors.mobile
                              ? true
                              : false
                          }
                        />
                        {validationType.touched.mobile &&
                        validationType.errors.mobile ? (
                          <FormFeedback type="invalid">
                            {validationType.errors.mobile}
                          </FormFeedback>
                        ) : null}
                      </div>
                    </Col>
                    </Row>
                    
                    {doctor &&  specialtiestList.length > 0 ? (
                    <Col sm={12}>
                        <div className="mb-3">
                          <Label htmlFor="validationCustom01">
                            {props.t("select_specialist")}  <span className="text-danger">*</span>
                          </Label>
                          <Select
                              className={
                                validationType.touched.specialist_list &&
                                validationType.errors.specialist_list
                                  ? " bor"
                                  : " text-dark  "
                              }
                              styles={{
                                control: styles => ({
                                  ...styles,
                                }),
                              }}
                              
                              onChange={e => {  
                              
                              setSelectedOption({
                                  value:  e?.value,
                                  label: e?.label,
                              });
                                
                              validationType.setFieldValue(
                                "specialist_list",
                                e?.value
                              );
                                
                              
                              }}
                              
                            
                              onBlur={validationType.handleBlur}
                              name="specialist_list"
                              value={selectedOption}
                              defaultValue={
                                validationType.values.specialist_list
                              }
                              options={specialtiestList}
                              //onInputChange={onSearchDoctorHandler}
                              //placeholder={<div>{props.t("typeToSearch")}</div>}
                            />

                          {/* <Select
                            defaultValue={validationType.values.mobile || ""}
                            placeholder={props.t("select_specialist")}
                            // onChange={(e) => {
                            //    const f = e.map((item, index) => {
                            //     return item.value;
                            //   })
                            //   validationType.setFieldValue("specialist_list", f)
                            //   //setSelectedOption(e)
                            // }}
                            onChange={e =>
                              handleChangeActivityType(e.target.value)
                            }
                            //onChange={validationType.handleChange}
                            onBlur={validationType.handleBlur}
                            options={specialtiestList}
                            // isMulti={false}
                            styles={{
                              control: (provided, state) => ({
                                ...provided,
                                borderColor:
                                  (validationType.touched.specialist_list &&
                                    validationType.errors.specialist_list)
                                    ? 'red'
                                    : '#ccc'
                              }
                              )
                            }}
                          /> */}
                          {validationType.touched.specialist_list &&
                            validationType.errors.specialist_list ? (
                            <div
                              style={{
                                color: "#f46a6a",
                                fontSize: "11px",
                                marginTop: "3px",
                              }}
                            >
                              {validationType.errors.specialist_list}
                            </div>
                          ) : null}
                        </div>

                      </Col>
                      ) : null}

                      <Row>
                        
                          <Label htmlFor="validationCustom01">
                            {props.t("Interests")}  
                            {/* <span className="text-danger">*</span> */}
                          </Label>
                          {interests.length > 0
                          ? interests.map((interest, i) => {
                              var isChecked = false;
                              userInterests.forEach((intrst, i) => {
                                if (intrst === interest.id) {
                                  console.log(intrst);
                                  isChecked = true;
                                }
                              });
                              return (
                                <Col sm={4} key={`interest_${i}`}>
                                  <div className="mb-3" >
                                  <input
                                    type="checkbox"
                                    onMouseUp={(e) => onInterestHandler(e, interest.id)}
                                    name="interest_list"
                                    defaultChecked={isChecked}
                                  />{" "}
                                  {interest.name_en}
                                  </div>
                                </Col>
                              );
                            })
                          : props.t("No-Post-Found")}

                            {validationType.touched.interest_list &&
                            validationType.errors.interest_list ? (
                            <div
                              style={{
                                color: "#f46a6a",
                                fontSize: "11px",
                                marginTop: "3px",
                              }}
                            >
                              {validationType.errors.interest_list}
                            </div>
                          ) : null}
                       
                       
                      </Row>
                    
                
                 
                    <Button color="primary" type="submit">
                      {props.t("Update")}
                    </Button>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withTranslation()(EditDoctor)
