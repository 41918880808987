// src/components/filter.
import React, { useEffect, useState } from "react";
import PropTypes from 'prop-types';
import { Base64 } from "Token";

//import components
import Breadcrumbs from '../../components/Common/Breadcrumb';
import DataTable from 'react-data-table-component';
import { BannerApi } from "../../apis/BannerApi";
import { useHistory, Link } from "react-router-dom"
import TableContainer from '../../components/Common/TableContainer';
import { withTranslation } from "react-i18next";
import * as Yup from "yup";
import { useFormik } from "formik";


import toastr from "toastr";
import config, { LIMIT } from "config/config";
import constants from "../../config/constants";
import i18n from "../../i18n";
import {
    Container,
    Row,
    Col,
    Card,
    CardBody,
    Form,
    Label,
    Input,
    FormFeedback,
    Button
} from "reactstrap";
import Swal from "sweetalert2";
import { CommunityApi } from "apis/CommunityApi";
import moment from "moment";
import * as url from "../../helpers/common_helper";
import FilterComponent from "pages/Program/Activity/FilterComponent";


function CommunityPost(props) {
    const history = useHistory();
    const [files1, setFiles1] = useState([]);
    const [communityList, setCommunityList] = useState([]);
    const [fetchBanner, setFetchBanner] = useState([]);
    const [postBannerId, setBannerId] = useState('');
    const [loading, setLoading] = useState(false);
   
    const [communityName, setCommunityName] = useState();
    const [autoSearch, setAutoSearchData] = useState([]);
    const [page, setCurrentPage] = useState(1);


    const [activeData, setActiveData] = useState({
        page: 1,
        totalPage: 1,
        search: "",
        limit: config.LIMIT
    });
    const [limit, setCurrentLimit] = useState(config.LIMIT);



    useEffect(() => {
        getAllCommunity(activeData);
    }, [page,limit]);

    const formClear = () => {
        validationType.resetForm();
        setFiles1([])
        setFetchCommunity();
    };

    const columns = [
        {
            name: props.t("Sr_No"),
            selector: row => row.id,
            sort: "asc",
            width: 150,
            sortable: true,
            defaultSortField: true,
            defaultSortAsc: false
        },
        // {
        //     name: props.t("companyName"),
        //     selector: row => row.company_name,
        //     sort: "asc",
        //     width: 270,
        //     sortable: true,
        //     defaultSortField: true,
        //     defaultSortAsc: false
        // },
        {
            name: props.t("Name_Eng"),
            selector: row => row.name_en,
            sort: "asc",
            width: 270,
            sortable: true,
            defaultSortField: true,
            defaultSortAsc: false
        },
        {
            name: props.t("Name_Thai"),
            selector: row => row.name_th,
            sort: "asc",
            width: 270,
            sortable: true,
            defaultSortField: true,
            defaultSortAsc: false
        },
        {
            name: props.t("postType"),
            selector: row => row.post_type,
            sort: "asc",
            width: 270,
            sortable: true,
            defaultSortField: true,
            defaultSortAsc: false
        },
        // {
        //     name: props.t("Description Eng"),
        //     selector: row => row.description_en,
        //     sort: "asc",
        //     width: 270,
        //     sortable: true,
        //     defaultSortField: true,
        //     defaultSortAsc: false
        // },
        // {
        //     name: props.t("Description Thai"),
        //     selector: row => row.description_th,
        //     sort: "asc",
        //     width: 270,
        //     sortable: true,
        //     defaultSortField: true,
        //     defaultSortAsc: false
        // },
        // {
        //     name: props.t("Type"),
        //     selector: row => row.type,
        //     sort: "asc",
        //     width: 270,
        //     sortable: true,
        //     defaultSortField: true,
        //     defaultSortAsc: false
        // },
        {
            name: props.t("Post_Date"),
            selector: row => row.post_date,
            sort: "asc",
            width: 270,
            sortable: true,
            defaultSortField: true,
            defaultSortAsc: false
        },
        // {
        //     name: props.t("Status"),
        //     selector: row => row.status,
        //     sort: "asc",
        //     width: 270,
        //     sortable: true,
        //     defaultSortField: true,
        //     defaultSortAsc: false
        // },
        {
            name: props.t("Action"),
            selector: row => row.action,
            sort: "asc",
            width: 200,
        },
    ];
    const getAllCommunity = (data) => {
        setLoading(true);
        CommunityApi.getAllCommunity(data)
            .then((res) => {
                setActiveData({
                    page: activeData.page,
                    totalPage: res?.data?.data?.count,
                    search: activeData.search,
                    limit: activeData.limit,
                });
                let data = [];
                var sr_no = res.data.sr_no_start;

                res?.data?.data?.rows?.forEach((v, i) => {
                    sr_no = sr_no + 1;
                    data[i] = {
                        id: sr_no,
                        //company_name: v.Company?.name_en,
                        name_en: v.name_en,
                        name_th: v.name_th,
                        post_type: v.Category?.name_en,
                        description_en: v.description_en,
                        description_th: v.description_th,
                        type: v.type,
                        // post_date: v.post_date,
                        post_date: url.dateFormat(v.post_date),
                        status: v.status,
                        // photo: (
                        //     <> 
                        //         <img
                        //             className=" header-profile-user"
                        //             src={`${v.image_url}`}

                        //         />
                        //     </>
                        // ),
                        action: (
                            <>

                                <Link
                                    style={{ marginRight: "15px" }}
                                    to={`/edit-posts/${v.id}`}
                                >
                                    <i className=" fas fa-edit" id="edittooltip" />
                                </Link>
                                <i className="mdi mdi-delete font-size-18" style={{ color: "red" }} id="deletetooltip" onClick={() => onDelete(v.id)} />
                            </>
                        ),
                    };
                });
                setCommunityList(data);
                setLoading(false);
            })
            .catch((err) => {
                console.log(err);
            });
    };


    // const onDelete = (id) => {

    //     Swal.fire({
    //         title: props.t("Are_you_sure"),
    //         text: props.t("Able_to_revert_this"),
    //         icon: props.t("Warning"),
    //         showCancelButton: true,
    //         confirmButtonColor: "#3085d6",
    //         cancelButtonColor: "#f46a6a",
    //         confirmButtonText: props.t("Yes_delete_it"),
    //     }).then((result) => {
    //         if (result.isConfirmed) {
    //             let Id = { "id": id };
    //             CommunityApi.deleteCommunity(Id)
    //                 .then((res) => {
    //                     Swal.fire(props.t("Deleted"), res.data.message, props.t("Success"));
    //                     getAllCommunity(activeData)

    //                 })
    //                 .catch((err) => {
    //                     console.log(err);
    //                 });
    //         }
    //     });
    // };
    const onDelete = (id) => {

        Swal.fire({
            title: props.t("Are_you_sure"),
            text: props.t("Able_to_revert_this"),
            icon: props.t("Warning"),
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#f46a6a",
            confirmButtonText: props.t("Yes_delete_it"),
            cancelButtonText: props.t("Cancel"),
        }).then((result) => {
            if (result.isConfirmed) {
                let communityId = { "community_id": id };
                CommunityApi.deleteCommunity(communityId)
                    .then((res) => {
                        Swal.fire(props.t("Deleted"), res.data.message, props.t("Success"));
                        //getAllCommunity(activeData)
                        handleChange(page);

                    })
                    .catch((err) => {
                        // cosnsole.log(12);
                        console.log(err);
                    });
            }
        });
    };

    const handleChange = (v) => {
        setCurrentPage(v);
        setActiveData({ page: v, totalPage: activeData.totalPage, search: activeData.search, limit: limit });
        const data = { page: v, totalPage: activeData.totalPage, search: activeData.search, limit: limit}
        getAllCommunity(data)
    }


    const handleRowChange = (v) => {
        setCurrentLimit(v);
        setActiveData({ page: activeData.page, totalPage: activeData.totalPage, search: activeData.search, limit: v });
        const data = { page: activeData.page, totalPage: activeData.totalPage, search: activeData.search, limit: v }
        getAllCommunity(data)
    }

    const handleChangeSearch = () => {
        getAllCommunity(activeData)
    }

    // const OnSearch = (e) => {
    //     setActiveData({ ...activeData, search: e.target.value })
    // }

    const OnSearchKeyUp = (e) => {
        setCommunityName(e.target.value);
        if(e.key == "Enter") {
          setActiveData({ page: activeData.page, totalPage: activeData.totalPage, search: e.target.value, limit: activeData.limit });
          const data = { page: activeData.page, totalPage: activeData.totalPage, search: e.target.value, limit: activeData.limit }
          getAllCommunity(data);
          setCommunityName(communityName);
          setAutoSearchData([]);
          return false;
        }
      }
        
      const OnSearch = (e) => {
        let data = { keyword: e.target.value };
        setCommunityName(e.target.value);
        if(e.target.value != '') {
            CommunityApi.getCommunityByName(data)
            .then((res) => {
              if (res.data.success) {
                setAutoSearchData(res.data.data);
              } else {
                setAutoSearchData([]);
              }
            })
            .catch((err) => {
              console.log(err);
          });
        }  else {
          setAutoSearchData([]);
        }
        // setActiveData({ ...activeData, search: e.target.value })
      }
    
      const searchBycommunityName = (keyword) => {
        setActiveData({ page: activeData.page, totalPage: activeData.totalPage, search: keyword, limit: activeData.limit });
        const data = { page: activeData.page, totalPage: activeData.totalPage, search: keyword, limit: activeData.limit }
        getAllCommunity(data);
        setCommunityName(keyword);
        setAutoSearchData([]);
      }
    
      const searchRecord = () => {
        activeData.communityName = (communityName !== undefined) ? communityName : "";

        setActiveData({ page: activeData.page, totalPage: activeData.totalPage, search: activeData.communityName, limit: activeData.limit });
        const data = { page: activeData.page, totalPage: activeData.totalPage, search: activeData.communityName, limit: activeData.limit }
        getAllCommunity(data);
        setCommunityName(communityName);
      }

    //meta title
    document.title = props.t("Community") + ' | ' + props.t("MedX");

    return (
        <div className="page-content">
            <div className="container-fluid">
                <Breadcrumbs title={props.t("Community")} breadcrumbItem={props.t("Post")} />
                <Container fluid={true}>
                    <Row>
                        <div style={{ margin: '12px' }} className="d-flex ">
                            {/* <Input onChange={OnSearch} className="w-25" name="search" placeholder={props.t("Search")} />
                            <button style={{ marginleft: '10px' }} onClick={handleChangeSearch} className="btn btn-primary">{props.t("Search")}</button> */}
                        
                        <div className="wrapper w-40">
                        <div className="search-input">
                            <a href="" target="_blank" hidden="" />
                            <input type="text" defaultValue={communityName} value={communityName} onChange={(e) => OnSearch(e) } onKeyUp = {OnSearchKeyUp}  placeholder="Type to search.."/>
                            {autoSearch.length > 0 && communityName != '' && (
                            <div className="autocom-box">
                                {autoSearch?.map((searchdata, index) => {
                                return (<li key={index} onClick={() => searchBycommunityName(searchdata.name_en)}>{searchdata.name_en}</li>)
                                })}
                            </div>
                            )}
                            <div className="icon">
                            <i className="fas fa-search" onClick={() => searchRecord()}/>
                            </div>
                        </div>
                        </div>
                        
                        </div>

                        <Col>
                            <Link to="/add-posts" className="btn btn-primary w-xs btn-right">{props.t("Add_Post")}</Link>
                        </Col>
                    </Row>
                </Container>
                <Container fluid={false}>
                    <Row>
                        <Col lg={12}>
                            <Card>
                                <CardBody >
                                    <Col sm={12}>
                                        <DataTable
                                            className="table-bordered"
                                            progressPending={loading}
                                            columns={columns}
                                            data={communityList}
                                            pagination
                                            paginationServer
                                            paginationTotalRows={activeData.totalPage}
                                            paginationPerPage={activeData.limit}
                                            defaultSortFieldID={1}
                                            onChangeRowsPerPage={value => handleRowChange(value)}
                                            onChangePage={value => handleChange(value)}
                                            sortable
                                            noHeader
                                            defaultSortField="id"
                                            defaultSortAsc={false}
                                            highlightOnHover
                                        />
                                    </Col>

                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </div>
    );
}


CommunityPost.propTypes = {
    t: PropTypes.any,
    preGlobalFilteredRows: PropTypes.any,
};

export default withTranslation()(CommunityPost);