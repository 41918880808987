import PropTypes from 'prop-types'
import React from "react"
import { Col, Modal, ModalBody, Row } from "reactstrap"

const TermsAndConditionModal = ({ show, acceptTermsAndCondition, termsAndCondtionData }) => {
  return (
    <Modal isOpen={show} toggle={acceptTermsAndCondition} centered={true}>
      <ModalBody>
        <Row>
          <Col lg={12}>
            <div className="text-center">
              <h4 className='modal-title'>{termsAndCondtionData?.name_en}</h4>
            </div>
          </Col>
          <Col lg={12}>
            <div className="modal-body scrollAndFixedHeight">
              <div
                dangerouslySetInnerHTML={{
                  __html: termsAndCondtionData?.content_en,
                }}
              />
            </div>
          </Col>
          <Col lg={12}>
            <div className="modal-footer">
              <div className="d-flex align-items-center justify-content-center">
                <button
                  onClick={acceptTermsAndCondition}
                  className="float-right btn btn-primary"
                >
                  Accept
                </button>
              </div>
            </div>
          </Col>
        </Row>
      </ModalBody>
    </Modal>
  )
}

TermsAndConditionModal.propTypes = {
  onCloseClick: PropTypes.func,
  onDeleteClick: PropTypes.func,
  show: PropTypes.any
}

export default TermsAndConditionModal
