import React, { useState, useEffect } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  Alert,
  CardBody,
  Button,
  Label,
  Input,
  FormFeedback,
  Form,
} from "reactstrap";

// Formik Validation
import * as Yup from "yup";
import { useFormik } from "formik";
import PropTypes from 'prop-types';
//redux
import { useSelector, useDispatch } from "react-redux";

import { withRouter } from "react-router-dom";

//Import Breadcrumb
import Breadcrumb from "../../../components/Common/Breadcrumb";

import avatar from "../../../assets/images/users/avatar-1.jpg";
// actions
import { editProfile, resetProfileFlag } from "../../../store/actions";

import {  getUserID,getName, getEmail, getProfilePhoto } from "../../../Token";
import { ProfileApi } from "../../../apis/ProfileApi";
import Swal from "sweetalert2";
import toastr from "toastr";
import "toastr/build/toastr.min.css";
import { withTranslation } from "react-i18next";
import i18n from "../../../i18n";
import profileImage from "../../../assets/images/profile.png";

const UpdateProfile = (props) => {
  document.title= props.t("Profile")+' | '+props.t("MedX");
  const dispatch = useDispatch();
  const { error, success } = useSelector(state => ({
    error: state.Profile.error,
    success: state.Profile.success,
  }));

  const [adminDetail, setAdminDetail] = useState({});

  const getProfileDetail = () => {
    ProfileApi.getProfileDetail()
      .then((res) => {
        if (res.data.success) {
          setAdminDetail(res.data.data);
        } else {
          setAdminDetail("");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getProfileDetail();
  });

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      first_name: adminDetail.first_name || '',
      last_name: adminDetail.last_name || ''
    },
    validationSchema: Yup.object({
      first_name: Yup.string().required(props.t("Required-Message")),
      last_name: Yup.string().required(props.t("Required-Message")),
    }),
    onSubmit: (values) => {
      ProfileApi.updateProfile(values)
      .then(res => {
          if (res.data.success) {
            Swal.fire({
              text: res.data.message,
              icon: 'success',
              imageAlt: 'success image',
              // confirmButtonColor: '#00CA84'
            }).then((result) => {
              if (result.isConfirmed) {
                window.location.reload('/update-profile');
                // history.push('/update-profile');
              }
            });
          } else {
              toastr.error(res.message, '');
          }
      }).catch(function (error) {
        toastr.error(error.response.data.message, '');
      });
    }
  });

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumb title={props.t("MedX")} breadcrumbItem={props.t("Profile")} />

          <Row>
            <Col lg="12">
              {error && error ? <Alert color="danger">{error}</Alert> : null}
              {success ? <Alert color="success">{success}</Alert> : null}

              <Card>
                <CardBody>
                  <div className="d-flex row">
                    <div className="ms-3 col-md-1">
                      <img
                        src={profileImage}
                        alt=""
                        className="avatar-md rounded-circle img-thumbnail"
                      />
                    </div>
                    <div className="flex-grow-1 align-self-center col-md-10">
                      <div className="text-muted">
                        <h5>{adminDetail.first_name} {" "} {adminDetail.last_name}</h5>
                        <p className="mb-1">{props.t("Email")} : {adminDetail.email}</p>
                        {/* <p className="mb-0">{props.t("Id_No")} : #{idx}</p> */}
                      </div>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>


          <Card>
            <CardBody>
              <Form
                className="form-horizontal"
                onSubmit={(e) => {
                  e.preventDefault();
                  validation.handleSubmit();
                  return false;
                }}
              > 
                <Col sm={6}>
                  <div className="form-group mb-3">
                    <Label className="form-label">{props.t("First_Name")} {" "}
                    <span className="text-danger">*</span>
                    </Label>
                    <Input
                      name="first_name"
                      className="form-control"
                      placeholder={props.t("First_Name")}
                      type="text"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.first_name || ""}
                      invalid={
                        validation.touched.first_name && validation.errors.first_name ? true : false
                      }
                    />
                    {validation.touched.first_name && validation.errors.first_name ? (
                      <FormFeedback type="invalid">{validation.errors.first_name}</FormFeedback>
                    ) : null}
                  </div>
                </Col>
                <Col sm={6}>
                  <div className="form-group mb-3">
                    <Label className="form-label">{props.t("Last_Name")}
                      {" "} <span className="text-danger">*</span>
                    </Label>
                    <Input
                      name="last_name"
                      className="form-control"
                      placeholder={props.t("Last_Name")}
                      type="text"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.last_name || ""}
                      invalid={
                        validation.touched.last_name && validation.errors.last_name ? true : false
                      }
                    />
                    {validation.touched.last_name && validation.errors.last_name ? (
                      <FormFeedback type="invalid">{validation.errors.last_name}</FormFeedback>
                    ) : null}
                  </div>
                </Col>
                <div className="mt-4">
                  <Button type="submit" color="primary">
                    {props.t("Update")}
                  </Button>
                </div>
              </Form>
            </CardBody>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  );
};

UpdateProfile.propTypes = {
  t: PropTypes.any,
  preGlobalFilteredRows: PropTypes.any,

};
export default withTranslation()(UpdateProfile);