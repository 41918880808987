import { EvalutionFormApi } from "apis/EvalutionFormApi"
import { InternshipApi } from "apis/InternshipApi"
import { UserApi } from "apis/UserApi"
import config from "config/config"
import { useFormik } from "formik"
import { debounce } from "lodash"
import React, { useEffect, useState } from "react"
import DataTable from "react-data-table-component"
import { withTranslation } from "react-i18next"
import { useHistory } from "react-router-dom"
import Select from "react-select"
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Form,
  Label,
  Row,
} from "reactstrap"
import Swal from "sweetalert2"
import toastr from "toastr"
import { getRoleID, getUserID } from "Token"
import * as Yup from "yup"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import constants from "../../config/constants"

const UserEvaluations = props => {
  const history = useHistory()
  const [userEvaluations, setUserEvaluations] = useState([])
  const [defaultType, setDefualtType] = useState()
  const [doctorsData, setDoctorsData] = useState([])
  const [evaluationForms, setEvaluationForms] = useState([])
  const [defaultEvaluationForm, setDefualtEvaluationForm] = useState()
  const [defaultSelectedDoctor, setDefualtSelectedDoctor] = useState()
  const [intUserEvaluation, setIntUserEvaluation] = useState([])
  const [intUserEvaluationId, setIntUserEvaluationId] = useState()
  const [loading, setLoading] = useState(false)
  const [activeData, setActiveData] = useState({
    page: 1,
    totalPage: 1,
    search: "",
    limit: config.LIMIT,
  })
  const [page, setCurrentPage] = useState(1);


  useEffect(() => {
    getAllUserEvaluations(activeData)
    getAllDoctors("INTERNAL")
    getInstituteAllEvaluationForms()
  }, [page])

  const getAllDoctors = (type, searchKeyword = "") => {
    const payload = {
      speaker_type: type,
      search: searchKeyword,
      page: 1,
      limit: 10,
    }
    UserApi.getSpeakerUser(payload)
      .then(res => {
        if (res.data.success) {
          let doctorArr = []
          res.data.data.rows.length > 0 &&
            res.data.data.rows.map((doctor, i) => {
              doctorArr[i] = {
                value: doctor.id,
                label:
                  doctor.first_name +
                  " " +
                  (doctor.last_name ? doctor.last_name : ""),
              }
            })
          setDoctorsData([...doctorArr])
        } else {
          setDoctorsData([])
        }
      })
      .catch(err => {
        setDoctorsData([])
      })
  }

  const getInstituteAllEvaluationForms = () => {
    const payload = {
      user_id: getUserID(),
    }
    EvalutionFormApi.getInstituteEvaluationForms(payload)
      .then(res => {
        if (res.data.success) {
          let formsArr = []
          res.data.data.rows.length > 0 &&
            res.data.data.rows.map((form, i) => {
              formsArr[i] = {
                value: form.id,
                label: form.name_en,
              }
            })
          setEvaluationForms([...formsArr])
        } else {
          setEvaluationForms([])
        }
      })
      .catch(err => {
        setEvaluationForms([])
      })
  }

  const onSearchDoctorHandler = debounce(function (searchInput) {
    if (searchInput.length >= 2)
      getAllDoctors("INTERNAL", searchInput.toString().trim())
  }, 500)

  const formClear = () => {
    validationType.resetForm()
    setIntUserEvaluation()
  }

  const columns = [
    {
      name: props.t("Sr_No"),
      selector: row => row.index,
      sort: "asc",
      width: 150,
      sortable: true,
      defaultSortField: true,
      defaultSortAsc: false,
    },
    {
      name: props.t("Type"),
      selector: row => row.type,
      sort: "asc",
      width: 270,
      sortable: true,
      defaultSortField: true,
      defaultSortAsc: false,
    },
    {
      name: props.t("doctor"),
      selector: row => row.userName,
      sort: "asc",
      width: 270,
      sortable: true,
      defaultSortField: true,
      defaultSortAsc: false,
    },
    {
      name: props.t("evaluationForm"),
      selector: row => row.evaluationForm,
      sort: "asc",
      width: 270,
      sortable: true,
      defaultSortField: true,
      defaultSortAsc: false,
    },
    {
      name: props.t("Action"),
      selector: row => row.action,
      sort: "asc",
      width: 200,
    },
  ]

  const getAllUserEvaluations = data => {
    setLoading(true)
    InternshipApi.getUserEvaluationsApi(data)
      .then(res => {
        if (res.data.success) {
          setActiveData({
            page: activeData.page,
            totalPage: res?.data?.data?.count,
            search: activeData.search,
            limit: activeData.limit,
          })
          let data = []
          let sr_no = res.data.sr_no_start
          res?.data?.data?.rows?.forEach((evaluation, i) => {
            sr_no = sr_no + 1
            data[i] = {
              index: sr_no,
              type: evaluation.type,
              userName:
                evaluation.user.first_name + " " + evaluation.user.last_name,
              evaluationForm: evaluation.evaluationForm.name_en,
              action: (
                <>
                  <a href="#" onClick={() => onEdit(evaluation.id)}>
                    <i
                      className="fas fa-edit"
                      id="deletetooltip"
                      style={{ color: "blue", marginRight: "3px" }}
                    />{" "}
                  </a>
                  <a href="#" onClick={() => onDelete(evaluation.id)}>
                    <i
                      className="mdi mdi-delete font-size-16"
                      style={{ color: "red" }}
                      id="deletetooltip"
                    ></i>
                  </a>
                </>
              ),
            }
          })
          setUserEvaluations(data)
          setLoading(false)
        }
      })
      .catch(err => {
        console.log(err)
      })
  }

  const onDelete = id => {
    Swal.fire({
      title: props.t("Are_you_sure"),
      text: props.t("Able_to_revert_this"),
      icon: props.t("Warning"),
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#f46a6a",
      confirmButtonText: props.t("Yes_delete_it"),
      cancelButtonText: props.t("Cancel"),
    }).then(result => {
      if (result.isConfirmed) {
        InternshipApi.deleteUserEvaluationApi(id)
          .then(res => {
            Swal.fire(props.t("Deleted"), res.data.message, props.t("Success"))
            setIntUserEvaluationId()
            formClear()
            handleChange(page);
            //getAllUserEvaluations(activeData)
          })
          .catch(err => {
            console.log(err)
          })
      }
    })
  }

  const onEdit = id => {
    setIntUserEvaluationId(id)
    InternshipApi.getUserEvaluationDetailByIDApi(id)
      .then(res => {
        if (res.data.success) {
          setIntUserEvaluation(res.data.data)
          setDefualtEvaluationForm({
            value: res.data.data.evaluationForm.id,
            label: res.data.data.evaluationForm.name_en,
          })
          setDefualtType({
            value: res.data.data.type,
            label: res.data.data.type,
          })
          setDefualtSelectedDoctor({
            value: res.data.data.user.id,
            label:
              res.data.data.user.first_name +
              " " +
              res.data.data.user.last_name,
          })
        }
      })
      .catch(err => {
        console.log(err)
      })
  }

  // Form validation
  const validationType = useFormik({
    enableReinitialize: true,
    initialValues: {
      id: intUserEvaluationId ? intUserEvaluationId : "",
      type: intUserEvaluation ? intUserEvaluation.type : "",
      user_id: intUserEvaluation ? intUserEvaluation?.user?.id : "",
      evaluation_form_id: intUserEvaluation
        ? intUserEvaluation?.evaluationForm?.id
        : "",
    },
    validationSchema: Yup.object().shape({
      type: Yup.string().required(props.t("Required-Message")),
      user_id: Yup.string().required(props.t("Required-Message")),
      evaluation_form_id: Yup.string().required(props.t("Required-Message")),
    }),
    onSubmit: values => {
      if (intUserEvaluationId) {
        InternshipApi.updateUserEvaluationApi(values)
          .then(res => {
            if (res.data.success) {
              Swal.fire({
                text: res.data.message,
                icon: "success",
                imageAlt: "success image",
              }).then(result => {
                if (result.isConfirmed) {
                  formClear()
                  setDefualtEvaluationForm()
                  setDefualtType()
                  setDefualtSelectedDoctor()
                  history.push("/user-evaluations")
                  setIntUserEvaluationId()
                  getAllUserEvaluations(activeData)
                }
              })
            } else {
              toastr.error(res.message, "")
            }
          })
          .catch(function (error) {
            if (error?.response?.data?.message) {
              toastr.error(error?.response?.data?.message, "")
            } else {
              toastr.error(
                error?.response?.data?.error?.errors?.map(
                  (item, v) => `${item.msg}<br>`
                ),
                ""
              )
            }
          })
      } else {
        InternshipApi.createUserEvaluationApi(values)
          .then(res => {
            if (res.data.success) {
              Swal.fire({
                text: res.data.message,
                icon: "success",
                imageAlt: "success image",
              }).then(result => {
                if (result?.isConfirmed) {
                  setDefualtEvaluationForm()
                  setDefualtType()
                  setDefualtSelectedDoctor()
                  history.push("/user-evaluations")
                  formClear()
                  getAllUserEvaluations(activeData)
                }
              })
            } else {
              toastr.error(res?.data?.message, "")
            }
          })
          .catch(function (error) {
            if (error?.response?.data?.message) {
              toastr.error(error?.response?.data?.message, "")
            } else {
              toastr.error(
                error?.response?.data?.error?.errors?.map(
                  (item, v) => `${item.msg}<br>`
                ),
                ""
              )
            }
          })
      }
    },
  })

  const handleChange = v => {
    setCurrentPage(v);
    setActiveData({
      page: v,
      totalPage: activeData.totalPage,
      search: activeData.search,
      limit: activeData.limit,
    })
    const data = {
      page: v,
      totalPage: activeData.totalPage,
      search: activeData.search,
      limit: activeData.limit,
    }
    getAllUserEvaluations(data)
  }

  const handleRowChange = v => {
    setActiveData({
      page: activeData.page,
      totalPage: activeData.totalPage,
      search: activeData.search,
      limit: v,
    })
    const data = {
      page: activeData.page,
      totalPage: activeData.totalPage,
      search: activeData.search,
      limit: v,
    }
    getAllUserEvaluations(data)
  }

  //meta title
  document.title = props.t("userEvaluations") + " | " + props.t("MedX")

  return (
    <div className="page-content">
      <div className="container-fluid">
        <Breadcrumbs
          title={props.t("internship")}
          breadcrumbItem={props.t("userEvaluations")}
        />
      </div>
      <Container fluid={false}>
        <Row>
          <Col lg={constants.ROLE.SUPERADMIN === getRoleID() ? 12 : 8}>
            <Card>
              <CardBody>
                <Col sm={12}>
                  <DataTable
                    className="table-bordered"
                    progressPending={loading}
                    columns={columns}
                    data={userEvaluations}
                    pagination
                    paginationServer
                    paginationTotalRows={activeData.totalPage}
                    paginationPerPage={activeData.limit}
                    defaultSortFieldID={1}
                    onChangeRowsPerPage={value => handleRowChange(value)}
                    onChangePage={value => handleChange(value)}
                    sortable
                    noHeader
                    defaultSortField="id"
                    defaultSortAsc={false}
                    highlightOnHover
                  />
                </Col>
              </CardBody>
            </Card>
          </Col>
          {constants.ROLE.SUPERADMIN !== getRoleID() && (
            <Col lg={4}>
              <Card>
                <CardBody>
                  <Col sm={12}>
                    <Form
                      onSubmit={e => {
                        e.preventDefault()
                        validationType.handleSubmit()
                        return false
                      }}
                    >
                      <Col sm={12}>
                        <div className="mb-3">
                          <Label className="form-label">
                            {props.t("Type")}{" "}
                            <span className="text-danger">*</span>
                          </Label>
                          <Select
                            className={
                              validationType.touched.type &&
                              validationType.errors.type
                                ? "bor "
                                : "text-dark"
                            }
                            styles={{
                              control: styles => ({
                                ...styles,
                              }),
                            }}
                            onChange={e => {
                              validationType.setFieldValue("type", e.value)
                              setDefualtType(e)
                            }}
                            value={defaultType || ""}
                            onBlur={validationType.handleBlur}
                            name="type"
                            options={constants.ASSIGN_EVALUATION_TYPE.map(
                              value => ({
                                value: `${value}`,
                                label: `${value}`,
                              })
                            )}
                            placeholder={
                              <div>{props.t("Please_Select_Type")}</div>
                            }
                          />
                          {validationType.touched.type &&
                          validationType.errors.type ? (
                            <div
                              style={{
                                color: "#f46a6a",
                                fontSize: "11px",
                                marginTop: "3px",
                              }}
                            >
                              {validationType.errors.type}
                            </div>
                          ) : null}
                        </div>
                      </Col>
                      <Col sm={12}>
                        <div className="mb-3">
                          <Label className="form-label">
                            {props.t("doctor")}{" "}
                            <span className="text-danger">*</span>
                          </Label>
                          <Select
                            className={
                              validationType.touched.user_id &&
                              validationType.errors.user_id
                                ? " bor"
                                : " text-dark  "
                            }
                            styles={{
                              control: styles => ({
                                ...styles,
                              }),
                            }}
                            value={defaultSelectedDoctor || ""}
                            defaultValue={validationType.values.user_id}
                            onBlur={validationType.handleBlur}
                            name="user_id"
                            options={[...doctorsData]}
                            onChange={e => {
                              validationType.setFieldValue("user_id", e?.value)
                              setDefualtSelectedDoctor(e)
                            }}
                            onInputChange={onSearchDoctorHandler}
                            placeholder={<div>{props.t("typeToSearch")}</div>}
                          />
                          {validationType.touched.user_id &&
                          validationType.errors.user_id ? (
                            <div
                              style={{
                                color: "#f46a6a",
                                fontSize: "11px",
                                marginTop: "3px",
                              }}
                            >
                              {validationType.errors.user_id}
                            </div>
                          ) : null}
                        </div>
                      </Col>
                      <Col sm={12}>
                        <div className="mb-3">
                          <Label className="form-label">
                            {props.t("evaluationForm")}{" "}
                            <span className="text-danger">*</span>
                          </Label>
                          <Select
                            className={
                              validationType.touched.evaluation_form_id &&
                              validationType.errors.evaluation_form_id
                                ? " bor"
                                : " text-dark  "
                            }
                            styles={{
                              control: styles => ({
                                ...styles,
                              }),
                            }}
                            value={defaultEvaluationForm || ""}
                            defaultValue={
                              validationType.values.evaluation_form_id
                            }
                            onBlur={validationType.handleBlur}
                            name="evaluation_form_id"
                            options={[...evaluationForms]}
                            onChange={e => {
                              validationType.setFieldValue(
                                "evaluation_form_id",
                                e?.value
                              )
                              setDefualtEvaluationForm(e)
                            }}
                            placeholder={
                              <div>{props.t("selectEvaluation")}</div>
                            }
                          />
                          {validationType.touched.evaluation_form_id &&
                          validationType.errors.evaluation_form_id ? (
                            <div
                              style={{
                                color: "#f46a6a",
                                fontSize: "11px",
                                marginTop: "3px",
                              }}
                            >
                              {validationType.errors.evaluation_form_id}
                            </div>
                          ) : null}
                        </div>
                      </Col>
                      <div className="d-flex flex-wrap gap-2">
                        <Button type="submit" color="primary">
                          {props.t("Submit")}
                        </Button>{" "}
                        <Button color="secondary" onClick={e => formClear()}>
                          {props.t("Clear")}
                        </Button>
                      </div>
                    </Form>
                  </Col>
                </CardBody>
              </Card>
            </Col>
          )}
        </Row>
      </Container>
    </div>
  )
}

export default withTranslation()(UserEvaluations)
