import React, { useEffect, useState } from "react"
import {
  Row,
  Col,
  Card,
  CardBody,
  Button,
  Label,
  Input,
  Container,
  FormFeedback,
  Form,
} from "reactstrap"
// Formik validation
import * as Yup from "yup"
import { useFormik } from "formik"
import Select from "react-select"

import toastr from "toastr"
import "toastr/build/toastr.min.css"

import { useHistory } from "react-router-dom"
//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import Swal from "sweetalert2"
import { withTranslation } from "react-i18next"
import i18n from "../../../i18n"
import constants from "../../../config/constants"
import config from "config/config"
import { RoleApi } from "apis/RoleApi"

// Form Editor

import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css"

import "flatpickr/dist/themes/material_blue.css"
import _ from "lodash"
import { ScoreTypesApi } from "apis/ScoreTypeApi"
import { TaskTypesApi } from "apis/TaskTypeApi"
import { CustomActivityTypeApi } from "apis/CustomActivityTypeApi"
import { AttachmentTypesApi } from "apis/AttachmentTypesApi"

function AddCustomActivityTypes(props) {
  const history = useHistory()
  const [creatorRoleList, setCreatorRoleList] = useState([])
  const [selectedCreatorOption, setSelectedCreatorOption] = useState(null)
  const [approvalRoleList, setApprovalRoleList] = useState([])
  const [selectedApprovalOption, setSelectedApprovalOption] = useState(null)
  const [loading, setLoading] = useState(false)
  const [scoreTypesList, setScoreTypesList] = useState([])
  const [attachementTypeList, setAttachementTypeList] = useState([])
  const [jsonObject, setJsonObject] = useState({
    activity_image: false,
    activity_name: true,
    activity_content: true,
    activity_creators: [],
    can_add_quiz: false,
    can_attach_file: false,
    approval_rquired: false,
    visible_to_other: false,
    assign_as_task: false,
    can_other_join_activity: false,
  })
  const [showApprovalRequiredRole, setShowApprovalRequiredRole] =
    useState(false)
  const [showTaskTypes, setShowTaskTypes] = useState(false)
  const [showEnterScoreforWords, setShowEnterScoreforWords] = useState(false)
  const [showEnterScoreforAdvise, setShowEnterScoreforAdvise] = useState(false)
  const [showEnterScoreforAttachment, setShowEnterScoreforAttachment] =
    useState(false)
  const [showEnterScoreToJoiningActivity, setShowEnterScoreToJoiningActivity] =
    useState(false)
  const [showEnterScoreOnTaskComplete, setShowEnterScoreOnTaskComplete] =
    useState(false)
  const [taskTypesList, setTaskTypesList] = useState([])
  const [showAttachmentFields, setShowAttachmentFields] = useState(false)
  const [activityType, setActivityType] = useState("normal")
  const [showFourLevelScores, setShowFourLevelScores] = useState(false)

  const [activeData, setActiveData] = useState({
    activePage: 1,
    totalPage: 1,
    search: "",
    limit: config.LIMIT,
  })

  useEffect(() => {
    getAllData(activeData)
  }, [])

  const getAllData = data => {
    setLoading(true)
    RoleApi.getAllRoles(data)
      .then(res => {
        const y = res?.data?.roles?.rows.map((item, index) => {
          return {
            label: item.role,
            value: item.id,
          }
        })

        setCreatorRoleList(y)
        setApprovalRoleList(y)
        setLoading(false)
      })
      .catch(err => {
        console.log(err)
      })

    ScoreTypesApi.getAllScoreTypesAPI()
      .then(res => {
        setScoreTypesList(res.data.scoreTypes.rows)
        setLoading(false)
      })
      .catch(err => {
        console.log(err)
      })

    AttachmentTypesApi.getAllAttachmentTypes()
      .then(res => {
        setAttachementTypeList(res.data.data.rows)
        setLoading(false)
      })
      .catch(err => {
        console.log(err)
      })

    TaskTypesApi.getAllTaskTypesAPI()
      .then(res => {
        if (res.data.success) {
          setTaskTypesList(res.data.taskTypes.rows)
          setLoading(false)
        }
      })
      .catch(err => {
        console.log(err)
      })
  }

  // Form validation
  const validationType = useFormik({
    enableReinitialize: true,
    initialValues: {
      activityType: activityType,
      activity_group: "",
      code: "",
    },
    validationSchema: Yup.object().shape({
      activity_group: Yup.number().min(3).required(props.t("Required-Message")),
      code: Yup.string().required(props.t("Required-Message")),
    }),
    onSubmit: values => {
      values.jsonObject = jsonObject
      values.creators =
        jsonObject?.activity_creators.length > 0
          ? jsonObject?.activity_creators.toString()
          : null

      CustomActivityTypeApi.createCustomActivityTypeAPI(values)
        .then(res => {
          if (res.data.success) {
            Swal.fire({
              text: res.data.message,
              icon: "success",
              imageAlt: "success image",
            }).then(result => {
              if (result?.isConfirmed) {
                history.push("/custom-activity-types")
                formClear()
              }
            })
          } else {
            toastr.error(res?.data?.message, "")
          }
        })
        .catch(function (error) {
          if (error?.response?.data?.message) {
            toastr.error(error?.response?.data?.message, "")
          } else {
            toastr.error(
              error?.response?.data?.error?.errors?.map(
                (item, v) => `${item.msg}<br>`
              ),
              ""
            )
          }
        })
    },
  })

  const formClear = () => {
    validationType.resetForm()
    setFiles1([])
  }

  const onHandleChange = e => {
    const jsonObjectCopy = _.cloneDeep(jsonObject)
    if (e.target.name === "content_length_3000") {
      jsonObjectCopy.content_length_3000 = e.target.value
    } else if (e.target.name === "content_length_5000") {
      jsonObjectCopy.content_length_5000 = e.target.value
    } else if (e.target.name === "score_per_attachement") {
      jsonObjectCopy.score_per_attachement = e.target.value
    } else if (e.target.name === "score_to_speakerHr") {
      jsonObjectCopy.score_to_speakerHr = e.target.value
    } else if (e.target.name === "score_to_audienceHr") {
      jsonObjectCopy.score_to_audienceHr = e.target.value
    } else if (e.target.name === "attachment_type_id") {
      jsonObjectCopy.attachment_type_id = e.target.value
    } else if (e.target.name === "max_attachment_limit") {
      jsonObjectCopy.max_attachment_limit = e.target.value
    } else if (e.target.name === "score_for_asking_advice") {
      jsonObjectCopy.score_for_asking_advice = e.target.value
    } else if (e.target.name === "score_for_giving_advice") {
      jsonObjectCopy.score_for_giving_advice = e.target.value
    } else if (e.target.name === "score_for_task") {
      jsonObjectCopy.score_for_task = e.target.value
    }else if (e.target.name === "score_for_teacher") {
      jsonObjectCopy.score_for_teacher = e.target.value
    }else if (e.target.name === "score_for_intern") {
      jsonObjectCopy.score_for_intern = e.target.value
    } else {
      if (e.target.checked) {
        if (e.target.name === "score_to_be_content_provider") {
          jsonObjectCopy.score_to_be_content_provider = true
        } else if (e.target.name === "activity_display_content_type") {
          jsonObjectCopy.activity_display_content_type = true
        } else if (e.target.name === "activity_content_and_coauthor") {
          jsonObjectCopy.activity_content_and_coauthor = true
        } else if (e.target.name === "quiz_provider_get_score") {
          jsonObjectCopy.quiz_provider_get_score = true
        } else if (e.target.name === "score_on_attachment") {
          jsonObjectCopy.score_on_attachment = true
        } else if (e.target.name === "project_amount") {
          jsonObjectCopy.project_amount = true
        } else if (e.target.name === "can_add_quiz") {
          jsonObjectCopy.can_add_quiz = true
        } else if (e.target.name === "can_attach_file") {
          setShowAttachmentFields(true)
          jsonObjectCopy.can_attach_file = true
        } else if (e.target.name === "approval_rquired") {
          setShowApprovalRequiredRole(true)
          jsonObjectCopy.approval_rquired = true
        } else if (e.target.name === "visible_to_other") {
          jsonObjectCopy.visible_to_other = true
        } else if (e.target.name === "assign_as_task") {
          setShowTaskTypes(true)
          jsonObjectCopy.assign_as_task = true
        } else if (e.target.name === "activity_image") {
          jsonObjectCopy.activity_image = true
        } else if (e.target.name === "can_other_join_activity") {
          jsonObjectCopy.can_other_join_activity = true
        }
      } else {
        if (e.target.name === "score_to_be_content_provider") {
          jsonObjectCopy.score_to_be_content_provider = false
        } else if (e.target.name === "activity_display_content_type") {
          jsonObjectCopy.activity_display_content_type = false
        } else if (e.target.name === "activity_content_and_coauthor") {
          jsonObjectCopy.activity_content_and_coauthor = false
        } else if (e.target.name === "quiz_provider_get_score") {
          jsonObjectCopy.quiz_provider_get_score = false
        } else if (e.target.name === "score_on_attachment") {
          jsonObjectCopy.score_on_attachment = false
        } else if (e.target.name === "project_amount") {
          jsonObjectCopy.project_amount = false
        } else if (e.target.name === "can_add_quiz") {
          jsonObjectCopy.can_add_quiz = false
        } else if (e.target.name === "can_attach_file") {
          setShowAttachmentFields(false)
          jsonObjectCopy.can_attach_file = false
        } else if (e.target.name === "approval_rquired") {
          setShowApprovalRequiredRole(false)
          jsonObjectCopy.approval_rquired = false
        } else if (e.target.name === "visible_to_other") {
          jsonObjectCopy.visible_to_other = false
        } else if (e.target.name === "assign_as_task") {
          setShowTaskTypes(false)
          jsonObjectCopy.assign_as_task = false
        } else if (e.target.name === "activity_image") {
          jsonObjectCopy.activity_image = false
        } else if (e.target.name === "can_other_join_activity") {
          jsonObjectCopy.can_other_join_activity = false
        }
      }
    }
    console.log(jsonObjectCopy);
    setJsonObject(jsonObjectCopy)
  }

  const onHandleChangeMultipleSelect = (name, data) => {
    const jsonObjectCopy = _.cloneDeep(jsonObject)
    if (name === "activity_creators") {
      jsonObjectCopy.activity_creators = data
    } else if (name === "approver_roles") {
      jsonObjectCopy.approver_roles = data
    } else if (name === "attachment_type") {
      jsonObjectCopy.attachment_type_id = data
    } else if (name === "score_type") {
      // if (
      //   parseInt(data.target.value) === constants.SCORE_TYPE.CONTENT_PROVIDING
      // ) {
      //   setShowEnterScoreforWords(true)
      //   setShowEnterScoreforAdvise(false)
      //   setShowEnterScoreforAttachment(false)
      //   setShowEnterScoreToJoiningActivity(false)
      //   setShowEnterScoreOnTaskComplete(false)
      // } else
      if (parseInt(data.target.value) === constants.SCORE_TYPE.GIVING_ADVISE) {
        setShowEnterScoreforWords(false)
        setShowEnterScoreforAdvise(true)
        setShowEnterScoreforAttachment(false)
        setShowEnterScoreToJoiningActivity(false)
        setShowEnterScoreOnTaskComplete(false)
        setShowFourLevelScores(false)
      } else if (
        parseInt(data.target.value) === constants.SCORE_TYPE.FILE_ATTACHMENT
      ) {
        setShowEnterScoreforWords(false)
        setShowEnterScoreforAdvise(false)
        setShowEnterScoreforAttachment(true)
        setShowEnterScoreToJoiningActivity(false)
        setShowEnterScoreOnTaskComplete(false)
        setShowFourLevelScores(false)
      } else if (
        parseInt(data.target.value) === constants.SCORE_TYPE.ATTENDING_ACTIVITY
      ) {
        setShowEnterScoreforWords(false)
        setShowEnterScoreforAdvise(false)
        setShowEnterScoreforAttachment(false)
        setShowEnterScoreToJoiningActivity(true)
        setShowEnterScoreOnTaskComplete(false)
        setShowFourLevelScores(false)
      } else if (
        parseInt(data.target.value) === constants.SCORE_TYPE.TASK_COMPLETION
      ) {
        setShowEnterScoreforWords(false)
        setShowEnterScoreforAdvise(false)
        setShowEnterScoreforAttachment(false)
        setShowEnterScoreToJoiningActivity(false)
        setShowEnterScoreOnTaskComplete(true)
        setShowFourLevelScores(false)
      } else if (
        parseInt(data.target.value) ===
          constants.SCORE_TYPE.FOUR_LEVEL_EVALUATION ||
        parseInt(data.target.value) ===
          constants.SCORE_TYPE.ONE_YEAR_ROTATION ||
        parseInt(data.target.value) ===
          constants.SCORE_TYPE.ROTATION_CERTIFICATE ||
        parseInt(data.target.value) === constants.SCORE_TYPE.EXAM_WITH_QUIZ
      ) {
        setShowEnterScoreforWords(false)
        setShowEnterScoreforAdvise(false)
        setShowEnterScoreforAttachment(false)
        setShowEnterScoreToJoiningActivity(false)
        setShowEnterScoreOnTaskComplete(false)
        setShowFourLevelScores(true)
      } else {
        setShowEnterScoreforWords(false)
        setShowEnterScoreforAdvise(false)
        setShowEnterScoreforAttachment(false)
        setShowEnterScoreToJoiningActivity(false)
        setShowEnterScoreOnTaskComplete(false)
        setShowFourLevelScores(false)
      }
      jsonObjectCopy.score_type_id = parseInt(data.target.value)
    } else if (name === "task_type") {
      jsonObjectCopy.task_type_id = parseInt(data.target.value)
    }
    setJsonObject(jsonObjectCopy)
  }

  document.title = props.t("Custom Activity" + " | " + props.t("MedX"))

  return (
    <React.Fragment>
      <div className="page-content">
        <Breadcrumbs
          title={props.t("custom_activity_type")}
          breadcrumbItem={
            props.t("Add") + " " + props.t("custom_activity_type")
          }
        />
        <Container fluid={true}>
          <Row>
            <Col lg={12}>
              <Card>
                <CardBody>
                  <Col sm={12}>
                    <Form
                      onSubmit={e => {
                        e.preventDefault()
                        validationType.handleSubmit()
                        return false
                      }}
                    >
                      <div className="row">
                        <div className="col-sm-6 mb-3">
                          <Input
                            type="radio"
                            value="normal"
                            name="activityType"
                            onChange={e => {
                              setActivityType(e.target.value)
                            }}
                            defaultChecked={true}
                            // checked={activityType === 'normal' ? true : false}
                          />
                          <Label>&nbsp;{props.t("normalActivity")}</Label>
                        </div>

                        <div className="col-sm-6 mb-3">
                          <Input
                            type="radio"
                            value="internship"
                            name="activityType"
                            onChange={e => {
                              setActivityType(e.target.value)
                            }}
                            // checked={activityType !== 'normal' ? true : false}
                            // onChange={e => {
                            //   setFormFieldType(e.target.value)
                            // }}
                          />
                          <Label>&nbsp;{props.t("internshipActivity")}</Label>
                        </div>
                      </div>
                      <Row>
                        <Col sm={6}>
                          <div className="mb-3">
                            <Label className="form-label">
                              {props.t("Activity_Group_Id")}
                            </Label>
                            <Input
                              name="activity_group"
                              type="number"
                              min={3}
                              onChange={validationType.handleChange}
                              onBlur={validationType.handleBlur}
                              value={validationType.values.activity_group || ""}
                              invalid={
                                validationType.touched.activity_group &&
                                validationType.errors.activity_group
                                  ? true
                                  : false
                              }
                            />
                            <span className="text-danger">
                              {" "}
                              ({props.t("Note")} : {props.t("you_should_add")})
                            </span>
                            {validationType.touched.activity_group &&
                            validationType.errors.activity_group ? (
                              <FormFeedback type="invalid">
                                {validationType.errors.activity_group}
                              </FormFeedback>
                            ) : null}
                          </div>
                        </Col>
                        <Col sm={6}>
                          <div className="mb-3">
                            <Label className="form-label">
                              {props.t("Code")}
                            </Label>
                            <Input
                              name="code"
                              placeholder={props.t("Code")}
                              type="text"
                              onChange={validationType.handleChange}
                              onBlur={validationType.handleBlur}
                              value={validationType.values.code || ""}
                              invalid={
                                validationType.touched.code &&
                                validationType.errors.code
                                  ? true
                                  : false
                              }
                            />
                            {validationType.touched.code &&
                            validationType.errors.code ? (
                              <FormFeedback type="invalid">
                                {validationType.errors.code}
                              </FormFeedback>
                            ) : null}
                          </div>
                        </Col>
                      </Row>

                      <Row>
                        <Col sm={12}>
                          <div className="mb-3">
                            <span className="caret">
                              <div className="form-check">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  name="activity_image"
                                  onChange={onHandleChange}
                                />
                                <Label className="form-label">
                                  {props.t("activity_image")}
                                </Label>
                              </div>
                            </span>
                          </div>
                        </Col>
                        <Col sm={12}>
                          <div className="mb-3">
                            <span className="caret">
                              <div className="form-check">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  name="activity_name"
                                  onChange={onHandleChange}
                                  checked
                                  disabled
                                />
                                <Label className="form-label">
                                  {props.t("activity_name")}
                                </Label>
                              </div>
                            </span>
                          </div>
                        </Col>
                        <Col sm={12}>
                          <div className="mb-3">
                            <span className="caret">
                              <div className="form-check">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  name="activity_content"
                                  checked
                                  disabled
                                />
                                <Label className="form-label">
                                  {props.t("activity_content")}
                                </Label>
                              </div>
                            </span>
                          </div>
                        </Col>
                        {/* <Col sm={12}>
                          <div className="mb-3">
                            <span className="caret">
                              <div className="form-check">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  name="score_to_be_content_provider"
                                  onChange={onHandleChange}
                                />
                                <Label className="form-label">
                                  {props.t("is_score_tobe_content_provider")}
                                </Label>
                              </div>
                            </span>
                          </div>
                        </Col>
                        <Col sm={12}>
                          <div className="mb-3">
                            <span className="caret">
                              <div className="form-check">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  name="activity_display_content_type"
                                  onChange={onHandleChange}
                                />
                                <Label className="form-label">
                                  {props.t("activity_content_type")}
                                </Label>
                              </div>
                            </span>
                          </div>
                        </Col>
                        <Col sm={12}>
                          <div className="mb-3">
                            <span className="caret">
                              <div className="form-check">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  name="activity_content_and_coauthor"
                                  onChange={onHandleChange}
                                />
                                <Label className="form-label">
                                  {props.t("activity_content_and_coauthor")}
                                </Label>
                              </div>
                            </span>
                          </div>
                        </Col> */}
                        {/* <Col sm={12}>
                          <div className="mb-3">
                            <span className="caret">
                              <div className="form-check">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  name="quiz_provider_get_score"
                                  onChange={onHandleChange}
                                />
                                <Label className="form-label">
                                  {props.t("quiz_provider_get_score")}
                                </Label>
                              </div>
                            </span>
                          </div>
                        </Col>
                        <Col sm={12}>
                          <div className="mb-3">
                            <span className="caret">
                              <div className="form-check">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  name="score_on_attachment"
                                  onChange={onHandleChange}
                                />
                                <Label className="form-label">
                                  {props.t("score_on_attachment")}
                                </Label>
                              </div>
                            </span>
                          </div>
                        </Col>

                        <Col sm={12}>
                          <div className="mb-3">
                            <span className="caret">
                              <div className="form-check">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  name="project_amount"
                                  onChange={onHandleChange}
                                />
                                <Label className="form-label">
                                  {props.t("project_amount")}
                                </Label>
                              </div>
                            </span>
                          </div>
                        </Col> */}
                      </Row>

                      {activityType === "normal" && (
                        <Row>
                          <Col sm={12}>
                            <div className="mb-3">
                              <span className="caret">
                                <div className="form-check">
                                  <input
                                    className="form-check-input"
                                    type="checkbox"
                                    name="can_add_quiz"
                                    onChange={onHandleChange}
                                  />
                                  <Label className="form-label">
                                    {props.t("Can_Add_Quiz")}
                                  </Label>
                                </div>
                              </span>
                            </div>
                          </Col>
                        </Row>
                      )}

                      <Row>
                        <Col sm={12}>
                          <div className="mb-3">
                            <span className="caret">
                              <div className="form-check">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  name="can_attach_file"
                                  onChange={onHandleChange}
                                />
                                <Label className="form-label">
                                  {props.t("Can_Attach_File")}
                                </Label>
                              </div>
                            </span>
                          </div>
                        </Col>
                      </Row>

                      {showAttachmentFields && (
                        <Row>
                          <Col sm={6}>
                            <div className="mb-3">
                              <Label className="form-label">
                                {props.t("Attachment_Type")}
                              </Label>
                              <select
                                name="attachment_type_id"
                                type="select"
                                className="form-control"
                                onChange={e =>
                                  onHandleChangeMultipleSelect(
                                    "attachment_type",
                                    e.target.value
                                  )
                                }
                                onBlur={validationType.handleBlur}
                                value={jsonObject?.attachment_type_id || ""}
                              >
                                <option value="">
                                  {props.t("Select_Attachment_Type")}
                                </option>
                                {attachementTypeList.map((item, i) => {
                                  return (
                                    <option value={item.id} key={i}>
                                      {item.name_en}
                                    </option>
                                  )
                                })}
                              </select>
                              {validationType.touched.attachment_type_id &&
                              validationType.errors.attachment_type_id ? (
                                <div
                                  style={{
                                    color: "#f46a6a",
                                    fontSize: "11px",
                                    marginTop: "3px",
                                  }}
                                >
                                  {validationType.errors.attachment_type_id}
                                </div>
                              ) : null}
                            </div>
                          </Col>
                          {/* <Col sm={6}>
                            <div className="mb-3">
                              <Label className="form-label">
                                {props.t("attachment_title")}
                              </Label>
                              <Input
                                name="attachment_title"
                                placeholder={props.t("attachment_title")}
                                type="text"
                                onChange={onHandleChange}
                              />
                            </div>
                          </Col> */}

                          <Col sm={6}>
                            <div className="mb-3">
                              <Label className="form-label">
                                {props.t("max_attachment_limit")}
                              </Label>
                              <Input
                                name="max_attachment_limit"
                                placeholder={props.t("max_attachment_limit")}
                                type="text"
                                onChange={onHandleChange}
                              />
                            </div>
                          </Col>
                        </Row>
                      )}

                      <Row>
                        <Col sm={12}>
                          <div className="mb-3">
                            <Label className="form-label">
                              {props.t("Activity_Creator")} (
                              {props.t("Activity_Creator_Lable_Note")}){" "}
                            </Label>
                            <Select
                              defaultValue={selectedCreatorOption}
                              //   onChange={e => onHandleChange(e)}
                              onChange={e => {
                                const f = e.map((item, index) => {
                                  return item.value
                                })
                                onHandleChangeMultipleSelect(
                                  "activity_creators",
                                  f
                                )
                                setSelectedCreatorOption(e)
                              }}
                              name="activity_creator"
                              options={creatorRoleList}
                              isMulti={true}
                              menuPortalTarget={document.body}
                              styles={{
                                control: (provided, state) => ({
                                  ...provided,
                                  menuPortal: base => ({
                                    ...base,
                                    zIndex: 9999,
                                  }),
                                  borderColor:
                                    validationType.touched.activity_creator &&
                                    validationType.errors.activity_creator
                                      ? "#f46a6a"
                                      : "#ccc",
                                }),
                              }}
                            />
                          </div>
                        </Col>
                      </Row>

                      <Row>
                        <Col sm={12}>
                          <div className="mb-3">
                            <span className="caret">
                              <div className="form-check">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  name="approval_rquired"
                                  onChange={onHandleChange}
                                />
                                <Label className="form-label">
                                  {props.t("Approval_Required")} (
                                  {props.t("Activity_Approval_Lable_Note")}){" "}
                                </Label>
                              </div>
                            </span>
                          </div>
                        </Col>

                        {showApprovalRequiredRole && (
                          <Col sm={12}>
                            <div className="mb-3">
                              <Select
                                defaultValue={selectedApprovalOption}
                                onChange={e => {
                                  const f = e.map((item, index) => {
                                    return item.value
                                  })
                                  onHandleChangeMultipleSelect(
                                    "approver_roles",
                                    f
                                  )
                                  setSelectedApprovalOption(e)
                                }}
                                options={approvalRoleList}
                                isMulti={true}
                                menuPortalTarget={document.body}
                                styles={{
                                  control: (provided, state) => ({
                                    ...provided,
                                    menuPortal: base => ({
                                      ...base,
                                      zIndex: 9999,
                                    }),
                                    borderColor:
                                      validationType.touched.approval_id &&
                                      validationType.errors.approval_id
                                        ? "#f46a6a"
                                        : "#ccc",
                                  }),
                                }}
                              />
                              {validationType.touched.approval_id &&
                              validationType.errors.approval_id ? (
                                <div
                                  style={{
                                    color: "#f46a6a",
                                    fontSize: "11px",
                                    marginTop: "3px",
                                  }}
                                >
                                  {validationType.errors.approval_id}
                                </div>
                              ) : null}
                            </div>
                          </Col>
                        )}
                      </Row>

                      <Row>
                        <Col sm={12}>
                          <div className="mb-3">
                            <span className="caret">
                              <div className="form-check">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  name="visible_to_other"
                                  onChange={onHandleChange}
                                />
                                <Label className="form-label">
                                  {props.t("Visible_Assign_to_Other")}
                                </Label>
                              </div>
                            </span>
                          </div>
                        </Col>

                        {/* <Col sm={3}>
                          <div className="mb-3">
                            <span className="caret">
                              <div className="form-check">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name="visible_assign_doctor"
                                />
                                <Label className="form-label">
                                  {props.t("All_Doctor")}
                                </Label>
                              </div>
                            </span>
                          </div>
                        </Col>

                        <Col sm={3}>
                          <div className="mb-3">
                            <span className="caret">
                              <div className="form-check">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name="visible_assign_doctor"
                                />
                                <Label className="form-label">
                                  {props.t("Specific_Doctor")}
                                </Label>
                              </div>
                            </span>
                          </div>
                        </Col> */}
                      </Row>

                      {activityType === "normal" && (
                        <Row>
                          <Col sm={12}>
                            <div className="mb-3">
                              <span className="caret">
                                <div className="form-check">
                                  <input
                                    className="form-check-input"
                                    type="checkbox"
                                    name="assign_as_task"
                                    onChange={onHandleChange}
                                  />
                                  <Label className="form-label">
                                    {props.t("assign_as_task")}
                                  </Label>
                                </div>
                              </span>
                            </div>
                          </Col>

                          {showTaskTypes && (
                            <Col sm={12}>
                              <div className="mb-3">
                                <Label className="form-label">
                                  {props.t("task_type")}
                                </Label>
                                <select
                                  name="task_type"
                                  type="select"
                                  className="form-control"
                                  onChange={e =>
                                    onHandleChangeMultipleSelect("task_type", e)
                                  }
                                  onBlur={validationType.handleBlur}
                                  value={jsonObject?.task_type_id || ""}
                                >
                                  <option value="">
                                    {props.t("select_task_type")}
                                  </option>
                                  {taskTypesList.map((item, i) => {
                                    return (
                                      <option value={item.id} key={i}>
                                        {item.name_en}
                                      </option>
                                    )
                                  })}
                                </select>
                                {/* <Select
                              defaultValue={selectedScoreTypes}
                              onChange={e => {
                                const f = e.map((item, index) => {
                                  return item.value
                                })
                                validationType.setFieldValue("score_type", f)
                                setSelectedScoreTypes(e)
                              }}
                              options={scoreTypesList}
                              isMulti={false}
                              menuPortalTarget={document.body}
                              styles={{
                                control: (provided, state) => ({
                                  ...provided,
                                  menuPortal: base => ({
                                    ...base,
                                    zIndex: 9999,
                                  }),
                                  borderColor:
                                    validationType.touched.score_type &&
                                    validationType.errors.score_type
                                      ? "#f46a6a"
                                      : "#ccc",
                                }),
                              }}
                            /> */}
                                {validationType.touched.task_type &&
                                validationType.errors.task_type ? (
                                  <div
                                    style={{
                                      color: "#f46a6a",
                                      fontSize: "11px",
                                      marginTop: "3px",
                                    }}
                                  >
                                    {validationType.errors.task_type}
                                  </div>
                                ) : null}
                              </div>
                            </Col>
                          )}

                          <Col sm={12}>
                            <div className="mb-3">
                              <span className="caret">
                                <div className="form-check">
                                  <input
                                    className="form-check-input"
                                    type="checkbox"
                                    name="can_other_join_activity"
                                    onChange={onHandleChange}
                                  />
                                  <Label className="form-label">
                                    {props.t("can_other_join_activity")}
                                  </Label>
                                </div>
                              </span>
                            </div>
                          </Col>
                        </Row>
                      )}

                      <Row>
                        <Col sm={12}>
                          <div className="mb-3">
                            <Label className="form-label">
                              {props.t("score_type")} (
                              {props.t("score_type_note")}){" "}
                            </Label>
                            <select
                              name="score_type"
                              type="select"
                              className="form-control"
                              onChange={e =>
                                onHandleChangeMultipleSelect("score_type", e)
                              }
                              onBlur={validationType.handleBlur}
                              value={jsonObject?.score_type_id || ""}
                            >
                              <option value="">
                                {props.t("select_score_type")}
                              </option>
                              {scoreTypesList.map((item, i) => {
                                return (
                                  <option value={item.id} key={i}>
                                    {item.name_en}
                                  </option>
                                )
                              })}
                            </select>
                            {validationType.touched.score_type &&
                            validationType.errors.score_type ? (
                              <div
                                style={{
                                  color: "#f46a6a",
                                  fontSize: "11px",
                                  marginTop: "3px",
                                }}
                              >
                                {validationType.errors.score_type}
                              </div>
                            ) : null}
                          </div>
                        </Col>
                      </Row>

                      {showEnterScoreforWords && (
                        <Row>
                          <Col sm={6}>
                            <div className="mb-3">
                              <Label className="form-label">
                                {props.t("content_length")} 3000{" "}
                                {props.t("words")}
                              </Label>
                              <Input
                                name="content_length_3000"
                                placeholder={props.t("enter_score")}
                                type="text"
                                onChange={onHandleChange}
                              />
                            </div>
                          </Col>

                          <Col sm={6}>
                            <div className="mb-3">
                              <Label className="form-label">
                                {props.t("content_length")} 5000{" "}
                                {props.t("words")}
                              </Label>
                              <Input
                                name="content_length_5000"
                                placeholder={props.t("enter_score")}
                                type="text"
                                onChange={onHandleChange}
                              />
                            </div>
                          </Col>
                        </Row>
                      )}

                      {showEnterScoreforAdvise && (
                        <Row>
                          <Col sm={6}>
                            <div className="mb-3">
                              <Label className="form-label">
                                {props.t("score_for_asking_advice")}
                              </Label>
                              <Input
                                name="score_for_asking_advice"
                                placeholder={props.t("enter_score")}
                                type="text"
                                onChange={onHandleChange}
                              />
                              {validationType.touched.score_for_asking_advice &&
                              validationType.errors.score_for_asking_advice ? (
                                <FormFeedback type="invalid">
                                  {
                                    validationType.errors
                                      .score_for_asking_advice
                                  }
                                </FormFeedback>
                              ) : null}
                            </div>
                          </Col>

                          <Col sm={6}>
                            <div className="mb-3">
                              <Label className="form-label">
                                {props.t("score_for_giving_advice")}
                              </Label>
                              <Input
                                name="score_for_giving_advice"
                                placeholder={props.t("enter_score")}
                                type="text"
                                onChange={onHandleChange}
                              />
                              {validationType.touched.score_for_giving_advice &&
                              validationType.errors.score_for_giving_advice ? (
                                <FormFeedback type="invalid">
                                  {
                                    validationType.errors
                                      .score_for_giving_advice
                                  }
                                </FormFeedback>
                              ) : null}
                            </div>
                          </Col>
                        </Row>
                      )}

                      {showFourLevelScores && (
                        <Row>
                          <Col sm={6}>
                            <div className="mb-3">
                              <Label className="form-label">
                                {props.t("scoreForTeacher")}
                              </Label>
                              <Input
                                name="score_for_teacher"
                                placeholder={props.t("enter_score")}
                                type="text"
                                onChange={onHandleChange}
                              />
                              {validationType.touched.score_for_teacher &&
                              validationType.errors.score_for_teacher ? (
                                <FormFeedback type="invalid">
                                  {validationType.errors.score_for_teacher}
                                </FormFeedback>
                              ) : null}
                            </div>
                          </Col>

                          <Col sm={6}>
                            <div className="mb-3">
                              <Label className="form-label">
                                {props.t("scoreForIntern")}
                              </Label>
                              <Input
                                name="score_for_intern"
                                placeholder={props.t("enter_score")}
                                type="text"
                                onChange={onHandleChange}
                              />
                              {validationType.touched.score_for_intern &&
                              validationType.errors.score_for_intern ? (
                                <FormFeedback type="invalid">
                                  {validationType.errors.score_for_intern}
                                </FormFeedback>
                              ) : null}
                            </div>
                          </Col>
                        </Row>
                      )}

                      {showEnterScoreforAttachment && (
                        <Row>
                          <Col sm={6}>
                            <div className="mb-3">
                              <Label className="form-label">
                                {props.t("score_per_attachement")}
                              </Label>
                              <Input
                                name="score_per_attachement"
                                placeholder={props.t("score_per_attachement")}
                                type="text"
                                onChange={onHandleChange}
                              />
                            </div>
                          </Col>

                          {/* <Col sm={6}>
                          <div className="mb-3">
                            <Label className="form-label">
                              {props.t("certificate_files")}
                            </Label>
                            <Input
                              name="certificate_files"
                              placeholder={props.t("enter_score")}
                              type="text"
                              onChange={onHandleChange}
                            />
                          </div>
                        </Col> */}
                        </Row>
                      )}

                      {showEnterScoreToJoiningActivity && (
                        <Row>
                          <Col sm={6}>
                            <div className="mb-3">
                              <Label className="form-label">
                                {props.t("score_to_speakerHr")}
                              </Label>
                              <Input
                                name="score_to_speakerHr"
                                placeholder={props.t("enter_score")}
                                type="text"
                                onChange={onHandleChange}
                              />
                            </div>
                          </Col>

                          <Col sm={6}>
                            <div className="mb-3">
                              <Label className="form-label">
                                {props.t("score_to_audienceHr")}
                              </Label>
                              <Input
                                name="score_to_audienceHr"
                                placeholder={props.t("enter_score")}
                                type="text"
                                onChange={onHandleChange}
                              />
                            </div>
                          </Col>
                        </Row>
                      )}

                      {showEnterScoreOnTaskComplete && (
                        <Row>
                          <Col sm={6}>
                            <div className="mb-3">
                              <Label className="form-label">
                                {props.t("enter_score")}
                              </Label>
                              <Input
                                name="score_for_task"
                                placeholder={props.t("enter_score")}
                                type="text"
                                onChange={onHandleChange}
                              />
                            </div>
                          </Col>
                        </Row>
                      )}

                      <div className="d-flex flex-wrap gap-2">
                        <Button type="submit" color="primary">
                          {props.t("Submit")}
                        </Button>{" "}
                        <Button color="secondary" onClick={e => formClear()}>
                          {props.t("Clear")}
                        </Button>
                      </div>
                    </Form>
                  </Col>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withTranslation()(AddCustomActivityTypes)
