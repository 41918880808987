import React, { useEffect, useState } from "react"
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Form,
  FormFeedback,
  Input,
  Label,
  Row,
} from "reactstrap"
// Formik validation
import { useFormik } from "formik"
import * as Yup from "yup"

import PropTypes from "prop-types"
import toastr from "toastr"
import "toastr/build/toastr.min.css"

import { useHistory } from "react-router-dom"
//Import Breadcrumb
import config from "config/config"
import { withTranslation } from "react-i18next"
import Swal from "sweetalert2"
import Breadcrumbs from "../../components/Common/Breadcrumb"

import { OfferApi } from "apis/OfferApi"
import { OfferTypeApi } from "apis/OfferTypeApi"

const AddOffer = props => {
  const [files1, setFiles1] = useState([])

  const history = useHistory()
  const [offerTypeList, setOfferTypeList] = useState([])
  const [loading, setLoading] = useState(false)
  const [isFormSubmited, setIsFormSubmited] = useState(false)

  const [activeData, setActiveData] = useState({
    page: 1,
    totalPage: 1,
    search: "",
    limit: config.LIMIT,
  })

  const getAllOfferTypes = data => {
    setLoading(true)
    OfferTypeApi.getAllOfferTypes(data)
      .then(res => {
        setActiveData({
          page: activeData.page,
          totalPage: res?.data?.data?.count,
          search: activeData.search,
          limit: activeData.limit,
        })
        setOfferTypeList(res?.data?.data?.rows)
        setLoading(false)
      })
      .catch(err => {
        console.log(err)
      })
  }

  useEffect(() => {
    getAllOfferTypes(activeData)
  }, [])

  // Form validation
  const validationType = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      name_en: "",
      name_th: "",
      terms: "",
      start_date: "",
      end_date: "",
      description_en: "",
      description_th: "",
      image_url: "",
      image_link: "",
      offer_type_id: "",
    },
    validationSchema: Yup.object().shape({
      name_en: Yup.string().required(props.t("Required-Message")),
      name_th: Yup.string().required(props.t("Required-Message")),
      description_en: Yup.string().required(props.t("Required-Message")),
      description_th: Yup.string().required(props.t("Required-Message")),
      start_date: Yup.date().required(props.t("Required-Message")),
      end_date: Yup.date().required(props.t("Required-Message")),
      image_url: Yup.string().required(props.t("Required-Message")),
      image_link: Yup.string().required(props.t("Required-Message")),
      offer_type_id: Yup.string().required(props.t("Required-Message")),
    }),
    onSubmit: values => {
      const data = new FormData()
      data.append("image_url", files1)
      data.append("name_en", values.name_en)
      data.append("name_th", values.name_th)
      data.append("description_en", values.description_en)
      data.append("description_th", values.description_th)
      data.append("start_date", values.start_date)
      data.append("end_date", values.end_date)
      data.append("offer_type_id", values.offer_type_id)
      data.append("image_link", values.image_link)
      OfferApi.createOffer(data)
        .then(res => {
          if (res.data.success) {
            Swal.fire({
              text: res.data.message,
              icon: "success",
              imageAlt: "success image",
            }).then(result => {
              if (result?.isConfirmed) {
                history.push("/offers")
                formClear()
                setFiles1()
              }
            })
          } else {
            toastr.error(res?.data?.message, "")
          }
        })
        .catch(function (error) {
          if (error?.response?.data?.message) {
            toastr.error(error?.response?.data?.message, "")
          } else {
            toastr.error(
              error?.response?.data?.error?.errors?.map(
                (item, v) => `${item.msg}<br>`
              ),
              ""
            )
          }
        })
    },
  })

  useEffect(() => {
    if (isFormSubmited) {
      const { errors } = validationType
      const keys = Object.keys(errors)
      if (keys.length > 0) {
        const errorElement = document.getElementById(keys[0])
        errorElement.focus()
        setIsFormSubmited(false)
      }
    }
  }, [isFormSubmited, validationType])

  const formClear = () => {
    validationType.resetForm()
    setFiles1([])
  }

  document.title = props.t("Offers") + " | " + props.t("MedX")

  return (
    <React.Fragment>
      <div className="page-content">
        <Breadcrumbs
          title={props.t("Offers")}
          breadcrumbItem={props.t("Add") + " " + props.t("Offer")}
        />
        <Container fluid={true}>
          <Row>
            <Col lg={12}>
              <Card>
                <CardBody>
                  <Col sm={12}>
                    <Form
                      onSubmit={e => {
                        e.preventDefault()
                        validationType.handleSubmit()
                        setIsFormSubmited(true)
                        return false
                      }}
                    >
                      <Col sm={12}>
                        <div className="mb-3">
                          <Label className="form-label">
                            {props.t("Name_Eng")}
                          </Label>
                          <Input
                            name="name_en"
                            id="name_en"
                            placeholder={props.t("Name_Eng")}
                            type="text"
                            onChange={validationType.handleChange}
                            onBlur={validationType.handleBlur}
                            value={validationType.values.name_en || ""}
                            invalid={
                              validationType.touched.name_en &&
                              validationType.errors.name_en
                                ? true
                                : false
                            }
                          />
                          {validationType.touched.name_en &&
                          validationType.errors.name_en ? (
                            <FormFeedback type="invalid">
                              {validationType.errors.name_en}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </Col>
                      <Col sm={12}>
                        <div className="mb-3">
                          <Label className="form-label">
                            {props.t("Name_Thai")}
                          </Label>
                          <Input
                            name="name_th"
                            id="name_th"
                            placeholder={props.t("Name_Thai")}
                            type="text"
                            onChange={validationType.handleChange}
                            onBlur={validationType.handleBlur}
                            value={validationType.values.name_th || ""}
                            invalid={
                              validationType.touched.name_th &&
                              validationType.errors.name_th
                                ? true
                                : false
                            }
                          />
                          {validationType.touched.name_th &&
                          validationType.errors.name_th ? (
                            <FormFeedback type="invalid">
                              {validationType.errors.name_th}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </Col>

                      <Col sm={12}>
                        <div className="mb-3">
                          <Label className="form-label">
                            {props.t("Terms_Condition_Eng")}
                          </Label>
                          <Input
                            name="description_en"
                            id="description_en"
                            placeholder={props.t("Terms_Condition_Eng")}
                            type="textarea"
                            onChange={validationType.handleChange}
                            onBlur={validationType.handleBlur}
                            value={validationType.values.description_en || ""}
                            invalid={
                              validationType.touched.description_en &&
                              validationType.errors.description_en
                                ? true
                                : false
                            }
                          />
                          {validationType.touched.description_en &&
                          validationType.errors.description_en ? (
                            <FormFeedback type="invalid">
                              {validationType.errors.description_en}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </Col>
                      <Col sm={12}>
                        <div className="mb-3">
                          <Label className="form-label">
                            {props.t("Terms_Condition_Thai")}
                          </Label>
                          <Input
                            name="description_th"
                            id="description_th"
                            placeholder={props.t("Terms_Condition_Thai")}
                            type="textarea"
                            onChange={validationType.handleChange}
                            onBlur={validationType.handleBlur}
                            value={validationType.values.description_th || ""}
                            invalid={
                              validationType.touched.description_th &&
                              validationType.errors.description_th
                                ? true
                                : false
                            }
                          />
                          {validationType.touched.description_th &&
                          validationType.errors.description_th ? (
                            <FormFeedback type="invalid">
                              {validationType.errors.description_th}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </Col>
                      <Col sm={12}>
                        <div className="mb-3">
                          <Label className="form-label">
                            {props.t("Start_Date")}
                          </Label>
                          <Input
                            name="start_date"
                            id="start_date"
                            placeholder={props.t("Start_Date")}
                            type="date"
                            onChange={validationType.handleChange}
                            onBlur={validationType.handleBlur}
                            value={validationType.values.start_date || ""}
                            invalid={
                              validationType.touched.start_date &&
                              validationType.errors.start_date
                                ? true
                                : false
                            }
                          />
                          {validationType.touched.start_date &&
                          validationType.errors.start_date ? (
                            <FormFeedback type="invalid">
                              {validationType.errors.start_date}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </Col>
                      <Col sm={12}>
                        <div className="mb-3">
                          <Label className="form-label">
                            {props.t("End_Date")}
                          </Label>
                          <Input
                            name="end_date"
                            id="end_date"
                            placeholder={props.t("End_Date")}
                            type="date"
                            onChange={validationType.handleChange}
                            onBlur={validationType.handleBlur}
                            value={validationType.values.end_date || ""}
                            invalid={
                              validationType.touched.end_date &&
                              validationType.errors.end_date
                                ? true
                                : false
                            }
                          />
                          {validationType.touched.end_date &&
                          validationType.errors.end_date ? (
                            <FormFeedback type="invalid">
                              {validationType.errors.end_date}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </Col>
                      <Col sm={12}>
                        <div className="mb-3">
                          <Label htmlFor="validationCustom01"></Label>
                          <Input
                            name="image_url"
                            id="image_url"
                            type="file"
                            className="form-control"
                            onChange={e => {
                              setFiles1(e.target.files[0])
                              validationType.setFieldValue(
                                "image_url",
                                e.target.files[0]
                              )
                            }}
                            invalid={
                              validationType.touched.image_url &&
                              validationType.errors.image_url
                                ? true
                                : false
                            }
                          />
                          {validationType.touched.image_url &&
                          validationType.errors.image_url ? (
                            <div
                              style={{
                                color: "#f46a6a",
                                fontSize: "11px",
                                marginTop: "3px",
                              }}
                            >
                              {validationType.errors.image_url}
                            </div>
                          ) : null}
                        </div>
                      </Col>

                      <Col sm={12}>
                        <div className="mb-3">
                          <Label className="form-label">
                            {props.t("imageLink")}
                          </Label>
                          <Input
                            name="image_link"
                            id="image_link"
                            placeholder={props.t("imageLink")}
                            type="text"
                            onChange={validationType.handleChange}
                            onBlur={validationType.handleBlur}
                            value={validationType.values.image_link || ""}
                            invalid={
                              validationType.touched.image_link &&
                              validationType.errors.image_link
                                ? true
                                : false
                            }
                          />
                          {validationType.touched.image_link &&
                          validationType.errors.image_link ? (
                            <FormFeedback type="invalid">
                              {validationType.errors.image_link}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </Col>

                      <Col sm={12}>
                        <div className="mb-3">
                          <Label className="form-label">
                            {props.t("Offer_Type")}
                          </Label>
                          <div className="dropdown-arrow">
                            <select
                              name="offer_type_id"
                              id="offer_type_id"
                              type="select"
                              className="form-control"
                              onChange={validationType.handleChange}
                              onBlur={validationType.handleBlur}
                              value={validationType.values.offer_type_id || ""}
                            >
                              <option value="">
                                {props.t("Please_Select_Type")}
                              </option>
                              {offerTypeList
                                ? offerTypeList?.map((item, index) => {
                                    return (
                                      <option value={item.id} key={index}>
                                        {item?.name_en}
                                      </option>
                                    )
                                  })
                                : ""}
                            </select>
                          </div>

                          {validationType.touched.offer_type_id &&
                          validationType.errors.offer_type_id ? (
                            <div
                              style={{
                                color: "#f46a6a",
                                fontSize: "11px",
                                marginTop: "3px",
                              }}
                            >
                              {validationType.errors.offer_type_id}
                            </div>
                          ) : null}
                        </div>
                      </Col>

                      <div className="d-flex flex-wrap gap-2">
                        <Button type="submit" color="primary">
                          {props.t("Submit")}
                        </Button>{" "}
                        <Button color="secondary" onClick={e => formClear()}>
                          {props.t("Clear")}
                        </Button>
                      </div>
                    </Form>
                  </Col>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

AddOffer.propTypes = {
  t: PropTypes.any,
}

export default withTranslation()(AddOffer)
