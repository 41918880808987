import React, { useEffect, useState } from "react";
import {
    Row,
    Col,
    Card,
    CardBody,
    FormGroup,
    Button,
    CardTitle,
    CardSubtitle,
    Label,
    Input,
    Container,
    FormFeedback,
    Form,
} from "reactstrap";
// Formik validation
import * as Yup from "yup";
import { useFormik } from "formik";
import toastr from "toastr";
import "toastr/build/toastr.min.css";
import PropTypes from 'prop-types';
// import { RoleApi } from "../../apis/RoleApi";
// import { RoleModuleApi } from "../../apis/RoleModuleApi";
import { useHistory } from "react-router-dom"
import Swal from "sweetalert2";
import { withTranslation } from "react-i18next";
// import i18n from "../../i18n";
import config from "config/config";
import { CompaniesApi } from "apis/CompaniesApi";
import Breadcrumb from "components/Common/Breadcrumb";
import constants from "../../../config/constants";
import { CategoryApi } from "apis/CategoryApi"

const EditCompany = props => {
    const company_id = props.match.params.company_id;
    const [files1, setFiles1] = useState([]);
    const [company, setCompany] = useState('')
    const [categoryList, setCategoryList] = useState([])
    const [defualtCategory, setdefualtCategory] = useState({});
    const [isFormSubmited, setIsFormSubmited] = useState(false)
    const history = useHistory();
    const [loading, setLoading] = useState(false);

    const [activeData, setActiveData] = useState({
        page: 1,
        totalPage: 1,
        search: "",
        limit: config.LIMIT
    });

    const getCompanyById = () => {
        CompaniesApi.getCompanyById({company_id})
          .then(res => {
            setCompany(res.data.data);
            setdefualtCategory({
                value: res.data.data?.Category?.id,
                label: res.data.data.Category?.name_en,
            })
          }).catch(err => {
            console.log(err)
        })
    }

    const getAllCategories = data => {
        setLoading(true)
        CategoryApi.getAllCategories(data)
          .then(res => {
            setActiveData({
              page: activeData.page,
              totalPage: res?.data?.data?.count,
              search: activeData.search,
              limit: activeData.limit,
            })
            setCategoryList(res?.data?.data?.rows)
            setLoading(false)
          })
          .catch(err => {
            console.log(err)
        })
    }
      

    useEffect(() => {
        getCompanyById();
        getAllCategories({...activeData, type : "COMPANY"})
    }, []);

    // Form validation 
    const validationType = useFormik({
        // enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: true,

        initialValues: {
            category_id: company.category_id ?? '',
            name_en: company.name_en  ?? '',
            name_th: company.name_th ?? '',
            email: company.email ?? '',
            phone_no: company.phone_no ?? '',
            other_email: company.other_email ?? '',
            other_phone_no: company.other_phone_no ?? '',
            description_en: company.description_en ?? '',
            description_th: company.description_th ?? '',
            website: company.website ?? '',
            facebook_url: company.facebook_url ?? '',
            insta_url: company.insta_url ?? '',
            line_url: company.line_url ?? '',
            logo_url: company.logo_url ?? '',
            is_microsite : company.is_microsite ?? '',
            address: company?.address ?? ''
        },
        validationSchema: Yup.object().shape({
            name_en: Yup.string().required(
                props.t("Required-Message")
            ),
            name_th: Yup.string().required(
                props.t("Required-Message")
            ),
            email: Yup.string().email().required(
                props.t("Required-Message")
            ),
            other_email: Yup.string().required(
                props.t("Required-Message")
            ),
            phone_no: Yup.string().required(
                props.t("Required-Message")
            ),
            other_phone_no: Yup.string().required(
                props.t("Required-Message")
            ),
            category_id: Yup.string().required(
                props.t("Required-Message")
            ),
            description_en: Yup.string().required(
                props.t("Required-Message")
            ),
            description_th: Yup.string().required(
                props.t("Required-Message")
            ),
            logo_url: Yup.mixed().required(
                props.t("File-Required-Message")
            )           
        }),
        onSubmit: (values) => {
            const data = new FormData();
            data.append('category_id', values.category_id);
            data.append('name_en', values.name_en);
            data.append('name_th', values.name_th);
            data.append('email', values.email);
            data.append('phone_no', values.phone_no);
            data.append('other_email', values.other_email);
            data.append('other_phone_no', values.other_phone_no);
            data.append('description_en', values.description_en);
            data.append('description_th', values.description_th);
            data.append('website', values.website);
            data.append('facebook_url', values.facebook_url);
            data.append('insta_url', values.insta_url);
            data.append('line_url', values.line_url);
            data.append('is_microsite', values.is_microsite);
            data.append('logo_url', files1);
            data.append('company_id', company_id);
            data.append('address', values.address);
            CompaniesApi.updateCompany(data)
                .then(res => {
                    if (res.data.success) {
                        Swal.fire({
                            text: res.data.message,
                            icon: 'success',
                            imageAlt: 'success image',
                        }).then((result) => {
                            if (result?.isConfirmed) {
                                history.push('/companies');
                                formClear()
                                setFiles1()
                            }
                        });
                    } else {
                        toastr.error(res?.data?.message, '');
                    }
                }).catch(function (error) {
                    if (error?.response?.data?.message) {
                        toastr.error(error?.response?.data?.message, "")
                       } else {
                        error.response.data.error.errors?.map((erritem, errindex) => {
                          if (erritem.param == "email") {
                            validationType.setErrors({ email: `${erritem.msg}` });
                          }
                          if (erritem.param == "phone_no") {
                            validationType.setErrors({ phone_no: `${erritem.msg}` });
                          }
                        });
                      }

                    // if (error?.response?.data?.message) {
                    //     toastr.error(error?.response?.data?.message, '');
                    // }
                    // else {
                    //     toastr.error(error?.response?.data?.error?.errors?.map((item, v) => `${item.msg}<br>`), '');
                    // }
                });
        }
    });
    
    const formClear = () => {
        validationType.resetForm();
        setFiles1([])
    };

    useEffect(() => {
        if(isFormSubmited){
          const { errors } = validationType;
          const keys = Object.keys(errors);
          if (keys.length > 0) {
            const errorElement = document.getElementById(keys[0]);
            errorElement.focus();
            setIsFormSubmited(false);
          }
        }
      },[isFormSubmited, validationType])
    
    document.title = props.t("Companies") + ' | ' + props.t("MedX");

    return (
        <React.Fragment>
            <div className="page-content">
                <Breadcrumb title={props.t("Company")} breadcrumbItem={props.t("Edit") + " " + props.t("Company")} />
                <Container fluid={true}>
                    <Row>

                        <Col lg={12}>
                            <Card>
                                <CardBody >
                                    <Col sm={12}>
                                        <Form
                                            onSubmit={(e) => {
                                                e.preventDefault();
                                                validationType.handleSubmit();
                                                setIsFormSubmited(true)
                                                return false;
                                            }}>
                                            <Col sm={12}>
                                                <div className="mb-3">
                                                    <Label className="form-label">{props.t("Name_Eng")}
                                                        {" "} <span className="text-danger">*</span>
                                                    </Label>
                                                    <Input
                                                        name="name_en"
                                                        id="name_en"
                                                        placeholder={props.t("Name_Eng")}
                                                        type="text"
                                                        onChange={validationType.handleChange}
                                                        onBlur={validationType.handleBlur}
                                                        value={validationType.values.name_en || ""}
                                                        invalid={
                                                            validationType.touched.name_en && validationType.errors.name_en ? true : false
                                                        }
                                                    />
                                                    {validationType.touched.name_en && validationType.errors.name_en ? (
                                                        <FormFeedback type="invalid">{validationType.errors.name_en}</FormFeedback>
                                                    ) : null}
                                                </div>
                                            </Col>
                                            <Col sm={12}>
                                                <div className="mb-3">
                                                    <Label className="form-label">{props.t("Name_Thai")}
                                                        {" "} <span className="text-danger">*</span>
                                                    </Label>
                                                    <Input
                                                        name="name_th"
                                                        id="name_th"
                                                        placeholder={props.t("Name_Thai")}
                                                        type="text"
                                                        onChange={validationType.handleChange}
                                                        onBlur={validationType.handleBlur}
                                                        value={validationType.values.name_th || ""}
                                                        invalid={
                                                            validationType.touched.name_th && validationType.errors.name_th ? true : false
                                                        }
                                                    />
                                                    {validationType.touched.name_th && validationType.errors.name_th ? (
                                                        <FormFeedback type="invalid">{validationType.errors.name_th}</FormFeedback>
                                                    ) : null}
                                                </div>
                                            </Col>
                                            <Col sm={12}>
                                                <div className="mb-3">
                                                    <Label className="form-label">{props.t("Email")}
                                                        {" "} <span className="text-danger">*</span>
                                                    </Label>
                                                    <Input
                                                        name="email"
                                                        id="email"
                                                        placeholder={props.t("Enter_Email)")}
                                                        type="text"
                                                        onChange={validationType.handleChange}
                                                        onBlur={validationType.handleBlur}
                                                        value={validationType.values.email || ""}
                                                        invalid={
                                                            validationType.touched.email && validationType.errors.email ? true : false
                                                        }
                                                    />
                                                    {validationType.touched.email && validationType.errors.email ? (
                                                        <FormFeedback type="invalid">{validationType.errors.email}</FormFeedback>
                                                    ) : null}
                                                </div>
                                            </Col>
                                            <Col sm={12}>
                                                <div className="mb-3">
                                                    <Label className="form-label">{props.t("Other_Email")}
                                                        {" "} <span className="text-danger">*</span>
                                                    </Label>
                                                    <Input
                                                        name="other_email"
                                                        id="other_email"
                                                        placeholder={props.t("Enter_Email")}
                                                        type="text"
                                                        onChange={validationType.handleChange}
                                                        onBlur={validationType.handleBlur}
                                                        value={validationType.values.other_email || ""}
                                                        invalid={
                                                            validationType.touched.other_email && validationType.errors.other_email ? true : false
                                                        }
                                                    />
                                                    {validationType.touched.other_email && validationType.errors.other_email ? (
                                                        <FormFeedback type="invalid">{validationType.errors.other_email}</FormFeedback>
                                                    ) : null}
                                                </div>
                                            </Col>

                                            

                                            <Col sm={12}>
                                                <div className="mb-3">
                                                    <Label className="form-label">{props.t("Phone_No")}
                                                        {" "} <span className="text-danger">*</span>
                                                    </Label>
                                                    <Input
                                                        name="phone_no"
                                                        id="phone_no"
                                                        placeholder={props.t("Enter_Phone_No")}
                                                        type="text"
                                                        onChange={validationType.handleChange}
                                                        onBlur={validationType.handleBlur}
                                                        value={validationType.values.phone_no || ""}
                                                        invalid={
                                                            validationType.touched.phone_no && validationType.errors.phone_no ? true : false
                                                        }
                                                    />
                                                    {validationType.touched.phone_no && validationType.errors.phone_no ? (
                                                        <FormFeedback type="invalid">{validationType.errors.phone_no}</FormFeedback>
                                                    ) : null}
                                                </div>
                                            </Col>


                                            <Col sm={12}>
                                                <div className="mb-3">
                                                    <Label className="form-label">{props.t("Other_Phone_No")}
                                                        {" "} <span className="text-danger">*</span>
                                                    </Label>
                                                    <Input
                                                        name="other_phone_no"
                                                        id="other_phone_no"
                                                        placeholder={props.t("Enter_Phone_No")}
                                                        type="text"
                                                        onChange={validationType.handleChange}
                                                        onBlur={validationType.handleBlur}
                                                        value={validationType.values.other_phone_no || ""}
                                                        invalid={
                                                            validationType.touched.other_phone_no && validationType.errors.other_phone_no ? true : false
                                                        }
                                                    />
                                                    {validationType.touched.other_phone_no && validationType.errors.other_phone_no ? (
                                                        <FormFeedback type="invalid">{validationType.errors.other_phone_no}</FormFeedback>
                                                    ) : null}
                                                </div>
                                            </Col>

                                            <Col sm={12}>
                                                <div className="mb-3">
                                                    <Label htmlFor="" className="form-label">
                                                        {props.t("Topics")}  <span className="text-danger">*</span>
                                                    </Label>
                                                    <select
                                                        onChange={validationType.handleChange}
                                                        value={validationType.values.category_id}
                                                        className={
                                                        validationType.touched.category_id &&
                                                            validationType.errors.category_id
                                                            ? "form-select border border-danger"
                                                            : "form-select"
                                                        }
                                                        name="category_id"
                                                        id="category_id"
                                                    >
                                                        <option value="">
                                                            {props.t("Please_Select_Topic")}
                                                        </option>
                                                        {categoryList
                                                            ? categoryList?.map((item, index) => {
                                                                return (
                                                                <option value={item.id} key={index}>
                                                                    {item?.name_en}
                                                                </option>
                                                                )
                                                            })
                                                        : ""}
                                                    </select>
                                                    {validationType.touched.category_id &&
                                                        validationType.errors.category_id ? (
                                                            <p className="text-danger">
                                                                {validationType.errors.category_id}
                                                            </p>
                                                    ) : null}
                                                </div>
                                            </Col>
                                            <Col sm={12}>
                                                <div className="mb-3">
                                                    <Label className="form-label">{props.t("Description_En")}
                                                        {" "} <span className="text-danger">*</span>
                                                    </Label>
                                                    <Input
                                                        name="description_en"
                                                        id="description_en"
                                                        placeholder={props.t("Description_En")}
                                                        type="textarea"
                                                        onChange={validationType.handleChange}
                                                        onBlur={validationType.handleBlur}
                                                        value={validationType.values.description_en || ""}
                                                        invalid={
                                                            validationType.touched.description_en && validationType.errors.description_en ? true : false
                                                        }
                                                    />
                                                    {validationType.touched.description_en && validationType.errors.description_en ? (
                                                        <FormFeedback type="invalid">{validationType.errors.description_en}</FormFeedback>
                                                    ) : null}
                                                </div>
                                            </Col>
                                            <Col sm={12}>
                                                <div className="mb-3">
                                                    <Label className="form-label">{props.t("Description_Th")}
                                                        {" "} <span className="text-danger">*</span>
                                                    </Label>
                                                    <Input
                                                        name="description_th"
                                                        id="description_th"
                                                        placeholder={props.t("Description_Th")}
                                                        type="textarea"
                                                        onChange={validationType.handleChange}
                                                        onBlur={validationType.handleBlur}
                                                        value={validationType.values.description_th || ""}
                                                        invalid={
                                                            validationType.touched.description_th && validationType.errors.description_th ? true : false
                                                        }
                                                    />
                                                    {validationType.touched.description_th && validationType.errors.description_th ? (
                                                        <FormFeedback type="invalid">{validationType.errors.description_th}</FormFeedback>
                                                    ) : null}
                                                </div>
                                            </Col>

                                            <Col sm={12}>
                                                <div className="mb-3">
                                                <Label htmlFor="logo">{props.t("Logo")}
                                                    {" "} <span className="text-danger">*</span>
                                                </Label>
                                                    <Input
                                                        name="logo_url"
                                                        id="logo_url"
                                                        type="file"
                                                        className="form-control"
                                                        onChange={(e) => {
                                                            setFiles1(e.target.files[0]);
                                                            validationType.setFieldValue('logo_url', e.target.files[0]);
                                                        }}
                                                        invalid={
                                                            validationType.touched.logo_url &&
                                                                validationType.errors.logo_url
                                                                ? true
                                                                : false
                                                        }
                                                    />
                                                    {validationType.touched.logo_url &&
                                                        validationType.errors.logo_url ? (
                                                        <div style={{ color: '#f46a6a', fontSize: '11px', marginTop: '3px' }}>
                                                            {validationType.errors.logo_url}
                                                        </div>
                                                    ) : null}
                                                    
                                                </div>
                                            </Col>

                                            <Col sm={12}>
                                                <div className="mb-3">
                                                    <Label className="form-label">{props.t("Website")}</Label>
                                                    <Input
                                                        name="website"
                                                        id="website"
                                                        placeholder={props.t("Website")}
                                                        type="text"
                                                        onChange={validationType.handleChange}
                                                        onBlur={validationType.handleBlur}
                                                        value={validationType.values.website || ""}
                                                        invalid={
                                                            validationType.touched.website && validationType.errors.website ? true : false
                                                        }
                                                    />
                                                    {validationType.touched.website && validationType.errors.website ? (
                                                        <FormFeedback type="invalid">{validationType.errors.website}</FormFeedback>
                                                    ) : null}
                                                </div>
                                            </Col>

                                            <Col sm={12}>
                                                <div className="mb-3">
                                                    <Label className="form-label">{props.t("FacebookUrl")}</Label>
                                                    <Input
                                                        name="facebook_url"
                                                        placeholder={props.t("FacebookUrl")}
                                                        type="text"
                                                        onChange={validationType.handleChange}
                                                        onBlur={validationType.handleBlur}
                                                        value={validationType.values.facebook_url || ""}
                                                        invalid={
                                                            validationType.touched.facebook_url && validationType.errors.facebook_url ? true : false
                                                        }
                                                    />
                                                    {validationType.touched.facebook_url && validationType.errors.facebook_url ? (
                                                        <FormFeedback type="invalid">{validationType.errors.facebook_url}</FormFeedback>
                                                    ) : null}
                                                </div>
                                            </Col>

                                            <Col sm={12}>
                                                <div className="mb-3">
                                                    <Label className="form-label">{props.t("InstaUrl")}</Label>
                                                    <Input
                                                        name="insta_url"
                                                        placeholder={props.t("InstaUrl")}
                                                        type="text"
                                                        onChange={validationType.handleChange}
                                                        onBlur={validationType.handleBlur}
                                                        value={validationType.values.insta_url || ""}
                                                        invalid={
                                                            validationType.touched.insta_url && validationType.errors.insta_url ? true : false
                                                        }
                                                    />
                                                    {validationType.touched.insta_url && validationType.errors.insta_url ? (
                                                        <FormFeedback type="invalid">{validationType.errors.insta_url}</FormFeedback>
                                                    ) : null}
                                                </div>
                                            </Col>

                                            <Col sm={12}>
                                                <div className="mb-3">
                                                    <Label className="form-label">{props.t("LineUrl")}</Label>
                                                    <Input
                                                        name="line_url"
                                                        placeholder={props.t("LineUrl")}
                                                        type="text"
                                                        onChange={validationType.handleChange}
                                                        onBlur={validationType.handleBlur}
                                                        value={validationType.values.line_url || ""}
                                                        invalid={
                                                            validationType.touched.line_url && validationType.errors.line_url ? true : false
                                                        }
                                                    />
                                                    {validationType.touched.line_url && validationType.errors.line_url ? (
                                                        <FormFeedback type="invalid">{validationType.errors.line_url}</FormFeedback>
                                                    ) : null}
                                                </div>
                                            </Col>

                                            <Col sm={12}>
                                                <div className="mb-3">
                                                    <Label className="form-label">{props.t("Address")}
                                                    </Label>
                                                    <Input
                                                        name="address"
                                                        placeholder={props.t("Address")}
                                                        type="textarea"
                                                        onChange={validationType.handleChange}
                                                        onBlur={validationType.handleBlur}
                                                        value={validationType.values.address || ""}
                                                        invalid={
                                                            validationType.touched.address && validationType.errors.address ? true : false
                                                        }
                                                    />
                                                </div>
                                            </Col>

                                            <div className="d-flex flex-wrap gap-2">
                                                <Button type="submit" color="primary" >
                                                    {props.t("Submit")}
                                                </Button>{" "}
                                                <Button color="secondary" onClick={e => formClear()}>
                                                    {props.t("Clear")}
                                                </Button>
                                            </div>
                                        </Form>
                                    </Col>

                                </CardBody>
                            </Card>
                        </Col>

                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
};


EditCompany.propTypes = {
    t: PropTypes.any,
};

export default withTranslation()(EditCompany);