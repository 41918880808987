import React, { useEffect, useState } from "react"
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Form,
  FormFeedback,
  Input,
  Label,
  Row,
} from "reactstrap"
// Formik validation
import { useFormik } from "formik"
import * as Yup from "yup"

import PropTypes from "prop-types"
import toastr from "toastr"
import "toastr/build/toastr.min.css"
// import { RoleApi } from "../../apis/RoleApi";
// import { RoleModuleApi } from "../../apis/RoleModuleApi";
import { useHistory } from "react-router-dom"
//Import Breadcrumb
// import Breadcrumbs from "../../components/Common/Breadcrumb";
import { withTranslation } from "react-i18next"
import Swal from "sweetalert2"
// import i18n from "../../i18n";
import { CategoryApi } from "apis/CategoryApi"
import { CommunityApi } from "apis/CommunityApi"
import { CompaniesApi } from "apis/CompaniesApi"
import { InterestApi } from "apis/InterestApi"
import Breadcrumb from "components/Common/Breadcrumb"
import config from "config/config"
import Select from "react-select"
import { getRoleID, Token } from "Token"
import constants from "../../config/constants"

// Form Editor
import { ContentState, convertFromHTML, EditorState } from "draft-js"
import { Editor } from "react-draft-wysiwyg"
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css"
import * as url from "../../helpers/common_helper"

const Role = Token.getRole()
const EditCommunity = props => {
  const community_id = props.match.params.community_id
  const [files1, setFiles1] = useState([])
  const [speaker, setSpeaker] = useState("")
  const [community, setcommunity] = useState("")
  const history = useHistory()
  const [interestList, setinterestList] = useState([])
  const [categoryList, setCategoryList] = useState([])
  const [companyList, setCompanyList] = useState([])
  const [loading, setLoading] = useState(false)
  const [selectedOption, setSelectedOption] = useState([])

  const [descriptionEn, setDescriptionEn] = useState(() =>
    EditorState.createEmpty()
  )
  const [descriptionTh, setDescriptionTh] = useState(() =>
    EditorState.createEmpty()
  )
  const [isFormSubmited, setIsFormSubmited] = useState(false)
  const [activeData, setActiveData] = useState({
    page: 1,
    limit: config.LIMIT,
    type: getRoleID() == constants.ROLE.SUPERADMIN ? "INFOCUS" : "NEWS",
  })

  const getAllCommunityTypes = data => {
    setLoading(true)
    InterestApi.getAllInterests(data)
      .then(res => {
        setActiveData({
          page: activeData.page,
          totalPage: res?.data?.data?.count,
          search: activeData.search,
          limit: activeData.limit,
        })
        const x = res?.data?.data?.rows
        const y = x?.map((item, index) => {
          return { label: item.name_en, value: item.id }
        })
        setinterestList(y)
        setLoading(false)
      })
      .catch(err => {
        console.log(err)
      })
  }

  useEffect(() => {
    getAllCommunityTypes(activeData)
  }, [])

  const getAllCategories = data => {
    setLoading(true)
    CategoryApi.getAllCategories(data)
      .then(res => {
        setActiveData({
          page: activeData.page,
          totalPage: res?.data?.data?.count,
          search: activeData.search,
          limit: activeData.limit,
        })
        setCategoryList(res?.data?.data?.rows)
        setLoading(false)
      })
      .catch(err => {
        console.log(err)
      })
  }

  const getAllCompanies = data => {
    setLoading(true)
    CompaniesApi.getAllCompanies(data)
      .then(res => {
        setActiveData({
          page: activeData.page,
          totalPage: res?.data?.data?.count,
          search: activeData.search,
          limit: activeData.limit,
        })
        setCompanyList(res?.data?.data?.rows)
        setLoading(false)
      })
      .catch(err => {
        console.log(err)
      })
  }

  useEffect(() => {
    getAllCompanies(activeData)
  }, [])

  const getCommunityById = () => {
    CommunityApi.getCommunityById({ community_id })
      .then(res => {
        if (res.data.success) {
          const xy = res.data.data.CommunityPostInterest
          const yz = xy.map(item => {
            return { value: item.Interest.id, label: item.Interest.name_en }
          })
          setSelectedOption(yz)
          setcommunity(res.data.data)
          getAllCategories({ ...activeData, type: res.data.data?.type })
          setDescriptionEn(
            EditorState.createWithContent(
              ContentState.createFromBlockArray(
                convertFromHTML(res.data.data.description_en)
              )
            )
          )
          setDescriptionTh(
            EditorState.createWithContent(
              ContentState.createFromBlockArray(
                convertFromHTML(res.data.data.description_th)
              )
            )
          )
        }
      })
      .catch(err => {
        console.log(err)
      })
  }

  useEffect(() => {
    getCommunityById()
    getAllCommunityTypes(activeData)
    setSelectedOption([{ label: "Cardiology", value: 2 }])
  }, [])

  // Form validation
  const validationType = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: {
      type: community.type ?? "",
      name_en: community.name_en ?? "",
      name_th: community.name_th ?? "",
      description_en: community.description_en ?? "",
      description_th: community.description_th ?? "",
      // image_url: community.image_url ??"",
      status:
        community?.status === 1
          ? "True"
          : community?.status === 0
          ? "False"
          : "True",
      source_link: community.source_link ?? "",
      interest_list: community?.CommunityPostInterest?.map((item, index) => {
        return item.Interest.id
      }),
      category_list: community.Category?.id ?? "",
      //company_list: community.Company?.id ?? "",
      community_files: null,
    },
    validationSchema: Yup.object().shape(
      {
        // type: Yup.string().required(props.t("Required-Message")),
        name_th: Yup.string().when("name_en", {
          is: name_en => !name_en || name_en.length === 0,
          then: Yup.string()
            .max(50, props.t("Field_Must_50_Characters"))
            .required(props.t("Required-Message")),
          otherwise: Yup.string(),
        }),
        name_en: Yup.string().when("name_th", {
          is: name_th => !name_th || name_th.length === 0,
          then: Yup.string()
            .max(50, props.t("Field_Must_50_Characters"))
            .required(props.t("Required-Message")),
          otherwise: Yup.string(),
        }),
        // description_en: Yup.string().when("description_th", {
        //   is: description_th => !description_th || description_th.length === 0,
        //   then: Yup.string().required(props.t("Required-Message")),
        //   otherwise: Yup.string(),
        // }),
        // description_th: Yup.string().when("description_en", {
        //   is: description_en => !description_en || description_en.length === 0,
        //   then: Yup.string().required(props.t("Required-Message")),
        //   otherwise: Yup.string(),
        // }),
        community_files:
          community?.CommunityPostMedia?.length > 0
            ? null
            : Yup.mixed().required(props.t("Required-Message")),
        interest_list: Yup.array()
          .min(1, props.t("Select_Interest"))
          .required(props.t("Required-Message")),
        category_list:
          getRoleID() == constants.ROLE.SUPERADMIN
            ? Yup.string().required(props.t("Required-Message"))
            : Yup.string(),
        //category_list: Yup.string().required(props.t("Required-Message")),
        //company_list: Role?.role == "Super Admin" ? Yup.string().required(props.t("Required-Message")) : null,

        // image_url: Yup.string().required(props.t("Required-Message")),
        // interest_list: Yup.string().min(2, "Please enter a name more than 2 character").required(props.t("minimum interest tow required ")),
        // category_list: Yup.string().required(props.t("Required-Message")),
        // company_list: Yup.string().required(props.t("Required-Message")),
      },
      [
        ["name_th", "name_en"],
        ["description_th", "description_en"],
      ]
    ),
    onSubmit: values => {
      const data = new FormData()
      for (let i = 0; i < values.community_files?.length; i++) {
        data.append("community_files", values.community_files[i])
      }
      if (Role?.role == "Super Admin") {
        data.append("type", "INFOCUS")
      } else {
        data.append("type", "NEWS")
      }
      data.append("post_date", new Date())
      data.append("name_en", values.name_en)
      data.append("name_th", values.name_th)
      // data.append("description_en", values.description_en)
      // data.append("description_th", values.description_th)
      data.append("description_en", values.description_en)
      data.append("description_th", values.description_th)
      data.append("status", true)
      data.append("source_link", values.source_link)
      // data.append("interestsArr", [values.interest_list])
      for (let i = 0; i < values.interest_list.length; i++) {
        data.append("interestsArr", values.interest_list[i])
      }

      data.append("category_id", values.category_list)
      // if (Role?.role == "Super Admin") {
      //   data.append("company_id", values.company_list)
      // } else {
      //   data.append("company_id", null)
      // }
      data.append("community_post_id", community_id)
      CommunityApi.updateCommunity(data)
        .then(res => {
          if (res.data.success) {
            Swal.fire({
              text: res.data.message,
              icon: "success",
              imageAlt: "success image",
            }).then(result => {
              if (result?.isConfirmed) {
                history.push("/Posts")
                formClear()
                setFiles1()
              }
            })
          } else {
            toastr.error(res?.data?.message, "")
          }
        })
        .catch(function (error) {
          console.log(error)
          if (error?.response?.data?.message) {
            toastr.error(error?.response?.data?.message, "")
          } else {
            toastr.error(
              error?.response?.data?.error?.errors?.map(
                (item, v) => `${item.msg}<br>`
              ),
              ""
            )
          }
        })
    },
  })

  useEffect(() => {
    if (isFormSubmited) {
      const { errors } = validationType
      const keys = Object.keys(errors)
      if (keys.length > 0) {
        const errorElement = document.getElementById(keys[0])
        errorElement.focus()
        setIsFormSubmited(false)
      }
    }
  }, [isFormSubmited, validationType])

  useEffect(() => {
    if (descriptionTh) {
      convertContentThTextToHTML()
    }
  }, [descriptionTh])

  useEffect(() => {
    if (descriptionEn) {
      convertContentEnTextToHTML()
    }
  }, [descriptionEn])

  const convertContentThTextToHTML = async () => {
    const contentHtml = await url.convertEditorContentTextToHtml(descriptionTh)
    validationType.setFieldValue("description_th", contentHtml)
  }

  const convertContentEnTextToHTML = async () => {
    const contentHtml = await url.convertEditorContentTextToHtml(descriptionEn)
    validationType.setFieldValue("description_en", contentHtml)
  }

  const removeImage = id => {
    const data = {
      id,
      community_post_id: community_id,
    }
    CommunityApi.deleteImage(data)
      .then(res => {
        if (res.data.success) {
          getCommunityById()
        } else {
          toastr.error(res?.data?.message, "")
        }
      })
      .catch(function (error) {
        console.log(error)
        if (error?.response?.data?.message) {
          toastr.error(error?.response?.data?.message, "")
        } else {
          toastr.error(
            error?.response?.data?.error?.errors?.map(
              (item, v) => `${item.msg}<br>`
            ),
            ""
          )
        }
      })
  }

  const removeImageLocal = lastModified => {
    const updatedList = [
      ...validationType.values.community_files.filter(
        e => e.lastModified !== lastModified
      ),
    ]
    validationType.setFieldValue(
      "community_files",
      updatedList.length > 0 ? updatedList : null
    )
  }

  const formClear = () => {
    validationType.resetForm()
  }

  document.title = props.t("Post") + " | " + props.t("MedX")

  return (
    <React.Fragment>
      <div className="page-content">
        <Breadcrumb
          title={props.t("Post")}
          breadcrumbItem={props.t("Edit_Post")}
        />
        <Container fluid={true}>
          <Row>
            <Col lg={12}>
              <Card>
                <CardBody>
                  <Col sm={12}>
                    <Form
                      onSubmit={e => {
                        e.preventDefault()
                        validationType.handleSubmit()
                        setIsFormSubmited(true)
                        return false
                      }}
                    >
                      <Label className="form-label h3 text-primary">
                        {Role?.role == "Super Admin"
                          ? props.t("INFOCUS")
                          : props.t("NEWS")}
                      </Label>
                      <hr />

                      <Col sm={12}>
                        <div className="mb-3">
                          <Label className="form-label">
                            {props.t("Name_Thai")}{" "}
                            <span className="text-danger">*</span>
                          </Label>
                          <Input
                            name="name_th"
                            id="name_th"
                            placeholder={props.t("Name_Thai")}
                            type="text"
                            autocomplete="off"
                            onChange={validationType.handleChange}
                            onBlur={validationType.handleBlur}
                            value={validationType.values.name_th || ""}
                            invalid={
                              validationType.touched.name_th &&
                              validationType.errors.name_th
                                ? true
                                : false
                            }
                          />
                          {validationType.touched.name_th &&
                          validationType.errors.name_th ? (
                            <FormFeedback type="invalid">
                              {validationType.errors.name_th}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </Col>
                      <Col sm={12}>
                        <div className="mb-3">
                          <Label className="form-label">
                            {props.t("Name_Eng")}{" "}
                            <span className="text-danger">*</span>
                          </Label>
                          <Input
                            name="name_en"
                            id="name_en"
                            placeholder={props.t("Name_Eng")}
                            type="text"
                            autocomplete="off"
                            onChange={validationType.handleChange}
                            onBlur={validationType.handleBlur}
                            value={validationType.values.name_en || ""}
                            invalid={
                              validationType.touched.name_en &&
                              validationType.errors.name_en
                                ? true
                                : false
                            }
                          />
                          {validationType.touched.name_en &&
                          validationType.errors.name_en ? (
                            <FormFeedback type="invalid">
                              {validationType.errors.name_en}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </Col>

                      <Col sm={12}>
                        <div className="mb-3">
                          <Label className="form-label">
                            {props.t("Description_Th")}{" "}
                            <span className="text-danger">*</span>
                          </Label>
                          {/* <Input
                            name="description_th"
                            placeholder={props.t("Description_Th")}
                            type="text"
                            autocomplete="off"
                            onChange={validationType.handleChange}
                            onBlur={validationType.handleBlur}
                            value={validationType.values.description_th || ""}
                            invalid={
                              validationType.touched.description_th &&
                                validationType.errors.description_th
                                ? true
                                : false
                            }
                          /> */}
                          <Editor
                            name="description_th"
                            id="description_th"
                            editorState={descriptionTh}
                            onEditorStateChange={state =>
                              setDescriptionTh(state)
                            }
                            toolbarClassName="toolbarClassName"
                            wrapperClassName="wrapperClassName"
                            editorClassName="editorClassName"
                            invalid={
                              validationType.touched.description_th &&
                              validationType.errors.description_th
                                ? true
                                : false
                            }
                          />
                          {validationType.touched.description_th &&
                          validationType.errors.description_th ? (
                            <FormFeedback type="invalid">
                              {validationType.errors.description_th}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </Col>
                      <Col sm={12}>
                        <div className="mb-3">
                          <Label className="form-label">
                            {props.t("Description_En")}{" "}
                            <span className="text-danger">*</span>
                          </Label>
                          {/* <Input
                            name="description_en"
                            placeholder={props.t("Description_En")}
                            type="text"
                            autocomplete="off"
                            onChange={validationType.handleChange}
                            onBlur={validationType.handleBlur}
                            value={validationType.values.description_en || ""}
                            invalid={
                              validationType.touched.description_en &&
                                validationType.errors.description_en
                                ? true
                                : false
                            }
                          /> */}
                          <Editor
                            name="description_en"
                            id="description_en"
                            editorState={descriptionEn}
                            onEditorStateChange={state =>
                              setDescriptionEn(state)
                            }
                            toolbarClassName="toolbarClassName"
                            wrapperClassName="wrapperClassName"
                            editorClassName="editorClassName"
                          />
                          {validationType.touched.description_en &&
                          validationType.errors.description_en ? (
                            <FormFeedback type="invalid">
                              {validationType.errors.description_en}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </Col>

                      <Col sm={12}>
                        <div className="mb-3">
                          <Label htmlFor="validationCustom01">
                            {props.t("Images")}{" "}
                            <span className="text-danger">*</span>
                          </Label>
                          <Input
                            name="community_files"
                            id="community_files"
                            placeholder={props.t("Select_Community_Post_Image")}
                            type="file"
                            multiple
                            accept="image/*,video/*"
                            className="form-control"
                            onBlur={e => {
                              if (
                                e.target.files.length == 0 &&
                                validationType.values.community_files &&
                                validationType.values.community_files.length ==
                                  0
                              ) {
                                validationType.setFieldValue(
                                  "community_files",
                                  null
                                )
                              }
                            }}
                            onChange={e => {
                              const filesArray2 = []
                              for (let i = 0; i < e.target.files.length; i++) {
                                filesArray2.push(e.target.files[i])
                              }
                              validationType.setFieldValue(
                                "community_files",
                                validationType.values.community_files
                                  ? [
                                      ...validationType.values.community_files,
                                      ...filesArray2,
                                    ]
                                  : filesArray2
                              )
                            }}
                            invalid={
                              validationType.touched.community_files &&
                              validationType.errors.community_files
                                ? true
                                : false
                            }
                          />

                          {validationType.touched.community_files &&
                          validationType.errors.community_files ? (
                            <div
                              style={{
                                color: "#f46a6a",
                                fontSize: "11px",
                                marginTop: "3px",
                              }}
                            >
                              {validationType.errors.community_files}
                            </div>
                          ) : null}
                        </div>
                        <div className="d-flex  px-3 py-2 pb-4">
                          {community?.CommunityPostMedia?.length > 0
                            ? community?.CommunityPostMedia?.map(
                                (item, index) => {
                                  return (
                                    <div key={index} className=" row">
                                      {item.media_type === "VIDEO" ? (
                                        <video
                                          className="col-md-12 border img-fluid"
                                          style={{ width: 100, height: 90 }}
                                          src={item["image_url"]}
                                        />
                                      ) : (
                                        <img
                                          className="col-md-12 border img-fluid"
                                          style={{ width: 70, height: 60 }}
                                          src={item["image_url"]}
                                        />
                                      )}
                                      <a
                                        className="text-danger"
                                        type="button"
                                        style={{ fontSize: 12 }}
                                        onClick={() => removeImage(item.id)}
                                      >
                                        {props.t("Remove")}
                                      </a>
                                    </div>
                                  )
                                }
                              )
                            : null}
                          {validationType.values.community_files?.length > 0
                            ? validationType.values.community_files?.map(
                                (item, index) => {
                                  return (
                                    <div key={index} className=" row">
                                      <img
                                        className="col-md-12 border img-fluid"
                                        style={{ width: 70, height: 60 }}
                                        src={URL.createObjectURL(item)}
                                      />
                                      <a
                                        className="text-danger"
                                        type="button"
                                        style={{ fontSize: 12 }}
                                        onClick={() =>
                                          removeImageLocal(item.lastModified)
                                        }
                                      >
                                        {props.t("Remove")}
                                      </a>
                                    </div>
                                  )
                                }
                              )
                            : null}
                        </div>
                      </Col>

                      <Col sm={12}>
                        <div className="mb-3">
                          <Label className="form-label">
                            {props.t("Source_Link")}
                          </Label>
                          <Input
                            name="source_link"
                            id="source_link"
                            placeholder={props.t("Source_Link")}
                            type="text"
                            onChange={validationType.handleChange}
                            onBlur={validationType.handleBlur}
                            value={validationType.values.source_link || ""}
                            invalid={
                              validationType.touched.source_link &&
                              validationType.errors.source_link
                                ? true
                                : false
                            }
                          />
                          {validationType.touched.source_link &&
                          validationType.errors.source_link ? (
                            <FormFeedback type="invalid">
                              {validationType.errors.source_link}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </Col>

                      <Col sm={12}>
                        <div className="mb-3">
                          <Label htmlFor="validationCustom01">
                            {props.t("Interests")}{" "}
                            <span className="text-danger">*</span>
                          </Label>

                          {community && interestList.length > 0 ? (
                            <Select
                              placeholder={props.t("Select_Interest")}
                              id="interest_list"
                              defaultValue={selectedOption}
                              onChange={e => {
                                const f = e.map((item, index) => {
                                  return item.value
                                })
                                validationType.setFieldValue("interest_list", f)
                                setSelectedOption(e)
                              }}
                              options={interestList}
                              isMulti={true}
                              className={
                                validationType.touched.interest_list &&
                                validationType.errors.interest_list
                                  ? " border border-danger"
                                  : ""
                              }
                            />
                          ) : null}
                          {validationType.touched.interest_list &&
                          validationType.errors.interest_list ? (
                            <div
                              style={{
                                color: "#f46a6a",
                                fontSize: "11px",
                                marginTop: "3px",
                              }}
                            >
                              {validationType.errors.interest_list}
                            </div>
                          ) : null}
                        </div>
                      </Col>
                      {getRoleID() == constants.ROLE.SUPERADMIN && (
                        <Col sm={12}>
                          <div className="mb-3">
                            <Label htmlFor="" className="form-label">
                              {props.t("Topics")}{" "}
                              <span className="text-danger">*</span>
                            </Label>
                            <select
                              onChange={validationType.handleChange}
                              value={validationType.values.category_list}
                              className={
                                validationType.touched.category_list &&
                                validationType.errors.category_list
                                  ? "form-select border border-danger"
                                  : "form-select"
                              }
                              name="category_list"
                              id="category_list"
                            >
                              <option value="">
                                {props.t("Please_Select_Topic")}
                              </option>
                              {categoryList
                                ? categoryList?.map((item, index) => {
                                    return (
                                      <option value={item.id} key={index}>
                                        {item?.name_en}
                                      </option>
                                    )
                                  })
                                : ""}
                            </select>
                            {validationType.touched.category_list &&
                            validationType.errors.category_list ? (
                              <p className="text-danger">
                                {validationType.errors.category_list}
                              </p>
                            ) : null}
                          </div>
                        </Col>
                      )}
                      {/* {Role?.role == "Super Admin" ?
                        <Col sm={12}>
                          <div className="mb-3">
                            <Label htmlFor="" className="form-label">
                              {props.t("Company")}  <span className="text-danger">*</span>
                            </Label>
                            <select
                              onChange={validationType.handleChange}
                              value={validationType.values.company_list || ""}
                              className={
                                validationType.touched.company_list &&
                                  validationType.errors.company_list
                                  ? "form-select border border-danger"
                                  : "form-select"
                              }
                              name="company_list"
                            >
                              {validationType.touched.company_list &&
                                validationType.errors.company_list ? (
                                <p className="text-danger">
                                  {validationType.errors.company_list}
                                </p>
                              ) : null}
                              <option value="">
                                {props.t("Please_Select_Company")}
                              </option>
                              {companyList
                                ? companyList?.map((item, index) => {
                                  return (
                                    <option value={item.id} key={index}>
                                      {item?.name_en}
                                    </option>
                                  )
                                })
                                : ""}
                            </select>
                          </div>
                        </Col>
                        : null} */}

                      <div className="d-flex flex-wrap gap-2">
                        <Button type="submit" color="primary">
                          {props.t("Submit")}
                        </Button>{" "}
                        {/* <Button color="secondary" onClick={e => formClear()}>
                            {props.t("Clear")}
                        </Button> */}
                      </div>
                    </Form>
                  </Col>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

EditCommunity.propTypes = {
  t: PropTypes.any,
}

export default withTranslation()(EditCommunity)
