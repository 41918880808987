import { CategoryApi } from "apis/CategoryApi"
import { CommunityApi } from "apis/CommunityApi"
import { CompaniesApi } from "apis/CompaniesApi"
import { InterestApi } from "apis/InterestApi"
import Breadcrumb from "components/Common/Breadcrumb"
import config from "config/config"
import { EditorState } from "draft-js"
import { useFormik } from "formik"
import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import { Editor } from "react-draft-wysiwyg"
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css"
import { withTranslation } from "react-i18next"
import { useHistory } from "react-router-dom"
import Select from "react-select"
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Form,
  FormFeedback,
  Input,
  Label,
  Row,
} from "reactstrap"
import Swal from "sweetalert2"
import toastr from "toastr"
import "toastr/build/toastr.min.css"
import * as Yup from "yup"
import { getRoleID } from "../../Token"
import constants from "../../config/constants"
import * as url from "../../helpers/common_helper"

const CommunityForm = props => {
  const [files1, setFiles1] = useState([])
  const [filesArray, setFilesArray] = useState([])
  const history = useHistory()
  const [interestList, setinterestList] = useState([])
  const [categoryList, setCategoryList] = useState([])
  const [companyList, setCompanyList] = useState([])
  const [loading, setLoading] = useState(false)
  const [selectedOption, setSelectedOption] = useState(null)
  const [descriptionEn, setDescriptionEn] = useState(() =>
    EditorState.createEmpty()
  )
  const [descriptionTh, setDescriptionTh] = useState(() =>
    EditorState.createEmpty()
  )
  const [isFormSubmited, setIsFormSubmited] = useState(false)
  const [activeData, setActiveData] = useState({
    page: 1,
    limit: config.LIMIT,
    type: getRoleID() == constants.ROLE.SUPERADMIN ? "INFOCUS" : "NEWS",
  })

  const getAllCommunityTypes = data => {
    setLoading(true)
    InterestApi.getAllInterests(data)
      .then(res => {
        setActiveData({
          page: activeData.page,
          totalPage: res?.data?.data?.count,
          search: activeData.search,
          limit: activeData.limit,
        })
        const x = res?.data?.data?.rows
        const y = x?.map((item, index) => {
          return {
            label: item.name_en,
            value: item.id,
          }
        })
        setinterestList(y)
        setLoading(false)
      })
      .catch(err => {
        console.log(err)
      })
  }

  useEffect(() => {
    getAllCommunityTypes(activeData)
  }, [])

  const getAllCategories = data => {
    setLoading(true)
    CategoryApi.getAllCategories(data)
      .then(res => {
        setActiveData({
          page: activeData.page,
          totalPage: res?.data?.data?.count,
          search: activeData.search,
          limit: activeData.limit,
        })
        setCategoryList(res?.data?.data?.rows)
        setLoading(false)
      })
      .catch(err => {
        console.log(err)
      })
  }

  useEffect(() => {
    getAllCategories(activeData)
  }, [])

  const getAllCompanies = data => {
    setLoading(true)
    CompaniesApi.getAllCompanies(data)
      .then(res => {
        setActiveData({
          page: activeData.page,
          totalPage: res?.data?.data?.count,
          search: activeData.search,
          limit: activeData.limit,
        })
        setCompanyList(res?.data?.data?.rows)
        setLoading(false)
      })
      .catch(err => {
        console.log(err)
      })
  }

  useEffect(() => {
    getAllCompanies(activeData)
  }, [])

  // Form validation
  const validationType = useFormik({
    enableReinitialize: true,
    validateOnBlur: false,
    initialValues: {
      type: "",
      name_en: "",
      name_th: "",
      description_en: "",
      description_th: "",
      community_files: null,
      status: "",
      source_link: "",
      interest_list: [],
      category_list: "",
      //company_list: "",
    },
    validationSchema: Yup.object().shape(
      {
        name_th: Yup.string().when("name_en", {
          is: name_en => !name_en || name_en.length === 0,
          then: Yup.string()
            .max(150, props.t("Field_Must_50_Characters"))
            .required(props.t("Required-Message")),
          otherwise: Yup.string(),
        }),
        name_en: Yup.string().when("name_th", {
          is: name_th => !name_th || name_th.length === 0,
          then: Yup.string()
            .max(150, props.t("Field_Must_50_Characters"))
            .required(props.t("Required-Message")),
          otherwise: Yup.string(),
        }),
        // description_en: Yup.string().when('description_th', {
        //   is: (description_th) => !description_th || description_th.length === 0,
        //   then: Yup.string().required(props.t("Required-Message")),
        //   otherwise: Yup.string()
        // }),
        // description_th: Yup.string().when('description_en', {
        //   is: (description_en) => !description_en || description_en.length === 0,
        //   then: Yup.string().required(props.t("Required-Message")),
        //   otherwise: Yup.string()
        // }),
        // name_en: Yup.string().max(50, "This field must be at most 50 characters").required(props.t("This Name (en) is required")),
        // name_th: Yup.string().max(50, "This field must be at most 50 characters").required(props.t("This Name (th) is required")),
        // description_en: Yup.string().required(props.t("This Description (en) is required")),
        // description_th: Yup.string().required(props.t("This Description (th) is required")),
        community_files: Yup.mixed().required(props.t("Required-Message")),
        interest_list: Yup.array()
          .min(1, props.t("Select_Interest"))
          .required(props.t("Required-Message")),
        //category_list: Yup.string().required(props.t("Required-Message")),
        category_list:
          getRoleID() == constants.ROLE.SUPERADMIN
            ? Yup.string().required(props.t("Required-Message"))
            : Yup.string(),
        //company_list: Role?.role == "Super Admin" ? Yup.string().required(props.t("Required-Message")) : null,
      },
      [
        ["name_th", "name_en"],
        ["description_th", "description_en"],
      ]
    ),
    onSubmit: values => {
      const data = new FormData()
      if (getRoleID() == constants.ROLE.SUPERADMIN) {
        data.append("type", "INFOCUS")
      } else {
        data.append("type", "NEWS")
      }

      for (let i = 0; i < values.community_files.length; i++) {
        data.append("community_files", values.community_files[i])
      }

      data.append("post_date", new Date())
      data.append("name_en", values.name_en ?? values?.name_th)
      data.append("name_th", values.name_th ?? values?.name_en)
      // data.append("description_en", values.description_en ??  values?.description_th)
      // data.append("description_th", values.description_th ?? values?.description_en)
      data.append("description_en", values.description_en)
      data.append("description_th", values.description_th)
      data.append("status", true)
      data.append("source_link", values.source_link ?? values?.source_link)
      data.append("category_id", values.category_list)
      // const interestsArr = [];
      // for (let i = 0; i < values.interest_list.length; i++) {
      //   interestsArr.push(values.interest_list[i]);
      // }
      // data.append("interestsArr",interestsArr);

      for (let i = 0; i < values.interest_list.length; i++) {
        data.append("interestsArr", values.interest_list[i])
      }

      // if (Role?.role == "Super Admin") {
      //   data.append("company_id", values.company_list)
      // } else {
      //   data.append("company_id", null)
      // }
      CommunityApi.createCommunity(data)
        .then(res => {
          if (res.data.success) {
            Swal.fire({
              text: res.data.message,
              icon: "success",
              imageAlt: "success image",
            }).then(result => {
              if (result?.isConfirmed) {
                history.push("/Posts")
                formClear()
                setFiles1()
              }
            })
          } else {
            toastr.error(res?.data?.message, "")
          }
        })
        .catch(function (error) {
          if (error?.response?.data?.message) {
            toastr.error(error?.response?.data?.message, "")
          } else {
            toastr.error(
              error?.response?.data?.error?.errors?.map(
                (item, v) => `${item.msg}<br>`
              ),
              ""
            )
          }
        })
    },
  })

  useEffect(() => {
    if (descriptionTh) {
      convertContentThTextToHTML()
    }
  }, [descriptionTh])

  useEffect(() => {
    if (descriptionEn) {
      convertContentEnTextToHTML()
    }
  }, [descriptionEn])

  const convertContentThTextToHTML = async () => {
    const contentHtml = await url.convertEditorContentTextToHtml(descriptionTh)
    validationType.setFieldValue("description_th", contentHtml)
  }

  const convertContentEnTextToHTML = async () => {
    const contentHtml = await url.convertEditorContentTextToHtml(descriptionEn)
    validationType.setFieldValue("description_en", contentHtml)
  }

  const formClear = () => {
    validationType.resetForm()
  }

  const removeImage = lastModified => {
    const updatedList = [
      ...validationType.values.community_files.filter(
        e => e.lastModified !== lastModified
      ),
    ]
    validationType.setFieldValue(
      "community_files",
      updatedList.length > 0 ? updatedList : null
    )
  }

  document.title = props.t("Post") + " | " + props.t("MedX")
  // useEffect(() => {
  //   if (validationType.isSubmitting) {
  //     const vobj = validationType.errors
  //     if (
  //       vobj &&
  //       Object.keys(vobj).length === 0 &&
  //       Object.getPrototypeOf(vobj) === Object.prototype
  //     ) {
  //     } else {
  //       let str = ""
  //       Object.keys(vobj).forEach(function (key) {
  //         str = str + vobj[key] + "<br />"
  //       })
  //       toastr.error(str)
  //     }
  //   }
  // }, [validationType.errors, validationType.isSubmitting])

  // useEffect(() => {}, [validationType])

  useEffect(() => {
    if (isFormSubmited) {
      const { errors } = validationType
      const keys = Object.keys(errors)
      if (keys.length > 0) {
        const errorElement = document.getElementById(keys[0])
        errorElement.focus()
        setIsFormSubmited(false)
      }
    }
  }, [isFormSubmited, validationType])

  return (
    <React.Fragment>
      <div className="page-content">
        <Breadcrumb
          title={props.t("Post")}
          breadcrumbItem={props.t("Add_Post")}
        />
        <Container fluid={true}>
          <Row>
            <Col lg={12}>
              <Card>
                <CardBody>
                  <Col sm={12}>
                    <Form
                      onSubmit={e => {
                        e.preventDefault()
                        validationType.handleSubmit()
                        setIsFormSubmited(true)
                        return false
                      }}
                    >
                      <Label className="form-label h3 text-primary">
                        {getRoleID() == constants.ROLE.SUPERADMIN
                          ? props.t("INFOCUS")
                          : props.t("NEWS")}
                      </Label>
                      <hr />
                      <Col sm={12}>
                        <div className="mb-3">
                          <Label className="form-label">
                            {props.t("Name_Thai")}{" "}
                            <span className="text-danger">*</span>
                          </Label>
                          <Input
                            name="name_th"
                            id="name_th"
                            placeholder={props.t("Name_Thai")}
                            type="text"
                            onChange={validationType.handleChange}
                            onBlur={validationType.handleBlur}
                            value={validationType.values.name_th || ""}
                            invalid={
                              validationType.touched.name_th &&
                              validationType.errors.name_th
                                ? true
                                : false
                            }
                          />
                          {validationType.touched.name_th &&
                          validationType.errors.name_th ? (
                            <FormFeedback type="invalid">
                              {validationType.errors.name_th}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </Col>

                      <Col sm={12}>
                        <div className="mb-3">
                          <Label className="form-label">
                            {props.t("Name_Eng")}{" "}
                            <span className="text-danger">*</span>
                          </Label>

                          <Input
                            name="name_en"
                            id="name_en"
                            placeholder={props.t("Name_Eng")}
                            type="text"
                            onChange={validationType.handleChange}
                            onBlur={validationType.handleBlur}
                            value={validationType.values.name_en || ""}
                            invalid={
                              validationType.touched.name_en &&
                              validationType.errors.name_en
                                ? true
                                : false
                            }
                          />
                          {validationType.touched.name_en &&
                          validationType.errors.name_en ? (
                            <FormFeedback type="invalid">
                              {validationType.errors.name_en}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </Col>

                      <Col sm={12}>
                        <div className="mb-3">
                          <Label className="form-label">
                            {props.t("Description_Th")}{" "}
                            <span className="text-danger">*</span>
                          </Label>
                          {/* <Input
                            name="description_th"
                            placeholder={props.t("Description_Th")}
                            type="text"
                            autocomplete="off"
                            onChange={validationType.handleChange}
                            onBlur={validationType.handleBlur}
                            value={validationType.values.description_th || ""}
                            invalid={
                              validationType.touched.description_th &&
                                validationType.errors.description_th
                                ? true
                                : false
                            }
                            autoComplete="off"
                          /> */}
                          <Editor
                            name="description_th"
                            id="description_th"
                            editorState={descriptionTh}
                            onEditorStateChange={state =>
                              setDescriptionTh(state)
                            }
                            toolbarClassName="toolbarClassName"
                            wrapperClassName="wrapperClassName"
                            editorClassName="editorClassName"
                            invalid={
                              validationType.touched.description_th &&
                              validationType.errors.description_th
                                ? true
                                : false
                            }
                          />
                          {validationType.touched.description_th &&
                          validationType.errors.description_th ? (
                            <FormFeedback type="invalid">
                              {validationType.errors.description_th}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </Col>
                      <Col sm={12}>
                        <div className="mb-3">
                          <Label className="form-label">
                            {props.t("Description_En")}{" "}
                            <span className="text-danger">*</span>
                          </Label>
                          {/* <Input
                            name="description_en"
                            placeholder={props.t("Description_En")}
                            type="text"
                            autocomplete="off"
                            onChange={validationType.handleChange}
                            onBlur={validationType.handleBlur}
                            value={validationType.values.description_en || ""}
                            invalid={
                              validationType.touched.description_en &&
                                validationType.errors.description_en
                                ? true
                                : false
                            }
                            autoComplete="off"
                          /> */}
                          <Editor
                            name="description_en"
                            id="description_en"
                            editorState={descriptionEn}
                            onEditorStateChange={state =>
                              setDescriptionEn(state)
                            }
                            toolbarClassName="toolbarClassName"
                            wrapperClassName="wrapperClassName"
                            editorClassName="editorClassName"
                          />
                          {validationType.touched.description_en &&
                          validationType.errors.description_en ? (
                            <FormFeedback type="invalid">
                              {validationType.errors.description_en}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </Col>

                      <Col sm={12}>
                        <div className="">
                          <Label htmlFor="validationCustom01">
                            {props.t("Images")}{" "}
                            <span className="text-danger">*</span>
                          </Label>
                          <Input
                            name="community_files"
                            id="community_files"
                            placeholder={props.t("Enter_Community_Image_Url")}
                            type="file"
                            multiple
                            accept="image/*,video/*"
                            className="form-control"
                            onBlur={e => {
                              if (
                                e.target.files.length == 0 &&
                                validationType.values.community_files &&
                                validationType.values.community_files.length ==
                                  0
                              ) {
                                validationType.setFieldValue(
                                  "community_files",
                                  null
                                )
                              }
                            }}
                            onChange={e => {
                              const filesArray2 = []
                              for (let i = 0; i < e.target.files.length; i++) {
                                filesArray2.push(e.target.files[i])
                              }
                              validationType.setFieldValue(
                                "community_files",
                                validationType.values.community_files
                                  ? [
                                      ...validationType.values.community_files,
                                      ...filesArray2,
                                    ]
                                  : filesArray2
                              )
                            }}
                            invalid={
                              validationType.touched.community_files &&
                              validationType.errors.community_files
                                ? true
                                : false
                            }
                          />
                          {validationType.touched.community_files &&
                          validationType.errors.community_files ? (
                            <div
                              style={{
                                color: "#f46a6a",
                                fontSize: "11px",
                                marginTop: "3px",
                              }}
                            >
                              {validationType.errors.community_files}
                            </div>
                          ) : null}
                        </div>
                        <div className="d-flex  px-3 py-2">
                          {validationType.values.community_files?.length > 0
                            ? validationType.values.community_files?.map(
                                (item, index) => {
                                  return (
                                    <div key={index} className=" row">
                                      {item.type.split("/")[0] === "image" ? (
                                        <>
                                          <img
                                            className="col-md-12 border img-fluid"
                                            style={{ width: 70, height: 60 }}
                                            src={URL.createObjectURL(item)}
                                          />
                                          <a
                                            className="text-danger"
                                            type="button"
                                            style={{ fontSize: 12 }}
                                            onClick={() => {
                                              removeImage(item.lastModified)
                                            }}
                                          >
                                            {props.t("Remove")}
                                          </a>
                                        </>
                                      ) : (
                                        <>
                                          <video
                                            className="col-md-12 border img-fluid"
                                            style={{ width: 100, height: 90 }}
                                            src={URL.createObjectURL(item)}
                                          />
                                          <a
                                            className="text-danger"
                                            type="button"
                                            style={{ fontSize: 12 }}
                                            onClick={() => {
                                              removeImage(item.lastModified)
                                            }}
                                          >
                                            {props.t("Remove")}
                                          </a>
                                        </>
                                      )}
                                    </div>
                                  )
                                }
                              )
                            : ""}
                        </div>
                      </Col>

                      <Col sm={12}>
                        <div className="mb-3">
                          <Label className="form-label">
                            {props.t("Source_Link")}
                          </Label>
                          <Input
                            name="source_link"
                            id="source_link"
                            placeholder={props.t("Source_Link")}
                            type="text"
                            onChange={validationType.handleChange}
                            onBlur={validationType.handleBlur}
                            value={validationType.values.source_link || ""}
                            invalid={
                              validationType.touched.source_link &&
                              validationType.errors.source_link
                                ? true
                                : false
                            }
                          />
                          {validationType.touched.source_link &&
                          validationType.errors.source_link ? (
                            <FormFeedback type="invalid">
                              {validationType.errors.source_link}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </Col>

                      <Col sm={12}>
                        <div className="mb-3">
                          <Label htmlFor="validationCustom01">
                            {props.t("Interests")}{" "}
                            <span className="text-danger">*</span>
                          </Label>
                          <Select
                            defaultValue={selectedOption}
                            placeholder={props.t("Select_Interest")}
                            onChange={e => {
                              const f = e.map((item, index) => {
                                return item.value
                              })
                              validationType.setFieldValue("interest_list", f)
                              setSelectedOption(e)
                            }}
                            id="interest_list"
                            options={interestList}
                            isMulti={true}
                            styles={{
                              control: (provided, state) => ({
                                ...provided,
                                borderColor:
                                  validationType.touched.interest_list &&
                                  validationType.errors.interest_list
                                    ? "red"
                                    : "#ccc",
                              }),
                            }}
                          />
                          {validationType.touched.interest_list &&
                          validationType.errors.interest_list ? (
                            <div
                              style={{
                                color: "#f46a6a",
                                fontSize: "11px",
                                marginTop: "3px",
                              }}
                            >
                              {validationType.errors.interest_list}
                            </div>
                          ) : null}
                        </div>
                      </Col>
                      {getRoleID() == constants.ROLE.SUPERADMIN && (
                        <Col sm={12}>
                          <div className="mb-3">
                            <Label htmlFor="" className="form-label">
                              {props.t("Topics")}{" "}
                              <span className="text-danger">*</span>
                            </Label>
                            <select
                              onChange={validationType.handleChange}
                              //   onBlur={() => getDistrict()}
                              value={validationType.values.category_list}
                              // className="form-select"
                              className={
                                validationType.touched.category_list &&
                                validationType.errors.category_list
                                  ? "form-select border border-danger"
                                  : "form-select"
                              }
                              name="category_list"
                              id="category_list"
                            >
                              <option value="">
                                {props.t("Please_Select_Topic")}
                              </option>
                              {categoryList
                                ? categoryList?.map((item, index) => {
                                    return (
                                      <option value={item.id} key={index}>
                                        {item?.name_en}
                                      </option>
                                    )
                                  })
                                : ""}
                            </select>
                            {validationType.touched.category_list &&
                            validationType.errors.category_list ? (
                              <p
                                style={{
                                  color: "#f46a6a",
                                  fontSize: "11px",
                                  marginTop: "3px",
                                }}
                              >
                                {validationType.errors.category_list}
                              </p>
                            ) : null}
                          </div>
                        </Col>
                      )}

                      {/* {Role?.role == "Super Admin" ?
                        <Col sm={12}>
                          <div className="mb-3">
                            <Label htmlFor="" className="form-label">
                              {props.t("Company")} <span className="text-danger">*</span>
                            </Label>
                            <select
                              onChange={validationType.handleChange}
                              value={validationType.values.company_list}
                              className={
                                validationType.touched.company_list &&
                                  validationType.errors.company_list
                                  ? "form-select border border-danger"
                                  : "form-select"
                              }
                              name="company_list"
                            >
                              <option value="">
                                {props.t("Please_Select_Company")}
                              </option>
                              {companyList
                                ? companyList?.map((item, index) => {
                                  return (
                                    <option value={item.id} key={index}>
                                      {item?.name_en}
                                    </option>
                                  )
                                })
                                : ""}
                            </select>
                            {validationType.touched.company_list &&
                              validationType.errors.company_list ? (
                              <p style={{
                                color: "#f46a6a",
                                fontSize: "11px",
                                marginTop: "3px",
                              }}>
                                {validationType.errors.company_list}
                              </p>
                            ) : null}
                          </div>
                        </Col>
                        : null} */}

                      <div className="d-flex flex-wrap gap-2">
                        <Button type="submit" color="primary">
                          {props.t("Submit")}
                        </Button>{" "}
                        {/* <Button color="secondary" onClick={e => formClear()}>
                                                    {props.t("Clear")}
                                                </Button> */}
                      </div>
                    </Form>
                  </Col>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

CommunityForm.propTypes = {
  t: PropTypes.any,
}

export default withTranslation()(CommunityForm)
