// src/components/filter.
import React, { useEffect, useState } from "react";
import PropTypes from 'prop-types';

//import components
import Breadcrumbs from "components/Common/Breadcrumb";
import DataTable from 'react-data-table-component';
// import { BannerApi } from "../../apis/BannerApi";
import { useHistory, Link } from "react-router-dom"
// import TableContainer from '../../components/Common/TableContainer';
import { withTranslation } from "react-i18next";
// import * as Yup from "yup";
// import { useFormik } from "formik";
// import toastr from "toastr";
import config from "config/config";
// import constants from "../../config/constants";
// import i18n from "../../i18n";
import {
    Container,
    Row,
    Col,
    Card,
    CardBody,
    Form,
    Label,
    Input,
    FormFeedback,
    Button
} from "reactstrap";
import Swal from "sweetalert2";
import moment from "moment";
import { CompaniesApi } from "apis/CompaniesApi";
import toastr from "toastr";
import "toastr/build/toastr.min.css";

function Companies(props) {
    const history = useHistory();
    const [files1, setFiles1] = useState([]);
    const [companieList, setCompanieList] = useState([]);
    const [fetchBanner, setFetchBanner] = useState([]);
    const [postBannerId, setBannerId] = useState('');
    const [loading, setLoading] = useState(false);
    const [limit, setCurrentLimit] = useState(config.LIMIT);

    const [activeData, setActiveData] = useState({
        page: 1,
        totalPage: 1,
        search: "",
        limit: config.LIMIT
    });
    const [page, setCurrentPage] = useState(1);



    useEffect(() => {
        getAllCompanies(activeData);
    }, [page,limit]);

    const formClear = () => {
        validationType.resetForm();
        setFiles1([])
        setFetchBanner();
    };

    const columns = [
        {
            name: props.t("Sr_No"),
            selector: row => row.offer_id,
            sort: "asc",
            width: 150,
            sortable: true,
            defaultSortField: true,
            defaultSortAsc: false
        },
        {
            name: props.t("Title_Eng"),
            selector: row => row.title_en,
            sort: "asc",
            width: 270,
            sortable: true,
            defaultSortField: true,
            defaultSortAsc: false
        },
        {
            name: props.t("Title_Thai"),
            selector: row => row.title_th,
            sort: "asc",
            width: 270,
            sortable: true,
            defaultSortField: true,
            defaultSortAsc: false
        },
        {
            name: props.t("Topic"),
            selector: row => row.category_id ,
            sort: "asc",
            width: 270,
            sortable: true,
            defaultSortField: true,
            defaultSortAsc: false
        },
        {
            name: props.t("Email"),
            selector: row => row.email,
            sort: "asc",
            width: 270,
            sortable: true,
            defaultSortField: true,
            defaultSortAsc: false
        },
        {
            name: props.t("Phone_No"),
            selector: row => row.phone_no,
            sort: "asc",
            width: 270,
            sortable: true,
            defaultSortField: true,
            defaultSortAsc: false
        },
        {
            name: props.t("Website"),
            selector: row => row.website,
            sort: "asc",
            width: 400
        },
        {
            name: props.t("Logo"),
            selector: row => row.logo_url,
            sort: "asc",
            width: 400
        },
        {
            name: props.t("FacebookUrl"),
            selector: row => row.facebook_url,
            sort: "asc",
            width: 400
        },
        {
            name: props.t("InstaUrl"),
            selector: row => row.insta_url,
            sort: "asc",
            width: 400
        },
        {
            name: props.t("LineUrl"),
            selector: row => row.line_url,
            sort: "asc",
            width: 400
        },
        {
            name: props.t("Action"),
            selector: row => row.action,
            sort: "asc",
            width: 200,
        }
    ];

    const onSendEmailPasswordHandler = (companyId) => {
        const payload = {
            company_id: companyId
        }
        CompaniesApi.sendPasswordToEmailAPI(payload)
            .then(res => {
                if (res.data.success) {
                    toastr.success(res.data.message, '');
                    return true;
                } else {
                    toastr.error(res?.data?.message, '');
                    return false;
                }
            }).catch(function (error) {
                if (error?.response?.data?.message) {
                    toastr.error(error?.response?.data?.message, '');
                }
                else {
                    toastr.error(error?.response?.data?.error?.errors?.map((item, v) => `${item.msg}<br>`), '');
                }
                return false;
            });
    }

    const getAllCompanies = (data) => {
        setLoading(true);
        CompaniesApi.getAllCompanies(data)
            .then((res) => {
                setActiveData({
                    page: activeData.page,
                    totalPage: res?.data?.data?.count,
                    search: activeData.search,
                    limit: activeData.limit,
                });
                let data = [];
                var sr_no = res.data.sr_no_start;
                res?.data?.data?.rows?.forEach((v, i) => {
                    sr_no = sr_no + 1;
                    data[i] = {
                        offer_id: sr_no,
                        title_en: v.name_en,
                        title_th: v.name_th,
                        category_id: v?.Category?.name_en,
                        email: v.email,
                        phone_no: v.phone_no,
                        website: v.website,
                        logo_url: (
                            <>
                                <img
                                    className="header-profile-user"
                                    src={`${v.logo_url}`}
                                />
                            </>
                        ),
                        facebook_url: v.facebook_url,
                        insta_url: v.insta_url,
                        line_url : v.line_url,
                        is_microsite: v.is_microsite == 1 ? (
                            <>
                                <Link
                                    style={{ marginRight: "15px" }}
                                    to={`/manage-company-temlate/${v.id}`}
                                >
                                    {props.t('Manage_Template')}
                               </Link>
                            </>
                        ) : (
                            <>
                            </>
                        ),
                        action: (
                            <>

                                <Link
                                    style={{ marginRight: "15px" }}
                                    to={`/edit-company/${v.id}`}
                                >
                                    <i className=" fas fa-edit" id="edittooltip" />
                                </Link>
                                <i className="mdi mdi-delete font-size-18" 
                                    style={{ color: "red", marginRight:"15px"}} id="deletetooltip" onClick={() => onDelete(v.id)} 
                                />
                                <Button
                                    type="button"
                                    color="primary"
                                    className="btn-sm btn-rounded"
                                    onClick={() => {
                                        onSendEmailPasswordHandler(v.id)
                                    }}
                                >
                                    {props.t("Send_Email")}
                                </Button>
                            </>
                        ),

                    };
                });
                setCompanieList(data);
                setLoading(false);
            })
            .catch((err) => {
                console.log(err);
            });
    };


    const onDelete = (id) => {

        Swal.fire({
            title: props.t("Are_you_sure"),
            text: props.t("Able_to_revert_this"),
            icon: props.t("Warning"),
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#f46a6a",
            confirmButtonText: props.t("Yes_delete_it"),
            cancelButtonText: props.t("Cancel"),
        }).then((result) => {
            if (result.isConfirmed) {
                let CompanyId = { "company_id": id };
                CompaniesApi.deleteCompany(CompanyId)
                    .then((res) => {
                        Swal.fire(props.t("Deleted"), res.data.message, props.t("Success"));
                        //getAllCompanies(activeData)
                        handleChange(page);
                       
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            }
        });
    };

   
    const handleChange = (v) => {
        setCurrentPage(v);
        setActiveData({ page: v, totalPage: activeData.totalPage, search: activeData.search, limit: limit });
        const data = { page: v, totalPage: activeData.totalPage, search: activeData.search, limit: limit }
        getAllCompanies(data)
    }


    const handleRowChange = (v) => {
        setCurrentLimit(v);
        setActiveData({ page: activeData.page, totalPage: activeData.totalPage, search: activeData.search, limit: v });
        const data = { page: activeData.page, totalPage: activeData.totalPage, search: activeData.search, limit: v }
        getAllCompanies(data)
    }

    //meta title
    document.title = props.t("Companies") + ' | ' + props.t("MedX");

    return (
        <div className="page-content">
            <div className="container-fluid">
                <Breadcrumbs title={props.t("Master")} breadcrumbItem={props.t("Companies")} />
                <Container fluid={true}>
                    <Row>
                        <Col>
                            <Link to="/add-company" className="btn btn-primary w-xs btn-right">{props.t("Add")}</Link>
                        </Col>
                    </Row>
                </Container>
                <Container fluid={false}>
                    <Row>
                        <Col lg={12}>
                            <Card>
                                <CardBody >
                                    <Col sm={12}>
                                        <DataTable
                                            className="table-bordered"
                                            progressPending={loading}
                                            columns={columns}
                                            data={companieList}
                                            pagination
                                            paginationServer
                                            paginationTotalRows={activeData.totalPage}
                                            paginationPerPage={activeData.limit}
                                            defaultSortFieldID={1}
                                            onChangeRowsPerPage={value => handleRowChange(value)}
                                            onChangePage={value => handleChange(value)}
                                            sortable
                                            noHeader
                                            defaultSortField="id"
                                            defaultSortAsc={false}
                                            highlightOnHover
                                        />
                                    </Col>

                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </div>
    );
}


Companies.propTypes = {
    t: PropTypes.any,
    preGlobalFilteredRows: PropTypes.any,
};

export default withTranslation()(Companies);