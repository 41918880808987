// src/components/filter.
import React, { useMemo, useEffect, useState } from "react";
import PropTypes from 'prop-types';

//import components
import Breadcrumbs from '../../../components/Common/Breadcrumb';
import TableContainer from '../../../components/Common/TableContainer';
import DataTable from 'react-data-table-component';
import { RequestInstitutionApi } from "../../../apis/RequestInstitutionApi";
import { useHistory } from "react-router-dom"


import { Link } from "react-router-dom";
import {
  Button,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  ButtonDropdown,
  Input
} from "reactstrap";

import Swal from "sweetalert2";

import { withTranslation } from "react-i18next";

import i18n from "../../../i18n";
import FilterComponent from "pages/Program/Activity/FilterComponent";
import config from "config/config";

function RequestInstitutions(props) {


  const history = useHistory();
  const [roles, setRoles] = useState([]);
  const [requestInstituteName, setRequestInstituteName] = useState();
  const [autoSearch, setAutoSearchData] = useState([]);

  
  const [loading, setLoading] = useState(false);
  const [activeData, setActiveData] = useState({
    activePage: 1,
    totalPage: 1,
    search: "",
    limit: config.LIMIT,
  });


  const [limit, setCurrentLimit] = useState(config.LIMIT);

  useEffect(() => {
    getAllRequestInstitutions(activeData);
  }, [limit]);

  const columns = [
    {
      name: props.t("Sr_No"),
      selector: (row, index) => index + 1,
      sort: "asc",
      width: 20,
      sortable: true,
      defaultSortField: true,
      defaultSortAsc: false
    },
    {
      name: props.t("Institution_Name"),
      selector: row => row.institution_name,
      sort: "asc",
      width: 270,
      sortable: true,
      defaultSortField: true,
      defaultSortAsc: false
    },
    {
      name: props.t("Organization_Type"),
      selector: row => row.organization_type,
      sort: "asc",
      width: 270,
      sortable: true,
      defaultSortField: true,
      defaultSortAsc: false
    },
    {
      name: props.t("Affiliation"),
      selector: row => row.affiliation,
      sort: "asc",
      width: 270,
      sortable: true,
      defaultSortField: true,
      defaultSortAsc: false
    },
    {
      name: props.t("Institution_Address"),
      selector: row => row.insitution_address,
      sort: "asc",
      width: 270,
      sortable: true,
      defaultSortField: true,
      defaultSortAsc: false
    },
    {
      name: props.t("Established_Yr"),
      selector: row => row.established_year,
      sort: "asc",
      width: 270,
      sortable: true,
      defaultSortField: true,
      defaultSortAsc: false
    },
    {
      name: props.t("Status"),
      selector: row => row.status,
      sort: "asc",
      width: 270,
      sortable: true,
      defaultSortField: true,
      defaultSortAsc: false
    },
    {
      name: props.t("Action"),
      selector: row => row.action,
      sort: "asc",
      width: 200,
    }
  ];



  const getAllRequestInstitutions = (data) => {
    setLoading(true);
    RequestInstitutionApi.getAllRequestInstitutions(data)
      .then((res) => {
        console.log(res)

        setActiveData({
          activePage: activeData.activePage,
          totalPage: res.data.requestinstitute.count,
          search: activeData.search,
          limit: activeData.limit,
        });
        let data = [];
        res.data.requestinstitute.rows.forEach((v, i) => {
          data[i] = {
            id: v.id,
            institution_name: v.institution_name,
            organization_type: v.OrganizationType?.name_en,
            affiliation: v.affiliation,
            insitution_address: v.insitution_address,
            established_year: v.established_year,
            status: v.status,
            action: (
              <>
                <Link
                  to={`/view-request-institute/${v.id}`}
                >
                  <Button
                    type="button"
                    color="primary"
                    className="btn-sm btn-rounded"
                  >
                    {props.t("View")}
                  </Button>
                </Link>
              </>
            ),
          };
        });
        setRoles(data);
        setLoading(false);


      })
      .catch((err) => {
        console.log(err);
      });
  };


  const handleChange = (v) => {
    setActiveData({ activePage: v, totalPage: activeData.totalPage, search: activeData.search, limit: limit });
    const data = { activePage: v, totalPage: activeData.totalPage, search: activeData.search, limit: limit }
    getAllRequestInstitutions(data)
  }


  const handleRowChange = (v) => {
    setCurrentLimit(v);
    setActiveData({ activePage: activeData.activePage, totalPage: activeData.totalPage, search: activeData.search, limit: v });
    const data = { activePage: activeData.activePage, totalPage: activeData.totalPage, search: activeData.search, limit: v }
    getAllRequestInstitutions(data)
  }

  const handleChangeSearch = () => {
    getAllRequestInstitutions(activeData)
  }

  const OnSearchKeyUp = (e) => {
    setRequestInstituteName(e.target.value);
    if(e.key == "Enter") {
      setActiveData({ activePage: activeData.activePage, totalPage: activeData.totalPage, search: e.target.value, limit: activeData.limit });
      const data = { activePage: activeData.activePage, totalPage: activeData.totalPage, search: e.target.value, limit: activeData.limit }
      getAllRequestInstitutions(data);
      setRequestInstituteName(requestInstituteName);
      setAutoSearchData([]);
      return false;
    }
  }

  const OnSearch = (e) => {
    let data = { keyword: e.target.value };
    setRequestInstituteName(e.target.value);
    if(e.target.value != '') {
      RequestInstitutionApi.getRequestInstitutionsByName(data)
        .then((res) => {
          if (res.data.success) {
            setAutoSearchData(res.data.data);
          } else {
            setAutoSearchData([]);
          }
        })
        .catch((err) => {
          console.log(err);
      });
    }  else {
      setAutoSearchData([]);
    }
    // setActiveData({ ...activeData, search: e.target.value })
  }

  const searchByInstituteName = (keyword) => {
    setActiveData({ activePage: activeData.activePage, totalPage: activeData.totalPage, search: keyword, limit: activeData.limit });
    const data = { activePage: activeData.activePage, totalPage: activeData.totalPage, search: keyword, limit: activeData.limit }
    getAllRequestInstitutions(data);
    setRequestInstituteName(keyword);
    setAutoSearchData([]);
  }

  const searchRecord = () => {
    activeData.requestInstituteName = (requestInstituteName !== undefined) ? requestInstituteName : "";

    setActiveData({ activePage: activeData.activePage, totalPage: activeData.totalPage, search: activeData.requestInstituteName, limit: activeData.limit });
    const data = { activePage: activeData.activePage, totalPage: activeData.totalPage, search: activeData.requestInstituteName, limit: activeData.limit }
    getAllRequestInstitutions(data);
    setRequestInstituteName(requestInstituteName);
  }



  //meta title
  document.title = props.t("Req_Institutions") + ' | ' + props.t("MedX");

  return (
    <div className="page-content">
      <div className="container-fluid">
        <Breadcrumbs title={props.t("Req_Institution")} breadcrumbItem={props.t("Req_Institutions")} />

      </div>
      <div style={{ margin: '12px' }} className="d-flex ">
        {/* <Input onChange={OnSearch} className="w-25" name="search" placeholder={props.t("Search")} />
        <button style={{ marginleft: '10px' }} onClick={handleChangeSearch} className="btn btn-primary">{props.t("Search")}</button>
      */}


      <div className="wrapper w-40">
        <div className="search-input">
          <a href="" target="_blank" hidden="" />
         
          <input type="text" defaultValue={requestInstituteName} value={requestInstituteName} onChange={(e) => OnSearch(e) } onKeyUp = {OnSearchKeyUp}  placeholder="Type to search.."/>
          {autoSearch.length > 0 && requestInstituteName != '' && (
            <div className="autocom-box">
              {autoSearch?.map((searchdata, index) => {
                return (<li key={index} onClick={() => searchByInstituteName(searchdata.institution_name)}  >{searchdata.institution_name}</li>)
              })}
            </div>
          )}
          <div className="icon">
            <i className="fas fa-search" onClick={() => searchRecord()}/>
          </div>
        </div>
      </div>

      </div> 



      <div className="container-fluid">
        <DataTable
          className="table-bordered"
          progressPending={loading}
          columns={columns}
          data={roles}
          pagination
          paginationServer
          paginationTotalRows={activeData.totalPage}
          paginationPerPage={activeData.limit}
          defaultSortFieldID={1}
          onChangeRowsPerPage={value => handleRowChange(value)}
          onChangePage={value => handleChange(value)}
          sortable
          noHeader
          defaultSortField="id"
          defaultSortAsc={false}
          highlightOnHover
        />
      </div>
    </div>
  );
}

RequestInstitutions.propTypes = {
  t: PropTypes.any,
  preGlobalFilteredRows: PropTypes.any,
};

export default withTranslation()(RequestInstitutions);