import React, { useEffect, useState } from "react";
import { withTranslation } from "react-i18next";

import PropTypes from 'prop-types';

//import components
import Breadcrumbs from '../../../components/Common/Breadcrumb';
import DataTable from 'react-data-table-component';
import { InstituteIndexesApi } from "apis/InstituteIndexesApi";
import { useHistory } from "react-router-dom"
import * as Yup from "yup";
import { useFormik } from "formik";
import toastr from "toastr";
import config from "config/config";
import constants from "../../../config/constants";

import {
    Container,
    Row,
    Col,
    Card,
    CardBody,
    Form,
    Label,
    Input,
    FormFeedback,
    Button
} from "reactstrap";

import Swal from "sweetalert2";

function InstituteIndex(props) {

    const history = useHistory();
    const [InstituteIndexesList, setInstituteIndexesList] = useState([]);
    const [fetchInstituteIndex, setFetchInstituteIndex] = useState([]);
    const [postIndexId, setIndexId] = useState("");
    const [loading, setLoading] = useState(false);

    const [activeData, setActiveData] = useState({
        page: 1,
        totalPage: 1,
        search: "",
        limit: config.LIMIT
    });
    const [page, setCurrentPage] = useState(1);
    const [limit, setCurrentLimit] = useState(config.LIMIT);

    const formClear = () => {
        validationType.resetForm();
    };

    useEffect(() => {
        getAllInstituteIndexesList(activeData)
    }, [page,limit])



    const columns = [
        {
            name: props.t("Sr_No"),
            selector: row => row.index_id,
            sort: "asc",
            width: 50,
            sortable: true,
            defaultSortField: true,
            defaultSortAsc: false
        },
        {
            name: props.t("Name_Eng"),
            selector: row => row.name_en,
            sort: "asc",
            width: 200,
            sortable: true,
            defaultSortField: true,
            defaultSortAsc: false
        },
        {
            name: props.t("Name_Thai"),
            selector: row => row.name_th,
            sort: "asc",
            width: 200,
            sortable: true,
            defaultSortField: true,
            defaultSortAsc: false
        },
        {
            name: props.t("Institute_Type"),
            selector: row => row.institute_type,
            sort: "asc",
            width: 100,
            sortable: true,
            defaultSortField: true,
            defaultSortAsc: false
        },
        {
            name: props.t("Action"),
            selector: row => row.action,
            sort: "asc",
            width: 200,
        }
    ];

    const TableData = [
        {
            quiz_id: 1,
            name_en: "Name english",
            name_th: "Name Thai",
            institute_type: "Primary",
            action: (
                <>

                    <i className="fas fa-edit" id="deletetooltip" style={{ color: "blue" }} onClick={() => onEdit(v.id)} />{" "}
                    <i className="mdi mdi-delete font-size-18" style={{ color: "red" }} id="deletetooltip" onClick={() => onDelete(v.id)} />
                </>
            ),
        },

        {
            quiz_id: 2,
            name_en: "Name english",
            name_th: "Name Thai",
            institute_type: "Primary",
            action: (
                <>

                    <i className="fas fa-edit" id="deletetooltip" style={{ color: "blue" }} onClick={() => onEdit(v.id)} />{" "}
                    <i className="mdi mdi-delete font-size-18" style={{ color: "red" }} id="deletetooltip" onClick={() => onDelete(v.id)} />
                </>
            ),
        }
    ];


    const getAllInstituteIndexesList = (data) => {
        setLoading(true);
        InstituteIndexesApi.getAllInstituteIndexes(data)
            .then((res) => {
                console.log(res)
                setActiveData({
                    page: activeData.page,
                    totalPage: res?.data?.data?.count,
                    search: activeData.search,
                    limit: activeData.limit,
                });
                let data = [];
                var sr_no = res.data.sr_no_start;

                res?.data?.data?.rows?.forEach((v, i) => {
                    sr_no = sr_no + 1;
                    data[i] = {
                        index_id: v.id,
                        name_en: v.name_en,
                        name_th: v.name_th,
                        institute_type: v.institute_type,
                        action: (
                            <>

                                <i className="fas fa-edit" id="deletetooltip" style={{ color: "blue" }} onClick={() => onEdit(v.id)} />{" "}
                                <i className="mdi mdi-delete font-size-18" style={{ color: "red" }} id="deletetooltip" onClick={() => onDelete(v.id)} />
                            </>
                        ),
                    };
                });
                setInstituteIndexesList(data);
                setLoading(false);
            })
            .catch((err) => {
                console.log(err);
            });
    };

    const onDelete = (id) => {

        Swal.fire({
            title: props.t("Are_you_sure"),
            text: props.t("Able_to_revert_this"),
            icon: props.t("Warning"),
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#f46a6a",
            confirmButtonText: props.t("Yes_delete_it"),
            cancelButtonText: props.t("Cancel"),
        }).then((result) => {
            if (result.isConfirmed) {
                let IndexId = { "index_id": id };
                InstituteIndexesApi.deleteInstituteIndex(IndexId)
                    .then((res) => {
                        Swal.fire(props.t("Deleted"), res.data.message, props.t("Success"));
                        setIndexId();
                        formClear();
                        handleChange(page);
                        //getAllBanner(activeData)
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            }
        });
    };

    const onEdit = (id) => {
        let IndexId = { "index_id": id };
        setIndexId(id);
        InstituteIndexesApi.getInstituteIndexById(IndexId)
            .then((res) => {
                setFetchInstituteIndex(res.data.data);
            })
            .catch((err) => {
                console.log(err);
            });
    };


    const validationType = useFormik({
        enableReinitialize: true,

        initialValues: {
            index_id: fetchInstituteIndex ? fetchInstituteIndex.id : "",
            name_en: fetchInstituteIndex ? fetchInstituteIndex.name_en : "",
            name_th: fetchInstituteIndex ? fetchInstituteIndex.name_th : "",
            institute_type: fetchInstituteIndex ? fetchInstituteIndex.institute_type : "",
        },

        validationSchema: Yup.object().shape({

            name_en: Yup.string().required(
                props.t("Required-Message")
            ),
            name_th: Yup.string().required(
                props.t("Required-Message")
            ),
            institute_type: Yup.string().required(
                props.t("Required-Message")
            ),
        }),

        onSubmit: (values) => {
            const data = new FormData();
            data.append("index_id", postIndexId)
            data.append("name_en", values.name_en);
            data.append("name_th", values.name_th);
            data.append("institute_type", values.institute_type);

            if (postIndexId) {
                values.index_id = postIndexId;
                InstituteIndexesApi.updateInstituteIndex(data)
                    .then(res => {
                        if (res.data.success) {
                            Swal.fire({
                                text: res.data.message,
                                icon: 'success',
                                imageAlt: 'success image',
                            }).then((result) => {
                                if (result.isConfirmed) {
                                    history.push('/institute-index');
                                    setIndexId();
                                    formClear()
                                    getAllInstituteIndexesList(activeData)
                                }
                            });
                        } else {
                            toastr.error(res.message, '');
                        }
                    }).catch(function (error) {
                        if (error?.response?.data?.message) {
                            toastr.error(error?.response?.data?.message, '');
                        }
                        else {
                            toastr.error(error?.response?.data?.error?.errors?.map((item, v) => `${item.msg}<br>`), '');
                        }
                    });
            }
            else {
                InstituteIndexesApi.createInstituteIndexes(data)
                    .then(res => {
                        if (res.data.success) {
                            Swal.fire({
                                text: res.data.message,
                                icon: 'success',
                                imageAlt: 'success image',
                            }).then((result) => {
                                if (result?.isConfirmed) {
                                    history.push('/institute-index');
                                    formClear()
                                    getAllInstituteIndexesList(activeData)
                                }
                            });
                        } else {
                            toastr.error(res?.data?.message, '');
                        }
                    }).catch(function (error) {
                        if (error?.response?.data?.message) {
                            toastr.error(error?.response?.data?.message, '');
                        }
                        else {
                            toastr.error(error?.response?.data?.error?.errors?.map((item, v) => `${item.msg}<br>`), '');
                        }
                    });
            }

        }
    })

    const handleChange = (v) => {
        setCurrentPage(v);
        setActiveData({ page: v, totalPage: activeData.totalPage, search: activeData.search, limit: limit });
        const data = { page: v, totalPage: activeData.totalPage, search: activeData.search, limit: limit }
        getAllBanner(data)
    }
    const handleRowChange = (v) => {
        setCurrentLimit(v);
        setActiveData({ page: activeData.page, totalPage: activeData.totalPage, search: activeData.search, limit: v });
        const data = { page: activeData.page, totalPage: activeData.totalPage, search: activeData.search, limit: v }
        getAllBanner(data)
    }

    document.title = props.t("Institute_Index") + ' | ' + props.t("MedX");


    return (
        <div className="page-content">
            <div className="container-fluid">
                <Breadcrumbs title={props.t("Master")} breadcrumbItem={props.t("Institute_Index")} />
            </div>
            <Container fluid={false}>
                <Row>
                    <Col lg={8}>
                        <Card>
                            <CardBody>
                                <Col sm={12}>
                                    <DataTable
                                        className="table-bordered"
                                        columns={columns}
                                        data={InstituteIndexesList}
                                    />
                                </Col>
                            </CardBody>
                        </Card>
                    </Col>
                    <Col lg={4}>
                        <Card>
                            <CardBody>
                                <Col sm={12}>
                                    <Form
                                        onSubmit={(e) => {
                                            e.preventDefault();
                                            validationType.handleSubmit();
                                            return false;
                                        }}
                                    >
                                        <Col sm={12}>
                                            <div className="mb-3">
                                                <Label className="form-label">{props.t("Name_Eng")}</Label>
                                                <Input
                                                    name="name_en"
                                                    placeholder={props.t("Name_Eng")}
                                                    type="text"
                                                    onChange={validationType.handleChange}
                                                    onBlur={validationType.handleBlur}
                                                    value={validationType.values.name_en || ""}
                                                    invalid={
                                                        validationType.touched.name_en && validationType.errors.name_en ? true : false
                                                    }
                                                />
                                                {validationType.touched.name_en && validationType.errors.name_en ? (
                                                    <FormFeedback type="invalid">{validationType.errors.name_en}</FormFeedback>
                                                ) : null}
                                            </div>
                                        </Col>

                                        <Col sm={12}>
                                            <div className="mb-3">
                                                <Label className="form-label">{props.t("Name_Thai")}</Label>
                                                <Input
                                                    name="name_th"
                                                    placeholder={props.t("Name_Thai")}
                                                    type="text"
                                                    onChange={validationType.handleChange}
                                                    onBlur={validationType.handleBlur}
                                                    value={validationType.values.name_th || ""}
                                                    invalid={
                                                        validationType.touched.name_th && validationType.errors.name_th ? true : false
                                                    }
                                                />
                                                {validationType.touched.name_th && validationType.errors.name_th ? (
                                                    <FormFeedback type="invalid">{validationType.errors.name_th}</FormFeedback>
                                                ) : null}
                                            </div>
                                        </Col>

                                        <Col sm={12}>
                                            <div className="mb-3">
                                                <Label className="form-label">{props.t("Institute_Type")}</Label>
                                                <select
                                                    name="institute_type"
                                                    className="form-control"
                                                    placeholder={props.t("Institute_Type")}
                                                    type="select"
                                                    onChange={validationType.handleChange}
                                                    onBlur={validationType.handleBlur}
                                                    value={validationType.values.institute_type || ""}

                                                >
                                                    {
                                                        Object.keys(constants.INSTITUTE_INDEX_TYPES).map(key => {
                                                            return (
                                                                <option value={key} key={key}>{constants.INSTITUTE_INDEX_TYPES[key]}</option>
                                                            )
                                                        })
                                                    }
                                                </select>
                                                {validationType.touched.institute_type && validationType.errors.institute_type ? (
                                                    <div style={{ color: '#f46a6a', fontSize: '11px', marginTop: '3px' }}>
                                                        {validationType.errors.institute_type}
                                                    </div>
                                                ) : null}
                                            </div>
                                        </Col>

                                        <div className="d-flex flex-wrap gap-2">
                                            <Button type="submit" color="primary" >
                                                {props.t("Submit")}
                                            </Button>{" "}
                                            <Button color="secondary" onClick={e => formClear()}>
                                                {props.t("Clear")}
                                            </Button>
                                        </div>

                                    </Form>
                                </Col>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default withTranslation()(InstituteIndex);