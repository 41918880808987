import { EvalutionFormApi } from "apis/EvalutionFormApi"
import config from "config/config"
import { useFormik } from "formik"
import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import DataTable from "react-data-table-component"
import { withTranslation } from "react-i18next"
import { useParams } from "react-router-dom"
import toastr from "toastr"
import * as Yup from "yup"
import Breadcrumbs from "../../../components/Common/Breadcrumb"

import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Form,
  FormFeedback,
  Input,
  Label,
  Row,
} from "reactstrap"

import Swal from "sweetalert2"

function ViewEvalutionForm(props) {
  const [formFieldList, setFormFieldList] = useState([])
  const [formFieldId, setFormFieldId] = useState(null)
  const [formFieldType, setFormFieldType] = useState("TEXT")
  const [modal, setModal] = useState(false)
  const [loading, setLoading] = useState(false)
  const [formValues, setFormValues] = useState([
    { option_en: "", option_th: "", isDeleted: false },
    { option_en: "", option_th: "", isDeleted: false },
  ])
  const [errorMsg, setErrorMsg] = useState("")
  const [validateError, setValidateError] = React.useState({
    label_en: "",
    label_th: "",
    optionsErr: "",
  })
  const [titleEn, setTitleEn] = useState("")
  const [titleTh, setTitleTh] = useState("")
  const [isRequired, setIsRequired] = useState(false)
  const [isRemark, setIsRemark] = useState(false)
  const [rank, setRank] = useState(null)

  const { id } = useParams()

  const toggle = () => setModal(!modal)

  let removeFormFields = i => {
    let newFormValues = [...formValues]
    newFormValues[i].isDeleted = true
    setFormValues(newFormValues)
  }

  let handleChangeOptions = (i, e) => {
    let newFormValues = [...formValues]
    newFormValues[i][e.target.name] = e.target.value
    setFormValues(newFormValues)
  }

  const [activeData, setActiveData] = useState({
    page: 1,
    totalPage: 1,
    search: "",
    limit: config.LIMIT,
    evalution_form_id: id,
  })
  const [page, setCurrentPage] = useState(1)

  useEffect(() => {
    getAllEvalutionFormDetails(activeData)
  }, [page])

  const formClear = () => {
    setValidateError({
      label_en: "",
      label_th: "",
      optionsErr: "",
    })
    setTitleEn("")
    setTitleTh("")
    setRank(null)
    setIsRemark(false)
    setIsRequired(false)
    setFormValues([
      { option_en: "", option_th: "", isDeleted: false },
      { option_en: "", option_th: "", isDeleted: false },
    ])
    document.getElementById("is_remark").checked = false
    document.getElementById("is_required").checked = false
  }

  const columns = [
    {
      name: props.t("Sr_No"),
      selector: row => row.evalution_form_detail_id,
      sort: "asc",
      width: 150,
      sortable: true,
      defaultSortField: true,
      defaultSortAsc: false,
    },
    {
      name: props.t("Title_Eng"),
      selector: row => row.label_en,
      sort: "asc",
      width: 270,
      sortable: true,
      defaultSortField: true,
      defaultSortAsc: false,
    },
    {
      name: props.t("Title_Thai"),
      selector: row => row.label_th,
      sort: "asc",
      width: 270,
      sortable: true,
      defaultSortField: true,
      defaultSortAsc: false,
    },
    {
      name: props.t("Rank"),
      selector: row => row.rank,
      sort: "asc",
      width: 270,
      sortable: true,
      defaultSortField: true,
      defaultSortAsc: false,
    },
    {
      name: props.t("formType"),
      selector: row => row.form_type,
      sort: "asc",
      width: 270,
      sortable: true,
      defaultSortField: true,
      defaultSortAsc: false,
    },
    {
      name: props.t("Action"),
      selector: row => row.action,
      sort: "asc",
      width: 200,
    },
  ]

  const getAllEvalutionFormDetails = data => {
    setLoading(true)
    EvalutionFormApi.getAllEvalutionFormDetails(data)
      .then(res => {
        setActiveData({
          page: activeData.page,
          totalPage: res?.data?.data?.count,
          search: activeData.search,
          limit: activeData.limit,
          evalution_form_id: activeData.evalution_form_id,
        })
        let data = []
        var sr_no = res.data.sr_no_start

        res?.data?.data?.rows?.forEach((v, i) => {
          sr_no = sr_no + 1
          data[i] = {
            evalution_form_detail_id: sr_no,
            label_en: v.label_en,
            label_th: v.label_th,
            rank: v.rank,
            // description_en: v.description_en,
            // description_th: v.description_th,
            form_type: v.form_type,
            action: (
              <>
                <i
                  className="fas fa-edit"
                  id="deletetooltip"
                  style={{ color: "blue" }}
                  onClick={() => onEditFormField(v.id)}
                />{" "}
                <i
                  className="mdi mdi-delete font-size-18"
                  style={{ color: "red" }}
                  id="deletetooltip"
                  onClick={() => onDelete(v.id)}
                />
              </>
            ),
          }
        })
        setFormFieldList(data)
        setLoading(false)
      })
      .catch(err => {
        console.log(err)
      })
  }

  const onDelete = id => {
    Swal.fire({
      title: props.t("Are_you_sure"),
      text: props.t("Able_to_revert_this"),
      icon: props.t("Warning"),
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#f46a6a",
      confirmButtonText: props.t("Yes_delete_it"),
      cancelButtonText: props.t("Cancel"),
    }).then(result => {
      if (result.isConfirmed) {
        let eValutionformdetailId = { evalution_form_detail_id: id }
        EvalutionFormApi.deleteEvalutionFormDetail(eValutionformdetailId)
          .then(res => {
            Swal.fire(props.t("Deleted"), res.data.message, props.t("Success"))
            setFormFieldId()
            formClear()
            handleChange(page)
            //getAllEvalutionFormDetails(activeData)
          })
          .catch(err => {
            console.log(err)
          })
      }
    })
  }

  const onEditFormField = id => {
    setFormFieldId(id)
    const payload = { form_field_id: id }
    EvalutionFormApi.getEvalutionFormFieldsApi(payload)
      .then(res => {
        if (res.data.success) {
          const formFieldOptions = res.data.data.EvaluationFormFieldOption
          let optionData = []
          formFieldOptions.map((option, i) => {
            optionData[i] = {
              id: option.id,
              option_en: option.option_en,
              option_th: option.option_th,
              isDeleted: false,
            }
          })
          setFormValues(optionData)
          setTitleEn(res.data.data.label_en)
          setTitleTh(res.data.data.label_th)
          setIsRemark(res.data.data?.is_remark)
          setIsRequired(res.data.data?.is_required)
          setFormFieldType(res.data.data.form_type)
          setRank(parseInt(res.data.data.rank))
          if (res.data.data?.is_remark) {
            document.getElementById("is_remark").checked = true
          } else {
            document.getElementById("is_remark").checked = false
          }
          if (res.data.data?.is_required) {
            document.getElementById("is_required").checked = true
          } else {
            document.getElementById("is_required").checked = false
          }
          window.scrollTo(0, 0)
        } else {
          toastr.error(res.data?.message, "")
        }
      })
      .catch(err => {
        console.log(err)
        toastr.error(err?.response?.data?.message, "")
      })
  }

  const handleChange = v => {
    setCurrentPage(v)
    setActiveData({
      page: v,
      totalPage: activeData.totalPage,
      search: activeData.search,
      limit: activeData.limit,
      evalution_form_id: activeData.evalution_form_id,
    })
    const data = {
      page: v,
      totalPage: activeData.totalPage,
      search: activeData.search,
      limit: activeData.limit,
      evalution_form_id: activeData.evalution_form_id,
    }
    getAllEvalutionFormDetails(data)
  }

  const handleRowChange = v => {
    setActiveData({
      page: activeData.page,
      totalPage: activeData.totalPage,
      search: activeData.search,
      limit: v,
      evalution_form_id: activeData.evalution_form_id,
    })
    const data = {
      page: activeData.page,
      totalPage: activeData.totalPage,
      search: activeData.search,
      limit: v,
      evalution_form_id: activeData.evalution_form_id,
    }
    getAllEvalutionFormDetails(data)
  }

  const addFormFields = () => {
    let newFormValues = [
      ...formValues,
      { option_en: "", option_th: "", isDeleted: false },
    ]
    setFormValues(newFormValues)
  }

  const inputChange = e => {
    if (
      e &&
      e.target &&
      typeof e.target.value != "undefined" &&
      e.target.name
    ) {
      let value = e.target.value
      switch (e.target.name) {
        case "label_en":
          setTitleEn(value)
          break
        case "label_th":
          setTitleTh(value)
          break
        case "is_required":
          setIsRequired(e.target.checked)
          break
        case "is_remark":
          setIsRemark(e.target.checked)
          break
        case "rank":
          setRank(value)
          break
        default:
        // code block
      }
    }
  }

  const checkFormValidation = async check => {
    if (check) {
      let ValidateError = {}
      if (titleEn === "") {
        ValidateError.label_en = props.t("Required-Message")
      }

      if (titleTh === "") {
        ValidateError.label_th = props.t("Required-Message")
      }

      if (rank === null) {
        ValidateError.rank = props.t("Required-Message")
      }

      if (formFieldType === "QUESTION") {
        if (
          formValues[0].option_en === "" ||
          formValues[0].option_th === "" ||
          formValues[1].option_en === "" ||
          formValues[1].option_th === ""
        ) {
          setErrorMsg(props.t("Required-Message"))
          ValidateError.formValues = props.t("Required-Message")
          // return false
        }
      }
      setValidateError(ValidateError)
      return ValidateError
    } else {
      return {}
    }
  }

  const onSubmitEvaluationFormField = async () => {
    let errors = await checkFormValidation(true)
    let errorsSize = Object.keys(errors).length
    if (errorsSize != 0) {
      return false
    }

    const payload = {
      evaluation_form_id: id,
      evalution_form_detail_id: formFieldId,
      label_en: titleEn,
      label_th: titleTh,
      is_required: isRequired,
      is_remark: isRemark,
      rank: rank,
      form_type: formFieldType,
      optionsArr: formValues || "",
    }

    setErrorMsg("")
    if (formFieldId) {
      EvalutionFormApi.updateEvalutionFormDetail(payload)
        .then(res => {
          if (res.data.success) {
            Swal.fire({
              text: res.data.message,
              icon: "success",
              imageAlt: "success image",
            }).then(result => {
              if (result.isConfirmed) {
                setFormFieldId()
                formClear()
                getAllEvalutionFormDetails(activeData)
              }
            })
          } else {
            toastr.error(res.message, "")
          }
        })
        .catch(function (error) {
          if (error?.response?.data?.message) {
            toastr.error(error?.response?.data?.message, "")
          } else {
            toastr.error(
              error?.response?.data?.error?.errors?.map(
                (item, v) => `${item.msg}<br>`
              ),
              ""
            )
          }
        })
    } else {
      EvalutionFormApi.createEvalutionFormDetail(payload)
        .then(res => {
          if (res.data.success) {
            Swal.fire({
              text: res.data.message,
              icon: "success",
              imageAlt: "success image",
            }).then(result => {
              if (result?.isConfirmed) {
                formClear()
                getAllEvalutionFormDetails(activeData)
              }
            })
          } else {
            toastr.error(res?.data?.message, "")
          }
        })
        .catch(function (error) {
          if (error?.response?.data?.message) {
            toastr.error(error?.response?.data?.message, "")
          } else {
            toastr.error(
              error?.response?.data?.error?.errors?.map(
                (item, v) => `${item.msg}<br>`
              ),
              ""
            )
          }
        })
    }
  }

  //meta title
  document.title = props.t("View Evalution Form") + " | " + props.t("MedX")

  return (
    <div className="page-content">
      <div className="container-fluid">
        <Breadcrumbs
          title={props.t("Master")}
          breadcrumbItem={props.t("View Evalution Form")}
        />
      </div>
      <Container fluid={false}>
        <Row>
          <Col lg={12}>
            <Card>
              <CardBody>
                <div className="row">
                  <div className="col-sm-4 mb-3">
                    <Input
                      type="radio"
                      value="TEXT"
                      checked={formFieldType == "TEXT" ? true : false}
                      name="form_type"
                      onMouseUp={e => setFormFieldType(e.target.value)}
                      defaultChecked={true}
                    />
                    <Label>&nbsp;{props.t("text")}</Label>
                  </div>

                  <div className="col-sm-4 mb-3">
                    <Input
                      type="radio"
                      value="MULTITEXT"
                      name="form_type"
                      checked={formFieldType == "MULTITEXT" ? true : false}
                      onMouseUp={e => {
                        setFormFieldType(e.target.value)
                      }}
                    />
                    <Label>&nbsp;{props.t("multitext")}</Label>
                  </div>

                  <div className="col-sm-4 mb-3">
                    <Input
                      type="radio"
                      value="QUESTION"
                      name="form_type"
                      checked={formFieldType == "QUESTION" ? true : false}
                      onMouseUp={e => {
                        setFormFieldType(e.target.value)
                      }}
                      onClick={toggle}
                    />
                    <Label>&nbsp; {props.t("options")}</Label>
                  </div>
                </div>

                {/* TEXT BOX */}
                <div className="row">
                  <Row className="mb-3">
                    <Col sm={6}>
                      <Label className="form-label">
                        {props.t("Title_Eng")}
                      </Label>
                      <Input
                        type="text"
                        name="label_en"
                        className=""
                        onChange={inputChange}
                        value={titleEn || ""}
                        invalid={validateError.label_en ? true : false}
                      />
                      {validateError.label_en ? (
                        <FormFeedback type="invalid">
                          {validateError.label_en}
                        </FormFeedback>
                      ) : null}
                    </Col>
                    <Col sm={6}>
                      <Label className="form-label">
                        {props.t("Title_Thai")}
                      </Label>
                      <Input
                        type="text"
                        name="label_th"
                        className=""
                        onChange={inputChange}
                        value={titleTh || ""}
                        invalid={validateError.label_th ? true : false}
                      />
                      {validateError.label_th ? (
                        <FormFeedback type="invalid">
                          {validateError.label_th}
                        </FormFeedback>
                      ) : null}
                    </Col>
                  </Row>

                  {formFieldType == "QUESTION" && (
                    <Row>
                      {formValues.map((element, index) => {
                        if (!element.isDeleted) {
                          return (
                            <div className="row" key={`questionOpEv_${index}`}>
                              <Col sm={4}>
                                <div className="mb-3">
                                  <Label
                                    htmlFor="option_en"
                                    className="form-label"
                                  >
                                    {index + 1 + ". " + props.t("optionEn")}{" "}
                                  </Label>
                                  <Input
                                    key={`{optionEng}` + index}
                                    type="text"
                                    name="option_en"
                                    className="form-control"
                                    onChange={e => {
                                      handleChangeOptions(index, e)
                                    }}
                                    value={element.option_en || ""}
                                    invalid={
                                      errorMsg !== "" &&
                                      element.option_en === "" &&
                                      index <= 1
                                        ? true
                                        : false
                                    }
                                  />
                                  {errorMsg !== "" &&
                                  element.option_en === "" &&
                                  index <= 1 ? (
                                    <FormFeedback type="invalid">
                                      {errorMsg}
                                    </FormFeedback>
                                  ) : null}
                                </div>
                              </Col>
                              <Col sm={4}>
                                <div className="mb-3">
                                  <Label
                                    htmlFor="option_th"
                                    className="form-label"
                                  >
                                    {index + 1 + ". " + props.t("optionTh")}{" "}
                                  </Label>
                                  <Input
                                    key={`{optionThai}` + index}
                                    type="text"
                                    name="option_th"
                                    className="form-control"
                                    onChange={e => {
                                      handleChangeOptions(index, e)
                                    }}
                                    value={element.option_th || ""}
                                    invalid={
                                      errorMsg !== "" &&
                                      element.option_th === "" &&
                                      index <= 1
                                        ? true
                                        : false
                                    }
                                  />
                                  {errorMsg !== "" &&
                                  element.option_th === "" &&
                                  index <= 1 ? (
                                    <FormFeedback type="invalid">
                                      {errorMsg}
                                    </FormFeedback>
                                  ) : null}
                                </div>
                              </Col>
                              <Col sm={2}>
                                <div className="mb-3">
                                  {index > 1 ? (
                                    <a
                                      href="#"
                                      key={`{remove}` + index}
                                      onClick={e => {
                                        removeFormFields(index)
                                      }}
                                      title="Edit"
                                    >
                                      <img
                                        src={require("../../../assets/images/delete-icon.png")}
                                        width={15}
                                        height={15}
                                      />
                                    </a>
                                  ) : null}
                                </div>
                              </Col>
                            </div>
                          )
                        }
                      })}

                      <Col sm={2}>
                        <button
                          color="success"
                          key={"addMoreOption"}
                          type="button"
                          className="btn btn-success mb-3"
                          onClick={e => {
                            addFormFields()
                          }}
                        >
                          {props.t("Add_More")}
                        </button>
                      </Col>

                      {/* {errorMsg !== "" ? (
                          <p className="text-danger text-small">{errorMsg}</p>
                        ) : null} */}
                    </Row>
                  )}

                  <Row>
                    <Col sm={6}>
                      <span className="caret">
                        <div className="form-check">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            name="is_remark"
                            id="is_remark"
                            onChange={inputChange}
                          />
                          <Label className="form-label">
                            {props.t("isRemark")}
                          </Label>
                        </div>
                      </span>
                    </Col>
                    <Col sm={6}>
                      <span className="caret">
                        <div className="form-check">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            name="is_required"
                            id="is_required"
                            onChange={inputChange}
                          />
                          <Label className="form-label">
                            {props.t("isRequired")}
                          </Label>
                        </div>
                      </span>
                    </Col>
                    <Col sm={6}>
                      <Label className="form-label">{props.t("Rank")}</Label>
                      <Input
                        type="number"
                        name="rank"
                        id="rank"
                        className=""
                        min={1}
                        onChange={inputChange}
                        value={rank || ""}
                        invalid={validateError.rank ? true : false}
                      />
                      {validateError.rank ? (
                        <FormFeedback type="invalid">
                          {validateError.rank}
                        </FormFeedback>
                      ) : null}
                    </Col>
                  </Row>

                  <Row>
                    <div className="d-flex flex-wrap gap-2 mt-2">
                      <Button
                        type="button"
                        onClick={onSubmitEvaluationFormField}
                        color="primary"
                      >
                        {props.t("Submit")}
                      </Button>{" "}
                      <Button color="secondary" onClick={e => formClear()}>
                        {props.t("Clear")}
                      </Button>
                    </div>
                  </Row>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col lg={12}>
            <Card>
              <CardBody>
                <Col sm={12}>
                  <DataTable
                    className="table-bordered"
                    progressPending={loading}
                    columns={columns}
                    data={formFieldList}
                    pagination
                    paginationServer
                    paginationTotalRows={activeData.totalPage}
                    paginationPerPage={activeData.limit}
                    defaultSortFieldID={1}
                    onChangeRowsPerPage={value => handleRowChange(value)}
                    onChangePage={value => handleChange(value)}
                    sortable
                    noHeader
                    defaultSortField="id"
                    defaultSortAsc={false}
                    highlightOnHover
                  />
                </Col>
              </CardBody>
            </Card>
          </Col>
          <Row />
        </Row>
      </Container>
    </div>
  )
}
ViewEvalutionForm.propTypes = {
  t: PropTypes.any,
  preGlobalFilteredRows: PropTypes.any,
}
export default withTranslation()(ViewEvalutionForm)
