import { EvalutionFormApi } from "apis/EvalutionFormApi"
import { InternshipApi } from "apis/InternshipApi"
import { UserApi } from "apis/UserApi"
import Breadcrumb from "components/Common/Breadcrumb"
import { useFormik } from "formik"
import { cloneDeep, debounce } from "lodash"
import React, { useEffect, useState } from "react"
import { withTranslation } from "react-i18next"
import { useHistory } from "react-router-dom"
import Select from "react-select"
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Form,
  Label,
  Row,
} from "reactstrap"
import Swal from "sweetalert2"
import toastr from "toastr"
import "toastr/build/toastr.min.css"
import { getUserID } from "Token"
import * as Yup from "yup"

const AddEvaluators = props => {
  const history = useHistory()
  const sessionId = props.match.params.sessionId
  const taskTypeId = props.match.params.taskTypeId

  const [session, setSession] = useState()
  const [selectedTaskTypes, setSelectedTaskTypes] = useState([])
  const [internsData, setInternsData] = useState([])
  const [evaluationForms, setEvaluationForms] = useState([])

  useEffect(() => {
    getSessionTaskAndEvaaluators()
  }, [])

  useEffect(() => {
    getAllDoctors("INTERNAL")
    getInstituteAllEvaluationForms()
  }, [])

  const getInstituteAllEvaluationForms = () => {
    const payload = {
      user_id: getUserID(),
    }
    EvalutionFormApi.getInstituteEvaluationForms(payload)
      .then(res => {
        if (res.data.success) {
          let formsArr = []
          res.data.data.rows.length > 0 &&
            res.data.data.rows.map((form, i) => {
              formsArr[i] = {
                value: form.id,
                label: form.name_en,
              }
            })
          setEvaluationForms([...formsArr])
        } else {
          setEvaluationForms([])
        }
      })
      .catch(err => {
        setEvaluationForms([])
      })
  }

  const getSessionTaskAndEvaaluators = () => {
    const payload = {
      taskTypeId: taskTypeId,
    }
    InternshipApi.getSessionTaskAndEvalauatorsApi(sessionId, payload)
      .then(res => {
        if (res.data.success) {
          let sessiontasks = res.data.data.sessionTasks
          setSession(res.data.data.session)
          sessiontasks.map((task, i) => {
            let evaluatorArr = []
            task.taskEvaluators.map((evaluatorData, j) => {
              evaluatorArr.push(evaluatorData.value)
            })
            task.evaluators = evaluatorArr
            if (task.taskEvaluators.length > 0) {
              if (task.taskEvaluators[0].evaluationForm)
                task.evaluation_form_id =
                  task.taskEvaluators[0].evaluationForm.value
            }
          })
          setSelectedTaskTypes(sessiontasks)
        } else {
          // setSelectedTaskTypes([])
        }
      })
      .catch(err => {
        console.log(err)
      })
  }

  const getAllDoctors = (type, searchKeyword = "") => {
    const payload = {
      speaker_type: type,
      search: searchKeyword,
      page: 1,
      limit: 10,
    }
    UserApi.getSpeakerUser(payload)
      .then(res => {
        if (res.data.success) {
          let doctorArr = []
          res.data.data.rows.length > 0 &&
            res.data.data.rows.map((doctor, i) => {
              doctorArr[i] = {
                value: doctor.id,
                label:
                  doctor.first_name +
                  " " +
                  (doctor.last_name ? doctor.last_name : ""),
              }
            })
          setInternsData([...doctorArr])
        } else {
          setInternsData([])
        }
      })
      .catch(err => {
        setInternsData([])
      })
  }

  const validationType = useFormik({
    enableReinitialize: true,
    validateOnBlur: false,
    initialValues: {
      session_id: sessionId,
    },
    validationSchema: Yup.object().shape({}),
    onSubmit: values => {
      values.tasks = selectedTaskTypes
      InternshipApi.addEvalauatorsToSessionApi(values)
        .then(res => {
          if (res.data.success) {
            Swal.fire({
              text: res.data.message,
              icon: "success",
              imageAlt: "success image",
            }).then(result => {
              if (result?.isConfirmed) {
                history.push("/evaluation-list")
              }
            })
          } else {
            toastr.error(res?.data?.message, "")
          }
        })
        .catch(function (error) {
          if (error?.response?.data?.message) {
            toastr.error(error?.response?.data?.message, "")
          } else {
            toastr.error(
              error?.response?.data?.error?.errors?.map(
                (item, v) => `${item.msg}<br>`
              ),
              ""
            )
          }
        })
    },
  })

  const onChangeTaskDetailhandler = (index, key, value, valueInArr = []) => {
    const selectedTaskTypesCopy = cloneDeep(selectedTaskTypes)
    if (key === "start_date") {
      selectedTaskTypesCopy[index].start_date = value
    } else if (key === "end_date") {
      selectedTaskTypesCopy[index].end_date = value
    } else if (key === "evaluators") {
      selectedTaskTypesCopy[index].evaluators = value
      selectedTaskTypesCopy[index].taskEvaluators = valueInArr
    } else if (key === "evaluation_form_id") {
      selectedTaskTypesCopy[index].evaluation_form_id = value
      selectedTaskTypesCopy[index].taskEvaluators[0].evaluationForm = valueInArr
    }
    setSelectedTaskTypes(selectedTaskTypesCopy)
  }

  const onSearchInternHandler = debounce(function (searchInput) {
    if (searchInput.length >= 2)
      getAllDoctors("INTERNAL", searchInput.toString().trim())
  }, 500)

  document.title = props.t("addEvaluators") + " | " + props.t("MedX")

  return (
    <React.Fragment>
      <div className="page-content">
        <Breadcrumb
          title={props.t("internship")}
          breadcrumbItem={props.t("addEvaluators")}
        />
        <Container fluid={true}>
          <Row>
            <Col lg={12}>
              <Card>
                <CardBody>
                  <Col sm={12}>
                    <Form
                      onSubmit={e => {
                        e.preventDefault()
                        validationType.handleSubmit()
                        return false
                      }}
                    >
                      <>
                        {selectedTaskTypes.length > 0 && (
                          <>
                            <h4>Add evaluators for each task</h4>
                            <hr />
                          </>
                        )}
                        {selectedTaskTypes.length > 0 &&
                          selectedTaskTypes.map((selectedTask, index) => {
                            return (
                              <Row key={selectedTask.label}>
                                <Col sm={2}>
                                  <h5 className="">
                                    {selectedTask.label}
                                  </h5>
                                </Col>
                                <Col sm={5}>
                                  <div className="mb-3">
                                    <Label className="form-label">
                                      {props.t("evaluators")}{" "}
                                      <span className="text-danger">*</span>
                                    </Label>
                                    <Select
                                      className={
                                        validationType.touched.interns &&
                                        validationType.errors.interns
                                          ? " bor"
                                          : " text-dark  "
                                      }
                                      styles={{
                                        control: styles => ({
                                          ...styles,
                                        }),
                                      }}
                                      isMulti={true}
                                      value={selectedTask?.taskEvaluators || ""}
                                      onBlur={validationType.handleBlur}
                                      name="interns"
                                      options={[...internsData]}
                                      onChange={e => {
                                        const f = e.map((item, index) => {
                                          return item.value
                                        })
                                        onChangeTaskDetailhandler(
                                          index,
                                          "evaluators",
                                          f,
                                          e
                                        )
                                      }}
                                      onInputChange={onSearchInternHandler}
                                      placeholder={
                                        <div>{props.t("typeToSearch")}</div>
                                      }
                                    />
                                    {/* {validationType.touched.interns &&
                                      validationType.errors.interns ? (
                                        <div
                                          style={{
                                            color: "#f46a6a",
                                            fontSize: "11px",
                                            marginTop: "3px",
                                          }}
                                        >
                                          {validationType.errors.interns}
                                        </div>
                                      ) : null} */}
                                  </div>
                                </Col>
                                <Col sm={5}>
                                  <div className="mb-3">
                                    <Label className="form-label">
                                      {props.t("evaluationForm")}{" "}
                                      <span className="text-danger">*</span>
                                    </Label>
                                    <Select
                                      className={
                                        validationType.touched
                                          .evaluation_form_id &&
                                        validationType.errors.evaluation_form_id
                                          ? " bor"
                                          : " text-dark  "
                                      }
                                      styles={{
                                        control: styles => ({
                                          ...styles,
                                        }),
                                      }}
                                      value={
                                        selectedTask?.taskEvaluators[0]
                                          ?.evaluationForm || ""
                                      }
                                      // defaultValue={
                                      //   selectedTask?.taskEvaluators[0]
                                      //     ?.evaluationForm || ""
                                      // }
                                      name="evaluation_form_id"
                                      options={[...evaluationForms]}
                                      onChange={e => {
                                        onChangeTaskDetailhandler(
                                          index,
                                          "evaluation_form_id",
                                          e.value,
                                          e
                                        )
                                      }}
                                      placeholder={
                                        <div>{props.t("selectEvaluation")}</div>
                                      }
                                    />
                                    {validationType.touched
                                      .evaluation_form_id &&
                                    validationType.errors.evaluation_form_id ? (
                                      <div
                                        style={{
                                          color: "#f46a6a",
                                          fontSize: "11px",
                                          marginTop: "3px",
                                        }}
                                      >
                                        {
                                          validationType.errors
                                            .evaluation_form_id
                                        }
                                      </div>
                                    ) : null}
                                  </div>
                                </Col>
                              </Row>
                            )
                          })}
                      </>
                      <div className="d-flex flex-wrap gap-2">
                        <Button type="submit" color="primary">
                          {props.t("Submit")}
                        </Button>
                      </div>
                    </Form>
                  </Col>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withTranslation()(AddEvaluators)
