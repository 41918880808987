import React, { useMemo, useEffect, useState } from "react"

import {
  Form,
  Card,
  CardBody,
  Col,
  Row,
  Label,
  Input,
  FormFeedback,
  Button,
  Container,
} from "reactstrap"

// Form Editor
import { Editor } from "react-draft-wysiwyg"
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css"
import { convertToHTML } from "draft-convert"
import { EditorState, ContentState, convertFromHTML } from "draft-js"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { withTranslation } from "react-i18next"
import { CMSApi } from "apis/CMSApi"
import * as Yup from "yup"
import { useFormik } from "formik"
import toastr from "toastr"
import Swal from "sweetalert2"
import { useHistory } from "react-router-dom"

const EditPageContent = props => {
  const history = useHistory()
  const cms_page_id = props.match.params.cms_page_id
  const [fetchCMSPage, setFetchCMSPage] = useState([])
  const [contentEn, setContentEn] = useState(() => EditorState.createEmpty())
  const [contentTh, setContentTh] = useState(() => EditorState.createEmpty())
  const [convertedContentEn, setConvertedContentEn] = useState(null)
  const [convertedContentTh, setConvertedContentTh] = useState(null)

  //meta title
  document.title = "PageContent"

  useEffect(() => {
    getCMSById()
  }, [])

  const getCMSById = () => {
    let CMSPageId = { cms_page_id: cms_page_id }
    CMSApi.getCMSById(CMSPageId)
      .then(res => {
        setFetchCMSPage(res.data.data)
        console.log()
        setContentEn(
          EditorState.createWithContent(
            ContentState.createFromBlockArray(
              convertFromHTML(res.data.data.content_en)
            )
          )
        )
        setContentTh(
          EditorState.createWithContent(
            ContentState.createFromBlockArray(
              convertFromHTML(res.data.data.content_th)
            )
          )
        )
      })
      .catch(err => {
        console.log(err)
      })
  }

  const handleEditorContentEnChange = state => {
    setContentEn(state)
    convertContentEnTextToHTML()
  }
  const convertContentEnTextToHTML = () => {
    let currentContentEnTextAsHTML = convertToHTML(
      contentEn.getCurrentContent()
    )
    setConvertedContentEn(currentContentEnTextAsHTML)
  }

  const handleEditorContentThChange = state => {
    setContentTh(state)
    convertContentThTextToHTML()
  }
  const convertContentThTextToHTML = () => {
    let currentContentThTextAsHTML = convertToHTML(
      contentTh.getCurrentContent()
    )
    setConvertedContentTh(currentContentThTextAsHTML)
  }

  const validationType = useFormik({
    enableReinitialize: true,
    initialValues: {
      name_en: fetchCMSPage ? fetchCMSPage.name_en : "",
      name_th: fetchCMSPage ? fetchCMSPage.name_th : "",
      content_en: fetchCMSPage ? fetchCMSPage.content_en : "",
      content_th: fetchCMSPage ? fetchCMSPage.content_th : "",
    },

    validationSchema: Yup.object().shape({
      name_en: Yup.string().required(props.t("Required-Message")),
      name_th: Yup.string().required(props.t("Required-Message")),
      content_en: Yup.string().required(props.t("Required-Message")),
      content_th: Yup.string().required(props.t("Required-Message")),
    }),
    onSubmit: values => {
      const data = new FormData()
      data.append("name_en", values.name_en)
      data.append("name_th", values.name_th)
      data.append(
        "content_en",
        convertedContentEn ? convertedContentEn : values.content_en
      )
      data.append(
        "content_th",
        convertedContentTh ? convertedContentTh : values.content_th
      )
      data.append("cms_page_id", cms_page_id)
      CMSApi.updateCMS(data)
        .then(res => {
          if (res.data.success) {
            Swal.fire({
              text: res.data.message,
              icon: "success",
              imageAlt: "success image",
            }).then(result => {
              if (result.isConfirmed) {
                history.push("/page-content")
              }
            })
          } else {
            toastr.error(res.message, "")
          }
        })
        .catch(function (error) {
          if (error?.response?.data?.message) {
            toastr.error(error?.response?.data?.message, "")
          } else {
            toastr.error(
              error?.response?.data?.error?.errors?.map(
                (item, v) => `${item.msg}<br>`
              ),
              ""
            )
          }
        })
    },
  })

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs
            title={props.t("Setting")}
            breadcrumbItem={props.t("Edit") + " " + props.t("Page_Content")}
          />
          <Row>
            <Col className="col-12">
              <Button
                style={{ float: "right", marginBottom: "20px" }}
                color="primary"
                className="waves-effect waves-light  pull-right"
                onClick={() => history.goBack()}
              >
                {props.t("Back")}
              </Button>
            </Col>
            <Col>
              <Card>
                <CardBody>
                  <Form
                    method="post"
                    className="wysiwyg-custom"
                    onSubmit={e => {
                      e.preventDefault()
                      validationType.handleSubmit()
                      return false
                    }}
                  >
                    <Col sm={12}>
                      <div className="mb-3">
                        <Label className="form-label">
                          {props.t("Name_Eng")}
                        </Label>
                        <Input
                          name="name_en"
                          placeholder={props.t("Name_Eng")}
                          type="text"
                          onChange={validationType.handleChange}
                          onBlur={validationType.handleBlur}
                          value={validationType.values.name_en || ""}
                          invalid={
                            validationType.touched.name_en &&
                            validationType.errors.name_en
                              ? true
                              : false
                          }
                        />
                        {validationType.touched.name_en &&
                        validationType.errors.name_en ? (
                          <FormFeedback type="invalid">
                            {validationType.errors.name_en}
                          </FormFeedback>
                        ) : null}
                      </div>
                    </Col>
                    <Col sm={12}>
                      <div className="mb-3">
                        <Label className="form-label">
                          {props.t("Name_Thai")}
                        </Label>
                        <Input
                          name="name_th"
                          placeholder={props.t("Name_Thai")}
                          type="text"
                          onChange={validationType.handleChange}
                          onBlur={validationType.handleBlur}
                          value={validationType.values.name_th || ""}
                          invalid={
                            validationType.touched.name_th &&
                            validationType.errors.name_th
                              ? true
                              : false
                          }
                        />
                        {validationType.touched.name_th &&
                        validationType.errors.name_th ? (
                          <FormFeedback type="invalid">
                            {validationType.errors.name_th}
                          </FormFeedback>
                        ) : null}
                      </div>
                    </Col>
                    <Col sm={12}>
                      <div className="mb-3">
                        <Label className="form-label">
                          {props.t("Content_En")}
                        </Label>
                        {/* <Input
                          name="content_en"
                          placeholder={props.t("Name_Thai")}
                          type="text"
                          onChange={validationType.handleChange}
                          onBlur={validationType.handleBlur}
                          value={validationType.values.content_en || ""}
                          invalid={
                            validationType.touched.content_en &&
                            validationType.errors.content_en
                              ? true
                              : false
                          }
                        /> */}
                        <Editor
                          name="content_en"
                          editorState={contentEn}
                          onEditorStateChange={handleEditorContentEnChange}
                          toolbarClassName="toolbarClassName"
                          wrapperClassName="wrapperClassName"
                          editorClassName="editorClassName"
                          invalid={
                            validationType.touched.content_en &&
                            validationType.errors.content_en
                              ? true
                              : false
                          }
                        />
                        {validationType.touched.content_en &&
                        validationType.errors.content_en ? (
                          <FormFeedback type="invalid">
                            {validationType.errors.content_en}
                          </FormFeedback>
                        ) : null}
                      </div>
                    </Col>

                    <Col sm={12}>
                      <div className="mb-3">
                        <Label className="form-label">
                          {props.t("Content_Th")}
                        </Label>

                        <Editor
                          name="content_th"
                          editorState={contentTh}
                          onEditorStateChange={handleEditorContentThChange}
                          toolbarClassName="toolbarClassName"
                          wrapperClassName="wrapperClassName"
                          editorClassName="editorClassName"
                        />
                        {validationType.touched.content_th &&
                        validationType.errors.content_th ? (
                          <FormFeedback type="invalid">
                            {validationType.errors.content_th}
                          </FormFeedback>
                        ) : null}
                      </div>
                    </Col>

                    <Button color="primary" type="submit">
                      {props.t("Update")}
                    </Button>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withTranslation()(EditPageContent)
