// src/components/filter.
import React, { useMemo } from "react";
import PropTypes from 'prop-types';

//import components
import Breadcrumbs from '../../components/Common/Breadcrumb';
import TableContainer from '../../components/Common/TableContainer';
import { withTranslation } from "react-i18next";

import i18n from "../../i18n";

function Products(props) {
    const columns = useMemo(
        () => [
            {
                Header: 'First Name',
                accessor: 'firstName',
            },
        ],
        []
    );

    const data = [
        // {
        //     "firstName": "Test"
        // }
    ];

     //meta title
    document.title = props.t("Products")+' | '+props.t("MedX");
    
    return (
        <div className="page-content">
            <div className="container-fluid">
                <Breadcrumbs title={props.t("Marketing")} breadcrumbItem={props.t("Products")} />
                <center><h1>{props.t("Coming Soon")}</h1></center>
                {/* <Table columns={columns} data={data} /> */}
                {/* <TableContainer
                    columns={columns}
                    data={data}
                    isGlobalFilter={true}
                    isAddOptions={false}
                    customPageSize={10}
                    className="custom-header-css"
                /> */}
            </div>
        </div>
    );
}


Products.propTypes = {
    t: PropTypes.any,
    preGlobalFilteredRows: PropTypes.any,
  };
  
export default withTranslation()(Products);

