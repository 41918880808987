import config from "../config/config";
import axios from "axios"
import { getToken } from "../Token";
import * as url from "../helpers/url_helper";


const token = getToken();
export const RequestSubInstitutionApi = {
    getRequestSubInstitutionsByName: async (data) => {
        var axiosConfig = {
            method: 'post',
            url: `${config.API_URL}${url.POST_FETCH_REQUEST_SUB_INSTITUTION_BY_NAME}`,
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            data:data
        };

        return await axios(axiosConfig);
    },
    getAllRequestSubInstitutions: async (data) => {
        var axiosConfig = {
            method: 'post',
            url: `${config.API_URL}${url.POST_FETCH_REQUEST_SUB_INSTITUTIONS}`,
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            data:data
        };

        return await axios(axiosConfig);
    },
    getSubInstitutionDetails: async (data) => {
        var axiosConfig = {
            method: 'post',
            url: `${config.API_URL}${url.GET_FETCH_REQUEST_SUB_INSTITUTE_DETAILS}`,
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            data:data
        };

        return await axios(axiosConfig);
    }
}