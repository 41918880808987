// src/components/filter.
import React, { useMemo, useEffect, useState } from "react";
import PropTypes from 'prop-types';

//import components
import Breadcrumbs from '../../../components/Common/Breadcrumb';
import TableContainer from '../../../components/Common/TableContainer';
import DataTable from 'react-data-table-component';
import { RequestInstitutionApi } from "../../../apis/RequestInstitutionApi";
import { InstitutionApi } from "../../../apis/InstitutionApi";

import { useHistory } from "react-router-dom"
import * as Yup from "yup";
import { useFormik } from "formik";


import { Link } from "react-router-dom";
import {
  Card,
  Col,
  Container,
  Row,
  CardBody,
  CardTitle,
  Label,
  Button,
  Form,
  Input,
  InputGroup,
  TabContent,
  Table,
  TabPane,
  FormFeedback,

} from "reactstrap";
import Swal from "sweetalert2";
import toastr from "toastr";
import "toastr/build/toastr.min.css";
import { withTranslation } from "react-i18next";
import i18n from "../../../i18n";



function RequestInstitutions(props) {

  const history = useHistory();
  const request_institute_id = props.match.params.request_institute_id;
  const [institutionData, setInstitutionData] = useState({});
  const [status, setStatus] = useState("");
  const [comment, setComment] = useState("");
  const [documents, setDocuments] = useState([])
  const [errorMsg, setErrorMsg] = useState("");
  const [docs, setDocs] = useState({})


  useEffect(() => {
    getRequestInstitution();
  }, []);

  const getRequestInstitution = () => {
    let data = { request_institute_id: request_institute_id };
    RequestInstitutionApi.getRequestInstitution(data)
      .then((res) => {

        setStatus(res.data.requestinstitute.status)
        setInstitutionData(res.data.requestinstitute);
        const docs = res.data.requestinstitute.RequestInstituteDocument;
        const groupByCategory = docs.reduce((group, product) => {
          const { document_type_id } = product;
          group[document_type_id] = group[document_type_id] ?? [];
          group[document_type_id].push(product);
          return group;
        }, {});
        setDocs(groupByCategory)


      })
      .catch((err) => {
        console.log(err);
      });
  };

  const [documentValidation, setDocumentValidation] = useState({
    validations: {},
    dep: [],
  });



  const rejectDocument = item => {
    const docObj = {
      id: docs[item][0]['id'],
      document_type_id: docs[item][0]['document_type_id'],
      status: props.t("REJECTED")
    }
    setDocuments([
      ...documents,
      docObj
    ])

    const docData = [
      ...documents,
      docObj
    ];

    
    const docVal = {
      validations: {},
      dep: [],
    };


     for (let j = 0; j < docData.length; j++) {
      docVal["dep"].push(`doc+comment${docData[j].document_type_id}`);
     }

     for (let i = 0; i < docData.length; i++) {
        const restIds = docVal["dep"].filter(
          (e) => e != `doc+comment${docData[i].document_type_id}`
        );
        // const restIds =  docVal["dep"];
        // console.log('restIds',restIds);

       docVal["validations"][`doc+comment${docData[i].document_type_id}`] =  Yup.mixed().required(props.t("Required-Message"));
              // Yup.mixed().when(restIds, {
              //   is: (...restIds) => {
              //     let flag = true;
              //     restIds.forEach((e) => {
              //       if (e && e.length > 0) {
              //         flag = false;
              //       }
              //     });
              //     return flag;
              //   },
              //   then: Yup.mixed().required(
              //     props.t("Required-Message")
              //   ),
              //   otherwise: Yup.mixed(),
              // });

       }
        docVal["dep"] = docVal["dep"].reduce(
          (acc, v, i) =>
            acc.concat(docVal["dep"].slice(i + 1).map((w) => [v, w])),
          []
        );
     //console.log(docData);
     setDocumentValidation(docVal);
  }
  

  const formik = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: {
      comment: institutionData.comment || '',
      status: institutionData.status || '',
    }, 
    validationSchema: Yup.object({
      status: Yup.string().required(props.t("Select_Status")),
      comment: Yup.string().required(props.t("Required-Message")), 
      ...documentValidation.validations
    },[
      ...documentValidation["dep"],
    ]), 
    onSubmit: (values) => {
      // const data = new FormData()
      // data.append("status", status)
      // data.append("comment", values.comment)
      // data.append("request_institute_id", request_institute_id)
      // data.append("documents", documents)
      const data = {
        status:status,
        comment: values.comment,
        request_institute_id:request_institute_id, 
        documents:documents
      };

      //  = request_institute_id;
      InstitutionApi.createInstitute(data)
        .then(res => {

          if (res.data.success) {
            Swal.fire({
              text: res.data.message,
              icon: 'success',
              imageAlt: 'success image',
              // confirmButtonColor: '#00CA84'
            }).then((result) => {
              if (result.isConfirmed) {
                history.push('/request-institutions');
                //history.push('/view-request-institute/'+request_institute_id);
              }
            });
          } else {
            toastr.error(res.data.message, '');
          }

        }).catch(function (error) {
          toastr.error(error.response.data.message, '');
        });
    }
  });

  const onSubmitRequestInstituteHandler = async () => {
    if (status === "0") {
      setErrorMsg(props.t("Select_Status"));
      return true;
    }
    const payload = {
      request_institute_id: request_institute_id,
      status: status,
      comment: comment,
      documents
    }
    InstitutionApi.createInstitute(payload)
    // .then(res => {
    //   if (res.data.success) {
    //     Swal.fire({
    //       text: res.data.message, 
    //       icon: 'success',
    //       imageAlt: 'success image',
    //       // confirmButtonColor: '#00CA84'
    //     }).then((result) => {
    //       if (result.isConfirmed) {
    //         history.push('/request-institutions');
    //       }
    //     });
    //   }
    //    else {
    //     toastr.error(res.message, '');
    //   }
    // }).catch(function (error) {
    //   toastr.error(error.response.data.message, '');
    // });
  }

  //meta title
  document.title = props.t("Req_Institution_Details") + ' | ' + props.t("MedX")


  useEffect(() => {
    if (documents.length > 0)
      setStatus("REJECTED")
  }, [documents])


  return (
    <div className="page-content">
      <div className="container-fluid">
        <Breadcrumbs title={props.t("Institutions")} breadcrumbItem={props.t("Req_Institution_Details")} />
      </div>



      <div className="container-fluid">

        <Row>
          <Col lg={6}>
            <Card>
              <CardBody>
                <div className="table-responsive">
                  <Table className="table mb-0 table-bordered">
                    <tbody>

                      <tr>
                        <th scope="row" className={"text-capitalize"}>{props.t("Institution_Name")}</th>
                        <td>{institutionData.institution_name ? institutionData.institution_name : "-"}</td>
                      </tr>

                      <tr>
                        <th scope="row" className={"text-capitalize"}>{props.t("Affiliation")}</th>
                        <td>{institutionData.affiliation ? institutionData.affiliation : "-"}</td>
                      </tr>


                      <tr>
                        <th scope="row" className={"text-capitalize"}>{props.t("Institution_Address")}</th>
                        <td>{institutionData.institution_address ? institutionData.institution_address : "-"}</td>
                      </tr>

                      <tr>
                        <th scope="row" className={"text-capitalize"}>{props.t("Province")}</th>
                        <td>
                        {
                          i18n.language == 'en' ? (
                            institutionData.Province?.name_en
                          ) : ( institutionData.Province?.name_th)
                        }
                        </td>
                      </tr>

                      <tr>
                        <th scope="row" className={"text-capitalize"}>{props.t("District")}</th>
                        <td>
                        {
                          i18n.language == 'en' ? (
                            institutionData.District?.name_en
                          ) : ( institutionData.District?.name_th)
                        }
                        </td>
                      </tr>

                      <tr>
                        <th scope="row" className={"text-capitalize"}>{props.t("Sub_District")}</th>
                        <td>
                        {
                          i18n.language == 'en' ? (
                            institutionData.SubDistrict?.name_en
                          ) : ( institutionData.SubDistrict?.name_th)
                        }
                        </td>
                      </tr>

                      <tr>
                        <th scope="row" className={"text-capitalize"}>{props.t("Post_Code")}</th>
                        <td>{institutionData.post_code ? institutionData.post_code : "-"}</td>
                      </tr>

                      <tr>
                        <th scope="row" className={"text-capitalize"}>{props.t("Phone")}</th>
                        <td>{institutionData.phone ? institutionData.phone : "-"}</td>
                      </tr>

                      <tr>
                        <th scope="row" className={"text-capitalize"}>{props.t("Email")}</th>
                        <td>{institutionData.email ? institutionData.email : "-"}</td>
                      </tr>

                      <tr>
                        <th scope="row" className={"text-capitalize"}>{props.t("Website")}</th>
                        <td>{institutionData.website ? institutionData.website : "-"}</td>
                      </tr>

                      <tr>
                        <th scope="row" className={"text-capitalize"}>{props.t("Organization")}</th>
                        <td>
                        {
                          i18n.language == 'en' ? (
                            institutionData.OrganizationType?.name_en
                          ) : ( institutionData.OrganizationType?.name_th)
                        }
                        </td>
                      </tr>

                      <tr>
                        <th scope="row" className={"text-capitalize"}>{props.t("Main_Organization")}</th>
                        <td>
                        {
                          i18n.language == 'en' ? (
                            institutionData.MainOrganizationType?.name_en
                          ) : ( institutionData.MainOrganizationType?.name_th)
                        }
                        </td>
                      </tr>

                      <tr>
                        <th scope="row" className={"text-capitalize"}>{props.t("Old_Institution_Code")}</th>
                        <td>{institutionData.old_institute_code ? institutionData.old_institute_code : "-"}</td>
                      </tr>

                      <tr>
                        <th scope="row" className={"text-capitalize"}>{props.t("Established_Yr")}</th>
                        <td>{institutionData.established_year ? institutionData.established_year : "-"}</td>
                      </tr>

                      <tr>
                        <th scope="row" className={"text-capitalize"}>{props.t("No_of_Members")}</th>
                        <td>{institutionData.number_of_members ? institutionData.number_of_members : "-"}</td>
                      </tr>

                      <tr>
                        <th scope="row" className={"text-capitalize"}>{props.t("Education")}</th>
                        <td>{institutionData.EducationType ? institutionData.EducationType.name_en : "-"}</td>
                      </tr>
                    </tbody>
                  </Table>
                </div>
              </CardBody>
            </Card>
          </Col>

          <Col lg={6}>
            <Card>
              <CardBody>
                <div className="table-responsive">
                  <Table className="table mb-0 table-bordered">
                    <tbody>

                      <tr>
                        <th scope="row" className={"text-capitalize"}>{props.t("Education_No_of_Med")}</th>
                        <td>{institutionData.education_number_of_medical_with ? institutionData.education_number_of_medical_with : "-"}</td>
                      </tr>

                      <tr>
                        <th scope="row" className={"text-capitalize"}>{props.t("Education_No_of_Beds")}</th>
                        <td>{institutionData.education_number_of_beds ? institutionData.education_number_of_beds : "-"}</td>
                      </tr>

                      <tr>
                        <th scope="row" className={"text-capitalize"}>{props.t("Service")}</th>
                        <td>{institutionData.ServiceType ? institutionData.ServiceType.name_en : "-"}</td>
                      </tr>

                      <tr>
                        <th scope="row" className={"text-capitalize"}>{props.t("Service_No_of_Med")}</th>
                        <td>{institutionData.service_number_of_medical_with ? institutionData.service_number_of_medical_with : "-"}</td>
                      </tr>

                      <tr>
                        <th scope="row" className={"text-capitalize"}>{props.t("Service_No_of_beds")}</th>
                        <td>{institutionData.service_number_of_beds ? institutionData.service_number_of_beds : "-"}</td>
                      </tr>

                      {/* <tr>
                        <th scope="row" className={"text-capitalize"}>{props.t("Service")}</th>
                        <td>{institutionData.service_id ? institutionData.service_id : "-"}</td>
                      </tr> */}


                      <tr>
                        <th scope="row" className={"text-capitalize"}>{props.t("Name_Title")}</th>
                        <td>{institutionData.contact_name_title ? institutionData.contact_name_title : "-"}</td>
                      </tr>

                      <tr>
                        <th scope="row" className={"text-capitalize"}>{props.t("First_Name")}</th>
                        <td>{institutionData.contact_first_name ? institutionData.contact_first_name : "-"}</td>
                      </tr>

                      <tr>
                        <th scope="row" className={"text-capitalize"}>{props.t("Last_Name")}</th>
                        <td>{institutionData.contact_last_name ? institutionData.contact_last_name : "-"}</td>
                      </tr>

                      <tr>
                        <th scope="row" className={"text-capitalize"}>{props.t("Organization_Position")}</th>
                        <td>{institutionData.organization_position ? institutionData.organization_position : "-"}</td>
                      </tr>


                      <tr>
                        <th scope="row" className={"text-capitalize"}>{props.t("Contact_Ph")}</th>
                        <td>{institutionData.contact_phone ? institutionData.contact_phone : "-"}</td>
                      </tr>

                      <tr>
                        <th scope="row" className={"text-capitalize"}>{props.t("Contact_Email")}</th>
                        <td>{institutionData.contact_email ? institutionData.contact_email : "-"}</td>
                      </tr>

                      <tr>
                        <th scope="row" className={"text-capitalize"}>{props.t("Contact_Web")}</th>
                        <td>{institutionData.contact_website ? institutionData.contact_website : "-"}</td>
                      </tr>

                      <tr>
                        <th scope="row" className={"text-capitalize"}>{props.t("Contact_Net_Affil")}</th>
                        <td>{institutionData.network_with_affiliated_institutions ? institutionData.network_with_affiliated_institutions : "-"}</td>
                      </tr>

                      <tr>
                        <th scope="row" className={"text-capitalize"}>{props.t("Affiliated_Number")}</th>
                        <td>{institutionData.affiliated_number ? institutionData.affiliated_number : "-"}</td>
                      </tr>

                    </tbody>
                  </Table>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>


        <Row>
          <Col lg={12}>
            <Card>
              <CardBody>
                <CardTitle className="mb-4">{props.t("Documents")}</CardTitle>

                <div className="table-responsive">
                  <Table className="table mb-0 table-bordered">
                    <tbody>

                      {
                        Object.keys(docs)?.length > 0 ?
                          Object.keys(docs)?.map((item, index) => {
                            return (
                              <tr key={index}>
                                <th className={"text-capitalize"}>{docs[item]?.length > 0 ? docs[item][0]['DocumentType']['title_en'] : null}</th>
                                <td>
                                  <div className="row px-3">

                                    {docs[item]?.map((ditem, dindex) => {
                                      return (
                                        <a target={"_blank"} rel="noreferrer" className=" border rounded my-1 py-1" href={ditem['document_url']} key={dindex}>document {dindex + 1}</a>
                                      )
                                    })}

                                  </div>

                                </td>
                                <td>
                                  <div className="row">

                                    <div className="radio-toolbar">
                                      <span className="mb-2 d-block" id="radio-title">
                                        {props.t("Status")}{" "}
                                      </span>
                                      <input
                                        type="radio"

                                        name={"sidebarType" + (docs[item]?.length > 0 ? docs[item][0]['document_type_id'] : null)}
                                        id={"sidebarType" + (docs[item]?.length > 0 ? docs[item][0]['document_type_id'] : null)}
                                        onChange={e => {

                                          const docObjs = documents.filter(ev => ev.document_type_id !== docs[item][0]['document_type_id'])
                                          setDocuments([...docObjs])
                                        }}
                                      />
                                      <label className="me-1" htmlFor={"sidebarType" + (docs[item]?.length > 0 ? docs[item][0]['document_type_id'] : null)}>Approve</label>
                                      <input
                                        type="radio"
                                        name={"sidebarType" + (docs[item]?.length > 0 ? docs[item][0]['document_type_id'] : null)}
                                        id={"sidebarCompact" + (docs[item]?.length > 0 ? docs[item][0]['document_type_id'] : null)}
                                        onChange={e => {

                                          

                                          rejectDocument(item)
                                          
                                        }}

                                      />
                                      <label className="me-1" htmlFor={"sidebarCompact" + (docs[item]?.length > 0 ? docs[item][0]['document_type_id'] : null)}>Reject</label>
                                      {documents?.find(e => e.document_type_id == docs[item][0]['document_type_id']) ?
                                        <div className="mt-3">
                                          <p htmlFor="formrow-email-Input">{props.t("Remarks")}</p>
                                          <Input
                                            name={"doc+comment" + (docs[item]?.length > 0 ? docs[item][0]['document_type_id'] : null)}
                                            type="textarea"
                                            className="form-control"
                                            id="horizontal-firstname-Input"
                                            placeholder={props.t("Remarks")}
                                            maxLength="225"
                                            rows="2"
                                            onChange={(e) => {
                                              
                                              const filesArray2 = [];
                                              formik.setFieldValue(
                                                `doc+comment${docs[item][0]['document_type_id']}`,
                                                formik.values[
                                                  `doc+comment${docs[item][0]['document_type_id']}`
                                                ]
                                                  ? [
                                                      ...formik.values[
                                                        `doc+comment${docs[item][0]['document_type_id']}`
                                                      ],
                                                      ...filesArray2,
                                                    ]
                                                  : filesArray2
                                              );


                                              const docObjs = documents.map(ev => {
                                                if (ev.document_type_id == docs[item][0]['document_type_id']) {
                                                  return {
                                                    ...ev,
                                                    comment: e.target.value
                                                  }
                                                }
                                                return ev
                                              })
                                              setDocuments([...docObjs])
                                            }}

                                          />

                                          {formik.errors[
                                            `doc+comment${docs[item][0]['document_type_id']}`
                                          ] ? (
                                            <p className="text-danger text-small ">
                                              {
                                                formik.errors[
                                                  `doc+comment${docs[item][0]['document_type_id']}`
                                                ]
                                              }
                                            </p>
                                          ) : null}

                                        </div>
                                        : null}
                                    </div>

                                  </div>

                                </td>
                              </tr>
                            )
                          })
                          :
                          props.t("No_Record_Found")
                      }

                    </tbody>
                  </Table>
                </div>
              </CardBody>
            </Card>
          </Col>


        </Row>
        <Row>
          <Col lg={12}>
            <Card>
              <CardBody>
                <CardTitle className="mb-4">{props.t("Update_Req_Institution_Status")}</CardTitle>

                <form
                  className="form-horizontal"
                  onSubmit={e => {
                    e.preventDefault()
                    formik.handleSubmit()
                    return false
                  }}
                >

                  <Row>
                    <Col md={6}>
                      <div className="mb-3">
                        <Label htmlFor="formrow-email-Input">{props.t("Status")}</Label>
                        <select
                          onChange={(e) => { setStatus(e.target.value) }}
                          value={status || ""}
                          // onBlur={validation.handleBlur}
                          // defaultValue={institutionData.status}
                          className="form-select"
                        >
                          <option value="0">{props.t("Select_Status")}</option>
                          {documents?.length > 0 ? null : <option value="APPROVED">{props.t("APPROVED")}</option>}
                          <option value="PENDING">{props.t("PENDING")}</option>
                          <option value="REJECTED">{props.t("REJECTED")}</option>
                        </select>
                        {errorMsg !== "" &&
                          <div style={{ color: '#f46a6a', fontSize: '11px', marginTop: '3px' }}>
                            {errorMsg}
                          </div>

                        }
                      </div>
                    </Col>
                  </Row>

                  <Row>
                    <Col md={6}>
                      <div className="mb-3">
                        <Label htmlFor="formrow-email-Input">{props.t("Comment")}</Label>
                        <Input
                          name="comment"
                          type="textarea"
                          className="form-control"
                          id="horizontal-firstname-Input"
                          placeholder={props.t("Comment")}
                          maxLength="225"
                          rows="3"
                          // onChange={(e) => { setComment(e.target.value) }}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.comment}
                          // value={comment || ""}
                          invalid={
                            formik.touched.comment &&
                              formik.errors.comment
                              ? true
                              : false
                          }
                        />
                        {formik.touched.comment &&
                          formik.errors.comment ? (
                          <div
                            style={{
                              color: "#f46a6a",
                              fontSize: "11px",
                              marginTop: "3px",
                            }}
                          >
                            {formik.errors.comment}
                          </div>
                        ) : null}
                      </div>
                    </Col>
                  </Row>

                  <div>
                    <button type="submit" className="btn btn-primary w-md">
                      {/* onClick={onSubmitRequestInstituteHandler} */}
                      {props.t("Submit")}
                    </button>
                  </div>
                </form>
              </CardBody>
            </Card>
          </Col>


        </Row>



      </div>
    </div >
  );
}
RequestInstitutions.propTypes = {
  t: PropTypes.any,
  preGlobalFilteredRows: PropTypes.any,
};

export default withTranslation()(RequestInstitutions);