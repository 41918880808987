// src/components/filter.
import React, { useEffect, useState } from "react";
import PropTypes from 'prop-types';
import { Base64 } from "Token";

//import components
import Breadcrumbs from "components/Common/Breadcrumb";
import DataTable from 'react-data-table-component';
// import { BannerApi } from "../../apis/BannerApi";
import { useHistory, Link } from "react-router-dom"
// import TableContainer from '../../components/Common/TableContainer';
import { withTranslation } from "react-i18next";
// import * as Yup from "yup";
// import { useFormik } from "formik";
// import toastr from "toastr";
import config from "config/config";
// import constants from "../../config/constants";
// import i18n from "../../i18n";
import {
    Container,
    Row,
    Col,
    Card,
    CardBody,
    Form,
    Label,
    Input,
    FormFeedback,
    Button
} from "reactstrap";
import Swal from "sweetalert2";
import moment from "moment";
import { CompaniesApi } from "apis/CompaniesApi";
import { SpeakerApi } from "apis/SpeakerApi";

function Speaker(props) {
    const history = useHistory();
    const [files1, setFiles1] = useState([]);
    const [speakerList, setSpeakerList] = useState([]);
    const [fetchBanner, setFetchBanner] = useState([]);
    const [postBannerId, setBannerId] = useState('');
    const [loading, setLoading] = useState(false);
    const [page, setCurrentPage] = useState(1);
    const [limit, setCurrentLimit] = useState(config.LIMIT);

    const [activeData, setActiveData] = useState({
        page: 1,
        limit: config.LIMIT
    });



    useEffect(() => {
        getAllSpeaker(activeData);
    }, [page,limit]);

    const formClear = () => {
        validationType.resetForm();
        setFiles1([])
        setFetchBanner();
    };

    const columns = [
        {
            name: props.t("Sr_No"),
            selector: row => row.offer_id,
            sort: "asc",
            width: 150,
            sortable: true,
            defaultSortField: true,
            defaultSortAsc: false
        },
        {
            name: props.t("First Name"),
            selector: row => row.title_en,
            sort: "asc",
            width: 270,
            sortable: true,
            defaultSortField: true,
            defaultSortAsc: false
        },
        {
            name: props.t("Last Name"),
            selector: row => row.title_th,
            sort: "asc",
            width: 270,
            sortable: true,
            defaultSortField: true,
            defaultSortAsc: false
        },
        {
            name: props.t("Email"),
            selector: row => row.email,
            sort: "asc",
            width: 270,
            sortable: true,
            defaultSortField: true,
            defaultSortAsc: false
        },
        {
            name: props.t("Phone No"),
            selector: row => row.phone_no,
            sort: "asc",
            width: 270,
            sortable: true,
            defaultSortField: true,
            defaultSortAsc: false
        },
        {
            name: props.t("Action"),
            selector: row => row.action,
            sort: "asc",
            width: 200,
        }
    ];

    const getAllSpeaker = (data) => {
        setLoading(true);
        SpeakerApi.getAllSpeaker(data)
            .then((res) => {
                // console.log(res.data)
                setActiveData({
                    page: activeData.page,
                    limit: activeData.limit,
                });
                let data = [];
                var sr_no = 0;

                res?.data?.speakers?.forEach((v, i) => {
                    sr_no = sr_no + 1;
                    data[i] = {
                        offer_id: sr_no,
                        title_en: v.first_name,
                        title_th: v.last_name,
                        email: v.email,
                        phone_no: v.mobile,
         
                        action: (
                            <>

                                <Link
                                    style={{ marginRight: "15px" }}
                                    to={`/edit-speaker/${v.id}`}
                                >
                                    <i className=" fas fa-edit" id="edittooltip" />
                                </Link>
                                <i className="mdi mdi-delete font-size-18" style={{ color: "red" }} id="deletetooltip" onClick={() => onDelete(v.id)} />
                            </>
                        ),
                    };
                });
                setSpeakerList(data);
                setLoading(false);
            })
            .catch((err) => {
                console.log(err);
            });
    };


    const onDelete = (id) => {

        Swal.fire({
            title: props.t("Are_you_sure"),
            text: props.t("Able_to_revert_this"),
            icon: props.t("Warning"),
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#f46a6a",
            confirmButtonText: props.t("Yes_delete_it"),
            cancelButtonText: props.t("Cancel"),
        }).then((result) => {
            if (result.isConfirmed) {
                const newId = Base64.encode(id)
                // let SpeakerId = { "speaker_id": newId };
                // console.log(SpeakerId)
                SpeakerApi.deleteSpeaker(newId)
                    .then((res) => {
                        Swal.fire(props.t("Deleted"), res.data.message, props.t("Success"));
                        //getAllSpeaker(activeData)
                        handleChange(page);
                       
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            }
        });
    };

   
    const handleChange = (v) => {
        setCurrentPage(v);
        setActiveData({ page: v, totalPage: activeData.totalPage, search: activeData.search, limit: limit });
        const data = { page: v, totalPage: activeData.totalPage, search: activeData.search, limit: limit }
        getAllSpeaker(data)
    }


    const handleRowChange = (v) => {
        setCurrentLimit(v);
        setActiveData({ page: activeData.page, totalPage: activeData.totalPage, search: activeData.search, limit: v });
        const data = { page: activeData.page, totalPage: activeData.totalPage, search: activeData.search, limit: v }
        getAllSpeaker(data)
    }

    //meta title
    document.title = props.t("Speaker") + ' | ' + props.t("MedX");

    return (
        <div className="page-content">
            <div className="container-fluid">
                <Breadcrumbs title="Master" breadcrumbItem="Speaker" />
                <Container fluid={true}>
                    <Row>
                        <Col>
                            <Link to="/add-speaker" className="btn btn-primary w-xs btn-right">{props.t("Add")}</Link>
                        </Col>
                    </Row>
                </Container>
                <Container fluid={false}>
                    <Row>
                        <Col lg={12}>
                            <Card>
                                <CardBody >
                                    <Col sm={12}>
                                        <DataTable
                                            className="table-bordered"
                                            progressPending={loading}
                                            columns={columns}
                                            data={speakerList}
                                            pagination
                                            paginationServer
                                            paginationTotalRows={activeData.totalPage}
                                            paginationPerPage={activeData.limit}
                                            defaultSortFieldID={1}
                                            onChangeRowsPerPage={value => handleRowChange(value)}
                                            onChangePage={value => handleChange(value)}
                                            sortable
                                            noHeader
                                            defaultSortField="id"
                                            defaultSortAsc={false}
                                            highlightOnHover
                                        />
                                    </Col>

                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </div>
    );
}


Speaker.propTypes = {
    t: PropTypes.any,
    preGlobalFilteredRows: PropTypes.any,
};

export default withTranslation()(Speaker);

