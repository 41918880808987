import { ReportApi } from "apis/ReportApi"
import { useFormik } from "formik"
import { timePeriodArr } from "helpers/common_helper"
import moment from "moment"
import PropTypes from "prop-types"
import React, { useEffect } from "react"
import DataTable from "react-data-table-component"
import ReactExport from "react-export-excel-xlsx-fix"
import { withTranslation } from "react-i18next"
import { Button, Col, Form, FormGroup, Input, Label, Row } from "reactstrap"
import * as Yup from "yup"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import config from "../../config/config"

const ExcelFile = ReactExport.ExcelFile
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn

const RegistredInstitutes = props => {
  const [loading, setLoading] = React.useState(false)
  const [registeredInstitutes, setRagisteredInstitutes] = React.useState([])
  const [isDateFilterShow, setDateFilterShow] = React.useState(false)
  const [timePeriod, setTimePeriod] = React.useState("TODAY")
  const [activeData, setActiveData] = React.useState({
    page: 1,
    totalPage: 1,
    search: "",
    limit: config.LIMIT,
  })

  useEffect(() => {
    getAllRegisteredInstitutes(activeData)
  }, [])

  const getAllRegisteredInstitutes = data => {
    ReportApi.getRegisteredInstitutes(data)
      .then(res => {
        if (res.data.success) {
          setActiveData({
            ...activeData,
            totalPage: res.data.data.count,
          })
          let data = []
          let sr_no = res.data.sr_no_start
          res.data.data.rows.forEach((v, i) => {
            sr_no = sr_no + 1
            data[i] = {
              sr_no: sr_no,
              institutionName: v.institution_name,
              creatorName: v.creatorName,
              organizationType: v.organizationType,
              establishedYear: v.established_year,
              affiliation: v.affiliation,
              email: v.email,
              phone: v.phone,
              registeredDate: v.created_at,
              ...v,
            }
          })
          setRagisteredInstitutes(data)
          setLoading(false)
        }
      })
      .catch(err => {
        console.log(err)
      })
  }

  const exportRegistredInstitutesHandler = async () => {
    const data = {
      ...activeData,
      limit: "",
    }
    setActiveData(data)
    getAllRegisteredInstitutes(data)
  }

  const columns = [
    {
      name: props.t("Sr_No"),
      selector: row => row.sr_no,
      sort: "asc",
      width: 150,
      sortable: true,
      defaultSortField: true,
      defaultSortAsc: false,
    },
    {
      name: props.t("instituteName"),
      selector: row => row.institutionName,
      sort: "asc",
      width: 270,
      sortable: true,
      defaultSortField: true,
      defaultSortAsc: false,
    },
    {
      name: props.t("creator"),
      selector: row => row.creatorName,
      sort: "asc",
      width: 270,
      sortable: true,
      defaultSortField: true,
      defaultSortAsc: false,
    },
    {
      name: props.t("Organization_Type"),
      selector: row => row.organizationType,
      sort: "asc",
      width: 270,
      sortable: true,
      defaultSortField: true,
      defaultSortAsc: false,
    },
    {
      name: props.t("Established_Yr"),
      selector: row => row.establishedYear,
      sort: "asc",
      width: 270,
      sortable: true,
      defaultSortField: true,
      defaultSortAsc: false,
    },
    {
      name: props.t("email"),
      selector: row => row.email,
      sort: "asc",
      width: 270,
      sortable: true,
      defaultSortField: true,
      defaultSortAsc: false,
    },
    {
      name: props.t("phone"),
      selector: row => row.phone,
      sort: "asc",
      width: 270,
      sortable: true,
      defaultSortField: true,
      defaultSortAsc: false,
    },
    {
      name: props.t("registeredDate"),
      selector: row => row.registeredDate,
      sort: "asc",
      width: 270,
      sortable: true,
      defaultSortField: true,
      defaultSortAsc: false,
    },
  ]

  const handleChange = v => {
    setActiveData({
      page: v,
      limit: config.LIMIT,
      ...activeData,
    })
    const data = {
      page: v,
      limit: config.LIMIT,
      ...activeData,
    }
    getAllRegisteredInstitutes(data)
  }

  const handleRowChange = v => {
    setActiveData({
      ...activeData,
      limit: v,
    })
    const data = {
      ...activeData,
      limit: v,
    }
    getAllRegisteredInstitutes(data)
  }

  const handleChangeTimePeriod = v => {
    validation.setFieldValue("time_period", v)
    setTimePeriod(v)
    if (v == "CHOOSE_PERIOD") {
      setDateFilterShow(true)
    } else {
      validation.setFieldValue("start_date", "")
      validation.setFieldValue("end_date", "")
      setDateFilterShow(false)
    }
  }

  document.title = "Page Content | MedX"

  // Form validation
  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      time_period: timePeriod,
      isDateShow: isDateFilterShow ? "true" : "false",
      start_date: "",
      end_date: "",
    },
    validationSchema: Yup.object({
      time_period: Yup.string().required(props.t("Required-Message")),
      // start_date: Yup.string().when('isDateFilterShow', {
      //   is: "false",
      //   then: Yup.string(),
      //   otherwise: Yup.string().required(props.t("Required-Message")),
      // }),
      // end_date: Yup.string().when('isDateFilterShow', {
      //   is: "false",
      //   then: Yup.string(),
      //   otherwise: Yup.string().required(props.t("Required-Message")),
      // }),
    }),
    onSubmit: values => {
      if (isDateFilterShow) {
        if (values.start_date == "") {
          validation.setErrors({ start_date: `${props.t("Required-Message")}` })
          return false
        }
        if (values.end_date == "") {
          validation.setErrors({ end_date: `${props.t("Required-Message")}` })
          return false
        }
        let start = moment(values.start_date, "YYYY-MM-DD")
        let end = moment(values.end_date, "YYYY-MM-DD")
        let differenceDay = moment.duration(end.diff(start)).asDays() + 1
        if (differenceDay <= 0) {
          validation.setErrors({ end_date: `${props.t("Invalid-Date-Range")}` })
          return false
        }

        if (differenceDay > 30) {
          validation.setErrors({ end_date: `${props.t("Max-30-Day-Search")}` })
          return false
        }
      }
      activeData.page = 1
      activeData.totalPage = 1
      activeData.search = values
      activeData.limit = config.LIMIT
      setActiveData(activeData)
      getAllRegisteredInstitutes(activeData)
    },
  })

  return (
    <div className="page-content">
      <div className="container-fluid">
        <Breadcrumbs
          title={props.t("Report")}
          breadcrumbItem={props.t("Institute")}
        />
      </div>

      <Form
        className="needs-validation"
        onSubmit={e => {
          e.preventDefault()
          validation.handleSubmit()
          return false
        }}
      >
        <Row>
          <Col md="3">
            <FormGroup className="mb-3">
              <Label htmlFor="validationCustom01">
                {props.t("Time Period")}
              </Label>
              <Input
                name="time_period"
                type="select"
                className="form-control"
                onBlur={validation.handleBlur}
                defaultValue={validation.values.time_period}
                onChange={e => handleChangeTimePeriod(e.target.value)}
              >
                <option value="">{props.t("Select The Time Period")}</option>
                {timePeriodArr.map((item, i) => (
                  <option key={i} value={item.type}>
                    {item.name}
                  </option>
                ))}
              </Input>
              {validation.touched.time_period &&
              validation.errors.time_period ? (
                <div
                  style={{
                    color: "#f46a6a",
                    fontSize: "11px",
                    marginTop: "3px",
                  }}
                >
                  {validation.errors.time_period}
                </div>
              ) : null}
            </FormGroup>
          </Col>

          {isDateFilterShow ? (
            <>
              <Col md="3">
                <FormGroup className="mb-3">
                  <Label htmlFor="validationCustom01">
                    {props.t("Start Date")}
                  </Label>
                  <Input
                    name="start_date"
                    placeholder={props.t("Start Date")}
                    type="date"
                    className="form-control"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.start_date || ""}
                  />

                  {validation.touched.start_date &&
                  validation.errors.start_date ? (
                    <div
                      style={{
                        color: "#f46a6a",
                        fontSize: "11px",
                        marginTop: "3px",
                      }}
                    >
                      {validation.errors.start_date}
                    </div>
                  ) : null}
                </FormGroup>
              </Col>
              <Col md="3">
                <FormGroup className="mb-3">
                  <Label htmlFor="validationCustom01">
                    {props.t("End Date")}
                  </Label>
                  <Input
                    name="end_date"
                    placeholder={props.t("End Date")}
                    type="date"
                    className="form-control"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.end_date || ""}
                    invalid={
                      validation.touched.end_date && validation.errors.end_date
                        ? true
                        : false
                    }
                  />

                  {validation.touched.end_date && validation.errors.end_date ? (
                    <div
                      style={{
                        color: "#f46a6a",
                        fontSize: "11px",
                        marginTop: "3px",
                      }}
                    >
                      {validation.errors.end_date}
                    </div>
                  ) : null}
                </FormGroup>
              </Col>
            </>
          ) : (
            <></>
          )}

          <Col md="3" className="mt-4" style={{ paddingTop: 5 }}>
            <Button color="primary">{props.t("Search")}</Button>

            <ExcelFile
              filename="registered-institutes"
              element={
                <Button
                  style={{ float: "right" }}
                  onClick={exportRegistredInstitutesHandler}
                >
                  {" "}
                  {props.t("exportToExcel")}
                </Button>
              }
            >
              <ExcelSheet
                data={registeredInstitutes}
                name="registeredInstitutes"
              >
                <ExcelColumn label={props.t("Sr_No")} value="sr_no" />
                <ExcelColumn
                  label={props.t("instituteName")}
                  value="institutionName"
                />
                <ExcelColumn label={props.t("creator")} value="creatorName" />
                <ExcelColumn
                  label={props.t("Organization_Type")}
                  value="organizationType"
                />
                <ExcelColumn
                  label={props.t("Established_Yr")}
                  value="establishedYear"
                />
                <ExcelColumn
                  label={props.t("Affiliation")}
                  value="affiliation"
                />
                <ExcelColumn label={props.t("Email")} value="email" />
                <ExcelColumn label={props.t("Phone")} value="phone" />
                <ExcelColumn
                  label={props.t("registeredDate")}
                  value="registeredDate"
                />
                <ExcelColumn
                  label={props.t("Address")}
                  value="institution_address"
                />
                <ExcelColumn label={props.t("Province")} value="provinceName" />
                <ExcelColumn label={props.t("District")} value="districtName" />
                <ExcelColumn
                  label={props.t("Sub_District")}
                  value="subDistrictName"
                />
                <ExcelColumn label={props.t("Post_Code")} value="post_code" />
                <ExcelColumn label={props.t("Website")} value="website" />
              </ExcelSheet>
            </ExcelFile>
          </Col>
        </Row>
      </Form>

      <div className="container-fluid">
        <DataTable
          className="table-bordered"
          progressPending={loading}
          columns={columns}
          data={registeredInstitutes}
          pagination
          paginationServer
          paginationTotalRows={activeData.totalPage}
          paginationPerPage={activeData.limit}
          defaultSortFieldID={1}
          onChangeRowsPerPage={value => handleRowChange(value)}
          onChangePage={value => handleChange(value)}
          sortable
          noHeader
          defaultSortField="id"
          defaultSortAsc={false}
          highlightOnHover
        />
      </div>
    </div>
  )
}

RegistredInstitutes.propTypes = {
  preGlobalFilteredRows: PropTypes.any,
}

export default withTranslation()(RegistredInstitutes)
