import React, { useEffect, useState } from "react";
import { withTranslation } from "react-i18next";

import PropTypes from 'prop-types';
import DataTable from "react-data-table-component";
import Breadcrumbs from '../../../components/Common/Breadcrumb';
import { useHistory } from "react-router-dom";
import * as Yup from 'yup';
import { useFormik } from "formik";
import toastr from "toastr";
import config from "config/config";
import constants from "../../../config/constants";
import { QuizTypeApi } from "apis/QuizTypeApi";
import axios from "axios";
import { getToken } from "../../../Token";



import {
    Container,
    Row,
    Col,
    Card,
    CardBody,
    Form,
    Label,
    Input,
    FormFeedback,
    Button,

} from "reactstrap";

import Swal from "sweetalert2";


function QuizTypes(props) {

    const history = useHistory();
    const [QuizTypesList, setQuizTypesList] = useState([]);
    const [loading, setLoading] = useState(false);
    const [fetchQuiz, setFetchQuiz] = useState([]);
    const [PostQuizId, setQuizId] = useState("");
    const [limit, setCurrentLimit] = useState(config.LIMIT);
    const [activeData, setActiveData] = useState({
        page: 1,
        totalPage: 1,
        search: "",
        limit: config.LIMIT
    });
    const [page, setCurrentPage] = useState(1);

    useEffect(() => {
        getAllQuizType(activeData)
    }, [page,limit])

    const formClear = () => {
        validationType.resetForm();
    }




    const columns = [
        {
            name: props.t("Sr_No"),
            selector: row => row.quiz_id,
            sort: "asc",
            width: 50,
            sortable: true,
            defaultSortField: true,
            defaultSortAsc: false
        },
        {
            name: props.t("Title_Eng"),
            selector: row => row.title_en,
            sort: "asc",
            width: 200,
            sortable: true,
            defaultSortField: true,
            defaultSortAsc: false
        },
        {
            name: props.t("Title_Thai"),
            selector: row => row.title_th,
            sort: "asc",
            width: 200,
            sortable: true,
            defaultSortField: true,
            defaultSortAsc: false
        },
        {
            name: props.t("Quiz_Provider_Score"),
            selector: row => row.quiz_provider_score,
            sort: "asc",
            width: 100,
            sortable: true,
            defaultSortField: true,
            defaultSortAsc: false
        },
        {
            name: props.t("Quiz_Taker_Score"),
            selector: row => row.quiz_taker_score,
            sort: "asc",
            width: 150,
            sortable: true,
            defaultSortField: true,
            defaultSortAsc: false
        },
        {
            name: props.t("Max_Questions"),
            selector: row => row.max_questions,
            sort: "asc",
            width: 100,
            sortable: true,
            defaultSortField: true,
            defaultSortAsc: false
        },
        {
            name: props.t("Action"),
            selector: row => row.action,
            sort: "asc",
            width: 200,
        }
    ];


    const getAllQuizType = (data) => {
        setLoading(true);
        QuizTypeApi.getAllQuizTypes(data)
            .then((res) => {
                console.log(res)
                setActiveData({
                    page: activeData.page,
                    totalPage: res?.data?.data?.count,
                    search: activeData.search,
                    limit: activeData.limit,
                });
                let data = [];
                var sr_no = res.data.id;

                res?.data?.data?.rows?.forEach((v, i) => {

                    data[i] = {
                        quiz_id: v.id,
                        title_en: v.name_en,
                        title_th: v.name_th,
                        quiz_provider_score: v.quiz_provider_score,
                        quiz_taker_score: v.quiz_taker_score,
                        max_questions: v.max_questions,

                        action: (
                            <>

                                <i className="fas fa-edit" id="deletetooltip" style={{ color: "blue" }} onClick={() => onEdit(v.id)} />{" "}
                                <i className="mdi mdi-delete font-size-18" style={{ color: "red" }} id="deletetooltip" onClick={() => onDelete(v.id)} />
                            </>
                        ),
                    };
                });
                setQuizTypesList(data);
                setLoading(false);
            })
            .catch((err) => {
                console.log(err);
            });
    };





    const onDelete = (id) => {

        Swal.fire({
            title: props.t("Are_you_sure"),
            text: props.t("Able_to_revert_this"),
            icon: props.t("Warning"),
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#f46a6a",
            confirmButtonText: props.t("Yes_delete_it"),
            cancelButtonText: props.t("Cancel"),
        }).then((result) => {
            if (result.isConfirmed) {
                let quiz_id = { "quiz_id": id };
                QuizTypeApi.deleteQuizType(quiz_id)
                    .then((res) => {
                        Swal.fire(props.t("Deleted"), res.data.message, props.t("Success"));
                        formClear();
                        handleChange(page);
                        //getAllQuizType(activeData)
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            }
        });
    };

    const onEdit = (id) => {
        let QuizId = { "quiz_id": id };
        setQuizId(id);
        QuizTypeApi.getQuizTypeById(QuizId)
            .then((res) => {
                setFetchQuiz(res.data.data);
            })
            .catch((err) => {
                console.log(err);
            });
    };

    const validationType = useFormik({
        enableReinitialize: true,

        initialValues: {
            quiz_id: fetchQuiz ? fetchQuiz.id : "",
            title_en: fetchQuiz ? fetchQuiz.name_en : "",
            title_th: fetchQuiz ? fetchQuiz.name_th : "",
            quiz_provider_score: fetchQuiz ? fetchQuiz.quiz_provider_score : "",
            quiz_taker_score: fetchQuiz ? fetchQuiz.quiz_taker_score : "",
            max_questions: fetchQuiz ? fetchQuiz.max_questions : "",
        },

        validationSchema: Yup.object().shape({
            title_en: Yup.string().required(
                props.t("Required-Message")
            ),

            title_th: Yup.string().required(
                props.t("Required-Message")
            ),

            quiz_provider_score: Yup.string().required(
                props.t("Required-Message")
            ),

            quiz_taker_score: Yup.string().required(
                props.t("Required-Message")
            ),

            max_questions: Yup.number().required(
                props.t("Required-Message")
            ),
        }),

        onSubmit: (values) => {
            const data = new FormData();
            data.append('quiz_id', PostQuizId)
            data.append('name_en', values.title_en);
            data.append('name_th', values.title_th);
            data.append('quiz_provider_score', values.quiz_provider_score);
            data.append('quiz_taker_score', values.quiz_taker_score);
            data.append('max_questions', values.max_questions);

            if (PostQuizId) {
                values.quiz_id = PostQuizId;
                QuizTypeApi.updateQuizType(data)
                    .then(res => {
                        if (res.data.success) {
                            Swal.fire({
                                text: res.data.message,
                                icon: 'success',
                                imageAlt: 'success image',
                            }).then((result) => {
                                if (result.isConfirmed) {
                                    history.push('/quiz-types');
                                    setQuizId();
                                    formClear()
                                    getAllQuizType(activeData)
                                }
                            });
                        } else {
                            toastr.error(res.message, '');
                        }
                    }).catch(function (error) {
                        if (error?.response?.data?.message) {
                            toastr.error(error?.response?.data?.message, '');
                        }
                        else {
                            toastr.error(error?.response?.data?.error?.errors?.map((item, v) => `${item.msg}<br>`), '');
                        }
                    });
            }
            else {
                QuizTypeApi.createQuizTypes(data)
                    .then(res => {
                        if (res.data.success) {
                            Swal.fire({
                                text: res.data.message,
                                icon: 'success',
                                imageAlt: 'success image',
                            }).then((result) => {
                                if (result?.isConfirmed) {
                                    history.push('/quiz-types');
                                    formClear()
                                    getAllQuizType(activeData)
                                }
                            });
                        } else {
                            toastr.error(res?.data?.message, '');
                        }
                    }).catch(function (error) {
                        if (error?.response?.data?.message) {
                            toastr.error(error?.response?.data?.message, '');
                        }
                        else {
                            toastr.error(error?.response?.data?.error?.errors?.map((item, v) => `${item.msg}<br>`), '');
                        }
                    });
            }

        }
    })

    const handleChange = (v) => {
        setCurrentPage(v);
        setActiveData({ page: v, totalPage: activeData.totalPage, search: activeData.search, limit: limit });
        const data = { page: v, totalPage: activeData.totalPage, search: activeData.search, limit: limit }

    }

    const handleRowChange = (v) => {
        setCurrentLimit(v);
        setActiveData({ page: activeData.page, totalPage: activeData.totalPage, search: activeData.search, limit: v });
        const data = { page: activeData.page, totalPage: activeData.totalPage, search: activeData.search, limit: v }

    }

    document.title = props.t("Quiz_Types") + ' | ' + props.t("MedX");


    return (
        <div className="page-content">
            <div className="container-fluid">
                <Breadcrumbs title={props.t("Master")} breadcrumbItem={props.t("Quiz_Types")} />
            </div>
            <Container fluid={false}>
                <Row>
                    <Col lg={8}>
                        <Card>
                            <CardBody>
                                <Col sm={12}>
                                    <DataTable className="table-bordered"
                                        columns={columns}
                                        data={QuizTypesList}
                                    />
                                </Col>
                            </CardBody>
                        </Card>
                    </Col>
                    <Col lg={4}>
                        <Form
                            onSubmit={(e) => {
                                e.preventDefault();
                                validationType.handleSubmit();
                                return false;
                            }}
                        >
                            <Col sm={12}>
                                <div className="mb-3">
                                    <Label className="form-label">{props.t("Title_Eng")}<span className="text-danger">*</span></Label>
                                    <Input
                                        name="title_en"
                                        placeholder={props.t("Title_Eng")}
                                        type="text"
                                        onChange={validationType.handleChange}
                                        onBlur={validationType.handleBlur}
                                        value={validationType.values.title_en || ""}
                                        invalid={
                                            validationType.touched.title_en && validationType.errors.title_en ? true : false
                                        }
                                    />
                                    {validationType.touched.title_en && validationType.errors.title_en ? (
                                        <FormFeedback type="invalid">{validationType.errors.title_en}</FormFeedback>
                                    ) : null}
                                </div>
                            </Col>
                            <Col sm={12}>
                                <div className="mb-3">
                                    <Label className="form-label">{props.t("Title_Thai")}<span className="text-danger">*</span></Label>
                                    <Input
                                        name="title_th"
                                        placeholder={props.t("Title_Thai")}
                                        type="text"
                                        onChange={validationType.handleChange}
                                        onBlur={validationType.handleBlur}
                                        value={validationType.values.title_th || ""}
                                        invalid={
                                            validationType.touched.title_th && validationType.errors.title_th ? true : false
                                        }
                                    />
                                    {validationType.touched.title_th && validationType.errors.title_th ? (
                                        <FormFeedback type="invalid">{validationType.errors.title_th}</FormFeedback>
                                    ) : null}
                                </div>
                            </Col>
                            <Col sm={12}>
                                <div className="mb-3">
                                    <Label className="form-label">{props.t("Quiz_Provider_Score")}<span className="text-danger">*</span></Label>
                                    <Input
                                        name="quiz_provider_score"
                                        placeholder={props.t("Quiz_Provider_Score")}
                                        type="text"
                                        onChange={validationType.handleChange}
                                        onBlur={validationType.handleBlur}
                                        value={validationType.values.quiz_provider_score || ""}
                                        invalid={
                                            validationType.touched.quiz_provider_score && validationType.errors.quiz_provider_score ? true : false
                                        }
                                    />
                                    {validationType.touched.quiz_provider_score && validationType.errors.quiz_provider_score ? (
                                        <FormFeedback type="invalid">{validationType.errors.quiz_provider_score}</FormFeedback>
                                    ) : null}
                                </div>
                            </Col>
                            <Col sm={12}>
                                <div className="mb-3">
                                    <Label className="form-label">{props.t("Quiz_Taker_Score")}<span className="text-danger">*</span></Label>
                                    <Input
                                        name="quiz_taker_score"
                                        placeholder={props.t("Quiz_Taker_Score")}
                                        type="text"
                                        onChange={validationType.handleChange}
                                        onBlur={validationType.handleBlur}
                                        value={validationType.values.quiz_taker_score || ""}
                                        invalid={
                                            validationType.touched.quiz_taker_score && validationType.errors.quiz_taker_score ? true : false
                                        }
                                    />
                                    {validationType.touched.quiz_taker_score && validationType.errors.quiz_taker_score ? (
                                        <FormFeedback type="invalid">{validationType.errors.quiz_taker_score}</FormFeedback>
                                    ) : null}
                                </div>
                            </Col>
                            <Col sm={12}>
                                <div className="mb-3">
                                    <Label className="form-label">{props.t("Max_Questions")}<span className="text-danger">*</span></Label>
                                    <Input
                                        name="max_questions"
                                        placeholder={props.t("Max_Questions")}
                                        type="text"
                                        onChange={validationType.handleChange}
                                        onBlur={validationType.handleBlur}
                                        value={validationType.values.max_questions || ""}
                                        invalid={
                                            validationType.touched.max_questions && validationType.errors.max_questions ? true : false
                                        }
                                    />
                                    {validationType.touched.max_questions && validationType.errors.max_questions ? (
                                        <FormFeedback type="invalid">{validationType.errors.max_questions}</FormFeedback>
                                    ) : null}
                                </div>
                            </Col>
                            <div className="d-flex flex-wrap gap-2">
                                <Button type="submit" color="primary" >
                                    {props.t("Submit")}
                                </Button>{" "}
                                <Button color="secondary" onClick={e => formClear()}>
                                    {props.t("Clear")}
                                </Button>
                            </div>
                        </Form>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default withTranslation()(QuizTypes);