import React, { useEffect, useState } from "react"
import {
  Row,
  Col,
  Card,
  CardBody,
  FormGroup,
  Button,
  CardTitle,
  CardSubtitle,
  Label,
  Input,
  Container,
  FormFeedback,
  Form,
} from "reactstrap"
// Formik validation
import * as Yup from "yup"
import { useFormik } from "formik"

import toastr from "toastr"
import "toastr/build/toastr.min.css"
import PropTypes from "prop-types"
// import { RoleApi } from "../../apis/RoleApi";
// import { RoleModuleApi } from "../../apis/RoleModuleApi";

import { useHistory } from "react-router-dom"
//Import Breadcrumb
// import Breadcrumbs from "../../components/Common/Breadcrumb";
import Swal from "sweetalert2"
import { withTranslation } from "react-i18next"
// import i18n from "../../i18n";
// import constants from "../../config/constants";
import config from "config/config"

import moment from "moment"
import { CompaniesApi, CSpeakerApi } from "apis/CompaniesApi"
import Breadcrumb from "components/Common/Breadcrumb"
import { SpeakerApi } from "apis/SpeakerApi"
import { Base64, Token } from "Token"
import { InterestApi } from "apis/InterestApi"
import { CategoryApi } from "apis/CategoryApi"
import { values } from "lodash"
import Select from "react-select"

import { CommunityApi } from "apis/CommunityApi"

const Role = Token.getRole()
// console.log(x, "0")

const EditCommunity = props => {
  const community_id = props.match.params.community_id
  const [files1, setFiles1] = useState([])
  const [speaker, setSpeaker] = useState("")
  const [community, setcommunity] = useState("")
  const history = useHistory()
  const [interestList, setinterestList] = useState([])
  const [categoryList, setCategoryList] = useState([])
  const [companyList, setCompanyList] = useState([])
  const [loading, setLoading] = useState(false)
  const [selectedOption, setSelectedOption] = useState([])

  const [activeData, setActiveData] = useState({
    page: 1,
    limit: config.LIMIT
  })

  const getAllCommunityTypes = data => {
    setLoading(true)
    InterestApi.getAllInterests(data)
      .then(res => {
        setActiveData({
          page: activeData.page,
          totalPage: res?.data?.data?.count,
          search: activeData.search,
          limit: activeData.limit,
        })
        const x = res?.data?.data?.rows
        const y = x?.map((item, index) => {
          return { label: item.name_en, value: item.id }
        })
        setinterestList(y)
        setLoading(false)
      })
      .catch(err => {
        console.log(err)
      })
  }

  useEffect(() => {
    getAllCommunityTypes(activeData)
  }, [])

  const getAllCategories = data => {
    setLoading(true)
    CategoryApi.getAllCategories(data)
      .then(res => {
        setActiveData({
          page: activeData.page,
          totalPage: res?.data?.data?.count,
          search: activeData.search,
          limit: activeData.limit,
        })
        setCategoryList(res?.data?.data?.rows)
        setLoading(false)
      })
      .catch(err => {
        console.log(err)
      })
  }

 

  const getAllCompanies = data => {
    setLoading(true)
    CompaniesApi.getAllCompanies(data)
      .then(res => {
        setActiveData({
          page: activeData.page,
          totalPage: res?.data?.data?.count,
          search: activeData.search,
          limit: activeData.limit,
        })
        setCompanyList(res?.data?.data?.rows)
        setLoading(false)
      })
      .catch(err => {
        console.log(err)
      })
  }

  useEffect(() => {
    getAllCompanies(activeData)
  }, [])

  const getCommunityById = () => {
    // console.log(community_id)
    CommunityApi.getCommunityById({ community_id })
      .then(res => {

        const xy = res.data.data.CommunityPostInterest
        const yz = xy.map((item) => {
          return { value: item.Interest.id, label: item.Interest.name_en }
        })


        setSelectedOption(yz)

        setcommunity(res.data.data)
        getAllCategories({...activeData,
          type:res.data.data?.type})

      })
      .catch(err => {
        console.log(err)
      })
  }

  useEffect(() => {
    getCommunityById()
    getAllCommunityTypes(activeData)
    setSelectedOption([{ label: "Cardiology", value: 2 }]);
  }, [])

  // Form validation
  const validationType = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: {
      type: community.type ?? "",
      name_en: community.name_en ?? "",
      name_th: community.name_th ?? "",
      description_en: community.description_en ?? "",
      description_th: community.description_th ?? "",
      // image_url: community.image_url ??"",
      status:
        community?.status === 1
          ? "True"
          : community?.status === 0
            ? "False"
            : "True",
      interest_list: community?.CommunityPostInterest?.map((item, index) => {
        return item.Interest.id
      }),
      category_list: community.Category?.id ?? "",
      company_list: community.Company?.id ?? "",
      community_files: null
    },
    validationSchema: Yup.object().shape({
      // type: Yup.string().required(props.t("Required-Message")),
      name_en: Yup.string().when('name_th', {
        is: (name_th) => !name_th || name_th.length === 0,
        then: Yup.string().max(50, props.t("Field_Must_50_Characters")).required(props.t("Required-Message")),
        otherwise: Yup.string()
      }),
      name_th: Yup.string().when('name_en', {
        is: (name_en) => !name_en || name_en.length === 0,
        then: Yup.string().max(50, props.t("Field_Must_50_Characters")).required(props.t("Required-Message")),
        otherwise: Yup.string()
      }),

      description_en: Yup.string().when('description_th', {
        is: (description_th) => !description_th || description_th.length === 0,
        then: Yup.string().required(props.t("Required-Message")),
        otherwise: Yup.string()
      }),
      description_th: Yup.string().when('description_en', {
        is: (description_en) => !description_en || description_en.length === 0,
        then: Yup.string().required(props.t("Required-Message")),
        otherwise: Yup.string()
      }),
      community_files: community?.CommunityPostMedia?.length > 0 ? null : Yup.mixed().required(props.t("Required-Message")),
      interest_list: Yup.array().min(1, props.t("Select_Interest")).required(props.t("Required-Message")),
      category_list: Yup.string().required(props.t("Required-Message")),
      company_list: Role?.role == "Super Admin" ? Yup.string().required(props.t("Required-Message")) : null,

      // image_url: Yup.string().required(props.t("Required-Message")),
      // interest_list: Yup.string().min(2, "Please enter a name more than 2 character").required(props.t("minimum interest tow required ")),
      // category_list: Yup.string().required(props.t("Required-Message")),
      // company_list: Yup.string().required(props.t("Required-Message")),
    },[['name_th','name_en'],['description_th','description_en']]),
    onSubmit: values => {
      // alert(JSON.stringify(values))
      // console.log(files1)
      const data = new FormData()
      for (let i = 0; i < values.community_files?.length; i++) {
        data.append("community_files", values.community_files[i])
      }

      // console.log(values.community_files)
      // return

      if (Role?.role == "Super Admin") {
        data.append("type", "INFOCUS")
      } else {
        data.append("type", "NEWS")
      }
      data.append("post_date", new Date())
      data.append("name_en", values.name_en)
      data.append("name_th", values.name_th)
      data.append("description_en", values.description_en)
      data.append("description_th", values.description_th)
      data.append("status", true)
      // data.append("interestsArr", [values.interest_list])
      for (let i = 0; i < values.interest_list.length; i++) {
        data.append("interestsArr", values.interest_list[i])
      }

      data.append("category_id", values.category_list)
      if (Role?.role == "Super Admin") {
        data.append("company_id", values.company_list)
      } else {
        data.append("company_id", null)
      }
      data.append("community_post_id", community_id)

      console.log(data)
      CommunityApi.updateCommunity(data)
        .then(res => {
          console.log(res.data)
          if (res.data.success) {
            Swal.fire({
              text: res.data.message,
              icon: "success",
              imageAlt: "success image",
            }).then(result => {
              if (result?.isConfirmed) {
                history.push("/Posts")
                formClear()
                setFiles1()
              }
            })
          } else {
            toastr.error(res?.data?.message, "")
          }
        })
        .catch(function (error) {
          console.log(error)
          if (error?.response?.data?.message) {
            toastr.error(error?.response?.data?.message, "")
          } else {
            toastr.error(
              error?.response?.data?.error?.errors?.map(
                (item, v) => `${item.msg}<br>`
              ),
              ""
            )
          }
        })
    },
  })

  const removeImage = (id) => {
    const data = {
      id,
      community_post_id: community_id
    }
    CommunityApi.deleteImage(data)
      .then(res => {
        console.log(res.data)
        if (res.data.success) {
          getCommunityById()
        } else {
          toastr.error(res?.data?.message, "")
        }
      })
      .catch(function (error) {
        console.log(error)
        if (error?.response?.data?.message) {
          toastr.error(error?.response?.data?.message, "")
        } else {
          toastr.error(
            error?.response?.data?.error?.errors?.map(
              (item, v) => `${item.msg}<br>`
            ),
            ""
          )
        }
      })
  }

  const removeImageLocal = (lastModified) => {
    const updatedList = [...validationType.values.community_files.filter(e => e.lastModified !== lastModified)]
    validationType.setFieldValue(
      "community_files",
      updatedList.length > 0 ? updatedList : null
    )
  }

  const formClear = () => {
    validationType.resetForm()
  }

  document.title = props.t("Post") + " | " + props.t("MedX")



  return (
    <React.Fragment>
      <div className="page-content">
        
        <Container fluid={true}>
          <Row>
            <Col lg={12}>
              <Card>
                <CardBody>
                  <Col sm={12}>
                    <Form
                      onSubmit={e => {
                        e.preventDefault()
                        validationType.handleSubmit()
                        return false
                      }}
                    >
                       <Label className="form-label h3 text-primary">
                        {props.t("NEWS")}
                      </Label>
                      <hr />

                      <Col sm={12}>
                        <div className="mb-3">
                          <Label className="form-label">
                            {props.t("Name_Thai")}  <span className="text-danger">*</span>
                          </Label>
                          <Input
                            name="name_th"
                            placeholder={props.t("Name_Thai")}
                            type="text"
                            autocomplete="off"
                            onChange={validationType.handleChange}
                            onBlur={validationType.handleBlur}
                            value={validationType.values.name_th || ""}
                            invalid={
                              validationType.touched.name_th &&
                                validationType.errors.name_th
                                ? true
                                : false
                            }
                          />
                          {validationType.touched.name_th &&
                            validationType.errors.name_th ? (
                            <FormFeedback type="invalid">
                              {validationType.errors.name_th}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </Col>
                      <Col sm={12}>
                        <div className="mb-3">
                          <Label className="form-label">
                            {props.t("Name_Eng")} <span className="text-danger">*</span>
                          </Label>
                          <Input
                            name="name_en"
                            placeholder={props.t("Name_Eng")}
                            type="text"
                            autocomplete="off"
                            onChange={validationType.handleChange}
                            onBlur={validationType.handleBlur}
                            value={validationType.values.name_en || ""}
                            invalid={
                              validationType.touched.name_en &&
                                validationType.errors.name_en
                                ? true
                                : false
                            }
                          />
                          {validationType.touched.name_en &&
                            validationType.errors.name_en ? (
                            <FormFeedback type="invalid">
                              {validationType.errors.name_en}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </Col>
                       
                      <Col sm={12}>
                        <div className="mb-3">
                          <Label className="form-label">
                            {props.t("Description_Th")}  <span className="text-danger">*</span>
                          </Label>
                          <Input
                            name="description_th"
                            placeholder={props.t("Description_Th")}
                            type="text"
                            autocomplete="off"
                            onChange={validationType.handleChange}
                            onBlur={validationType.handleBlur}
                            value={validationType.values.description_th || ""}
                            invalid={
                              validationType.touched.description_th &&
                                validationType.errors.description_th
                                ? true
                                : false
                            }
                          />
                          {validationType.touched.description_th &&
                            validationType.errors.description_th ? (
                            <FormFeedback type="invalid">
                              {validationType.errors.description_th}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </Col>
                      <Col sm={12}>
                        <div className="mb-3">
                          <Label className="form-label">
                            {props.t("Description_En")}  <span className="text-danger">*</span>
                          </Label>
                          <Input
                            name="description_en"
                            placeholder={props.t("Description_En")}
                            type="text"
                            autocomplete="off"
                            onChange={validationType.handleChange}
                            onBlur={validationType.handleBlur}
                            value={validationType.values.description_en || ""}
                            invalid={
                              validationType.touched.description_en &&
                                validationType.errors.description_en
                                ? true
                                : false
                            }
                          />
                          {validationType.touched.description_en &&
                            validationType.errors.description_en ? (
                            <FormFeedback type="invalid">
                              {validationType.errors.description_en}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </Col>

                      

                      <Col sm={12}>
                        <div className="mb-3">
                          <Label htmlFor="validationCustom01">
                            {props.t("Images")}  <span className="text-danger">*</span>
                          </Label>
                          <Input
                            name="community_files"
                            placeholder={props.t("Select_Community_Post_Image")}
                            type="file"
                            multiple
                            className="form-control"
                            onBlur={e => {
                              if(e.target.files.length == 0 && validationType.values.community_files && validationType.values.community_files.length == 0){
                               validationType.setFieldValue( "community_files",null)
                              }
                             }}
                             onChange={e => {
                               console.log(e.target.files)
                               const filesArray2 = [];
                               for (let i = 0; i < e.target.files.length; i++) {
                                 // console.log()
                                 filesArray2.push(e.target.files[i])
                               }
                               validationType.setFieldValue(
                                 "community_files",
                                 validationType.values.community_files ? [...validationType.values.community_files, ...filesArray2] : filesArray2
                               )
                             }}
                            invalid={
                              validationType.touched.community_files &&
                                validationType.errors.community_files
                                ? true
                                : false
                            }
                          />

                          {validationType.touched.community_files &&
                            validationType.errors.community_files ? (
                            <div
                              style={{
                                color: "#f46a6a",
                                fontSize: "11px",
                                marginTop: "3px",
                              }}
                            >
                              {validationType.errors.community_files}
                            </div>
                          ) : null}
                        </div>
                        <div className="d-flex  px-3 py-2 pb-4">
                          {community?.CommunityPostMedia?.length > 0 ?
                            community?.CommunityPostMedia?.map((item, index) => {
                              return (
                                <div key={index} className=" row">
                                  <img className="col-md-12 border img-fluid" style={{ width: 70, height: 60, marginRight:"40px" }} src={item['image_url']} />
                                </div>

                              )
                            })
                            : null}
                             {validationType.values.community_files?.length > 0 ?
                            validationType.values.community_files?.map((item, index) => {
                              return (
                                <div key={index} className=" row">
                                  <img className="col-md-12 border img-fluid" style={{ width: 70, height: 60 }} src={URL.createObjectURL(item)} />
                                  <a className="text-danger" type="button" style={{ fontSize: 12 }} onClick={() => removeImageLocal(item.lastModified)}>{props.t("Remove")}</a>
                                </div>

                              )
                            })
                            : null}
                        </div>
                      </Col>

                      <Col sm={12}>
                        <div className="mb-3">

                          <Label htmlFor="validationCustom01">
                            {props.t("Interests")}  <span className="text-danger">*</span>
                          </Label>
                          {community && interestList.length > 0 ?
                            <Select
                              placeholder={props.t("Select_Interest")}
                              defaultValue={selectedOption}
                              onChange={e => {
                                console.log(e)
                                const f = e.map((item, index) => {
                                  return item.value
                                })
                                validationType.setFieldValue("interest_list", f)
                                setSelectedOption(e)
                              }}
                              options={interestList}
                              isMulti={true}
                              className={
                                validationType.touched.interest_list &&
                                  validationType.errors.interest_list
                                  ? " border border-danger"
                                  : ""
                              }
                            /> : null}
                          {validationType.touched.interest_list &&
                            validationType.errors.interest_list ? (
                            <div
                              style={{
                                color: "#f46a6a",
                                fontSize: "11px",
                                marginTop: "3px",
                              }}
                            >
                              {validationType.errors.interest_list}
                            </div>
                          ) : null}
                        </div>
                      </Col>
                      <Col sm={12}>
                        <div className="mb-3">
                          <Label htmlFor="" className="form-label">
                            {props.t("Topics")}  <span className="text-danger">*</span>
                          </Label>
                          <select
                            onChange={validationType.handleChange}
                            value={validationType.values.category_list}
                            className={
                              validationType.touched.category_list &&
                                validationType.errors.category_list
                                ? "form-select border border-danger"
                                : "form-select"
                            }
                            name="category_list"
                          >
                            <option value="">
                              {props.t("Please_Select_Topic")}
                            </option>
                            {categoryList
                              ? categoryList?.map((item, index) => {
                                return (
                                  <option value={item.id} key={index}>
                                    {item?.name_en}
                                  </option>
                                )
                              })
                              : ""}
                          </select>
                          {validationType.touched.category_list &&
                            validationType.errors.category_list ? (
                            <p className="text-danger">
                              {validationType.errors.category_list}
                            </p>
                          ) : null}
                        </div>
                      </Col>
                   


                    
                    </Form>
                  </Col>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

EditCommunity.propTypes = {
  t: PropTypes.any,
}

export default withTranslation()(EditCommunity)