import React, { useEffect, useState, useRef } from "react"
import {
  Row,
  Col,
  Card,
  CardBody,
  Button,
  Label,
  Input,
  Container,
  FormFeedback,
  Form,
  CardImg,
  CardText,
  Modal,
  ModalBody,
} from "reactstrap"
import * as Yup from "yup"
import { useFormik } from "formik"
import toastr from "toastr"
import "toastr/build/toastr.min.css"
import PropTypes from "prop-types"
import { useHistory } from "react-router-dom"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import Swal from "sweetalert2"
import { withTranslation } from "react-i18next"
import constants from "../../../config/constants"
import config from "config/config"
import { ActivityApi } from "apis/ActivityApi"
import { InstitutionApi } from "apis/InstitutionApi"
import { UserApi } from "apis/UserApi"
import { ProvinceApi } from "apis/ProvinceApi"
import { ActivityTypeApi } from "apis/ActivityTypeApi"
import { InterestApi } from "apis/InterestApi"
import { Editor } from "react-draft-wysiwyg"
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css"
import { convertToHTML } from "draft-convert"
import { EditorState } from "draft-js"
import "flatpickr/dist/themes/material_blue.css"
import Select from "react-select"
import moment from "moment"
import _, { debounce } from "lodash"
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import * as url from "../../../helpers/common_helper"
import { registerLocale, setDefaultLocale } from "react-datepicker"
import th from "date-fns/locale/th"
registerLocale("th", th)
import { Base64, getRoleID } from "Token"

import {
  LoadScript,
  GoogleMap,
  DrawingManager,
  Polygon,
  Marker,
  MarkerF,
  OverlayView,
  StandaloneSearchBox,
} from "@react-google-maps/api"

const AddOffer = props => {
  const [startDate, setStartDate] = useState(
    new Date(new Date().setFullYear(new Date().getFullYear() + 543))
  )
  const [files1, setFiles1] = useState([])
  const [files2, setFiles2] = useState([])
  const [videoFile, setVideoFile] = useState([])
  const [videoThumbnailFile, setVideoThumbnailFile] = useState()

  const history = useHistory()
  const [institutionList, setInstitutionList] = useState([])
  const [activitySubTypeList, setActivitySubTypeList] = useState([])
  const [proviceList, setProviceList] = useState([])
  const [interestList, setInterestList] = useState([])
  const [userList, setUserList] = useState([])
  const [loading, setLoading] = useState(false)
  const [contentEn, setContentEn] = useState(() => EditorState.createEmpty())
  const [contentTh, setContentTh] = useState(() => EditorState.createEmpty())
  const [isSubmit, setIsSubmit] = useState(false)
  const [selectedOption, setSelectedOption] = useState(null)

  const [selectedOptionSpeaker, setSelectedOptionSpeaker] = useState(null)
  const [showModal, setShowModal] = useState(false)

  const [bannerData, setBannerData] = useState([])
  const [activeData, setActiveData] = useState({
    page: 1,
    totalPage: 1,
    search: "",
    limit: config.LIMIT,
  })
  const [formValues, setFormValues] = useState([
    { banner_images: "", banner_links: "", banner_rank: "" },
  ])
  const [institutesData, setinstitutesData] = useState([])
  const [institutesPaginateData, setinstitutesPaginateData] = React.useState({
    page: 1,
    limit: 20,
    totalRecords: 1000,
    search: "",
  })
  const [isMoreInstitutes, setIsMoreInstitutes] = useState(true)
  const [isInstituteLoading, setIsInstituteLoading] = useState(true)
  const [doctorsData, setDoctorsData] = useState([])
  const [doctorPaginateData, setDoctorPaginateData] = useState({
    page: 1,
    limit: 20,
    totalRecords: 1000,
    search: "",
  })
  const [isMoreDoctors, setIsMoreDoctors] = useState(true)
  const [isDoctorLoading, setIsDoctorLoading] = useState(true)
  const [speakerType, setSpeakerType] = useState("")
  const [customValidation, setCustomValidation] = useState()
  const [isFormSubmited, setIsFormSubmited] = useState(false)
  const [isSchedulePublish, setIsSchedulePublish] = useState(false);

  const getAllActivity = data => {
    setLoading(true)
    // InstitutionApi.getAllInstituteWithoutPaginate()
    //   .then(res => {
    //     setInstitutionList(res?.data?.data)
    //     setLoading(false)
    //   })
    //   .catch(err => {
    //     console.log(err)
    //   })
    ProvinceApi.getAllProvince()
      .then(res => {
        setProviceList(res?.data?.data)
        setLoading(false)
      })
      .catch(err => {
        console.log(err)
      })
    InterestApi.getAllInterest()
      .then(res => {
        const y = res?.data?.data?.map((item, index) => {
          return {
            label: item.name_en,
            value: item.id,
          }
        })
        setInterestList(y)
        setLoading(false)
      })
      .catch(err => {
        console.log(err)
      })
  }

  const onUploadBannerHandleChange = (value, type, index) => {
    const bannerDataCopy = _.cloneDeep(bannerData)
    if (type === "File") {
      const editBannerData = bannerDataCopy[index]
      if (!editBannerData) {
        const data = {
          banner_image: value,
          banner_link: "",
          banner_rank: "",
        }
        bannerDataCopy.push(data)
      } else {
        editBannerData.banner_image = value
        bannerDataCopy.splice(index, 1, editBannerData)
      }
    } else if (type === "bannerLink") {
      const editBannerData = bannerDataCopy[index]
      if (!editBannerData) {
        const data = {
          banner_image: "",
          banner_link: value,
          banner_rank: "",
        }
        bannerDataCopy.push(data)
      } else {
        editBannerData.banner_link = value
        bannerDataCopy.splice(index, 1, editBannerData)
      }
    } else if (type === "bannerRank") {
      const editBannerData = bannerDataCopy[index]
      if (!editBannerData) {
        const data = {
          banner_image: "",
          banner_link: "",
          banner_rank: value,
        }
        bannerDataCopy.push(data)
      } else {
        editBannerData.banner_rank = value
        bannerDataCopy.splice(index, 1, editBannerData)
      }
    }
    setBannerData(bannerDataCopy)
  }

  const onRemoveBannerHandleChange = index => {
    const bannerDataCopy = _.cloneDeep(bannerData)
    bannerDataCopy.splice(index, 1)
    setBannerData(bannerDataCopy)
  }

  useEffect(() => {
    getAllActivity(activeData)
  }, [bannerData])

  //   useEffect(() => {
  //   }, [institutesPaginateData])

  const onScrollInstituesDataHandler = debounce(async function () {
    if (isMoreInstitutes) {
      setIsInstituteLoading(true)
      let paginateData = {
        ...institutesPaginateData,
        page: institutesPaginateData.page + 1,
      }
      setinstitutesPaginateData(paginateData)
      getAllInstitutesPaginate(paginateData)
    }
  }, 100)

  const onSearchInstitutesHandler = debounce(function (searchInput) {
    // setinstitutesPaginateData({
    //   ...institutesPaginateData,
    //   page: 1,
    //   search: searchInput,
    // })
    if (searchInput.length >= 2) getAllInstitutesPaginate(searchInput)
  }, 500)

  const onSearchDoctorHandler = debounce(function (searchInput) {
    // setinstitutesPaginateData({
    //   ...institutesPaginateData,
    //   page: 1,
    //   search: searchInput,
    // })
    if (searchInput.length >= 2)
      getAllSpeakers(speakerType, searchInput.toString().trim())
  }, 500)

  const onOpenInstituteMenuHandler = () => {
    // const paginateData = {
    //   ...institutesPaginateData,
    //   page: 1,
    // }
    // setinstitutesPaginateData(paginateData)
    getAllInstitutesPaginate()
  }

  // useEffect(() => {
  //     console.log(bannerData);
  // }, [bannerData]);

  const getAllInstitutesPaginate = (searchKeyword = "") => {
    if (searchKeyword !== "") {
      institutesPaginateData.search = searchKeyword
    } else {
      institutesPaginateData.search = ""
    }
    InstitutionApi.getAllInstitutesApi(institutesPaginateData)
      .then(res => {
        if (res.data.success) {
          //   const pageNo = paginationData.page
          //   setinstitutesPaginateData({
          //     ...paginationData,
          //     totalRecords: res.data.data.count,
          //     page: res.data.data.metadata.page,
          //     limit: res.data.data.metadata.limit,
          //   })
          setinstitutesData([...res.data.data.rows])
          //   if (pageNo === 1) {
          //     setinstitutesData([...res.data.data.rows])
          //   } else {
          //     if (res.data.data.rows.length === 0) {
          //       setIsMoreInstitutes(false)
          //       setIsInstituteLoading(false)
          //     }
          //     setinstitutesData([...institutesData, ...res.data.data.rows])
          //   }
        } else {
          setinstitutesData([])
        }
      })
      .catch(err => {
        setinstitutesData([])
      })
  }

  const getAllSpeakers = (type, searchKeyword = "") => {
    const payload = {
      speaker_type: type,
      search: searchKeyword,
      page: 1,
      limit: 10,
    }
    UserApi.getSpeakerUser(payload)
      .then(res => {
        if (res.data.success) {
          //   const pageNo = doctorPaginateData.page
          //   setDoctorPaginateData({
          //     ...doctorPaginateData,
          //     totalRecords: res.data.data.count,
          //     page: res.data.data.metadata.page + 1,
          //     limit: res.data.data.metadata.limit,
          //   })
          let doctorArr = []
          res.data.data.rows.length > 0 &&
            res.data.data.rows.map((doctor, i) => {
              doctorArr[i] = {
                value: doctor.id,
                label:
                  doctor.first_name +
                  " " +
                  (doctor.last_name ? doctor.last_name : ""),
              }
            })
          setDoctorsData([...doctorArr])

          //   if (pageNo === 1) {
          //     setDoctorsData([...doctorArr])
          //   } else {
          //     if (doctorArr.length === 0) {
          //       setIsMoreDoctors(false)
          //       setIsDoctorLoading(false)
          //     }
          //   }
        } else {
          setDoctorsData([])
        }
      })
      .catch(err => {
        setDoctorsData([])
      })
  }

  const handleChangeSpeakerType = speaker_type => {
    validationType.setFieldValue("speaker_type", speaker_type)
    setSpeakerType(speaker_type)
    getAllSpeakers(speaker_type)
  }

  const handleChangeActivityType = activity_group => {
    validationType.setFieldValue("activity_group", activity_group)
    let activityTypeId = { activity_group: activity_group }
    if (activity_group) {
      ActivityTypeApi.getAllActivityType(activityTypeId)
        .then(res => {
          setActivitySubTypeList(res?.data?.data)
        })
        .catch(err => {
          console.log(err)
        })
    }
  }

  const handleActivityDate = activity_date => {
    validationType.setFieldValue("activity_date", activity_date)
  }

  let handleChange = (i, e) => {
    let newFormValues = [...formValues]
    newFormValues[i][e.target.name] = e.target.value
    setFormValues(newFormValues)
  }

  let addFormFields = () => {
    setFormValues([
      ...formValues,
      { banner_images: "", banner_links: "", banner_rank: "" },
    ])
  }

  let removeFormFields = i => {
    let newFormValues = [...formValues]
    newFormValues.splice(i, 1)
    setFormValues(newFormValues)
  }

  // Form validation
  const validationType = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: {
      institute_id: "",
      speaker_user_id: "",
      isInstitute: constants.ROLE.INSTITUTE == getRoleID() ? "false" : "",
      speaker_type: "",
      activity_group: "",
      activity_type_id: "",
      title_en: "",
      title_th: "",
      description_en: "",
      description_th: "",
      activity_date: "",
      address: "",
      latitude: "",
      longitude: "",
      online_meeting_url: "",
      start_time: "",
      end_time: "",
      province_id: "",
      education_type: "",
      attendee_limit: "",
      type: "",
      fee_type: "",
      interest_id: "",
      status: 1,
      publish_date: "",
      publish_time: "",
      activity_images: null,
      file_url: null,
      videoFile: null,
      website: "",
      email: "",
      contact_number: "",
      facebook_link: "",
      bannerData: null,
      is_credit: false,
    },
    validationSchema: Yup.object().shape(
      {
        ...customValidation,
      },
      [
        ["title_en", "title_th"],
        ["description_en", "description_th"],
      ]
    ),
    onSubmit: values => {
      setIsSubmit(true)
      const data = new FormData()
      if (values.activity_images != null) {
        for (let i = 0; i < values.activity_images.length; i++) {
          data.append("activity_images", values.activity_images[i])
        }
      }
      //data.append('image_url', files1);
      data.append("file_url", files2)
      data.append("institute_id", values.institute_id)
      data.append("speaker_user_id", values.speaker_user_id)
      data.append("speaker_type", values.speaker_type)
      data.append("activity_group", values.activity_group)
      data.append("activity_type_id", values.activity_type_id)
      data.append("activity_date", values.activity_date)
      data.append("title_en", values.title_en)
      data.append("title_th", values.title_th)
      data.append("description_en", values.description_en)
      data.append("description_th", values.description_th)
      data.append("fees", values.fees)
      data.append("fee_type", values.fee_type)
      data.append("start_time", values.start_time)
      data.append("end_time", values.end_time)
      data.append("address", values.address)
      data.append("latitude", values.latitude)
      data.append("longitude", values.longitude)
      data.append("province_id", values.province_id)
      data.append("type", values.type)
      data.append("attendee_limit", values.attendee_limit)
      data.append("education_type", values.education_type)
      data.append("online_meeting_url", values.online_meeting_url)
      data.append("join_number_of_users", values.join_number_of_users)
      data.append("interest_id", values.interest_id)
      data.append("status", values.status)
      data.append("publish_date", values.publish_date)
      data.append("publish_time", values.publish_time)
      data.append("website", values.website)
      data.append("email", values.email)
      data.append("contact_number", values.contact_number)
      data.append("facebook_link", values.facebook_link)
      data.append("banner_data", bannerData)
      data.append("videoFile", videoFile)
      data.append("videoThumbnailFile", videoThumbnailFile)
      data.append("is_credit", values.is_credit)

      ActivityApi.createActivity(data)
        .then(res => {
          setIsSubmit(false)
          if (res.data.success) {
            Swal.fire({
              text: res.data.message,
              icon: "success",
              imageAlt: "success image",
            }).then(result => {
              if (result?.isConfirmed) {
                history.push("/activities")
                formClear()
                setFiles1()
              }
            })
          } else {
            toastr.error(res?.data?.message, "")
          }
        })
        .catch(function (error) {
          setIsSubmit(false)
          if (error?.response?.data?.message) {
            toastr.error(error?.response?.data?.message, "")
          } else {
            toastr.error(
              error?.response?.data?.error?.errors?.map(
                (item, v) => `${item.msg}<br>`
              ),
              ""
            )
          }
        })
    },
  })

  useEffect(() => {
    if (isFormSubmited) {
      const { errors } = validationType
      const keys = Object.keys(errors)
      if (keys.length > 0) {
        const errorElement = document.getElementById(keys[0])
        errorElement.focus()
        setIsFormSubmited(false)
      }
    }
  }, [isFormSubmited, validationType])

  useEffect(() => {
    if (
      validationType.values.education_type ===
      constants.EDUCATION_TYPE.Multimedia
    ) {
      setCustomValidation({
        institute_id: Yup.string().when("isInstitute", {
          is: "false",
          then: Yup.string(),
          otherwise: Yup.string().required(props.t("Required-Message")),
        }),
        title_th: Yup.string().when("title_en", {
          is: title_en => !title_en || title_en.length === 0,
          then: Yup.string().required(props.t("Required-Message")),
          otherwise: Yup.string(),
        }),
        title_en: Yup.string().when("title_th", {
          is: title_th => !title_th || title_th.length === 0,
          then: Yup.string().required(props.t("Required-Message")),
          otherwise: Yup.string(),
        }),
        videoFile: Yup.mixed().required(props.t("Required-Message")),
        videoThumbnailImage: Yup.mixed().required(props.t("Required-Message")),
        status: Yup.string().required(props.t("Required-Message")),
        description_th: Yup.string().when("description_en", (val, schema) => {
          if (val?.length == undefined) {
            //if address exist then apply min max else not
            return Yup.string().required(props.t("Required-Message"))
          } else {
            return Yup.string().notRequired()
          }
        }),
      })
    } else {
      setCustomValidation({
        institute_id: Yup.string().when("isInstitute", {
          is: "false",
          then: Yup.string(),
          otherwise: Yup.string().required(props.t("Required-Message")),
        }),
        education_type: Yup.string().required(props.t("Required-Message")),
        activity_date: Yup.date().required(props.t("Required-Message")),
        activity_group: Yup.string().required(props.t("Required-Message")),
        activity_type_id: Yup.string().required(props.t("Required-Message")),
        title_th: Yup.string().when("title_en", {
          is: title_en => !title_en || title_en.length === 0,
          then: Yup.string().required(props.t("Required-Message")),
          otherwise: Yup.string(),
        }),
        title_en: Yup.string().when("title_th", {
          is: title_th => !title_th || title_th.length === 0,
          then: Yup.string().required(props.t("Required-Message")),
          otherwise: Yup.string(),
        }),
        start_time: Yup.string().required(props.t("Required-Message")),
        end_time: Yup.string().required(props.t("Required-Message")),
        province_id: Yup.string().required(props.t("Required-Message")),
        attendee_limit: Yup.string().required(props.t("Required-Message")),
        join_number_of_users: Yup.string().when("attendee_limit", {
          is: "LIMITED",
          then: Yup.string()
            .required(props.t("Required-Message"))
            .matches(/^[0-9\b]+$/, props.t("Only-Number-Message")),
        }),
        type: Yup.string().required(props.t("Required-Message")),
        address: Yup.string().when("type", {
          is: type =>
            type === constants.TYPE.Onsite || type === constants.TYPE.Hybrid,
          then: Yup.string().required(props.t("Required-Message")),
          otherwise: Yup.string(),
        }),
        latitude: Yup.string().when("type", {
          is: type =>
            type === constants.TYPE.Onsite || type === constants.TYPE.Hybrid,
          then: Yup.string().required(props.t("Required-Message")),
          otherwise: Yup.string(),
        }),
        longitude: Yup.string().when("type", {
          is: type =>
            type === constants.TYPE.Onsite || type === constants.TYPE.Hybrid,
          then: Yup.string().required(props.t("Required-Message")),
          otherwise: Yup.string(),
        }),
        online_meeting_url: Yup.string().when("type", {
          is: type =>
            type === constants.TYPE.Online || type === constants.TYPE.Hybrid,
          then: Yup.string().required(props.t("Required-Message")),
          otherwise: Yup.string(),
        }),
        fee_type: Yup.string().required(props.t("Required-Message")),
        fees: Yup.number().when("fee_type", {
          is: "PAID",
          then: Yup.number().required(props.t("Required-Message")),
        }),
        speaker_type: Yup.string().required(props.t("Required-Message")),
        speaker_user_id: Yup.array()
          .min(1, props.t("Select_Interest"))
          .required(props.t("Required-Message")),
        website: Yup.string().required(props.t("Required-Message")),
        email: Yup.string().email().required(props.t("Required-Message")),
        // description_en: Yup.string().when("description_th", (val, schema) => {
        //     if(val?.length == undefined) {  //if address exist then apply min max else not
        //        return Yup.string().required(props.t("Required-Message"));
        //     } else {
        //        return Yup.string().notRequired();
        //     }
        // }),
        contact_number: Yup.string()
          .min(8, props.t("Contact-Number-Message"))
          .max(10, props.t("Contact-Number-Message"))
          .required(props.t("Required-Message")),
        facebook_link: Yup.string().required(props.t("Required-Message")),
        interest_id: Yup.array()
          .min(1, props.t("Select_Interest"))
          .required(props.t("Required-Message")),
        status: Yup.string().required(props.t("Required-Message")),
        publish_date: Yup.string().when("status", {
          is: "2",
          then:  Yup.string().required(props.t("Required-Message")),
          otherwise: Yup.string(),
        }),
        publish_time: Yup.string().when("status", {
          is: "2",
          then: Yup.string().required(props.t("Required-Message")),
          otherwise:  Yup.string(),
        }),
        description_th: Yup.string().when("description_en", (val, schema) => {
          if (val?.length == undefined) {
            //if address exist then apply min max else not
            return Yup.string().required(props.t("Required-Message"))
          } else {
            return Yup.string().notRequired()
          }
        }),
        // activity_images: Yup.mixed().required(
        //     props.t("Image is required")
        // ),

        // contact_number:  Yup.number()
        //     .typeError(props.t("you must specify a number") )
        //     .required(props.t("Required-Message")
        // ),
        activity_images: Yup.mixed()
          .test(
            "fileSize",
            props.t("File_Size_is_too_large") + " Max Size is 2 MB",
            value => {
              if (value && value?.length > 0) {
                for (let i = 0; i < value.length; i++) {
                  if (
                    value[i].size > constants.IMAGE_VALIDATION.ACTIVITY_IMAGE
                  ) {
                    return false
                  }
                }
              }
              return true
            }
          )
          .test("fileType", props.t("Unsupported_File_Format"), value => {
            if (value && value.length > 0) {
              for (let i = 0; i < value.length; i++) {
                if (
                  value[i].type != "image/png" &&
                  value[i].type != "image/jpg" &&
                  value[i].type != "image/jpeg"
                ) {
                  return false
                }
              }
            }
            return true
          }),
        file_url: Yup.mixed()
          .test(
            "fileSize",
            props.t("File_Size_is_too_large") + " Max Size is 10 MB",
            value => {
              if (value && value?.length > 0) {
                for (let i = 0; i < value.length; i++) {
                  if (value[i].size > constants.IMAGE_VALIDATION.ACTIVITY_PDF) {
                    return false
                  }
                }
              }
              return true
            }
          )
          .test("fileType", props.t("Unsupported_File_Format"), value => {
            if (value && value.length > 0) {
              for (let i = 0; i < value.length; i++) {
                if (value[i].type != "application/pdf") {
                  return false
                }
              }
            }
            return true
          }),
        videoFile: undefined,
        videoThumbnailImage: undefined,
      })
    }
  }, [validationType.values.education_type])

  useEffect(() => {
    if (contentTh) {
      convertContentThTextToHTML()
    } 
  }, [contentTh])

  useEffect(() => {
    if (contentEn) {
      convertContentEnTextToHTML()
    }
  }, [contentEn])

  const convertContentThTextToHTML = async () => {
    const contentHtml = await url.convertEditorContentTextToHtml(contentTh)
    validationType.setFieldValue("description_th", contentHtml)
  }

  const convertContentEnTextToHTML = async () => {
    const contentHtml = await url.convertEditorContentTextToHtml(contentEn)
    validationType.setFieldValue("description_en", contentHtml)
  }

  const formClear = () => {
    validationType.resetForm()
    setFiles1([])
  }
  
  const removeImage = lastModified => {
    const updatedList = [
      ...validationType.values.activity_images.filter(
        e => e.lastModified !== lastModified
      ),
    ]
    validationType.setFieldValue(
      "activity_images",
      updatedList.length > 0 ? updatedList : null
    )
  }

  document.title = props.t("Activity") + " | " + props.t("MedX")

  const [latitude, setLatitude] = useState(15.87)
  const [longitude, setLongitude] = useState(100.5086199930891)
  const [address, setAddress] = useState(
    "Ministry of Public Health The Secretariat of the Medical Council"
  )
  const libraries = ["places", "geometry"]

  const google_key = config.google.API_KEY

  const inputRef = useRef()
  const inputRef1 = useRef()

  const handlePlaceChanged = () => {
    const [place] = inputRef.current.getPlaces()
    if (place) {
      //console.log(place);
      if (place.geometry.location.lat() && place.geometry.location.lng()) {
        setLatitude(place.geometry.location.lat())
        setLongitude(place.geometry.location.lng())
        setAddress(place.formatted_address)
      }
      validationType.setFieldValue("address", place.formatted_address)
      validationType.setFieldValue("latitude", place.geometry.location.lat())
      validationType.setFieldValue("longitude", place.geometry.location.lng())
    }
  }

  const handlePlaceChangedOne = () => {
    const [place] = inputRef1.current.getPlaces()
    if (place) {
      //console.log(place);
      if (place.geometry.location.lat() && place.geometry.location.lng()) {
        setLatitude(place.geometry.location.lat())
        setLongitude(place.geometry.location.lng())
        setAddress(place.formatted_address)
      }
      validationType.setFieldValue("address", place.formatted_address)
      validationType.setFieldValue("latitude", place.geometry.location.lat())
      validationType.setFieldValue("longitude", place.geometry.location.lng())
    }
  };
  
  const changeActivityStatus = (value) => {
    validationType.setFieldValue("status",value);
    if(value == 2) {
      setIsSchedulePublish(true);
    } else {
      setIsSchedulePublish(false);
    }
  };
  
  const onLocationSearch = () => {
    setShowModal(true)
  }

  function handleLoad() {}

  function hanldePlacesChanged() {}

  const onMarkerDragEnd = coord => {
    const { latLng } = coord
    setLatitude(latLng.lat())
    setLongitude(latLng.lng())
    validationType.setFieldValue("latitude", latLng.lat())
    validationType.setFieldValue("longitude", latLng.lng())
    validationType.setFieldValue("addrsss", address)
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Breadcrumbs
          title={props.t("Activity")}
          breadcrumbItem={props.t("Add") + " " + props.t("Activity")}
        />
        <Container fluid={true}>
          <Row>
            <Col lg={12}>
              <Card>
                <CardBody>
                  <Col sm={12}>
                    <Form
                      onSubmit={e => {
                        e.preventDefault()
                        validationType.handleSubmit()
                        setIsFormSubmited(true)
                        return false
                      }}
                    >
                      <Row>
                        {constants.ROLE.INSTITUTE != getRoleID() ? (
                          <Col sm={6}>
                            <div className="mb-3">
                              <Label className="form-label">
                                {props.t("Institute")}{" "}
                                <span className="text-danger">*</span>
                              </Label>
                              <input
                                type="text"
                                className="hide-focus"
                                id="institute_id"
                              />
                              <Select
                                className={
                                  validationType.touched.institute_id &&
                                  validationType.errors.institute_id
                                    ? "bor "
                                    : "text-dark"
                                }
                                styles={{
                                  control: styles => ({
                                    ...styles,
                                  }),
                                }}
                                onChange={e => {
                                  validationType.setFieldValue(
                                    "institute_id",
                                    e.value
                                  )
                                }}
                                onInputChange={onSearchInstitutesHandler}
                                onMenuOpen={onOpenInstituteMenuHandler}
                                defaultValue={
                                  validationType.values.institute_id
                                }
                                onBlur={validationType.handleBlur}
                                name="institute_id"
                                options={[...institutesData]}
                                placeholder={
                                  <div>{props.t("typeToSearch")}</div>
                                }
                              />
                              {validationType.touched.institute_id &&
                              validationType.errors.institute_id ? (
                                <div
                                  style={{
                                    color: "#f46a6a",
                                    fontSize: "11px",
                                    marginTop: "3px",
                                  }}
                                >
                                  {validationType.errors.institute_id}
                                </div>
                              ) : null}
                            </div>
                          </Col>
                        ) : (
                          <></>
                        )}

                        <Col sm={6}>
                          <div className="mb-3">
                            <Label className="form-label">
                              {props.t("Education_Type")}{" "}
                              <span className="text-danger">*</span>
                            </Label>
                            <select
                              name="education_type"
                              id="education_type"
                              type="select"
                              className="form-control"
                              onChange={validationType.handleChange}
                              onBlur={validationType.handleBlur}
                              value={validationType.values.education_type || ""}
                            >
                              <option value="">
                                {props.t("Select_Education_Type")}
                              </option>
                              {Object.keys(constants.EDUCATION_TYPE).map(
                                key => {
                                  return (
                                    <option value={key} key={key}>
                                      {constants.EDUCATION_TYPE[key]}
                                    </option>
                                  )
                                }
                              )}
                            </select>
                            {validationType.touched.education_type &&
                            validationType.errors.education_type ? (
                              <div
                                style={{
                                  color: "#f46a6a",
                                  fontSize: "11px",
                                  marginTop: "3px",
                                }}
                              >
                                {validationType.errors.education_type}
                              </div>
                            ) : null}
                          </div>
                        </Col>

                        {validationType.values.education_type !==
                          constants.EDUCATION_TYPE.Multimedia && (
                          <Col sm={6}>
                            <div className="mb-3">
                              <Label className="form-label">
                                {props.t("Activity_Date")}{" "}
                                <span className="text-danger">*</span>
                              </Label>
                              <Input
                                name="activity_date"
                                id="activity_date"
                                type="date"
                                locale="th"
                                onChange={validationType.handleChange}
                                onBlur={validationType.handleBlur}
                                min={moment().format("Y-M-D")}
                                value={
                                  validationType.values.activity_date || ""
                                }
                                invalid={
                                  validationType.touched.activity_date &&
                                  validationType.errors.activity_date
                                    ? true
                                    : false
                                }
                              />
                              {validationType.touched.activity_date &&
                              validationType.errors.activity_date ? (
                                <FormFeedback type="invalid">
                                  {validationType.errors.activity_date}
                                </FormFeedback>
                              ) : null}
                            </div>
                          </Col>
                        )}
                      </Row>

                      {validationType.values.education_type !==
                        constants.EDUCATION_TYPE.Multimedia && (
                        <Row>
                          <Col sm={6}>
                            <div className="mb-3">
                              <Label className="form-label">
                                {props.t("Activity_Group")}{" "}
                                <span className="text-danger">*</span>
                              </Label>
                              <select
                                name="activity_group"
                                id="activity_group"
                                type="select"
                                className="form-control"
                                onChange={e =>
                                  handleChangeActivityType(e.target.value)
                                }
                                onBlur={validationType.handleBlur}
                                value={
                                  validationType.values.activity_group || ""
                                }
                              >
                                <option value="">
                                  {props.t("Select_Activity_Group")}
                                </option>
                                {Object.keys(constants.ACTIVITY_GROUP).map(
                                  key => {
                                    return (
                                      <option value={key} key={key}>
                                        {constants.ACTIVITY_GROUP[key]}
                                      </option>
                                    )
                                  }
                                )}
                              </select>
                              {validationType.touched.activity_group &&
                              validationType.errors.activity_group ? (
                                <div
                                  style={{
                                    color: "#f46a6a",
                                    fontSize: "11px",
                                    marginTop: "3px",
                                  }}
                                >
                                  {validationType.errors.activity_group}
                                </div>
                              ) : null}
                            </div>
                          </Col>
                          <Col sm={6}>
                            <div className="mb-3">
                              <Label className="form-label">
                                {props.t("Activity_Type")}{" "}
                                <span className="text-danger">*</span>
                              </Label>
                              <select
                                name="activity_type_id"
                                id="activity_type_id"
                                type="select"
                                className="form-control"
                                onChange={validationType.handleChange}
                                onBlur={validationType.handleBlur}
                                value={
                                  validationType.values.activity_type_id || ""
                                }
                              >
                                <option value="">
                                  {props.t("Select_Activity_Type")}
                                </option>
                                {activitySubTypeList?.map((item, i) => {
                                  return (
                                    <option value={item.id} key={i}>
                                      {item.code} - {item.title_en}
                                    </option>
                                  )
                                })}
                              </select>
                              {validationType.touched.activity_type_id &&
                              validationType.errors.activity_type_id ? (
                                <div
                                  style={{
                                    color: "#f46a6a",
                                    fontSize: "11px",
                                    marginTop: "3px",
                                  }}
                                >
                                  {validationType.errors.activity_type_id}
                                </div>
                              ) : null}
                            </div>
                          </Col>
                        </Row>
                      )}

                      <Row>
                        <Col sm={6}>
                          <div className="mb-3">
                            <Label className="form-label">
                              {props.t("Title_Thai")}{" "}
                              <span className="text-danger">*</span>
                            </Label>
                            <Input
                              name="title_th"
                              id="title_th"
                              placeholder={props.t("Title_Thai")}
                              type="text"
                              onChange={validationType.handleChange}
                              onBlur={validationType.handleBlur}
                              value={validationType.values.title_th || ""}
                              invalid={
                                validationType.touched.title_th &&
                                validationType.errors.title_th
                                  ? true
                                  : false
                              }
                            />
                            {validationType.touched.title_th &&
                            validationType.errors.title_th ? (
                              <FormFeedback type="invalid">
                                {validationType.errors.title_th}
                              </FormFeedback>
                            ) : null}
                          </div>
                        </Col>

                        <Col sm={6}>
                          <div className="mb-3">
                            <Label className="form-label">
                              {props.t("Title_Eng")}{" "}
                              <span className="text-danger">*</span>
                            </Label>
                            <Input
                              name="title_en"
                              id="title_en"
                              placeholder={props.t("Title_Eng")}
                              type="text"
                              onChange={validationType.handleChange}
                              onBlur={validationType.handleBlur}
                              value={validationType.values.title_en || ""}
                              invalid={
                                validationType.touched.title_en &&
                                validationType.errors.title_en
                                  ? true
                                  : false
                              }
                            />
                            {validationType.touched.title_en &&
                            validationType.errors.title_en ? (
                              <FormFeedback type="invalid">
                                {validationType.errors.title_en}
                              </FormFeedback>
                            ) : null}
                          </div>
                        </Col>
                      </Row>

                      {/* start cond */}

                      {validationType.values.education_type !==
                        constants.EDUCATION_TYPE.Multimedia && (
                        <>
                          <Row>
                            <Col sm={6}>
                              <div className="form-check mb-3">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  name="is_credit"
                                  id="is_credit"
                                  onClick={e => {
                                    validationType.setFieldValue(
                                      "is_credit",
                                      e.target.checked
                                    )
                                  }}
                                />
                                <Label className="form-label">
                                  {props.t("isHavingCredit")}
                                </Label>
                              </div>
                              {/* </span> */}
                            </Col>
                          </Row>
                          <Row>
                            <Col sm={6}>
                              <div className="mb-3">
                                <Label className="form-label">
                                  {props.t("Start_Time")}{" "}
                                  <span className="text-danger">*</span>
                                </Label>
                               
                                <Input
                                  name="start_time"
                                  id="start_time"
                                  type="time"
                                  onChange={validationType.handleChange}
                                  onBlur={validationType.handleBlur}
                                  value={validationType.values.start_time || ""}
                                  invalid={
                                    validationType.touched.start_time &&
                                    validationType.errors.start_time
                                      ? true
                                      : false
                                  }
                                />
                                {validationType.touched.start_time &&
                                validationType.errors.start_time ? (
                                  <FormFeedback type="invalid">
                                    {validationType.errors.start_time}
                                  </FormFeedback>
                                ) : null}
                              </div>
                            </Col>
                            <Col sm={6}>
                              <div className="mb-3">
                                <Label className="form-label">
                                  {props.t("End_Time")}{" "}
                                  <span className="text-danger">*</span>
                                </Label>
                                {/* <Flatpickr
                                                        name="end_time"
                                                        className="form-control d-block"
                                                        placeholder="Select time"
                                                        options={{
                                                            enableTime: true,
                                                            noCalendar: true,
                                                            dateFormat: "H:i"
                                                          }}
                                                        onChange={validationType.handleChange}
                                                        onBlur={validationType.handleBlur}
                                                        value={validationType.values.end_time || ""}
                                                        invalid={
                                                            validationType.touched.end_time && validationType.errors.end_time ? true : false
                                                        }
                                                        /> */}
                                <Input
                                  name="end_time"
                                  id="end_time"
                                  type="time"
                                  onChange={validationType.handleChange}
                                  onBlur={validationType.handleBlur}
                                  value={validationType.values.end_time || ""}
                                  invalid={
                                    validationType.touched.end_time &&
                                    validationType.errors.end_time
                                      ? true
                                      : false
                                  }
                                />
                                {validationType.touched.end_time &&
                                validationType.errors.end_time ? (
                                  <FormFeedback type="invalid">
                                    {validationType.errors.end_time}
                                  </FormFeedback>
                                ) : null}
                              </div>
                            </Col>
                          </Row>

                          <Row>
                            <Col sm={6}>
                              <div className="mb-3">
                                <Label className="form-label">
                                  {props.t("Province")}{" "}
                                  <span className="text-danger">*</span>
                                </Label>
                                <select
                                  name="province_id"
                                  id="province_id"
                                  type="select"
                                  className="form-control"
                                  onChange={validationType.handleChange}
                                  onBlur={validationType.handleBlur}
                                  value={
                                    validationType.values.province_id || ""
                                  }
                                >
                                  <option value="">
                                    {props.t("Select-Province-Message")}
                                  </option>
                                  {proviceList?.map((item, i) => {
                                    return (
                                      <option value={item.id} key={i}>
                                        {item.name_en}
                                      </option>
                                    )
                                  })}
                                </select>
                                {validationType.touched.province_id &&
                                validationType.errors.province_id ? (
                                  <div
                                    style={{
                                      color: "#f46a6a",
                                      fontSize: "11px",
                                      marginTop: "3px",
                                    }}
                                  >
                                    {validationType.errors.province_id}
                                  </div>
                                ) : null}
                              </div>
                            </Col>
                          </Row>

                          <Row>
                            <Col sm={6}>
                              <div className="mb-3">
                                <Label className="form-label">
                                  {props.t("Attendee_Limit")}{" "}
                                  <span className="text-danger">*</span>
                                </Label>
                                <select
                                  name="attendee_limit"
                                  id="attendee_limit"
                                  type="select"
                                  className="form-control"
                                  onChange={validationType.handleChange}
                                  onBlur={validationType.handleBlur}
                                  value={
                                    validationType.values.attendee_limit || ""
                                  }
                                >
                                  <option value="">
                                    {props.t("Select_Attendee_Limit")}
                                  </option>
                                  {Object.keys(constants.ATTENDEE_LIMIT).map(
                                    key => {
                                      return (
                                        <option value={key} key={key}>
                                          {constants.ATTENDEE_LIMIT[key]}
                                        </option>
                                      )
                                    }
                                  )}
                                </select>
                                {validationType.touched.attendee_limit &&
                                validationType.errors.attendee_limit ? (
                                  <div
                                    style={{
                                      color: "#f46a6a",
                                      fontSize: "11px",
                                      marginTop: "3px",
                                    }}
                                  >
                                    {validationType.errors.attendee_limit}
                                  </div>
                                ) : null}
                              </div>
                            </Col>
                            <Col sm={6}>
                              <div className="mb-3">
                                <Label className="form-label">
                                  {props.t("Join_Number_Users")}{" "}
                                </Label>
                                <Input
                                  name="join_number_of_users"
                                  id="join_number_of_users"
                                  placeholder={props.t("Join_Number_Users")}
                                  type="text"
                                  readOnly={
                                    validationType.values.attendee_limit ===
                                    "LIMITED"
                                      ? false
                                      : true
                                  }
                                  onChange={validationType.handleChange}
                                  onBlur={validationType.handleBlur}
                                  value={
                                    validationType.values.attendee_limit ==
                                    "LIMITED"
                                      ? validationType.values
                                          .join_number_of_users
                                      : ""
                                  }
                                  invalid={
                                    validationType.touched
                                      .join_number_of_users &&
                                    validationType.errors.join_number_of_users
                                      ? true
                                      : false
                                  }
                                />
                                {validationType.touched.join_number_of_users &&
                                validationType.errors.join_number_of_users ? (
                                  <FormFeedback type="invalid">
                                    {validationType.errors.join_number_of_users}
                                  </FormFeedback>
                                ) : null}
                              </div>
                            </Col>
                          </Row>

                          <Row>
                            <Col sm={6}>
                              <div className="mb-3">
                                <Label className="form-label">
                                  {props.t("Type")}{" "}
                                  <span className="text-danger">*</span>
                                </Label>
                                <select
                                  name="type"
                                  id="type"
                                  type="select"
                                  className="form-control"
                                  onChange={validationType.handleChange}
                                  onBlur={validationType.handleBlur}
                                  value={validationType.values.type || ""}
                                >
                                  <option value="">
                                    {props.t("Please_Select_Type")}
                                  </option>
                                  {Object.keys(constants.TYPE).map(key => {
                                    return (
                                      <option value={key} key={key}>
                                        {constants.TYPE[key]}
                                      </option>
                                    )
                                  })}
                                </select>
                                {validationType.touched.type &&
                                validationType.errors.type ? (
                                  <div
                                    style={{
                                      color: "#f46a6a",
                                      fontSize: "11px",
                                      marginTop: "3px",
                                    }}
                                  >
                                    {validationType.errors.type}
                                  </div>
                                ) : null}
                              </div>
                            </Col>

                            {(validationType.values.type ===
                              constants.TYPE.Online ||
                              validationType.values.type ===
                                constants.TYPE.Hybrid) && (
                              <Col sm={6}>
                                <div className="mb-3">
                                  <Label className="form-label">
                                    {props.t("Online_Meeting_Url")}{" "}
                                    <span className="text-danger">*</span>{" "}
                                  </Label>
                                  <Input
                                    name="online_meeting_url"
                                    id="online_meeting_url"
                                    placeholder={props.t("Online_Meeting_Url")}
                                    type="text"
                                    readOnly={
                                      validationType.values.type ===
                                        constants.TYPE.Online ||
                                      validationType.values.type ===
                                        constants.TYPE.Hybrid
                                        ? false
                                        : true
                                    }
                                    onChange={validationType.handleChange}
                                    onBlur={validationType.handleBlur}
                                    value={
                                      validationType.values.type ===
                                        constants.TYPE.Online ||
                                      validationType.values.type ===
                                        constants.TYPE.Hybrid
                                        ? validationType.values
                                            .online_meeting_url
                                        : ""
                                    }
                                    invalid={
                                      validationType.touched
                                        .online_meeting_url &&
                                      validationType.errors.online_meeting_url
                                        ? true
                                        : false
                                    }
                                  />

                                  {validationType.touched.online_meeting_url &&
                                  validationType.errors.online_meeting_url ? (
                                    <FormFeedback type="invalid">
                                      {validationType.errors.online_meeting_url}
                                    </FormFeedback>
                                  ) : null}
                                </div>
                              </Col>
                            )}

                            {(validationType.values.type ===
                              constants.TYPE.Onsite ||
                              validationType.values.type ===
                                constants.TYPE.Hybrid) && (
                              <>
                                <Col sm={6}>
                                  <LoadScript
                                    googleMapsApiKey={google_key}
                                    libraries={libraries}
                                    region="th"
                                    country="th"
                                  >
                                    <div className="mb-3">
                                      <Label className="form-label">
                                        {props.t("Address")}{" "}
                                        <span className="text-danger">*</span>
                                      </Label>

                                      <StandaloneSearchBox
                                        onLoad={ref =>
                                          (inputRef1.current = ref)
                                        }
                                        onPlacesChanged={handlePlaceChangedOne}
                                      >
                                        <Input
                                          name="address"
                                          id="address"
                                          placeholder={props.t("Address")}
                                          type="text"
                                          onChange={validationType.handleChange}
                                          onBlur={validationType.handleBlur}
                                          readOnly={
                                            validationType.values.type ===
                                              constants.TYPE.Onsite ||
                                            validationType.values.type ===
                                              constants.TYPE.Hybrid
                                              ? false
                                              : true
                                          }
                                          value={
                                            validationType.values.type ===
                                              constants.TYPE.Onsite ||
                                            validationType.values.type ===
                                              constants.TYPE.Hybrid
                                              ? validationType.values.address
                                              : ""
                                          }
                                          invalid={
                                            validationType.touched.address &&
                                            validationType.errors.address
                                              ? true
                                              : false
                                          }
                                        />
                                      </StandaloneSearchBox>
                                      {/* </LoadScript> */}

                                      {validationType.touched.address &&
                                      validationType.errors.address ? (
                                        <FormFeedback type="invalid">
                                          {validationType.errors.address}
                                        </FormFeedback>
                                      ) : null}

                                      <div>
                                        <Button
                                          onClick={onLocationSearch}
                                          color="info"
                                          className="btn-sm"
                                          style={{
                                            float: "left",
                                            marginTop: "4px",
                                          }}
                                        >
                                          {props.t("FindLocation")}
                                        </Button>
                                      </div>

                                      <Modal
                                        className="map-model locationSearch"
                                        isOpen={showModal}
                                        centered={true}
                                      >
                                        <ModalBody>
                                          <Row>
                                            <Col lg={12}>
                                              <div className="text-center">
                                                <h4 className="modal-title">
                                                  {props.t(
                                                    "Whatyourexactlocation"
                                                  )}
                                                </h4>
                                              </div>
                                            </Col>
                                            <Col lg={12}>
                                              {/* <LoadScript
                                            googleMapsApiKey={google_key}
                                            libraries={libraries}
                                            region="th"
                                            country="th"
                                            > */}
                                              <StandaloneSearchBox
                                                onLoad={ref =>
                                                  (inputRef.current = ref)
                                                }
                                                onPlacesChanged={
                                                  handlePlaceChanged
                                                }
                                              >
                                                <input
                                                  type="text"
                                                  className="form-control"
                                                  placeholder={props.t(
                                                    "Address"
                                                  )}
                                                  style={{
                                                    marginBottom: "15px",
                                                    border: "1px solid #ccc",
                                                  }}
                                                />
                                              </StandaloneSearchBox>

                                              <div>
                                                <Button
                                                  color="info"
                                                  className="btn-sm"
                                                  onClick={() => {
                                                    setShowModal(false)
                                                  }}
                                                  style={{ float: "left" }}
                                                >
                                                  {props.t("Save")}
                                                </Button>
                                              </div>

                                              <br></br>
                                              <br></br>
                                              <div className="modal-body scrollAndFixedHeight">
                                                {latitude && longitude && (
                                                  <GoogleMap
                                                    zoom={14}
                                                    center={{
                                                      lat: latitude,
                                                      lng: longitude,
                                                    }}
                                                    id="map"
                                                    style={{
                                                      overflow: "unset",
                                                    }}
                                                    yesIWantToUseGoogleMapApiInternals
                                                  >
                                                    <MarkerF
                                                      position={{
                                                        lat: latitude,
                                                        lng: longitude,
                                                      }}
                                                      name={address}
                                                      draggable={true}
                                                      onDragEnd={event =>
                                                        onMarkerDragEnd(event)
                                                      }
                                                    />
                                                  </GoogleMap>
                                                )}
                                              </div>
                                              {/* </LoadScript> */}
                                            </Col>
                                          </Row>
                                        </ModalBody>
                                      </Modal>
                                    </div>
                                  </LoadScript>
                                </Col>

                                <Col sm={6}>
                                  <div className="mb-3">
                                    <Label className="form-label">
                                      {props.t("Latitude")}{" "}
                                      <span className="text-danger">*</span>
                                    </Label>
                                    <Input
                                      name="latitude"
                                      id="latitude"
                                      placeholder={props.t("Latitude")}
                                      type="text"
                                      onChange={validationType.handleChange}
                                      onBlur={validationType.handleBlur}
                                      readOnly={
                                        validationType.values.type ===
                                          constants.TYPE.Onsite ||
                                        validationType.values.type ===
                                          constants.TYPE.Hybrid
                                          ? false
                                          : true
                                      }
                                      value={
                                        validationType.values.type ===
                                          constants.TYPE.Onsite ||
                                        validationType.values.type ===
                                          constants.TYPE.Hybrid
                                          ? validationType.values.latitude
                                          : ""
                                      }
                                      invalid={
                                        validationType.touched.latitude &&
                                        validationType.errors.latitude
                                          ? true
                                          : false
                                      }
                                    />
                                    {validationType.touched.latitude &&
                                    validationType.errors.latitude ? (
                                      <FormFeedback type="invalid">
                                        {validationType.errors.latitude}
                                      </FormFeedback>
                                    ) : null}
                                  </div>
                                </Col>

                                <Col sm={6}>
                                  <div className="mb-3">
                                    <Label className="form-label">
                                      {props.t("Longitude")}{" "}
                                      <span className="text-danger">*</span>
                                    </Label>
                                    <Input
                                      name="longitude"
                                      id="longitude"
                                      placeholder={props.t("Longitude")}
                                      type="text"
                                      onChange={validationType.handleChange}
                                      onBlur={validationType.handleBlur}
                                      readOnly={
                                        validationType.values.type ===
                                          constants.TYPE.Onsite ||
                                        validationType.values.type ===
                                          constants.TYPE.Hybrid
                                          ? false
                                          : true
                                      }
                                      value={
                                        validationType.values.type ===
                                          constants.TYPE.Onsite ||
                                        validationType.values.type ===
                                          constants.TYPE.Hybrid
                                          ? validationType.values.longitude
                                          : ""
                                      }
                                      invalid={
                                        validationType.touched.longitude &&
                                        validationType.errors.longitude
                                          ? true
                                          : false
                                      }
                                    />
                                    {validationType.touched.longitude &&
                                    validationType.errors.longitude ? (
                                      <FormFeedback type="invalid">
                                        {validationType.errors.longitude}
                                      </FormFeedback>
                                    ) : null}
                                  </div>
                                </Col>
                              </>
                            )}
                          </Row>

                          <Row>
                            <Col sm={6}>
                              <div className="mb-3">
                                <Label className="form-label">
                                  {props.t("Fee_Type")}{" "}
                                  <span className="text-danger">*</span>
                                </Label>
                                <select
                                  name="fee_type"
                                  id="fee_type"
                                  type="select"
                                  className="form-control"
                                  onChange={validationType.handleChange}
                                  onBlur={validationType.handleBlur}
                                  value={validationType.values.fee_type || ""}
                                >
                                  <option value="">
                                    {props.t("Select_Fee_Type")}
                                  </option>
                                  {Object.keys(constants.FEE_TYPE).map(key => {
                                    return (
                                      <option value={key} key={key}>
                                        {constants.FEE_TYPE[key]}
                                      </option>
                                    )
                                  })}
                                </select>
                                {validationType.touched.fee_type &&
                                validationType.errors.fee_type ? (
                                  <div
                                    style={{
                                      color: "#f46a6a",
                                      fontSize: "11px",
                                      marginTop: "3px",
                                    }}
                                  >
                                    {validationType.errors.fee_type}
                                  </div>
                                ) : null}
                              </div>
                            </Col>
                            <Col sm={6}>
                              <div className="mb-3">
                                <Label className="form-label">
                                  {props.t("Fees")}{" "}
                                </Label>
                                <Input
                                  name="fees"
                                  id="fees"
                                  placeholder={props.t("Fees")}
                                  type="text"
                                  readOnly={
                                    validationType.values.fee_type === "PAID"
                                      ? false
                                      : true
                                  }
                                  onChange={validationType.handleChange}
                                  onBlur={validationType.handleBlur}
                                  value={
                                    validationType.values.fee_type == "FREE"
                                      ? ""
                                      : validationType.values.fees
                                  }
                                  invalid={
                                    validationType.errors.fees ? true : false
                                  }
                                />
                                {validationType.errors.fees ? (
                                  <div
                                    style={{
                                      color: "#f46a6a",
                                      fontSize: "11px",
                                      marginTop: "3px",
                                    }}
                                  >
                                    {validationType.errors.fees}
                                  </div>
                                ) : null}
                              </div>
                            </Col>
                          </Row>

                          <Row>
                            <Col sm={6}>
                              <div className="mb-3">
                                <Label className="form-label">
                                  {props.t("Speaker_Type")}{" "}
                                  <span className="text-danger">*</span>
                                </Label>
                                <select
                                  name="speaker_type"
                                  id="speaker_type"
                                  type="select"
                                  className="form-control"
                                  onChange={e =>
                                    handleChangeSpeakerType(e.target.value)
                                  }
                                  onBlur={validationType.handleBlur}
                                  value={
                                    validationType.values.speaker_type || ""
                                  }
                                >
                                  <option value="">
                                    {props.t("Select_Speaker_Type")}
                                  </option>
                                  {Object.keys(constants.SPEAKER_TYPE).map(
                                    key => {
                                      return (
                                        <option value={key} key={key}>
                                          {constants.SPEAKER_TYPE[key]}
                                        </option>
                                      )
                                    }
                                  )}
                                </select>
                                {validationType.touched.speaker_type &&
                                validationType.errors.speaker_type ? (
                                  <div
                                    style={{
                                      color: "#f46a6a",
                                      fontSize: "11px",
                                      marginTop: "3px",
                                    }}
                                  >
                                    {validationType.errors.speaker_type}
                                  </div>
                                ) : null}
                              </div>
                            </Col>

                            <Col sm={6}>
                              <div className="mb-3">
                                <Label className="form-label">
                                  {props.t("Speaker_User")}{" "}
                                  <span className="text-danger">*</span>
                                </Label>
                                {/* <Select
                                                            name="speaker_user_id"
                                                            onChange={validationType.handleChange}
                                                            onBlur={validationType.handleBlur}
                                                            value={validationType.values.speaker_user_id || ""}
                                                            options={userList}
                                                            classNamePrefix="select2-selection"
                                                        /> */}
                                {/* <select
                              name="speaker_user_id"
                              type="select"
                              className="form-control"
                              onChange={validationType.handleChange}
                              onBlur={validationType.handleBlur}
                              value={
                                validationType.values.speaker_user_id || ""
                              }
                            >
                              <option value="">
                                {props.t("Select_Speaker")}
                              </option>
                              {userList.map((item, i) => {
                                return (
                                  <option value={item.id} key={i}>
                                    {item.first_name} {item.last_name}
                                  </option>
                                )
                              })}
                            </select> */}
                                <input
                                  type="text"
                                  className="hide-focus"
                                  id="speaker_user_id"
                                />
                                <Select
                                  className={
                                    validationType.touched.speaker_user_id &&
                                    validationType.errors.speaker_user_id
                                      ? " bor"
                                      : " text-dark  "
                                  }
                                  styles={{
                                    control: (provided, state) => ({
                                      ...provided,
                                      menuPortal: base => ({
                                        ...base,
                                        zIndex: 9999,
                                      }),
                                      borderColor:
                                        validationType.touched
                                          .speaker_user_id &&
                                        validationType.errors.speaker_user_id
                                          ? "#f46a6a"
                                          : "#ccc",
                                    }),
                                  }}
                                  // defaultValue={
                                  //   validationType.values.speaker_user_id
                                  // }
                                  defaultValue={selectedOptionSpeaker}
                                  onBlur={validationType.handleBlur}
                                  name="speaker_user_id"
                                  isMulti={true}
                                  options={[...doctorsData]}
                                  onChange={e => {
                                    const f = e.map((item, index) => {
                                      return item.value
                                    })
                                    validationType.setFieldValue(
                                      "speaker_user_id",
                                      f
                                    )
                                    setSelectedOptionSpeaker(e)
                                  }}
                                  onInputChange={onSearchDoctorHandler}
                                  placeholder={
                                    <div>{props.t("typeToSearch")}</div>
                                  }
                                />

                                {validationType.touched.speaker_user_id &&
                                validationType.errors.speaker_user_id ? (
                                  <div
                                    style={{
                                      color: "#f46a6a",
                                      fontSize: "11px",
                                      marginTop: "3px",
                                    }}
                                  >
                                    {validationType.errors.speaker_user_id}
                                  </div>
                                ) : null}
                              </div>
                            </Col>
                          </Row>
                        </>
                      )}
                      {/*end cond  */}
                      <Row>
                        {validationType.values.education_type !==
                        constants.EDUCATION_TYPE.Multimedia ? (
                          <Col sm={6}>
                            <div className="mb-3">
                              <Label htmlFor="validationCustom01">
                                {props.t("Images")}
                                {/* <span className="text-danger">*</span> */}
                              </Label>
                              <span className="text-danger">
                                {" "}
                                ({props.t("Note")} :{" "}
                                {props.t("Image_File_Extensions")})
                              </span>
                              <Input
                                name="activity_images"
                                id="activity_images"
                                type="file"
                                multiple
                                className="form-control"
                                onBlur={e => {
                                  if (
                                    e.target.files.length == 0 &&
                                    validationType.values.activity_images &&
                                    validationType.values.activity_images
                                      .length == 0
                                  ) {
                                    validationType.setFieldValue(
                                      "activity_images",
                                      null
                                    )
                                  }
                                }}
                                onChange={e => {
                                  const filesArray2 = []
                                  for (
                                    let i = 0;
                                    i < e.target.files.length;
                                    i++
                                  ) {
                                    filesArray2.push(e.target.files[i])
                                  }
                                  validationType.setFieldValue(
                                    "activity_images",
                                    validationType.values.activity_images
                                      ? [
                                          ...validationType.values
                                            .activity_images,
                                          ...filesArray2,
                                        ]
                                      : filesArray2
                                  )
                                }}
                                invalid={
                                  validationType.touched.activity_images &&
                                  validationType.errors.activity_images
                                    ? true
                                    : false
                                }
                              />
                              <span className="text-danger">
                                {props.t("Size")} (310 X 310)
                              </span>
                              {validationType.touched.activity_images &&
                              validationType.errors.activity_images ? (
                                <div
                                  style={{
                                    color: "#f46a6a",
                                    fontSize: "11px",
                                    marginTop: "3px",
                                  }}
                                >
                                  {validationType.errors.activity_images}
                                </div>
                              ) : null}
                            </div>
                            {validationType.values.activity_images?.length >
                            0 ? (
                              <div className="d-flex  px-3 py-2 pb-4">
                                {validationType.values.activity_images?.map(
                                  (item, index) => {
                                    return (
                                      <Col sm={2} key={index}>
                                        <CardImg
                                          src={URL.createObjectURL(item)}
                                          alt="Activity"
                                          className="rounded avatar-sm"
                                        />
                                        <CardText class Name="mt-2 mb-lg-0">
                                          <a
                                            className="text-danger"
                                            type="button"
                                            onClick={() => {
                                              removeImage(item.lastModified)
                                            }}
                                          >
                                            {props.t("Remove")}
                                          </a>
                                        </CardText>
                                      </Col>
                                    )
                                  }
                                )}
                              </div>
                            ) : null}
                          </Col>
                        ) : (
                          <>
                            <Col sm={6}>
                              <div className="mb-3">
                                <Label className="form-label">
                                  {props.t("video")}
                                  <span className="text-danger"> *</span>
                                </Label>
                                <Input
                                  name="videoFile"
                                  id="videoFile"
                                  type="file"
                                  className="form-control"
                                  onChange={e => {
                                    setVideoFile(e.target.files[0])
                                    validationType.setFieldValue(
                                      "videoFile",
                                      e.target.files[0]
                                    )
                                  }}
                                  accept="video/mp4,video/x-m4v,video/*"
                                  invalid={
                                    validationType.touched.videoFile &&
                                    validationType.errors.videoFile
                                      ? true
                                      : false
                                  }
                                />
                                {validationType.touched.videoFile &&
                                validationType.errors.videoFile ? (
                                  <div
                                    style={{
                                      color: "#f46a6a",
                                      fontSize: "11px",
                                      marginTop: "3px",
                                    }}
                                  >
                                    {validationType.errors.videoFile}
                                  </div>
                                ) : null}
                              </div>
                            </Col>
                            <Col sm={6}>
                              <div className="mb-3">
                                <Label htmlFor="validationCustom01">
                                  {props.t("thumbnailImage")}
                                  {/* <span className="text-danger">*</span> */}
                                </Label>
                                <span className="text-danger">
                                  {" "}
                                  ({props.t("Note")} :{" "}
                                  {props.t("Image_File_Extensions")})
                                </span>
                                <Input
                                  name="videoThumbnailImage"
                                  id="videoThumbnailImage"
                                  type="file"
                                  className="form-control"
                                  onChange={e => {
                                    setVideoThumbnailFile(e.target.files[0])
                                    validationType.setFieldValue(
                                      "videoThumbnailImage",
                                      e.target.files[0]
                                    )
                                  }}
                                  accept="image/png, image/jpeg"
                                  invalid={
                                    validationType.errors.videoThumbnailImage
                                      ? true
                                      : false
                                  }
                                />
                                <span className="text-danger">
                                  {props.t("Size")} (310 X 310)
                                </span>
                                {validationType.errors.videoThumbnailImage ? (
                                  <div
                                    style={{
                                      color: "#f46a6a",
                                      fontSize: "11px",
                                      marginTop: "3px",
                                    }}
                                  >
                                    {validationType.errors.videoThumbnailImage}
                                  </div>
                                ) : null}
                              </div>
                              {validationType.values.videoThumbnailImage ? (
                                <div className="d-flex  px-3 py-2 pb-4">
                                  <Col sm={6} key="videothumnailimage">
                                    <CardImg
                                      src={URL.createObjectURL(
                                        validationType.values
                                          .videoThumbnailImage
                                      )}
                                      height={250}
                                      width={250}
                                      alt="Activity"
                                    />
                                  </Col>
                                </div>
                              ) : null}
                            </Col>
                          </>
                        )}

                        {validationType.values.education_type !==
                          constants.EDUCATION_TYPE.Multimedia && (
                          <>
                            <Col sm={6}>
                              <div className="mb-3">
                                <Label className="form-label">
                                  {props.t("Details_Activity_PDF")}
                                </Label>
                                <Input
                                  name="file_url"
                                  id="file_url"
                                  type="file"
                                  className="form-control"
                                  onChange={e => {
                                    setFiles2(e.target.files[0])
                                    validationType.setFieldValue(
                                      "file_url",
                                      e.target.files[0]
                                    )
                                  }}
                                  invalid={
                                    validationType.touched.file_url &&
                                    validationType.errors.file_url
                                      ? true
                                      : false
                                  }
                                />
                                {validationType.touched.file_url &&
                                validationType.errors.file_url ? (
                                  <div
                                    style={{
                                      color: "#f46a6a",
                                      fontSize: "11px",
                                      marginTop: "3px",
                                    }}
                                  >
                                    {validationType.errors.file_url}
                                  </div>
                                ) : null}
                              </div>
                            </Col>

                            <Col sm={6}>
                              <div className="mb-3">
                                <Label className="form-label">
                                  {props.t("Website")}{" "}
                                  <span className="text-danger">*</span>
                                </Label>
                                <Input
                                  name="website"
                                  id="website"
                                  placeholder={props.t("Website")}
                                  type="text"
                                  onChange={validationType.handleChange}
                                  onBlur={validationType.handleBlur}
                                  value={validationType.values.website || ""}
                                  invalid={
                                    validationType.touched.website &&
                                    validationType.errors.website
                                      ? true
                                      : false
                                  }
                                />
                                {validationType.touched.website &&
                                validationType.errors.website ? (
                                  <FormFeedback type="invalid">
                                    {validationType.errors.website}
                                  </FormFeedback>
                                ) : null}
                              </div>
                            </Col>

                            <Col sm={6}>
                              <div className="mb-3">
                                <Label className="form-label">
                                  {props.t("Email")}{" "}
                                  <span className="text-danger">*</span>
                                </Label>
                                <Input
                                  name="email"
                                  id="email"
                                  placeholder={props.t("Email")}
                                  type="text"
                                  onChange={validationType.handleChange}
                                  onBlur={validationType.handleBlur}
                                  value={validationType.values.email || ""}
                                  invalid={
                                    validationType.touched.email &&
                                    validationType.errors.email
                                      ? true
                                      : false
                                  }
                                />
                                {validationType.touched.email &&
                                validationType.errors.email ? (
                                  <FormFeedback type="invalid">
                                    {validationType.errors.email}
                                  </FormFeedback>
                                ) : null}
                              </div>
                            </Col>

                            <Col sm={6}>
                              <div className="mb-3">
                                <Label className="form-label">
                                  {props.t("Contact_Number")}
                                  <span className="text-danger">*</span>
                                </Label>
                                <Input
                                  name="contact_number"
                                  id="contact_number"
                                  placeholder={props.t("Contact_Number")}
                                  type="text"
                                  onChange={validationType.handleChange}
                                  onBlur={validationType.handleBlur}
                                  value={
                                    validationType.values.contact_number || ""
                                  }
                                  invalid={
                                    validationType.touched.contact_number &&
                                    validationType.errors.contact_number
                                      ? true
                                      : false
                                  }
                                />
                                {validationType.touched.contact_number &&
                                validationType.errors.contact_number ? (
                                  <FormFeedback type="invalid">
                                    {validationType.errors.contact_number}
                                  </FormFeedback>
                                ) : null}
                              </div>
                            </Col>

                            <Col sm={6}>
                              <div className="mb-3">
                                <Label className="form-label">
                                  {props.t("Facebook_Link")}{" "}
                                  <span className="text-danger">*</span>
                                </Label>
                                <Input
                                  name="facebook_link"
                                  id="facebook_link"
                                  placeholder={props.t("Facebook_Link")}
                                  type="text"
                                  onChange={validationType.handleChange}
                                  onBlur={validationType.handleBlur}
                                  value={
                                    validationType.values.facebook_link || ""
                                  }
                                  invalid={
                                    validationType.touched.facebook_link &&
                                    validationType.errors.facebook_link
                                      ? true
                                      : false
                                  }
                                />
                                {validationType.touched.facebook_link &&
                                validationType.errors.facebook_link ? (
                                  <FormFeedback type="invalid">
                                    {validationType.errors.facebook_link}
                                  </FormFeedback>
                                ) : null}
                              </div>
                            </Col>

                            <Col sm={6}>
                              <div className="mb-3">
                                <Label className="form-label">
                                  {props.t("Interests")}{" "}
                                  <span className="text-danger">*</span>
                                </Label>
                                <input
                                  type="text"
                                  className="hide-focus"
                                  id="interest_id"
                                />
                                <Select
                                  defaultValue={selectedOption}
                                  name="interest_id"
                                  onChange={e => {
                                    const f = e.map((item, index) => {
                                      return item.value
                                    })
                                    validationType.setFieldValue(
                                      "interest_id",
                                      f
                                    )
                                    setSelectedOption(e)
                                  }}
                                  options={interestList}
                                  isMulti={true}
                                  menuPortalTarget={document.body}
                                  styles={{
                                    control: (provided, state) => ({
                                      ...provided,
                                      menuPortal: base => ({
                                        ...base,
                                        zIndex: 9999,
                                      }),
                                      borderColor:
                                        validationType.touched.interest_id &&
                                        validationType.errors.interest_id
                                          ? "#f46a6a"
                                          : "#ccc",
                                    }),
                                  }}
                                />
                                {validationType.touched.interest_id &&
                                validationType.errors.interest_id ? (
                                  <div
                                    style={{
                                      color: "#f46a6a",
                                      fontSize: "11px",
                                      marginTop: "3px",
                                    }}
                                  >
                                    {validationType.errors.interest_id}
                                  </div>
                                ) : null}
                              </div>
                            </Col>
                          </>
                        )}

                        <Col sm={6}>
                          <div className="mb-3">
                            <Label className="form-label">
                              {props.t("Status")}{" "}
                              <span className="text-danger">*</span>
                            </Label>
                            <select
                              name="status"
                              type="select"
                              className="form-control"
                              // onChange={validationType.handleChange}
                              onChange={e => 
                                changeActivityStatus(e.target.value)
                              }
                              onBlur={validationType.handleBlur}
                              value={validationType.values.status || ""}
                            >
                              {Object.keys(constants.ACTIVITY_STATUS).map(key => {
                                return ( 
                                  <option value={key} key={key}>
                                    {constants.ACTIVITY_STATUS[key]}
                                  </option>
                                )
                              })}
                            </select> 
                            {validationType.touched.status &&
                            validationType.errors.status ? (
                              <div
                                style={{
                                  color: "#f46a6a",
                                  fontSize: "11px",
                                  marginTop: "3px",
                                }}
                              >
                                {validationType.errors.status}
                              </div>
                            ) : null}
                          </div>
                        </Col>
                      </Row>


                      {isSchedulePublish &&
                          <Row>
                          <Col sm={6}>
                                <div className="mb-3">
                                  <Label className="form-label">
                                    {props.t("Activity_Publish_Date")}{" "}
                                    <span className="text-danger">*</span>
                                  </Label>
                                  <Input
                                    name="publish_date"
                                    id="publish_date"
                                    type="date"
                                    locale="th"
                                    onChange={validationType.handleChange}
                                    onBlur={validationType.handleBlur}
                                    min={moment().format("Y-M-D")}
                                    value={
                                      validationType.values.publish_date || ""
                                    }
                                    invalid={
                                      validationType.touched.publish_date &&
                                      validationType.errors.publish_date
                                        ? true
                                        : false
                                    }
                                  />
                                  {validationType.touched.publish_date &&
                                  validationType.errors.publish_date ? (
                                    <FormFeedback type="invalid">
                                      {validationType.errors.publish_date}
                                    </FormFeedback>
                                  ) : null}
                                </div>
                              </Col>
                              

                              <Col sm={6}>
                                  <div className="mb-3">
                                    <Label className="form-label">
                                      {props.t("Publish_Time")}{" "}
                                      <span className="text-danger">*</span>
                                    </Label>
                                  
                                    <Input
                                      name="publish_time"
                                      id="publish_time"
                                      type="time"
                                      onChange={validationType.handleChange}
                                      onBlur={validationType.handleBlur}
                                      value={validationType.values.publish_time || ""}
                                      invalid={
                                        validationType.touched.publish_time &&
                                        validationType.errors.publish_time
                                          ? true
                                          : false
                                      }
                                    />
                                    {validationType.touched.publish_time &&
                                    validationType.errors.publish_time ? (
                                      <FormFeedback type="invalid">
                                        {validationType.errors.publish_time}
                                      </FormFeedback>
                                    ) : null}
                                  </div>
                                </Col>

                          </Row>
                      }




















                      <Row>
                        <Col sm={12}>
                          <div className="mb-3">
                            <Label className="form-label">
                              {props.t("Description_Th")}{" "}
                              <span className="text-danger">*</span>
                            </Label>
                            <input
                              type="text"
                              className="hide-focus"
                              id="description_th"
                            />
                            <Editor
                              name="description_th"
                              editorState={contentTh}
                              onEditorStateChange={state => setContentTh(state)}
                              toolbarClassName="toolbarClassName"
                              wrapperClassName="wrapperClassName"
                              editorClassName="editorClassName"
                              styles={{
                                control: (provided, state) => ({
                                  ...provided,
                                  borderColor:
                                    validationType.touched.description_th &&
                                    validationType.errors.description_th
                                      ? "#f46a6a"
                                      : "#ccc",
                                }),
                              }}
                            />

                            {validationType.touched.description_th &&
                            validationType.errors.description_th ? (
                              <div
                                style={{
                                  color: "#f46a6a",
                                  fontSize: "11px",
                                  marginTop: "3px",
                                }}
                              >
                                {validationType.errors.description_th}
                              </div>
                            ) : null}
                          </div>
                        </Col>

                        <Col sm={12}>
                          <div className="mb-3">
                            <Label className="form-label">
                              {props.t("Description_En")}
                              {/* <span className="text-danger">*</span> */}
                            </Label>
                            <Editor
                              name="description_en"
                              editorState={contentEn}
                              onEditorStateChange={state => setContentEn(state)}
                              toolbarClassName="toolbarClassName"
                              wrapperClassName="wrapperClassName"
                              editorClassName="editorClassName"
                              styles={{
                                control: (provided, state) => ({
                                  ...provided,
                                  borderColor:
                                    validationType.touched.description_en &&
                                    validationType.errors.description_en
                                      ? "1px solid #f46a6a"
                                      : "#ccc",
                                }),
                              }}
                            />
                          </div>

                          {validationType.touched.description_en &&
                          validationType.errors.description_en ? (
                            <div
                              style={{
                                color: "#f46a6a",
                                fontSize: "11px",
                                marginTop: "3px",
                              }}
                            >
                              {validationType.errors.description_en}
                            </div>
                          ) : null}
                        </Col>
                      </Row>

                      {validationType.values.education_type !==
                        constants.EDUCATION_TYPE.Multimedia && (
                        <Row>
                          {formValues.map((element, index) => (
                            <>
                              <Col sm={4}>
                                <div className="mb-3">
                                  <Label htmlFor="validationCustom01">
                                    {props.t("banner_images")}
                                    {/* <span className="text-danger">*</span> */}
                                  </Label>
                                  <Input
                                    key={`{bannerImage}` + index}
                                    name="banner_images"
                                    type="file"
                                    className="form-control"
                                    onChange={(e, i) => {
                                      onUploadBannerHandleChange(
                                        e.target.files[0],
                                        "File",
                                        index
                                      )
                                      handleChange(i, e)
                                      validationType.setFieldValue(
                                        "banner_images",
                                        e.target.files[0]
                                      )
                                    }}
                                    invalid={
                                      validationType.touched.banner_images &&
                                      validationType.errors.banner_images
                                        ? true
                                        : false
                                    }
                                  />
                                  {validationType.touched.banner_images &&
                                  validationType.errors.banner_images ? (
                                    <div
                                      style={{
                                        color: "#f46a6a",
                                        fontSize: "11px",
                                        marginTop: "3px",
                                      }}
                                    >
                                      {validationType.errors.banner_images}
                                    </div>
                                  ) : null}
                                </div>
                              </Col>

                              <Col sm={4}>
                                <div className="mb-3">
                                  <Label className="form-label">
                                    {props.t("Banner_Link")}
                                    {/* <span className="text-danger">*</span></Label> */}
                                  </Label>
                                  <Input
                                    key={`{bannerLink}` + index}
                                    name="banner_links"
                                    placeholder={props.t("Banner_Link")}
                                    type="text"
                                    // onChange={validationType.handleChange}
                                    onChange={e => {
                                      handleChange(index, e)
                                      onUploadBannerHandleChange(
                                        e.target.value,
                                        "bannerLink",
                                        index
                                      )
                                    }}
                                    onBlur={validationType.handleBlur}
                                    // value={validationType.values.banner_links || ""}
                                    invalid={
                                      validationType.touched.banner_links &&
                                      validationType.errors.banner_links
                                        ? true
                                        : false
                                    }
                                  />
                                  {validationType.touched.banner_links &&
                                  validationType.errors.banner_links ? (
                                    <FormFeedback type="invalid">
                                      {validationType.errors.banner_links}
                                    </FormFeedback>
                                  ) : null}
                                </div>
                              </Col>

                              <Col sm={3}>
                                <div className="mb-3">
                                  <Label className="form-label">
                                    {props.t("Rank")}
                                    {/* <span className="text-danger">*</span> */}
                                  </Label>
                                  <Input
                                    key={`{bannerRank}` + index}
                                    name="banner_rank"
                                    placeholder={props.t("Rank")}
                                    type="text"
                                    // onChange={validationType.handleChange}
                                    onChange={e => {
                                      handleChange(index, e)
                                      onUploadBannerHandleChange(
                                        e.target.value,
                                        "bannerRank",
                                        index
                                      )
                                    }}
                                    onBlur={validationType.handleBlur}
                                    // value={validationType.values.banner_links || ""}
                                    invalid={
                                      validationType.touched.banner_rank &&
                                      validationType.errors.banner_rank
                                        ? true
                                        : false
                                    }
                                  />
                                  {validationType.touched.banner_rank &&
                                  validationType.errors.banner_rank ? (
                                    <FormFeedback type="invalid">
                                      {validationType.errors.banner_rank}
                                    </FormFeedback>
                                  ) : null}
                                </div>
                              </Col>

                              <Col sm={1}>
                                <div className="mb-3">
                                  <Label className="form-label"></Label>
                                  {index ? (
                                    <Button
                                      color="danger"
                                      className="btn-sm"
                                      style={{ marginTop: "30px" }}
                                      onClick={e => {
                                        removeFormFields(index)
                                        onRemoveBannerHandleChange(index)
                                      }}
                                    >
                                      {props.t("Remove")}
                                    </Button>
                                  ) : null}
                                </div>
                              </Col>
                            </>
                          ))}
                          <Col sm={12} className="mb-3">
                            <Button
                              color="success"
                              className="btn-sm"
                              onClick={e => addFormFields()}
                            >
                              {props.t("Add_More")}
                            </Button>
                          </Col>
                        </Row>
                      )}

                      <div className="d-flex flex-wrap gap-2">
                        {isSubmit ? (
                          <Button type="submit" disabled color="primary">
                            {props.t("Submit")}
                          </Button>
                        ) : (
                          <Button type="submit" color="primary">
                            {props.t("Submit")}
                          </Button>
                        )}{" "}
                        <Button color="secondary" onClick={e => formClear()}>
                          {props.t("Clear")}
                        </Button>
                      </div>
                    </Form>
                  </Col>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
} 

// AddOffer.propTypes = {
//   t: PropTypes.any,
// }

export default withTranslation()(AddOffer)
