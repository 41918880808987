import React, { useEffect, useState } from "react";
import { withTranslation } from "react-i18next";

import PropTypes from 'prop-types';

//import components
import Breadcrumbs from '../../../components/Common/Breadcrumb';
import DataTable from 'react-data-table-component';
import { TaskTypesApi } from "apis/TaskTypesApi";
import { useHistory } from "react-router-dom"
import * as Yup from "yup";
import { useFormik } from "formik";
import toastr from "toastr";
import config from "config/config";
import constants from "../../../config/constants";

import {
    Container,
    Row,
    Col,
    Card,
    CardBody,
    Form,
    Label,
    Input,
    FormFeedback,
    Button
} from "reactstrap";

import Swal from "sweetalert2";

function TaskTypes(props) {

    const history = useHistory();
    const [TaskTypesList, setTaskTypesList] = useState([]);
    const [loading, setLoading] = useState(false);

    const [activeData, setActiveData] = useState({
        page: 1,
        totalPage: 1,
        search: "",
        limit: config.LIMIT
    });
    const [limit, setCurrentLimit] = useState(config.LIMIT);

    useEffect(() => {
        getAllTaskTypes(activeData)
    }, [limit])


    const columns = [
        {
            name: props.t("Sr_No"),
            selector: row => row.task_id,
            sort: "asc",
            width: 50,
            sortable: true,
            defaultSortField: true,
            defaultSortAsc: false
        },
        {
            name: props.t("Name_Eng"),
            selector: row => row.name_en,
            sort: "asc",
            width: 200,
            sortable: true,
            defaultSortField: true,
            defaultSortAsc: false
        },
        {
            name: props.t("Name_Thai"),
            selector: row => row.name_th,
            sort: "asc",
            width: 200,
            sortable: true,
            defaultSortField: true,
            defaultSortAsc: false
        },
    ];


    const getAllTaskTypes = (data) => {
        setLoading(true);
        TaskTypesApi.getAllTaskTypes(data)
            .then((res) => {
                console.log(res.data)
                setActiveData({
                    page: activeData.page,
                    totalPage: res?.data?.data?.count,
                    search: activeData.search,
                    limit: activeData.limit,
                });
                let data = [];
                var sr_no = res.data.sr_no_start;

                res?.data?.taskTypes?.rows?.forEach((v, i) => {
                    sr_no = sr_no + 1;
                    data[i] = {
                        task_id: v.id,
                        name_en: v.name_en,
                        name_th: v.name_th,
                    };
                });
                setTaskTypesList(data);
                setLoading(false);
            })
            .catch((err) => {
                console.log(err);
            });
    };



    const handleChange = (v) => {
        setActiveData({ page: v, totalPage: activeData.totalPage, search: activeData.search, limit: limit });
        const data = { page: v, totalPage: activeData.totalPage, search: activeData.search, limit: limit }
        getAllBanner(data)
    }

    const handleRowChange = (v) => {
        setCurrentLimit(v);
        setActiveData({ page: activeData.page, totalPage: activeData.totalPage, search: activeData.search, limit: v });
        const data = { page: activeData.page, totalPage: activeData.totalPage, search: activeData.search, limit: v }
        getAllBanner(data)
    }

    document.title = props.t("Task_Types") + ' | ' + props.t("MedX");


    return (
        <div className="page-content">
            <div className="container-fluid">
                <Breadcrumbs title={props.t("Master")} breadcrumbItem={props.t("Task_Types")} />
            </div>
            <Container fluid={false}>
                <Row>
                    <Col lg={12}>
                        <Card>
                            <CardBody>
                                <Col sm={12}>
                                    <DataTable
                                        className="table-bordered"
                                        columns={columns}
                                        data={TaskTypesList}
                                    />
                                </Col>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default withTranslation()(TaskTypes);