import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import {
  Container,
  Row,
  Col,
} from "reactstrap"
import { getRoleID, getUserInfo } from "../../Token"
import { InstitutionApi } from "apis/InstitutionApi"
import { RequestInstitutionApi } from "apis/RequestInstitutionApi"
import { SubInstitutionApi } from "apis/SubInstitutionsApi"
import { RequestSubInstitutionApi } from "apis/RequestSubInstitutionApi"
import { ActivityApi } from "apis/ActivityApi"
import { CommunityApi } from "apis/CommunityApi"
import { CompaniesApi } from "apis/CompaniesApi"
import { CMSApi } from 'apis/CMSApi'
import { ProfileApi } from "apis/ProfileApi";
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { withTranslation } from "react-i18next"
import config from "config/config"
import DashboardCard from "./DashboardCard"
import constants from "config/constants"
import toastr from "toastr";
import "toastr/build/toastr.min.css";
import TermsAndConditionModal from "./TermsAndConditionModal"

const Dashboard = props => {
  const [modal, setmodal] = useState(false)
  const [subscribemodal, setSubscribemodal] = useState(false)
  const [instituteCount, setInstituteCount] = useState(0)
  const [requestInstituteCount, setRequestInstituteCount] = useState(0)
  const [subInstituteCount, setSubInstituteCount] = useState(0)
  const [requestSubInstituteCount, setRequestSubInstituteCount] = useState(0)
  const [activitiesCount, setActivitiesCount] = useState(0)
  const [communityPostCount, setCommunityPostCount] = useState(0)
  const [companiesCount, setCompaniesCount] = useState(0)
  const [activeData, setActiveData] = useState({
    activePage: 1,
    totalPage: 1,
    search: "",
    limit: config.LIMIT,
  })

  const [ActivitiesActiveData, setActivitiesActiveData] = useState({
    page: 1,
    totalPage: 1,
    search: "",
    limit: config.LIMIT,
  })
  const [termsAndCondtionData, setTermsAndCondtionData] = useState("");
  const [adminDetail, setAdminDetail] = useState({});
  const [showModal, setShowModal] = useState(false);

  const handleDeleteOrder = () => {
    setShowModal(false);
  };

  const getProfileDetail = () => {
    ProfileApi.getProfileDetail()
      .then((res) => {
        if (res.data.success) {
          setAdminDetail(res.data.data);
          if (!res.data.data.is_accept_termsandcondition) {
            getTermsAndCondtion();
          }
        } else {
          setAdminDetail("");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getTermsAndCondtion = async () => {
    CMSApi.cmsPageByTypeApi("TermsAndCondition")
      .then((res) => {
        if (res.data.success) {
          setTermsAndCondtionData(res.data.data);
          setShowModal(true);
        } else {
          setShowModal(false);
          toastr.error(res?.data?.message, "");
        }
      })
      .catch((err) => {
        toastr.error(err?.message, "");
        console.log(err);
      });
  };

  const onAcceptTermsAndCondition = async () => {
    CMSApi.acceptTermsandconditionApi()
      .then((res) => {
        if (res.data.success) {
          setShowModal(false);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getProfileDetail();
    getInstituteData(activeData)
    getRequestInstituteData(activeData)
    getSubInstituteData(activeData)
    getRequestSubInstituteData(activeData)
    getActivitiesData(ActivitiesActiveData)
    getCommunityPostsData(ActivitiesActiveData)
    getCompaniesData(ActivitiesActiveData)
  }, [])

  function getInstituteData(data) {
    InstitutionApi.getAllInstitutes(data).then(res => {
      setInstituteCount(res.data.requestinstitute.count)
    })
  }

  function getRequestInstituteData(data) {
    RequestInstitutionApi.getAllRequestInstitutions(data).then(res => {
      setRequestInstituteCount(res.data.requestinstitute.count)
    })
  }

  function getSubInstituteData(data) {
    SubInstitutionApi.getAllSubInstitutes(data).then(res => {
      setSubInstituteCount(res.data.subInstitutes.count)
    })
  }

  function getRequestSubInstituteData(data) {
    RequestSubInstitutionApi.getAllRequestSubInstitutions(data).then(res => {
      setRequestSubInstituteCount(res.data.requessubtinstitute.count)
    })
  }

  function getActivitiesData(data) {
    ActivityApi.getAllActivites(data).then(res => {
      setActivitiesCount(res.data.data.count)
    })
  }

  function getCommunityPostsData(data) {
    CommunityApi.getAllCommunity(data).then(res => {
      setCommunityPostCount(res.data.data.count)
    })
  }

  function getCompaniesData(data) {
    CompaniesApi.getAllCompanies(data).then(res => {
      setCompaniesCount(res.data.data.count)
    })
  }

  //meta title
  document.title = props.t("Dashboard") + " | " + props.t("MedX")

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs
            title={props.t("Dashboards")}
            breadcrumbItem={props.t("Dashboard")}
          />
          {getRoleID() === constants.ROLE.SUPERADMIN && (
            <>
              <Row>
                <Col sm={4}>
                  <DashboardCard
                    title={props.t("approvedInstitutes")}
                    path="/institutions"
                    totalCount={instituteCount}
                    iconName="fa-regular fa-building font-size-24"
                  />
                </Col>
                <Col sm={4}>
                  <DashboardCard
                    title={props.t("pendingInstitutes")}
                    path="/request-institutions"
                    totalCount={requestInstituteCount}
                    iconName="fa-solid fa-timeline font-size-24"
                  />
                </Col>
                <Col sm={4}>
                  <DashboardCard
                    title={props.t("approvedSubInstitutes")}
                    path="/sub-institutions"
                    totalCount={subInstituteCount}
                    iconName="fa-solid fa-building-columns font-size-24"
                  />
                </Col>
              </Row>
              <Row>
                <Col sm={4}>
                  <DashboardCard
                    title={props.t("pendingSubInstitutes")}
                    path="/request-sub-institutions"
                    totalCount={requestSubInstituteCount}
                    iconName="bx bx-buildings font-size-24"
                  />
                </Col>
                <Col sm={4}>
                  <DashboardCard
                    title={props.t("users")}
                    path="/users"
                    totalCount="40"
                    iconName="bx bxs-user font-size-24"
                  />
                </Col>

                <Col sm={4}>
                  <DashboardCard
                    title={props.t("activities")}
                    path="/activities"
                    totalCount={activitiesCount}
                    iconName="fa-solid fa-person-walking font-size-24"
                  />
                </Col>
              </Row>
              <Row>
                <Col sm={4}>
                  <DashboardCard
                    title={props.t("activitiesTakenByUser")}
                    path="/user-activities"
                    totalCount="25"
                    iconName="fa-solid fa-users font-size-24"
                  />
                </Col>

                <Col sm={4}>
                  <DashboardCard
                    title={props.t("communityPosts")}
                    path="/posts"
                    totalCount={communityPostCount}
                    iconName="fa-solid fa-users-rectangle font-size-24"
                  />
                </Col>

                <Col sm={4}>
                  <DashboardCard
                    title={props.t("companies")}
                    path="/companies"
                    totalCount={companiesCount}
                    iconName="bx bx-copy-alt font-size-24"
                  />
                </Col>
              </Row>
            </>
          )}

          {getRoleID() === constants.ROLE.CME && (
            <>
              <Row>
                <Col sm={4}>
                  <DashboardCard
                    title={props.t("approvedInstitutes")}
                    path="/institutions"
                    totalCount={instituteCount}
                    iconName="fa-regular fa-building font-size-24"
                  />
                </Col>
                <Col sm={4}>
                  <DashboardCard
                    title={props.t("pendingInstitutes")}
                    path="/request-institutions"
                    totalCount={requestInstituteCount}
                    iconName="fa-solid fa-timeline font-size-24"
                  />
                </Col>
                <Col sm={4}>
                  <DashboardCard
                    title={props.t("approvedSubInstitutes")}
                    path="/sub-institutions"
                    totalCount={subInstituteCount}
                    iconName="fa-solid fa-building-columns font-size-24"
                  />
                </Col>
              </Row>
              <Row>
                <Col sm={4}>
                  <DashboardCard
                    title={props.t("pendingSubInstitutes")}
                    path="/request-sub-institutions"
                    totalCount={requestSubInstituteCount}
                    iconName="bx bx-buildings font-size-24"
                  />
                </Col>
                <Col sm={4}>
                  <DashboardCard
                    title={props.t("users")}
                    path="/users"
                    totalCount="40"
                    iconName="bx bxs-user font-size-24"
                  />
                </Col>

                <Col sm={4}>
                  <DashboardCard
                    title={props.t("activities")}
                    path="/activities"
                    totalCount={activitiesCount}
                    iconName="fa-solid fa-person-walking font-size-24"
                  />
                </Col>
              </Row>
              <Row>
                <Col sm={4}>
                  <DashboardCard
                    title={props.t("activitiesTakenByUser")}
                    path="/user-activities"
                    totalCount="25"
                    iconName="fa-solid fa-users font-size-24"
                  />
                </Col>

                <Col sm={4}>
                  <DashboardCard
                    title={props.t("communityPosts")}
                    path="/posts"
                    totalCount={communityPostCount}
                    iconName="fa-solid fa-users-rectangle font-size-24"
                  />
                </Col>

                <Col sm={4}>
                  <DashboardCard
                    title={props.t("companies")}
                    path="/companies"
                    totalCount={companiesCount}
                    iconName="bx bx-copy-alt font-size-24"
                  />
                </Col>
              </Row>
            </>
          )}

          {getRoleID() === constants.ROLE.INSTITUTE && (
            <>
              <Row>
                <Col sm={4}>
                  <DashboardCard
                    title={props.t("approvedSubInstitutes")}
                    path="/sub-institutions"
                    totalCount={subInstituteCount}
                    iconName="fa-solid fa-building-columns font-size-24"
                  />
                </Col>
                <Col sm={4}>
                  <DashboardCard
                    title={props.t("pendingSubInstitutes")}
                    path="/request-sub-institutions"
                    totalCount={requestSubInstituteCount}
                    iconName="bx bx-buildings font-size-24"
                  />
                </Col>
                <Col sm={4}>
                  <DashboardCard
                    title={props.t("activities")}
                    path="/activities"
                    totalCount={activitiesCount}
                    iconName="fa-solid fa-person-walking font-size-24"
                  />
                </Col>
              </Row>
              <Row>
                <Col sm={4}>
                  <DashboardCard
                    title={props.t("activitiesTakenByUser")}
                    path="/user-activities"
                    totalCount="25"
                    iconName="fa-solid fa-users font-size-24"
                  />
                </Col>

                <Col sm={4}>
                  <DashboardCard
                    title={props.t("communityPosts")}
                    path="/posts"
                    totalCount={communityPostCount}
                    iconName="fa-solid fa-users-rectangle font-size-24"
                  />
                </Col>
              </Row>

              <TermsAndConditionModal
                show={showModal}
                acceptTermsAndCondition={() => onAcceptTermsAndCondition()}
                termsAndCondtionData = {termsAndCondtionData}
              />
            </>
          )}

          {getRoleID() === constants.ROLE.SUBINSTITUTE && (
            <>
              <Row>
                <Col sm={4}>
                  <DashboardCard
                    title={props.t("activities")}
                    path="/activities"
                    totalCount={activitiesCount}
                    iconName="fa-solid fa-person-walking font-size-24"
                  />
                </Col>
                <Col sm={4}>
                  <DashboardCard
                    title={props.t("activitiesTakenByUser")}
                    path="/user-activities"
                    totalCount="25"
                    iconName="fa-solid fa-users font-size-24"
                  />
                </Col>
                <Col sm={4}>
                  <DashboardCard
                    title={props.t("communityPosts")}
                    path="/posts"
                    totalCount={communityPostCount}
                    iconName="fa-solid fa-users-rectangle font-size-24"
                  />
                </Col>
              </Row>
            </>
          )}
        </Container>
      </div>
    </React.Fragment>
  )
}

Dashboard.propTypes = {
  t: PropTypes.any,
  chartsData: PropTypes.any,
  onGetChartsData: PropTypes.func,
}

export default withTranslation()(Dashboard)
