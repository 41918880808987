import React from "react"
import { CommunityApi } from "apis/CommunityApi"
import config from "config/config"
import { useEffect, useState } from "react"
import DataTable from "react-data-table-component"
import { withTranslation } from "react-i18next"
import { Link, useHistory } from "react-router-dom"
import { Card, CardBody, Col, Container, Input, Row, Button } from "reactstrap"
import Swal from "sweetalert2"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import * as url from "../../helpers/common_helper"
import { InternshipApi } from "apis/InternshipApi"

const EvaluationList = props => {
  const history = useHistory()
  const [sessions, setSessions] = useState([])
  const [loading, setLoading] = useState(false)
  const [activeData, setActiveData] = useState({
    page: 1,
    totalPage: 1,
    search: "",
    limit: config.LIMIT,
  })

  useEffect(() => {
    getAllSessions(activeData)
  }, [])

  const columns = [
    {
      name: props.t("Sr_No"),
      selector: row => row.id,
      sort: "asc",
      width: "auto",
      sortable: true,
      defaultSortField: true,
      defaultSortAsc: false,
    },
    {
      name: props.t("internshipCreator"),
      selector: row => row.institute_name,
      sort: "asc",
      width: "auto",
      sortable: true,
      defaultSortField: true,
      defaultSortAsc: false,
    },
    {
      name: props.t("task_type"),
      selector: row => row.task_name,
      sort: "asc",
      width: "auto",
      sortable: true,
      defaultSortField: true,
      defaultSortAsc: false,
    },
    {
      name: props.t("Start_Date"),
      selector: row => row.start_date,
      sort: "asc",
      width: "auto",
      sortable: true,
      defaultSortField: true,
      defaultSortAsc: false,
    },
    {
      name: props.t("End_Date"),
      selector: row => row.end_date,
      sort: "asc",
      width: 270,
      sortable: true,
      defaultSortField: true,
      defaultSortAsc: false,
    },
    {
      name: props.t("Action"),
      selector: row => row.action,
      sort: "asc",
      width: "auto",
    },
  ]

  const getAllSessions = data => {
    setLoading(true)
    InternshipApi.getEvaluationListApi(data)
      .then(res => {
        if (res.data.success) {
          setActiveData({
            page: activeData.page,
            totalPage: res?.data?.data?.count,
            search: activeData.search,
            limit: activeData.limit,
          })
          let data = []
          let sr_no = res.data.sr_no_start
          res?.data?.data?.rows?.forEach((session, i) => {
            sr_no = sr_no + 1
            data[i] = {
              id: sr_no,
              institute_name: session?.session?.instituteName,
              task_name: session.taskName,
              start_date: url.dateFormat(session.start_date),
              end_date: url.dateFormat(session.end_date),
              action: (
                <>
                  <Button
                    type="button"
                    color="primary"
                    className="btn-sm btn-rounded"
                    onClick={() => {
                      history.push({
                        pathname: `/add-evaluators/${session.id}/${session.task_type_id}`,
                        // state: { instituteId: session.institute.id },
                      })
                    }}
                  >
                    {props.t("addEvaluators")}
                  </Button>
                </>
              ),
            }
          })
          setSessions(data)
          setLoading(false)
        }
      })
      .catch(err => {
        console.log(err)
      })
  }

  const handleChange = v => {
    setActiveData({
      page: v,
      totalPage: activeData.totalPage,
      search: activeData.search,
      limit: activeData.limit,
    })
    const data = {
      page: v,
      totalPage: activeData.totalPage,
      search: activeData.search,
      limit: activeData.limit,
    }
    getAllSessions(data)
  }

  const handleRowChange = v => {
    setActiveData({
      page: activeData.page,
      totalPage: activeData.totalPage,
      search: activeData.search,
      limit: v,
    })
    const data = {
      page: activeData.page,
      totalPage: activeData.totalPage,
      search: activeData.search,
      limit: v,
    }
    getAllSessions(data)
  }

  //meta title
  document.title = props.t("evaluationList") + " | " + props.t("MedX")

  return (
    <div className="page-content">
      <div className="container-fluid">
        <Breadcrumbs
          title={props.t("internship")}
          breadcrumbItem={props.t("evaluationList")}
        />
        <Container fluid={true}>
          <Row>
            <Col lg={12}>
              <Card>
                <CardBody>
                  <Col sm={12}>
                    <DataTable
                      className="table-bordered"
                      progressPending={loading}
                      columns={columns}
                      data={sessions}
                      pagination
                      paginationServer
                      paginationTotalRows={activeData.totalPage}
                      paginationPerPage={activeData.limit}
                      defaultSortFieldID={1}
                      onChangeRowsPerPage={value => handleRowChange(value)}
                      onChangePage={value => handleChange(value)}
                      sortable
                      noHeader
                      defaultSortField="id"
                      defaultSortAsc={false}
                      highlightOnHover
                    />
                  </Col>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  )
}

export default withTranslation()(EvaluationList)
