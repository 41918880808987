// src/components/filter.
import React, { useMemo, useEffect,useState } from "react";
import PropTypes from 'prop-types';

//import components
import Breadcrumbs from '../../../components/Common/Breadcrumb';
import TableContainer from '../../../components/Common/TableContainer';
import DataTable from 'react-data-table-component';
import { RequestInstitutionApi } from "../../../apis/RequestInstitutionApi";
import { InstitutionApi } from "../../../apis/InstitutionApi";

import { useHistory } from "react-router-dom"
import * as Yup from "yup";
import { useFormik } from "formik";


import { Link } from "react-router-dom";
import {
    Card,
    Col,
    Container,
    Row,
    CardBody,
    CardTitle,
    Label,
    Button,
    Form,
    Input,
    InputGroup,
    TabContent,
  Table,
  TabPane,
  FormFeedback,

  } from "reactstrap";
import Swal from "sweetalert2";
import toastr from "toastr";
import "toastr/build/toastr.min.css";
import { withTranslation } from "react-i18next";

import i18n from "../../../i18n";
import { RequestSubInstitutionApi } from "apis/RequestSubInstitutionApi";
import { SubInstitutionApi } from "apis/SubInstitutionsApi";



function RequestInstitutions(props) {

    const history = useHistory();
    const request_institute_id = props.match.params.request_institute_id;
    const [institutionData, setInstitutionData] = useState({});
    const [status, setStatus] = useState("");
    const [comment, setComment] = useState("");
    const [errorMsg, setErrorMsg] = useState("");

    useEffect(() => {
        getRequestInstitution();
    }, []);

    const getRequestInstitution = () => {
        let data = {requst_sub_institute_id:request_institute_id};
        SubInstitutionApi.getAllSubInstitutesDtails(data)
          .then((res) => {
            //console.log(res.data, "aaa");
            setStatus(res.data.requestSubInstitute.status)
            setInstitutionData(res.data.requestSubInstitute);
          })
          .catch((err) => {
            console.log(err);
          });
    };

    const formik = useFormik({
      // enableReinitialize : use this flag when initial values needs to be changed
      enableReinitialize: true,
  
      initialValues: {
        comment: institutionData.comment || '', 
        status: status || ''
      },
      validationSchema: Yup.object({
        status: Yup.string().required(props.t("Select_Status")),
        comment: Yup.string().required(props.t("Required-Message")), 
      }),
      onSubmit: (values) => {
        //values.request_sub_institute_id =  request_institute_id;
        const payload = {
          request_sub_institute_id: request_institute_id,
          status: status,
          comment: values.comment
        }
         SubInstitutionApi.createSubInstitute(payload)
          .then(res => {
              console.log(res.data)
            if (res.data.success) {
                      Swal.fire({
                        text: res.data.message,
                        icon: 'success',
                        imageAlt: 'success image',
                        // confirmButtonColor: '#00CA84'
                      }).then((result) => {
                        if (result.isConfirmed) {
                          history.push('/sub-institutions');
                        }
                      });
              } else {
                  toastr.error(res.data.message, '');
              }
          }).catch(function (error) {
          toastr.error(error.response.data.message, '');
          });
      }
    });

    // const onSubmitSubRequestInstituteHandler = async () => {
    //   if(status === "0"){
    //     setErrorMsg("Select_Status");
    //     return true;
    //   }
    //   if(comment === " "){
    //     setCommentErrorMsg("Select_Status");
    //     return true;
    //   }
    //   const payload = {
    //     request_sub_institute_id: request_institute_id,
    //     status: status,
    //     comment: comment
    //   }
    //    SubInstitutionApi.createSubInstitute(payload)
    //     .then(res => {
    //         console.log(res.data)
    //       if (res.data.success) {
    //                 Swal.fire({
    //                   text: res.data.message,
    //                   icon: 'success',
    //                   imageAlt: 'success image',
    //                   // confirmButtonColor: '#00CA84'
    //                 }).then((result) => {
    //                   if (result.isConfirmed) {
    //                     history.push('/sub-institutions');
    //                   }
    //                 });
    //         } else {
    //             toastr.error(res.data.message, '');
    //         }
    //     }).catch(function (error) {
    //     toastr.error(error.response.data.message, '');
    //     });
    // }
  
    //meta title
    document.title = props.t("Req_Institution_Details")+' | '+props.t("MedX")
    

    return (
        <div className="page-content">
            <div className="container-fluid">
            <Breadcrumbs title={props.t("Req_Institution")} breadcrumbItem={props.t("Req_Institution_Details")} />
            </div>

            

            <div className="container-fluid">

            <Row>
            <Col lg={6}>
              <Card>
                <CardBody>
                <div className="table-responsive">
                        <Table className="table mb-0 table-bordered">
                          <tbody>
                            
                                <tr>
                                  <th scope="row" className={"text-capitalize"}>{props.t("Sub_Institution_Name")}</th>
                                  <td>{institutionData.institution_name ? institutionData.institution_name : "-"}</td>
                                </tr>

                                <tr>
                                  <th scope="row" className={"text-capitalize"}>{props.t("Affiliation")}</th>
                                  <td>{institutionData.affiliation ? institutionData.affiliation : "-"}</td>
                                </tr>


                                <tr>
                                  <th scope="row" className={"text-capitalize"}>{props.t("Province")}</th>
                                  <td>
                                  {
                                    i18n.language == 'en' ? (
                                      institutionData.OfficerProvince?.name_en
                                    ) : ( institutionData.OfficerProvince?.name_th)
                                  }
                                  </td>
                                </tr>

                                <tr>
                                  <th scope="row" className={"text-capitalize"}>{props.t("District")}</th>
                                  <td>
                                  {
                                    i18n.language == 'en' ? (
                                      institutionData.OfficerDistrict?.name_en
                                    ) : ( institutionData.OfficerDistrict?.name_th)
                                  }
                                  </td>
                                </tr>

                                <tr>
                                  <th scope="row" className={"text-capitalize"}>{props.t("Sub_District")}</th>
                                  <td>
                                  {
                                    i18n.language == 'en' ? (
                                      institutionData.OfficerSubDistrict?.name_en
                                    ) : ( institutionData.OfficerSubDistrict?.name_th)
                                  }
                                  </td>
                                </tr>

                                <tr>
                                  <th scope="row" className={"text-capitalize"}>{props.t("Post_Code")}</th>
                                  <td>{institutionData.contact_post_code ? institutionData.contact_post_code : "-"}</td>
                                </tr>
 
                                <tr>
                                  <th scope="row" className={"text-capitalize"}>{props.t("Name_Title")}</th>
                                  <td>{institutionData.contact_name_title ? institutionData.contact_name_title : "-"}</td>
                                </tr>

                                <tr>
                                  <th scope="row" className={"text-capitalize"}>{props.t("First_Name")}</th>
                                  <td>{institutionData.contact_first_name ? institutionData.contact_first_name : "-"}</td>
                                </tr>

                                <tr>
                                  <th scope="row" className={"text-capitalize"}>{props.t("Last_Name")}</th>
                                  <td>{institutionData.contact_last_name ? institutionData.contact_last_name : "-"}</td>
                                </tr>

                                <tr>
                                  <th scope="row" className={"text-capitalize"}>{props.t("Phone")}</th>
                                  <td>{institutionData.contact_phone ? institutionData.contact_phone : "-"}</td>
                                </tr>

                                <tr>
                                  <th scope="row" className={"text-capitalize"}>{props.t("Email")}</th>
                                  <td>{institutionData.contact_email ? institutionData.contact_email : "-"}</td>
                                </tr>

                                <tr>
                                  <th scope="row" className={"text-capitalize"}>{props.t("Website")}</th>
                                  <td>{institutionData.contact_website ? institutionData.contact_website : "-"}</td>
                                </tr>

                                 <tr>
                                  <th scope="row" className={"text-capitalize"}>{props.t("Organization")}</th>
                                  <td>{institutionData.OrganizationType ? institutionData.OrganizationType.name_en : "-"}</td>
                                </tr>

                                <tr>
                                  <th scope="row" className={"text-capitalize"}>{props.t("Established_Yr")}</th>
                                  <td>{institutionData.established_year ? institutionData.established_year : "-"}</td>
                                </tr>

                                <tr>
                                  <th scope="row" className={"text-capitalize"}>{props.t("No_of_Members")}</th>
                                  <td>{institutionData.number_of_members ? institutionData.number_of_members : "-"}</td>
                                </tr>

                                <tr>
                                  <th scope="row" className={"text-capitalize"}>{props.t("Education")}</th>
                                  <td>{institutionData.EducationType ? institutionData.EducationType.name_en : "-"}</td>
                                </tr>

                               
                                <tr>
                                  <th scope="row" className={"text-capitalize"}>{props.t("Education_No_of_Med")}</th>
                                  <td>{institutionData.education_number_of_medical_with ? institutionData.education_number_of_medical_with : "-"}</td>
                                </tr>

                                <tr>
                                  <th scope="row" className={"text-capitalize"}>{props.t("Education_No_of_Beds")}</th>
                                  <td>{institutionData.education_number_of_beds ? institutionData.education_number_of_beds : "-"}</td>
                                </tr>
                            
                                <tr>
                                  <th scope="row" className={"text-capitalize"}>{props.t("Service")}</th>
                                  <td>{institutionData.ServiceType ? institutionData.ServiceType.name_en : "-"}</td>
                                </tr>
                                <tr>
                                  <th scope="row" className={"text-capitalize"}>{props.t("Status")}</th>
                                  <td>{institutionData.status ? institutionData.status : "-"}</td>
                                </tr>

                                <tr>
                                  <th scope="row" className={"text-capitalize"}>{props.t("Institution_Name")}</th>
                                  <td>{institutionData.Institute ? institutionData.Institute.institution_name : "-"}</td>
                                </tr>

                                <tr>
                                  <th scope="row" className={"text-capitalize"}>{props.t("Code")}</th>
                                  <td>{institutionData.Institute ? institutionData.Institute.new_code : "-"}</td>
                                </tr>
                                
                             
                          </tbody>
                        </Table>
                      </div>
                </CardBody>
              </Card>
            </Col>

            <Col lg={6}>
              <Card>
                <CardBody>
                   <div className="table-responsive">
                        <Table className="table mb-0 table-bordered">
                          <tbody>

                                

                                <tr>
                                  <th scope="row" className={"text-capitalize"}>{props.t("Service_No_of_Med")}</th>
                                  <td>{institutionData.service_number_of_medical_with ? institutionData.service_number_of_medical_with : "-"}</td>
                                </tr>

                                <tr>
                                  <th scope="row" className={"text-capitalize"}>{props.t("Service_No_of_beds")}</th>
                                  <td>{institutionData.service_number_of_beds ? institutionData.service_number_of_beds : "-"}</td>
                                </tr>

                                <tr>
                                  <th scope="row" className={"text-capitalize"}>{props.t("contact_Institution_Address")}</th>
                                  <td>{institutionData.contact_institution_address ? institutionData.contact_institution_address : "-"}</td>
                                </tr>


                                

                                <tr>
                                  <th scope="row" className={"text-capitalize"}>{props.t("Dr_Title")}</th>
                                  <td>{institutionData.doctor_name_title ? institutionData.doctor_name_title : "-"}</td>
                                </tr>


                                <tr>
                                  <th scope="row" className={"text-capitalize"}>{props.t("Dr_First_Name")}</th>
                                  <td>{institutionData.doctor_first_name ? institutionData.doctor_first_name : "-"}</td>
                                </tr>

                                <tr>
                                  <th scope="row" className={"text-capitalize"}>{props.t("Dr_Last_Name")}</th>
                                  <td>{institutionData.doctor_last_name ? institutionData.doctor_last_name : "-"}</td>
                                </tr>

                                <tr>
                                  <th scope="row" className={"text-capitalize"}>{props.t("License_No")}</th>
                                  <td>{institutionData.doctor_license_number ? institutionData.doctor_license_number : "-"}</td>
                                </tr>

                                <tr>
                                  <th scope="row" className={"text-capitalize"}>{props.t("Dr_Organization_Position")}</th>
                                  <td>{institutionData.doctor_organization_position ? institutionData.doctor_organization_position : "-"}</td>
                                </tr>

                                <tr>
                                  <th scope="row" className={"text-capitalize"}>{props.t("Dr_Provice")}</th>
                                  <td>
                                  {
                                    i18n.language == 'en' ? (
                                      institutionData.DoctorProvince?.name_en
                                    ) : ( institutionData.DoctorProvince?.name_th)
                                  }
                                  </td>
                                </tr>
                                <tr>
                                  <th scope="row" className={"text-capitalize"}>{props.t("Dr_District")}</th>
                                  <td>
                                  {
                                    i18n.language == 'en' ? (
                                      institutionData.DoctorDistrict?.name_en
                                    ) : ( institutionData.DoctorDistrict?.name_th)
                                  }
                                  </td>
                                </tr>
                                <tr>
                                  <th scope="row" className={"text-capitalize"}>{props.t("Dr_Sub_District")}</th>
                                  <td>
                                  {
                                    i18n.language == 'en' ? (
                                      institutionData.DoctorSubDistrict?.name_en
                                    ) : ( institutionData.DoctorSubDistrict?.name_th)
                                  }
                                  </td>
                                </tr>
                                <tr>
                                  <th scope="row" className={"text-capitalize"}>{props.t("Dr_Post_Code")}</th>
                                  <td>{institutionData.DoctorSubDistrict ? institutionData.doctor_post_code : "-"}</td>
                                </tr> 
                                <tr>
                                  <th scope="row" className={"text-capitalize"}>{props.t("Dr_Institution_Address")}</th>
                                  <td>{institutionData.doctor_institution_address ? institutionData.doctor_institution_address : "-"}</td>
                                </tr>
                                <tr>
                                  <th scope="row" className={"text-capitalize"}>{props.t("Dr_Phone")}</th>
                                  <td>{institutionData.doctor_phone ? institutionData.doctor_phone : "-"}</td>
                                </tr>
                                <tr>
                                  <th scope="row" className={"text-capitalize"}>{props.t("Dr_Email")}</th>
                                  <td>{institutionData.doctor_email ? institutionData.doctor_email : "-"}</td>
                                </tr>
                                <tr>
                                  <th scope="row" className={"text-capitalize"}>{props.t("Dr_Web")}</th>
                                  <td>{institutionData.doctor_website ? institutionData.doctor_website : "-"}</td>
                                </tr>
                                <tr>
                                  <th scope="row" className={"text-capitalize"}>{props.t("Workshop")}</th>
                                  <td>{institutionData.cme_workshop ? institutionData.cme_workshop : "-"}</td>
                                </tr>
                             
                          </tbody>
                        </Table>
                      </div>
                </CardBody>
              </Card>
            </Col>
          </Row>

          <Row>
            <Col lg={12}>
              <Card>
                <CardBody>
                  <CardTitle className="mb-4">{props.t("Update_Req_SubInstitution_Status")}</CardTitle>

                  <form
                  className="form-horizontal"
                  onSubmit={e => {
                    e.preventDefault()
                    formik.handleSubmit()
                    return false
                  }}
                 >
                  {/* <h1>

                   {institutionData.RequestInstituteDocument.request_institute_id ? institutionData.RequestInstituteDocument.request_institute_id : 'null'}
                  </h1> */}
                  
                    <Row>
                      <Col md={6}>
                        <div className="mb-3">
                          <Label htmlFor="formrow-email-Input">{props.t("Status")}</Label>
                          <select 
                            onChange={(e) => {setStatus(e.target.value)}}
                            value={status || ""}
                            // onBlur={validation.handleBlur}
                            // defaultValue={institutionData.status}
                            className="form-select">
                            <option value="0">{props.t("Select_Status")}</option>
                            <option value="APPROVED">{props.t("APPROVED")}</option>
                            <option value="PENDING">{props.t("PENDING")}</option>
                            <option value="REJECTED">{props.t("REJECTED")}</option>
                        </select>
                        {errorMsg !== "" && 
                        <div style={{ color: '#f46a6a', fontSize: '11px', marginTop: '3px' }}>
                          {errorMsg}
                        </div>
                        }
                        </div>
                      </Col>
                    </Row>

                    <Row>
                      <Col md={6}>
                        <div className="mb-3">
                          <Label htmlFor="formrow-email-Input">{props.t("Comment")}</Label>
                          <Input
                          name="comment"
                          type="textarea"
                          className="form-control"
                          id="horizontal-firstname-Input"
                          placeholder={props.t("Comment")}
                          maxLength="225"
                          rows="3"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.comment}
                          // value={comment || ""}
                          invalid={
                            formik.touched.comment &&
                              formik.errors.comment
                              ? true
                              : false
                          }
                        
                         />

                        {formik.touched.comment &&
                        formik.errors.comment ? (
                        <div
                        style={{
                        color: "#f46a6a",
                        fontSize: "11px",
                        marginTop: "3px",
                        }}
                        >
                        {formik.errors.comment}
                        </div>
                        ) : null}
                        </div>
                      </Col>
                    </Row>

                    <div>
                    {/* onClick={onSubmitSubRequestInstituteHandler}  */}
                      <button type="submit" className="btn btn-primary w-md">
                        {props.t("Submit")}
                      </button>
                    </div>
                  </form>
                </CardBody>
              </Card>
            </Col>

            
          </Row>


                   
            </div>
        </div>
    );
}
RequestInstitutions.propTypes = {
  t: PropTypes.any,
  preGlobalFilteredRows: PropTypes.any,
};

export default withTranslation()(RequestInstitutions);