import config from "../config/config"
import axios from "axios"
import { getToken } from "../Token"
import * as url from "../helpers/url_helper"

const token = getToken()
export const InstitutionApi = {
  createInstitute: async data => {
    var axiosConfig = {
      method: "post",
      url: `${config.API_URL}${url.POST_CREATE_INSTITUTIONS}`,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      data: data,
    }

    return await axios(axiosConfig)
  },
  getInstitutionsByName: async data => {
    var axiosConfig = {
      method: "post",
      url: `${config.API_URL}${url.POST_FETCH_ALL_INSTITUTION_NAME}`,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      data: data,
    }

    return await axios(axiosConfig)
  },
  getAllInstitutes: async data => {
    var axiosConfig = {
      method: "post",
      url: `${config.API_URL}${url.POST_FETCH_ALL_INSTITUTIONS}`,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      data: data,
    }

    return await axios(axiosConfig)
  },
  getAllInstituteWithoutPaginategetAllInstitutes: async data => {
    var axiosConfig = {
      method: "post",
      url: `${config.API_URL}${url.POST_FETCH_ALL_INSTITUTIONS}`,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      data: data,
    }

    return await axios(axiosConfig)
  },
  getAllInstituteWithoutPaginate: async data => {
    var axiosConfig = {
      method: "post",
      url: `${config.API_URL}${url.POST_FETCH_ALL_INSTITUTION_ALL}`,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      data: data,
    }

    return await axios(axiosConfig)
  },
  getAllInstitutesApi: async data => {
    const axiosConfig = {
      method: "post",
      url: `${config.API_URL}/api/common/getAllInstitutesPaginate`,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    }
    return await axios(axiosConfig)
  },
}
