// src/components/filter.
import React, { useEffect, useState } from "react";
import PropTypes from 'prop-types';

//import components
import Breadcrumbs from '../../../components/Common/Breadcrumb';
import DataTable from 'react-data-table-component';
import { OfferTypeApi } from "../../../apis/OfferTypeApi";
import { useHistory } from "react-router-dom"
import { withTranslation } from "react-i18next";
import * as Yup from "yup";
import { useFormik } from "formik";
import toastr from "toastr";
import config from "config/config";
import constants from "../../../config/constants";

import {
    Container,
    Row,
    Col,
    Card,
    CardBody,
    Form,
    Label,
    Input,
    FormFeedback,
    Button
} from "reactstrap";

import Swal from "sweetalert2";

function OfferTypes(props) {
    const history = useHistory();
    const [offerTypeList, setOfferTypeList] = useState([]);
    const [fetch_offer_type, setFetchOfferType] = useState([]);
    const [post_offer_type_id, setPostOfferTypeId] = useState('');
    const [loading, setLoading] = useState(false);
    const [limit, setCurrentLimit] = useState(config.LIMIT);
    const [activeData, setActiveData] = useState({
        page: 1,
        totalPage: 1,
        search: "",
        limit: config.LIMIT
    });
    const [page, setCurrentPage] = useState(1);

    useEffect(() => {
        getAllOfferTypes(activeData);
    }, [page,limit]);

    const formClear = () => {
        validationType.resetForm();
        setFetchOfferType();
    };

    const columns = [
        {
            name: props.t("Sr_No"),
            selector: row => row.offer_type_id,
            sort: "asc",
            width: 150,
            sortable: true,
            defaultSortField: true,
            defaultSortAsc: false
        },
        {
            name: props.t("Name_Eng"),
            selector: row => row.name_en,
            sort: "asc",
            width: 270,
            sortable: true,
            defaultSortField: true,
            defaultSortAsc: false
        },
        {
            name: props.t("Name_Thai"),
            selector: row => row.name_th,
            sort: "asc",
            width: 270,
            sortable: true,
            defaultSortField: true,
            defaultSortAsc: false
        },
        {
            name: props.t("Status"),
            selector: row => row.status,
            sort: "asc",
            width: 270,
            sortable: true,
            defaultSortField: true,
            defaultSortAsc: false
        },
        {
            name: props.t("Action"),
            selector: row => row.action,
            sort: "asc",
            width: 200,
        }
    ];

    const getAllOfferTypes = (data) => {
        setLoading(true);
        OfferTypeApi.getAllOfferTypes(data)
            .then((res) => {
                setActiveData({
                    page: activeData.page,
                    totalPage: res?.data?.data?.count,
                    search: activeData.search,
                    limit: activeData.limit,
                });
                let data = [];
                var sr_no = res.data.sr_no_start;

                res?.data?.data?.rows?.forEach((v, i) => {
                    sr_no = sr_no + 1;
                    data[i] = {
                        offer_type_id: sr_no,
                        name_en: v.name_en,
                        name_th: v.name_th,
                        status: v.status == 1 ? (
                            <>
                                <span style={{ color: "green" }}>{props.t("Active")}</span>
                            </>
                        ) : (
                            <>
                                <span style={{ color: "red" }}>{props.t("Deactive")}</span>
                            </>
                        ),
                        action: (
                            <>

                                <i className="fas fa-edit" id="deletetooltip" style={{ color: "blue" }} onClick={() => onEdit(v.id)} />{" "}
                                <i className="mdi mdi-delete font-size-18" style={{ color: "red" }} id="deletetooltip" onClick={() => onDelete(v.id)} />
                            </>
                        ),
                    };
                });
                setOfferTypeList(data);
                setLoading(false);
            })
            .catch((err) => {
                console.log(err);
            });
    };


    const onDelete = (id) => {

        Swal.fire({
            title: props.t("Are_you_sure"),
            text: props.t("Able_to_revert_this"),
            icon: props.t("Warning"),
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#f46a6a",
            confirmButtonText: props.t("Yes_delete_it"),
            cancelButtonText: props.t("Cancel"),
        }).then((result) => {
            if (result.isConfirmed) {
                let offerId = { "offer_type_id": id };
                OfferTypeApi.deleteOfferType(offerId)
                    .then((res) => {
                        Swal.fire(props.t("Deleted"), res.data.message, props.t("Success"));
                        setPostOfferTypeId();
                        formClear();
                        handleChange(page);
                        //getAllOfferTypes(activeData)
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            }
        });
    };

    const onEdit = (id) => {
        let offerId = { "offer_type_id": id };
        setPostOfferTypeId(id);
        OfferTypeApi.getOfferTypeById(offerId)
            .then((res) => {
                setFetchOfferType(res.data.data);
            })
            .catch((err) => {
                console.log(err);
            });
    };
    // Form validation 
    const validationType = useFormik({
        enableReinitialize: true,
        initialValues: {
            offer_type_id: fetch_offer_type ? fetch_offer_type.id : "",
            name_en: fetch_offer_type ? fetch_offer_type.name_en : "",
            name_th: fetch_offer_type ? fetch_offer_type.name_th : "",
            bg_color_code: fetch_offer_type ? fetch_offer_type.bg_color_code : "",
            status: fetch_offer_type?.status === 1 ? "1" : fetch_offer_type?.status === 0 ? "0" : "1"
        },
        validationSchema: Yup.object().shape({
            name_en: Yup.string().required(
                props.t("Required-Message")
            ),
            name_th: Yup.string().required(
                props.t("Required-Message")
            ),
            bg_color_code: Yup.string().required(
                props.t("Required-Message")
            ),
            status: Yup.string().required(
                props.t("Required-Message")
            )
        }),
        onSubmit: (values) => {
            if (post_offer_type_id) {
                values.offer_type_id = post_offer_type_id;
                OfferTypeApi.updateOfferType(values)
                    .then(res => {
                        if (res.data.success) {
                            Swal.fire({
                                text: res.data.message,
                                icon: 'success',
                                imageAlt: 'success image',
                            }).then((result) => {
                                if (result.isConfirmed) {
                                    history.push('/offer-types');
                                    setPostOfferTypeId();
                                    formClear()
                                    getAllOfferTypes(activeData)
                                }
                            });
                        } else {
                            toastr.error(res.message, '');
                        }
                    }).catch(function (error) {
                        if (error?.response?.data?.message) {
                            toastr.error(error?.response?.data?.message, '');
                        }
                        else {
                            toastr.error(error?.response?.data?.error?.errors?.map((item, v) => `${item.msg}<br>`), '');
                        }
                    });
            }
            else {
                OfferTypeApi.createOfferType(values)
                    .then(res => {
                        if (res.data.success) {
                            Swal.fire({
                                text: res.data.message,
                                icon: 'success',
                                imageAlt: 'success image',
                            }).then((result) => {
                                if (result?.isConfirmed) {
                                    history.push('/offer-types');
                                    formClear()
                                    getAllOfferTypes(activeData)


                                }
                            });
                        } else {
                            toastr.error(res?.data?.message, '');
                        }
                    }).catch(function (error) {

                        if (error?.response?.data?.message) {
                            toastr.error(error?.response?.data?.message, '');
                        }
                        else {
                            toastr.error(error?.response?.data?.error?.errors?.map((item, v) => `${item.msg}<br>`), '');
                        }
                    });
            }

        }
    });

    const handleChange = (v) => {
        setCurrentPage(v);
        setActiveData({ page: v, totalPage: activeData.totalPage, search: activeData.search, limit: limit });
        const data = { page: v, totalPage: activeData.totalPage, search: activeData.search, limit: limit }
        getAllOfferTypes(data)
    }


    const handleRowChange = (v) => {
        setCurrentLimit(v);
        setActiveData({ page: activeData.page, totalPage: activeData.totalPage, search: activeData.search, limit: v });
        const data = { page: activeData.page, totalPage: activeData.totalPage, search: activeData.search, limit: v }
        getAllOfferTypes(data)
    }


    //meta title
    document.title = props.t("Offer_Types") + ' | ' + props.t("MedX");

    return (
        <div className="page-content">
            <div className="container-fluid">
                <Breadcrumbs title={props.t("Master")} breadcrumbItem={props.t("Offer_Types")} />
            </div>
            <Container fluid={false}>
                <Row>
                    <Col lg={8}>
                        <Card>
                            <CardBody >
                                <Col sm={12}>
                                    <DataTable
                                        className="table-bordered"
                                        progressPending={loading}
                                        columns={columns}
                                        data={offerTypeList}
                                        pagination
                                        paginationServer
                                        paginationTotalRows={activeData.totalPage}
                                        paginationPerPage={activeData.limit}
                                        defaultSortFieldID={1}
                                        onChangeRowsPerPage={value => handleRowChange(value)}
                                        onChangePage={value => handleChange(value)}
                                        sortable
                                        noHeader
                                        defaultSortField="id"
                                        defaultSortAsc={false}
                                        highlightOnHover
                                    />
                                </Col>

                            </CardBody>
                        </Card>
                    </Col>
                    <Col lg={4}>
                        <Card>
                            <CardBody >
                                <Col sm={12}>
                                    <Form
                                        onSubmit={(e) => {
                                            e.preventDefault();
                                            validationType.handleSubmit();
                                            return false;
                                        }}>
                                        <Col sm={12}>
                                            <div className="mb-3">
                                                <Label className="form-label">{props.t("Name_Eng")}</Label>
                                                <Input
                                                    name="name_en"
                                                    placeholder={props.t("Name_Eng")}
                                                    type="text"
                                                    onChange={validationType.handleChange}
                                                    onBlur={validationType.handleBlur}
                                                    value={validationType.values.name_en || ""}
                                                    invalid={
                                                        validationType.touched.name_en && validationType.errors.name_en ? true : false
                                                    }
                                                />
                                                {validationType.touched.name_en && validationType.errors.name_en ? (
                                                    <FormFeedback type="invalid">{validationType.errors.name_en}</FormFeedback>
                                                ) : null}
                                            </div>
                                        </Col>
                                        <Col sm={12}>
                                            <div className="mb-3">
                                                <Label className="form-label">{props.t("Name_Thai")}</Label>
                                                <Input
                                                    name="name_th"
                                                    placeholder={props.t("Name_Thai")}
                                                    type="text"
                                                    onChange={validationType.handleChange}
                                                    onBlur={validationType.handleBlur}
                                                    value={validationType.values.name_th || ""}
                                                    invalid={
                                                        validationType.touched.name_th && validationType.errors.name_th ? true : false
                                                    }
                                                />
                                                {validationType.touched.name_th && validationType.errors.name_th ? (
                                                    <FormFeedback type="invalid">{validationType.errors.name_th}</FormFeedback>
                                                ) : null}
                                            </div>
                                        </Col>
                                        <Col sm={12}>
                                            <div className="mb-3">
                                                <Label className="form-label">{props.t("Back_Ground_Color_Code")}</Label>
                                                <Input
                                                    name="bg_color_code"
                                                    placeholder={props.t("Back_Ground_Color_Code")}
                                                    type="text"
                                                    onChange={validationType.handleChange}
                                                    onBlur={validationType.handleBlur}
                                                    value={validationType.values.bg_color_code || ""}
                                                    invalid={
                                                        validationType.touched.bg_color_code && validationType.errors.bg_color_code ? true : false
                                                    }
                                                />
                                                {validationType.touched.bg_color_code && validationType.errors.bg_color_code ? (
                                                    <FormFeedback type="invalid">{validationType.errors.bg_color_code}</FormFeedback>
                                                ) : null}
                                            </div>
                                        </Col>

                                        <Col sm={12}>
                                            <div className="mb-3">
                                                <Label className="form-label">{props.t("Status")}</Label>

                                                <select
                                                    name="status"
                                                    type="select"
                                                    className="form-control"
                                                    onChange={validationType.handleChange}
                                                    onBlur={validationType.handleBlur}
                                                    value={validationType.values.status || ""}

                                                >

                                                    {
                                                        Object.keys(constants.STATUS).map(key => {
                                                            return (
                                                                <option value={key} key={key}>{constants.STATUS[key]}</option>
                                                            )
                                                        })
                                                    }

                                                </select>
                                                {validationType.touched.status && validationType.errors.status ? (
                                                    <div style={{ color: '#f46a6a', fontSize: '11px', marginTop: '3px' }}>
                                                        {validationType.errors.status}
                                                    </div>
                                                ) : null}
                                            </div>
                                        </Col>


                                        <div className="d-flex flex-wrap gap-2">
                                            <Button type="submit" color="primary" >
                                                {props.t("Submit")}
                                            </Button>{" "}
                                            <Button color="secondary" onClick={e => formClear()}>
                                                {props.t("Clear")}
                                            </Button>
                                        </div>
                                    </Form>
                                </Col>

                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </div>
    );
}
OfferTypes.propTypes = {
    t: PropTypes.any,
    preGlobalFilteredRows: PropTypes.any,

};
export default withTranslation()(OfferTypes);