// src/components/filter.
import React, { useEffect, useState } from "react";
import PropTypes from 'prop-types';

//import components
import Breadcrumbs from '../../../components/Common/Breadcrumb';
import DataTable from 'react-data-table-component';
import { CategoryApi } from "../../../apis/CategoryApi";
import { useHistory } from "react-router-dom"
import { withTranslation } from "react-i18next";
import * as Yup from "yup";
import { useFormik } from "formik";
import toastr from "toastr";
import config from "config/config";
import constants from "../../../config/constants";

import {
    Container,
    Row,
    Col,
    Card,
    CardBody,
    Form,
    Label,
    Input,
    FormFeedback,
    Button
} from "reactstrap";

import Swal from "sweetalert2";

function Category(props) {
    const history = useHistory();
    const [serviceTypeList, setCategoryList] = useState([]);
    const [fetchCategory, setFetchCategory] = useState([]);
    const [postCategoryId, setCategoryId] = useState('');
    const [loading, setLoading] = useState(false);

    const [activeData, setActiveData] = useState({
        page: 1,
        totalPage: 1,
        search: "",
        limit: config.LIMIT
    });
    const [page, setCurrentPage] = useState(1);
    const [limit, setCurrentLimit] = useState(config.LIMIT);

    useEffect(() => {
        getAllCategory(activeData);
    }, [page,limit]);

    const formClear = () => {
        validationType.resetForm();
        setFetchCategory();
    };

    const columns = [
        {
            name: props.t("Sr_No"),
            selector: row => row.category_id,
            sort: "asc",
            width: 150,
            sortable: true,
            defaultSortField: true,
            defaultSortAsc: false
        },
        {
            name: props.t("Name_Eng"),
            selector: row => row.name_en,
            sort: "asc",
            width: 270,
            sortable: true,
            defaultSortField: true,
            defaultSortAsc: false
        },
        {
            name: props.t("Name_Thai"),
            selector: row => row.name_th,
            sort: "asc",
            width: 270,
            sortable: true,
            defaultSortField: true,
            defaultSortAsc: false
        },
        {
            name: props.t("Status"),
            selector: row => row.status,
            sort: "asc",
            width: 270,
            sortable: true,
            defaultSortField: true,
            defaultSortAsc: false
        },
        {
            name: props.t("Action"),
            selector: row => row.action,
            sort: "asc",
            width: 200,
        }
    ];

    const getAllCategory = (data) => {
        setLoading(true);
        CategoryApi.getAllCategories(data)
            .then((res) => {
                setActiveData({
                    page: activeData.page,
                    totalPage: res?.data?.data?.count,
                    search: activeData.search,
                    limit: activeData.limit,
                });
                let data = [];
                var sr_no = res.data.sr_no_start;

                res?.data?.data?.rows?.forEach((v, i) => {
                    sr_no = sr_no + 1;
                    data[i] = {
                        category_id: sr_no,
                        name_en: v.name_en,
                        name_th: v.name_th,
                        status: v.status == 1 ? (
                            <>
                                <span style={{ color: "green" }}>{props.t("Active")}</span>
                            </>
                        ) : (
                            <>
                                <span style={{ color: "red" }}>{props.t("Deactive")}</span>
                            </>
                        ),
                        action: (
                            <>

                                <i className="fas fa-edit" id="deletetooltip" style={{ color: "blue" }} onClick={() => onEdit(v.id)} />{" "}
                                <i className="mdi mdi-delete font-size-18" style={{ color: "red" }} id="deletetooltip" onClick={() => onDelete(v.id)} />
                            </>
                        ),
                    };
                });
                setCategoryList(data);
                setLoading(false);
            })
            .catch((err) => {
                console.log(err);
            });
    };


    const onDelete = (id) => {

        Swal.fire({
            title: props.t("Are_you_sure"),
            text: props.t("Able_to_revert_this"),
            icon: props.t("Warning"),
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#f46a6a",
            confirmButtonText: props.t("Yes_delete_it"),
            cancelButtonText: props.t("Cancel"),
        }).then((result) => {
            if (result.isConfirmed) {
                let CategoryId = { "category_id": id };
                CategoryApi.deleteCategory(CategoryId)
                    .then((res) => {
                        Swal.fire(props.t("Deleted"), res.data.message, props.t("Success"));
                        setCategoryId();
                        formClear();
                        handleChange(page);
                        //getAllCategory(activeData)
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            }
        });
    };

    const onEdit = (id) => {
        let CategoryId = { "category_id": id };
        setCategoryId(id);
        CategoryApi.getCategoryById(CategoryId)
            .then((res) => {
                setFetchCategory(res.data.data);
            })
            .catch((err) => {
                console.log(err);
            });
    };
    // Form validation 
    const validationType = useFormik({
        enableReinitialize: true,
        initialValues: {
            category_id: fetchCategory ? fetchCategory.id : "",
            name_en: fetchCategory ? fetchCategory.name_en : "",
            name_th: fetchCategory ? fetchCategory.name_th : "",
            status: fetchCategory?.status === 1 ? "1" : fetchCategory?.status === 0 ? "0" : "1",
            parent_type: fetchCategory ? fetchCategory?.parent_type : "",
            // parent_type: fetchCategory?.parent_type === "community" ? "community" : fetchCategory?.parent_type === "offers" ? "offers" : "",
            type: fetchCategory ? fetchCategory?.type : ""
            // type: fetchCategory?.type === "INFOCUS" ? "INFOCUS" : fetchCategory?.type === "NEWS" ? "NEWS" : fetchCategory?.type === "D-CONNECT" ? "D-CONNECT" : ""
        },
        validationSchema: Yup.object().shape({
            name_en: Yup.string().required(
                props.t("Required-Message")
            ),
            name_th: Yup.string().required(
                props.t("Required-Message")
            ),
            parent_type: Yup.string().required(
                props.t("Required-Message")
            ),
            type: Yup.string().required(
                props.t("Required-Message")
            ),
            status: Yup.string().required(
                props.t("Required-Message")
            )
        }),
        onSubmit: (values) => {
            if (postCategoryId) {
                values.category_id = postCategoryId;
                CategoryApi.updateCategory(values)
                    .then(res => {
                        if (res.data.success) {
                            Swal.fire({
                                text: res.data.message,
                                icon: 'success',
                                imageAlt: 'success image',
                            }).then((result) => {
                                if (result.isConfirmed) {
                                    history.push('/categories');
                                    setCategoryId();
                                    formClear()
                                    getAllCategory(activeData)
                                }
                            });
                        } else {
                            toastr.error(res.message, '');
                        }
                    }).catch(function (error) {
                        if (error?.response?.data?.message) {
                            toastr.error(error?.response?.data?.message, '');
                        }
                        else {
                            toastr.error(error?.response?.data?.error?.errors?.map((item, v) => `${item.msg}<br>`), '');
                        }
                    });
            }
            else {
                CategoryApi.createCategory(values)
                    .then(res => {
                        if (res.data.success) {
                            Swal.fire({
                                text: res.data.message,
                                icon: 'success',
                                imageAlt: 'success image',
                            }).then((result) => {
                                if (result?.isConfirmed) {
                                    history.push('/categories');
                                    formClear()
                                    getAllCategory(activeData)


                                }
                            });
                        } else {
                            toastr.error(res?.data?.message, '');
                        }
                    }).catch(function (error) {

                        if (error?.response?.data?.message) {
                            toastr.error(error?.response?.data?.message, '');
                        }
                        else {
                            toastr.error(error?.response?.data?.error?.errors?.map((item, v) => `${item.msg}<br>`), '');
                        }
                    });
            }

        }
    });

    const handleChange = (v) => {
        setCurrentPage(v);
        setActiveData({ page: v, totalPage: activeData.totalPage, search: activeData.search, limit: limit });
        const data = { page: v, totalPage: activeData.totalPage, search: activeData.search, limit: limit }
        getAllCategory(data)
    }


    const handleRowChange = (v) => {
        setCurrentLimit(v);
        setActiveData({ page: activeData.page, totalPage: activeData.totalPage, search: activeData.search, limit: v });
        const data = { page: activeData.page, totalPage: activeData.totalPage, search: activeData.search, limit: v }
        getAllCategory(data)
    }


    //meta title
    document.title = props.t("Category") + ' | ' + props.t("MedX");

    return (
        <div className="page-content">
            <div className="container-fluid">
                <Breadcrumbs title={props.t("Master")} breadcrumbItem={props.t("Category")} />
            </div>
            <Container fluid={false}>
                <Row>
                    <Col lg={8}>
                        <Card>
                            <CardBody >
                                <Col sm={12}>
                                    <DataTable
                                        className="table-bordered"
                                        progressPending={loading}
                                        columns={columns}
                                        data={serviceTypeList}
                                        pagination
                                        paginationServer
                                        paginationTotalRows={activeData.totalPage}
                                        paginationPerPage={activeData.limit}
                                        defaultSortFieldID={1}
                                        onChangeRowsPerPage={value => handleRowChange(value)}
                                        onChangePage={value => handleChange(value)}
                                        sortable
                                        noHeader
                                        defaultSortField="id"
                                        defaultSortAsc={false}
                                        highlightOnHover
                                    />
                                </Col>

                            </CardBody>
                        </Card>
                    </Col>
                    <Col lg={4}>
                        <Card>
                            <CardBody >
                                <Col sm={12}>
                                    <Form
                                        onSubmit={(e) => {
                                            e.preventDefault();
                                            validationType.handleSubmit();
                                            return false;
                                        }}>
                                        <Col sm={12}>
                                            <div className="mb-3">
                                                <Label className="form-label">{props.t("Name_Eng")}</Label>
                                                <Input
                                                    name="name_en"
                                                    placeholder={props.t("Name_Eng")}
                                                    type="text"
                                                    onChange={validationType.handleChange}
                                                    onBlur={validationType.handleBlur}
                                                    value={validationType.values.name_en || ""}
                                                    invalid={
                                                        validationType.touched.name_en && validationType.errors.name_en ? true : false
                                                    }
                                                />
                                                {validationType.touched.name_en && validationType.errors.name_en ? (
                                                    <FormFeedback type="invalid">{validationType.errors.name_en}</FormFeedback>
                                                ) : null}
                                            </div>
                                        </Col>
                                        <Col sm={12}>
                                            <div className="mb-3">
                                                <Label className="form-label">{props.t("Name_Thai")}</Label>
                                                <Input
                                                    name="name_th"
                                                    placeholder={props.t("Name_Thai")}
                                                    type="text"
                                                    onChange={validationType.handleChange}
                                                    onBlur={validationType.handleBlur}
                                                    value={validationType.values.name_th || ""}
                                                    invalid={
                                                        validationType.touched.name_th && validationType.errors.name_th ? true : false
                                                    }
                                                />
                                                {validationType.touched.name_th && validationType.errors.name_th ? (
                                                    <FormFeedback type="invalid">{validationType.errors.name_th}</FormFeedback>
                                                ) : null}
                                            </div>
                                        </Col>

                                        <Col sm={12}>
                                            <div className="mb-3">
                                                <Label className="form-label">{props.t("Parent_Type")}</Label>

                                                <select
                                                    name="parent_type"
                                                    type="select"
                                                    className="form-control"
                                                    onChange={validationType.handleChange}
                                                    onBlur={validationType.handleBlur}
                                                    value={validationType.values.parent_type || ""}

                                                >
                                                    <option value="">{props.t("Please_Select_Parent_Type")}</option>
                                                    {
                                                        Object.keys(constants.CATEGORY_PARENT_TYPE).map(key => {
                                                            return (
                                                                <option value={key} key={key}>{constants.CATEGORY_PARENT_TYPE[key]}</option>
                                                            )
                                                        })
                                                    }

                                                </select>
                                                {validationType.touched.parent_type && validationType.errors.parent_type ? (
                                                    <div style={{ color: '#f46a6a', fontSize: '11px', marginTop: '3px' }}>
                                                        {validationType.errors.parent_type}
                                                    </div>
                                                ) : null}
                                            </div>
                                        </Col>

                                        <Col sm={12}>
                                            <div className="mb-3">
                                                <Label className="form-label">{props.t("Type")}</Label>

                                                <select
                                                    name="type"
                                                    type="select"
                                                    className="form-control"
                                                    onChange={validationType.handleChange}
                                                    onBlur={validationType.handleBlur}
                                                    value={validationType.values.type || ""}

                                                >
                                                    <option value="">{props.t("Please_Select_Type")}</option>
                                                    {
                                                        Object.keys(constants.CATEGORY_TYPE).map(key => {
                                                            return (
                                                                <option value={key} key={key}>{constants.CATEGORY_TYPE[key]}</option>
                                                            )
                                                        })
                                                    }

                                                </select>
                                                {validationType.touched.type && validationType.errors.type ? (
                                                    <div style={{ color: '#f46a6a', fontSize: '11px', marginTop: '3px' }}>
                                                        {validationType.errors.type}
                                                    </div>
                                                ) : null}
                                            </div>
                                        </Col>

                                        <Col sm={12}>
                                            <div className="mb-3">
                                                <Label className="form-label">{props.t("Status")}</Label>

                                                <select
                                                    name="status"
                                                    type="select"
                                                    className="form-control"
                                                    onChange={validationType.handleChange}
                                                    onBlur={validationType.handleBlur}
                                                    value={validationType.values.status || ""}

                                                >

                                                    {
                                                        Object.keys(constants.STATUS).map(key => {
                                                            return (
                                                                <option value={key} key={key}>{constants.STATUS[key]}</option>
                                                            )
                                                        })
                                                    }

                                                </select>
                                                {validationType.touched.status && validationType.errors.status ? (
                                                    <div style={{ color: '#f46a6a', fontSize: '11px', marginTop: '3px' }}>
                                                        {validationType.errors.status}
                                                    </div>
                                                ) : null}
                                            </div>
                                        </Col>


                                        <div className="d-flex flex-wrap gap-2">
                                            <Button type="submit" color="primary" >
                                                {props.t("Submit")}
                                            </Button>{" "}
                                            <Button color="secondary" onClick={e => formClear()}>
                                                {props.t("Clear")}
                                            </Button>
                                        </div>
                                    </Form>
                                </Col>

                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </div>
    );
}
Category.propTypes = {
    t: PropTypes.any,
    preGlobalFilteredRows: PropTypes.any,

};
export default withTranslation()(Category);