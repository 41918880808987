// src/components/filter.
import React, { useMemo, useEffect, useState } from "react";
import PropTypes from 'prop-types';

//import components
import Breadcrumbs from '../../components/Common/Breadcrumb';
// import TableContainer from '../../../components/Common/TableContainer';
// import DataTable from 'react-data-table-component';
import { RequestInstitutionApi } from "../../apis/RequestInstitutionApi";
import { InstitutionApi } from "../../apis/InstitutionApi";

import { useHistory } from "react-router-dom"
import * as Yup from "yup";
import { useFormik } from "formik";


import { Link } from "react-router-dom";
import {
  Card,
  Col,
  Container,
  Row,
  CardBody,
  CardTitle,
  Label,
  Button,
  Form,
  Input,
  InputGroup,
  TabContent,
  Table,
  TabPane,
  FormFeedback,

} from "reactstrap";
import Swal from "sweetalert2";
import toastr from "toastr";
import "toastr/build/toastr.min.css";
import { withTranslation } from "react-i18next";
import i18n from "../../i18n";


function ViewInstitutions(props) {

  const history = useHistory();
  const request_institute_id = props.match.params.request_institute_id;
  const [institutionData, setInstitutionData] = useState({});
  const [status, setStatus] = useState("");
  const [comment, setComment] = useState("");
  const [documents, setDocuments] = useState([])
  const [errorMsg, setErrorMsg] = useState("");
  const [docs, setDocs] = useState({})



  const getGetInstitutionView = () => {
    let data = { institute_id: request_institute_id };
    
    RequestInstitutionApi.getGetInstitutionView(data)
      .then((res) => {
        // console.log(res.data, "yahi h baha")
        // setStatus(res.data.requestinstitute.status)
        setInstitutionData(res.data.institute);
        const docs = res.data.institute.InstituteDocument;
        const groupByCategory = docs.reduce((group, product) => {
          const { document_type_id } = product;
          group[document_type_id] = group[document_type_id] ?? [];
          group[document_type_id].push(product);
          return group;
        }, {});
        setDocs(groupByCategory);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getGetInstitutionView();
  }, []);
  const formik = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      comment: institutionData.comment || '',
      status: institutionData.status || '',

    },
    validationSchema: Yup.object({
      status: Yup.string().required(props.t("Select_Status")),
    }),
    onSubmit: (values) => {
      // console.log("values", values);
      values.request_institute_id = request_institute_id;
    }
  });


  //meta title
  document.title = props.t("Institution_Details") + ' | ' + props.t("MedX")


  useEffect(() => {
    if (documents.length > 0)
      setStatus("REJECTED")

  }, [documents])


  return (
    <div className="page-content">
      <div className="container-fluid">
        <Breadcrumbs title={props.t("Institution")} breadcrumbItem={props.t("Institution_Details")} />
      </div>



      <div className="container-fluid">

        <Row>
          <Col lg={6}>
            <Card>
              <CardBody>
                <div className="table-responsive">
                        <Table className="table mb-0 table-bordered">
                          <tbody>
                            
                                <tr>
                                  <th scope="row" className={"text-capitalize"}>{props.t("Institution_Name")}</th>
                                  <td>{institutionData.institution_name ? institutionData.institution_name : "-"}</td>
                                </tr>

                                <tr>
                                  <th scope="row" className={"text-capitalize"}>{props.t("Affiliation")}</th>
                                  <td>{institutionData.affiliation ? institutionData.affiliation : "-"}</td>
                                </tr>


                                <tr>
                                  <th scope="row" className={"text-capitalize"}>{props.t("Institution_Address")}</th>
                                  <td>{institutionData.institution_address ? institutionData.institution_address : "-"}</td>
                                </tr>

                                <tr>
                                  <th scope="row" className={"text-capitalize"}>{props.t("Province")}</th>
                                  <td>
                                  {
                                    i18n.language == 'en' ? (
                                      institutionData.Province?.name_en
                                    ) : ( institutionData.Province?.name_th)
                                  }
                                  </td>
                                </tr>

                                <tr>
                                  <th scope="row" className={"text-capitalize"}>{props.t("District")}</th>
                                  <td>
                                  {
                                    i18n.language == 'en' ? (
                                      institutionData.District?.name_en
                                    ) : ( institutionData.District?.name_th)
                                  }
                                  </td>
                                </tr> 

                                <tr>
                                  <th scope="row" className={"text-capitalize"}>{props.t("Sub_District")}</th>
                                  <td>
                                  {
                                    i18n.language == 'en' ? (
                                      institutionData.SubDistrict?.name_en
                                    ) : ( institutionData.SubDistrict?.name_th)
                                  }
                                  </td>
                                </tr>

                                <tr>
                                  <th scope="row" className={"text-capitalize"}>{props.t("Post_Code")}</th>
                                  <td>{institutionData.post_code ? institutionData.post_code : "-"}</td>
                                </tr>

                                <tr>
                                  <th scope="row" className={"text-capitalize"}>{props.t("Phone")}</th>
                                  <td>{institutionData.phone ? institutionData.phone : "-"}</td>
                                </tr>

                                <tr>
                                  <th scope="row" className={"text-capitalize"}>{props.t("Email")}</th>
                                  <td>{institutionData.email ? institutionData.email : "-"}</td>
                                </tr>

                                <tr>
                                  <th scope="row" className={"text-capitalize"}>{props.t("Website")}</th>
                                  <td>{institutionData.website ? institutionData.website : "-"}</td>
                                </tr>

                                 <tr>
                                  <th scope="row" className={"text-capitalize"}>{props.t("Organization")}</th>
                                  <td>
                                  {
                                    i18n.language == 'en' ? (
                                      institutionData.OrganizationType?.name_en
                                    ) : ( institutionData.OrganizationType?.name_th)
                                  }
                                  </td>
                                </tr>

                                <tr>
                                <th scope="row" className={"text-capitalize"}>{props.t("Main_Organization")}</th>
                                <td>
                                  {
                                    i18n.language == 'en' ? (
                                      institutionData.MainOrganizationType?.name_en
                                    ) : ( institutionData.MainOrganizationType?.name_th)
                                  }
                                </td>
                                </tr>

                                <tr>
                                <th scope="row" className={"text-capitalize"}>{props.t("Old_Institution_Code")}</th>
                                <td>{institutionData.old_code ? institutionData.old_code : "-"}</td>
                                </tr>

                                <tr>
                                <th scope="row" className={"text-capitalize"}>{props.t("New_Institution_Code")}</th>
                                <td>{institutionData.new_code ? institutionData.new_code : "-"}</td>
                                </tr>

                                <tr>
                                  <th scope="row" className={"text-capitalize"}>{props.t("Established_Yr")}</th>
                                  <td>{institutionData.established_year ? institutionData.established_year : "-"}</td>
                                </tr>

                                <tr>
                                  <th scope="row" className={"text-capitalize"}>{props.t("No_of_Members")}</th>
                                  <td>{institutionData.number_of_members ? institutionData.number_of_members : "-"}</td>
                                </tr>

                                <tr>
                                  <th scope="row" className={"text-capitalize"}>{props.t("Education")}</th>
                                  <td>{institutionData.EducationType ? institutionData.EducationType.name_en : "-"}</td>
                                </tr>
                          </tbody>
                        </Table>
                      </div>
                </CardBody>
              </Card>
            </Col>

            <Col lg={6}>
              <Card>
                <CardBody>
                   <div className="table-responsive">
                        <Table className="table mb-0 table-bordered">
                          <tbody>
                              <tr>
                                <th scope="row" className={"text-capitalize"}>{props.t("Education_No_of_Med")}</th>
                                  <td>{institutionData.education_number_of_medical_with ? institutionData.education_number_of_medical_with : "-"}</td>
                                </tr>
                              <tr>
                                  <th scope="row" className={"text-capitalize"}>{props.t("Education_No_of_Beds")}</th>
                                  <td>{institutionData.education_number_of_beds ? institutionData.education_number_of_beds : "-"}</td>
                                </tr>
                                <tr>
                                  <th scope="row" className={"text-capitalize"}>{props.t("Service")}</th>
                                  <td>{institutionData.ServiceType ? institutionData.ServiceType.name_en : "-"}</td>
                                </tr>
                                <tr>
                                  <th scope="row" className={"text-capitalize"}>{props.t("Service_No_of_Med")}</th>
                                  <td>{institutionData.service_number_of_medical_with ? institutionData.service_number_of_medical_with : "-"}</td>
                                </tr>
                                <tr>
                                  <th scope="row" className={"text-capitalize"}>{props.t("Service_No_of_beds")}</th>
                                  <td>{institutionData.service_number_of_beds ? institutionData.service_number_of_beds : "-"}</td>
                                </tr>
                                <tr>
                                  <th scope="row" className={"text-capitalize"}>{props.t("Organization_Position")}</th>
                                  <td>{institutionData.organization_position ? institutionData.organization_position : "-"}</td>
                                </tr>
                                <tr>
                                  <th scope="row" className={"text-capitalize"}>{props.t("Contact_Web")}</th>
                                  <td>{institutionData.contact_website ? institutionData.contact_website : "-"}</td>
                                </tr>
                                <tr>
                                  <th scope="row" className={"text-capitalize"}>{props.t("Contact_Net_Affil")}</th>
                                  <td>{institutionData.network_with_affiliated_institutions ? institutionData.network_with_affiliated_institutions : "-"}</td>
                                </tr>

                                <tr>
                                  <th scope="row" className={"text-capitalize"}>{props.t("Affiliated_Number")}</th>
                                  <td>{institutionData.affiliated_number ? institutionData.affiliated_number : "-"}</td>
                                </tr>

                                <tr>
                                  <th scope="row" className={"text-capitalize"}>{props.t("First_Name")}</th>
                                  <td>{institutionData.InstituteUser ? institutionData.InstituteUser.first_name : "-"}</td>
                                </tr>

                                <tr>
                                  <th scope="row" className={"text-capitalize"}>{props.t("Last_Name")}</th>
                                  <td>{institutionData.InstituteUser ? institutionData.InstituteUser.first_name : "-"}</td>
                                </tr>

                                <tr>
                                  <th scope="row" className={"text-capitalize"}>{props.t("Email")}</th>
                                  <td>{institutionData.InstituteUser ? institutionData.InstituteUser.email : "-"}</td>
                                </tr>

                                <tr>
                                  <th scope="row" className={"text-capitalize"}>{props.t("Mobile")}</th>
                                  <td>{institutionData.InstituteUser ? institutionData.InstituteUser.mobile : "-"}</td>
                                </tr>


                          </tbody>

                           

                        </Table>
                      </div>
                </CardBody>
              </Card>
            </Col>
          </Row>



          <Row>
                            <Col lg={12}>
                              <Card>
                                <CardBody>
                                  <CardTitle className="mb-4">{props.t("Documents")}</CardTitle>

                                  <div className="table-responsive">
                                    <Table className="table mb-0 table-bordered">
                                      <tbody>

                                        {
                                          Object.keys(docs)?.length > 0 ?
                                            Object.keys(docs)?.map((item, index) => {
                                              return (
                                                <tr key={index}>
                                                  <th className={"text-capitalize"}>{docs[item]?.length > 0 ? docs[item][0]['DocumentType']['title_en'] : null}</th>
                                                  <td>
                                                    <div className="row px-5">

                                                      {docs[item]?.map((ditem, dindex) => {
                                                        return (
                                                          <a target={"_blank"} rel="noreferrer" className=" border rounded my-1 py-1" href={ditem['document_url']} key={dindex}>document {dindex + 1}</a>
                                                        )
                                                      })}

                                                    </div>

                                                  </td>
                                                  
                                                </tr>
                                              )
                                            })
                                            :
                                            props.t("No_Record_Found")
                                        }

                                      </tbody>
                                    </Table>
                                  </div>
                                </CardBody>
                              </Card>
                            </Col>
                          </Row>



      </div>
    </div >
  );
}
ViewInstitutions.propTypes = {
  t: PropTypes.any,
  preGlobalFilteredRows: PropTypes.any,
};

export default withTranslation()(ViewInstitutions);