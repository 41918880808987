// src/components/filter.
import React, { useMemo, useEffect,useState } from "react";
import PropTypes from 'prop-types';

//import components
import Breadcrumbs from '../../components/Common/Breadcrumb';
import TableContainer from '../../components/Common/TableContainer';
import DataTable from 'react-data-table-component';
import { RequestInstitutionApi } from "../../apis/RequestInstitutionApi";
import { InstitutionApi } from "../../apis/InstitutionApi";

import { useHistory } from "react-router-dom"
import * as Yup from "yup";
import { useFormik } from "formik";

import { Link } from "react-router-dom";
import {
    Card,
    Col,
    Container,
    Row,
    CardBody,
    CardTitle,
    Label,
    Button,
    Form,
    Input,
    InputGroup,
    TabContent,
  Table,
  TabPane,
  FormFeedback,

  } from "reactstrap";
import Swal from "sweetalert2";
import toastr from "toastr";
import "toastr/build/toastr.min.css";
import { withTranslation } from "react-i18next";

import { RequestSubInstitutionApi } from "apis/RequestSubInstitutionApi";
import i18n from "../../i18n";



function ViewSubInstitution(props) {

    const history = useHistory();
    const request_institute_id = props.match.params.request_institute_id;
    const [institutionData, setInstitutionData] = useState({});
    const [status, setStatus] = useState("");
    const [comment, setComment] = useState("");
    const [errorMsg, setErrorMsg] = useState("");

    useEffect(() => {
        getSubInstitutionDetails();
    }, []);

    const getSubInstitutionDetails = () => {
        let data = {sub_institute_id:request_institute_id};
        RequestSubInstitutionApi.getSubInstitutionDetails(data)
          .then((res) => {
            console.log(res.data, "opop")
            // setStatus(res.data.requestinstitute.status)
            setInstitutionData(res.data.subInstitute);
          })
          .catch((err) => {
            console.log(err);
          });
    };

    const validation = useFormik({
      // enableReinitialize : use this flag when initial values needs to be changed
      enableReinitialize: true,
  
      initialValues: {
        comment: institutionData.comment || '',
        status: institutionData.status || ''
      },
      validationSchema: Yup.object({
        status: Yup.string().required(props.t("Select_Status")),
      }),
      onSubmit: (values) => {
        console.log("values", values);
        values.request_institute_id =  request_institute_id;
        // InstitutionApi.createInstitute(values)
        // .then(res => {
        //   if (res.data.success) {
        //             Swal.fire({
        //               text: res.data.message,
        //               icon: 'success',
        //               imageAlt: 'success image',
        //               // confirmButtonColor: '#00CA84'
        //             }).then((result) => {
        //               if (result.isConfirmed) {
        //                 history.push('/view-request-institute/'+request_institute_id);
        //               }
        //             }); 
        //     } else {
        //         toastr.error(res.message, '');
        //     }
            
        // }).catch(function (error) {
        // toastr.error(error.response.data.message, '');
        // });
      }
    });


  
    //meta title
    document.title = props.t("Sub_Institution_Details")+' | '+props.t("MedX")
    

    return (
        <div className="page-content">
            <div className="container-fluid">
            <Breadcrumbs title={props.t("Sub_Institution")} breadcrumbItem={props.t("Sub_Institution_Details")} />
            </div>

            

            <div className="container-fluid">

            <Row>
            <Col lg={6}>
              <Card>
                <CardBody>
                <div className="table-responsive">
                        <Table className="table mb-0 table-bordered">
                          <tbody>
                            
                                <tr>
                                  <th scope="row" className={"text-capitalize"}>{props.t("Sub_Institution_Name")}</th>
                                  <td>{institutionData.institution_name ? institutionData.institution_name : "-"}</td>
                                </tr>

                                <tr>
                                  <th scope="row" className={"text-capitalize"}>{props.t("Sub_Institution_Code")}</th>
                                  <td>{institutionData.new_code ? institutionData.new_code : "-"}</td>
                                </tr>

                                <tr>
                                  <th scope="row" className={"text-capitalize"}>{props.t("Affiliation")}</th>
                                  <td>{institutionData.affiliation ? institutionData.affiliation : "-"}</td>
                                </tr>


                               
                           
                                

                                <tr>
                                  <th scope="row" className={"text-capitalize"}>{props.t("Province")}</th>
                                  <td>
                                  {
                                    i18n.language == 'en' ? (
                                      institutionData.OfficerProvince?.name_en
                                    ) : ( institutionData.OfficerProvince?.name_th)
                                  }
                                  </td>
                                </tr>

                                <tr>
                                  <th scope="row" className={"text-capitalize"}>{props.t("District")}</th>
                                  <td>
                                  
                                  {
                                    i18n.language == 'en' ? (
                                      institutionData.OfficerDistrict?.name_en
                                    ) : ( institutionData.OfficerDistrict?.name_th)
                                  }
                                  </td>
                                </tr>

                                <tr>
                                  <th scope="row" className={"text-capitalize"}>{props.t("Sub_District")}</th>
                                  <td>
                                  {
                                    i18n.language == 'en' ? (
                                      institutionData.OfficerSubDistrict?.name_en
                                    ) : ( institutionData.OfficerSubDistrict?.name_th)
                                  }</td>
                                </tr>

                                <tr>
                                  <th scope="row" className={"text-capitalize"}>{props.t("Post_Code")}</th>
                                  <td>{institutionData.contact_post_code ? institutionData.contact_post_code : "-"}</td>
                                </tr>
                                <tr>
                                    <th scope="row" className={"text-capitalize"}>{props.t("Name Title")}</th>
                                    <td>{institutionData.ContactUser ? institutionData.ContactUser.name_title : "-"}</td>
                                  </tr>

                                  <tr>
                                    <th scope="row" className={"text-capitalize"}>{props.t("First_Name")}</th>
                                    <td>{institutionData.ContactUser ? institutionData.ContactUser.first_name : "-"}</td>
                                  </tr>

                                  <tr>
                                    <th scope="row" className={"text-capitalize"}>{props.t("Last_Name")}</th>
                                    <td>{institutionData.ContactUser ? institutionData.ContactUser.last_name : "-"}</td>
                                  </tr>

                                <tr>
                                  <th scope="row" className={"text-capitalize"}>{props.t("Phone")}</th>
                                  <td>{institutionData.ContactUser ? institutionData.ContactUser.mobile : "-"}</td>
                                </tr>

                                <tr>
                                  <th scope="row" className={"text-capitalize"}>{props.t("Email")}</th>
                                  <td>{institutionData.ContactUser ? institutionData.ContactUser.email : "-"}</td>
                                </tr>

                                <tr>
                                  <th scope="row" className={"text-capitalize"}>{props.t("Website")}</th>
                                  <td>{institutionData.contact_website ? institutionData.contact_website : "-"}</td>
                                </tr>

                                 <tr>
                                  <th scope="row" className={"text-capitalize"}>{props.t("Organization")}</th>
                                  <td>{institutionData.OrganizationType ? institutionData.OrganizationType.name_en : "-"}</td>
                                </tr>

                                <tr>
                                  <th scope="row" className={"text-capitalize"}>{props.t("Established_Yr")}</th>
                                  <td>{institutionData.established_year ? institutionData.established_year : "-"}</td>
                                </tr>

                                <tr>
                                  <th scope="row" className={"text-capitalize"}>{props.t("No_of_Members")}</th>
                                  <td>{institutionData.number_of_members ? institutionData.number_of_members : "-"}</td>
                                </tr>

                                <tr>
                                  <th scope="row" className={"text-capitalize"}>{props.t("Education")}</th>
                                  <td>{institutionData.EducationType ? institutionData.EducationType.name_en : "-"}</td>
                                </tr>

                                <tr>
                                  <th scope="row" className={"text-capitalize"}>{props.t("Institution_Name")}</th>
                                  <td>{institutionData.InstituteDetail ? institutionData.InstituteDetail.institution_name : "-"}</td>
                                </tr>

                                <tr>
                                  <th scope="row" className={"text-capitalize"}>{props.t("Code")}</th>
                                  <td>{institutionData.InstituteDetail ? institutionData.InstituteDetail.new_code : "-"}</td>
                                </tr>
                          </tbody>
                        </Table>
                      </div>
                </CardBody>
              </Card>
            </Col>

            <Col lg={6}>
              <Card>
                  <CardBody>
                    <div className="table-responsive">
                          <Table className="table mb-0 table-bordered">
                            <tbody>

                            <tr>
                                    <th scope="row" className={"text-capitalize"}>{props.t("Education_No_of_Med")}</th>
                                    <td>{institutionData.education_number_of_medical_with ? institutionData.education_number_of_medical_with : "-"}</td>
                                  </tr>

                            <tr>
                                    <th scope="row" className={"text-capitalize"}>{props.t("Education_No_of_Beds")}</th>
                                    <td>{institutionData.education_number_of_beds ? institutionData.education_number_of_beds : "-"}</td>
                                  </tr>
                              
                                  <tr>
                                    <th scope="row" className={"text-capitalize"}>{props.t("Service")}</th>
                                    <td>{institutionData.ServiceType ? institutionData.ServiceType.name_en : "-"}</td>
                                  </tr>

                                  <tr>
                                    <th scope="row" className={"text-capitalize"}>{props.t("Service_No_of_Med")}</th>
                                    <td>{institutionData.service_number_of_medical_with ? institutionData.service_number_of_medical_with : "-"}</td>
                                  </tr>

                                  <tr>
                                    <th scope="row" className={"text-capitalize"}>{props.t("Service_No_of_beds")}</th>
                                    <td>{institutionData.service_number_of_beds ? institutionData.service_number_of_beds : "-"}</td>
                                  </tr>

                                  <tr>
                                    <th scope="row" className={"text-capitalize"}>{props.t("Person_Web")}</th>
                                    <td>{institutionData.contact_website ? institutionData.contact_website : "-"}</td>
                                  </tr>


                                  <tr>
                                    <th scope="row" className={"text-capitalize"}>{props.t("Dr_Title")}</th>
                                    <td>{institutionData.DoctorUser ? institutionData.DoctorUser.name_title : "-"}</td>
                                  </tr>

                                  <tr>
                                    <th scope="row" className={"text-capitalize"}>{props.t("First_Name")}</th>
                                    <td>{institutionData.DoctorUser ? institutionData.DoctorUser.first_name : "-"}</td>
                                  </tr>

                                  <tr>
                                    <th scope="row" className={"text-capitalize"}>{props.t("Last_Name")}</th>
                                    <td>{institutionData.DoctorUser ? institutionData.DoctorUser.last_name : "-"}</td>
                                  </tr>

                                  <tr>
                                    <th scope="row" className={"text-capitalize"}>{props.t("License Number")}</th>
                                    <td>{institutionData.DoctorUser ? institutionData.DoctorUser.license_number : "-"}</td>
                                  </tr>

                                  <tr>
                                    <th scope="row" className={"text-capitalize"}>{props.t("Organization_Position")}</th>
                                    <td>{institutionData.doctor_organization_position ? institutionData.doctor_organization_position : "-"}</td>
                                  </tr>
                                  <tr>
                                    <th scope="row" className={"text-capitalize"}>{props.t("Institution_Address")}</th>
                                    <td>{institutionData.doctor_institution_address ? institutionData.doctor_institution_address : "-"}</td>
                                </tr>

                                <tr>
                                  <th scope="row" className={"text-capitalize"}>{props.t("Province")}</th>
                                  <td>
                                  {
                                    i18n.language == 'en' ? (
                                      institutionData.DoctorProvince?.name_en
                                    ) : ( institutionData.DoctorProvince?.name_th)
                                  }
                                  </td>
                                </tr>

                                <tr>
                                  <th scope="row" className={"text-capitalize"}>{props.t("District")}</th>
                                  <td>
                                  {
                                    i18n.language == 'en' ? (
                                      institutionData.DoctorDistrict?.name_en
                                    ) : ( institutionData.DoctorDistrict?.name_th)
                                  }
                                  </td>
                                </tr>

                                <tr>
                                  <th scope="row" className={"text-capitalize"}>{props.t("Sub_District")}</th>
                                  <td>
                                  {
                                    i18n.language == 'en' ? (
                                      institutionData.DoctorSubDistrict?.name_en
                                    ) : ( institutionData.DoctorSubDistrict?.name_th)
                                  }
                                  </td>
                                </tr>
                                <tr>
                                  <th scope="row" className={"text-capitalize"}>{props.t("Dr_Post_Code")}</th>
                                  <td>{institutionData.doctor_post_code ? institutionData.doctor_post_code : "-"}</td>
                                </tr> 
                                <tr>
                                  <th scope="row" className={"text-capitalize"}>{props.t("Contact_Ph")}</th>
                                  <td>{institutionData.DoctorUser ? institutionData.DoctorUser.mobile : "-"}</td>
                                </tr>

                                <tr>
                                  <th scope="row" className={"text-capitalize"}>{props.t("Contact_Email")}</th>
                                  <td>{institutionData.DoctorUser ? institutionData.DoctorUser.email : "-"}</td>
                                </tr>

                                <tr>
                                  <th scope="row" className={"text-capitalize"}>{props.t("Contact_Web")}</th>
                                  <td>{institutionData.doctor_website ? institutionData.doctor_website : "-"}</td>
                                </tr>
                                <tr>
                                  <th scope="row" className={"text-capitalize"}>{props.t("Trained")}</th>
                                  <td>{institutionData.cme_workshop ? institutionData.cme_workshop : "-"}</td>
                                </tr>                             
                          </tbody>
                        </Table>
                      </div>
                </CardBody>
              </Card>
            </Col>
          </Row>                   
            </div>
        </div>
    );
}
ViewSubInstitution.propTypes = {
  t: PropTypes.any,
  preGlobalFilteredRows: PropTypes.any,
};

export default withTranslation()(ViewSubInstitution);