import React, { useEffect, useState } from "react";
import { withTranslation } from "react-i18next";

import PropTypes from 'prop-types';

//import components
import Breadcrumbs from '../../../components/Common/Breadcrumb';
import DataTable from 'react-data-table-component';
import { ContentLengthScoresApi } from "apis/ContentLengthScoresApi";
import { useHistory } from "react-router-dom"
import * as Yup from "yup";
import { useFormik } from "formik";
import toastr from "toastr";
import config from "config/config";
import constants from "../../../config/constants";

import {
    Container,
    Row,
    Col,
    Card,
    CardBody,
    Form,
    Label,
    Input,
    FormFeedback,
    Button
} from "reactstrap";

import Swal from "sweetalert2";

function ContentLengthScore(props) {

    const history = useHistory();
    const [ContentLengthScoresList, setContentLengthScoresList] = useState([]);
    const [fetchContentLengthScore, setFetchContentLengthScore] = useState([]);
    const [postContentLengthId, setContentLengthId] = useState("");
    const [loading, setLoading] = useState(false);
    const [page, setCurrentPage] = useState(1);

    const [activeData, setActiveData] = useState({
        page: 1,
        totalPage: 1,
        search: "",
        limit: config.LIMIT
    });
    const [limit, setCurrentLimit] = useState(config.LIMIT);

    const formClear = () => {
        validationType.resetForm();
    };

    useEffect(() => {
        getAllContentLengthScoresList(activeData)
    }, [page,limit])



    const columns = [
        {
            name: props.t("Sr_No"),
            selector: row => row.content_length_id,
            sort: "asc",
            width: 50,
            sortable: true,
            defaultSortField: true,
            defaultSortAsc: false
        },
        {
            name: props.t("length"),
            selector: row => row.content_length,
            sort: "asc",
            width: 200,
            sortable: true,
            defaultSortField: true,
            defaultSortAsc: false
        },
        {
            name: props.t("score"),
            selector: row => row.score,
            sort: "asc",
            width: 200,
            sortable: true,
            defaultSortField: true,
            defaultSortAsc: false
        },
        {
            name: props.t("Content-Type"),
            selector: row => row.content_type,
            sort: "asc",
            width: 200,
            sortable: true,
            defaultSortField: true,
            defaultSortAsc: false
        },
        {
            name: props.t("Action"),
            selector: row => row.action,
            sort: "asc",
            width: 200,
        }
    ];

   


    const getAllContentLengthScoresList = (data) => {
        setLoading(true);
        ContentLengthScoresApi.getAllContentLengthScores(data)
            .then((res) => {
                console.log(res)
                setActiveData({
                    page: activeData.page,
                    totalPage: res?.data?.data?.count,
                    search: activeData.search,
                    limit: activeData.limit,
                });
                let data = [];
                var sr_no = res.data.sr_no_start;

                res?.data?.data?.rows?.forEach((v, i) => {
                    sr_no = sr_no + 1;
                    data[i] = {
                        content_length_id: v.id,
                        content_length: v.length,
                        score: v.score,
                        content_type: v.content_type,
                        action: (
                            <>

                                <i className="fas fa-edit" id="deletetooltip" style={{ color: "blue" }} onClick={() => onEdit(v.id)} />{" "}
                                <i className="mdi mdi-delete font-size-18" style={{ color: "red" }} id="deletetooltip" onClick={() => onDelete(v.id)} />
                            </>
                        ),
                    };
                });
                setContentLengthScoresList(data);
                setLoading(false);
            })
            .catch((err) => {
                console.log(err);
            });
    };

    const onDelete = (id) => {

        Swal.fire({
            title: props.t("Are_you_sure"),
            text: props.t("Able_to_revert_this"),
            icon: props.t("Warning"),
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#f46a6a",
            confirmButtonText: props.t("Yes_delete_it"),
            cancelButtonText: props.t("Cancel"),
        }).then((result) => {
            if (result.isConfirmed) {
                let ContentLengthId = { "content_length_id": id };
                ContentLengthScoresApi.deleteContentLengthScore(ContentLengthId)
                    .then((res) => {
                        Swal.fire(props.t("Deleted"), res.data.message, props.t("Success"));
                        setContentLengthId();
                        formClear();
                        handleChange(page);
                        //getAllContentLengthScoresList(activeData)
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            }
        });
    };

    const onEdit = (id) => {
        let ContentLengthId = { "content_length_id": id };
        setContentLengthId(id);
        ContentLengthScoresApi.getContentLengthScoreById(ContentLengthId)
            .then((res) => {
                setFetchContentLengthScore(res.data.data);
            })
            .catch((err) => {
                console.log(err);
            });
    };


    const validationType = useFormik({
        enableReinitialize: true,

        initialValues: {
            content_length_id: fetchContentLengthScore ? fetchContentLengthScore.id : "",
            content_length: fetchContentLengthScore ? fetchContentLengthScore.length : "",
            score: fetchContentLengthScore ? fetchContentLengthScore.score : "",
            content_type: fetchContentLengthScore ? fetchContentLengthScore.content_type : "",
        },

        validationSchema: Yup.object().shape({

            content_length: Yup.number().typeError(props.t("you must specify a number") ).required(
                props.t("Required-Message")
            ),
            score: Yup.number().required(
                props.t("Required-Message")
            ),
            content_type: Yup.string().required(
                props.t("Required-Message")
            ),

            
        }),

        onSubmit: (values) => {
            const data = new FormData();
            data.append("content_length_id", postContentLengthId)
            data.append("length", values.content_length);
            data.append("score", values.score);
            data.append("content_type", values.content_type);
            
            if (postContentLengthId) {
                values.content_length_id = postContentLengthId;
                ContentLengthScoresApi.updateContentLengthScore(data)
                    .then(res => {
                        if (res.data.success) {
                            Swal.fire({
                                text: res.data.message,
                                icon: 'success',
                                imageAlt: 'success image',
                            }).then((result) => {
                                if (result.isConfirmed) {
                                    history.push('/content-length-scores');
                                    setContentLengthId();
                                    formClear()
                                    getAllContentLengthScoresList(activeData)
                                }
                            });
                        } else {
                            toastr.error(res.message, '');
                        }
                    }).catch(function (error) {
                        if (error?.response?.data?.message) {
                            toastr.error(error?.response?.data?.message, '');
                        }
                        else {
                            toastr.error(error?.response?.data?.error?.errors?.map((item, v) => `${item.msg}<br>`), '');
                        }
                    });
            }
            else {
                ContentLengthScoresApi.createContentLengthScores(data)
                    .then(res => {
                        if (res.data.success) {
                            Swal.fire({
                                text: res.data.message,
                                icon: 'success',
                                imageAlt: 'success image',
                            }).then((result) => {
                                if (result?.isConfirmed) {
                                    history.push('/content-length-scores');
                                    formClear()
                                    getAllContentLengthScoresList(activeData)
                                }
                            });
                        } else {
                            toastr.error(res?.data?.message, '');
                        }
                    }).catch(function (error) {
                        if (error?.response?.data?.message) {
                            toastr.error(error?.response?.data?.message, '');
                        }
                        else {
                            toastr.error(error?.response?.data?.error?.errors?.map((item, v) => `${item.msg}<br>`), '');
                        }
                    });
            }

        }
    })

    const handleChange = (v) => {
        setCurrentPage(v);
        setActiveData({ page: v, totalPage: activeData.totalPage, search: activeData.search, limit: limit });
        const data = { page: v, totalPage: activeData.totalPage, search: activeData.search, limit: limit }
        getAllBanner(data)
    }

    const handleRowChange = (v) => {
        setCurrentLimit(v);
        setActiveData({ page: activeData.page, totalPage: activeData.totalPage, search: activeData.search, limit: v });
        const data = { page: activeData.page, totalPage: activeData.totalPage, search: activeData.search, limit: v }
        getAllBanner(data)
    }

    document.title = props.t("Content_Length _Score") + ' | ' + props.t("MedX");


    return (
        <div className="page-content">
            <div className="container-fluid">
                <Breadcrumbs title={props.t("Master")} breadcrumbItem={props.t("Content_Length _Score")} />
            </div>
            <Container fluid={false}>
                <Row>
                    <Col lg={8}>
                        <Card>
                            <CardBody>
                                <Col sm={12}>
                                    <DataTable
                                        className="table-bordered"
                                        columns={columns}
                                        data={ContentLengthScoresList}
                                    />
                                </Col>
                            </CardBody>
                        </Card>
                    </Col>
                    <Col lg={4}>
                        <Card>
                            <CardBody>
                                <Col sm={12}>
                                    <Form
                                        onSubmit={(e) => {
                                            e.preventDefault();
                                            validationType.handleSubmit();
                                            return false;
                                        }}
                                    >
                                        <Col sm={12}>
                                            <div className="mb-3">
                                                <Label className="form-label">{props.t("Content_Length")}</Label>
                                                <Input
                                                    name="content_length"
                                                    placeholder={props.t("Content_Length")}
                                                    type="text"
                                                    onChange={validationType.handleChange}
                                                    onBlur={validationType.handleBlur}
                                                    value={validationType.values.content_length || ""}
                                                    invalid={
                                                        validationType.touched.content_length && validationType.errors.content_length ? true : false
                                                    }
                                                />
                                                {validationType.touched.content_length && validationType.errors.content_length ? (
                                                    <FormFeedback type="invalid">{validationType.errors.content_length}</FormFeedback>
                                                ) : null}
                                            </div>
                                        </Col>

                                        <Col sm={12}>
                                            <div className="mb-3">
                                                <Label className="form-label">{props.t("Score")}</Label>
                                                <Input
                                                    name="score"
                                                    placeholder={props.t("Score")}
                                                    type="number"
                                                    onChange={validationType.handleChange}
                                                    onBlur={validationType.handleBlur}
                                                    value={validationType.values.score || ""}
                                                    invalid={
                                                        validationType.touched.score && validationType.errors.score ? true : false
                                                    }
                                                />
                                                {validationType.touched.score && validationType.errors.score ? (
                                                    <FormFeedback type="invalid">{validationType.errors.score}</FormFeedback>
                                                ) : null}
                                            </div>
                                        </Col>

                                        <Col sm={12}>
                                            <div className="mb-3">
                                                <Label className="form-label">{props.t("Content-Type")}</Label>

                                                <select
                                                    name="content_type"
                                                    type="select"
                                                    className="form-control"
                                                    onChange={validationType.handleChange}
                                                    onBlur={validationType.handleBlur}
                                                    value={validationType.values.content_type || ""}

                                                >
                                                  <option value="">{props.t("Content-Type")}</option>
                                                    {
                                                        Object.keys(constants.CONTENT_TYPE).map(key => {
                                                            return (
                                                                <option value={key} key={key}>{constants.CONTENT_TYPE[key]}</option>
                                                            )
                                                        })
                                                    }

                                                </select>
                                                {validationType.touched.content_type && validationType.errors.content_type ? (
                                                    <div style={{ color: '#f46a6a', fontSize: '11px', marginTop: '3px' }}>
                                                        {validationType.errors.content_type}
                                                    </div>
                                                ) : null}
                                            </div>
                                        </Col>


                                        <div className="d-flex flex-wrap gap-2">
                                            <Button type="submit" color="primary" >
                                                {props.t("Submit")}
                                            </Button>{" "}
                                            <Button color="secondary" onClick={e => formClear()}>
                                                {props.t("Clear")}
                                            </Button>
                                        </div>

                                    </Form>
                                </Col>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default withTranslation()(ContentLengthScore);