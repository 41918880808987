// src/components/filter.
import React, { useEffect, useMemo, useState } from "react";
import PropTypes from 'prop-types';

//import components
import Breadcrumbs from '../../components/Common/Breadcrumb';
import TableContainer from '../../components/Common/TableContainer';
import { withTranslation } from "react-i18next";
import { Link, useHistory } from "react-router-dom";
import { InstitutionApi } from "apis/InstitutionApi";

import {
    Button,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    ButtonDropdown,
    Table,
    Input,
  } from "reactstrap";
import config from "config/config";
import DataTable from "react-data-table-component";

function Institutions(props) {
    const history = useHistory();
    const [roles, setRoles] = useState([]);
    const [loading, setLoading] = useState(false);
    const [activeData, setActiveData] = useState({
        activePage: 1,
        totalPage: 1,
        search: "",
        limit: config.LIMIT,
    });

    const [instituteName, setInstituteName] = useState();
    const [autoSearch, setAutoSearchData] = useState([]);
    const [limit, setCurrentLimit] = useState(config.LIMIT);

    const columns = [
        {
          name: props.t("Sr_No"),
          selector: (row, index) => (index+1)+( ( activeData?.activePage -1)*activeData?.limit),
          sort: "asc",
          width: 20,
          sortable: true,
          defaultSortField: true,
          defaultSortAsc: false
        },
        {
          name:props.t("Institution_Name"),
          selector: row => row.institution_name,
          sort: "asc",
          width: 270,
          sortable: true,
          defaultSortField: true,
          defaultSortAsc: false
        },
        {
            name:props.t("Affiliation"),
            selector: row => row.affiliation,
            sort: "asc",
            width: 270,
            sortable: true,
            defaultSortField: true,
            defaultSortAsc: false
        },
        {
          name:props.t("Established_Yr"),
          selector: row => row.established_year,
          sort: "asc",
          width: 270,
          sortable: true,
          defaultSortField: true,
          defaultSortAsc: false
        },
        {
          name:props.t("Status"),
          selector: row => row.status,
          sort: "asc",
          width: 270,
          sortable: true,
          defaultSortField: true,
          defaultSortAsc: false
        },
        {
          name:props.t("Action"),
          selector: row => row.action,
          sort: "asc",
          width: 200,
        }
      ];

    const getAllInstitutions = (adata) => {
        setLoading(true);
        InstitutionApi.getAllInstitutes(adata)
          .then((res) => {
            setActiveData({
              activePage: adata.activePage,
              totalPage: res.data.requestinstitute.count,
              search: adata.search,
              limit: adata.limit,
            });
            let data = [];
            res.data.requestinstitute.rows.forEach((v, i) => {
              data[i] = {
                id: v.id,
                institution_name: v.institution_name,
                affiliation: v.affiliation,
                institution_address: v.institution_address,
                established_year: v.established_year,
                status: v.status,
                action: (
                  <>
                    <Link
                      to={`/view-get-institute/${v.id}`}
                    >
                        <Button
                        type="button"
                        color="primary"
                        className="btn-sm btn-rounded"
                        >
                        {props.t("View")}
                        </Button>
                    </Link>
                  </>
                ),
              };
            });
            setRoles(data);
            setLoading(false);
          })
          .catch((err) => {
            console.log(err);
          });
      };

      useEffect(() => {
        getAllInstitutions(activeData);
    }, [limit]);

    const handleChange = (v) => {
        setActiveData({ activePage: v, totalPage: activeData.totalPage, search: activeData.search, limit: limit });
        const data = { activePage: v, totalPage: activeData.totalPage, search: activeData.search, limit: limit }
        getAllInstitutions(data)
        }
    
        const handleRowChange = (v) => {
        setCurrentLimit(v);
        setActiveData({ activePage: activeData.activePage, totalPage: activeData.totalPage, search: activeData.search, limit: v });
        const data = { activePage: activeData.activePage, totalPage: activeData.totalPage, search: activeData.search, limit: v }
        getAllInstitutions(data)
        }

        const handleChangeSearch = () => {
         getAllInstitutions(activeData)
          }

          // const onSearch = (e) => {
          //   setActiveData({ ...activeData, search: e.target.value })
          // }
    
      const OnSearchKeyUp = (e) => {
        setInstituteName(e.target.value);
        if(e.key == "Enter") {
          setActiveData({ activePage: activeData.activePage, totalPage: activeData.totalPage, search: e.target.value, limit: activeData.limit });
          const data = { activePage: activeData.activePage, totalPage: activeData.totalPage, search: e.target.value, limit: activeData.limit }
          getAllInstitutions(data);
          setInstituteName(instituteName);
          setAutoSearchData([]);
          return false;
        }
      }
        
      const OnSearch = (e) => {
        let data = { keyword: e.target.value };
        setInstituteName(e.target.value);
        if(e.target.value != '') {
          InstitutionApi.getInstitutionsByName(data)
            .then((res) => {
              if (res.data.success) {
                setAutoSearchData(res.data.data);
              } else {
                setAutoSearchData([]);
              }
            })
            .catch((err) => {
              console.log(err);
          });
        }  else {
          setAutoSearchData([]);
        }
        // setActiveData({ ...activeData, search: e.target.value })
      }
    
      const searchByInstituteName = (keyword) => {
        setActiveData({ activePage: activeData.activePage, totalPage: activeData.totalPage, search: keyword, limit: activeData.limit });
        const data = { activePage: activeData.activePage, totalPage: activeData.totalPage, search: keyword, limit: activeData.limit }
        getAllInstitutions(data);
        setInstituteName(keyword);
        setAutoSearchData([]);
      }
    
      const searchRecord = () => {
        activeData.instituteName = (instituteName !== undefined) ? instituteName : "";

        setActiveData({ activePage: activeData.activePage, totalPage: activeData.totalPage, search: activeData.instituteName, limit: activeData.limit });
        const data = { activePage: activeData.activePage, totalPage: activeData.totalPage, search: activeData.instituteName, limit: activeData.limit }
        getAllInstitutions(data);
        setInstituteName(instituteName);
      }
    

     //meta title
    document.title = props.t("Institutions")+' | '+props.t("MedX");

    return (
        <div className="page-content">
            <div className="container-fluid">
                <Breadcrumbs title={props.t("Institution")} breadcrumbItem={props.t("Institutions")} />
            </div>

            <div style={{margin:'12px'}} className="d-flex ">
             {/* <Input onChange={onSearch} className="w-25" name="search" placeholder={props.t("Search")}/>
             <button style={{marginleft: '10px'}} onClick={handleChangeSearch} className="btn btn-primary">{props.t("Search")}</button> */}
            <div className="wrapper w-40">
              <div className="search-input">
                <a href="" target="_blank" hidden="" />
              
                <input type="text" defaultValue={instituteName} value={instituteName} onChange={(e) => OnSearch(e) } onKeyUp = {OnSearchKeyUp}  placeholder="Type to search.."/>
                {autoSearch.length > 0 && instituteName != '' && (
                  <div className="autocom-box">
                    {autoSearch?.map((searchdata, index) => {
                      return (<li key={index} onClick={() => searchByInstituteName(searchdata.institution_name)}  >{searchdata.institution_name}</li>)
                    })}
                  </div>
                )}
                <div className="icon">
                  <i className="fas fa-search" onClick={() => searchRecord()}/>
                </div>
              </div>
            </div>

           
            </div>

            <div className="container-fluid">
              
              <DataTable
                className="table-bordered"
                progressPending={loading}
                columns={columns}
                data={roles}
                pagination
                paginationServer
                paginationTotalRows={activeData.totalPage}
                paginationPerPage={activeData.limit}
                defaultSortFieldID={1}
                onChangeRowsPerPage={value => handleRowChange(value)}
                onChangePage={value => handleChange(value)}
                sortable
                noHeader
                defaultSortField="id"
                defaultSortAsc={false}
                highlightOnHover
              />
      </div>

    </div>
  );
}
Institutions.propTypes = {
  t: PropTypes.any,
  preGlobalFilteredRows: PropTypes.any,

};


export default withTranslation()(Institutions);