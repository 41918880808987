// src/components/filter.
import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"

//import components
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import DataTable from "react-data-table-component"
import { BannerApi } from "../../../apis/BannerApi"
import { useHistory, Link } from "react-router-dom"
import TableContainer from "../../../components/Common/TableContainer"
import { withTranslation } from "react-i18next"
import * as Yup from "yup"
import { useFormik } from "formik"
import toastr from "toastr"
import config from "config/config"
import constants from "../../../config/constants"
import i18n from "../../../i18n"
import * as url from "../../../helpers/common_helper"

import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Form,
  Label,
  Input,
  FormFeedback,
  Button,
} from "reactstrap"
import Swal from "sweetalert2"
import { ActivityApi } from "apis/ActivityApi"
import moment from "moment"
import { getRoleID, getToken } from "../../../Token"

const ActivityRequests = props => {
  const history = useHistory()
  const [activityList, setActivityList] = useState([])
  const [loading, setLoading] = useState(false)

  const [activeData, setActiveData] = useState({
    page: 1,
    totalPage: 1,
    search: "",
    limit: config.LIMIT,
  })

  useEffect(() => {
    getAllActivityRequests(activeData)
  }, [])

  const columns = [
    {
      name: props.t("Sr_No"),
      selector: row => row.sr_no,
      sort: "asc",
      width: 150,
      sortable: true,
      defaultSortField: true,
      defaultSortAsc: false,
    },
    {
      name: props.t("Title_Eng"),
      selector: row => row.title_en,
      sort: "asc",
      width: 270,
      sortable: true,
      defaultSortField: true,
      defaultSortAsc: false,
    },
    {
      name: props.t("Activity_Type"),
      selector: row => row.activity_type,
      sort: "asc",
      width: 270,
      sortable: true,
      defaultSortField: true,
      defaultSortAsc: false,
    },
    {
      name: props.t("Activity_Sub_Type"),
      selector: row => row.activity_sub_type,
      sort: "asc",
      width: 270,
      sortable: true,
      defaultSortField: true,
      defaultSortAsc: false,
    },
    {
      name: props.t("Visible_To_Others"),
      selector: row => row.visible_to_others,
      sort: "asc",
      width: 270,
      sortable: true,
      defaultSortField: true,
      defaultSortAsc: false,
    },
    // {
    //     name: props.t("End_Time"),
    //     selector: row => row.end_time,
    //     sort: "asc",
    //     width: 270,
    //     sortable: true,
    //     defaultSortField: true,
    //     defaultSortAsc: false
    // },
    {
      name: props.t("Status"),
      selector: row => row.status,
      sort: "asc",
      width: 270,
      sortable: true,
      defaultSortField: true,
      defaultSortAsc: false,
    },
    {
      name: props.t("Action"),
      selector: row => row.action,
      sort: "asc",
      width: 200,
    },
  ]

  const onClickViewActivityDetailGandler = (id, institute_id) => {
    const payload = {
      activity_id: id,
      token: getToken(),
      institute_id : institute_id
    }
    ActivityApi.viewCustomActivityFormAPI(payload)
      .then(res => {
        if (res.data.success) {
          window.open(res.data.loadUrl, "_blank")
        }
      })
      .catch(err => {
        console.log(err)
      })
  }

  const getAllActivityRequests = data => {
    setLoading(true)
    ActivityApi.getAllActivityRequestAPi(data)
      .then(res => {
        setActiveData({
          page: activeData.page,
          totalPage: res?.data?.data?.count,
          search: activeData.search,
          limit: activeData.limit,
        })
        let data = []
        var sr_no = res.data.sr_no_start
        res?.data?.data?.rows?.forEach((v, i) => {
          sr_no = sr_no + 1
          data[i] = {
            sr_no: sr_no,
            title_en: v.title_en,
            activity_type: v.activity_group,
            activity_sub_type: v?.ActivityType?.code ? v.ActivityType?.code : "",
            visible_to_others: v.visible_to_others ? "Yes" : "No",
            // activity_date:url.dateFormat(v.activity_date),
            // start_time:url.TimeFormat(v.start_time),
            // end_time:url.TimeFormat(v.end_time),
            status: v.approval_status,
            action: (
              <>
                <Button
                  type="button"
                  color="primary"
                  className="btn-sm btn-rounded"
                  onClick={() => {
                    onClickViewActivityDetailGandler(v.id, v.Institute?.id)
                  }}
                >
                  {props.t("checkDetails")}
                </Button>
              </>
            ),
          }
        })
        setActivityList(data)
        setLoading(false)
      })
      .catch(err => {
        console.log(err)
      })
  }

  const handleChange = v => {
    setActiveData({
      page: v,
      totalPage: activeData.totalPage,
      search: activeData.search,
      limit: activeData.limit,
    })
    const data = {
      page: v,
      totalPage: activeData.totalPage,
      search: activeData.search,
      limit: activeData.limit,
    }
    getAllActivityRequests(data)
  }

  const handleRowChange = v => {
    setActiveData({
      page: activeData.page,
      totalPage: activeData.totalPage,
      search: activeData.search,
      limit: v,
    })
    const data = {
      page: activeData.page,
      totalPage: activeData.totalPage,
      search: activeData.search,
      limit: v,
    }
    getAllActivityRequests(data)
  }

  //meta title
  document.title = props.t("activityRequests") + " | " + props.t("MedX")

  return (
    <div className="page-content">
      <div className="container-fluid">
        <Breadcrumbs
          title={props.t("activityRequests")}
          breadcrumbItem={props.t("activityRequests")}
        />
        <Container fluid={false}>
          <Row>
            <Col lg={12}>
              <Card>
                <CardBody>
                  <Col sm={12}>
                    <DataTable
                      className="table-bordered"
                      progressPending={loading}
                      columns={columns}
                      data={activityList}
                      pagination
                      paginationServer
                      paginationTotalRows={activeData.totalPage}
                      paginationPerPage={activeData.limit}
                      defaultSortFieldID={1}
                      onChangeRowsPerPage={value => handleRowChange(value)}
                      onChangePage={value => handleChange(value)}
                      sortable
                      noHeader
                      defaultSortField="id"
                      defaultSortAsc={false}
                      highlightOnHover
                    />
                  </Col>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  )
}

ActivityRequests.propTypes = {
  t: PropTypes.any,
  preGlobalFilteredRows: PropTypes.any,
}

export default withTranslation()(ActivityRequests)
