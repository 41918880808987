import React from "react"
import config from "config/config"
import { useFormik } from "formik"
import { useEffect, useState } from "react"
import DataTable from "react-data-table-component"
import { withTranslation } from "react-i18next"
import { useHistory } from "react-router-dom"
import toastr from "toastr"
import * as Yup from "yup"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { InternshipApi } from "apis/InternshipApi"
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Form,
  FormFeedback,
  Input,
  Label,
  Row,
} from "reactstrap"
import Swal from "sweetalert2"
import constants from "../../config/constants"
import { getRoleID } from "Token"

const MainTaskTypes = props => {
  const history = useHistory()
  const [mainTaskTypes, setMainTaskTypes] = useState([])
  const [mainTaskType, setMainTaskType] = useState([])
  const [maintaskTypeId, setMainTaskTypeId] = useState()
  const [loading, setLoading] = useState(false)
  const [activeData, setActiveData] = useState({
    page: 1,
    totalPage: 1,
    search: "",
    limit: config.LIMIT,
  })
  const [page, setCurrentPage] = useState(1);


  useEffect(() => {
    getAllMainTaskTypes(activeData)
  }, [page])

  const formClear = () => {
    validationType.resetForm()
    setMainTaskType()
  }

  const columns = [
    {
      name: props.t("Sr_No"),
      selector: row => row.index,
      sort: "asc",
      width: 150,
      sortable: true,
      defaultSortField: true,
      defaultSortAsc: false,
    },
    {
      name: props.t("Title_Eng"),
      selector: row => row.title_en,
      sort: "asc",
      width: 270,
      sortable: true,
      defaultSortField: true,
      defaultSortAsc: false,
    },
    {
      name: props.t("Title_Thai"),
      selector: row => row.title_th,
      sort: "asc",
      width: 270,
      sortable: true,
      defaultSortField: true,
      defaultSortAsc: false,
    },
    {
      name: props.t("Action"),
      selector: row => row.action,
      sort: "asc",
      width: 200,
    },
  ]

  const getAllMainTaskTypes = data => {
    setLoading(true)
    InternshipApi.getMainTaskTypesApi(data)
      .then(res => {
        if (res.data.success) {
          setActiveData({
            page: activeData.page,
            totalPage: res?.data?.data?.count,
            search: activeData.search,
            limit: activeData.limit,
          })
          let data = []
          let sr_no = res.data.sr_no_start
          res?.data?.data?.rows?.forEach((task, i) => {
            sr_no = sr_no + 1
            data[i] = {
              index: sr_no,
              title_en: task.title_en,
              title_th: task.title_th,
              action: (
                <>
                  <a href="#" onClick={() => onEdit(task.id)}>
                    <i
                      className="fas fa-edit"
                      id="deletetooltip"
                      style={{ color: "blue", marginRight: "3px" }}
                    />{" "}
                  </a>
                  <a href="#" onClick={() => onDelete(task.id)}>
                    <i
                      className="mdi mdi-delete font-size-16"
                      style={{ color: "red" }}
                      id="deletetooltip"
                    ></i>
                  </a>
                </>
              ),
            }
          })
          setMainTaskTypes(data)
          setLoading(false)
        }
      })
      .catch(err => {
        console.log(err)
      })
  }

  const onDelete = id => {
    Swal.fire({
      title: props.t("Are_you_sure"),
      text: props.t("Able_to_revert_this"),
      icon: props.t("Warning"),
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#f46a6a",
      confirmButtonText: props.t("Yes_delete_it"),
      cancelButtonText: props.t("Cancel"),
    }).then(result => {
      if (result.isConfirmed) {
        InternshipApi.deleteMainTaskTypeApi(id)
          .then(res => {
            Swal.fire(props.t("Deleted"), res.data.message, props.t("Success"))
            setMainTaskTypeId()
            formClear()
            handleChange(page);
            //getAllMainTaskTypes(activeData)
          })
          .catch(err => {
            console.log(err)
          })
      }
    })
  }

  const onEdit = id => {
    setMainTaskTypeId(id)
    InternshipApi.getMainTaskTypeApi(id)
      .then(res => {
        if (res.data.success) setMainTaskType(res.data.data)
      })
      .catch(err => {
        console.log(err)
      })
  }

  // Form validation
  const validationType = useFormik({
    enableReinitialize: true,
    initialValues: {
      main_task_type_id: maintaskTypeId ? maintaskTypeId : "",
      title_en: mainTaskType ? mainTaskType.title_en : "",
      title_th: mainTaskType ? mainTaskType.title_th : "",
      description_en: mainTaskType ? mainTaskType.description_en : "",
      description_th: mainTaskType ? mainTaskType.description_th : "",
    },
    validationSchema: Yup.object().shape({
      title_en: Yup.string().required(props.t("Required-Message")),
      title_th: Yup.string().required(props.t("Required-Message")),
      description_en: Yup.string().required(props.t("Required-Message")),
      description_th: Yup.string().required(props.t("Required-Message")),
    }),
    onSubmit: values => {
      if (maintaskTypeId) {
        InternshipApi.updateMainTaskTypeApi(values)
          .then(res => {
            if (res.data.success) {
              Swal.fire({
                text: res.data.message,
                icon: "success",
                imageAlt: "success image",
              }).then(result => {
                if (result.isConfirmed) {
                  history.push("/main-task-types")
                  setMainTaskTypeId()
                  formClear()
                  getAllMainTaskTypes(activeData)
                }
              })
            } else {
              toastr.error(res.message, "")
            }
          })
          .catch(function (error) {
            if (error?.response?.data?.message) {
              toastr.error(error?.response?.data?.message, "")
            } else {
              toastr.error(
                error?.response?.data?.error?.errors?.map(
                  (item, v) => `${item.msg}<br>`
                ),
                ""
              )
            }
          })
      } else {
        InternshipApi.createMainTaskTypeApi(values)
          .then(res => {
            if (res.data.success) {
              Swal.fire({
                text: res.data.message,
                icon: "success",
                imageAlt: "success image",
              }).then(result => {
                if (result?.isConfirmed) {
                  history.push("/main-task-types")
                  formClear()
                  getAllMainTaskTypes(activeData)
                }
              })
            } else {
              toastr.error(res?.data?.message, "")
            }
          })
          .catch(function (error) {
            if (error?.response?.data?.message) {
              toastr.error(error?.response?.data?.message, "")
            } else {
              toastr.error(
                error?.response?.data?.error?.errors?.map(
                  (item, v) => `${item.msg}<br>`
                ),
                ""
              )
            }
          })
      }
    },
  })

  const handleChange = v => {
    setCurrentPage(v);
    setActiveData({
      page: v,
      totalPage: activeData.totalPage,
      search: activeData.search,
      limit: activeData.limit,
    })
    const data = {
      page: v,
      totalPage: activeData.totalPage,
      search: activeData.search,
      limit: activeData.limit,
    }
    getAllMainTaskTypes(data)
  }

  const handleRowChange = v => {
    setActiveData({
      page: activeData.page,
      totalPage: activeData.totalPage,
      search: activeData.search,
      limit: v,
    })
    const data = {
      page: activeData.page,
      totalPage: activeData.totalPage,
      search: activeData.search,
      limit: v,
    }
    getAllMainTaskTypes(data)
  }

  //meta title
  document.title = props.t("mainTaskTypes") + " | " + props.t("MedX")

  return (
    <div className="page-content">
      <div className="container-fluid">
        <Breadcrumbs
          title={props.t("internship")}
          breadcrumbItem={props.t("mainTaskTypes")}
        />
      </div>
      <Container fluid={false}>
        <Row>
          <Col lg={constants.ROLE.SUPERADMIN === getRoleID() ? 12 : 8}>
            <Card>
              <CardBody>
                <Col sm={12}>
                  <DataTable
                    className="table-bordered"
                    progressPending={loading}
                    columns={columns}
                    data={mainTaskTypes}
                    pagination
                    paginationServer
                    paginationTotalRows={activeData.totalPage}
                    paginationPerPage={activeData.limit}
                    defaultSortFieldID={1}
                    onChangeRowsPerPage={value => handleRowChange(value)}
                    onChangePage={value => handleChange(value)}
                    sortable
                    noHeader
                    defaultSortField="id"
                    defaultSortAsc={false}
                    highlightOnHover
                  />
                </Col>
              </CardBody>
            </Card>
          </Col>
          {constants.ROLE.SUPERADMIN !== getRoleID() && (
            <Col lg={4}>
              <Card>
                <CardBody>
                  <Col sm={12}>
                    <Form
                      onSubmit={e => {
                        e.preventDefault()
                        validationType.handleSubmit()
                        return false
                      }}
                    >
                      <Col sm={12}>
                        <div className="mb-3">
                          <Label className="form-label">
                            {props.t("Title_Thai")}
                            <span className="text-danger">*</span>
                          </Label>
                          <Input
                            name="title_th"
                            placeholder={props.t("Title_Thai")}
                            type="text"
                            onChange={validationType.handleChange}
                            onBlur={validationType.handleBlur}
                            value={validationType.values.title_th || ""}
                            invalid={
                              validationType.touched.title_th &&
                              validationType.errors.title_th
                                ? true
                                : false
                            }
                          />
                          {validationType.touched.title_th &&
                          validationType.errors.title_th ? (
                            <FormFeedback type="invalid">
                              {validationType.errors.title_th}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </Col>
                      <Col sm={12}>
                        <div className="mb-3">
                          <Label className="form-label">
                            {props.t("Title_Eng")}
                            <span className="text-danger">*</span>
                          </Label>
                          <Input
                            name="title_en"
                            placeholder={props.t("Title_Eng")}
                            type="text"
                            onChange={validationType.handleChange}
                            onBlur={validationType.handleBlur}
                            value={validationType.values.title_en || ""}
                            invalid={
                              validationType.touched.title_en &&
                              validationType.errors.title_en
                                ? true
                                : false
                            }
                          />
                          {validationType.touched.title_en &&
                          validationType.errors.title_en ? (
                            <FormFeedback type="invalid">
                              {validationType.errors.title_en}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </Col>

                      <Col sm={12}>
                        <div className="mb-3">
                          <Label className="form-label">
                            {props.t("Description_Th")}{" "}
                            <span className="text-danger">*</span>
                          </Label>
                          <Input
                            name="description_th"
                            placeholder={props.t("Description_Th")}
                            type="textarea"
                            onChange={validationType.handleChange}
                            onBlur={validationType.handleBlur}
                            value={validationType.values.description_th || ""}
                            invalid={
                              validationType.touched.description_th &&
                              validationType.errors.description_th
                                ? true
                                : false
                            }
                            autoComplete="off"
                          />
                          {validationType.touched.description_th &&
                          validationType.errors.description_th ? (
                            <FormFeedback type="invalid">
                              {validationType.errors.description_th}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </Col>
                      <Col sm={12}>
                        <div className="mb-3">
                          <Label className="form-label">
                            {props.t("Description_En")}{" "}
                            <span className="text-danger">*</span>
                          </Label>
                          <Input
                            name="description_en"
                            placeholder={props.t("Description_En")}
                            type="textarea"
                            onChange={validationType.handleChange}
                            onBlur={validationType.handleBlur}
                            value={validationType.values.description_en || ""}
                            invalid={
                              validationType.touched.description_en &&
                              validationType.errors.description_en
                                ? true
                                : false
                            }
                            autoComplete="off"
                          />
                          {validationType.touched.description_en &&
                          validationType.errors.description_en ? (
                            <FormFeedback type="invalid">
                              {validationType.errors.description_en}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </Col>
                      <div className="d-flex flex-wrap gap-2">
                        <Button type="submit" color="primary">
                          {props.t("Submit")}
                        </Button>{" "}
                        <Button color="secondary" onClick={e => formClear()}>
                          {props.t("Clear")}
                        </Button>
                      </div>
                    </Form>
                  </Col>
                </CardBody>
              </Card>
            </Col>
          )}
        </Row>
      </Container>
    </div>
  )
}

export default withTranslation()(MainTaskTypes)
