import config from "../config/config";
import axios from "axios"
import { getToken } from "../Token";
import * as url from "../helpers/url_helper";


const token = getToken();
export const InterestApi = {
    getAllInterests: async (data) => {
        var axiosConfig = {
            method: 'post',
            url: `${config.API_URL}${url.POST_FETCH_ALL_INTERESTS}`,
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            data: data
        };

        return await axios(axiosConfig);
    },
    createInterest: async (data) => {
        var axiosConfig = {
            method: 'post',
            url: `${config.API_URL}${url.POST_CREATE_INTEREST}`,
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            data: data
        };

        return await axios(axiosConfig);
    },
    getInterestById: async (data) => {
        var axiosConfig = {
            method: 'post',
            url: `${config.API_URL}${url.POST_FETCH_INTEREST}`,
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            data: data
        };

        return await axios(axiosConfig);
    },
    updateInterest: async (data) => {
        var axiosConfig = {
            method: 'post',
            url: `${config.API_URL}${url.POST_UPDATE_INTEREST}`,
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            data: data
        };

        return await axios(axiosConfig);
    },
    deleteInterest: async (data) => {
        var axiosConfig = {
            method: 'post',
            url: `${config.API_URL}${url.POST_DELETE_INTEREST}`,
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            data: data
        };

        return await axios(axiosConfig);
    },
    getAllInterest: async () => {
        var axiosConfig = {
            method: 'post',
            url: `${config.API_URL}${url.POST_FETCH_ALL_COMMON_INTEREST}`,
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
        };

        return await axios(axiosConfig);
    },

    fetchAllInterests: async (data) => {
        var axiosConfig = {
            method: 'post',
            url: `${config.API_URL}${url.POST_FETCH_ALL_COMMON_INTEREST}`,
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            data: data
        };

        return await axios(axiosConfig);
    },
}