import config from "../config/config";
import axios from "axios"
import { getToken } from "../Token";
import * as url from "../helpers/url_helper";


const token = getToken();
export const UserApi = {
    
    getDoctorNameByLicenseNumber: async (data) => {
        const axiosConfig = {
            method: 'post',
            url: `${config.API_URL}${url.POST_FETCH_ALL_DOCTOR_NAME_BY_LICENSE_NO}`,
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            data: data
        };
        return await axios(axiosConfig);
    },
    getSpeakerUser: async (data) => {
        const axiosConfig = {
            method: 'post',
            url: `${config.API_URL}${url.POST_FETCH_ALL_SPEAKERUSER}`,
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            data: data
        };
        return await axios(axiosConfig);
    },
    createDoctor: async (data) => {
        var axiosConfig = {
            method: 'post',
            url: `${config.API_URL}${url.POST_CREATE_DOCTOR}`,
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            data: data
        };

        return await axios(axiosConfig);
    },
    getDoctorById: async (doctor_id) => {
        var axiosConfig = {
            method: 'get',
            url: `${config.API_URL}${url.POST_FETCH_DOCTOR_BY_ID}/${doctor_id}`,
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            }
        };

        return await axios(axiosConfig);
    },
    updateDoctor: async (data) => {
        var axiosConfig = {
            method: 'post',
            url: `${config.API_URL}${url.POST_UPDATE_DOCTOR}`,
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            data: data
        };

        return await axios(axiosConfig);
    },
    deleteDoctor: async (data) => {
        var axiosConfig = {
            method: 'post',
            url: `${config.API_URL}${url.POST_DELETE_DOCTOR}`,
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            data: data
        };
        return await axios(axiosConfig);

    },
    getAllDoctor: async (data) => {
        var axiosConfig = {
            method: 'post',
            url: `${config.API_URL}${url.POST_FETCH_ALL_DOCTOR}`,
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            data:data
        };

        return await axios(axiosConfig);
    },
    getRequestAllDoctor: async (data) => {
        var axiosConfig = {
            method: 'post',
            url: `${config.API_URL}${url.POST_REQUEST_ALL_DOCTOR}`,
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            data:data
        };

        return await axios(axiosConfig);
    },
    createRequestAllDoctor: async (data) => {
        var axiosConfig = {
            method: 'post',
            url: `${config.API_URL}${url.POST_CREATE_REQUEST_DOCTOR}`,
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            data:data
        };

        return await axios(axiosConfig);
    },
    deleteRequestDoctor: async (data) => {
        var axiosConfig = {
            method: 'post',
            url: `${config.API_URL}${url.POST_DELETE_REQUEST_DOCTOR}`,
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            data: data
        };
        return await axios(axiosConfig);
    },
    getViewRequestDoctorById: async (request_doctor_id) => {
        var axiosConfig = {
            method: 'get',
            url: `${config.API_URL}${url.POST_FETCH_REQUST_DOCTOR_BY_ID}/${request_doctor_id}`,
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            }
        };

        return await axios(axiosConfig);
    },
}